import MultiRecordMain from '../MultiRecordMain';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { MultiRecordViewContext } from '../Context';

export function mapProps({ location, multiRecordMainStore }) {
  return {
    path: location?.pathname,
    mode: multiRecordMainStore.pageMode,
    setCurrentPath: multiRecordMainStore.setCurrentPath,
  };
}

const MultiRecordMainContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordMain,
  })
);

export default MultiRecordMainContainer;
