import { autorun, computed, decorate, observable } from 'mobx';

import { SingletonStore, PatientClient, VipClient } from 'common';

class PatientStore extends SingletonStore {
  // Observable
  id = null;

  constructor(params) {
    super(params);

    autorun(() => {
      this.refresh();
    });
  }

  fetch() {
    if (this.id) return PatientClient.getById(this.id);
  }
}

decorate(PatientStore, {
  id: observable,
});

const AuthPatientStore = new PatientStore();

class CreateStore extends SingletonStore {
  // Computed
  get patient() {
    return AuthPatientStore.result;
  }

  // Computed
  get isLoading() {
    return this.loading || AuthPatientStore.loading;
  }

  setPatient = id => {
    AuthPatientStore.id = id;
  };

  removePatient() {
    this.setPatient(null);
  }

  setParams(params) {
    if (params.patientId) {
      this.setPatient(params.patientId);
    }

    if (params.employeeId) {
      this.setUser(params.employeeId);
    }
  }

  reset = () => {
    AuthPatientStore.id = null;
  };

  create(params) {
    const newVipRegistration = Object.assign({ patient: this.patient }, params);

    return VipClient.create(newVipRegistration).then(this.reset.bind(this));
  }
}

decorate(CreateStore, {
  patient: computed,
  isLoading: computed,
});

export default new CreateStore();
