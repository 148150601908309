import $ from 'jquery';
import moment from 'moment';
import { fromCamelCase, makeSessionStorageForKey } from 'common';
const DEFAULT_START = moment()
  .subtract(1, 'months')
  .format('YYYY-MM-DD');
const DEFAULT_END = moment().format('YYYY-MM-DD');
export const sqUser = makeSessionStorageForKey('sqUser-incidents');
export const sqPatient = makeSessionStorageForKey('sqPatient-incidents');
export const DEFAULT_DATES = [DEFAULT_START, DEFAULT_END];

export const ADC_KEY = 'adcs';
export const AFTER_KEY = 'After';
export const BEFORE_KEY = 'Before';
export const DATES_KEY = 'dates';
export const GROUPS_KEY = 'groups';
export const MEDICATIONS_KEY = 'medications';
export const PATIENT_FULL_NAMES_KEY = 'patientFullNames';
export const USER_FULL_NAMES_KEY = 'userFullNames';
export const TYPES_KEY = 'types';
export const STATUS_KEY = 'status';
export const INCIDENT_IDS_KEY = 'incidentIds';
export const GROUP_BY_KEY = 'groupBy';
export const SORT_BY_KEY = 'sortBy';

export function mapKeyToDisplayLabel(key) {
  if (!key) return '';
  const defaultDisplayLabel = fromCamelCase(key);

  return (
    {
      [USER_FULL_NAMES_KEY]: 'User Name',
      [PATIENT_FULL_NAMES_KEY]: 'Patient Name',
      [MEDICATIONS_KEY]: 'Generic Medication Name',
      [DATES_KEY]: 'Time',
      [GROUPS_KEY]: 'Group',
      [TYPES_KEY]: 'Type',
      [ADC_KEY]: 'ADC',
      [STATUS_KEY]: 'Status',
      [INCIDENT_IDS_KEY]: 'Incident ID',
    }[key] || defaultDisplayLabel
  );
}

export function mapFilterKeyToParamKey(key) {
  if (!key) return null;

  return (
    {
      [ADC_KEY]: 'station',
      [DATES_KEY]: 'range',
      [AFTER_KEY]: 'rangeAfter',
      [BEFORE_KEY]: 'rangeBefore',
      [PATIENT_FULL_NAMES_KEY]: PATIENT_FULL_NAMES_KEY,
      [MEDICATIONS_KEY]: MEDICATIONS_KEY,
      [USER_FULL_NAMES_KEY]: USER_FULL_NAMES_KEY,
      [STATUS_KEY]: 'resolution',
      [TYPES_KEY]: 'type',
      [GROUP_BY_KEY]: 'groupBy',
      [SORT_BY_KEY]: 'sort',
      [GROUPS_KEY]: 'group',
      [INCIDENT_IDS_KEY]: 'incidentIds',
    }[key] || null
  );
}

export function hasThisOption(filterOpt = null, options = []) {
  return options.find(opt => opt.value === filterOpt) || null;
}

export function formatFilterDate(date) {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return { value: formattedDate };
}

export function handleOnChange(
  key,
  opt,
  queryCriteria,
  historyPush,
  exclude = false
) {
  const newCriteria = { ...queryCriteria };
  const k = mapFilterKeyToParamKey(key);
  let val = opt.value;
  delete newCriteria[k]; // delete old property

  if (exclude && opt.length) {
    // Add k to list of excludeFilters
    // If list of excludeFilters only has 1 then it will be just a string and not in an array,
    // you must put the existing excludeFilter into an array and then add the new excludeFilter
    newCriteria.excludeFilters = Array.from(
      new Set([
        ...(typeof newCriteria.excludeFilters === 'string'
          ? [newCriteria.excludeFilters]
          : newCriteria.excludeFilters || []),
        k,
      ])
    );
  } else {
    newCriteria.excludeFilters =
      typeof newCriteria?.excludeFilters === 'string'
        ? [newCriteria.excludeFilters]?.filter(f => f !== k)
        : newCriteria.excludeFilters?.filter(f => f !== k);

    // If exclude is false and there are no items in excludeFilters,
    // then delete that object from the query
    if (!newCriteria.excludeFilters) {
      delete newCriteria.excludeFilters;
    }
  }

  if (k === 'range' && val !== 'custom') {
    delete newCriteria.rangeAfter;
    delete newCriteria.rangeBefore;
  }

  if (k === 'range' && val === 'custom' && !newCriteria.rangeAfter) {
    newCriteria.rangeAfter = DEFAULT_DATES[0];
  }

  if (k === 'range' && val === 'custom' && !newCriteria.rangeBefore) {
    newCriteria.rangeBefore = DEFAULT_DATES[1];
  }

  if (k === 'groupBy') delete newCriteria.sort; // delete sort property for reset
  if (val === 'user' || val === 'patient' || val === 'station')
    newCriteria.sort = 'asc'; // we want A-Z for humans and meds

  // Create comma-separated list of incidentIds, userFullNames, patientFullNames and medications
  if (
    k === INCIDENT_IDS_KEY ||
    k === USER_FULL_NAMES_KEY ||
    k === PATIENT_FULL_NAMES_KEY ||
    k === MEDICATIONS_KEY
  ) {
    val = opt
      .filter(o => o.trim() !== '')
      .map(o => o.trim())
      .join();
    if (val === '') val = null;
  }

  if (k === 'group') newCriteria.group = (opt || []).map(({ id }) => id);

  if (k === 'type' && opt?.length) {
    newCriteria.type = (opt || []).map(({ value }) => value);
  }

  if (k === 'station' && opt?.length) {
    newCriteria.station = (opt || []).map(({ value }) => value);
  }

  if (k === 'resolution' && opt?.length) {
    newCriteria.resolution = (opt || []).map(({ value }) => value);
  }

  if (val && val !== 'any') newCriteria[k] = val;

  const cleanFields = { ...newCriteria };
  // scrub sensitive PII with numeric string then pass to history which sets url
  if (cleanFields.userFullNames) {
    const storageKey = sqUser.timeSet(cleanFields.userFullNames);
    cleanFields.userFullNames = storageKey;
  }

  if (cleanFields.patientFullNames) {
    const storageKey = sqPatient.timeSet(cleanFields.patientFullNames);
    cleanFields.patientFullNames = storageKey;
  }

  return historyPush({
    pathname: `/incidents`,
    search: '?' + $.param(cleanFields, true),
  });
}
