import { action, computed, decorate, observable } from 'mobx';
import { SingletonStore } from 'common';
import { AuditLogSummaryStore } from '../AuditLogSummaryStore';
import { AuditLogDetailStore } from '../AuditLogDetailStore';

// Fields we can change in setValue().
const FIELDS_ALLOWED_TO_CHANGE = ['user'];

/**
 * Houses both the summary and detailed user logs.
 */
class AuditLogStore extends SingletonStore {
  constructor({ appAuditClient, auditLogFilterStore }) {
    super();

    this.auditLogFilterStore = auditLogFilterStore;

    this.summaryStore = new AuditLogSummaryStore({
      appAuditClient,
      auditLogStore: this,
      auditLogFilterStore,
    });

    this.detailStore = new AuditLogDetailStore({
      appAuditClient,
      auditLogStore: this,
      auditLogFilterStore,
    });
  }

  tearDown = () => {
    this.summaryStore.tearDown();
    this.detailStore.tearDown();
  };

  // observables
  user = 'all';
  sort = 'created,desc';

  // action
  setValue = (type, val) => {
    if (!FIELDS_ALLOWED_TO_CHANGE.includes(type)) {
      throw new Error(
        `Cannot set value other than ${FIELDS_ALLOWED_TO_CHANGE.join(', ')}`
      );
    }

    this[type] = val;
  };

  // computed
  get chartData() {
    return this.summaryStore.data;
  }

  // computed
  get tableData() {
    return this.detailStore.userData;
  }

  tablePager = () => {
    this.detailStore.nextPage();
  };
}

decorate(AuditLogStore, {
  user: observable,
  sort: observable,

  setValue: action,
  chartData: computed,
  tableData: computed,
  tablePager: action,
});

export { AuditLogStore };
