import React from 'react';
import { observer } from 'mobx-react';
import { HalUtils, RegistrySearchStore } from 'common';
import CreateAuthView from '../CreateAuthView';
import { withRouter } from 'react-router-dom';
import CreateAuthStore from '../stores/CreateAuthStore';

export function mapProps({
  location,
  match,
  CreateAuthStore,
  HalUtils,
  RegistrySearchStore,
}) {
  const resetSearchStore = () => {
    RegistrySearchStore.reset();
  };

  const reset = () => {
    resetSearchStore();
    CreateAuthStore.reset();
  };

  return {
    pathname: location.pathname,
    params: match.params,
    user: CreateAuthStore.user,
    patient: CreateAuthStore.patient,
    selectedPatient: RegistrySearchStore.store.selectedPatient,
    selectedPatientId: HalUtils.getId(
      RegistrySearchStore.store.selectedPatient
    ),
    selectedUser: RegistrySearchStore.store.selectedUser,
    selectedUserId: HalUtils.getId(RegistrySearchStore.store.selectedUser),
    setType: RegistrySearchStore.setType,
    getEndpoint: HalUtils.getEndpoint,
    reset,
    resetSearchStore,
    authStore: CreateAuthStore,
    removePatient: CreateAuthStore.removePatient,
    removeUser: CreateAuthStore.removeUser,
    authStoreSetPatient: CreateAuthStore.setPatient,
    authStoreSetUser: CreateAuthStore.setUser,
    isLoading: CreateAuthStore.isLoading,
  };
}

const CreateAuthViewContainer = withRouter(
  observer(({ location, match }) => {
    return (
      <CreateAuthView
        {...mapProps({
          location,
          match,
          CreateAuthStore,
          HalUtils,
          RegistrySearchStore,
        })}
      />
    );
  })
);

export default CreateAuthViewContainer;
