import React from 'react';
import { observer } from 'mobx-react';

import CaseResultsStore from '../stores/CaseResultsStore';
import CaseResultsFiltersStore from '../../stores/CaseResultsFiltersStore';
import UserFilters from '../../ui/UserFilters';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({
  CaseResultsStore,
  CaseResultsFiltersStore,
  history,
  location,
  query,
}) {
  return {
    history,
    location,
    query,

    queryForToggle: CaseResultsStore.queryForToggle,
    queryForSelectionChange: CaseResultsStore.queryForSelectionChange,

    loadOrganizations: CaseResultsFiltersStore.loadOrganizations,
    loadDepartments: CaseResultsFiltersStore.loadDepartments,

    organizationOptions: CaseResultsFiltersStore.userOrganizations,
    organizationSelections: CaseResultsStore.displayOrganization,

    departmentOptions: CaseResultsFiltersStore.userDepartments,
    departmentSelections: CaseResultsStore.displayDepartment,

    roleOptions: CaseResultsFiltersStore.userTypes,
    roleSelections: CaseResultsStore.displayUserType,
    roleExcluded: CaseResultsStore.userTypeExclude,

    tagOptions: CaseResultsFiltersStore.userTags,
    tagSelections: CaseResultsStore.displayTags,
    tagExcluded: CaseResultsStore.userTagsExclude,
  };
}

const UserFiltersContainer = observer(({ history, location, query }) => (
  <UserFilters
    {...mapProps({
      CaseResultsStore,
      CaseResultsFiltersStore,
      history,
      location,
      query,
    })}
  />
));

UserFiltersContainer.displayName = 'UserFiltersContainer';

export default withRouterAndQuery(UserFiltersContainer);
