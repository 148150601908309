import React from 'react';
import PropTypes from 'prop-types';
import EmployeeBox from '../EmployeeBox';
import { observer } from 'mobx-react';
import CaseStore from '../../case/stores/CaseStore';
import EmployeeActivityStore from '../stores/EmployeeActivityStore';
import EmployeeAuthorizationsStore from '../../stores/EmployeeAuthorizationsStore';
import PrintSettingsStore from '../../stores/PrintSettingsStore';
import CaseCountByDBRefStore from '../../stores/CaseCountByDBRefStore';
import { EmployeeCasesStore, PermissionStore } from 'common';
import RelationshipStore from '../../stores/RelationshipStore';
import Store from '../../activity/stores/Store';
import { withRouter } from 'react-router-dom';
import { showTwoColumns } from '../../people/ContainerHelpers';

export const mapProps = ({
  CaseStore,
  EmployeeActivityStore,
  EmployeeAuthorizationsStore,
  EmployeeCasesStore,
  CaseCountByDBRefStore,
  PermissionStore,
  PrintSettingsStore,
  RelationshipStore,
  Store,
  history,
  forPage,
}) => {
  const employeeId =
    forPage === 'activity' ? Store.userIdParam : CaseStore.userId;
  const patientId =
    forPage === 'activity' ? Store.patientIdParam : CaseStore.patientId;
  const employee = forPage === 'activity' ? Store.user : CaseStore.user;

  const otherAliasId = RelationshipStore.getOtherAliasId(
    'users',
    employeeId,
    employee && employee.source
  );

  const tagListProps = {
    canManageTags: PermissionStore.has('PERSON_TAG_ASSIGN'),
    canViewTags: PermissionStore.has('PERSON_TAG_VIEW'),
    tags: employee?.tags,
    userId: employeeId,
  };

  const subEmployeeBoxProps = {
    authorizations: EmployeeAuthorizationsStore.infoById.get(employeeId),
    canManageAuths: PermissionStore.has('ACCESS_AUTH_CREATE'),
    canViewAuths: PermissionStore.has('ACCESS_AUTH_VIEW'),
    employeeId,
    otherAliasId,
    patientLinkForOppositeAlias:
      forPage === 'activity'
        ? Store.getPatientLink({
            patientId: otherAliasId,
          })
        : null,
    patientId,
    tagListProps,
  };

  const employeeRelationships =
    RelationshipStore.userRelationships.get(employeeId) || [];

  return {
    aliases: RelationshipStore.getAliases('users', employeeId),
    currentView: forPage,
    employee,
    fixedWidth: forPage === 'activity' && Store.isUserPatientActivityView,
    linkAlias: alias => history.push(Store.findLink(alias)),
    employeeCases: EmployeeCasesStore.infoById.get(employeeId) || [],
    employeeCaseCount: CaseCountByDBRefStore.infoById.get(employeeId) || 0,
    employeeId,
    employeeRelationships,
    orderLast: forPage === 'activity' && Store.isPatientFocus,
    otherAliases: RelationshipStore.getOtherAliases(
      'users',
      employeeId,
      employee && employee.source
    ),
    printSettings: PrintSettingsStore.printSettings,
    subEmployeeBoxProps,
    userLink:
      forPage === 'activity'
        ? Store.getUserLink({
            userId: employeeId,
          })
        : CaseStore.personLink('user'),
    lastActivityMoment: EmployeeActivityStore.date,
    showTwoColumns: showTwoColumns({
      forPage,
      caseStore: CaseStore,
      activityStore: Store,
    }),
    hideDob: !PermissionStore.has('USER_PII_VIEW'),
    loading: Store.loading,
  };
};

const EmployeeBoxContainer = observer(({ history, forPage }) => (
  <EmployeeBox
    {...mapProps({
      CaseStore,
      EmployeeActivityStore,
      EmployeeAuthorizationsStore,
      EmployeeCasesStore,
      CaseCountByDBRefStore,
      PermissionStore,
      PrintSettingsStore,
      RelationshipStore,
      Store,
      history,
      forPage,
    })}
  />
));

EmployeeBoxContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  forPage: PropTypes.oneOf(['activity', 'case']).isRequired,
};

EmployeeBoxContainer.displayName = 'EmployeeBoxContainer';

export default withRouter(EmployeeBoxContainer);
