import React from 'react';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { observer } from 'mobx-react';
import {
  AlertCategoryStore,
  GroupStore,
  SettingsStore,
  PermissionStore,
} from 'common';
import SettingsChartStore from '../../stores/SettingsChartStore';
import SettingsView from '../SettingsView';

export function mapProps({
  alertCategoryStore,
  groupStore,
  settingsChartStore,
  settingsStore,
  permissionStore,
  query,
}) {
  return {
    automatedCategories: alertCategoryStore.automatedCategories,
    creatingCases: Boolean(settingsStore.creatingCases),
    downloadCSV: settingsStore.downloadCSV,
    groups: groupStore.groups,
    hasPermission: permissionStore.has('ALERT_CATEGORY_SAVE'),
    isGroupEnabled: group => alertCategoryStore.isGroupEnabledMap.get(group),
    setFilters: settingsChartStore.setFilters,
    showLimits: settingsChartStore.showLimits,
    toggleAllCaseCreation: settingsStore.toggleAllCaseCreation,
    toggleGroupCaseCreation: settingsStore.toggleGroupCaseCreation,
    query,
  };
}

const SettingsViewContainer = observer(({ query }) => (
  <SettingsView
    {...mapProps({
      alertCategoryStore: AlertCategoryStore,
      settingsChartStore: SettingsChartStore,
      settingsStore: SettingsStore,
      groupStore: GroupStore,
      permissionStore: PermissionStore,
      query,
    })}
  />
));

SettingsViewContainer.displayName = 'SettingsViewContainer';

export default withRouterAndQuery(SettingsViewContainer);
