import React from 'react';
import { observer } from 'mobx-react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'common';
import UserTagsListViewContainer from '../users/UserTagsListViewContainer';
import UsersByTagListViewContainer from '../users/UsersByTagListViewContainer';
import UserTagsAssignViewContainer from '../assign/AssignViewContainer';
import ApplyBodyClassName from '../../ui/ApplyBodyClassName';

const TagsRootView = observer(
  class TagsRootView extends React.Component {
    render() {
      return (
        <main>
          <ApplyBodyClassName className="tags" />
          <Switch>
            <ProtectedRoute
              exact
              path="/user-tags/create"
              component={UserTagsListViewContainer}
            />
            <ProtectedRoute
              exact
              path="/user-tags/assign"
              component={UserTagsAssignViewContainer}
            />
            <ProtectedRoute
              exact
              path="/user-tags/assign/employee/:employeeId"
              component={UserTagsAssignViewContainer}
            />
            <ProtectedRoute
              exact
              path="/user-tags/:tagId"
              component={UserTagsListViewContainer}
            />
            <ProtectedRoute
              exact
              path="/user-tags/:tagId/users"
              component={UsersByTagListViewContainer}
            />
            <ProtectedRoute
              exact
              path="/user-tags/:tagId/users/:userId"
              component={UsersByTagListViewContainer}
            />
            <ProtectedRoute component={UserTagsListViewContainer} />
          </Switch>
        </main>
      );
    }
  }
);

TagsRootView.displayName = 'TagsRootView';

export default TagsRootView;
