import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';
import Field from '../../../ui/Field';

const UserCardFields = ({
  children,
  dateOfBirth,
  recentDepartment,
  recentOrganization,
  recentRoleName,
  roles,
  userId,
  hideDob,
}) => {
  let dob;
  if (dateOfBirth) {
    dob = moment(dateOfBirth)
      .utc()
      .format('l');
  }

  const uniqueRoleNames = new Set();
  const uniqueOrgs = new Set();
  const uniqueDepartments = new Set();

  roles.forEach(r => {
    if (r.name) uniqueRoleNames.add(r.name);
    if (r.organization) uniqueOrgs.add(r.organization);
    if (r.department) uniqueDepartments.add(r.department);
  });
  return (
    <ul>
      {hideDob ? null : <Field label="DOB" value={dob} />}
      <Field label="User ID" value={userId} width="wider1" />
      <Field
        label="Primary Role"
        value={recentRoleName}
        length={uniqueRoleNames.size}
      />
      <Field
        blankWhenEmpty
        label="Organization"
        value={recentOrganization}
        length={uniqueOrgs.size}
      />
      <Field
        blankWhenEmpty
        label="Department"
        value={recentDepartment}
        length={uniqueDepartments.size}
      />
      {children}
    </ul>
  );
};

UserCardFields.propTypes = {
  children: PropTypes.node,
  dateOfBirth: PropTypes.string,
  recentDepartment: PropTypes.string,
  recentOrganization: PropTypes.string,
  recentRoleName: PropTypes.string,
  roles: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      department: PropTypes.string,
      name: PropTypes.string,
      organization: PropTypes.string,
    })
  ),
  userId: PropTypes.string,
  hideDob: PropTypes.bool,
};

UserCardFields.defaultProps = {
  children: null,
  dateOfBirth: null,
  recentDepartment: null,
  recentOrganization: null,
  recentRoleName: null,
  roles: [],
  userId: null,
};

export default UserCardFields;
