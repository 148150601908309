import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import moment from 'moment';
import styles from './index.module.scss';

const DateCell = ({ date, format, timezone }) => {
  const none = <em className={styles.date}>none</em>;
  if (!date) return <Cell>{none}</Cell>;

  return (
    <Cell>{`${moment(date)
      .tz(timezone)
      .format(format)}`}</Cell>
  );
};

export default DateCell;

DateCell.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
  timezone: PropTypes.string,
};

DateCell.defaultProps = {
  date: '',
  format: 'l',
};
