import React from 'react';
import IncidentGroup from './IncidentGroup';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import IncidentPropTypes from './PropTypes';
import { groupIncidents } from '../../utils/IncidentUtils';
import EntityHeaderWrapper from './EntityHeaderWrapper';
import classnames from 'classnames';

const IncidentGroupList = ({
  className,
  fadeIn,
  medicationIncidents,
  selected,
  showCaseLink,
}) => {
  return (
    <>
      {medicationIncidents.map(incidentGroup => (
        <div
          key={`${incidentGroup.bottomText}-${incidentGroup.incidents[0].id}`}
          className={classnames({ fadeIn })}
        >
          <EntityHeaderWrapper
            topText={incidentGroup.headerData.topText}
            bottomText={incidentGroup.headerData.bottomText}
            extraText={incidentGroup.headerData.extraText}
          />
          {Object.entries(groupIncidents(incidentGroup.incidents)).map(
            ([key, incidentArray]) => (
              <IncidentGroup
                key={key}
                incidents={incidentArray}
                selected={selected}
                showCaseLink={showCaseLink}
                className={className}
              />
            )
          )}
        </div>
      ))}
    </>
  );
};

IncidentGroupList.propTypes = {
  className: PropTypes.string,
  fadeIn: PropTypes.bool,
  medicationIncidents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      headerData: PropTypes.shape({
        topText: PropTypes.string.isRequired,
        bottomText: PropTypes.string.isRequired,
        extraText: PropTypes.string,
      }).isRequired,
      incidents: mobxPropTypes.arrayOrObservableArrayOf(
        PropTypes.shape({
          administrations: IncidentPropTypes.administration,
          handlings: IncidentPropTypes.handling,
          medications: IncidentPropTypes.medication,
          user: IncidentPropTypes.person,
          patient: IncidentPropTypes.person,
          type: PropTypes.string.isRequired,
          details: PropTypes.shape({
            unreconciledAmount: PropTypes.shape({
              unit: PropTypes.string,
              value: PropTypes.string,
            }),
            patientDischargeTime: PropTypes.string,
          }),
          primaryEvent: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  selected: PropTypes.bool,
  showCaseLink: PropTypes.bool,
};

IncidentGroupList.defaultProps = {
  className: null,
  selected: false,
};

IncidentGroupList.displayName = 'IncidentGroupList';

export default IncidentGroupList;
