import { action, computed, decorate, observable, reaction } from 'mobx';
import {
  ERROR,
  HIDE_TOKEN,
  PATCH_SUCCESS,
  POST_SUCCESS,
  DELETE_SUCCESS,
  RADAR_INTEGRATION,
  SAI_360_INTEGRATION,
  SAI_360_LABEL,
  RADAR_LABEL,
  ORIGAMI_INTEGRATION,
  ORIGAMI_LABEL,
} from './const';
import { toast } from 'react-toastify';
import {
  SingletonStore,
  HalUtils,
  CaseIntegrationClient,
  LoginStore,
} from 'common';

/**
 * Convert the given string -> boolean hash to an array of the keys where the
 * corresponding boolean is false. These are the keys we wish to ignore when
 * synchronizing.
 *
 * @param {Object} hash
 * @return {string[]}
 */
function settingsHashToArrayOfIgnoredFields(hash) {
  return Object.entries(hash)
    .filter(([, shouldSync]) => !shouldSync)
    .map(([key]) => key);
}

/**
 * Houses Case Integration settings
 *
 */
class CaseIntegrationStore extends SingletonStore {
  constructor({ caseIntegrationClient, halUtils, loginStore }) {
    super();

    this.caseIntegrationClient = caseIntegrationClient;
    this.halUtils = halUtils;
    this.loginStore = loginStore;

    this.refresh();

    reaction(
      () => [this.loginStore.loggedIn],
      () => this.refresh()
    );

    reaction(
      () => [this.userIgnoredFields],
      () => this.initializeUserIgnoredField()
    );

    reaction(
      () => [this.result],
      () => {
        this.caseIntegration.timezone &&
          this.setTimezone(this.caseIntegration.timezone);
        this.caseIntegration?.classType &&
          this.setIntegrationType(this.caseIntegration.classType);
      }
    );
  }

  // SingletonStore overrides
  fetch() {
    return this.caseIntegrationClient.findAll();
  }

  // Observables
  formEnabled;
  formToken;
  formUrl;
  timezone = 'UTC';
  integrationType = RADAR_INTEGRATION;
  isDeleteConfirmationVisible = false;
  caseSyncSettings = {
    [RADAR_INTEGRATION]: {
      discovered: true,
      firstInformedAt: true,
      occurredOn: true,
      description: true,
      individualsImpacted: true,
      department: true,
      employeeInvolved: true,
      startDate: true,
      remediation: true,
      endDate: true,
      caseNotes: true,
      caseActions: true,
      caseResolution: true,
    },
    [SAI_360_INTEGRATION]: {
      importId: true,
      emrUserName: true,
      patientName: true,
      caseTitle: true,
      caseGroup: true,
      caseType: true,
      caseAssessmentReasons: true,
      earliestAccessDate: true,
      caseCreationDate: true,
      caseOwnerName: true,
      caseNotes: true,
      caseActions: true,
      caseResolution: true,
      caseAttachments: true,
      caseEmails: true,
      caseFinalAssessment: true,
    },
  };

  // Computed properties
  get caseIntegration() {
    const integrations = this.halUtils.getData(this.result);
    return (integrations.length && integrations[0]) || {};
  }

  get caseIntegrationId() {
    return this.halUtils.getId(this.caseIntegration);
  }

  get isEnabled() {
    return this.formEnabled !== undefined
      ? this.formEnabled
      : this.caseIntegration.deprecated !== undefined
      ? !this.caseIntegration.deprecated
      : false;
  }

  get tokenDisplay() {
    return this.formToken !== undefined
      ? this.formToken
      : this.caseIntegration.tokenPopulated
      ? HIDE_TOKEN
      : '';
  }

  get urlDisplay() {
    return this.formUrl !== undefined
      ? this.formUrl
      : this.caseIntegration.urlPopulated
      ? HIDE_TOKEN
      : '';
  }

  get userIgnoredFields() {
    return this.caseIntegration.userIgnoredFields || [];
  }

  get activeCaseSyncSettings() {
    return this.caseSyncSettings[this.integrationType] || {};
  }

  get canSave() {
    // We can always save if the token has been changed. We do not have an original value with which to compare
    if (this.formToken !== undefined || this.formUrl !== undefined) return true;

    // If the timezone that is saved doesn't match the timezone in our dropdown
    const currentTimezone = this.caseIntegration?.timezone;
    if (
      this.integrationType === RADAR_INTEGRATION &&
      this.caseIntegration.deprecated !== undefined &&
      this.timezone !== currentTimezone
    )
      return true;

    // If the radar integration doesn't exist the deprecated field will not be defined. Therefore we can save if enabled has changed to a true value;
    if (this.formEnabled && this.caseIntegration.deprecated === undefined)
      return true;

    // If the radar integration exists, we can save so long as the value on the form does not equal the negation of the integration's deprecated field.
    if (
      this.formEnabled !== undefined &&
      this.caseIntegration.deprecated !== undefined &&
      this.formEnabled !== !this.caseIntegration.deprecated
    )
      return true;
    // If a form value differs from the saved settings, we can save.
    return (
      this.userIgnoredFields
        .slice()
        .sort()
        .join() !==
      settingsHashToArrayOfIgnoredFields(this.activeCaseSyncSettings)
        .sort()
        .join()
    );
  }

  get integrationLabel() {
    const { integrationType } = this;

    if (integrationType === SAI_360_INTEGRATION) return SAI_360_LABEL;
    if (integrationType === RADAR_INTEGRATION) return RADAR_LABEL;
    if (integrationType === ORIGAMI_INTEGRATION) return ORIGAMI_LABEL;

    return 'Unknown';
  }

  // Actions
  setIntegrationType = integrationType => {
    this.integrationType = integrationType;
  };

  setEnabled = isEnabled => {
    this.formEnabled = isEnabled;
  };

  setToken = newValue => {
    this.formToken = newValue.trim();
  };

  setUrl = newValue => {
    this.formUrl = newValue.trim();
  };

  setTimezone = newValue => {
    this.timezone = newValue;
  };

  setDeleteConfirmationVisible = isVisible => {
    this.isDeleteConfirmationVisible = isVisible;
  };

  initializeUserIgnoredField = () => {
    this.userIgnoredFields.forEach(field => {
      if (this.integrationType === RADAR_INTEGRATION)
        this.setRadarSyncSetting(field, false);
      else if (this.integrationType === SAI_360_INTEGRATION)
        this.setSAI360SyncSetting(field, false);
    });
  };

  setRadarSyncSetting = (setting, newValue) => {
    this.caseSyncSettings = {
      ...this.caseSyncSettings,
      [RADAR_INTEGRATION]: {
        ...this.activeCaseSyncSettings,
        [setting]: newValue,
      },
    };
  };

  setSAI360SyncSetting = (setting, newValue) => {
    this.caseSyncSettings = {
      ...this.caseSyncSettings,
      [SAI_360_INTEGRATION]: {
        ...this.activeCaseSyncSettings,
        [setting]: newValue,
      },
    };
  };

  save = () => {
    if (!this.canSave) return;
    const payload = {
      ...this.caseIntegration,
      _class: 'RadarIntegration',
      deprecated: !this.isEnabled,
      userIgnoredFields: settingsHashToArrayOfIgnoredFields(
        this.caseSyncSettings[this.integrationType]
      ),
      timezone: this.timezone,
    };

    // If new token has been set use that, otherwise ignore it so we leave the original token, which is not
    // part of the incoming radar integration.
    if (this.formToken !== undefined) payload.token = this.formToken;

    const savePromise = !this.caseIntegrationId
      ? this.caseIntegrationClient.create(payload)
      : this.caseIntegrationClient.update(this.caseIntegrationId, payload);

    return savePromise
      .then(() => {
        this.resetStore();
        this.handleSuccess(
          !this.caseIntegrationId ? POST_SUCCESS : PATCH_SUCCESS
        );
      })
      .catch(() => this.handleError())
      .then(() => this.refresh());
  };

  saveIntegration = () => {
    if (!this.canSave) return;

    const payload =
      this.integrationType === RADAR_INTEGRATION
        ? {
            ...this.caseIntegration,
            _class: 'RadarIntegration',
            type: RADAR_INTEGRATION,
            deprecated: !this.isEnabled,
            userIgnoredFields: settingsHashToArrayOfIgnoredFields(
              this.activeCaseSyncSettings
            ),
            timezone: this.timezone,
          }
        : this.integrationType === SAI_360_INTEGRATION
        ? {
            ...this.caseIntegration,
            type: SAI_360_INTEGRATION,
            deprecated: !this.isEnabled,
            userIgnoredFields: settingsHashToArrayOfIgnoredFields(
              this.activeCaseSyncSettings
            ),
            _class: 'SAI360Integration',
          }
        : this.integrationType === ORIGAMI_INTEGRATION
        ? {
            ...this.caseIntegration,
            _class: 'OrigamiIntegration',
            type: ORIGAMI_INTEGRATION,
            deprecated: !this.isEnabled,
            userIgnoredFields: settingsHashToArrayOfIgnoredFields(
              this.activeCaseSyncSettings
            ),
          }
        : {};

    // If new token has been set use that, otherwise ignore it so we leave the original token, which is not
    // part of the incoming radar integration.

    if (
      this.integrationType === RADAR_INTEGRATION &&
      this.formToken !== undefined
    ) {
      payload.token = this.formToken;
    } else if (
      this.integrationType === SAI_360_INTEGRATION &&
      this.formUrl !== undefined
    ) {
      payload.url = this.formUrl;
    }

    const savePromise = !this.caseIntegrationId
      ? this.caseIntegrationClient.create(payload)
      : this.caseIntegrationClient.update(this.caseIntegrationId, payload);

    return savePromise
      .then(() => {
        this.resetStore();
        this.handleSuccess(
          !this.caseIntegrationId ? POST_SUCCESS : PATCH_SUCCESS
        );
      })
      .catch(() => this.handleError())
      .then(() => this.refresh());
  };

  delete = () => {
    if (!this.caseIntegrationId) return;

    return this.caseIntegrationClient
      .deleteById(this.caseIntegrationId)
      .then(() => {
        this.resetStore();
        this.handleSuccess(DELETE_SUCCESS);
      })
      .catch(() => this.handleError())
      .then(() => this.refresh());
  };

  // Helper methods
  resetStore = () => {
    this.formEnabled = undefined;
    this.formToken = undefined;
    this.formUrl = undefined;
    this.isDeleteConfirmationVisible = false;
  };

  handleSuccess = msg => {
    toast.success(msg, {
      position: 'bottom-left',
      autoClose: true,
    });
  };

  handleError = () => {
    toast.error(ERROR, {
      position: 'bottom-left',
      autoClose: true,
    });
  };
}

decorate(CaseIntegrationStore, {
  caseIntegration: computed,
  caseIntegrationId: computed,
  isEnabled: computed,
  tokenDisplay: computed,
  urlDisplay: computed,
  canSave: computed,
  userIgnoredFields: computed,
  activeCaseSyncSettings: computed,
  integrationLabel: computed,

  integrationType: observable,
  timezone: observable,
  formEnabled: observable,
  formToken: observable,
  formUrl: observable,
  caseSyncSettings: observable,
  isDeleteConfirmationVisible: observable,

  setIntegrationType: action,
  setEnabled: action,
  setToken: action,
  setUrl: action,
  setRadarSyncSetting: action,
  setSAI360SyncSetting: action,
  setDeleteConfirmationVisible: action,
  save: action,
  saveIntegration: action,
  deleteIntegration: action,
  delete: action,
  initializeUserIgnoredField: action,
});

const store = new CaseIntegrationStore({
  caseIntegrationClient: CaseIntegrationClient,
  halUtils: HalUtils,
  loginStore: LoginStore,
});
export { store as CaseIntegrationStore };
export default CaseIntegrationStore;
