import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { useStateMatchProp } from './hooks';

export function withRouterAndQuery(Component) {
  return withRouter(withRouterProps => {
    const [search, setSearch] = useStateMatchProp(
      withRouterProps.location.search
    );

    useEffect(() => {
      return withRouterProps.history.listen(location => {
        setSearch(location.search);
      });
    }, [withRouterProps.history, setSearch]);

    return <Component {...withRouterProps} query={parse(search)} />;
  });
}

export const routerAndQueryPropTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.object,
  }),
  match: PropTypes.shape({
    params: PropTypes.object,
    isExact: PropTypes.bool,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  query: PropTypes.object,
};
