/**
 *
 * @param specialties
 * @param other
 * @returns {{specialties: String, ...other}}
 */
export const processOtherFields = ({ specialties = [], other = {} }) => {
  if (!specialties.length && !Object.keys(other).length) return undefined;
  return {
    specialties: specialties.join(', '),
    ...other,
  };
};

/**
 *
 * @param departments
 * @param roles
 * @returns {{roleOrganizations: *[], departments: *[], roleDepartments: *[]}}
 *
 */
export const getUserDepartments = ({ departments = [], roles = [] }) => {
  const userDepartments =
    departments
      ?.slice()
      .reverse()
      .map(d => ({
        name: d?.name,
        source: d?.source,
        otherFields: processOtherFields({ ...d }),
      })) || [];

  const userRoleDepartments = [];
  const userRoleOrgs = [];

  roles.forEach(r => {
    if (
      r.department &&
      !userRoleDepartments.map(r => r.name).includes(r.department)
    ) {
      userRoleDepartments.push({
        name: r.department,
        source: r.source,
      });
    }

    if (
      r.organization &&
      !userRoleOrgs.map(o => o.name).includes(r.organization)
    )
      userRoleOrgs.push({
        name: r.organization,
        source: r.source,
      });
  });

  return {
    roleDepartments: [...userRoleDepartments],
    departments: [...userDepartments],
    roleOrganizations: [...userRoleOrgs],
  };
};
