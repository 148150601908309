import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import PersonAttribute from '../PersonAttribute';
import { FullName } from 'common';
import moment from 'moment';

const AuthAccessAttribute = ({
  auths,
  canManageAuths,
  patientId,
  type,
  userId,
}) => {
  let content;
  if (auths?.length) {
    // By default, the customer is shown the next most active authorization.
    // However, if the customer is on an activity screen where both employee
    // and patient are displayed, we want to see if there are any
    // authorizations that overlap and display that instead.
    let auth = auths[0];
    if (type === 'patient' && userId) {
      auth = auths.find(auth => auth.user?.id === userId) || auth;
      // Hack fix for possible missing data.
      if (!auth.user) auth = null;
    } else if (type === 'user' && patientId) {
      auth = auths.find(auth => auth.patient?.id === patientId) || auth;
      // Hack fix for possible missing data.
      if (!auth.patient) auth = null;
    }

    // link to other
    content = auth && (
      <>
        <Link
          to={`/activity/${type === 'patient' ? 'employee' : 'patient'}/${
            type === 'patient' ? auth.user.id : auth.patient.id
          }`}
        >
          <FullName
            person={type === 'patient' ? auth.user : auth.patient}
            condensed
          />
        </Link>
        <span className="float-right">
          {moment(auth.start).format('M/D/YY')} &ndash;&nbsp;
          {auth.requestedEnd ? (
            moment(auth.requestedEnd).format('M/D/YY')
          ) : (
            <em>indefinite</em>
          )}
        </span>
      </>
    );
  }

  let emptyLink;
  if (canManageAuths && type === 'user')
    emptyLink = `/authorizations/create/employee/${userId}`;
  else if (canManageAuths && type === 'patient')
    emptyLink = `/authorizations/create/patient/${patientId}`;

  return (
    <PersonAttribute
      content={content}
      icon="icon-verified_user"
      emptyLink={emptyLink}
      emptyText="No Authorized Accesses"
      tooltipText="Create Authorized Access"
      data-cy="no-auth-accesses"
    />
  );
};

AuthAccessAttribute.propTypes = {
  auths: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      patient: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
      user: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
    })
  ),
  canManageAuths: PropTypes.bool,
  patientId: PropTypes.string,
  type: PropTypes.oneOf(['patient', 'user']).isRequired,
  userId: PropTypes.string,
};

export default AuthAccessAttribute;
