import { autorun, computed, decorate, observable } from 'mobx';

import {
  SingletonStore,
  EnumValueStore,
  HalUtils,
  UserClient,
  PersonTagsClient,
} from 'common';

class UserStore extends SingletonStore {
  // Observable
  id = null;

  constructor(params) {
    super(params);

    autorun(() => {
      this.refresh();
    });
  }

  fetch() {
    if (this.id) return UserClient.getById(this.id);
  }
}

decorate(UserStore, {
  id: observable,
});

const TaggedUserStore = new UserStore();

class AssignStore extends SingletonStore {
  // Computed
  get user() {
    return TaggedUserStore.result;
  }

  // Computed
  get isLoading() {
    return this.loading || TaggedUserStore.loading || EnumValueStore.loading;
  }

  // Computed
  get availableTags() {
    return EnumValueStore.userTags
      .filter(t => !t.deprecated)
      .map(tag => {
        const id = HalUtils.getId(tag);
        return {
          id: id,
          name: tag.name,
        };
      });
  }

  setUser(id) {
    TaggedUserStore.id = id;
  }

  removeUser() {
    this.setUser(null);
  }

  setParams(params) {
    if (params.userId) {
      this.setUser(params.userId);
    }
  }

  reset() {
    TaggedUserStore.id = null;
  }

  assign(id, params) {
    return PersonTagsClient.assign(id, params).then(this.reset.bind(this));
  }
}

decorate(AssignStore, {
  user: computed,
  isLoading: computed,
  availableTags: computed,
});

export default new AssignStore();
