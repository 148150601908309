import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class ProgressButton extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    type: PropTypes.string,
    loading: PropTypes.bool,
    percentage: PropTypes.number,
  };

  render() {
    const {
      disabled,
      type,
      className,
      loading,
      percentage,
      children,
      ...other
    } = this.props;
    let klasses = classnames('button button--progress', {
      'button--progress--loading': loading,
    });

    if (className) klasses += ` ${className}`;

    return (
      <button
        type={type || 'button'}
        disabled={disabled || loading}
        className={klasses}
        {...other}
      >
        <span className="button--progress__content">{children}</span>
        <span className="button--progress__progress">
          <span
            className="button--progress__progress-inner"
            style={{
              width: `${loading ? percentage : 0}%`,
              opacity: loading ? 1 : 0,
            }}
          />
        </span>
      </button>
    );
  }
}
