import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import Field from '../../ui/Field';
import ListItem from '../../ui/SideList/ListItem';
import SideList from '../../ui/SideList';
import ShowHideMore from '../../ui/ShowHideMore';
import ViaSource from '../../ui/ViaSource';
import { getUserDepartments } from './helpers';

export const getLabel = l =>
  l === 'roleOrganizations' ? 'Organization' : 'Department';

export const NoDepartments = () => <Field label={getLabel()} width="auto" />;

export const Department = ({ name, source, otherFields = {}, label }) => {
  const sideListItems = [];

  Object.entries(otherFields).forEach(([k, v]) =>
    sideListItems.push(
      <ListItem label={k.capitalizeFirstLetter()} value={v} key={k + v} />
    )
  );

  return (
    <Field label={label} width="auto">
      <p>{name}</p>
      {source ? <ViaSource source={source} /> : null}
      {sideListItems?.length ? <SideList>{sideListItems}</SideList> : null}
    </Field>
  );
};

const DepartmentsAndOrganizations = ({ ehrDepartments = [], roles = [] }) => {
  if (!ehrDepartments.length && !roles.length) return <NoDepartments />;

  const depts = [],
    orgs = [];

  const data = getUserDepartments({ departments: ehrDepartments, roles });
  [...data.roleDepartments, ...data.departments].forEach((d, i) => {
    depts.push(<Department key={d.name + i} {...d} label={getLabel()} />);
  });

  data.roleOrganizations.forEach((d, i) => {
    orgs.push(
      <Department
        key={d.name + i}
        {...d}
        label={getLabel('roleOrganizations')}
      />
    );
  });

  return (
    <div>
      {depts.length > 0 && (
        <ShowHideMore numberToShow={1} label={getLabel()}>
          {depts}
        </ShowHideMore>
      )}
      {orgs.length > 0 && (
        <ShowHideMore numberToShow={1} label={getLabel('roleOrganizations')}>
          {orgs}
        </ShowHideMore>
      )}
    </div>
  );
};

DepartmentsAndOrganizations.propTypes = {
  ehrDepartments: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  roles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
};

Department.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  otherFields: PropTypes.shape({}),
};

export default DepartmentsAndOrganizations;
