import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './index.module.scss';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Anchor } from '../index';

export const NoteAction = ({
  onInitializeNote,
  toggleExpanded,
  isExpanded,
  notes,
  openNotesDisabled,
  addNoteDisabled,
}) => {
  const viewNoteText = (notes.length && (isExpanded ? 'Hide' : 'View')) || null;

  return (
    <>
      <div className={styles.notesHeading} data-cy="incident-note-count">
        <Anchor
          tag="span"
          role="button"
          tabIndex="0"
          className={cn(styles.viewNote, {
            [styles.disabled]: openNotesDisabled,
          })}
          disabled={openNotesDisabled}
          onClick={openNotesDisabled ? null : toggleExpanded}
          onKeyDown={({ key }) =>
            (key === ' ' || key === 'Enter') && toggleExpanded()
          }
        >
          {viewNoteText} Notes <strong>{notes.length}</strong>
        </Anchor>
      </div>
      <Anchor
        data-cy="incident-add-note"
        tag={'span'}
        role="button"
        tabIndex="0"
        className={cn(styles.addNote, {
          [styles.disabled]: addNoteDisabled,
        })}
        disabled={addNoteDisabled}
        onClick={addNoteDisabled ? null : onInitializeNote}
        onKeyDown={({ key }) =>
          (key === ' ' || key === 'Enter') && onInitializeNote()
        }
      >
        Add Note
      </Anchor>
    </>
  );
};

NoteAction.propTypes = {
  onInitializeNote: PropTypes.func.isRequired,
  notes: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      createdBy: PropTypes.shape({ fullName: PropTypes.string }),
      created: PropTypes.string,
      notes: PropTypes.string,
    })
  ),
  toggleExpanded: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  openNotesDisabled: PropTypes.bool.isRequired,
  addNoteDisabled: PropTypes.bool.isRequired,
};

NoteAction.defaultProps = {
  notes: [],
  isExpanded: false,
};

export default NoteAction;
