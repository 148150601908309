import { action, reaction, computed, decorate, observable } from 'mobx';
import moment from 'moment';

import {
  CaseClient,
  DateHelpers,
  DownloadUtils,
  RouterContainer,
  SettingsStore,
  SingletonStore,
} from 'common';

class SettingsChartStore extends SingletonStore {
  constructor() {
    super();
    reaction(
      () => [SettingsStore.categories, SettingsStore.size],
      () => {
        // either no category or user has switched to a group without the current category
        if (
          this.category === undefined ||
          !SettingsStore.categories.find(
            category => category.id === this.category
          )
        ) {
          this.category = 'All';
        }
      }
    );
  }

  // Observables
  category = localStorage.getItem('settingsCategory') || 'All';
  range = localStorage.getItem('settingsRange') || 'month';
  interval = localStorage.getItem('settingsInterval') || 'week';
  customStart = moment().subtract(1, 'month');
  customEnd = moment();

  // Action
  setFilters = query => {
    // if there is a category check to make sure it is one that is currently active
    if (query.category)
      this.category =
        query.category === 'All'
          ? query.category
          : this.checkCat(query.category);
    if (query.range) this.range = query.range;
    if (query.interval) this.interval = query.interval;
    if (query.settingsCreatedAfter)
      this.customStart = query.settingsCreatedAfter;
    if (query.settingsCreatedBefore)
      this.customEnd = query.settingsCreatedBefore;

    // might need to adjust the interval if the range has changed
    if (this.disabledIntervals.includes(this.interval)) {
      const defaultVal = DateHelpers.getIntervalDefault(
        this.range,
        this.customStart,
        this.customEnd
      );
      this.interval = defaultVal;
    }
  };

  // Computed
  get results() {
    return this.response || [];
  }

  /**
   * Compute a Date reference for the calendar quarter before the current one.
   * @return {Object} - a moment Object that stores the Date information for
   *                    the preceding quarter.
   */
  // Computed
  get previousQuarter() {
    return moment.startOf('quarter').subtract(1, 'quarter');
  }

  // Computed
  get dateRange() {
    const range = DateHelpers.rangeForPeriod(
      this.range,
      [moment(this.customStart), moment(this.customEnd)],
      [moment().subtract(1, 'months'), moment()]
    );
    if (!range[0]) range[0] = moment().subtract(1, 'month');
    if (!range[1]) range[1] = moment();
    return range;
  }

  // Computed
  get disabledIntervals() {
    return DateHelpers.getDisabledIntervalByRange(
      this.range,
      this.customStart,
      this.customEnd
    );
  }

  // Computed
  get categoriesList() {
    let categories = SettingsStore.categories;
    if (categories) {
      categories = categories.slice().map(c => ({
        id: c.id,
        name: c.name,
        group: (c.group && c.useGroups && c.group.name) || null,
      }));
    } else categories = [];

    categories.unshift({ id: 'All', name: 'All' });
    return categories;
  }

  // Computed
  get totalCases() {
    let count = 0;
    this.results.forEach(result => {
      count += result.count;
    });
    return count;
  }

  // Computed
  get totalCasesByResolution() {
    const counts = { VIOLATION: 0, NOT_VIOLATION: 0, UNRESOLVED: 0 };

    this.results.forEach(result => {
      const resolution = result.group2 || 'UNRESOLVED';
      counts[resolution] += result.count;
    });

    return Object.entries(counts).map(entry => ({ x: entry[0], y: entry[1] }));
  }

  // Computed
  get tickInterval() {
    let interval = 'month';

    if (this.range === 'year') interval = 'month';
    else if (this.range === 'ninetyDays' || this.range === 'quarter')
      interval = this.interval;
    else if (this.range === 'month') interval = 'week';
    else if (this.range === 'week') interval = 'day';
    else if (this.range === 'custom') {
      if (moment(this.customEnd).diff(moment(this.customStart), 'months') > 3)
        interval = 'month';
      else if (
        moment(this.customEnd).diff(moment(this.customStart), 'days') > 14
      )
        interval = 'week';
      else interval = 'day';
    }

    return interval;
  }

  // Computed
  get tickValues() {
    const tickValues = [];

    const date = moment(this.dateRange[0]);

    while (date.diff(this.dateRange[1]) < 0) {
      date.startOf(this.tickInterval);
      if (this.tickInterval === 'week') date.add(1, 'day'); // start weeks on Mondays to sync with Mongo count
      tickValues.push(date.toDate());
      date.add(1, this.tickInterval);
    }

    return tickValues;
  }

  // Computed
  get violationsByDate() {
    const counts = this.emptyCounts();
    this.results
      .filter(result => result.group2 === 'VIOLATION')
      .forEach(result => {
        counts[moment(result.group).toISOString()] = result.count;
      });

    return Object.entries(counts).map(entry => ({
      x: new Date(entry[0]),
      y: entry[1],
    }));
  }

  // Computed
  get notViolationsByDate() {
    const counts = this.emptyCounts();
    this.results
      .filter(result => result.group2 === 'NOT_VIOLATION')
      .forEach(result => {
        counts[moment(result.group).toISOString()] = result.count;
      });

    return Object.entries(counts).map(entry => ({
      x: new Date(entry[0]),
      y: entry[1],
    }));
  }

  // Computed
  get unresolvedByDate() {
    const counts = this.emptyCounts();
    this.results
      .filter(result => result.group2 === undefined || result.group2 === null)
      .forEach(result => {
        counts[moment(result.group).toISOString()] = result.count;
      });

    return Object.entries(counts).map(entry => ({
      x: new Date(entry[0]),
      y: entry[1],
    }));
  }

  mergeParams(current, newQuery) {
    const merged = Object.assign({}, current, newQuery);

    const keys = Object.keys(merged);

    keys.forEach(key => {
      if (merged[key] === null || merged[key] === undefined) {
        delete merged[key];
      }
    });

    return merged;
  }

  go(newQuery) {
    const merged = this.mergeParams(RouterContainer.query, newQuery || {});

    RouterContainer.go('/settings', merged);
  }

  fetch() {
    const matchingCategory = SettingsStore.categories.find(
      category => category.id === this.category
    );

    const params = {
      groupBy: ['created', 'resolution'],
      window: this.interval,
      createdAfter: this.dateRange[0],
      createdBefore: this.dateRange[1],
    };

    if (this.category !== 'All') {
      params.category = matchingCategory && matchingCategory.id;
    }

    return CaseClient.countBy(params);
  }

  emptyCounts() {
    const counts = {};
    const date = moment(this.dateRange[0]);

    while (date.diff(this.dateRange[1]) < 0) {
      date.startOf(this.interval);
      if (this.interval === 'week') date.add(1, 'day'); // start weeks on Mondays to sync with Mongo count
      counts[date.toISOString()] = 0;
      date.add(1, this.interval);
    }

    return counts;
  }

  checkCat(category) {
    const foundCat = SettingsStore.categories.find(cat => cat.id === category);
    return (foundCat && foundCat.id) || 'All';
  }

  /*
   * Retrieve a CSV report of the same data used to generate the new alerts bar chart.
   */
  downloadCSV() {
    const matchingCategory = SettingsStore.categories.find(
      category => category.id === this.category
    );

    const params = {
      groupBy: ['created', 'resolution'],
      window: this.interval,
      createdAfter: moment(this.dateRange[0])
        .startOf('day')
        .toISOString(),
    };

    if (matchingCategory)
      params.category = matchingCategory && matchingCategory.id;
    if (this.dateRange[1])
      params.createdBefore = moment(this.dateRange[1])
        .endOf('day')
        .toISOString();

    const csvHref = CaseClient.url('report/count', params);
    let csvTitle;

    if (params.category) {
      if (matchingCategory.group && matchingCategory.group.name)
        csvTitle = `${matchingCategory.group.name}: ${matchingCategory.name} Case History.csv`;
      else csvTitle = `${matchingCategory.name} Case History.csv`;
    } else {
      csvTitle = 'Case History.csv';
    }
    DownloadUtils.downloadFromServer(csvHref, csvTitle);
  }
}

decorate(SettingsChartStore, {
  // Observable
  category: observable,
  range: observable,
  interval: observable,
  customStart: observable,
  customEnd: observable,
  // Computed
  results: computed,
  previousQuarter: computed,
  dateRange: computed,
  disabledIntervals: computed,
  categoriesList: computed,
  totalCases: computed,
  totalCasesByResolution: computed,
  tickInterval: computed,
  tickValues: computed,
  violationsByDate: computed,
  notViolationsByDate: computed,
  unresolvedByDate: computed,
  // Action
  setFilters: action,
});

export default new SettingsChartStore();
