// responsible for drawing ticks, dates
import * as d3 from 'd3';

export default function drawTicks(
  context,
  brushedXScale,
  width,
  tickFormat,
  margin
) {
  const svg = context && context.select('g.axis');

  if (!svg) return;

  svg.select('g.x-axis.top').remove();
  const xAxis = d3
    .axisBottom(brushedXScale)
    .ticks(width / 150) // have no more than a tick per 150px
    .tickFormat(tickFormat);

  // xAxisEl
  svg
    .append('g')
    .classed('x-axis', true)
    .classed('top', true)
    .attr('transform', `translate(${margin.left}, 0)`)
    .call(xAxis);

  // Rotate the tick text so they have room if they need to overlap.
  svg.selectAll('.tick text').attr('transform', 'rotate(-15)');
}
