import React from 'react';
import { observer } from 'mobx-react';
import { AlertCategoryStore } from 'common';

import GRCCaseStore from '../../stores/GRCCaseStore';
import GRCCaseForm from '../GRCCaseForm';

export function mapProps(AlertCategoryStore, GRCCaseStore) {
  const {
    canSubmit,
    createCase,
    endDate,
    setValue,
    startDate,
    submitting,
    reset,
    title,
  } = GRCCaseStore;
  return {
    categories: AlertCategoryStore.GRCCategories,
    onChange: setValue,
    startDate,
    endDate,
    submitButtonValue: 'Create Case',
    canSubmit,
    submitFunction: createCase,
    submitting,
    reset,
    title,
  };
}

const NewGRCCaseContainer = observer(() => (
  <GRCCaseForm {...mapProps(AlertCategoryStore, GRCCaseStore)} />
));

NewGRCCaseContainer.displayName = 'NewGRCCaseContainer';

export default NewGRCCaseContainer;
