import { SettingsStore } from 'common';
import CasesReportCountStore from '../home/stores/CasesReportCountStore';
import CaseAgesStore from '../home/stores/CaseAgesStore';
import OpenCasesStore from '../home/stores/OpenCasesStore';
import CaseActivityStore from '../home/stores/CaseActivityStore';
import CaseResultsStore from '../search/stores/CaseResultsStore';
import CaseBundleStore from '../stores/CaseBundleStore';

class CaseUtils {
  /**
   * Synchronizes case counts and activity feeds. Typically invoked following
   * a case event which would change these values.
   * @return {undefined}
   */
  synchronize() {
    CasesReportCountStore.refresh();
    CaseAgesStore.refresh();
    OpenCasesStore.refresh();
    SettingsStore.refresh();
    CaseResultsStore.refresh();
    CaseActivityStore.refresh();
    CaseBundleStore.refresh();
  }

  /**
   * Synchronizes the activity feed. For lightweight case events (e.g. adding
   * notes), a fully synchronize is not necessary.
   * @return {undefined}
   */
  synchronizeActivity() {
    CaseActivityStore.refresh();
  }

  /**
   * Determine if this case is a privacy case.
   *
   * @param {Case} kase   The case in question.
   *
   * @return {boolean} Is privacy type.
   */
  isPrivacyCase = (kase = {}) => {
    if (kase.type && kase.type === 'privacy') return true;

    return !!(kase._links && kase._links.privacyCase);
  };

  /**
   * Determine if this case is a diversion case.
   *
   * @param {Case} kase   The case in question.
   *
   * @return {boolean} Is diversion type.
   */
  isDiversionCase = (kase = {}) => {
    if (kase.type && kase.type === 'diversion') return true;

    return !!(kase._links && kase._links.diversionCase);
  };

  /**
   * Determine if this case is a grc case.
   *
   * @param {Case} kase The case in question.
   *
   * @return {boolean} Is GRC type.
   */
  isGRCCase = (kase = {}) => {
    if (kase.type && kase.type === 'GRC') return true;

    return !!(kase._links && kase._links.gRCCase);
  };
  /**
   * Determine which type of case this is
   *
   * @param {Case} kase The case in question.
   *
   * @return {string} the string of case type in question.
   */
  whichCaseType = (kase = {}) => {
    if (this.isPrivacyCase(kase)) return 'privacy';
    if (this.isDiversionCase(kase)) return 'diversion';
    if (this.isGRCCase(kase)) return 'GRC';
    return 'creating';
  };
}

const me = new CaseUtils();
export { me as CaseUtils };
export default me;
