import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

function ExclusionButton({
  isDisabled,
  isExcluding,
  toggleFilter,
  'data-cy': dataCy,
}) {
  return (
    <span>
      <i
        role="button"
        onClick={() => {
          if (!isDisabled) toggleFilter();
        }}
        className={classnames('material-icons icon-add_circle', {
          'icon-remove_circle': isExcluding,
          'text-danger': isExcluding,
          [styles.disabled]: isDisabled,
          [styles.pointer]: !isDisabled,
        })}
        data-cy={dataCy + '--exclusion-button'}
      />
    </span>
  );
}

ExclusionButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isExcluding: PropTypes.bool,
  toggleFilter: PropTypes.func.isRequired,
  'data-cy': PropTypes.string,
};

ExclusionButton.defaultProps = {
  isExcluding: false,
  'data-cy': 'adv--filters',
};
export default ExclusionButton;
