import React from 'react';
import PropTypes from 'prop-types';
import { MedicationUtils } from 'common';
import { Source, Tooltip, FlexRow } from '../../../ui';

const Detail = ({
  label,
  value,
  source,
  instance,
  environment,
  potentialBadDates,
}) => {
  let sourceEl;
  if (source)
    sourceEl = (
      <Source source={source} instance={instance} environment={environment} />
    );
  if (value !== undefined) {
    let valueEl;
    if (typeof value === 'number' && value % 1 !== 0)
      valueEl = <p>{MedicationUtils.formatDecimal(value)}</p>;
    else if (value.value === undefined && value.unit === undefined)
      valueEl = <p>{value}</p>;
    else valueEl = <p>{MedicationUtils.formatAmount(value)}</p>;

    return (
      <li>
        <FlexRow
          left={
            <h5>
              {label}
              {potentialBadDates && (
                <span alt="Possible incorrect data">
                  <Tooltip content="Possible incorrect data">
                    <i className="material-icons icon-report_problem" />
                  </Tooltip>
                </span>
              )}
            </h5>
          }
          right={sourceEl}
        />
        {valueEl}
      </li>
    );
  }

  return null;
};

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string,
  instance: PropTypes.string,
  environment: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.number,
    PropTypes.shape({
      unit: PropTypes.string,
      value: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  potentialBadDates: PropTypes.bool,
};

Detail.defaultProps = {
  source: undefined,
  instance: undefined,
  value: undefined,
  environment: undefined,
  potentialBadDates: false,
};

export default Detail;
