/**
 * Determine if an EmployeeBox or PatientBox should render with one or two
 * adjacent columns.
 *
 * @param forPage {string} "activity" or "case"
 * @param caseStore {object} The case store.
 * @param activityStore {object} The activity store.
 * @return {boolean}
 */
export const showTwoColumns = ({ forPage, caseStore, activityStore }) => {
  // If viewing on the case page, we only allow two columns on diversion cases
  // where there is only a user showing.
  if (forPage === 'case') {
    return caseStore.type === 'diversion';
  }

  // On the activity view, we allow two columns if you are on the dedicated user
  // or patient view, or you are on the compare view.
  return !!activityStore.shouldShowBothPersonBoxPages;
};
