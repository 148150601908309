import DataFeedEvaluationView from '../DataFeedEvaluationView';
import { DataFeedContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({ evaluationStore, query, history, location }) {
  return {
    loaded: !evaluationStore.loading,
    failure: evaluationStore.failure,
    failureStatus: evaluationStore.failureStatus,
    requiresAttentionGroups: evaluationStore.requiresAttentionGroups,
    noIssuesGroups: evaluationStore.noIssuesGroups,
    setQueryFromURI: evaluationStore.setQueryFromURI,
    query,
    history,
    location,
  };
}

const DataFeedEvaluationViewContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DataFeedContext,
    mapProps: mapProps,
    PresentationalComponent: DataFeedEvaluationView,
  })
);

export default DataFeedEvaluationViewContainer;
