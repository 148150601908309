import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DropdownList } from 'react-widgets';
import ExclusionMultiselect from '../../../ui/Filters/ExclusionMultiselect';
import { toJS } from 'mobx';
import styles from './index.module.scss';
import {
  ASSESSMENTS_LABEL,
  ASSESSMENTS_TEXT_FIELD,
  ASSESSMENTS_VALUE_FIELD,
} from '../stores/AssessmentsPrintOptionsStore/consts';

const AssessmentsPrintOptions = ({
  selectedMainOption,
  selectedAssessmentsTimeRanges,
  mainOptions,
  timeRangeOptions,
  isCustomizing,
  timeRangesLabel,
  filteredAssessmentsCount,
  handleMainOptionOnChange,
  handleTimeRangesOnChange,
  type,
  hasClusterReasons,
}) => {
  return (
    <div className={styles.dropdownWrapper}>
      <p
        className={styles.label}
      >{`${ASSESSMENTS_LABEL} - ${filteredAssessmentsCount} Matching Assessments`}</p>
      <DropdownList
        data={mainOptions}
        textField={ASSESSMENTS_TEXT_FIELD}
        valueField={ASSESSMENTS_VALUE_FIELD}
        value={selectedMainOption}
        onChange={handleMainOptionOnChange}
      />
      {type === 'diversion' && hasClusterReasons ? (
        <p className={styles.assessmentPrintMsg}>
          Printed Assessments will reflect the Peer Group selection on the
          Caseflow tab
        </p>
      ) : null}
      {isCustomizing && (
        <ExclusionMultiselect
          label={timeRangesLabel}
          options={timeRangeOptions}
          value={toJS(selectedAssessmentsTimeRanges)}
          textField={ASSESSMENTS_TEXT_FIELD}
          valueField={ASSESSMENTS_VALUE_FIELD}
          onChange={handleTimeRangesOnChange}
          hideExclusion
          hideExclusionSpacing
        />
      )}
    </div>
  );
};

AssessmentsPrintOptions.propTypes = {
  selectedMainOption: PropTypes.string,
  mainOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  selectedAssessmentsTimeRanges: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  timeRangeOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  handleMainOptionOnChange: PropTypes.func.isRequired,
  handleTimeRangesOnChange: PropTypes.func.isRequired,
  isCustomizing: PropTypes.bool,
  timeRangesLabel: PropTypes.string,
  filteredAssessmentsCount: PropTypes.number,
  type: PropTypes.string,
  hasClusterReasons: PropTypes.bool,
};

export default AssessmentsPrintOptions;
