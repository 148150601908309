import { computed, decorate, reaction } from 'mobx';
import moment from 'moment';

import { AlertCategoryLogsClient, HalUtils, SingletonStore } from 'common';
import SettingsChartStore from '../SettingsChartStore';

class SettingsLimitStore extends SingletonStore {
  constructor() {
    super();
    reaction(
      () => [SettingsChartStore.category],
      () => {
        if (SettingsChartStore.category) this.refresh();
      }
    );
  }

  fetch() {
    return AlertCategoryLogsClient.findByAlertCategoryIncludeGlobal(
      SettingsChartStore.category
    );
  }

  // Computed
  get results() {
    return HalUtils.getData(this.result) || [];
  }

  // Computed
  get limitsByDate() {
    let preLimit = null;
    let postLimit = null;
    let recentLimit = null;

    // convert alert category logs to [date, limit] data points
    let limits = this.results
      .map(log => {
        if (log.action === 'EDIT_LIMIT') {
          recentLimit =
            log.parameters.newLimit[0] === 'none'
              ? null
              : parseInt(log.parameters.newLimit[0], 10);
          return { x: moment(log.created).toDate(), y: recentLimit };
        } else if (
          log.action === 'DISABLED_CASE_CREATION' ||
          log.action === 'PAUSED_CASE_CREATION'
        ) {
          return { x: moment(log.created).toDate(), y: 0 };
        } else if (
          log.action === 'ENABLED_CASE_CREATION' ||
          log.action === 'RESUMED_CASE_CREATION'
        ) {
          return { x: moment(log.created).toDate(), y: recentLimit };
        }

        return null;
      })
      .filter(data => data !== null);

    // determine what the limit was before and at the edge of the time window
    limits
      .filter(data => moment(data.x).isBefore(SettingsChartStore.dateRange[0]))
      .forEach(data => {
        preLimit = data.y;
      });
    limits
      .filter(data => moment(data.x).isBefore(SettingsChartStore.dateRange[1]))
      .forEach(data => {
        postLimit = data.y;
      });

    // insert the pre-window limit at the start date
    if (preLimit)
      limits.unshift({
        x: SettingsChartStore.dateRange[0].toDate(),
        y: preLimit,
      });
    // insert the post-window limit at the end date
    if (postLimit)
      limits.push({
        x: SettingsChartStore.dateRange[1].toDate(),
        y: postLimit,
      });

    // filter out data points outside of the time window
    limits = limits.filter(data =>
      moment(data.x).isBetween(
        SettingsChartStore.dateRange[0],
        SettingsChartStore.dateRange[1],
        null,
        '[]'
      )
    );

    // Victory has problems if the only y-values are zero and/or null
    return limits.some(data => data.y !== 0 && data.y !== null) ? limits : [];
  }
}

decorate(SettingsLimitStore, {
  results: computed,
  limitsByDate: computed,
});

export default new SettingsLimitStore();
