import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import OtherAlias from '../../people/PersonBox/OtherAlias';
import styles from './index.module.scss';
import VIPAttribute from '../VIPAttribute';
import AuthAccessAttribute from '../../people/PersonBox/AuthAccessAttribute';
import { useFetchVIPs } from '../../stores/PatientVipStore/useFetchVIPs';
import { useFetchPatientAuths } from '../../stores/PatientAuthorizationsStore/useFetchPatientAuths';

const SubPatientBox = ({
  authorizations,
  canManageAuths,
  canManageVIPs,
  canViewAuths,
  canViewVIPs,
  employeeId,
  userLinkForOppositeAlias,
  vipRegistrations,
  patientId,
}) => {
  useFetchPatientAuths(patientId);
  useFetchVIPs(patientId);

  return (
    <div className={styles.subPatientBox} data-cy="patient-box">
      <OtherAlias link={userLinkForOppositeAlias} mode="patient" />
      {canViewVIPs && (
        <VIPAttribute
          canManageVIPs={canManageVIPs}
          patientId={patientId}
          registration={vipRegistrations?.[0]}
        />
      )}
      {canViewAuths && (
        <AuthAccessAttribute
          auths={authorizations}
          canManageAuths={canManageAuths}
          patientId={patientId}
          type="patient"
          userId={employeeId}
        />
      )}
    </div>
  );
};

SubPatientBox.propTypes = {
  authorizations: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  canManageAuths: PropTypes.bool,
  canManageVIPs: PropTypes.bool,
  canViewAuths: PropTypes.bool,
  canViewVIPs: PropTypes.bool,
  employeeId: PropTypes.string,
  userLinkForOppositeAlias: PropTypes.string,
  patientId: PropTypes.string,
  vipRegistrations: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
};

export default SubPatientBox;
