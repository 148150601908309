import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import styles from './index.module.scss';
import { AsidePanel } from '../../ui';

const MRN = 'mrn';
const PATIENT_ID = 'patientId';
const LAST_NAME = 'lastName';
const FIRST_NAME = 'firstName';
const DATE_OF_BIRTH = 'dateOfBirth';
const ADDRESS_1 = 'address1';
const ADDRESS_2 = 'address2';
const CITY = 'city';
const STATE = 'state';
const ZIP = 'zip';

const AdvancedPatientSearchForm = ({
  isActive,
  onClose,
  onSubmit,
  searchFields,
  onSearchFieldChange,
  requiredAdvancedFiltersExist,
}) => {
  const onChange = name => e => onSearchFieldChange(name, e.target.value);
  const getSearchField = (name, defaultValue = '') =>
    searchFields[name] || defaultValue;

  const handleManualDateEntry = e => {
    const text = e.target.value;
    const date = moment(text, ['M/D/YYYY', 'M-D-YYYY'], true);
    if (date.isValid()) onSearchFieldChange(DATE_OF_BIRTH, date.toDate());
  };

  return (
    <AsidePanel
      onClose={onClose}
      title="Advanced Patient Search"
      isOpen={isActive}
      leftAnimation
      actionNavHeight
    >
      <form
        data-cy="patient-advanced-search"
        className={styles.form}
        name="lastpass-disable-search"
        onSubmit={e => {
          e.preventDefault();
          if (requiredAdvancedFiltersExist) onSubmit();
        }}
      >
        <section>
          <h4>
            <i className="material-icons icon-person" />
            <span>Patient Details</span>
          </h4>
          <input
            type="text"
            placeholder="MRN"
            value={getSearchField(MRN)}
            onChange={onChange(MRN)}
          />
          <input
            type="text"
            placeholder="Patient ID"
            value={getSearchField(PATIENT_ID)}
            onChange={onChange(PATIENT_ID)}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={getSearchField(LAST_NAME)}
            onChange={onChange(LAST_NAME)}
          />
          <fieldset disabled={!requiredAdvancedFiltersExist}>
            <input
              type="text"
              placeholder="First Name"
              value={getSearchField(FIRST_NAME)}
              onChange={onChange(FIRST_NAME)}
            />
            <DateTimePicker
              placeholder="Date of Birth"
              format="M/D/YYYY"
              value={getSearchField(DATE_OF_BIRTH, null)}
              onChange={date => onSearchFieldChange(DATE_OF_BIRTH, date)}
              onKeyUp={handleManualDateEntry}
              time={false}
            />
            <input
              type="text"
              placeholder="Address 1"
              value={getSearchField(ADDRESS_1)}
              onChange={onChange(ADDRESS_1)}
            />
            <input
              type="text"
              placeholder="Address 2"
              value={getSearchField(ADDRESS_2)}
              onChange={onChange(ADDRESS_2)}
            />
            <div className={styles.inlineInputs}>
              <input
                type="text"
                placeholder="City"
                className={styles.cityInput}
                value={getSearchField(CITY)}
                onChange={onChange(CITY)}
              />
              <input
                type="text"
                placeholder="State"
                className={styles.stateInput}
                value={getSearchField(STATE)}
                onChange={onChange(STATE)}
              />
              <input
                type="text"
                placeholder="Zip"
                className={styles.zipInput}
                value={getSearchField(ZIP)}
                onChange={onChange(ZIP)}
              />
            </div>
          </fieldset>
        </section>

        <button
          type="submit"
          className="button button--block"
          disabled={!requiredAdvancedFiltersExist}
        >
          Search
        </button>
      </form>
    </AsidePanel>
  );
};

AdvancedPatientSearchForm.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchFields: PropTypes.shape({
    [MRN]: PropTypes.string,
    [PATIENT_ID]: PropTypes.string,
    [LAST_NAME]: PropTypes.string,
    [FIRST_NAME]: PropTypes.string,
    [DATE_OF_BIRTH]: PropTypes.instanceOf(Date),
    [ADDRESS_1]: PropTypes.string,
    [ADDRESS_2]: PropTypes.string,
    [CITY]: PropTypes.string,
    [STATE]: PropTypes.string,
    [ZIP]: PropTypes.string,
  }).isRequired,
  onSearchFieldChange: PropTypes.func.isRequired,
  requiredAdvancedFiltersExist: PropTypes.bool.isRequired,
};

export default AdvancedPatientSearchForm;
