import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import Tooltip from '../../../ui/Tooltip';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export const MetadataCount = ({ currentPath, metadata }) => {
  const handleClick = e => {
    if (!metadata?.link?.pathname || metadata?.link?.pathname === currentPath) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <>
      {metadata?.data?.multilineTooltip ? (
        <Link to={metadata.link} onClick={e => handleClick(e)}>
          <Tooltip
            content={metadata?.data?.multilineTooltipContent?.map(
              (tooltipRow, i) => {
                return (
                  <div
                    className={styles.multilineTooltipRow}
                    key={`${tooltipRow.textLeftCol}${i}`}
                  >
                    <p className={styles.multilineTooltipText}>
                      {tooltipRow.textLeftCol}
                    </p>
                    <p
                      className={classnames(
                        styles.multilineTooltipText,
                        styles.accessAuthTooltipRightCol
                      )}
                    >
                      {tooltipRow.textRightCol}
                    </p>
                  </div>
                );
              }
            )}
          >
            <div className={styles.metadataCount}>
              <i className={`material-icons ${metadata.icon}`}></i>
              <span>{metadata?.data?.count}</span>
            </div>
          </Tooltip>
        </Link>
      ) : (
        <Link to={metadata.link} onClick={e => handleClick(e)}>
          <Tooltip content={metadata?.data?.tooltipContent}>
            <div className={styles.metadataCount}>
              <i className={`material-icons ${metadata.icon}`}></i>
              <span>{metadata?.data?.count}</span>
            </div>
          </Tooltip>
        </Link>
      )}
    </>
  );
};

MetadataCount.propTypes = {
  currentPath: PropTypes.string,
  metadata: PropTypes.object,
};
