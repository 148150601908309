import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, decorate } from 'mobx';

import QrCode from '../QrCode';

const androidSteps = [
  {
    picture: 'assets/img/android/android_1@2x.png',
    description: (
      <span>
        First, open the
        <strong> Play Store </strong>
        app on your phone - by default the Play Store icon is on your Home
        Screen. If not, you can find it with the installed apps on your phone.
      </span>
    ),
  },
  {
    picture: 'assets/img/android/android_2@2x.png',
    description: (
      <span>
        Once of the
        <strong> Play Store </strong>
        opens, type
        <strong> Google Authenticator </strong>
        into the search bar at the top of the screen and select it from the
        results.
      </span>
    ),
  },
  {
    picture: 'assets/img/android/android_3@2x.png',
    description: (
      <span>
        From the
        <strong> Google Authenticator </strong>
        page tap the
        <strong> INSTALL </strong>
        button to install the app.
      </span>
    ),
  },
  {
    picture: 'assets/img/android/android_4@2x.png',
    description: (
      <span>
        Once installation is complete tap
        <strong> OPEN</strong>, or find
        <strong> Google Authenicator </strong>
        with the rest of your installed applications and tap the icon.
      </span>
    ),
  },
  {
    picture: 'assets/img/android/android_5@2x.png',
    description: (
      <span>
        Once the
        <strong> Authenticator </strong>
        app starts, tap the
        <strong> BEGIN </strong>
        button at the bottom of the screen.
      </span>
    ),
  },
  {
    picture: 'assets/img/android/android_6@2x.png',
    description: (
      <span>
        Next, tap
        <strong> Scan barcode </strong>- you may need to grant this app camera
        access to scan the barcode.
      </span>
    ),
  },
  {
    picture: 'qrCode',
    description: (
      <span>
        Point the camera on your phone at the barcode above to scan it.
      </span>
    ),
  },
  {
    picture: 'assets/img/android/android_7@2x.png',
    description: (
      <span>
        Once the barcode is capured you’ll see a sceen like the one above with a
        6-digit verification code. The code resets every 30 seconds. The round
        dial in the right corner represents the timer.
      </span>
    ),
  },
];

const iPhoneSteps = [
  {
    picture: 'assets/img/ios/ios_1@2x.png',
    description: (
      <span>
        First, open the
        <strong> App Store </strong>
        on your iPhone - by default it should be located on your
        <strong> Home Screen</strong>.
      </span>
    ),
  },
  {
    picture: 'assets/img/ios/ios_2@2x.png',
    description: (
      <span>
        Once the
        <strong> App Store </strong>
        opens, tap the
        <strong> Search </strong>
        icon at the bottom of your screen.
      </span>
    ),
  },
  {
    picture: 'assets/img/ios/ios_3@2x.png',
    description: (
      <span>
        At the top of your screen, type
        <strong> Google Authenticator </strong>
        in the search box, then tap the blue
        <strong> Search </strong>
        button at the bottom right of your screen on the keyboard.
      </span>
    ),
  },
  {
    picture: 'assets/img/ios/ios_4@2x.png',
    description: (
      <span>
        You’ll find the
        <strong> Google Authenticator </strong>
        app in the search results, tap the
        <strong> GET </strong>
        button to install it - you may need to enter your iTunes password.
      </span>
    ),
  },
  {
    picture: 'assets/img/ios/ios_5@2x.png',
    description: (
      <span>
        Once installation is complete, head to your
        <strong> Home Screen</strong>, you’ll find the
        <strong> Authenticator App </strong>
        there, tap the icon to open it.
      </span>
    ),
  },
  {
    picture: 'assets/img/ios/ios_6@2x.png',
    description: (
      <span>
        Once the
        <strong> Authenticator app </strong>
        starts, tap
        <strong> BEGIN SETUP </strong>
        at the bottom of the screen.
      </span>
    ),
  },
  {
    picture: 'assets/img/ios/ios_7@2x.png',
    description: (
      <span>
        Next, tap
        <strong> Scan barcode </strong>
        in the popup menu - you may need to grant this app camera access to scan
        the barcode.
      </span>
    ),
  },
  {
    picture: 'qrCode',
    description: (
      <span>
        Point the camera on your phone at the barcode above to scan it.
      </span>
    ),
  },
  {
    picture: 'assets/img/ios/ios_8@2x.png',
    description: (
      <span>
        Once the barcode is capured you’ll see a sceen like the one above with a
        6-digit verification code. The code resets every 30 seconds. The round
        dial in the right corner represents the timer.
      </span>
    ),
  },
];

const MfaInstructions = class MfaInstructions extends React.Component {
  // Observable
  currStep = 0;

  handleBackClick = () => {
    const { toggleInstructionsOff } = this.props;

    if (this.currStep === 0) toggleInstructionsOff();
    else this.currStep -= 1;
  };

  handleForwardClick = () => {
    this.currStep += 1;
  };

  showQrCode(phone) {
    return (
      (phone[this.currStep] && phone[this.currStep].picture === 'qrCode') ||
      this.currStep >= phone.length
    );
  }

  renderQrCode(phone) {
    if (phone[this.currStep] && phone[this.currStep].picture === 'qrCode')
      return <QrCode step="picture" />;
    else if (this.currStep >= phone.length) return <QrCode step="code" />;
    return <img src={phone[this.currStep].picture} alt="mfaInstructions" />;
  }

  renderDescription(phone) {
    if (
      (phone[this.currStep] && phone[this.currStep].picture === 'qrCode') ||
      this.currStep >= phone.length
    )
      return (
        <p className="description">
          {phone[this.currStep] && phone[this.currStep].description}
        </p>
      );
    return <p className="description">{phone[this.currStep].description}</p>;
  }

  render() {
    const { showIos } = this.props;

    const phoneType = showIos ? iPhoneSteps : androidSteps;
    const phone = showIos ? 'Apple iPhone' : 'Android';
    let step;
    if (
      phoneType[this.currStep] &&
      phoneType[this.currStep].picture === 'qrCode'
    )
      step = 'picture';
    else if (this.currStep >= phoneType.length) step = 'code';

    return (
      <div>
        {this.showQrCode(phoneType) ? (
          <QrCode
            type="step"
            step={step}
            phone={phone}
            onBackClick={this.handleBackClick}
            nextValue={step === 'picture' ? 'Next' : 'Verify'}
            onNextClick={this.handleForwardClick}
            {...this.props}
          >
            {step === 'picture' ? (
              <p className="barcode">
                Point the camera on your phone at the barcode above to scan it.
              </p>
            ) : (
              <p className="mfa-code">
                Enter your current 6-digit code from the
                <strong> Authenticator </strong>
                app into the
                <strong> Verification Code </strong>
                field below - then click the
                <strong> Verify </strong>
                button below before the code resets.
              </p>
            )}
          </QrCode>
        ) : (
          <div>
            <h1>
              2-Step Verification
              <span className="normal-weight"> {phone}</span>
            </h1>
            <div key={this.currStep} className="mfa-wrapper-step">
              {this.renderQrCode(phoneType)}
              {this.renderDescription(phoneType)}
              <p className="mfa-buttons">
                <input
                  className="button button-back"
                  value="Back"
                  onClick={this.handleBackClick}
                  type="button"
                />
                <input
                  className="button"
                  value="Next"
                  onClick={this.handleForwardClick}
                  type="button"
                />
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
};

decorate(MfaInstructions, {
  currStep: observable,
});

MfaInstructions.propTypes = {
  showIos: PropTypes.bool,
  toggleInstructionsOff: PropTypes.func,
};

MfaInstructions.displayName = 'MfaInstructions';

export default observer(MfaInstructions);
