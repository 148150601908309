import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Anchor from '../Anchor';
import moment from 'moment';
import Tooltip from '../Tooltip';

export const Order = ({ id = '', 'data-cy': dataCy }) => {
  if (id.length < 15) return <td data-cy={dataCy}>{id}</td>;
  return (
    <Tooltip followCursor content={id}>
      <td data-cy={dataCy}>{`${id.slice(0, 14)}...`}</td>
    </Tooltip>
  );
};

Order.propTypes = {
  'data-cy': PropTypes.string,
  id: PropTypes.string,
};

const EventRow = ({ date, order, action, detail, link, timezone }) => {
  return (
    <tr className={styles.printTable}>
      <td className={styles.dateColumn}>
        <Anchor tag="a" href={link} data-cy="incident-card-created-date">
          {moment(date)
            .tz(timezone)
            .format('MM/DD/YYYY HH:mm:ss z')}
        </Anchor>
      </td>
      <Order data-cy="incident-card-order-id" id={order} />
      <td>{action}</td>
      <td>{detail}</td>
    </tr>
  );
};

EventRow.propTypes = {
  date: PropTypes.string,
  order: PropTypes.string,
  action: PropTypes.string,
  detail: PropTypes.string,
  link: PropTypes.string,
  timezone: PropTypes.string,
};

EventRow.defaultProps = {
  date: '',
  order: '',
  action: '',
  detail: '',
  link: '/',
};

export default EventRow;
