import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Circle from '../Circle';
import Header from '../Header';
import { colorDefaults, presetDefaults, presetPropTypes } from './propTypes';
import styles from '../index.module.scss';

const Overrides = ({ topText, bottomText, extraText, color, materialIcon }) => (
  <div className={styles.wrapper}>
    <Circle color={color}>
      <i className={classnames('material-icons', materialIcon, styles.icon)} />
    </Circle>
    <Header topText={topText} bottomText={bottomText} extraText={extraText} />
  </div>
);

Overrides.propTypes = {
  ...presetPropTypes,
  materialIcon: PropTypes.string,
};

Overrides.defaultProps = {
  ...presetDefaults,
  color: colorDefaults.secondary,
  materialIcon: 'icon-dns',
};

export default Overrides;
