import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';
import styles from './index.module.scss';

/* Note: do not use the class "no-wrap" in the column that renders this cell - it will override the styles */
const DoubleLineCell = ({
  className,
  hidePadding,
  lineOne,
  lineTwo,
  'data-cy': dataCy,
}) => (
  <Cell className={classnames(styles.wrapper, className)}>
    <div
      className={classnames(styles.content, {
        [styles.hidePadding]: hidePadding,
      })}
      data-cy={dataCy}
    >
      <div data-cy="vip-name">{lineOne}</div>
      <div className={classnames(styles.lineTwo, className)}>{lineTwo}</div>
    </div>
  </Cell>
);

export default DoubleLineCell;

DoubleLineCell.propTypes = {
  className: PropTypes.string,
  hidePadding: PropTypes.bool,
  lineOne: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  'data-cy': PropTypes.string,
  lineTwo: PropTypes.string,
};

DoubleLineCell.defaultProps = {
  className: '',
  hidePadding: false,
  lineOne: '',
  lineTwo: '',
};
