import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BulkProgressButton from '../../BulkProgressButton';
import BulkCaseActionHelpText from '../BulkCaseActionHelpText';

class BulkCaseActionFormActions extends React.Component {
  static propTypes = {
    buttonActionType: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    buttonType: PropTypes.string,
    helpText: PropTypes.string,
    hidden: PropTypes.bool,
    isList: PropTypes.bool,
    onClick: PropTypes.func,
    store: PropTypes.shape({
      performBulkAction: PropTypes.func.isRequired,
      performingBulkAction: PropTypes.bool.isRequired,
      percentComplete: PropTypes.number.isRequired,
    }),
  };

  renderButton() {
    const {
      buttonActionType,
      buttonDisabled,
      buttonType,
      hidden,
      onClick,
      store,
    } = this.props;

    return (
      <BulkProgressButton
        action={buttonActionType}
        store={store}
        hidden={hidden}
        disabled={buttonDisabled}
        type={buttonType || 'submit'}
        onClick={onClick}
      />
    );
  }

  renderHelpText() {
    const { helpText, hidden, store } = this.props;

    return (
      <BulkCaseActionHelpText
        store={store}
        hidden={hidden}
        helpText={helpText}
      />
    );
  }

  render() {
    const { isList } = this.props;

    let node;
    if (isList) {
      node = (
        <li>
          {this.renderButton()}
          {this.renderHelpText()}
        </li>
      );
    } else {
      node = (
        <div>
          {this.renderButton()}
          {this.renderHelpText()}
        </div>
      );
    }

    return node;
  }
}

export default observer(BulkCaseActionFormActions);
