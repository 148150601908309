import { action, autorun, computed, decorate, observable } from 'mobx';

import { PagedStore, EnumValueStore, PersonTagsClient } from 'common';
import PersonTagsCountStore from '../../../stores/PersonTagsCountStore';
import UserTagInfoStore from '../UserTagInfoStore';
import Store from '../../../../activity/stores/Store';

class UsersByTagStore extends PagedStore {
  constructor() {
    super();

    autorun(() => {
      this.refresh();
      if (this.activeUserId) UserTagInfoStore.fetch(this.activeUserId);
    });
  }

  // Observables
  activeUserId = null;
  tagId = null;

  setValue = (type, val) => {
    this[type] = val;
  };

  // Computed
  get tagInfoId() {
    return UserTagInfoStore.tagInfoByUserId.get(this.activeUserId);
  }

  // Computed
  get activeUser() {
    const tag = this.results.find(
      r => r.user && r.user.id === this.activeUserId
    );

    if (tag) return tag.user;
    else return null;
  }

  removeCurrentTag() {
    // grab all tags for the user
    const { tags } = this.activeUser;

    // create storage for tags to keep
    let remainingTags;

    if (tags) {
      remainingTags = tags.filter(tag => tag.id !== this.tagId);
    }

    return PersonTagsClient.assign(this.tagInfoId, {
      user: this.activeUserId,
      tags: remainingTags,
    }).then(() => {
      EnumValueStore.refresh();
      PersonTagsCountStore.refresh();
      Store.refresh();
      this.refresh();
    });
  }

  fetch() {
    if (!this.tagId) return [];
    return PersonTagsClient.getPeopleByTag(this.tagId);
  }
}

decorate(UsersByTagStore, {
  activeUserId: observable,
  tagId: observable,
  tagInfoId: computed,
  activeUser: computed,
  setValue: action,
});

export default new UsersByTagStore();
