import React from 'react';
import Store from '../../activity/stores/Store';
import { Link } from 'react-router-dom';
import { FullName } from 'common';
import PropTypes from 'prop-types';
import RenderUsers from './RenderUsers';

const EncounterLinks = ({ encounter }) => {
  const generateLinks = encounter => {
    const seenKey = {};
    if (encounter) {
      return encounter
        .filter(e => Boolean(e))
        .sort((a, b) => {
          if (a.lastName < b.lastName) return -1;
          else if (a.lastName > b.lastName) return 1;
          return 0;
        })
        .map(e => {
          const ref = Store.getUserLink({ userId: e.id });
          return (
            <Link key={e.id} to={ref}>
              <FullName person={e} condensed />
            </Link>
          );
        })
        .filter(link =>
          // remove duplicates based on the key
          Object.prototype.hasOwnProperty.call(seenKey, link.key)
            ? false
            : (seenKey[link.key] = true)
        );
    }
  };

  const empNames = generateLinks(encounter.users);
  const providers = generateLinks(encounter?.providers);

  return (
    <>
      <RenderUsers users={empNames} label="Viewed By" />
      <RenderUsers users={providers} label="Providers" />
    </>
  );
};

EncounterLinks.propTypes = {
  encounter: PropTypes.object,
};

export default EncounterLinks;
