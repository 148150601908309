import React from 'react';
import PropTypes from 'prop-types';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';
import Checkbox from '../Checkbox';

class ToggleCell extends React.Component {
  static propTypes = {
    option: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      selected: PropTypes.bool,
    }),
    store: PropTypes.shape({
      toggleSelect: PropTypes.func.isRequired,
      isSelected: PropTypes.func.isRequired,
    }),
  };

  // Observable
  selected = true;

  componentDidMount() {
    this.selected = this.props.option && Boolean(this.props.option.selected);
  }

  toggleChecked = e => {
    e.preventDefault();
    e.stopPropagation();
    const { store, option } = this.props;
    store.toggleSelect(option);
  };

  render() {
    const { option, store } = this.props,
      selected = store.isSelected(option);

    return (
      <Cell
        data-cy="vip-checkbox"
        className={classnames({ selected })}
        onClick={this.toggleChecked}
      >
        <Checkbox checked={selected} onChange={this.toggleChecked} />
      </Cell>
    );
  }
}

decorate(ToggleCell, {
  selected: observable,
});

export default observer(ToggleCell);
