import React from 'react';
import PropTypes from 'prop-types';
import EncountersList from '../EncountersList';
import BoxSection from '../../people/PersonBox/BoxSection';

const Encounters = ({ futureEncounters, pastEncounters }) => {
  return (
    <BoxSection>
      <h2>Encounters</h2>
      <h3>
        Future Encounters{' '}
        <span className="text-subtle">{futureEncounters.length}</span>
      </h3>
      {futureEncounters.length === 0 ? (
        <em className="muted">No Future Encounters</em>
      ) : (
        <EncountersList encounters={futureEncounters} />
      )}
      <h3>
        Past Encounters{' '}
        <span className="text-subtle">{pastEncounters.length}</span>
      </h3>
      {pastEncounters.length === 0 ? (
        <em className="muted">No Past Encounters</em>
      ) : (
        <EncountersList encounters={pastEncounters} />
      )}
    </BoxSection>
  );
};

Encounters.propTypes = {
  futureEncounters: PropTypes.array,
  pastEncounters: PropTypes.array,
};

Encounters.defaultProps = {
  futureEncounters: [],
  pastEncounters: [],
};

export default Encounters;
