import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { capitalizeFirstLetter } from 'common';
import SideList from '../../ui/SideList';
import ListItem from '../../ui/SideList/ListItem';
import styles from './index.module.scss';
import Field from '../../ui/Field';
import Address from '../Address';
import ViaSource from '../../ui/ViaSource';
import { ShowHideMore } from '../../ui';

const Contacts = ({ contacts }) => (
  <div className={styles.container}>
    <h2>
      Contacts <span className="text-subtle">{contacts.length}</span>
    </h2>
    <ShowHideMore numberToShow={2} label="Contact">
      {contacts.length === 0 ? (
        <em className="muted">No Contacts</em>
      ) : (
        contacts
          .slice()
          .reverse()
          .map(contact => (
            <div key={Math.random()} className={styles.contactCard}>
              <div>
                <Field
                  label="Contact"
                  width="inherit"
                  value={contact.fullName}
                />
                <ViaSource source={contact.source} />
                <SideList>
                  {contact.relationship && (
                    <ListItem
                      label="Relationship"
                      value={capitalizeFirstLetter(contact.relationship)}
                    />
                  )}
                  {contact.phoneNumbers
                    ? contact.phoneNumbers.map(c => {
                        return (
                          <ListItem
                            key={Math.random()}
                            label={`${capitalizeFirstLetter(c.type)} Phone`}
                            value={c.number}
                          />
                        );
                      })
                    : null}
                  {contact.addresses
                    ? contact.addresses.map(address => {
                        return (
                          <Address key={Math.random()} address={address} />
                        );
                      })
                    : null}
                </SideList>
              </div>
            </div>
          ))
      )}
    </ShowHideMore>
  </div>
);

Contacts.propTypes = {
  contacts: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      source: PropTypes.string,
      relationship: PropTypes.string,
      phoneNumbers: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
      addresses: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
    })
  ),
};

Contacts.defaultProps = {
  contacts: [],
};

export default Contacts;
