import React from 'react';
import { observer } from 'mobx-react';

import { PermissionStore, RouterContainer } from 'common';
import CaseBundleStore from '../../../stores/CaseBundleStore';

import CaseAuthorizationsStore from '../../stores/CaseAuthorizationsStore';
import CaseViewAside from '../CaseViewAside';
import CaseStore from '../../stores/CaseStore';
import { CaseIntegrationStore } from '../../../settings/stores/CaseIntegrationStore';

export function mapProps(
  CaseStore,
  CaseAuthorizationsStore,
  CaseBundleStore,
  caseBundleIndicatorStore,
  CaseIntegrationStore,
  PermissionStore,
  RouterContainer
) {
  const {
    canDelete,
    canViewAuths,
    canSync,
    created,
    editReason,
    lastModified,
    patientFullName,
    patientId,
    personLink,
    readOnly,
    type,
    userFullName,
    userId,
  } = CaseStore;

  return {
    canViewAuths: canViewAuths,
    permissionsCount:
      CaseAuthorizationsStore.auths.length +
      CaseAuthorizationsStore.permissions.length,
    bundlesCount: caseBundleIndicatorStore?.bundles?.length,
    bundles: caseBundleIndicatorStore?.bundles,
    bundlesRefresh: () => caseBundleIndicatorStore.refresh(),
    canViewBundles: PermissionStore.has('BUNDLE_VIEW'),
    canDelete: canDelete,
    canSync: canSync,
    caseCreated: created,
    caseLastModified: lastModified,
    caseType: type,
    onDeleteCase: value =>
      CaseStore.delete(value).then(() => RouterContainer.go('/cases')),
    creatingCase: type === 'creating',
    editReason: editReason,
    userLink: personLink('user'),
    patientLink: personLink('patient'),
    patientId: patientId,
    patientFullName: patientFullName,
    readOnly: readOnly,
    userId: userId,
    userFullName: userFullName,
    otherUserCases: CaseStore.otherUserCases,
    caseIntegration: CaseIntegrationStore.caseIntegration,
  };
}

const CaseViewAsideContainer = observer(({ caseBundleIndicatorStore }) => (
  <CaseViewAside
    {...mapProps(
      CaseStore,
      CaseAuthorizationsStore,
      CaseBundleStore,
      caseBundleIndicatorStore,
      CaseIntegrationStore,
      PermissionStore,
      RouterContainer
    )}
  />
));

CaseViewAsideContainer.displayName = 'CaseViewAsideContainer';

export default CaseViewAsideContainer;
