import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { SingleViewContext } from '../../../Context';
import MultiRecordInformationDropdown from '../../../../ui/MultiRecordInformationDropdown';
import {
  employeeDropdownMapProps,
  employeeSecondDropdownMapProps,
} from '../../mapProps';

export const SingleViewEmployeeDropdownContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: SingleViewContext,
    mapProps: employeeDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);

export const SingleViewEmployeeDropdownContainerSecond = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: SingleViewContext,
    mapProps: employeeSecondDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);
