import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.scss';

export const PrintHeader = ({ showGeneratedTime }) => {
  return (
    <div className={classnames('print-only', styles.printHeaderContainer)}>
      <img
        className={classnames('float-left', styles.logo)}
        src="assets/img/logo.png"
        alt="Protenus"
      />
      {showGeneratedTime && (
        <p className="float-right">
          Report Generated
          <strong>{` ${moment().format('LL')}`}</strong>
        </p>
      )}
    </div>
  );
};

PrintHeader.propTypes = {
  showGeneratedTime: PropTypes.bool.isRequired,
};

export default PrintHeader;
