import CaseSummary from '../CaseSummary';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { CaseReportsContext } from '../Context';

export function mapProps({
  caseReportsCaseResolutionsStore,
  caseReportsFilterSelectionsStore,
  isPrinted,
}) {
  return {
    casesBy: caseReportsFilterSelectionsStore.casesBy,
    casesTotal: caseReportsCaseResolutionsStore.casesTotal,
    isPrinted: isPrinted,
    reportSettingsDateAfter:
      caseReportsFilterSelectionsStore.reportSettingsDateAfter,
    reportSettingsDateBefore:
      caseReportsFilterSelectionsStore.reportSettingsDateBefore,
    reportSettingsDateRange:
      caseReportsFilterSelectionsStore.reportSettingsDateRange,
  };
}

const CaseSummaryContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseReportsContext,
    mapProps,
    PresentationalComponent: CaseSummary,
  })
);

CaseSummaryContainer.displayName = 'CaseSummaryContainer';

export default CaseSummaryContainer;
