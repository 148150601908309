import {
  INCIDENT_IDS_LABEL,
  INCIDENT_STATUSES_ALL,
  INCIDENT_STATUSES_LABEL,
  INCIDENT_TIME_RANGES_ALL,
  INCIDENT_TIME_RANGES_LABEL,
  INCIDENT_TYPES_ALL,
  INCIDENT_TYPES_LABEL,
  INCIDENTS_ALL,
  INCIDENTS_CUSTOM,
  INCIDENTS_ID,
  INCIDENTS_ID_PLACEHOLDER,
  INCIDENTS_NONE,
} from './consts';
import { action, computed, decorate, observable } from 'mobx';
import moment from 'moment';

class IncidentsPrintOptionsStore {
  constructor({
    incidentsFromCase,
    incidentTypeFilterOptions,
    incidentStatusFilterOptions,
    incidentRangeFilterOptions,
  }) {
    this.incidentsFromCase = incidentsFromCase;
    this.incidentTypeFilterOptions = incidentTypeFilterOptions;
    this.incidentStatusFilterOptions = incidentStatusFilterOptions;
    this.incidentRangeFilterOptions = incidentRangeFilterOptions;
  }
  // observables
  selectedMainOption = INCIDENTS_ALL;
  incidentsFromCase = null;
  incidentIdsToInclude = [];
  selectedIncidentTimeRanges = [];
  selectedIncidentTypes = [];
  selectedIncidentStatuses = [];

  // statics
  allMainOptions = [
    { name: 'All', value: INCIDENTS_ALL },
    { name: 'Custom', value: INCIDENTS_CUSTOM },
    { name: 'Incident ID', value: INCIDENTS_ID },
    { name: 'None', value: INCIDENTS_NONE },
  ];

  teardown = () => {
    this.selectedMainOption = INCIDENTS_ALL;
    this.incidentsFromCase = null;
    this.incidentIdsToInclude = [];
    this.selectedIncidentTimeRanges = [];
    this.selectedIncidentTypes = [];
    this.selectedIncidentStatuses = [];
  };

  get mainOptions() {
    return this.allMainOptions;
  }

  get incidentIdOptions() {
    return (this.incidentsFromCase || [])
      .map(({ incidentId }) => ({
        name: incidentId,
        value: incidentId,
      }))
      .filter(
        ({ value }) => value && !this.incidentIdsToInclude.includes(value)
      );
  }

  get timeRangeOptions() {
    return (this.incidentRangeFilterOptions || []).filter(
      ({ value }) => value && !this.selectedIncidentTimeRanges.includes(value)
    );
  }

  get typeOptions() {
    return (this.incidentTypeFilterOptions || [])
      .filter(({ category }) => category !== 'Medications')
      .filter(
        ({ value }) => value && !this.selectedIncidentTypes.includes(value)
      );
  }

  get statusOptions() {
    return (this.incidentStatusFilterOptions || []).filter(
      ({ value }) => value && !this.selectedIncidentStatuses.includes(value)
    );
  }

  get isCustomizing() {
    return this.selectedMainOption === INCIDENTS_CUSTOM;
  }

  get isSelectingIncidentIds() {
    return this.selectedMainOption === INCIDENTS_ID;
  }

  get incidentIdLabel() {
    return this.incidentIdsToInclude.length
      ? INCIDENT_IDS_LABEL
      : INCIDENTS_ID_PLACEHOLDER;
  }

  get timeRangesLabel() {
    return this.selectedIncidentTimeRanges.length
      ? INCIDENT_TIME_RANGES_LABEL
      : INCIDENT_TIME_RANGES_ALL;
  }

  get statusesLabel() {
    return this.selectedIncidentStatuses.length
      ? INCIDENT_STATUSES_LABEL
      : INCIDENT_STATUSES_ALL;
  }

  get typesLabel() {
    return this.selectedIncidentTypes.length
      ? INCIDENT_TYPES_LABEL
      : INCIDENT_TYPES_ALL;
  }

  get filteredIncidentsForPrint() {
    const {
      incidentsFromCase,
      selectedMainOption,
      incidentIdsToInclude,
      selectedIncidentTypes,
      selectedIncidentStatuses,
      selectedIncidentTimeRanges,
    } = this;

    if (selectedMainOption === INCIDENTS_ALL) return incidentsFromCase;
    if (selectedMainOption === INCIDENTS_NONE) return [];

    // query by incidentId field
    if (selectedMainOption === INCIDENTS_ID && !incidentIdsToInclude.length)
      return incidentsFromCase;
    if (selectedMainOption === INCIDENTS_ID && incidentIdsToInclude.length)
      return (incidentsFromCase || []).filter(inc => {
        return incidentIdsToInclude
          .map(id => id.value)
          .includes(inc.incidentId);
      });

    // query by status, type and time ranges
    const typeQuery = selectedIncidentTypes.map(type => type.value);
    const statusQuery = selectedIncidentStatuses.map(status => status.value);
    const timeRangeQuery = selectedIncidentTimeRanges.map(range => range.value);

    let matched = [...incidentsFromCase];

    if (timeRangeQuery.length && matched.length)
      matched = matched.filter(inc =>
        timeRangeQuery.find(range => {
          const ranges = range ? range.split(',') : [];
          return (
            moment(inc.startTime).isSameOrAfter(
              moment(ranges[0]).startOf('d')
            ) &&
            moment(inc.endTime).isSameOrBefore(
              moment(ranges[1])
                .subtract(1, 'd')
                .endOf('d')
            )
          );
        })
      );

    if (typeQuery.length && matched.length)
      matched = matched.filter(inc => typeQuery.includes(inc.type));

    if (statusQuery.length && matched.length)
      matched = matched.filter(inc =>
        statusQuery.includes(
          inc.computedResolution ? inc.computedResolution : 'UNRESOLVED'
        )
      );

    return matched;
  }

  get filteredIncidentsCount() {
    return (this.filteredIncidentsForPrint || []).length;
  }

  handleMainOptionOnChange = opt => (this.selectedMainOption = opt.value);

  setIncidentIdsToInclude = values => (this.incidentIdsToInclude = values);

  handleTimeRangesOnChange = values =>
    (this.selectedIncidentTimeRanges = values);

  handleTypesOnChange = values => (this.selectedIncidentTypes = values);

  handleStatusesOnChange = values => (this.selectedIncidentStatuses = values);

  setIncidentsFromCase = incidents => (this.incidentsFromCase = incidents);
}

decorate(IncidentsPrintOptionsStore, {
  selectedMainOption: observable,
  incidentsFromCase: observable,
  incidentIdsToInclude: observable,
  selectedIncidentTimeRanges: observable,
  selectedIncidentTypes: observable,
  selectedIncidentStatuses: observable,
  mainOptions: computed,
  incidentIdOptions: computed,
  isCustomizing: computed,
  isSelectingIncidentIds: computed,
  timeRangeOptions: computed,
  statusOptions: computed,
  typeOptions: computed,
  incidentIdLabel: computed,
  timeRangesLabel: computed,
  statusesLabel: computed,
  typesLabel: computed,
  filteredIncidentsForPrint: computed,
  filteredIncidentsCount: computed,
  handleMainOptionOnChange: action,
  setIncidentIdsToInclude: action,
  handleStatusesOnChange: action,
  handleTimeRangesOnChange: action,
  handleTypesOnChange: action,
  setIncidentsFromCase: action,
});

export { IncidentsPrintOptionsStore };
