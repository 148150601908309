import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DateHelpers, Pandy } from 'common';
import $ from 'jquery';
import Loader from '../../ui/Loader';
import StaggeredList from '../../ui/StaggeredList';
import BundleLI from '../BundleLI';
import ActionNav from '../../navbar/ActionNav';
import { ApplyBodyClassName, DocumentTitle } from '../../ui';
import { useReactiveRef } from '../../utils/hooks';

const Bundles = ({
  allBundles,
  failure,
  hasMore,
  loading,
  setQueryFromURL,
  query,
}) => {
  const [scrollRef, setScrollRef] = useReactiveRef();
  const timezone = DateHelpers.getCurrentAppUserTimezone();

  useEffect(() => {
    if (!$.isEmptyObject(query)) setQueryFromURL(query);
  }, [query, setQueryFromURL]);

  return (
    <div className="bundles--view">
      <DocumentTitle text="Case Bundles" />
      <ApplyBodyClassName className="bundles" />
      <ActionNav secondary title="Case Bundle" count={allBundles.length} />
      <div className="bundles view-content" ref={setScrollRef}>
        <div className="row no-gutter">
          <div className="col-xs-10 col-xs-offset-1">
            <Loader loaded={!loading}>
              <Pandy visible={!loading && allBundles.length === 0} mood="happy">
                <div className="speechBubble">
                  <span>No case bundles. Sorry!</span>
                </div>
              </Pandy>
              <Pandy visible={Boolean(failure)} mood="sad">
                <div className="speechBubble">
                  <span>Uh oh...</span>
                </div>
                <p>{failure}</p>
              </Pandy>
              {failure ? <p className="error">{failure}</p> : null}
              <StaggeredList
                className="card_holder"
                drag={0}
                pandyText="Case Bundles"
                pandyHasMore={hasMore}
                useSmallPandy
                toAdd="fadeIn"
                parentNodeRef={scrollRef}
              >
                {allBundles.map(bundle => (
                  <BundleLI
                    key={bundle.id}
                    bundle={bundle}
                    query={query}
                    timezone={timezone}
                  />
                ))}
              </StaggeredList>
            </Loader>
          </div>
        </div>
      </div>
    </div>
  );
};

Bundles.propTypes = {
  allBundles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  failure: PropTypes.bool,
  setQueryFromURL: PropTypes.func,
  query: PropTypes.shape({}),
};

Bundles.displayName = 'Bundles';
export default Bundles;
