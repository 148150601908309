/**
 * Capitalize the first letter of a string.
 *
 * @param s {string} The string to capitalize.
 * @return {string}
 */
export function capitalizeFirstLetter(s) {
  var _s;
  s = (_s = s) !== null && _s !== void 0 ? _s : '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Converts a string from camelCase to a spaced formatted string. e.g. camelCase -> Camel Case
 *
 * @param s {string} The string to convert from camelCase
 */
export function fromCamelCase(s) {
  if (/^[A-Z]*$/.test(s) || /\s/.test(s)) return s;
  return s.charAt(0).toUpperCase() + s.slice(1).replace(/([A-Z])/g, ' $1');
}

/**
 * Pluralize a term if needed.
 *
 * @param numItems {number}     If 1, use singular term. Otherwise use plural term.
 * @param singularTerm {string} Singular term.
 * @param [pluralTerm] {string} Plural term. If missing, we add "s" to the singular term.
 *
 * @return {string} Term in correct form.
 */
export function pluralize(numItems, singularTerm, pluralTerm) {
  if (!pluralTerm) pluralTerm = singularTerm + 's';
  return !numItems || numItems > 1 ? pluralTerm : singularTerm;
}