import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { DropdownList } from 'react-widgets';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';

import { Authenticated } from 'common';

import {
  BulkActionsAside,
  BulkActionsHeader,
  RegistryContainer,
} from '../../ui';

import List from '../List';
import ExpireOption from '../BulkActionsOptions/Expire';
import ModifyOption from '../BulkActionsOptions/Modify';
import ModifyReasonOption from '../BulkActionsOptions/Reason';
import listStyles from '../ListView/index.module.scss';
import styles from './index.module.scss';

// these can be used later for matching
const SELECT = 'Select An Action';
const MODIFY = 'Modify Expirations';
const EXPIRE = 'Expire Registrations';
const REASON = 'Change Registration Reasons';

const actionOptions = [SELECT, MODIFY, EXPIRE, REASON];

const BulkActionsView = ({
  advancedFiltersVisible,
  onExpire,
  onModify,
  onReason,
  query,
  setFilters,
  size,
  storeForRegistry,
  bulkActionComplete,
}) => {
  useEffect(() => {
    setFilters(query);
  }, [query, setFilters]);

  const [currentAction, setCurrentAction] = useState(actionOptions[0]);

  if (bulkActionComplete) {
    return <Redirect to={`/vips?${$.param(query)}`} />;
  }

  return (
    <div className="vip--view">
      <div className={listStyles.mainPanel}>
        <Authenticated permission="VIP_REGISTRATION_SEARCH_ALL">
          <RegistryContainer
            store={storeForRegistry}
            className={classnames('registry', styles.bulkVipActions)}
            pandyText={`VIP Registrations`}
          >
            <List
              vipsStore={storeForRegistry}
              advancedFiltersVisible={advancedFiltersVisible}
              showCheckboxes
            />
          </RegistryContainer>
        </Authenticated>
      </div>
      <BulkActionsAside>
        <BulkActionsHeader
          item="Vip Registration"
          icon="icon-star"
          size={size}
        />
        <div className={styles.contentWrapper}>
          {size === 0 ? (
            <span>
              Please select at least one VIP registration to perform an action.
            </span>
          ) : (
            <>
              <DropdownList
                data={actionOptions}
                onChange={value => setCurrentAction(value)}
                value={currentAction}
              />
              {currentAction === MODIFY && <ModifyOption modify={onModify} />}
              {currentAction === EXPIRE && <ExpireOption expire={onExpire} />}
              {currentAction === REASON && (
                <ModifyReasonOption onSubmit={onReason} />
              )}
              <p className={styles.bottomText}>
                These changes will be applied to all selected VIP records.
              </p>
            </>
          )}
        </div>
      </BulkActionsAside>
    </div>
  );
};

BulkActionsView.propTypes = {
  advancedFiltersVisible: PropTypes.bool,
  onExpire: PropTypes.func.isRequired,
  onReason: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  query: PropTypes.shape({}),
  setFilters: PropTypes.func.isRequired,
  storeForRegistry: PropTypes.shape({}).isRequired,
  size: PropTypes.number,
  bulkActionComplete: PropTypes.bool.isRequired /**/,
};

export default BulkActionsView;
