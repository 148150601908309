import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';

const reason = PropTypes.shape({
  description: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  statistics: PropTypes.shape({
    mean: PropTypes.number.isRequired,
    zscore: PropTypes.number.isRequired,
  }).isRequired,
});

const incident = PropTypes.shape({
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  description: PropTypes.string,
});

const statisticPeriod = PropTypes.shape({
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  reasons: mobxPropTypes.arrayOrObservableArrayOf(reason).isRequired,
  suspicionScore: PropTypes.number.isRequired,
  // Minimal user representation, not full user model.
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    fullName: PropTypes.string,
  }).isRequired,
  incidents: mobxPropTypes.arrayOrObservableArrayOf(incident).isRequired,
  selected: PropTypes.bool,
});

export default { reason, incident, statisticPeriod };
