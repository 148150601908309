import { reaction, computed, decorate } from 'mobx';

import { CaseBundleClient, SingletonStore } from 'common';

class CaseBundleFilterOptionsStore extends SingletonStore {
  constructor({ groupStore }) {
    super();

    reaction(
      () => groupStore.activeGroups,
      () => {
        this.refresh();
      }
    );
  }

  get allBundles() {
    return this.result || [];
  }

  /**
   * Fetches all bundles as a minimum projection. There is no paging.
   * @return {Promise}
   */
  fetch() {
    return CaseBundleClient.getAllMin();
  }
}

decorate(CaseBundleFilterOptionsStore, {
  allBundles: computed,
});

export default CaseBundleFilterOptionsStore;
