import { useState, useEffect } from 'react';

export function useToggled(visible) {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    if (visible) setToggled(true);
  }, [visible]);

  return toggled;
}

export function useOptions(isUserFocus, hasPermission) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (isUserFocus) {
      // user is in left pane, so y labels will be for patients
      setOptions([
        { id: 'dod', label: 'Date of Death' },
        { id: 'mrn', label: 'MRN' },
        { id: 'dob', label: 'Patient Age' },
        { id: 'patientId', label: 'Patient Id' },
        { id: 'name', label: 'Patient Name' },
        { id: 'sex', label: 'Sex' },
      ]);
    } else {
      // patient is in left pane, so y labels are for users
      setOptions([
        { id: 'organization', label: 'Organization' },
        { id: 'title', label: 'Title' },
        { id: 'id', label: 'User ID' },
        { id: 'name', label: 'User Name' },
      ]);
    }

    if (hasPermission) {
      setOptions(options => [
        ...options,
        {
          id: 'suspicionScore',
          label: 'Privacy Suspicion Score',
        },
      ]);
    }
  }, [isUserFocus, hasPermission]);

  return options;
}

export function useTooltip(focus, patientId, userId) {
  const [tooltip, setTooltip] = useState('');

  useEffect(() => {
    if (focus === 'employee_accesses') setTooltip('Export User Only Events');
    else if (!patientId) setTooltip('Export All User Events');
    else if (!userId) setTooltip('Export All Patient Events');
    else setTooltip('Export Events Between User and Patient');
  }, [focus, patientId, userId]);

  return tooltip;
}

export function useSortOrder(storeSort, setSortOrder) {
  const [newSortOrder, setNewSortOrder] = useState(0);

  useEffect(() => {
    if (storeSort.split(',')[0] === 'startTime') setNewSortOrder(0);
    else if (storeSort.split(',')[1] === 'asc') setNewSortOrder(1);
    else setNewSortOrder(2);
    if (setSortOrder) setSortOrder(newSortOrder);
  }, [newSortOrder, storeSort, setSortOrder]);

  return newSortOrder;
}
