import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

export const Circle = ({ status = 'disabled', dataCy }) => (
  <div
    className={classnames(styles.circle, {
      [styles.danger]: status === 'danger',
      [styles.warning]: status === 'warning',
      [styles.success]: status === 'success',
      [styles.disabled]: status === 'disabled',
    })}
    data-cy={dataCy}
  />
);

Circle.propTypes = {
  status: PropTypes.string.isRequired,
  dataCy: PropTypes.string,
};

export default Circle;
