import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import styles from '../index.module.scss';
import cn from 'classnames';
import { NOT_APPLICABLE, TOOLTIP_CONTENT } from './const';
import { Tooltip } from '../../../ui';
import { PropTypes as mobxPropTypes } from 'mobx-react';

function getIcon(status) {
  if (status === 'Unprocessed') return 'help';
  if (status === 'Pending') return 'sync';
  if (status === 'Failed') return 'error';
  return 'check_circle';
}

const ProcessingCell = ({
  completed,
  results,
  errorMessage,
  ignored = false,
}) => {
  let status = NOT_APPLICABLE,
    tooltipContent = NOT_APPLICABLE;

  if (completed && !errorMessage && results && !ignored) {
    status = 'Processed';
    tooltipContent = TOOLTIP_CONTENT.processed;
  }

  if (completed && errorMessage && !ignored) {
    status = 'Failed';
    tooltipContent = TOOLTIP_CONTENT.failed;
  }

  if (completed && !results && !errorMessage && !ignored) {
    status = 'Unprocessed';
    tooltipContent = TOOLTIP_CONTENT.unprocessed;
  }

  if (!completed && !ignored) {
    status = 'Pending';
    tooltipContent = TOOLTIP_CONTENT.pending;
  }

  return (
    <Cell>
      <Tooltip content={tooltipContent} placement="left">
        <div
          className={cn(styles.processCell, {
            [styles.error]: status === 'Failed',
          })}
        >
          <i className={`material-icons icon-${getIcon(status)}`} />
          <span>{status}</span>
        </div>
      </Tooltip>
    </Cell>
  );
};

ProcessingCell.propTypes = {
  completed: PropTypes.string,
  results: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  errorMessage: PropTypes.string,
  ignored: PropTypes.bool,
};

export default ProcessingCell;
