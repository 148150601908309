import { decorate, observable, computed, reaction } from 'mobx';

import { PersonTagsClient, EnumValueStore } from 'common';

import PersonTagsCountStore from '../../../stores/PersonTagsCountStore';
import UserTagsStore from '../UserTagsStore';

class UserTagStore {
  constructor({ userTagsStore }) {
    reaction(
      () => [userTagsStore.activeTag],
      () => {
        this.setProps(userTagsStore.activeTag);
      }
    );
  }

  // OBSERVABLES: model properties
  name;
  description;
  count;
  id;
  createdBy;
  created;
  deprecated;
  cachedProps = {};

  get isDirty() {
    const name = this.name && this.name.trim();
    const description = this.description && this.description.trim();
    return (
      name !== this.cachedProps.name ||
      description !== this.cachedProps.description
    );
  }

  // Set properties on the data model
  setProps(props) {
    if (props) {
      this.name = props.name;
      this.description = props.description;
      this.count = props.count;
      this.id = props.id;
      this.createdBy = props.createdBy;
      this.created = props.created;
      this.deprecated = props.deprecated;
      this.cachedProps = { ...props };
    }
  }

  // Send update request to the service. Use the properties as set by the
  // setProps method
  update() {
    return PersonTagsClient.update({
      id: this.id,
      name: this.name && this.name.trim(),
      description: this.description && this.description.trim(),
    }).then(r => {
      EnumValueStore.refresh();
      PersonTagsCountStore.refresh(); // ensure that the tags count stays correct
      return r;
    });
  }
}

decorate(UserTagStore, {
  name: observable,
  description: observable,
  count: observable,
  id: observable,
  createdBy: observable,
  created: observable,
  deprecated: observable,
  cachedProps: observable,
  isDirty: computed,
});

export default new UserTagStore({ userTagsStore: UserTagsStore });
