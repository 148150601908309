import { action, decorate, reaction, observable, computed } from 'mobx';
import React from 'react';

import {
  VipClient,
  DownloadUtils,
  RouterContainer,
  SingletonStore,
  mapValuesToArray,
} from 'common';

import NotificationStore from '../../../../stores/NotificationStore';

class BulkVipStore extends SingletonStore {
  constructor() {
    super();

    reaction(
      () => [this.attachedFile],
      () => {
        this.refresh();
      },
      {
        equals: ([previousFile], [newFile]) => {
          // dont run if there was a file and there is no new file, this prevents a call to refresh on exiting component
          return !!previousFile && !newFile;
        },
      }
    );

    reaction(
      () => [this.matchedPatients],
      () => {
        this.selectAll();
      },
      {
        equals: (previousMatchedPatients, newMatchedPatients) => {
          return (
            // if the length of this.matchedPatients was at 0 and is now has a length
            // this means that the user has uploaded a file and so we should start them out
            // by selecting all rows
            previousMatchedPatients.length === 0 &&
            newMatchedPatients.length !== 0
          );
        },
      }
    );
  }

  // Observables
  attachedFile = null;
  registering = false;
  selected = new Map();
  completedRequests = {}; // we treat this Obj like a Map to get around a bug our version of mobx has, do not turn into a Map
  failedRequests = 0;
  complete = false;
  uploadComplete = false;
  workflowComplete = false;

  // computed
  get results() {
    return this.result;
  }

  // Computed
  get data() {
    if (this.status === 'matched') return this.matchedPatients;
    if (this.status === 'unmatched') return this.unmatchedPatients;
    if (this.status === 'existing') return this.existingPatients;
    return this.allPatients;
  }

  // Computed
  get size() {
    return this.data.length;
  }

  // Computed
  get allPatients() {
    return this.results || [];
  }

  // Computed
  get matchedPatients() {
    return this.allPatients.filter(
      patient => patient.matchedPatient && !patient.errorMessage
    );
  }

  // Computed
  get unmatchedPatients() {
    return this.allPatients.filter(patient => !patient.matchedPatient);
  }

  // Computed
  get existingPatients() {
    return this.allPatients.filter(
      patient => patient.matchedPatient && patient.errorMessage
    );
  }

  // Computed
  get showMatched() {
    return (
      !!this.matchedPatients.length &&
      (this.status === 'all' || this.status === 'matched')
    );
  }

  // Computed
  get status() {
    const { status = 'all' } = RouterContainer.query;
    return status;
  }

  // Computed
  get title() {
    if (this.status === 'matched') return 'Matched Patient';
    if (this.status === 'unmatched') return 'Unmatched Patient';
    if (this.status === 'existing') return 'Existing VIP Patient';
    return 'File Row';
  }

  // Computed
  get navTitle() {
    return `${this.title}${this.size === 1 ? '' : 's'} in ${this.attachedFile &&
      this.attachedFile.name}`;
  }

  // compute the percentage of completed requests.
  // Computed
  get percentComplete() {
    if (!this.selected.size || !this.completedRequestsSize) return 0;
    return Math.min(
      Math.ceil((this.completedRequestsSize / this.selected.size) * 100),
      100
    ); // don't exceed 100%
  }

  // computed
  get allSelected() {
    return this.matchedPatients.length === this.selected.size;
  }

  // computed
  get mixedCheckedStatus() {
    return (
      !this.allSelected &&
      this.selected.size !== 0 &&
      this.matchedPatients.length > 0
    );
  }

  // computed
  get completedRequestsSize() {
    return Object.keys(this.completedRequests).length;
  }

  // computed
  get tableTitle() {
    return `${this.title}s`;
  }

  // computed
  get failedUploadMessage() {
    if (!this.failureStatus) return null;

    return this._jqxhr.responseText;
  }

  // Action
  setAttachment = file => (this.attachedFile = file);

  // Action
  onFileChange = e => {
    const file = e.files[0];
    this.setAttachment(file);
  };

  // Action
  select = patient => {
    this.selected.set(patient.id, patient);
  };

  // action
  toggleSelect = patient => {
    if (this.selected.has(patient.id)) {
      this.selected.delete(patient.id);
    } else {
      this.select(patient);
    }
  };

  // action
  selectAll = () => {
    this.matchedPatients.forEach(patient => {
      this.toggleSelect(patient.matchedPatient);
    });
  };

  deselectAll = () => {
    this.selected.clear();
  };

  // Action
  toggleAll = () => {
    if (this.allSelected || this.selected.size === 0) this.selectAll();
    else this.deselectAll();
  };

  // action
  onComplete = (patient, xhr, newPatientList) => {
    if (xhr.status > 299) this.failedRequests++;
    this.completedRequests = {
      ...this.completedRequests,
      [patient.id]: xhr,
    };

    if (newPatientList.length > 0) {
      this.makeRequest(newPatientList);
    } else if (this.failedRequests > 0) {
      // reached the end of the request list and had some fail
      let complete = null;
      if (this.completedRequestsSize - this.failedRequests > 0) {
        complete = (
          <span>
            <i className="material-icons icon-check_circle" />
            {`Successfully created ${this.completedRequestsSize -
              this.failedRequests} VIP registration(s).`}
          </span>
        );
      }
      const problemRegistrations = (
        <span>
          <i className="material-icons icon-error" />
          {`There was a problem creating ${this.failedRequests} VIP registration(s).`}
        </span>
      );
      if (complete) {
        NotificationStore.add({ level: 'success', content: complete });
      }
      NotificationStore.add({
        level: 'error',
        content: problemRegistrations,
      });
      this.complete = true;
      this.registering = false;
    } else {
      // reached the end of the list and they were all successful
      const content = (
        <span>
          <i className="material-icons icon-check_circle" />
          {`Successfully created ${this.completedRequestsSize} VIP registration(s).`}
        </span>
      );
      NotificationStore.add({ level: 'success', content: content });
      this.workflowComplete = true;
    }
  };

  matchedPatient = patientId => {
    return this.matchedPatients.find(person => {
      return person.matchedPatient.id === patientId;
    });
  };

  // action
  makeRequest = patientList => {
    if (!Array.isArray(patientList))
      patientList = mapValuesToArray(patientList);
    const patientCopy = patientList[0];
    const newPatientList = patientList.slice(1); // copy the patientList without the current patient Object

    const matchedPatent = this.matchedPatient(patientCopy.id);
    const newVIP = {
      patient: patientCopy.id,
    };
    if (matchedPatent.description) newVIP.reason = matchedPatent.description;
    if (matchedPatent.endDate) newVIP.endDate = matchedPatent.endDate;

    this.currentRequest = VipClient.create(
      Object.assign(newVIP, {
        complete: xhr => this.onComplete(patientCopy, xhr, newPatientList),
      })
    );
    return this.currentRequest;
  };

  // action
  registerVIPs = () => {
    this.registering = true;
    return this.makeRequest(this.selected);
  };

  // action
  reset = () => {
    this.attachedFile = null;
    this.registering = false;
    this.selected = new Map();
    this.completedRequests = {};
    this.failedRequests = 0;
    this.complete = false;
    this.loading = false;
  };

  isSelected = patient => {
    return this.selected.has(patient.id);
  };

  downloadTemplate = () => {
    const template = VipClient.url('bulk/example');
    DownloadUtils.downloadFromServer(template, 'ExampleBulkVIP.xlsx');
  };

  fetch = () => {
    const formData = new FormData();
    formData.append('file', this.attachedFile);
    return VipClient.uploadAttachment(formData).done(() => {
      this.uploadComplete = !this.failedUploadMessage;
    });
  };
}

decorate(BulkVipStore, {
  attachedFile: observable,
  complete: observable,
  completedRequests: observable,
  failedRequests: observable,
  registering: observable,
  selected: observable,
  uploadComplete: observable,
  workflowComplete: observable,
  allPatients: computed,
  allSelected: computed,
  data: computed,
  existingPatients: computed,
  matchedPatients: computed,
  navTitle: computed,
  percentComplete: computed,
  results: computed,
  showMatched: computed,
  size: computed,
  title: computed,
  unmatchedPatients: computed,
  tableTitle: computed,
  failedUploadMessage: computed,
  mixedCheckedStatus: computed,
  completedRequestsSize: computed,
  makeRequest: action,
  onComplete: action,
  onFileChange: action,
  registerVIPs: action,
  reset: action,
  select: action,
  selectAll: action,
  setAttachment: action,
  setRegistering: action,
  toggleSelect: action,
  toggleAll: action,
  deselectAll: action,
});

export { BulkVipStore };
