import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { CompareViewContext } from '../../../Context';
import MultiRecordInformationDropdown from '../../../../ui/MultiRecordInformationDropdown';
import {
  employeeDropdownMapProps,
  employeeSecondDropdownMapProps,
} from '../../mapProps';

export const CompareViewEmployeeDropdownContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CompareViewContext,
    mapProps: employeeDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);

export const CompareViewEmployeeDropdownContainerSecond = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CompareViewContext,
    mapProps: employeeSecondDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);
