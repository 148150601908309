import {
  fromCamelCase,
  DateHelpers,
  DownloadUtils,
  SingletonStore,
} from 'common';
import {
  action,
  observable,
  comparer,
  computed,
  decorate,
  reaction,
} from 'mobx';
import {
  addPercentage,
  addTotals,
  displayValue,
  getTimeRange,
  groupBy,
  groupFieldFor,
} from '../utils';
import { compareAndPrioritize } from '../../../utils/compare';
import { makeLegendData } from '../../ChartAndTable/BarChartTheme';
import { CREATED } from '../const';
import moment from 'moment';

const PIVOT_DISPLAY_NAMES = {
  resolution: 'Resolution',
  resolutionDescription: 'Resolution Description',
  creationType: 'Creation',
  'category.name': 'Type',
  'category.group': 'Group',
  department: 'Department',
  owner: 'Case Owner',
  role: 'Role',
  userType: 'User Type',
};

const compare = compareAndPrioritize('Uncategorized');

class CaseReportsChartAndTableBaseStore extends SingletonStore {
  constructor({ caseClient, caseReportsFilterSelectionsStore, processParams }) {
    super();

    this.caseClient = caseClient;
    this.caseReportsFilterSelectionsStore = caseReportsFilterSelectionsStore;
    this.processParams = processParams;

    this.disposers = [
      reaction(
        () => [
          this.caseReportsFilterSelectionsStore.filterValuesForChartAndTable,
        ],
        () => {
          this.refresh();
        },
        { equals: comparer.structural, fireImmediately: true }
      ),
    ];
  }

  // Observable
  chartCasesBy;
  chartInterval;
  chartStatisticCalculation;

  setChartCasesBy = val => {
    this.chartCasesBy = val;
    this.refresh();
  };

  /**
   * Computed.
   * Gets the "casesBy" filter that will determine by which case date the cases are returned. Returns either
   * the selected casesBy from a chart's dropdown, or if none is set, the filter's casesBy
   * or "created" if the store is undefined
   *
   * @return {string}
   */
  get casesBy() {
    return (
      this.chartCasesBy ??
      this.caseReportsFilterSelectionsStore?.casesBy ??
      CREATED
    );
  }

  setChartInterval = val => {
    this.chartInterval = val;
    this.refresh();
  };

  /**
   * Computed.
   * Gets the "interval" filter that will determine how the cases are grouped by date. Returns either
   * the selected interval from a chart's dropdown if it is a valid interval for the report date range, or the default
   * interval for the report date range, or "day" if nothing else is resolved.
   *
   * @return {string}
   */
  get interval() {
    const {
      reportSettingsDateRange,
      reportSettingsDateAfter,
      reportSettingsDateBefore,
    } = this.caseReportsFilterSelectionsStore;
    const intervalDefault = DateHelpers.getIntervalDefault(
      reportSettingsDateRange,
      reportSettingsDateAfter,
      reportSettingsDateBefore
    );

    return this.chartInterval &&
      !this.disabledIntervals.includes(this.chartInterval)
      ? this.chartInterval
      : intervalDefault ?? 'day';
  }

  setChartStatisticCalculation = val => {
    this.chartStatisticCalculation = val;
    this.refresh();
  };

  /**
   * Computed.
   * Gets the "chartStatisticCalculation" filter that will determine by which statistic
   * calculation should be applied to the chart based off of a chart's dropdown, or if
   * none is set, defaults to 'average'
   *
   * @return {string}
   */
  get statisticCalculation() {
    return this.chartStatisticCalculation;
  }

  /**
   * Computed.
   * The selected "pivot on" option.
   *
   * @return {string|undefined}
   */
  get pivotOn() {
    return this.caseReportsFilterSelectionsStore.filterValuesForChartAndTable
      ?.pivotOn;
  }

  /**
   * The group field for the selected pivot on option. E.g. "group3"
   *
   * @return {string}
   */
  get groupFieldForPivotOn() {
    return groupFieldFor(this.pivotOn, this.result?.[0]);
  }

  /**
   * The group field for the primary grouping property. E.g. "group"
   *
   * @return {string}
   */
  get groupFieldForPrimary() {
    return groupFieldFor(this.primaryField, this.result?.[0]);
  }

  /**
   * Disabled date options for interval dropdown selection
   *
   * @return {array}
   */
  get disabledIntervals() {
    return DateHelpers.getDisabledIntervalByRange(
      this.caseReportsFilterSelectionsStore.reportSettingsDateRange,
      this.caseReportsFilterSelectionsStore.reportSettingsDateAfter,
      this.caseReportsFilterSelectionsStore.reportSettingsDateBefore
    );
  }

  /**
   * Chart title. Override in subclasses.
   *
   * @return {string}
   */
  get title() {
    throw new Error('Implement in subclass');
  }

  /**
   * Array of case fields to group by. Override in subclasses.
   *
   * @return {string[]}
   */
  get groupBy() {
    throw new Error('Implement in subclass');
  }

  /**
   * Primary case field for grouping. Override in subclasses.
   *
   * @return {string}
   */
  get primaryField() {
    throw new Error('Implement in subclass');
  }

  /**
   * An array of all possible values that should be displayed for the primary
   * field based on which filters are enabled
   *
   * @returns {*[]}
   */
  get primaryValues() {
    return this.caseReportsFilterSelectionsStore.possibleValues(
      this.primaryField
    );
  }

  /**
   * An array of all possible values that should be displayed for the pivot on
   * field based on which filters are enabled. Undefined is returned when not
   * pivoting
   *
   * @returns {*[]|undefined}
   */
  get pivotOnValues() {
    if (!this.pivotOn) return undefined;
    return this.caseReportsFilterSelectionsStore.possibleValues(this.pivotOn);
  }

  /**
   * Cases total. Override in subclass
   *
   * @return {number}
   */
  get casesTotal() {
    throw new Error('Implement in subclass');
  }

  /**
   * Whether or not this type of chart requires zero count population
   *
   * @returns {boolean}
   */
  get needsZeroCounts() {
    return false;
  }

  /**
   * Display name to use when rendering values for the primary field.
   *
   * @return {string}
   */
  get primaryFieldDisplayName() {
    switch (this.primaryField) {
      case 'resolution':
        return 'Resolution';
      case 'category.name':
        return 'Type';
      case 'resolutionDescription':
        return 'Resolution Description';
      case 'created':
        return `Created ${this.interval}`;
      default:
        return '';
    }
  }

  /**
   * Getter function for producing a legend item display.
   *
   * @return {function(Object): [string]}
   */
  get legendValueGetter() {
    return datum => [datum[this.pivotDisplayName]];
  }

  /**
   * The array of fields we want to group by when building chart data.
   *
   * @return {string[]}
   */
  get chartDataGroupBy() {
    return [this.pivotDisplayName];
  }

  /**
   * Getter function for x-axis display values.
   *
   * @return {function(Object): string}
   */
  get chartDataX() {
    return datum => datum[this.primaryFieldDisplayName];
  }

  /**
   * The date ranges for a given range and interval.
   *
   * @return {string[]} the set of date ranges for the range and interval
   */
  get dateRanges() {
    // Only produce date ranges if the endpoint produced date ranges.
    const hasDateRanges =
      [
        ...new Set(
          (this.result || [])
            .map(item => item[groupFieldFor('created', item)])
            .filter(Boolean)
        ),
      ].length > 0;

    if (!hasDateRanges || this.showChartStatisticCalculation) return [];

    // Calculate a lookback amount given the selected range and interval, and from that derive a set of the applicable
    // date ranges for the range and interval.
    const {
      reportSettingsDateAfter,
      reportSettingsDateBefore,
      reportSettingsDateRange,
    } = this.caseReportsFilterSelectionsStore;
    const lookback = DateHelpers.getLookbackForRangeAndInterval(
      reportSettingsDateRange,
      this.interval,
      reportSettingsDateAfter,
      reportSettingsDateBefore
    );

    const dateRanges = [];
    for (let i = 0; i < lookback; i++) {
      const rangeEnd = reportSettingsDateBefore
        ? moment(reportSettingsDateBefore)
        : moment();
      const rangeStart = DateHelpers.getIntervalStart(
        rangeEnd.add(-i, this.interval),
        this.interval
      );
      dateRanges.push(rangeStart.toISOString().replace('Z', '+00:00'));
    }

    return dateRanges.sort();
  }

  /**
   * Processed data from the API response. This will power both table and cart
   * data.
   *
   * This array of objects corresponds 1:1 with the results from the API. We
   * additionally will fill in any missing time range data with zeros.
   *
   * Each object will be include the shape of the API response:
   * count: ..., groups: [...], group: ..., group2, ..., group3: ..., etc
   * and will additionally have:
   * [primaryFieldDisplayName]: primaryValue, [pivotDisplayName]: pivotValue
   *
   * If the object represents time-bucketed counts, it will also carry
   * timeRangeValue: dateString, timeRangeDisplayName: formattedRangeDisplay
   *
   * Objects will be sorted first by pivot value and second by primary value.
   *
   * @return {Object[]}
   */
  get coreData() {
    // Add primary, pivot, and time range values to each item.
    const data = (this.needsZeroCounts
      ? this.fillZeroes(this.result)
      : this.result || []
    ).map(item => {
      const datum = {
        ...item,
        [this.primaryFieldDisplayName]: displayValue(
          this.primaryField,
          item[this.groupFieldForPrimary]
        ),
      };
      if (this.pivotDisplayName) {
        datum[this.pivotDisplayName] = displayValue(
          this.pivotOn,
          item[this.groupFieldForPivotOn]
        );
      }
      const timeRangeGroupField = groupFieldFor('created', item);
      if (timeRangeGroupField && !this.showChartStatisticCalculation) {
        // Associate a column name and a display name with the time range. The column name will serve as a unique key
        // and will be the full string representation of the range. The display name will serve as a shortened display name
        // to be shown on the browser except when the column name needs to be used for disambiguation. Also track the full
        // date of the time range group.
        datum.timeRangeValue = item[timeRangeGroupField];

        datum.timeRangeColumnName = getTimeRange(
          item[timeRangeGroupField],
          this.interval,
          true
        );

        datum.timeRangeDisplayName = getTimeRange(
          item[timeRangeGroupField],
          this.interval
        );
      }

      return datum;
    });

    const results = [];

    // We'll bucket all data by pivot values, then additionally by primary
    // values. We'll then ensure all time range data is filled in with zeros
    // as needed.
    const bucketedByPivot = data.groupBy(this.pivotDisplayName);
    Object.entries(bucketedByPivot).forEach(([, data]) => {
      const bucketedByPrimary = data.groupBy(this.primaryFieldDisplayName);
      return Object.entries(bucketedByPrimary).forEach(([, data]) => {
        if (this.dateRanges.length > 0) {
          this.dateRanges.forEach(date => {
            results.push(
              // If we are dealing with time-series data, fill in any missing
              // points with 0s.
              data.find(({ timeRangeValue }) => timeRangeValue === date) || {
                ...data[0],
                timeRangeValue: date,
                timeRangeColumnName: getTimeRange(date, this.interval, true),
                timeRangeDisplayName: getTimeRange(date, this.interval),
                count: 0,
              }
            );
          });
        } else {
          results.push(...data);
        }
      });
    });

    // only compare date values
    if (this.skipAlphaNumericSort)
      return results.sort((a, b) =>
        compare(a.timeRangeValue || '', b.timeRangeValue || '')
      );

    // Sort the objects by pivot -> primary -> time range.
    return results.sort(
      (a, b) =>
        compare(
          a[this.pivotDisplayName] || '',
          b[this.pivotDisplayName] || ''
        ) ||
        compare(
          a[this.primaryFieldDisplayName] || '',
          b[this.primaryFieldDisplayName] || ''
        ) ||
        compare(a.timeRangeValue || '', b.timeRangeValue || '')
    );
  }

  /**
   * Nested array of objects to power a bar or line chart.
   * Each sub-array will represent either a line series or a set of bars
   * corresponding to a pivot group.
   *
   * Each object has only an x and y value. The x value is what is displayed
   * on the x-axis. The y value is the count of cases.
   *
   * @return {Object[][]}
   */
  get chartData() {
    // Example for a resolution bar chart pivoting by category:
    /*
    [
      // Counts for Coworker
      [{x: 'Good Catch', y: total}, {x: 'False Positive', y: total}, ...],
      // Counts for Family Member
      [{x: 'Good Catch', y: total}, {x: 'False Positive', y: total}, ...],
      // Counts for VIP
      [{x: 'Good Catch', y: total}, {x: 'False Positive', y: total}, ...],
    ]
     */

    const bucketedData = groupBy(this.coreData, datum =>
      this.chartDataGroupBy
        .map(field => datum[field])
        .filter(Boolean)
        .join(', ')
    );

    return Object.entries(bucketedData).map(([bucketName, data]) => {
      return data.map(datum => ({
        x: this.chartDataX(datum),
        y: datum.count,
        bucketName,
        timeRangeValue: datum.timeRangeValue,
      }));
    });
  }

  /**
   * Builds an array of objects to power a table.
   * Each field in the object represents a column in the table.
   *
   * @param  {boolean} useExportHeaders true if we are exporting and should use specific headers for the columns, false otherwise
   * @return {Object[]}                 the array of objects representing tabular data
   */
  buildTableData(useExportHeaders = false) {
    // Give each object an id and group by that id. The id will represent one
    // row in the table.
    const bucketedById = this.coreData
      .map(datum => ({
        ...datum,
        id: datum[this.pivotDisplayName] + datum[this.primaryFieldDisplayName],
      }))
      .groupBy('id');

    let timeRangeColumns = [];

    const rows = Object.entries(bucketedById).map(([id, data]) => {
      const firstDatum = data[0];
      const lastDatum = data[data.length - 1];

      const row = {
        id,
        [this.primaryFieldDisplayName]:
          firstDatum[this.primaryFieldDisplayName],
      };

      if (this.pivotDisplayName) {
        row[this.pivotDisplayName] = firstDatum[this.pivotDisplayName];
      }

      // Get the time range columns if they exist, and also check if we are spanning multiple years
      // by checking against the first and last entries - coreData should ensure the sorting is correct.
      const isMultipleYearSpan =
        moment(firstDatum.timeRangeValue).year() <
        moment(lastDatum.timeRangeValue).year();
      timeRangeColumns = data
        .map(datum => datum.timeRangeColumnName)
        .filter(Boolean);

      data.forEach(datum => {
        if (datum.timeRangeColumnName) {
          // Associate a value object for the time range that will use the derived display name - this allows us to keep uniqueness
          // with the column name as key, but use a different header when rendering the table. The display name will be the full range
          // (column name) if we are exporting and want to use full headers, or if the range of the data
          // spans more than one year, otherwise use the shortened display name.
          const displayName =
            !useExportHeaders && !isMultipleYearSpan
              ? datum.timeRangeDisplayName
              : datum.timeRangeColumnName;

          row[datum.timeRangeColumnName] = {
            displayName: displayName,
            value: datum.count,
          };
        }
      });
      row.total = data.reduce((a, { count: b }) => a + b, 0);
      return row;
    });

    if (!this.skipAlphaNumericSort)
      rows.sort(
        (a, b) =>
          compare(
            a[this.primaryFieldDisplayName] || '',
            b[this.primaryFieldDisplayName] || ''
          ) ||
          compare(
            a[this.pivotDisplayName] || '',
            b[this.pivotDisplayName] || ''
          ) ||
          compare(a.timeRangeValue || '', b.timeRangeValue || '')
      );

    return addPercentage(
      addTotals(rows, this.primaryFieldDisplayName, timeRangeColumns)
    );
  }

  get tableData() {
    return this.buildTableData();
  }

  get exportTableData() {
    return this.buildTableData(true);
  }

  /**
   * Display name to use for the pivot value. If we are pivoting by the primary
   * field, ignore it.
   *
   * @return {string}
   */
  get pivotDisplayName() {
    const pivotOn = this.pivotOn;
    if (!pivotOn || pivotOn === this.primaryField) return '';
    return PIVOT_DISPLAY_NAMES[pivotOn];
  }

  /**
   * Array of objects to pass to the legend component.
   *
   * @return {{labels: string[], color: string}[]}
   */
  get legendData() {
    const uniqueLegendLabels = Object.values(
      this.coreData.map(this.legendValueGetter).reduce((hash, labels) => {
        return { ...hash, [labels.join()]: labels };
      }, {})
    );

    return makeLegendData(uniqueLegendLabels);
  }

  /**
   * Params we'll include in the API request to retrieve our grouped counts.
   *
   * @return {*&{groupBy: string[], window: (string|undefined)}}
   */
  get params() {
    const groupBySet = new Set([...this.groupBy, this.pivotOn]);
    const params = {
      ...this.processParams({
        filterValuesForChartAndTable: this.caseReportsFilterSelectionsStore
          .filterValuesForChartAndTable,
        casesBy: this.casesBy,
      }),
      groupBy: [...groupBySet].filter(Boolean),
      window: this.interval,
    };
    if (this.chartStatisticCalculation)
      params.statisticCalculation = this.chartStatisticCalculation;

    return params;
  }

  downloadCSV = () => {
    // base CSV off of the same table structure used in the UI with optional export-related
    // transformations

    // collect headers from table row object keys
    let headers = [];
    if (this.exportTableData?.length > 0) {
      headers = Object.keys(this.exportTableData[0]).filter(
        key => key !== 'id'
      );
    }

    // map table rows as CSV rows
    const rows = (this.exportTableData || []).map(row =>
      headers.map(h => row[h])
    );

    // place header row first
    rows.unshift(
      headers.map(h => {
        return fromCamelCase(h);
      })
    );

    const csvTitle = this.title + '.csv';
    DownloadUtils.downloadFromClient(rows, csvTitle);
  };

  get configuration() {
    return {
      maxBars: 60,
      maxLines: 15,
      maxRowsBeforeScroll: 10,
    };
  }

  tearDown = () => {
    this.disposers.forEach(d => d());
  };

  fetch() {
    return this.caseClient.countBy(this.params);
  }

  /**
   * As part of the post-processing stage, each store may populate "zero counts"
   * to account for values which weren't represented in the case data selected,
   * e.g. populate Violation: 0, if there were no cases with a violation
   * resolution in the case data.
   *
   * Stores can make use of zeroCountExclusions to forbid the creation of
   * certain "impossible" combinations of values (e.g. Diversion Group: Family
   * Member Category).
   *
   * @param response
   */
  fillZeroes(response) {
    const result = (response || []).slice();

    // Determine if there is a secondary grouping aka pivot. For groups these
    // are an object and this chart only needs to know the group name
    const primaryGroup = groupFieldFor(this.primaryField, result[0]) || 'group';
    const secondaryGroup = groupFieldFor(this.pivotOn, result[0]);

    // No pivoting currently, simply make sure we have the counts for primary values
    if (!this.pivotOnValues || this.pivotOn === this.primaryField) {
      this.primaryValues.forEach(value => {
        if (!result.find(g => g[primaryGroup] === value)) {
          result.push({
            count: 0,
            [primaryGroup]: value,
            groups: this.groupBy,
          });
        }
      });
    } else {
      this.primaryValues.forEach(primaryValue => {
        this.pivotOnValues.forEach(secondaryValue => {
          if (
            // don't populate zero counts for "impossible combinations"
            !this.zeroCountExclusions?.[primaryValue]?.includes(
              secondaryValue
            ) &&
            !result.find(
              g =>
                g[primaryGroup] === primaryValue &&
                g[secondaryGroup] === secondaryValue
            )
          ) {
            result.push({
              count: 0,
              [primaryGroup]: primaryValue,
              [secondaryGroup]: secondaryValue,
              groups: this.groupBy,
            });
          }
        });
      });
    }

    return result;
  }
}

decorate(CaseReportsChartAndTableBaseStore, {
  chartCasesBy: observable,
  chartInterval: observable,
  chartStatisticCalculation: observable,
  setChartCasesBy: action,
  setChartInterval: action,
  setChartStatisticCalculation: action,
  casesBy: computed,
  chartData: computed,
  configuration: computed,
  coreData: computed,
  dateRanges: computed,
  disabledIntervals: computed,
  exportTableData: computed,
  groupFieldForPivotOn: computed,
  groupFieldForPrimary: computed,
  interval: computed,
  legendData: computed,
  params: computed,
  pivotDisplayName: computed,
  pivotOn: computed,
  pivotOnValues: computed,
  primaryValues: computed,
  statisticCalculation: computed,
  tableData: computed,
});

export default CaseReportsChartAndTableBaseStore;
