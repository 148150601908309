import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import MultiRecordSearchContainer from '../MultiRecordSearchContainer';
import MultiRecordCompositeFieldSectionHeader from './Header';
import ExpandCollapse from '../../ui/ExpandCollapse';
import classnames from 'classnames';
import { useEphemeralStore } from '../../utils/hooks';
import { MultiRecordCompositeSectionFilterStore } from '../stores/MultiRecordCompositeSectionFilterStore';
import MultiRecordCompositeSectionFilterContainer from '../MultiRecordCompositeSectionFilterContainer';

const MultiRecordCompositeFieldSection = ({
  name,
  groups,
  isCountVisible,
  setSearchVisible,
  expansionThreshold,
  isExpandable,
  searchVisible,
}) => {
  const multiRecordCompositeSectionFilterStore = useEphemeralStore(
    () => new MultiRecordCompositeSectionFilterStore()
  );

  useEffect(() => {
    multiRecordCompositeSectionFilterStore.setGroups(groups);
  }, [multiRecordCompositeSectionFilterStore, groups]);

  // get number of fields across all groups
  const numFields = groups.reduce(
    (count, currGroup) => count + currGroup?.fields?.length ?? 0,
    0
  );

  return (
    <div className={styles.compositeSectionWrapper}>
      {name && numFields > 2 ? (
        <ExpandCollapse
          onExpand={() => setSearchVisible(true)}
          onCollapse={() => setSearchVisible(false)}
        >
          <ExpandCollapse.Header>
            <span className={styles.header}>
              <MultiRecordCompositeFieldSectionHeader
                name={name}
                isCountVisible={isCountVisible}
                numGroups={groups.length}
              />
              <button
                type="button"
                className={classnames(styles.filterButton, {
                  [styles.searchVisible]: searchVisible,
                })}
              >
                <span
                  className={classnames(
                    'material-icons',
                    styles.filterButtonIcon
                  )}
                >
                  filter_list
                </span>
              </button>
            </span>
          </ExpandCollapse.Header>
          <ExpandCollapse.Section>
            <MultiRecordSearchContainer
              multiRecordCompositeSectionFilterStore={
                multiRecordCompositeSectionFilterStore
              }
            />
          </ExpandCollapse.Section>
        </ExpandCollapse>
      ) : (
        <span className={styles.header}>
          <MultiRecordCompositeFieldSectionHeader
            name={name}
            isCountVisible={isCountVisible}
            numGroups={groups.length}
          />
        </span>
      )}
      <MultiRecordCompositeSectionFilterContainer
        multiRecordCompositeSectionFilterStore={
          multiRecordCompositeSectionFilterStore
        }
        name={name}
        groups={groups}
        isCountVisible={isCountVisible}
        isExpandable={isExpandable}
        expansionThreshold={expansionThreshold}
      />
    </div>
  );
};

MultiRecordCompositeFieldSection.propTypes = {
  name: PropTypes.string,
  groups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  isCountVisible: PropTypes.bool,
  isExpandable: PropTypes.bool,
  expansionThreshold: PropTypes.number,
  employeeColumnStore: PropTypes.object,
  matchingGroups: PropTypes.array,
  filterTerm: PropTypes.string,
  setFilterTerm: PropTypes.func,
  setSearchVisible: PropTypes.func,
  searchVisible: PropTypes.bool,
  setGroups: PropTypes.func,
};

MultiRecordCompositeFieldSection.defaultProps = {
  name: null,
  groups: [],
  isCountVisible: false,
  isExpandable: false,
  expansionThreshold: 2,
  filterTerm: '',
  setFilterTerm: () => {},
  matchingGroups: [],
  setSearchVisible: () => {},
  searchVisible: false,
  setGroups: () => {},
};

export default MultiRecordCompositeFieldSection;
