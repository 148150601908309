import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import Assessment from '../../../ui/Assessment';

const PrivacyAssessment = ({
  analyticAssessment,
  author,
  condensedHeader,
  headline,
  caseId,
  caseNumber,
  linkTo,
  userId,
  patientId,
}) => {
  /* eslint-disable react/prop-types */
  const maxEventDate =
    analyticAssessment.maxEventDate || analyticAssessment.endTime;
  const minEventDate =
    analyticAssessment.minEventDate || analyticAssessment.startTime;
  const smartRefProps = {
    caseId,
    caseNumber,
    maxEventDate,
    minEventDate,
    patientId,
    userId,
  };

  return (
    <Assessment>
      <Assessment.Header
        author={author}
        dateAdded={analyticAssessment.dateAdded}
        dateCreated={
          analyticAssessment.dateCreated || minEventDate || maxEventDate || ''
        }
        startDate={minEventDate || ''}
        endDate={maxEventDate || ''}
        condensedHeader={condensedHeader}
        type="privacy"
      />
      <Assessment.PrivacyAssessments
        headline={headline}
        reasons={analyticAssessment.reasons}
        linkTo={linkTo}
        smartRefProps={smartRefProps}
        suspicionScore={
          analyticAssessment.suspicionScore || analyticAssessment.hipaaScore
        }
        lastUpdatedByAG={analyticAssessment.lastUpdatedByAG}
      />
    </Assessment>
  );
};

PrivacyAssessment.propTypes = {
  analyticAssessment: PropTypes.shape({
    suspicionScore: PropTypes.number,
    minEventDate: PropTypes.string,
    maxEventDate: PropTypes.string,
    lastUpdatedByAG: PropTypes.string,
    dateAdded: PropTypes.string,
    dateCreated: PropTypes.string,
    reasons: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})), // detailed validations in the component
  }),
  author: PropTypes.string,
  condensedHeader: PropTypes.bool,
  headline: PropTypes.string,
  caseId: PropTypes.string,
  linkTo: PropTypes.string,
  userId: PropTypes.string,
  caseNumber: PropTypes.number,
  patientId: PropTypes.string,
};

PrivacyAssessment.defaultProps = {
  analyticAssessment: {},
  assessmentsHeadline: '',
  author: '',
  caseNumber: 0,
  caseId: null,
  condensedHeader: false,
  linkTo: '',
  patientId: '',
  userId: '',
};

export default PrivacyAssessment;
