/**
 * Helpers for dealing with case filter options and selections.
 */

import moment from 'moment';
import commaSeparatedToArray from '../../utils/commaSeparatedToArray';

/**
 * Utility method to create a display-ready copy of a given Array, finding the
 * referenced object from the options list.
 *
 * @param {Array|String} src                Either an Array of IDs or a single ID value, to
 *                                          be used for looking up Objects in the options list
 * @param {Array}        options            the Options list to use as a reference
 *
 * @return {Array}                          a new Array, with display-friendly Objects included
 */
export function prepForDisplay(src, options) {
  const result = [];
  if (src) {
    src = commaSeparatedToArray(src);

    src.forEach(item => {
      result.push(options && options.find(i => item === i.id));
    });
  }

  return result.filter(r => r?.id);
}

/**
 * Build a new partial query for setting filter selections.
 *
 * @param filter {string} Filter name. E.g. "owner"
 * @param values {Object|Object[]} Object or array of objects containing an id field.
 * @param [excludeFilters] {string[]} List of filters being excluded currently.
 *
 * @return query {Object} Updated query portion for navigation.
 */
export function queryForSelectionChange(filter, values, excludeFilters) {
  excludeFilters = excludeFilters || [];
  const query = {};
  const isArray = Array.isArray(values);
  const filterNameWithoutRange = filter.replace('Range', '');

  if (['suspicionScore', 'timeToResolve'].includes(filter)) {
    // Single selects with possible "all" value that clears the selection.
    const { value } = values;
    query[filter] = value === 'all' ? undefined : value;
  } else if (
    [
      'actionsDateRange',
      'createdRange',
      'lastModifiedRange',
      'reportSettingsDateRange',
      'resolutionDateRange',
      'violationDateRange',
    ].includes(filter)
  ) {
    // Date selector with custom option.
    query[`${filterNameWithoutRange}After`] = undefined;
    query[`${filterNameWithoutRange}Before`] = undefined;
    query[`${filterNameWithoutRange}Range`] = undefined;

    // We are not choosing the "all" option. Set the dropdown value.
    if (values.value !== 'all') {
      query[`${filterNameWithoutRange}Range`] = values.value;
    }

    // We have selected "custom" from the dropdown. Set initial dates for the
    // calendar picker.
    if (values.value === 'custom') {
      query[`${filterNameWithoutRange}After`] = moment()
        .subtract(1, 'month')
        .format('YYYY-MM-DD');
      query[`${filterNameWithoutRange}Before`] = moment().format('YYYY-MM-DD');
    }
  } else if (/(After|Before)$/.test(filter)) {
    // Setting a before or after date on a custom date selector.
    query[filter] = moment(values).format('YYYY-MM-DD');
  } else {
    // A multiselect with any number of objects containing an id property.
    query[filter] = values.map(v => v.id);
  }

  if (
    excludeFilters.includes(filter) &&
    ((isArray && !values.length) || (!isArray && values.value === 'all'))
  ) {
    // If we were excluding this filter and now have removed all values, stop
    // excluding it.
    query.excludeFilters = excludeFilters.filter(f => f !== filter);
  }

  // Reset resolution description if resolution is being changed
  if (filter === 'resolution') {
    query.resolutionDescription = undefined;
  }

  return query;
}

/**
 * Helper method to convert a provided parameter value to an array if it
 * isn't one already
 *
 * @param {String|Array} paramValue - the parameter value to convert
 * @return {Array} the converted value as an array
 */
export function convertMultiValue(paramValue) {
  if (paramValue) {
    const values = paramValue instanceof Array ? paramValue : [paramValue];

    return values.map(a => a);
  }
  return undefined;
}

/**
 * Build a new partial query for toggling exclusion state of a particular
 * filter.
 *
 * @param filter {string} Filter name. E.g. "created"
 * @param [forFilter] {string} Filter we are actually excluding.
 *                             E.g. "createdRange"
 *                             Optional; filter is used otherwise.
 * @param store {Object} "this"
 * @return query {Object} Updated query portion for navigation.
 */
export function queryForToggle(filter, forFilter, store) {
  forFilter = forFilter || filter;

  // Ignore if we do not have any selections for the filter.
  if (!store[forFilter] || !store[forFilter].length) return {};

  const field = `${filter}Exclude`;
  const query = {};
  if (!store[field]) {
    // need to add it to exclude filters list if it's not already on
    query.excludeFilters = Array.from(
      new Set([...(store.excludeFilters || []), filter])
    );

    // need to remove actions data range param if it's currently on, and they want to do an exclusive search for actions taken
    if (filter === 'actionsTaken') {
      query.actionsDateRange = undefined;
    }
  } else if (store.excludeFilters) {
    // remove it from exclude filter list
    query.excludeFilters = store.excludeFilters.filter(f => f !== filter);
  }

  return query;
}
