import React from 'react';
import { observer } from 'mobx-react';

import { PermissionStore } from 'common';
import CaseResultsStore from '../stores/CaseResultsStore';
import CaseResultsFiltersStore from '../../stores/CaseResultsFiltersStore';
import CaseDetailFilters from '../../ui/CaseDetailFilters';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({
  CaseResultsStore,
  CaseResultsFiltersStore,
  PermissionStore,
  history,
  location,
  query,
}) {
  return {
    history,
    location,
    query,

    onMount: () =>
      CaseResultsFiltersStore.caseBundleFilterOptionsStore.refresh(),

    queryForToggle: CaseResultsStore.queryForToggle,
    queryForSelectionChange: CaseResultsStore.queryForSelectionChange,

    canToggleOwner: PermissionStore.has('CASE_SEARCH'),
    caseOwnerOptions: CaseResultsFiltersStore.caseOwners,
    caseOwnerSelections: CaseResultsStore.displayOwner,
    caseOwnerExcluded: CaseResultsStore.ownerExclude,

    caseCreatedByOptions: CaseResultsFiltersStore.caseCreators,
    caseCreatedBySelections: CaseResultsStore.displayCreatedBy,
    caseCreatedByExcluded: CaseResultsStore.createdByExclude,

    caseTypeOptions: CaseResultsFiltersStore.alertCategoryNames,
    caseTypeSelections: CaseResultsStore.displayCategory,
    caseTypeExclusionDisabled: true,

    caseBundleOptions: CaseResultsFiltersStore.caseBundles,
    caseBundleSelections: CaseResultsStore.displayBundle,
    caseBundleExcluded: CaseResultsStore.bundleExclude,

    groupOptions: CaseResultsFiltersStore.groups,
    groupSelections: CaseResultsStore.displayGroup,
    groupExcluded: CaseResultsStore.groupExclude,

    resolutionOptions: CaseResultsFiltersStore.resolutions,
    resolutionSelections: CaseResultsStore.displayResolution,
    resolutionExcluded: CaseResultsStore.resolutionExclude,

    resolvedByOptions: CaseResultsFiltersStore.caseResolvers,
    resolvedBySelections: CaseResultsStore.displayResolvedBy,
    resolvedByExcluded: CaseResultsStore.resolvedByExclude,

    caseActionOptions: CaseResultsFiltersStore.caseActions,
    caseActionSelections: CaseResultsStore.displayActions,
    caseActionExcluded: CaseResultsStore.actionsTakenExclude,

    caseActionDateRangeSelection: CaseResultsStore.actionsDateRange,
    caseActionDateAfter: CaseResultsStore.actionsDateAfter,
    caseActionDateBefore: CaseResultsStore.actionsDateBefore,

    suspicionScoreOptions: CaseResultsFiltersStore.suspicionScores,
    suspicionScoreSelection: CaseResultsStore.suspicionScore,
    suspicionScoreExcluded: CaseResultsStore.suspicionScoreExclude,

    resolutionDescriptionOptions:
      CaseResultsFiltersStore.resolutionDescriptionOptions,
    resolutionDescriptionSelections:
      CaseResultsStore.displayResolutionDescription,
    resolutionDescriptionExcluded:
      CaseResultsStore.resolutionDescriptionExclude,
  };
}

const CaseDetailFiltersContainer = observer(({ history, location, query }) => (
  <CaseDetailFilters
    {...mapProps({
      CaseResultsStore,
      CaseResultsFiltersStore,
      PermissionStore,
      history,
      location,
      query,
    })}
  />
));

CaseDetailFiltersContainer.displayName = 'CaseDetailFiltersContainer';

export default withRouterAndQuery(CaseDetailFiltersContainer);
