import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { SingleViewContext } from '../../../Context';
import MultiRecordEntityColumn from '../../../MultiRecordEntityColumn';
import {
  employeeColumnMapProps,
  employeeSecondColumnMapProps,
} from '../../mapProps';

export const SingleViewEmployeeColumnContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: SingleViewContext,
    mapProps: employeeColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);

export const SingleViewEmployeeColumnContainerSecond = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: SingleViewContext,
    mapProps: employeeSecondColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);
