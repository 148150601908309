import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';

import {
  AlertCategoryGroupStore,
  AllUsersStore,
  AppRolesStore,
  ApprovedDomainsStore,
  AppUserClient,
  Authenticated,
  HalUtils,
  LoginClient,
  LoginStore,
  PermissionStore,
  ProtectedRoute,
} from 'common';
import { DocumentTitle } from '../../../ui';
import styles from './index.module.scss';

import { useEphemeralStore } from '../../../utils/hooks';
import { CustomerAdministeredAccountsContext } from '../stores/Context';
import { CustomerAdministeredAccountsListStore } from '../stores/CustomerAdministeredAccountsListStore';
import { CustomerAdministeredAccountsEditUserStore } from '../stores/CustomerAdministeredAccountsEditUserStore';
import CustomerAdministeredAccountsListViewContainer from '../CustomerAdministeredAccountsListViewContainer';
import { CREATE_PATH, UPDATE_PATH } from '../const';
import CustomerAdministeredAccountsManageFormContainer from '../CustomerAdministeredAccountsManageFormContainer';
import UserAccountLimitSettingsStore from '../../../stores/UserAccountLimitSettingsStore';
import { permissionMap } from '../../helpers';

const AppUsersRootView = ({ match }) => {
  const customerAdministeredAccountsListStore = useEphemeralStore(
    () =>
      new CustomerAdministeredAccountsListStore({
        appUserClient: AppUserClient,
        permissionStore: PermissionStore,
      })
  );
  const customerAdministeredAccountsEditUserStore = useEphemeralStore(
    () =>
      new CustomerAdministeredAccountsEditUserStore({
        alertCategoryGroupStore: AlertCategoryGroupStore,
        allUsersStore: AllUsersStore,
        appRolesStore: AppRolesStore,
        approvedDomainsStore: ApprovedDomainsStore,
        appUserClient: AppUserClient,
        customerAdministeredAccountsListStore: customerAdministeredAccountsListStore,
        halUtils: HalUtils,
        loginClient: LoginClient,
        loginStore: LoginStore,
        userAccountLimitSettingsStore: UserAccountLimitSettingsStore,
        permissionStore: PermissionStore,
      })
  );

  const createUpdateAsideVisible =
    match.isExact && (match.path === CREATE_PATH || match.path === UPDATE_PATH);

  return (
    <Authenticated permission={permissionMap.appUsers}>
      <DocumentTitle text="App User Management" />
      <div className={styles.appUsersRootView}>
        <CustomerAdministeredAccountsContext.Provider
          value={{
            alertCategoryGroupStore: AlertCategoryGroupStore,
            createUpdateAsideVisible,
            customerAdministeredAccountsListStore,
            customerAdministeredAccountsEditUserStore,
            loginStore: LoginStore,
          }}
        >
          <CustomerAdministeredAccountsListViewContainer />
          <Switch>
            <ProtectedRoute
              exact
              path={CREATE_PATH}
              component={CustomerAdministeredAccountsManageFormContainer}
            />
            <ProtectedRoute
              exact
              path={UPDATE_PATH}
              component={CustomerAdministeredAccountsManageFormContainer}
            />
          </Switch>
        </CustomerAdministeredAccountsContext.Provider>
      </div>
    </Authenticated>
  );
};

AppUsersRootView.propTypes = {
  match: PropTypes.object,
};

export default AppUsersRootView;
