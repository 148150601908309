import React from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';
import queryString from 'query-string';
import TopNavBar from '../../navbar/TopNavBar';
import styles from './index.module.scss';

const createdAfterOptions = [
  { value: 'today', label: 'Today' },
  { value: 'last7Days', label: 'Past 7 days' },
  { value: 'last30Days', label: 'Past 30 days' },
  { value: 'last90Days', label: 'Past 90 days' },
  { value: 'year', label: 'Past Year' },
];

const DataFeedEvaluationNav = ({ createdAfter, history, location, query }) => {
  const onChange = ({ value }) => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({
        ...query,
        selectedRow: undefined,
        showAsidePanel: undefined,
        createdAfter: value,
      }),
    });
  };

  return (
    <TopNavBar actionBar secondary className={styles.subnav}>
      <DropdownList
        className={styles.dropdown}
        data={createdAfterOptions}
        value={createdAfterOptions.find(({ value }) => value === createdAfter)}
        onChange={onChange}
        textField="label"
        valueField="value"
        valueComponent={({ item }) => (
          <span>
            <strong>Date Range</strong> {item?.label}
          </span>
        )}
        data-cy="fdd-data-evaluation-date-range"
      />
    </TopNavBar>
  );
};

DataFeedEvaluationNav.propTypes = {
  createdAfter: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  query: PropTypes.object,
};

export default DataFeedEvaluationNav;
