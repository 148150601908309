import CaseReportsChartAndTableBaseStore from '../CaseReportsChartAndTableBaseStore';
import { computed, decorate } from 'mobx';

class CaseReportsCaseTypesStore extends CaseReportsChartAndTableBaseStore {
  get title() {
    return 'Case Types';
  }

  get groupBy() {
    return ['category.name'];
  }

  get primaryField() {
    return 'category.name';
  }

  get needsZeroCounts() {
    return true;
  }

  /**
   * Returns a mapping of primary to secondary values which should not generate
   * a "zero count". Example:
   *
   *   {
   *     'Family Member': 'Diversion University', // no such thing as family
   *                                              // member diversion cases
   *     'VIP: 'Diversion University',
   *     etc.
   *   }
   *
   * @returns {{}} mapping of primary to secondary values which should not
   * create zero count entries
   */
  get zeroCountExclusions() {
    const groups = this.caseReportsFilterSelectionsStore.caseResultsFiltersStore.groups.map(
      g => g.name
    );
    const categories = this.caseReportsFilterSelectionsStore.caseResultsFiltersStore.alertCategories.filter(
      category => category.reportName
    );

    // Step 1 - Build map of category.reportName -> arrayOfGroups
    const categoryMap = {};

    categories.forEach(category => {
      categoryMap[category.reportName] = (
        categoryMap[category.reportName] ?? new Set()
      ).add(category.group);
    });

    // Step 2 - Build exclusion map of category.reportName -> nonApplicableGroups
    const exclusionMap = {};

    Object.entries(categoryMap)
      // filter out categories that belong to all groups
      .filter(entry => entry[1].size !== groups.length)
      .forEach(entry => {
        exclusionMap[entry[0]] = groups.filter(g => !entry[1].has(g));
      });

    return exclusionMap;
  }
}

decorate(CaseReportsCaseTypesStore, {
  zeroCountExclusions: computed,
});

export default CaseReportsCaseTypesStore;
