import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import styles from '../index.module.scss';
import { getPathFromLocation } from '../../stores/DeliveryStore/helpers';

const PathCell = ({ location }) => {
  const path = getPathFromLocation(location);

  return (
    <Cell>
      <div className={styles.pathCell}>
        <span title={path}>{path}</span>
      </div>
    </Cell>
  );
};

PathCell.propTypes = {
  location: PropTypes.string,
};

PathCell.defaultProps = {
  location: '',
};

export default PathCell;
