import React from 'react';
import PropTypes from 'prop-types';

const GenericPatientInfoHeader = ({ type }) => {
  const headerText = {
    none: 'No Patient',
    multi: 'Multiple Patients',
  }[type];
  const subHeaderText = {
    none: 'User Only Events',
    multi: 'High volume of patients accessed',
  }[type];

  return (
    <div className="titleCard">
      <div className="titleCard--id patient">
        <i className="material-icons icon-folder_shared" />
      </div>
      <div className="personInformation">
        <h2>{headerText}</h2>
        <h5 className="text-secondary">{subHeaderText}</h5>
      </div>
    </div>
  );
};

GenericPatientInfoHeader.propTypes = {
  type: PropTypes.oneOf(['none', 'multi']).isRequired,
};

export default GenericPatientInfoHeader;
