import React from 'react';
import { observer } from 'mobx-react';
import EvaluationIconStore from '../stores/EvaluationIconStore';
import DataFeedTopNav from '../DataFeedTopNav';

export function mapProps({ EvaluationIconStore }) {
  return {
    errorCount: EvaluationIconStore.errorCount,
  };
}

const DataFeedTopNavContainer = observer(() => (
  <DataFeedTopNav
    {...mapProps({
      EvaluationIconStore,
    })}
  />
));

DataFeedTopNavContainer.displayName = 'DataFeedTopNavContainer';

export default DataFeedTopNavContainer;
