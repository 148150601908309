import { SingletonStore } from 'common';
import { action, computed, decorate, observable } from 'mobx';

class MultiRecordPersonHeaderStore extends SingletonStore {
  constructor({ multiRecordViewClient }) {
    super();
    this.multiRecordViewClient = multiRecordViewClient;
  }

  get personHeaders() {
    return this.result?.personHeaders?.slice() || [];
  }

  currentMatch;

  setCurrentMatch = match => {
    this.currentMatch = match;
    this.refresh();
  };

  fetch() {
    const { employeeId, patientId, caseId } = this.currentMatch.params;
    const params = {};
    if (employeeId) params.userId = employeeId;
    if (patientId) params.patientId = patientId;
    if (caseId) params.caseId = caseId;

    return this.multiRecordViewClient.getPersonHeaders(params);
  }
}

decorate(MultiRecordPersonHeaderStore, {
  currentMatch: observable,
  personHeaders: computed,
  setCurrentMatch: action,
});

export { MultiRecordPersonHeaderStore };
