import React from 'react';
import PropTypes from 'prop-types';
import { decorate, reaction, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import EventDetailedStore from '../../stores/EventDetailedStore';
import Access from '../Access';
import MedicationOrder from '../MedicationOrder';
import MedicationAdministration from '../MedicationAdministration';
import MedicationHandling from '../MedicationHandling';
import MedicationDiscrepancy from '../MedicationDiscrepancy';
import TimeEntry from '../TimeEntry';
import Tooltip from '../../../ui/Tooltip';

const EventDetails = observer(
  class extends React.Component {
    static propTypes = {
      getActivityDescription: PropTypes.func.isRequired,
      activityDefsMap: PropTypes.object.isRequired,
    };

    constructor(props) {
      super(props);

      const disposers = [];
      disposers.push(
        reaction(
          () => [EventDetailedStore.detailView],
          () => {
            this.open = true;
          }
        )
      );
    }

    // Observable
    open = true;

    closeDrawer() {
      setTimeout(() => {
        this.open = false;
        EventDetailedStore.detailFocus = null;
      }, 333);
    }

    renderDetails() {
      const focusedEvent = EventDetailedStore.detailView || {};
      const { getActivityDescription, activityDefsMap } = this.props;

      if (focusedEvent.class === 'access')
        return (
          <Access
            access={focusedEvent}
            getActivityDescription={getActivityDescription}
            forceRerender={{ activityDefsMap }}
          />
        );
      if (focusedEvent.class === 'order')
        return <MedicationOrder order={focusedEvent} />;
      if (focusedEvent.class === 'administration')
        return <MedicationAdministration administration={focusedEvent} />;
      if (focusedEvent.class === 'handling')
        return <MedicationHandling handling={focusedEvent} />;
      if (focusedEvent.class === 'discrepancy')
        return <MedicationDiscrepancy discrepancy={focusedEvent} />;
      if (focusedEvent.class === 'timeEntry')
        return <TimeEntry timeEntry={focusedEvent} />;

      return null;
    }

    render() {
      const cn = classnames('eventLogDetails', {
        openDrawer: this.open && EventDetailedStore.detailView,
      });

      return (
        <div className={cn}>
          <span
            className="tooltip"
            onClick={this.closeDrawer.bind(this)}
            role="button"
            tabIndex={-10}
          >
            <Tooltip content="Close">
              <i className="material-icons icon-close" />
            </Tooltip>
          </span>
          {this.renderDetails()}
        </div>
      );
    }
  }
);

decorate(EventDetails, {
  open: observable,
});

EventDetails.displayName = 'EventDetails';

export default EventDetails;
