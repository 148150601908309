import IncidentReason from './IncidentReason';
import { IncidentContext } from './Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';

export function mapProps({ incidentStateStore, incident, link }) {
  return {
    addNote: incidentStateStore.addNote,
    deleteNote: incidentStateStore.deleteNote,
    hasUnsavedNotes: incidentStateStore.hasUnsavedNotes,
    incident,
    link,
    loading: incidentStateStore.loading,
    notes: incident.notes,
    reopen: incidentStateStore.reopen,
    resolve: incidentStateStore.resolve,
    setIncidentNoteDirty: incidentStateStore.setIncidentNoteDirty,
  };
}

const IncidentReasonContainer = observerWrappedContainerFactory({
  Context: IncidentContext,
  mapProps: mapProps,
  PresentationalComponent: IncidentReason,
});

IncidentReasonContainer.displayName = 'IncidentReasonContainer';

export default IncidentReasonContainer;
