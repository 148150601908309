import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Use for refs when you need the component to re-render when the ref is set.
 *
 * @return {[HTMLElement, function(HTMLElement): void]}
 */
export const useReactiveRef = () => {
  const [ref, setRef] = useState(null);
  const refCallback = useCallback(
    node => {
      if (node !== null && ref !== node) {
        setRef(node);
      }
    },
    [ref]
  );

  return [ref, refCallback];
};

/**
 * A hook that provides a store instance to be used for the lifetime of the
 * component asking for it.
 *
 * @param createStore {Function} A function that creates a store instance.
 * @return {Object} A store instance.
 */
export const useEphemeralStore = createStore => {
  // The challenge with using useState() for store creation is that a new store
  // would be created every time useState(new Store()) is invoked.

  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = createStore();
  }
  const store = storeRef.current;

  // Clean up the store if needed when unmounting.
  useEffect(() => {
    return store?.tearDown;
  }, [store]);

  return store;
};

/**
 * A hook that matches the useState interface but gets reset to the provided
 * prop value whenever it changes.
 *
 * @param propValue {*}
 * @return {[unknown, ((value: unknown) => void)]}
 */
export const useStateMatchProp = propValue => {
  // Initial state matches the prop.
  const [state, setState] = useState(propValue);

  // Any time the prop is updated, set the state to that new value.
  useEffect(() => {
    setState(propValue);
  }, [propValue, setState]);

  return [state, setState];
};

/**
 * A hook that provides the previously known value.
 *
 * @param value {*} Current value.
 * @return {*} Previously known value.
 */
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
