import React from 'react';
import { observer } from 'mobx-react';
import MultiRecordCompositeFilteredSection from '../MultiRecordCompositeFilteredSection';

export function mapProps({
  multiRecordCompositeSectionFilterStore,
  name,
  groups,
  isCountVisible,
  isExpandable,
  expansionThreshold,
}) {
  return {
    filterTerm: multiRecordCompositeSectionFilterStore.filterTerm,
    matchingGroups: multiRecordCompositeSectionFilterStore.matchingGroups,
    nonMatchingGroups: multiRecordCompositeSectionFilterStore.nonMatchingGroups,
    setFilterTerm: multiRecordCompositeSectionFilterStore.setFilterTerm,
    setGroups: multiRecordCompositeSectionFilterStore.setGroups,
    setSearchVisible: multiRecordCompositeSectionFilterStore.setSearchVisible,
    searchVisible: multiRecordCompositeSectionFilterStore.searchVisible,
    filterGroups: multiRecordCompositeSectionFilterStore.groups,
    name,
    groups,
    isCountVisible,
    isExpandable,
    expansionThreshold,
  };
}

const MultiRecordCompositeSectionFilterContainer = observer(
  ({
    multiRecordCompositeSectionFilterStore,
    name,
    groups,
    isCountVisible,
    isExpandable,
    expansionThreshold,
  }) => (
    <MultiRecordCompositeFilteredSection
      {...mapProps({
        multiRecordCompositeSectionFilterStore,
        name,
        groups,
        isCountVisible,
        isExpandable,
        expansionThreshold,
      })}
    />
  )
);

MultiRecordCompositeSectionFilterContainer.displayName = MultiRecordCompositeSectionFilterContainer;
export default MultiRecordCompositeSectionFilterContainer;
