import { reaction, computed, decorate } from 'mobx';

import { PagedStore } from 'common';
import queryString from 'query-string';

/**
 * Houses the app audit logs a user has created by using the application.
 */
class AuditLogDetailStore extends PagedStore {
  constructor({ appAuditClient, auditLogStore, auditLogFilterStore }) {
    super();

    this.appAuditClient = appAuditClient;
    this.auditLogStore = auditLogStore;
    this.auditLogFilterStore = auditLogFilterStore;

    this.disposers = [
      reaction(
        () => [
          this.auditLogStore.user,
          this.auditLogStore.sort,
          this.auditLogFilterStore.dates[0],
          this.auditLogFilterStore.dates[1],
        ],
        inputs => {
          if (inputs.filter(Boolean).length === 4) {
            this.refresh();
          }
        },
        { fireImmediately: true }
      ),
    ];
  }

  tearDown = () => {
    this.disposers.forEach(d => d());
  };

  fetch() {
    return this.appAuditClient.getUserActivity(
      this.auditLogFilterStore.selectedUsers.length !== 0
        ? this.auditLogFilterStore.selectedUsers[0].value
        : null,
      this.auditLogFilterStore.dates[0],
      this.auditLogFilterStore.dates[1],
      this.auditLogStore.sort
    );
  }

  get userData() {
    return (this.results ?? []).map(log => {
      const endParam = log.referrer.indexOf('?');
      const searchCriteria = queryString.parse(log.referrer.slice(endParam))
        ?.criteria;

      return {
        ...log,
        action: searchCriteria ? 'Query' : 'View',
      };
    });
  }
}

decorate(AuditLogDetailStore, {
  userData: computed,
});

export { AuditLogDetailStore };
