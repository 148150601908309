import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';

const MRN = ({ rows, rowKey, rowIndex }) => {
  const row = rows[rowIndex][rowKey];
  let mrn;
  if (row && row.medicalRecordNumbers) {
    if (row.medicalRecordNumbers.length === 0) return null;
    if (row.medicalRecordNumbers.length > 1)
      mrn = `${row.medicalRecordNumbers.length} MRNs`;
    else mrn = row.medicalRecordNumbers[0];
  }
  return <Cell>{mrn}</Cell>;
};

MRN.propTypes = {
  rows: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      inputPatient: PropTypes.shape({
        medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
          PropTypes.string
        ),
      }),
      matchedPatient: PropTypes.shape({
        medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
          PropTypes.string
        ),
      }),
    })
  ),
  rowIndex: PropTypes.number,
  rowKey: PropTypes.string,
};

MRN.defaultProps = {
  rows: [
    {
      inputPatient: null,
      matchedPatient: null,
    },
  ],
  rowIndex: null,
  rowKey: null,
};

export default MRN;
