import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { RouterContainer } from 'common';
import NotificationStore from '../../stores/NotificationStore';
import CreateStore from '../stores/CreateStore';
import { Button, DatePicker, MultilineInput } from '../../ui';
import styles from './index.module.scss';

const CreateForm = observer(
  class CreateForm extends React.Component {
    static propTypes = {
      reset: PropTypes.func,
    };
    // Observables
    duplicate = false;
    endDate = null;
    reason = '';

    onSubmit(e) {
      e.preventDefault();
      const form = {};

      if (this.reason) form.reason = this.reason;
      if (this.endDate) form.endDate = this.endDate.format('YYYY-MM-DD');

      CreateStore.create(form).then(
        () => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Successfully created VIP registration
            </span>
          );
          NotificationStore.add({ level: 'success', content });
          RouterContainer.go('/vips');
        },
        r => {
          let msg = 'There was a problem creating this VIP registration.';
          if (r.status === 409) {
            // 409 is HTTP Conflict, disable the submit button. Otherwise, let them try again
            this.duplicate = true;
            msg = 'A VIP registration already exists for this patient.';
          }
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              {msg}
            </span>
          );
          NotificationStore.add({ level: 'warning', content });
        }
      );
    }

    reset() {
      if (this.props.reset) this.props.reset();
    }

    onEndChange(date) {
      const isValid =
        date === null ||
        moment(date)
          .startOf('day')
          .isSameOrAfter(moment().startOf('day'));
      if (isValid) this.endDate = moment(date);
      else this.endDate = moment();
    }

    render() {
      return (
        <form
          className="record__create-form record__form"
          onSubmit={this.onSubmit.bind(this)}
        >
          <ul className="no-gutter">
            <div className={styles.infoCard}>
              <li className={styles.expirationLi}>
                <DatePicker
                  label="Expiration Date - Optional"
                  className={styles.smallMargin}
                  onChange={this.onEndChange.bind(this)}
                  minDate={moment.now()}
                  value={this.endDate}
                />
                <p className={styles.expirationText}>
                  Specify a date in the future when the VIP Registration should
                  expire.
                </p>
              </li>
              <li>
                <MultilineInput
                  bordered
                  value={this.reason}
                  label="VIP Reason - Optional"
                  onChange={val => (this.reason = val)}
                  data-cy="create-vip--multiline-input"
                />
              </li>
            </div>
            <li className="form__actions">
              <Button type="submit" value="Submit" disabled={this.duplicate} />
              <Link
                to="/vips"
                onClick={this.reset.bind(this)}
                className="button button--link"
              >
                Cancel
              </Link>
            </li>
          </ul>
        </form>
      );
    }
  }
);

decorate(CreateForm, {
  duplicate: observable,
  endDate: observable,
  reason: observable,
});

CreateForm.displayName = 'CreateForm';

export default CreateForm;
