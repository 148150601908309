import { action, computed, decorate } from 'mobx';

import { SingletonStore } from 'common';

class CaseBundleIndicatorStore extends SingletonStore {
  constructor({ caseId, caseBundleClient }) {
    super();
    this.caseId = caseId;
    this.caseBundleClient = caseBundleClient;

    this.refresh();
  }

  // Computed
  get bundles() {
    return this.result || [];
  }

  setCaseId = caseId => {
    if (this.caseId !== caseId) {
      this.caseId = caseId;
      this.refresh();
    }
  };

  fetch() {
    if (!this.caseId) return [];

    return this.caseBundleClient.getAllMin({
      withCase: this.caseId,
    });
  }
}

decorate(CaseBundleIndicatorStore, {
  bundles: computed,
  setCaseId: action,
});

export default CaseBundleIndicatorStore;
