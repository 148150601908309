import React from 'react';

export const FlagContext = React.createContext({
  flags: [],
  setFlags: () => {},
});

/**
 * Used to scroll an element relative to something higher up in the component
 * tree.
 * @type {React.Context<{scrollIntoView: Function}>}
 */
export const ScrollIntoViewContext = React.createContext({
  scrollIntoView: () => {},
});
