import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Pandy } from 'common';
import IncidentGroup from '../../../ui/IncidentGroupList/IncidentGroup';
import { groupIncidents } from '../../../utils/IncidentUtils';
import styles from './index.module.scss';
import moment from 'moment';

const CaseMedicationIncidents = ({ filteredIncidents, lastViewedByUser }) => {
  // Checks whether the incident has been viewed
  const showNewIcon = date => {
    return (
      date &&
      (!lastViewedByUser ||
        moment(date)
          .add(1, 'hour')
          .isAfter(moment(lastViewedByUser)))
    );
  };
  return (
    <>
      {filteredIncidents?.length ? (
        Object.entries(
          groupIncidents(filteredIncidents)
        ).map(([key, incidentArray]) => (
          <IncidentGroup
            key={key}
            incidents={incidentArray}
            showNewIcon={showNewIcon(incidentArray[0].created)}
          />
        ))
      ) : (
        <Pandy visible={true} mood="sad" className={styles.pandyWrapper}>
          <div className="speechBubble">
            <span>No matches. Sorry!</span>
          </div>
        </Pandy>
      )}
    </>
  );
};

CaseMedicationIncidents.propTypes = {
  filteredIncidents: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  lastViewedByUser: PropTypes.string,
};

CaseMedicationIncidents.defaultProps = {
  filteredIncidents: [],
  lastViewedByUser: '',
};

CaseMedicationIncidents.displayName = 'CaseMedicationIncidents';

export default CaseMedicationIncidents;
