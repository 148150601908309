import React, { useEffect } from 'react';
import moment from 'moment';
import { DropdownList } from 'react-widgets';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { CategoryComponent } from 'common';
import classnames from 'classnames';
import CaseHeader from '../../CaseHeader';
import CaseViewAsideContainer from '../../caseAside/CaseViewAsideContainer';

import styles from './index.module.scss';
import {
  ApplyBodyClassName,
  Button,
  DatePicker,
  DocumentTitle,
} from '../../../ui';

const GRCCaseForm = ({
  canSubmit,
  caseType,
  caseResolution,
  caseId,
  categories,
  editing,
  endDate,
  failureStatus,
  onChange,
  reset,
  startDate,
  submitButtonValue,
  submitFunction,
  submitting,
  title,
}) => {
  useEffect(() => {
    onChange('caseId', caseId);
  }, [caseId, onChange]);

  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  if (
    (caseType && caseType !== 'GRC') ||
    failureStatus === 404 ||
    caseResolution
  )
    return <Redirect to={`/case/${caseId}`} />;

  return (
    <main className="case_view__body">
      <DocumentTitle text="GRC Case Form" />
      <ApplyBodyClassName className="case_view" />
      <section className={styles.section}>
        <CaseHeader
          editing
          onChange={val => {
            onChange('title', val);
          }}
          title={title}
          printable={false}
        />
        <div className={styles.pickers}>
          <DatePicker
            onChange={(date, valid) => {
              if (valid) onChange('startDate', moment(date).startOf('day'));
            }}
            label="Case Event Start Date"
            placeholder="Give this case a Start Date"
            value={startDate}
            maxDate={endDate}
          />
          <DatePicker
            label="Case Event End Date"
            placeholder="Give this case an End Date"
            onChange={(date, valid) => {
              if (valid) onChange('endDate', moment(date).endOf('day'));
            }}
            value={endDate}
            minDate={startDate}
            maxDate={moment()}
          />
          {!editing && (
            <DropdownList
              onChange={category => {
                onChange('category', category);
              }}
              data={categories}
              defaultValue={{ name: 'Give this case a category' }}
              textField="name"
              valueField="id"
              valueComponent={CategoryComponent}
              itemComponent={CategoryComponent}
            />
          )}
          <div className={styles.buttonDiv}>
            <div className={styles.button}>
              <Button
                type="submit"
                disabled={submitting || !canSubmit}
                onClick={submitFunction}
                value={submitting ? 'Submitting...' : submitButtonValue}
              />
            </div>
            <div
              className={classnames(styles.button, {
                hidden: !editing,
              })}
            >
              <Link to={`/case/${caseId}`}>
                <Button value="Cancel" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <CaseViewAsideContainer />
    </main>
  );
};

GRCCaseForm.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  caseId: PropTypes.string,
  caseResolution: PropTypes.string,
  caseType: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  editing: PropTypes.bool,
  endDate: PropTypes.instanceOf(moment).isRequired,
  failureStatus: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
  submitFunction: PropTypes.func.isRequired,
  submitButtonValue: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

GRCCaseForm.defaultProps = {
  caseId: null,
  caseType: null,
  caseResolution: null,
  categories: [],
  editing: false,
  failureStatus: null,
  title: null,
};

export default GRCCaseForm;
