import React from 'react';
import MultiRecordTopNav from '../MultiRecordTopNav';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observer } from 'mobx-react';

export function mapProps({ match, location, query }) {
  return {
    path: location?.pathname,
    employeeId: match?.params?.employeeId,
    patientId: match?.params?.patientId,
    query,
  };
}

const MultiRecordTopNavContainer = observer(({ location, match, query }) => (
  <MultiRecordTopNav {...mapProps({ location, match, query })} />
));

MultiRecordTopNavContainer.displayName = 'MultiRecordTopNavContainer';

export default withRouterAndQuery(MultiRecordTopNavContainer);
