import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const ListItem = ({ label, value }) => {
  return (
    <li className={styles.listItem}>
      <div className={styles.label}>
        <strong>{label}</strong>{' '}
      </div>
      <div className={styles.value}>
        {value ? value : <em>None Provided</em>}
      </div>
    </li>
  );
};

ListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node,
};

ListItem.defaultProps = {
  value: '',
};

export default ListItem;
