import React from 'react';
import { DataFeedContext } from '../Context';
import { Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute, DataFeedClient, HalUtils } from 'common';
import { DocumentTitle } from '../../ui';
import { DeliveryStore } from '../stores/DeliveryStore';
import { EvaluationStore } from '../stores/EvaluationStore';
import { DeliveryFilterStore } from '../stores/DeliveryFilterStore';
import { processGroupsData } from '../stores/EvaluationStore/helpers';
import DataFeedDeliveryViewContainer from '../DataFeedDeliveryViewContainer';
import DataFeedEvaluationViewContainer from '../DataFeedEvaluationViewContainer';
import DataFeedTopNavContainer from '../DataFeedTopNavContainer';
import styles from './index.module.scss';
import { useEphemeralStore } from '../../utils/hooks';

export const DataFeedRootView = () => {
  const deliveryStore = useEphemeralStore(
    () =>
      new DeliveryStore({
        dataFeedClient: DataFeedClient,
      })
  );
  const deliveryFilterStore = useEphemeralStore(
    () =>
      new DeliveryFilterStore({
        dataFeedClient: DataFeedClient,
        halUtils: HalUtils,
      })
  );
  const evaluationStore = useEphemeralStore(
    () =>
      new EvaluationStore({
        dataFeedClient: DataFeedClient,
        halUtils: HalUtils,
        processGroupsData: processGroupsData,
      })
  );

  return (
    <>
      <DocumentTitle text="File Delivery Dashboard" />
      <DataFeedContext.Provider
        value={{
          deliveryStore,
          deliveryFilterStore,
          evaluationStore,
        }}
      >
        <DataFeedTopNavContainer />
        <div className={styles.page}>
          <Switch>
            <ProtectedRoute
              exact
              path="/data-feed/delivery"
              component={DataFeedDeliveryViewContainer}
            />
            <ProtectedRoute
              exact
              path="/data-feed/evaluation"
              component={DataFeedEvaluationViewContainer}
            />
            <Redirect to="/data-feed/evaluation" />
          </Switch>
        </div>
      </DataFeedContext.Provider>
    </>
  );
};

export default DataFeedRootView;
