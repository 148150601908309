import { reaction, computed, decorate } from 'mobx';

import {
  HalUtils,
  PermissionStore,
  AuthorizationClient,
  SingletonStore,
} from 'common';
import CaseStore from '../CaseStore';

class CaseAuthorizationsStore extends SingletonStore {
  /**
   * Initialize the class instance, set a method that runs whenever an
   * observed property changes
   * @constructor
   */
  constructor() {
    super();

    reaction(
      () => [CaseStore.case],
      () => {
        this.refresh();
      }
    );
  }

  // Computed
  get auths() {
    return (this.result || []).filter(aa => aa.type !== 'TEMPORARY_PERMISSION');
  }

  // Computed
  get permissions() {
    return (this.result || []).filter(aa => aa.type === 'TEMPORARY_PERMISSION');
  }

  // Computed
  get existingPermissionForCase() {
    return this.permissions.find(
      p => (p.relatedCase && p.relatedCase.id) === CaseStore.caseId
    );
  }

  fetch() {
    if (
      !PermissionStore.has('ACCESS_AUTH_SEARCH') ||
      !CaseStore.userId ||
      !CaseStore.patientId
    )
      return null;
    return AuthorizationClient.getReport({
      user: CaseStore.userId,
      patient: CaseStore.patientId,
      requestedEndBefore: CaseStore.maxEventDate,
      startAfter: CaseStore.minEventDate,
    }).then(r => HalUtils.getData(r));
  }
}

decorate(CaseAuthorizationsStore, {
  auths: computed,
  existingPermissionForCase: computed,
  permissions: computed,
});

export default new CaseAuthorizationsStore();
