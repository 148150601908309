import DataFeedDeliveryTable from '../DataFeedDeliveryTable';
import { DataFeedContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';

export function mapProps({ deliveryStore }) {
  return {
    loaded: !deliveryStore.loading,
    noResults: !deliveryStore.loading && deliveryStore.totalElements === 0,
    data: deliveryStore.results,
    sortStore: deliveryStore.currentSort,
    store: deliveryStore,
  };
}

const DataFeedDeliveryTableContainer = observerWrappedContainerFactory({
  Context: DataFeedContext,
  mapProps: mapProps,
  PresentationalComponent: DataFeedDeliveryTable,
});

export default DataFeedDeliveryTableContainer;
