import React from 'react';
import $ from 'jquery';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { SmallPandy } from 'common';
import Spinner from '../Spinner';
import styles from './index.module.scss';

function checkEquals(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  const matches = arr1.reduce((acc, val, idx) => {
    if (val === arr2[idx]) acc.push(val);
    return acc;
  }, []);

  return matches.length === arr1.length;
}

/*
 * StaggeredList component will render an array of children spaced out with some rendering animation
 *
 * StaggeredList has an optional 'scroll to top' which is triggered with an array of watched elements
 * When the elements change the list will scroll to the top. elementToScroll is a
 * required className so the component knows what element to scroll
 *
 * StaggeredList also uses an optional 'end of list' pandy.
 * `useSmallPandy` determines if pandy is in use based on `pandyHasMore` and
 * the height of the results vs the height of `parentNodeRef`.
 * pandy can also take an optional string to customize her speech.
 */

class StaggeredList extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    drag: PropTypes.number,
    useSmallPandy: PropTypes.bool,
    pandyText: PropTypes.string,
    pandyHasMore: PropTypes.bool,
    stagger: PropTypes.number,
    toRemove: PropTypes.string,
    toAdd: PropTypes.string,
    topOfListScrollValues: PropTypes.shape({
      elementToScroll: PropTypes.string,
      watchedElements: PropTypes.array,
    }),
    parentNodeRef: PropTypes.instanceOf(HTMLElement),
  };

  static defaultProps = {
    drag: 25,
    pandyHasMore: false,
    pandyText: '',
    stagger: 100,
    toRemove: '',
    toAdd: '',
    topOfListScrollValues: {
      elementToScroll: null,
      watchedElements: [],
    },
    useSmallPandy: false,
  };

  constructor() {
    super();
    this._nextIndex = 0;
    this._batchIndex = 0;
  }

  state = {
    canShowAutoPandy: false,
  };

  componentDidMount() {
    this.updateNext();
    this.checkCanShowAutoPandy();

    window.addEventListener('resize', this.checkCanShowAutoPandy);
  }

  componentDidUpdate(prevProps) {
    if (
      !checkEquals(
        prevProps.topOfListScrollValues.watchedElements,
        this.props.topOfListScrollValues.watchedElements
      )
    ) {
      this._batchIndex = 0;
      this._nextIndex = this.props.children.length;
      this.scroll();
    }
    this.updateNext();
    this.checkCanShowAutoPandy();
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
    window.removeEventListener('resize', this.checkCanShowAutoPandy);
  }

  scroll = () => {
    const {
      topOfListScrollValues: { elementToScroll },
    } = this.props;

    $(`.${elementToScroll}`).animate(
      {
        scrollTop: 0,
      },
      700
    );
  };

  updateNext() {
    clearTimeout(this._timer);

    const me = $(this.list);
    const children = me.children().not('.tinyPandy');
    const { stagger, drag, toAdd, toRemove } = this.props;

    // apply the changes again to all the children up to the index, in case it has been
    // clobbered by react updates :-/
    const child = $(children.slice(0, this._nextIndex));
    const delay = stagger + this._batchIndex * drag;

    child.addClass(toAdd);
    child.removeClass(toRemove);

    if (this._nextIndex < children.length) {
      this._nextIndex++;
      this._batchIndex++;
      this._timer = setTimeout(() => {
        this.updateNext();
      }, delay);
    } else {
      this._timer = null;
    }
  }

  checkCanShowAutoPandy = () => {
    // Check if the rendered list content is taller than the parent node.
    const { parentNodeRef, pandyHasMore } = this.props;

    const { list } = this;
    if (!parentNodeRef || !list) return;

    // Check if the list's height is greater than its scroll parent's height.
    // Use list.scrollHeight to ensure we get the full height of the element
    // offscreen. Use parentNodeRef.clientHeight to ensure we're only counting
    // the visible portion of it.
    const newCanShowAutoPandy =
      !pandyHasMore && list.scrollHeight > parentNodeRef.clientHeight;

    this.setState(({ canShowAutoPandy }) => {
      if (newCanShowAutoPandy !== canShowAutoPandy) {
        return { canShowAutoPandy: newCanShowAutoPandy };
      }
    });
  };

  render() {
    /* eslint-disable no-unused-vars */
    const {
      toRemove,
      toAdd,
      drag,
      stagger,
      pandyHasMore,
      pandyText,
      topOfListScrollValues,
      children,
      useSmallPandy,
      parentNodeRef,
      ...other
    } = this.props;
    const { canShowAutoPandy } = this.state;

    return (
      <ul ref={node => (this.list = node)} {...other}>
        {children}
        {useSmallPandy && (
          <SmallPandy visible={canShowAutoPandy}>
            <div className="speechBubble">
              <span>{`All ${pandyText || 'Results'} Loaded`}</span>
            </div>
          </SmallPandy>
        )}
        {pandyHasMore && (
          <div className={styles.spinner}>
            <Spinner dark />
          </div>
        )}
      </ul>
    );
  }
}

export default observer(StaggeredList);
