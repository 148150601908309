import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';

export const person = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.string,
});

const administrations = MobxPropTypes.arrayOrObservableArrayOf(
  PropTypes.shape({
    length: PropTypes.string,
    startTime: PropTypes.string,
    recordTime: PropTypes.string,
    id: PropTypes.string,
    medicationOrder: PropTypes.shape({
      id: PropTypes.string,
    }),
    actionString: PropTypes.string,
  })
);

const handlings = MobxPropTypes.arrayOrObservableArrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    eventTime: PropTypes.string,
    medicationOrder: PropTypes.shape({}),
    formattedAction: PropTypes.string,
  })
);

const medications = MobxPropTypes.arrayOrObservableArrayOf(
  PropTypes.shape({
    brandName: PropTypes.string,
    genericName: PropTypes.string,
    form: PropTypes.string,
    strength: PropTypes.shape({}),
    volume: PropTypes.shape({}),
  })
);

export default { administrations, handlings, medications, person };
