import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { CaseCustomPrintContext } from '../../Context';
import CaseflowPrintOptions from '../CaseflowPrintOptions';

export function mapProps({
  caseCustomPrintStore,
  caseStore,
  assessmentsPrintOptionsStore,
}) {
  return {
    selectedCaseflowOption: caseCustomPrintStore.selectedCaseflowOption,
    caseflowOptions: caseCustomPrintStore.caseflowOptions,
    caseflowFieldsToInclude: caseCustomPrintStore.caseflowFieldsToInclude,
    caseflowFields: caseCustomPrintStore.caseflowFields,
    handleCaseflowOnChange: caseCustomPrintStore.handleCaseflowOnChange,
    setCaseflowFieldsToInclude: caseCustomPrintStore.setCaseflowFieldsToInclude,
    setCaseflowPrintOptionsForCaseStore:
      caseStore.setCaseflowPrintOptionsForCaseStore,
    filteredAssessmentsForPrint:
      assessmentsPrintOptionsStore.filteredAssessmentsForPrint,
  };
}

const CaseflowPrintOptionsContainer = observerWrappedContainerFactory({
  Context: CaseCustomPrintContext,
  mapProps,
  PresentationalComponent: CaseflowPrintOptions,
});

export default CaseflowPrintOptionsContainer;
