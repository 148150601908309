import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Authenticated } from 'common';

import styles from './index.module.scss';

const DrawerNav = ({ activeTab, onChange, suspicionScores, tabs, type }) => {
  let suspicionValue = 'Unknown';
  if (suspicionScores.length) {
    const min = Math.min(...suspicionScores);
    const max = Math.max(...suspicionScores);

    suspicionValue = min === max ? min : `${min} - ${max}`;
  }

  return (
    <div className={styles.drawerNav}>
      <ul>
        {tabs.map(tab => (
          <li
            className={classnames('button', {
              [styles.tab]: true,
              [styles.activeTab]: tab === activeTab,
            })}
            key={tab}
            onClick={() => onChange(tab)}
            onKeyPress={e => {
              if (e.keyCode === 13) onChange(tab);
            }}
            role="button"
            tabIndex={-1}
            data-cy={tab === activeTab ? 'active-drawer-tab' : ''}
          >
            {tab}
          </li>
        ))}
        <li className={styles.suspicionScore}>
          <Authenticated>
            {`${type} Suspicion Score: `}
            <strong>{suspicionValue}</strong>
          </Authenticated>
        </li>
      </ul>
    </div>
  );
};

DrawerNav.propTypes = {
  activeTab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  suspicionScores: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

DrawerNav.defaultProps = {
  suspicionScores: [],
};

export default DrawerNav;
