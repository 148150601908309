import { SingletonStore } from 'common';
import { action, computed, decorate, observable, reaction } from 'mobx';
import { generateMetadataTypes } from '../../utils/helpers';

class MultiRecordPersonMetadataStore extends SingletonStore {
  constructor({ multiRecordViewClient, personType }) {
    super();
    this.multiRecordViewClient = multiRecordViewClient;
    this.personType = personType;

    this.disposer = reaction(
      () => [this.personMetadata],
      () => {
        this.personMetadata && this.setMetadataTypes(this.personMetadata);
      }
    );
  }

  disposer;

  tearDown = () => this.disposer();

  get personMetadata() {
    return (
      this.result?.personMetadata.find(
        person => person.personType === this.personType
      ) || []
    );
  }

  metadataTypes;

  setMetadataTypes = personMetadata => {
    this.metadataTypes = generateMetadataTypes(personMetadata, this.personType);
  };

  get metadataTypeColumns() {
    // all metadata for this personType split between two columns for layout purposes (when we display two column view of metadata details)
    return this.metadataTypes
      ? [this.metadataTypes.slice(0, 3), this.metadataTypes.slice(3, 6)]
      : [];
  }

  currentMatch;

  setCurrentMatch = match => {
    this.currentMatch = match;
    this.refresh();
  };

  fetch() {
    const { employeeId, patientId, caseId } = this.currentMatch.params;
    const params = {};

    if (employeeId) params.userId = employeeId;
    if (patientId) params.patientId = patientId;
    if (caseId) params.caseId = caseId;

    return this.multiRecordViewClient.getPersonMetadata(params);
  }
}

decorate(MultiRecordPersonMetadataStore, {
  currentMatch: observable,
  metadataTypes: observable,
  metadataTypeColumns: computed,
  personMetadata: computed,
  setCurrentMatch: action,
});

export { MultiRecordPersonMetadataStore };
