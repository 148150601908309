import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { LoginStore, PermissionStore } from 'common';
import CaseStore from '../../stores/CaseStore';
import OwnerSelect from '../OwnerSelect';

import classnames from 'classnames';
import styles from './index.module.scss';

const AssignForm = class AssignForm extends React.Component {
  onAssignToMe = () => {
    CaseStore.setNewOwner(LoginStore.id);
  };

  renderAssignToMe() {
    if (
      PermissionStore.has('CASE_ASSIGN') &&
      !this.props.readOnly &&
      CaseStore.owner !== LoginStore.id
    ) {
      return (
        <span className="strong header-link prot-a" onClick={this.onAssignToMe}>
          Assign To Me
        </span>
      );
    }
  }

  render() {
    const defaultValue = CaseStore.owner || 'Unassigned';
    const value = CaseStore.newOwner || defaultValue;

    return (
      <div className={classnames('item', styles.assignSection)}>
        {this.renderAssignToMe(value)}
        <h5>Owner</h5>
        <ul className="list-unstyled">
          <li>
            <OwnerSelect
              dropUp
              value={value}
              defaultValue={defaultValue}
              onChange={CaseStore.setNewOwner}
              {...this.props}
            />
          </li>
        </ul>
      </div>
    );
  }
};

AssignForm.propTypes = {
  readOnly: PropTypes.bool,
};

AssignForm.displayName = 'AssignForm';

export default observer(AssignForm);
