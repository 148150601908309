import React from 'react';
import PropTypes from 'prop-types';
import CaseIntegrationTable from '../../CaseIntegration/Table';
import { TABLE_CELL_TYPE } from '../../CaseIntegration/const';
import styles from './index.module.scss';

const protenusFromRadar = [
  [
    { type: TABLE_CELL_TYPE.lock, text: 'Incident ID' },
    {
      type: TABLE_CELL_TYPE.source,
      text: 'Incident ID',
      arrowBack: false,
      bold: true,
    },
  ],
  [
    {
      type: TABLE_CELL_TYPE.lock,
      text: 'Link to Incident',
    },
    {
      type: TABLE_CELL_TYPE.source,
      text: 'Link to Incident',
      arrowBack: false,
      bold: true,
    },
  ],
];

const RadarIntegrationTable = ({ caseSyncSettings, setCaseSyncSettings }) => {
  const onChangeForSetting = setting => () =>
    setCaseSyncSettings(setting, !caseSyncSettings[setting]);

  const protenusToRadar = [
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Incident Name', bold: true },
      { type: TABLE_CELL_TYPE.source, text: 'Case Title' },
    ],
    [
      { type: TABLE_CELL_TYPE.lock, text: 'Incident Method', bold: true },
      { type: TABLE_CELL_TYPE.source, text: 'Case number & Link to case' },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Discovered Date',
        isChecked: caseSyncSettings?.discovered,
        onChange: onChangeForSetting('discovered'),
        bold: true,
      },
      { type: TABLE_CELL_TYPE.source, text: 'Creation date' },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'First Informed Date',
        isChecked: caseSyncSettings?.firstInformedAt,
        onChange: onChangeForSetting('firstInformedAt'),
        bold: true,
      },
      { type: TABLE_CELL_TYPE.source, text: 'Creation date' },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Occurred Date',
        isChecked: caseSyncSettings?.occurredOn,
        onChange: onChangeForSetting('occurredOn'),
        bold: true,
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'Date of earliest access in case',
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Description',
        isChecked: caseSyncSettings?.description,
        onChange: onChangeForSetting('description'),
        bold: true,
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'Category & Assessment reasons',
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Individuals Impacted',
        isChecked: caseSyncSettings?.individualsImpacted,
        onChange: onChangeForSetting('individualsImpacted'),
        bold: true,
      },
      { type: TABLE_CELL_TYPE.source, text: 'Patient' },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Department',
        isChecked: caseSyncSettings?.department,
        onChange: onChangeForSetting('department'),
        bold: true,
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: `User’s Department`,
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Employee Involved',
        isChecked: caseSyncSettings?.employeeInvolved,
        onChange: onChangeForSetting('employeeInvolved'),
        bold: true,
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'User',
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Investigation Start Date',
        isChecked: caseSyncSettings?.startDate,
        onChange: onChangeForSetting('startDate'),
        bold: true,
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'Earliest date of activity of a Protenus user in the case',
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Remediation Comments',
        isChecked: caseSyncSettings?.remediation,
        onChange: onChangeForSetting('remediation'),
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'Final assessment',
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Investigation End Date',
        isChecked: caseSyncSettings?.endDate,
        onChange: onChangeForSetting('endDate'),
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'Date resolution was set on the case',
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Notes',
        isChecked: caseSyncSettings?.caseNotes,
        onChange: onChangeForSetting('caseNotes'),
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'Case notes',
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Notes',
        isChecked: caseSyncSettings?.caseResolution,
        onChange: onChangeForSetting('caseResolution'),
      },
      {
        type: TABLE_CELL_TYPE.source,
        text: 'Case resolution',
      },
    ],
  ];

  return (
    <div
      className={styles.radarIntegrationTable}
      data-cy="integration-radar-table"
    >
      <div className={styles.topSpacing}>
        <p>Fields to be added to Protenus from Radar Incident:</p>
        <CaseIntegrationTable
          data={protenusFromRadar}
          headers={['Radar Incident Field', 'Added to Protenus Data As']}
        />
        <p className={styles.topSpacing}>
          Fields to be added to Radar Incident from Protenus:
        </p>
        <CaseIntegrationTable
          data={protenusToRadar}
          headers={['Radar Incident Field', 'Protenus Source']}
        />
        <h3 className={styles.topSpacing}>
          <i className="material-icons icon-notifications tip" />
          Tip: Fields in bold will be synced when the Radar Incident is created,
          but will never be updated. Non-bold fields will be updated every time
          the case is synced.
        </h3>
      </div>
    </div>
  );
};

RadarIntegrationTable.propTypes = {
  caseSyncSettings: PropTypes.shape({
    discovered: PropTypes.bool,
    firstInformedAt: PropTypes.bool,
    occurredOn: PropTypes.bool,
    description: PropTypes.bool,
    individualsImpacted: PropTypes.bool,
    department: PropTypes.bool,
    employeeInvolved: PropTypes.bool,
    startDate: PropTypes.bool,
    remediation: PropTypes.bool,
    endDate: PropTypes.bool,
    caseNotes: PropTypes.bool,
    caseActions: PropTypes.bool,
    caseResolution: PropTypes.bool,
  }),
  setCaseSyncSettings: PropTypes.func,
};

export default RadarIntegrationTable;
