import { computed, autorun, decorate } from 'mobx';
import moment from 'moment';

import { PermissionStore, SingletonStore, CaseClient } from 'common';

class FiltersBannerStore extends SingletonStore {
  constructor() {
    super();
    autorun(() => {
      this.refresh();
    });
  }

  fetch() {
    const params = { sort: 'created, asc', size: 1 };
    return CaseClient.getReport(params);
  }

  get lsBannerKey() {
    return `banner`;
  }

  get lsFilterMsg() {
    return `filterMsg`;
  }

  // Computed
  get cases() {
    return this.result && this.result._embedded && this.result._embedded.case;
  }

  // Computed
  get totalCases() {
    const r = this.result;
    return r && r.page && r.page.totalElements;
  }

  // Computed
  get shouldShowBanner() {
    return this.show('banner');
  }

  // Computed
  get shouldShowMsg() {
    return this.show('msg');
  }

  show(type) {
    const firstCase = this.cases && this.cases[0].created;
    const shortTerm = moment().subtract(3, 'months');
    const longTerm = moment().subtract(6, 'months');
    type =
      type === 'banner'
        ? localStorage.getItem(this.lsBannerKey)
        : localStorage.getItem(this.lsFilterMsg);

    if (moment(firstCase).isBetween(shortTerm, moment()) && !type) {
      return PermissionStore.has('CASE_SEARCH') && this.totalCases >= 25;
    } else if (moment(firstCase).isBefore(longTerm) && !type) {
      return PermissionStore.has('CASE_SEARCH');
    }
    return false;
  }

  dismissBanner() {
    localStorage.setItem(this.lsBannerKey, true);
  }

  dismissMsg() {
    localStorage.setItem(this.lsFilterMsg, true);
    this.refresh();
  }
}

decorate(FiltersBannerStore, {
  cases: computed,
  totalCases: computed,
  shouldShowBanner: computed,
  shouldShowMsg: computed,
});

const me = new FiltersBannerStore();
export { me as FiltersBannerStore };
export default me;
