import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { VIEW_PATH } from '../../../settings/appUsers/const';

const SortableHeaderCell = ({
  baseUrl,
  children,
  history,
  label,
  propName,
  query,
  secondaryLabel,
  store,
  useSecondaryLabel,
  'data-cy': dataCy,
}) => {
  const isSorted = fieldName => store.sortBy === fieldName;

  /*
   * @function sortBy - change the sort order of the results
   *
   * @param propName {String} - the column property being used for the sort
   *
   * @return undefined
   */
  const sortBy = fieldName => {
    const oldSortBy = store.sortBy;
    const oldSortDir = store.sortDir;
    let newSortDir = 'desc';

    if (oldSortBy === fieldName) {
      newSortDir = oldSortDir === 'desc' ? 'asc' : 'desc'; // swap directions for persistent
    }

    const newSort = `${propName},${newSortDir}`;

    history.push(
      `${baseUrl}?${$.param({
        ...(baseUrl.startsWith('/vips') ||
        baseUrl.startsWith('/data-feed') ||
        baseUrl.startsWith(VIEW_PATH)
          ? query
          : {}),
        sort: newSort,
      })}`
    );
  };

  const _isSorted = isSorted(propName);
  const sortDir = store.sortDir;

  return (
    <Cell
      className={classnames('registry__table-header table-header-sortable', {
        'registry__table-header--active': _isSorted,
      })}
      onClick={sortBy.bind(this, propName)}
      data-cy={dataCy}
    >
      {!useSecondaryLabel && (
        <>
          {label}
          <i
            className={classnames('material-icons', {
              hidden: !_isSorted,
              'icon-arrow_upward': sortDir === 'asc',
              'icon-arrow_downward': sortDir === 'desc',
            })}
            data-cy="header-arrow--icon"
          />
        </>
      )}

      {useSecondaryLabel && (
        <div style={{ display: 'flex' }}>
          <div>
            <div>{label}</div>
            {secondaryLabel && (
              <div className="secondaryLabel">{secondaryLabel}</div>
            )}
          </div>
          <div>
            <i
              className={classnames('material-icons', {
                hidden: !_isSorted,
                'icon-arrow_upward': sortDir === 'asc',
                'icon-arrow_downward': sortDir === 'desc',
              })}
              data-cy="header-arrow--icon"
            />
          </div>
        </div>
      )}

      {children}
    </Cell>
  );
};

SortableHeaderCell.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  label: PropTypes.node,
  propName: PropTypes.string.isRequired,
  query: PropTypes.shape(),
  secondaryLabel: PropTypes.node,
  store: PropTypes.shape().isRequired,
  useSecondaryLabel: PropTypes.bool,
  'data-cy': PropTypes.string,
};

export default withRouterAndQuery(observer(SortableHeaderCell));
