import React from 'react';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { AlertCategoryStore } from 'common';

import PrivacyStatementStore from '../../stores/PrivacyStatementStore';
import PrivacyStatementView from '../PrivacyStatementView';
import { observer } from 'mobx-react';
import queryString from 'query-string';

export function mapProps({
  AlertCategoryStore,
  history,
  match,
  PrivacyStatementStore,
  query,
}) {
  const routeToReportSummary = (id, groups) => {
    const pathname = id ? `/reports/summary/${id}` : '/reports/summary';
    if (groups) {
      query.group = groups.map(group => group.id);
    }
    history.push({
      pathname,
      search: '?' + queryString.stringify(query),
    });
  };
  return {
    categories: AlertCategoryStore.categories,
    setCurrentStatementId: id =>
      PrivacyStatementStore.setCurrentStatementId(id),
    currentStatementId: PrivacyStatementStore.currentStatementId,
    failure: PrivacyStatementStore.failure,
    failureStatus: PrivacyStatementStore.failureStatus,
    paramsId: match.params.statementId,
    crunchingNumbers: PrivacyStatementStore.crunchingNumbers,
    response: PrivacyStatementStore.response,
    periods: PrivacyStatementStore.periods,
    groups: PrivacyStatementStore.groups,
    filterValue: PrivacyStatementStore.filterValue,
    setValue: PrivacyStatementStore.setValue,
    routeToStatement: id =>
      history.push({
        pathname: `/statements/${id}`,
        search: '?' + queryString.stringify(query),
      }),
    routeToReportSummary,
    onMount: () => PrivacyStatementStore.refresh(),
    query,
  };
}

const PrivacyStatementViewContainer = withRouterAndQuery(
  observer(({ history, match, query }) => (
    <PrivacyStatementView
      {...mapProps({
        history,
        match,
        query,
        PrivacyStatementStore,
        AlertCategoryStore,
      })}
    />
  ))
);

PrivacyStatementViewContainer.displayName = 'PrivacyStatementViewContainer';

export default PrivacyStatementViewContainer;
