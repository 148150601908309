import moment from 'moment';
import { SingletonStore } from 'common';
import {
  action,
  computed,
  decorate,
  observable,
  reaction,
  comparer,
} from 'mobx';
import {
  EMPLOYEE_INFORMATION,
  PATIENT_INFORMATION,
  PERSONAL_INFORMATION,
  PATIENT_INFORMATION_SECTION,
  PERSONAL_INFORMATION_SECTION,
  EMPLOYEE_INFORMATION_SECTION,
} from '../const';

class MultiRecordEntityColumnStore extends SingletonStore {
  constructor({ multiRecordViewClient, visibleSystemStore, personType }) {
    super();
    this.multiRecordViewClient = multiRecordViewClient;
    this.visibleSystemStore = visibleSystemStore;
    this.personType = personType;

    reaction(
      () => [this.employeeId, this.patientId, this.caseId],
      () => {
        this.refresh();
      },
      {
        equals: comparer.structural,
      }
    );

    reaction(
      () => [this.informationType],
      () => {
        this.filterInformationType();
      },
      {
        equals: comparer.structural,
      }
    );

    this.disposer = reaction(
      () => [this.visibleSystemStore?.filteredSources],
      () => {
        this.refresh();
      },
      {
        equals: comparer.structural,
      }
    );
  }

  informationType;
  employeeId;
  patientId;
  caseId;
  disposer;

  get information() {
    let path;
    let info;
    const { informationType, result } = this;
    if (informationType === EMPLOYEE_INFORMATION) {
      path = EMPLOYEE_INFORMATION_SECTION;
      info = result?.find(res => res[path]) || [];
    }
    if (informationType === PATIENT_INFORMATION) {
      path = PATIENT_INFORMATION_SECTION;
      info = result?.find(res => res[path]) || [];
    }
    if (informationType === PERSONAL_INFORMATION) {
      path = PERSONAL_INFORMATION_SECTION;
      info = result?.find(res => res[path]) || [];
    }
    return info;
  }

  get disabledOption() {
    return this.result
      ?.map(res => {
        if (res[PATIENT_INFORMATION_SECTION]?.[0]?.hideSection) {
          return PATIENT_INFORMATION;
        }
        if (res[EMPLOYEE_INFORMATION_SECTION]?.[0]?.hideSection) {
          return EMPLOYEE_INFORMATION;
        }
        return;
      })
      .filter(item => item !== undefined);
  }

  get personalInformationSections() {
    return this.information?.personalInformationSections?.[0]?.sections || [];
  }

  get employeeInformationSections() {
    return this.information?.employeeInformationSections?.[0]?.sections || [];
  }

  get patientInformationSections() {
    return this.information?.patientInformationSections?.[0]?.sections || [];
  }

  setInformationType = type => (this.informationType = type);
  setEmployeeId = id => (this.employeeId = id);
  setPatientId = id => (this.patientId = id);
  setCaseId = id => (this.caseId = id);

  filterInformationType = () => {
    return this.information;
  };

  tearDown = () => {
    this.disposer();
  };

  fetch() {
    const { employeeId, patientId, caseId } = this;
    const params = {};
    params.filteredSources = this.visibleSystemStore.filteredSources;
    if (caseId !== undefined) {
      params.caseId = caseId;
      params.personType = this.personType;
    } else {
      if (employeeId !== undefined) params.userId = employeeId;
      if (patientId !== undefined) params.patientId = patientId;
    }

    if (employeeId || patientId || caseId) {
      const requests = [
        this.multiRecordViewClient.getPersonalInformation(params),
      ];

      requests.push(this.multiRecordViewClient.getEmployeeInformation(params));
      requests.push(
        this.multiRecordViewClient.getPatientInformation({
          ...params,
          startTime: moment()
            .startOf('day')
            .toISOString(),
        })
      );

      return Promise.allSettled(requests)
        .then(promises =>
          promises
            .filter(promise => promise.status !== 'rejected')
            .map(result => result.value)
        )
        .then(results => {
          return results.reduce((acc, result) => {
            return acc.concat(result);
          }, []);
        });
    }
  }
}

decorate(MultiRecordEntityColumnStore, {
  informationType: observable,
  employeeId: observable,
  patientId: observable,
  caseId: observable,
  disabledOption: computed,
  personalInformationSections: computed,
  employeeInformationSections: computed,
  patientInformationSections: computed,
  information: computed,
  setInformationType: action,
  filterInformationType: action,
  setEmployeeId: action,
  setPatientId: action,
  filterFieldsBySource: action,
  setCaseId: action,
  setDisabledOption: action,
});

export { MultiRecordEntityColumnStore };
