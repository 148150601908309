import { useCallback, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MedicationUtils, HalUtils } from 'common';
import styles from './index.module.scss';
import UserPatientSection from './UserPatientSection';
import EventList from './EventList';
import Header from './Header';
import IncidentReasonContainer from './IncidentReasonContainer';
import IncidentPropTypes from './PropTypes';
import { buildEvents } from './buildEvents';
import { ScrollIntoViewContext } from '../context';
import { IncidentsType } from '../../types/incidentsTypes';

interface IncidentGroupProps {
  className: string | null;
  incidents: IncidentsType[];
  selected: boolean;
  scrollToInc: boolean;
  clearScrollTo: () => void;
  showNewIcon: boolean;
  showCaseLink: boolean;
}

const IncidentGroup = ({
  className,
  incidents,
  selected,
  scrollToInc,
  clearScrollTo,
  showNewIcon,
  showCaseLink,
}: IncidentGroupProps) => {
  /* if one of the incidents is type 'ChangeInCustodyTranslator,' pass this incident to buildEvents
     so we can check if those events are in the changeInCustody details */
  const custodyInc = incidents.find(
    inc => inc.type === 'ChangeInCustodyTranslator'
  );

  /* type, resolution, and notes are unique per incident, for everything else we can just use the first incident
    if no 'ChangeInCustodyTranslator' type is found */
  const medicationIncident = custodyInc ? custodyInc : incidents[0];
  const {
    medications,
    patient,
    user,
    sourceLocation,
    destinationLocations,
  } = medicationIncident;

  /* Incident Case - if multiple, select the one with the greatest maxEventDate  */
  const maxCase = medicationIncident.cases?.sort((a, b) =>
    moment(b.maxEventDate).diff(a.maxEventDate)
  )?.[0];

  // Activity view links

  const minEventDate = medicationIncident.startTime;
  const maxEventDate = medicationIncident.endTime;
  const userLink = HalUtils.buildActivityViewLink({
    userId: user?.id,
    maxEventDate,
    minEventDate,
    type: 'user',
  });
  const patientLink = HalUtils.buildActivityViewLink({
    patientId: patient?.id,
    maxEventDate,
    minEventDate,
    type: 'patient',
  });
  const incidentReasonLink = primaryEvent =>
    HalUtils.buildActivityViewLink({
      userId: user?.id,
      maxEventDate,
      minEventDate,
      focusEvent: primaryEvent,
      type: 'incidentFocus',
    });

  const scrollIntoViewContext = useContext(ScrollIntoViewContext);

  const selectedRef = useCallback(
    node => {
      if (node && scrollToInc && scrollIntoViewContext) {
        scrollIntoViewContext.scrollIntoView(node);

        // clear out the scrollToInc so you don't scroll to inc every time you switch tabs
        clearScrollTo();
      }
    },
    [scrollToInc, clearScrollTo, scrollIntoViewContext]
  );
  // TODO REAP-528 - Remove single medication display
  return (
    <div
      ref={selectedRef}
      className={classnames(styles.incidentBox, className, {
        [styles.selected]: selected,
      })}
      data-cy={
        selected ? 'incident-card-selected' : 'incident-card-not-selected'
      }
    >
      <Header
        kase={showCaseLink ? maxCase : null}
        count={incidents.length}
        date={medicationIncident.created}
        medication={MedicationUtils.formatMedication(
          medications?.slice(0, 1),
          true
        )}
        showNewIcon={showNewIcon}
      />
      {incidents.map(incident => (
        <IncidentReasonContainer
          key={incident.type + incident.id}
          incident={incident}
          link={incidentReasonLink(incident.primaryEvent)}
        />
      ))}
      <UserPatientSection
        user={user}
        patient={patient}
        adc={medicationIncident.station}
        userLink={userLink}
        patientLink={patientLink}
        sourceLocation={sourceLocation}
        destinationLocations={destinationLocations}
      />
      <EventList
        user={user}
        userLink={userLink}
        events={buildEvents(medicationIncident)}
      />
    </div>
  );
};

IncidentGroup.propTypes = {
  clearScrollTo: PropTypes.func,
  incidents: PropTypes.arrayOf(
    PropTypes.shape({
      administrations: IncidentPropTypes.administrations,
      case: PropTypes.shape({}),
      destinationLocations: IncidentPropTypes.destinationLocations,
      handlings: IncidentPropTypes.handlings,
      medications: IncidentPropTypes.medications,
      patient: IncidentPropTypes.patient,
      user: IncidentPropTypes.user,
      details: PropTypes.shape({
        unreconciledAmount: PropTypes.shape({
          unit: PropTypes.string,
          value: PropTypes.string,
        }),
        patientDischargeTime: PropTypes.string,
      }),
      primaryEvent: PropTypes.string.isRequired,
      sourceLocation: IncidentPropTypes.sourceLocation,
    })
  ).isRequired,
  selected: PropTypes.bool,
  scrollToInc: PropTypes.bool,
  showNewIcon: PropTypes.bool,
  showCaseLink: PropTypes.bool,
  flags: PropTypes.arrayOf(PropTypes.string),
};

IncidentGroup.defaultProps = {
  clearScrollTo: () => {},
  scrollToInc: false,
  selected: false,
  showCaseLink: false,
  flags: [],
};

IncidentGroup.displayName = 'IncidentGroup';

export default IncidentGroup;
