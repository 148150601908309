import React from 'react';
import { observer } from 'mobx-react';
import CaseStore from '../../stores/CaseStore';
import CaseEventTable from '../../CaseEventTable';

export function mapProps({ CaseStore }) {
  return {
    eventData: CaseStore.eventData,
    sortData: {
      sortBy: CaseStore.sortBy,
      sortDir: CaseStore.sortDir,
    },
    linkForEvent: CaseStore.linkForAccessTableEvent,
  };
}

const AccessesContainer = observer(() => (
  <CaseEventTable {...mapProps({ CaseStore })} />
));

AccessesContainer.displayName = 'AccessesContainer';

export default AccessesContainer;
