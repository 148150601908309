import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../ui/Button';
import styles from './index.module.scss';

const AddToCase = ({ headerText, pText, onClick, link }) => {
  const [submitted, setSubmitted] = useState(false);
  return (
    <>
      <h2>{headerText}</h2>
      <p>{pText}</p>
      <p>
        IMPORTANT: Once you add this assessment to an existing case, you will
        not be able to remove it.
      </p>
      <a target="_blank" href={link} rel="noopener noreferrer">
        View existing case
      </a>
      <Button
        data-cy="add-to-case-button"
        className={styles.marginTop}
        type="submit"
        value={`${submitted ? 'Adding...' : 'Add to Case'}`}
        disabled={submitted}
        onClick={() => {
          setSubmitted(true);
          onClick();
        }}
      />
    </>
  );
};

AddToCase.propTypes = {
  headerText: PropTypes.string,
  pText: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
};

AddToCase.defaultProps = {
  headerText: '',
  pText: '',
  onClick: () => {},
};

export default AddToCase;
