import React from 'react';
import PropTypes from 'prop-types';
import {
  BulkActionsAside,
  BulkCaseActions as BulkActions,
  BulkActionsHeader,
} from '../../ui';

const BulkCaseActions = ({ store, size }) => (
  <BulkActionsAside>
    <section>
      <BulkActionsHeader size={size} item="Case" icon="icon-folder" />
      <BulkActions className="bulk-case-actions__action" store={store} />
    </section>
  </BulkActionsAside>
);

BulkCaseActions.propTypes = {
  store: PropTypes.shape({}),
  size: PropTypes.number,
};

export default BulkCaseActions;
