import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { CaseReportsContext } from '../Context';
import CaseReportsChartAndTableView from '../CaseReportsChartAndTableView';

export function mapProps({
  caseReportsCaseResolutionsStore,
  caseReportsCaseResolutionsOverTimeStore,
  caseReportsCaseTypesStore,
  caseReportsCaseTypesOverTimeStore,
  caseReportsTimeToResolveStore,
  caseReportsFilterSelectionsStore,
}) {
  return {
    caseReportsCaseResolutionsStore,
    caseReportsCaseResolutionsOverTimeStore,
    caseReportsCaseTypesStore,
    caseReportsCaseTypesOverTimeStore,
    caseReportsFilterSelectionsStore,
    caseReportsTimeToResolveStore,
    hasCases: caseReportsCaseResolutionsStore.casesTotal > 0,
  };
}

const CaseReportsChartAndTableViewContainer = observerWrappedContainerFactory({
  Context: CaseReportsContext,
  mapProps,
  PresentationalComponent: CaseReportsChartAndTableView,
});

CaseReportsChartAndTableViewContainer.displayName =
  'CaseReportsChartAndTableViewContainer';

export default CaseReportsChartAndTableViewContainer;
