import { MultiRecordMetadataDetails } from '../MultiRecordMetadataDetails';
import { MultiRecordViewContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';

export function mapProps({
  multiRecordMainStore,
  multiRecordPatientMetadataStore,
}) {
  return {
    pageMode: multiRecordMainStore.pageMode,
    personType: multiRecordPatientMetadataStore.personType,
    metadataTypes: multiRecordPatientMetadataStore.metadataTypes,
    metadataTypeColumns: multiRecordPatientMetadataStore.metadataTypeColumns,
  };
}

export const MultiRecordPatientMetadataDetailsContainer = observerWrappedContainerFactory(
  {
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordMetadataDetails,
  }
);
