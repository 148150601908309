import React from 'react';
import { Link } from 'react-router-dom';
import { action, decorate, observable } from 'mobx';
import { observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { Authenticated, HalUtils } from 'common';
import BundleStore from '../../bundle/stores/BundleStore';
import CaseBundleStore from '../../stores/CaseBundleStore';
import BulkCaseActionsStore from '../../stores/BulkCaseActionsStore';
import { BundleCases } from '../../ui/actions';

import CaseStore from '../stores/CaseStore';
import PropTypes from 'prop-types';
import { Tooltip } from '../../ui';

const CaseViewBundles = observer(
  class CaseViewBundles extends React.Component {
    static propTypes = {
      bundles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
      bundlesRefresh: PropTypes.func,
    };

    // Observable
    removeBundle = null;

    // Action
    setBundle = id => {
      const bundle = this.props.bundles.find(b => b.id === id);
      this.removeBundle = bundle;
    };

    componentDidMount() {
      BulkCaseActionsStore.clearSelection();
      BulkCaseActionsStore.select(CaseStore.case);
    }

    removeFromBundle = e => {
      e.stopPropagation();

      BundleStore.bundleId = HalUtils.getId(this.removeBundle);
      BundleStore.removeCases([CaseStore.caseId]).then(() => {
        CaseBundleStore.refresh();
        this.props.bundlesRefresh();
      });

      this.removeBundle = null;
    };

    renderAction() {
      if (this.removeBundle) {
        return (
          <section className="bundle-actions">
            <form>
              <section>
                <div className="item">
                  <button
                    type="submit"
                    onClick={this.removeFromBundle.bind(this)}
                  >
                    Remove from bundle
                  </button>
                </div>
                <div className="item">
                  <button
                    className="button--link"
                    onClick={() => this.setBundle(undefined)}
                  >
                    Cancel
                  </button>
                </div>
              </section>
            </form>
            <p className="small text-center">
              This case will be removed from the bundle, &quot;
              {this.removeBundle?.name}&quot;.
            </p>
          </section>
        );
      }

      return (
        <Authenticated permission="BUNDLE_MODIFY">
          <section className="bundle-actions">
            <p>Add this case to a bundle</p>
            <BundleCases
              store={BulkCaseActionsStore}
              bundlesWithCase={this.props.bundles}
              bundleRefresh={this.props.bundlesRefresh}
              singleCase
            />
          </section>
        </Authenticated>
      );
    }

    render() {
      const bundles = this.props.bundles;
      let bundleListing;

      if (bundles && bundles.length > 0) {
        const items = bundles.map((bundle, idx) => {
          const { id, casesCount } = bundle;
          const numCases = casesCount;

          return (
            <li key={`bundle${idx}`}>
              <Link to={`/bundles/${id}`}>{bundle.name}</Link>
              <Authenticated permission="BUNDLE_MODIFY">
                <span
                  className="float-right prot-a"
                  role="button"
                  tabIndex={idx}
                  onClick={() => this.setBundle(id)}
                >
                  Remove
                </span>
              </Authenticated>
              <Tooltip
                content={`A total number of all cases in the bundle.
              You might see a different number of cases in Case Bundles page
              depends on your permissions and groups.`}
              >
                <span className="text-subtle float-right">
                  {numCases} Case{numCases === 1 ? '' : 's'}
                </span>
              </Tooltip>
            </li>
          );
        });

        bundleListing = (
          <section className="bundles">
            <div className="item">
              <p className="small">{}</p>
              <ul>{items}</ul>
            </div>
          </section>
        );
      }

      return (
        <div>
          {bundleListing}
          {this.renderAction()}
        </div>
      );
    }
  }
);

decorate(CaseViewBundles, {
  removeBundle: observable,
  setBundle: action,
});

CaseViewBundles.displayName = 'CaseViewBundles';

export default CaseViewBundles;
