import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import styles from '../index.module.scss';
import { getSourceFromLocation } from '../../stores/DeliveryStore/helpers';

const SourceCell = ({ location }) => {
  const source = getSourceFromLocation(location);

  return (
    <Cell>
      <div className={styles.sourceCell}>
        <span title={source}>{source}</span>
      </div>
    </Cell>
  );
};

SourceCell.propTypes = {
  location: PropTypes.string,
};

SourceCell.defaultProps = {
  location: '',
};

export default SourceCell;
