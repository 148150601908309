import { InfoCacheStore, PermissionStore, VipClient } from 'common';

class PatientVipStore extends InfoCacheStore {
  fetchData(id) {
    return VipClient.findByPatient(id);
  }

  hasPermission() {
    return PermissionStore.has('VIP_REGISTRATION_VIEW');
  }
}

export default new PatientVipStore();
