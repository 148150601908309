import React from 'react';
import styles from './index.module.scss';
import Column from '../Column';
import { nodeIsType } from '../../../utils/ComponentUtils';

const Columns = ({ children }) => {
  return (
    <div className={styles.columns} data-cy="person-column">
      {children}
    </div>
  );
};

Columns.propTypes = {
  children: function({ children }) {
    children = React.Children.toArray(children).filter(Boolean);
    if (children.length > 2)
      return new Error('Render at most two people columns.');
    if (
      children.filter(node => nodeIsType(node, Column)).length !==
      children.length
    )
      return new Error('Only render Column inside of Columns.');
  },
};

export default Columns;
