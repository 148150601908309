import React from 'react';
import { DropdownList } from 'react-widgets';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';

import classnames from 'classnames';
import {
  AppUserStore,
  Pandy,
  ScrollPager,
  RouterContainer,
  convertMultiValue,
} from 'common';
import Loader from '../../ui/Loader';
import StaggeredList from '../../ui/StaggeredList';

import CaseActivityStore from '../stores/CaseActivityStore';
import AppUserSelect from '../AppUserSelect';
import CaseActivityItem from '../CaseActivityItem';

/**
 * Primary component for displaying the case activity feed.
 * @extends React.Component
 */
const CaseActivity = observer(
  class extends React.Component {
    // Observable
    pagerElement = null;

    /**
     * COMPUTED
     * Compute the currently selected activity type from the available list of
     * options to display in the dropdownlist.
     * @return {Object} the activity type Object
     */
    get selectedActivityType() {
      let { action } = CaseActivityStore;

      if (action === null) action = 'all';

      const activityIndex = CaseActivityStore.actions.findIndex(
        o => o.value === action
      );

      return CaseActivityStore.actions[activityIndex];
    }

    /**
     * Event handler for the onChange event fired by the dropdownlist of activity
     * types. Bound to the class instance context.
     * @param {Object} e - the Event that triggered the handler
     */
    onActivityTypeChange = e => {
      const params = this.combineParams(
        {
          activityType: e.value,
        },
        RouterContainer.query
      );
      if (params.group) params.group = convertMultiValue(params.group);
      localStorage.setItem('activityType', e.value);
      RouterContainer.go('/', params);
    };

    /**
     * Utility method to help combine new query parameters with existing query
     * parameters.
     * @param {Object} params - the new query parameters to be applied
     * @return {Object} the parameters Object
     */
    combineParams(params) {
      return Object.assign({}, RouterContainer.query || {}, params);
    }

    /**
     * Iterate through the case activity items and create React DOM Nodes for each item.
     * @return {Array} collection of React DOM Objects
     */
    sortFunc(a, b) {
      return a[0].created < b[0].created ? 1 : -1;
    }

    renderActivity() {
      const combinedActivities = {};

      if (CaseActivityStore.results) {
        CaseActivityStore.results
          .filter(activity => activity.complianceCase)
          .forEach(activity => {
            const activityKey = `${activity.created.slice(0, 10)}-${
              activity.complianceCase.id
            }`;
            if (combinedActivities[activityKey]) {
              combinedActivities[activityKey].push(activity);
            } else {
              combinedActivities[activityKey] = [activity];
            }
          });
      }

      const sortedActivityArray = Object.keys(combinedActivities)
        .map(activityID => combinedActivities[activityID])
        .sort(this.sortFunc);

      return sortedActivityArray.map((activityArray, idx) => {
        return <CaseActivityItem data={activityArray} key={`activity${idx}`} />;
      });
    }

    /*
     * Render the component to the DOM.
     * @return {Object} React DOM Object
     */
    render() {
      return (
        <section className="home-section col-2 home__recent-activity">
          <header>
            <h3>Recent Activity</h3>
            <DropdownList
              data={CaseActivityStore.actions}
              valueField="value"
              textField="label"
              onChange={this.onActivityTypeChange}
              value={this.selectedActivityType}
            />
            <AppUserSelect
              paramName="activityUser"
              value={CaseActivityStore.user}
              users={AppUserStore.enabledActiveCaseOwners}
              meLabel="My Activity"
            />
          </header>
          <div
            className="view-content__box"
            ref={node => {
              if (node && node !== this.pagerElement) {
                this.pagerElement = node;
              }
            }}
          >
            <ScrollPager.Component
              elt={this.pagerElement}
              store={CaseActivityStore}
              rowHeight={87}
            />
            <Loader
              loaded={
                !CaseActivityStore.loading || CaseActivityStore.size !== 0
              }
            >
              <section
                className={classnames('home-sub-section', {
                  'results-empty':
                    !CaseActivityStore.loading && CaseActivityStore.size === 0,
                })}
              >
                <Pandy
                  visible={
                    !CaseActivityStore.loading && CaseActivityStore.size === 0
                  }
                  mood="happy"
                >
                  <div className="speechBubble">
                    <span>No matches. Sorry!</span>
                  </div>
                </Pandy>
                <StaggeredList
                  className="card_holder feed"
                  drag={5}
                  pandyText="Recent Activity"
                  useSmallPandy
                  pandyHasMore={CaseActivityStore.hasMore}
                  toAdd="fadeIn"
                  topOfListScrollValues={{
                    elementToScroll: 'view-content__box',
                    watchedElements: [
                      this.selectedActivityType.label,
                      CaseActivityStore.user,
                    ],
                  }}
                  parentNodeRef={this.pagerElement}
                >
                  {this.renderActivity()}
                </StaggeredList>
              </section>
            </Loader>
          </div>
        </section>
      );
    }
  }
);

decorate(CaseActivity, {
  selectedActivityType: computed,
  pagerElement: observable,
});

CaseActivity.displayName = 'CaseActivity';

export default CaseActivity;
