import { observable, autorun, decorate } from 'mobx';
import {
  convertMultiValue,
  CaseLogClient,
  PagedStore,
  PermissionStore,
} from 'common';

/**
 * Data store for all case activity items. Used on the dashboard case activity feed.
 * @extends PagedStore
 */
class CaseActivityStore extends PagedStore {
  /**
   * Invoke the super class's constructor, configure autorun behaviors.
   */
  constructor({ permissionStore }) {
    super();

    this.permissionStore = permissionStore;

    autorun(() => {
      this.refresh();
    });
  }

  // Observables
  initialized = false;
  action = null;
  user = null;
  group = null;

  // NOT observable
  sort = 'lastModified,desc';

  /**
   * Produce a list of actions to use in the dropdownlist as options.
   * @return {Array} The list of case activity types to expose for user
   *   selection
   */
  get actions() {
    const actions = [
      { value: 'all', label: 'All Activity' },
      { value: 'EDIT_ASSESSMENT', label: 'New Assessment Edits' },
      { value: 'ASSIGN', label: 'New Assignments' },
      { value: 'CREATE', label: 'New Cases' },
      { value: 'COMMENT', label: 'New Comments' },
      { value: 'ADD_NOTE', label: 'New Note Edits' },
      { value: 'REOPEN', label: 'Reopened Cases' },
      { value: 'RESOLVE', label: 'Resolved Cases' },
      { value: 'UNASSIGN', label: 'Unassignments' },
    ];

    if (this.permissionStore.has('MEDICATION_INCIDENT_VIEW')) {
      actions.push({ value: 'ADD_INCIDENT', label: 'New Incidents' });
      actions.push({ value: 'ADD_ANALYTIC_ASSESSMENT', label: 'New Analysis' });
    }

    if (this.permissionStore.has('CASE_SYNC_OWNED'))
      actions.push({ value: 'EXTERNAL_SYNC', label: 'Case Synchronizations' });

    return actions;
  }

  /**
   * Update the filtering options for the data store.
   * @param {Object} query - the new filters to apply
   */
  setFilters(query) {
    if (query.activityType === 'all') query.activityType = null;

    if (query.activityUser === 'all') query.activityUser = null;

    this.initialized = true;
    this.action = query.activityType;
    this.user = query.activityUser;
    this.group = query.group;
  }

  /**q
   * Fetch the results from the server.
   * @return {Promise} an HTTP Promise
   */
  fetch() {
    if (!this.initialized) return [];
    const params = {
      sort: this.sort,
      projection: 'minimum',
      group: this.group,
    };

    if (this.user) params.createdBy = this.user;

    if (this.action) {
      if (this.action === 'ADD_NOTE') {
        params.action = convertMultiValue([
          'ADD_NOTE',
          'EDIT_NOTE',
          'REMOVE_NOTE',
          'ADD_NOTE_INCIDENT',
          'REMOVE_NOTE_INCIDENT',
        ]);
      } else if (this.action === 'EDIT_ASSESSMENT') {
        params.action = convertMultiValue([
          'CREATE_ASSESSMENT',
          'EDIT_ASSESSMENT',
          'REMOVE_ASSESSMENT',
        ]);
      } else {
        params.action = convertMultiValue(this.action);
      }
    } else {
      params.action = convertMultiValue(
        this.actions.map(action => action.value)
      );
    }

    if (params.group) params.group = convertMultiValue(this.group);

    return CaseLogClient.findByActionInAndCreatedBy(params);
  }
}

decorate(CaseActivityStore, {
  action: observable,
  initialized: observable,
  user: observable,
  group: observable,
});

export default new CaseActivityStore({ permissionStore: PermissionStore });
