import React from 'react';
import { observer } from 'mobx-react';
import AssessmentsForCaseType from '../../../case/subViews/AssessmentsForCaseType';
import DiversionActivityStore from '../../stores/DiversionActivityStore';
import EventDetailedStore from '../../stores/EventDetailedStore';
import AssessmentStore from '../../stores/AssessmentStore';
import Store from '../../stores/Store';

export function mapProps({
  DiversionActivityStore,
  EventDetailedStore,
  AssessmentStore,
  Store,
}) {
  const isDiversion = Store.focus === 'employee_incidents';
  const caseId = localStorage.getItem('caseId');
  return {
    analyticAssessments: isDiversion
      ? DiversionActivityStore.statisticsWithoutOutOfBoundIncidents
      : AssessmentStore.assessments,
    assessmentsHeadlines: isDiversion
      ? DiversionActivityStore.assessmentsHeadlines
      : AssessmentStore.assessmentsHeadlines,
    checkable: true,
    condensedHeader: true,
    incidentsByPeriod: DiversionActivityStore.statisticsWithoutOutOfBoundIncidents.map(
      a => a.incidents.flatMap(inc => inc.linkedIncidents)
    ),
    unreconciledDrugs: DiversionActivityStore.unreconciledDrugs,
    onSelectIncident: EventDetailedStore.setIncidentFocus,
    setChecked: DiversionActivityStore.toggleFlag,
    selectInc: EventDetailedStore.setIncidentFocus,
    setStatistic: DiversionActivityStore.selectStatisticSourceStartDate,
    scrollToStatistic: DiversionActivityStore.scrollToStatistic,
    clearScrollTo: DiversionActivityStore.clearScrollTo,
    type: isDiversion ? 'diversion' : 'privacy',
    userId: Store.userId,
    patientId: Store.patientId,
    linkTo: isDiversion ? `/incidents` : `/case/${caseId}/accesses`,
  };
}

const AssessmentsForCaseTypeContainer = observer(() => (
  <AssessmentsForCaseType
    {...mapProps({
      DiversionActivityStore,
      EventDetailedStore,
      AssessmentStore,
      Store,
    })}
  />
));

export default AssessmentsForCaseTypeContainer;
