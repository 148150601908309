import React from 'react';
import { observer } from 'mobx-react';

import EncounterStore from '../../stores/EncounterStore';
import TimeEntryStore from '../../stores/TimeEntryStore';
import ChartStore from '../../stores/ChartStore';

import XAxisChart from '../XAxisChart';

export function mapProps({ EncounterStore, TimeEntryStore, ChartStore }) {
  return {
    encounters: EncounterStore.encounters,
    timeEntries: TimeEntryStore.timeEntries,
    brushedXScale: ChartStore.brushedXScale,
    width: ChartStore.width,
    tickFormat: ChartStore.tickFormat,
    margin: ChartStore.margin,
    padding: ChartStore.padding,
  };
}

const Index = observer(() => (
  <XAxisChart
    {...mapProps({
      EncounterStore,
      TimeEntryStore,
      ChartStore,
    })}
  />
));

export default Index;
