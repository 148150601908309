import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { CaseViewContext } from '../../../Context';
import MultiRecordInformationDropdown from '../../../../ui/MultiRecordInformationDropdown';
import { patientDropdownMapProps } from '../../mapProps';

export const CaseViewPatientDropdownContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseViewContext,
    mapProps: patientDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);
