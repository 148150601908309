import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExclusionMultiselect from '../ExclusionMultiselect';
import styles from './index.module.scss';

const ExclusionFreeForm = ({
  label,
  inputKey,
  selectedValues,
  handleOnChange,
  hideExclusion = true,
  isExcluding,
  onToggleExclusion,
  queryCriteria,
  history,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const setQueryFromSelection = newValue => {
    if (newValue) {
      handleOnChange(
        inputKey,
        [...selectedValues, newValue],
        queryCriteria,
        history.push,
        isExcluding
      );
    }
  };

  return (
    <div className={styles.exclusionFreeForm} data-cy="exclusion-free-form">
      <ExclusionMultiselect
        label={label}
        hideExclusion={hideExclusion}
        className={styles.multiselect}
        value={selectedValues}
        onChange={values =>
          handleOnChange(
            inputKey,
            values,
            queryCriteria,
            history.push,
            isExcluding
          )
        }
        multiselectProps={{
          onCreate: setQueryFromSelection,
          allowCreate: true,
          onSearch: term => setSearchTerm(term),
          onBlur: () => setQueryFromSelection(searchTerm),
        }}
        isExcluding={isExcluding}
        onToggleExclusion={onToggleExclusion}
      />
    </div>
  );
};

ExclusionFreeForm.propTypes = {
  label: PropTypes.string,
  inputKey: PropTypes.string,
  isExcluding: PropTypes.bool,
  selectedValues: PropTypes.array,
  handleOnChange: PropTypes.func,
  hideExclusion: PropTypes.bool,
  queryCriteria: PropTypes.shape({}),
  onToggleExclusion: PropTypes.func,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default ExclusionFreeForm;
