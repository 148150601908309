import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';
import PersonAside from '../../people/PersonBox/PersonAside';
import PersonHeader from '../../people/PersonBox/PersonHeader';
import EmployeeDetails from '../EmployeeDetails';
import Contacts from '../../people/Contacts';
import Roles from '../Roles';
import SubEmployeeBox from '../SubEmployeeBox';
import MaybeTwoColumns from '../../people/PersonBox/MaybeTwoColumns';
import { useFetchCaseCount } from '../../stores/CaseCountByDBRefStore/useFetchCaseCount';

const EmployeeBox = ({
  aliases,
  linkAlias,
  orderLast,
  otherAliases,
  currentView,
  employeeId,
  employee,
  employeeCases,
  employeeCaseCount,
  employeeRelationships,
  fixedWidth,
  hideDob,
  lastActivityMoment,
  printSettings,
  showTwoColumns,
  subEmployeeBoxProps,
  userLink,
  loading,
}) => {
  useFetchCaseCount(employeeId, 'user');

  // In some situations we are fed a user snapshot which is immediately
  // available. In other situations we have to wait for an async request to come
  // back with the user.
  if (!employee) employee = {};

  const caseIdIfOnlyOneCaseForPerson =
    employeeCases.length === 1 ? employeeCases[0].id : null;

  const firstColumnParts = () => (
    <>
      {employeeId && <SubEmployeeBox {...subEmployeeBoxProps} />}
      <EmployeeDetails
        employee={employee}
        employeeId={employeeId}
        employeeRelationships={employeeRelationships}
        printSettings={printSettings}
        lastActivityMoment={lastActivityMoment}
        hideDob={hideDob}
      />
    </>
  );

  const secondColumnParts = () => (
    <>
      <Roles roles={employee.chronoRoles} />
      <hr />
      <Contacts contacts={employee.contacts || []} />
    </>
  );

  let component;
  if (!loading)
    component = (
      <>
        <PersonHeader
          aliases={aliases}
          currentView={currentView}
          otherAliases={otherAliases}
          findLink={alias => linkAlias(alias)}
          person={employee}
          personType="user"
          personLink={userLink}
          numCases={employeeCaseCount}
          caseIdIfOnlyOneCaseForPerson={caseIdIfOnlyOneCaseForPerson}
          linkToOnlyCase={currentView !== 'case'}
          personId={employeeId}
        />
        <MaybeTwoColumns
          key={employeeId}
          moreLabel="Roles & Contacts"
          backLabel="Details"
          showTwoColumns={showTwoColumns}
          renderFirstColumn={firstColumnParts}
          renderSecondColumn={secondColumnParts}
        />
      </>
    );

  return (
    <PersonAside fixedWidth={fixedWidth} orderLast={orderLast}>
      {component}
    </PersonAside>
  );
};

EmployeeBox.propTypes = {
  aliases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  currentView: PropTypes.string,
  employee: PropTypes.shape({
    contacts: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    chronoRoles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  }),
  employeeId: PropTypes.string,
  employeeCases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  employeeCaseCount: PropTypes.number,
  employeeRelationships: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  fixedWidth: PropTypes.bool,
  hideDob: PropTypes.bool,
  lastActivityMoment: PropTypes.instanceOf(moment),
  linkAlias: PropTypes.func,
  orderLast: PropTypes.bool,
  otherAliases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  printSettings: PropTypes.shape({}),
  showTwoColumns: PropTypes.bool.isRequired,
  subEmployeeBoxProps: PropTypes.shape({}),
  userLink: PropTypes.string,
  loading: PropTypes.bool,
};

EmployeeBox.defaultProps = {
  aliases: [],
  employeeCases: [],
  employee: {},
  employeeId: '',
  fixedWidth: false,
  hidden: false,
  linkAlias: () => {},
  orderLast: false,
  otherAliases: [],
  userLink: '',
};

export default EmployeeBox;
