import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'fixed-data-table-2';
import classnames from 'classnames';

const Registry = React.forwardRef(
  (
    {
      width,
      height,
      scroll,
      scrollX,
      rowHeight,
      headerTitle,
      headerHeight,
      header,
      dataCount,
      className,
      children,
      onClick,
    },
    ref
  ) => {
    className = className ? `${className} registry__table` : 'registry__table';

    return (
      <section
        ref={ref}
        className={classnames(className, {
          hidden: !dataCount,
        })}
      >
        {header && (
          <header>
            <h2>{headerTitle}</h2>
          </header>
        )}
        <Table
          rowHeight={rowHeight}
          onRowClick={(event, index) => onClick(index)}
          headerHeight={headerHeight}
          groupHeaderHeight={rowHeight}
          rowsCount={dataCount}
          width={width}
          overflowX={scrollX ? 'auto' : 'hidden'}
          overflowY={scroll ? 'auto' : 'hidden'}
          height={height || (dataCount + 1.25) * rowHeight}
        >
          {children}
        </Table>
      </section>
    );
  }
);

Registry.propTypes = {
  rowHeight: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  headerHeight: PropTypes.number,
  scroll: PropTypes.bool,
  scrollX: PropTypes.bool,
  header: PropTypes.bool,
  headerTitle: PropTypes.string,
  dataCount: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Registry.defaultProps = {
  rowHeight: 50,
  width: 0,
  height: null,
  scroll: false,
  scrollX: false,
  header: false,
  headerHeight: 50,
  headerTitle: '',
  dataCount: 0,
  className: '',
  onClick: () => {},
};

Registry.displayName = 'Registry';

export default Registry;
