import { action, computed, decorate, observable } from 'mobx';
import { AlertCategoryGroupClient, HalUtils, LoginStore } from 'common';
import GeneralSettingsStore from '../../../stores/GeneralSettingsStore';

class CaseNotificationsStore extends GeneralSettingsStore {
  constructor({ loginStore }) {
    super();

    this.loginStore = loginStore;
  }

  // Observable
  _editedPreferences = {};

  // Action
  changeSetting = (group, property, value) => {
    let editProperty, newValue;

    if (property === 'enabled') {
      editProperty = 'enabled';
      newValue = !group.caseReminderPreferences.enabled;
    } else if (property === 'deadline') {
      editProperty = 'deadline';
      if (value === '') {
        newValue = '';
      } else {
        newValue = Number.parseInt(value, 10);
        if (Number.isNaN(newValue) || !newValue) return;
        newValue = Math.abs(newValue);
      }
    } else if (/send\d+/.test(property)) {
      // Adding/removing a day update.
      editProperty = 'dayReminders';
      const day = +property.substring(4);
      const daySet = new Set(group.caseReminderPreferences.dayReminders);
      if (daySet.has(day)) {
        daySet.delete(day);
      } else {
        daySet.add(day);
      }
      newValue = Array.from(daySet);
    } else {
      return;
    }

    this._editedPreferences = {
      ...this._editedPreferences,
      [group.id]: {
        ...this._editedPreferences[group.id],
        [editProperty]: newValue,
      },
    };
  };

  // Action
  reset() {
    this._editedPreferences = {};
  }

  fetch() {
    return AlertCategoryGroupClient.findAll();
  }

  // Computed
  get results() {
    const { user } = this.loginStore;
    if (!user) return [];

    const allGroups = HalUtils.getData(this.result) || [];
    // user.groups is an array of {id, name} representing all groups the user
    // has access to.
    // Does not care if the groups are toggled on or off in the UI.
    const userGroupIds = (user.groups || []).map(({ id }) => id);

    return allGroups.filter(({ id }) => userGroupIds.includes(id));
  }

  // Computed
  get editedGroups() {
    // Merge fetched results with edited values.
    return this.results.map(group => {
      return {
        ...group,
        caseReminderPreferences: {
          // Default fields if the preferences are missing.
          enabled: false,
          deadline: 30,
          dayReminders: [],
          // Preferences from the DB.
          ...group.caseReminderPreferences,
          // Preferences we've edited but not yet saved.
          ...this._editedPreferences[group.id],
        },
      };
    });
  }

  // Computed
  get isDirty() {
    return this.results.reduce((dirty, originalGroup, index) => {
      const editedGroup = this.editedGroups[index];
      return dirty || isGroupDirty(originalGroup, editedGroup);
    }, false);
  }

  // Action
  save() {
    if (!this.isDirty) return;

    const groupsToSave = this.editedGroups.filter((editedGroup, index) => {
      const originalGroup = this.results[index];
      return isGroupDirty(originalGroup, editedGroup);
    });

    if (
      groupsToSave.filter(
        group =>
          group.caseReminderPreferences.enabled &&
          !group.caseReminderPreferences.deadline
      ).length > 0
    )
      return this.toastThatStuff('warning');

    return Promise.all(
      groupsToSave
        .map(group => [
          HalUtils.getSelf(group),
          { caseReminderPreferences: group.caseReminderPreferences },
        ])
        .map(updateArgs => AlertCategoryGroupClient.update(...updateArgs))
    )
      .then(() => this.toastThatStuff('success'))
      .then(() => this.refresh())
      .then(() => this.reset())
      .catch(() => this.toastThatStuff('warning'));
  }
}

/**
 * Compare an original group with its edited version. If the edits constitute a
 * meaningful change, we consider it dirty and eligible for updating.
 *
 * @param {AlertCategoryGroup} originalGroup
 * @param {AlertCategoryGroup} editedGroup
 * @return {boolean}
 */
function isGroupDirty(originalGroup, editedGroup) {
  const originalPrefs = originalGroup.caseReminderPreferences || {};
  const editedPrefs = editedGroup.caseReminderPreferences;

  // Turned it on.
  if (editedPrefs.enabled && !originalPrefs.enabled) return true;
  // Turned if off.
  if (!editedPrefs.enabled && originalPrefs.enabled) return true;

  // Still off; no change can be made.
  if (!editedPrefs.enabled) return false;

  // See if sub-preferences have changed.
  const hashSubPrefs = ({ deadline, dayReminders }) =>
    `${deadline}${[...dayReminders].sort().join()}`;

  return hashSubPrefs(originalPrefs) !== hashSubPrefs(editedPrefs);
}

decorate(CaseNotificationsStore, {
  _editedPreferences: observable,
  results: computed,
  editedGroups: computed,
  isDirty: computed,
  changeSetting: action,
  reset: action,
  save: action,
});

export { CaseNotificationsStore };
export default new CaseNotificationsStore({ loginStore: LoginStore });
