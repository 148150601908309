import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { CaseViewContext } from '../../../Context';
import MultiRecordEntityColumn from '../../../MultiRecordEntityColumn';
import { patientColumnMapProps } from '../../mapProps';

export const CaseViewPatientColumnContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseViewContext,
    mapProps: patientColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);
