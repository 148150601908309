import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import MultiRecordCompositeFieldValue from '../MultiRecordCompositeField/Value';
import MultiRecordCompositeFieldSources from '../MultiRecordCompositeField/Sources';
import classnames from 'classnames';
import { generateHighlightedText } from '../utils/helpers';

const MultiRecordCompositeFieldTieredGroup = ({
  fieldName,
  tieredField,
  filteredTerm,
}) => {
  return (
    <div className={styles.compositeFieldGroup}>
      <div className={styles.fieldCol}>
        <div className={classnames(styles.fieldRow, styles.rowOffset)}>
          <h3 className={classnames(styles.text, styles.tieredFieldName)}>
            {generateHighlightedText(fieldName, filteredTerm)}
          </h3>
          <div className={styles.fieldRow}>
            {/* bolds filtered text */}
            <p className={styles.text}>
              {generateHighlightedText(tieredField.value, filteredTerm)}
            </p>
            <MultiRecordCompositeFieldSources sources={tieredField.sources} />
          </div>
        </div>
        <div
          className={classnames(styles.fieldRow, styles.tieredValueContainer)}
        >
          <MultiRecordCompositeFieldValue
            field={tieredField}
            filteredTerm={filteredTerm}
          />
        </div>
      </div>
    </div>
  );
};

MultiRecordCompositeFieldTieredGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  tieredField: PropTypes.object.isRequired,
  filteredTerm: PropTypes.string,
};

export default MultiRecordCompositeFieldTieredGroup;
