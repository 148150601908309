import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import {
  PATIENT_INFORMATION,
  EMPLOYEE_INFORMATION,
  PERSONAL_INFORMATION,
} from '../stores/const';
import { MultiRecordPatientInformation } from './MultiRecordPatientInformation';
import { MultiRecordEmployeeInformation } from './MultiRecordEmployeeInformation';
import { MultiRecordPersonalInformation } from './MultiRecordPersonalInformation';
const MultiRecordEntityColumn = ({
  setInformationType,
  information,
  selectedOption,
  setPatientId,
  setEmployeeId,
  setCaseId,
  employeeId,
  patientId,
  caseId,
  patientInformationSections,
  employeeInformationSections,
  personalInformationSections,
}) => {
  useEffect(() => {
    if (selectedOption) setInformationType(selectedOption);
  }, [setInformationType, selectedOption]);

  useEffect(() => {
    if (employeeId !== undefined) setEmployeeId(employeeId);
  }, [setEmployeeId, employeeId]);

  useEffect(() => {
    if (patientId !== undefined) setPatientId(patientId);
  }, [setPatientId, patientId]);

  useEffect(() => {
    if (caseId !== undefined) setCaseId(caseId);
  }, [setCaseId, caseId]);

  return (
    <div className={styles.column}>
      <div className={styles.informationSection}>
        {selectedOption === PATIENT_INFORMATION ? (
          <MultiRecordPatientInformation
            information={information}
            patientInformationSections={patientInformationSections}
          />
        ) : selectedOption === EMPLOYEE_INFORMATION ? (
          <MultiRecordEmployeeInformation
            information={information}
            employeeInformationSections={employeeInformationSections}
          />
        ) : selectedOption === PERSONAL_INFORMATION ? (
          <MultiRecordPersonalInformation
            information={information}
            personalInformationSections={personalInformationSections}
          />
        ) : null}
      </div>
    </div>
  );
};

MultiRecordEntityColumn.propTypes = {
  selectedOption: PropTypes.string,
  information: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  setInformationType: PropTypes.func.isRequired,
  setPatientId: PropTypes.func,
  setEmployeeId: PropTypes.func,
  setCaseId: PropTypes.func,
  employeeId: PropTypes.string,
  patientId: PropTypes.string,
  caseId: PropTypes.string,
  personalInformationSections: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  employeeInformationSections: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]),
  patientInformationSections: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]),
};

export default MultiRecordEntityColumn;
