import React from 'react';
import PropTypes from 'prop-types';
import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import styles from './index.module.scss';

const DataFeedTopNav = ({ errorCount }) => (
  <div className={styles.wrapper} data-cy="fdd-top-nav">
    <TopNavBar secondary>
      <NavBarItem route="/data-feed/evaluation">
        Data Evaluation
        {errorCount > 0 && (
          <span className={styles.error} data-cy="fdd-error-count">
            {errorCount}
          </span>
        )}
      </NavBarItem>
      <NavBarItem route="/data-feed/delivery">Data Delivery</NavBarItem>
    </TopNavBar>
  </div>
);

DataFeedTopNav.propTypes = {
  errorCount: PropTypes.number,
};

DataFeedTopNav.defaultProps = {
  errorCount: 0,
};

export default DataFeedTopNav;
