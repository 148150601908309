import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Authenticated } from 'common';
import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';

import { permissionMap } from '../helpers';
import { VIEW_PATH } from '../appUsers/const';

const TopNav = ({ location }) => (
  <TopNavBar>
    <Authenticated permission={permissionMap.case} anyPermission>
      <NavBarItem route="/settings">Case Creation</NavBarItem>
    </Authenticated>
    <Authenticated permission={permissionMap.template} anyPermission>
      <NavBarItem route="/settings/templates">Templates</NavBarItem>
    </Authenticated>
    <Authenticated permission={permissionMap.general} anyPermission>
      <NavBarItem route="/settings/general">General</NavBarItem>
    </Authenticated>
    <Authenticated appRole="ADMIN">
      <NavBarItem
        route="/settings/enums"
        active={location.pathname.startsWith('/settings/enums')}
      >
        Enums
      </NavBarItem>
    </Authenticated>
    <Authenticated permission={permissionMap.appUsers}>
      <NavBarItem
        route={VIEW_PATH}
        active={location.pathname.startsWith(VIEW_PATH)}
      >
        App Users
      </NavBarItem>
    </Authenticated>
    <Authenticated appRole="ADMIN">
      <NavBarItem
        route="/settings/roles"
        active={location.pathname.startsWith('/settings/roles')}
      >
        Roles
      </NavBarItem>
    </Authenticated>
  </TopNavBar>
);

TopNav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withRouter(TopNav);
