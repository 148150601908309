import React from 'react';
import PropTypes from 'prop-types';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';

import { RouterContainer } from 'common';

import { Button, DatePicker, Input, MultilineInput } from '../../ui';
import NotificationStore from '../../stores/NotificationStore';
import AuthorizationPermissionStore from '../stores/AuthorizationPermissionStore';
import { toLocalDateString } from '../../utils/LocalDateUtils';

const RevokeForm = observer(
  class RevokeForm extends React.Component {
    static propTypes = {
      view: PropTypes.string,
    };

    // Observable
    reasonValid = false;
    dateValid = true;
    submitted = false;
    revocationDate = moment();
    reason = '';
    confirmText = null;
    hasEnteredReason = false;

    onSubmit = e => {
      e.preventDefault();
      const { view } = this.props;

      this.submitted = true;

      AuthorizationPermissionStore.revoke(
        toLocalDateString(this.revocationDate),
        this.reason
      )
        .then(() => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Authorized access revocation successful
            </span>
          );
          NotificationStore.add({ level: 'success', content });

          view === 'Temporary Permission'
            ? RouterContainer.go('/authorizations/temporary')
            : RouterContainer.go('/authorizations');
        })
        .fail(() => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              An undefined error occurred revoking the authorization. Please
              check connectivity or contact support.
            </span>
          );
          NotificationStore.add({ level: 'warning', content });
        });
    };

    validateReason = reason => /\w+/.test(reason);

    onReasonChange = value => {
      this.reason = value;
      this.reasonValid = this.validateReason(value);
      this.hasEnteredReason = true;
    };

    onDateChange = (val, valid) => {
      this.dateValid = valid;
      if (valid) this.revocationDate = moment(val);
    };

    render() {
      const textValid = this.confirmText === 'REVOKE';
      const error = this.confirmText !== null && !textValid;
      const valid = textValid && this.reasonValid && this.dateValid;
      const { view } = this.props;
      const lcView = view.toLowerCase();

      return (
        <div>
          <form className="form padd-all" onSubmit={this.onSubmit}>
            <h2>{`Revoke ${view}`}</h2>
            <p>{`This ${lcView} will be permanently revoked and cannot be reauthorized.`}</p>
            <p>{`Type "REVOKE", case-sensitive, to confirm revocation of this ${lcView}.`}</p>
            <ul>
              <Input
                autoFocus
                bordered
                error={error}
                margin="md"
                label='Enter "REVOKE", case-sensitive'
                onChange={val => (this.confirmText = val)}
                value={this.confirmText || ''}
              />
              <li className="input-container input-group-date-picker registry__date">
                <DatePicker
                  value={this.revocationDate}
                  placeholder="Revocation Date"
                  label="Revocation Date"
                  onChange={this.onDateChange}
                />
              </li>
            </ul>
            <p>
              Please enter a reason for revoking this {lcView}. This is
              required.
            </p>
            <ul>
              <MultilineInput
                type="text"
                label={`Reason for revoking ${lcView}`}
                warn={this.hasEnteredReason && !this.reasonValid}
                value={this.reason}
                onChange={this.onReasonChange}
                bordered
              />
            </ul>
            <Button
              type="submit"
              value={`Revoke ${lcView}`}
              disabled={!valid}
            />
          </form>
        </div>
      );
    }
  }
);

decorate(RevokeForm, {
  reasonValid: observable,
  confirmText: observable,
  dateValid: observable,
  submitted: observable,
  revocationDate: observable,
  reason: observable,
  hasEnteredReason: observable,
});

export default RevokeForm;
