import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { CaseReportsContext } from '../Context';
import ChartAndTable from '../ChartAndTable';

export function mapProps({
  store,
  chartType,
  tableCollapsed,
  showLegend,
  'data-cy': dataCy,
}) {
  return {
    averageData: store.averageData,
    casesBy: store.casesBy,
    chartData: store.chartData,
    chartType,
    configuration: store.configuration,
    disabledIntervals: store.disabledIntervals,
    downloadCSV: store.downloadCSV,
    interval: store.interval,
    legendData: store.legendData,
    setChartCasesBy: store.setChartCasesBy,
    setChartInterval: store.setChartInterval,
    showChartCasesBy: chartType === 'lineChart',
    showIntervalFilter:
      chartType === 'lineChart' || Boolean(store.showIntervalFilter),
    showChartStatisticCalculation: store.showChartStatisticCalculation,
    showLegend,
    setChartStatisticCalculation: store.setChartStatisticCalculation,
    statisticCalculation: store.statisticCalculation,
    tableCollapsed,
    tableData: store.tableData,
    title: store.title,
    type: store.pivotDisplayName,
    skipAlphaNumericSort: store.skipAlphaNumericSort,
    'data-cy': dataCy,
  };
}

const ChartAndTableContainer = observerWrappedContainerFactory({
  Context: CaseReportsContext,
  mapProps,
  PresentationalComponent: ChartAndTable,
});

ChartAndTableContainer.displayName = 'ChartAndTableContainer';

export default ChartAndTableContainer;
