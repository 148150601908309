import React from 'react';
import { observer } from 'mobx-react';

import {
  AlertCategoryGroupStore,
  EnumValueStore,
  EnumCategoryStore,
} from 'common';
import EnumsManageForm from '../EnumsManageForm';

export function mapProps({
  AlertCategoryGroupStore,
  EnumValueStore,
  EnumCategoryStore,
  match,
  history,
}) {
  return {
    enumValueFields: EnumValueStore.editingEnumValueFields,
    onUpdateEnumValueField: EnumValueStore.setEditingEnumValueField,
    saveEnumValue: EnumValueStore.createOrUpdate,
    categories: EnumCategoryStore.categoriesForEnumsSettings,
    isValid: EnumValueStore.canSaveEnumValue,
    isDirty: EnumValueStore.isFormDirty,
    failure: EnumValueStore.savingEnumValueFailure,
    selectedEnumValueId: match.params.id,
    onChangeSelectedEnumValueId: EnumValueStore.setEditingEnumValueId,
    helperText: EnumValueStore.formText,
    groups: AlertCategoryGroupStore.groups,
    disabledGroups: EnumValueStore.disabledGroups,
    onCloseForm: () => history.push('/settings/enums'),
  };
}

const EnumsManageFormContainer = observer(({ match, history }) => (
  <EnumsManageForm
    {...mapProps({
      AlertCategoryGroupStore,
      EnumValueStore,
      EnumCategoryStore,
      match,
      history,
    })}
  />
));

EnumsManageFormContainer.displayName = 'EnumsManageFormContainer';

export default EnumsManageFormContainer;
