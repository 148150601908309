import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function ListItem({ item, exclusive }) {
  const { label, filterLabel } = item;

  if (!filterLabel || !label) return <span>{label || filterLabel}</span>;
  return (
    <span>
      {filterLabel}{' '}
      <strong className={classnames({ 'text-danger': exclusive })}>
        {exclusive ? 'Is Not' : 'Is'}
      </strong>
      <strong data-cy={'list-item--value'}>{`: ${label}`}</strong>
    </span>
  );
}

ListItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    filterLabel: PropTypes.string,
  }).isRequired,
  exclusive: PropTypes.bool,
  'data-cy': PropTypes.string,
};

ListItem.defaultProps = {
  exclusive: false,
};
export default ListItem;
