import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { AsidePanel } from '../../ui';
import { updateQueryParams } from '../DataFeedEvaluationView/helpers';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import styles from './index.module.scss';
import {
  getPathFromLocation,
  getSourceFromLocation,
} from '../stores/DeliveryStore/helpers';

export const handleOnClose = ({ query, location, historyPush }) => {
  const newQuery = { ...query, selectedRow: undefined };

  historyPush(
    updateQueryParams({ query: newQuery, location, showAsidePanel: false })
  );
};

export const IssueDescription = ({ descriptions = [] }) => (
  <>
    <h4 className={styles.subheader}>Issue Description</h4>
    <div>
      {(descriptions || []).map((d, i) => (
        <div className={styles.issues} key={d.title + i}>
          <p>
            <strong data-cy="fdd-panel-description-type">{d.title}</strong>
          </p>
          <p>{d.description}</p>
        </div>
      ))}
    </div>
  </>
);

IssueDescription.propTypes = {
  descriptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
};

export const EmailSentTo = ({ recipients = [] }) => (
  <>
    <h4 className={styles.subheader}>Email sent to</h4>
    <div className={styles.emails}>
      {(recipients || []).map((r, i) =>
        i === recipients.length - 1 ? (
          <p key={r}>{`${r}`}</p>
        ) : (
          <p key={r}>{`${r}, `}</p>
        )
      )}
    </div>
  </>
);

EmailSentTo.propTypes = {
  recipients: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
};

const DataFeedEvaluationAside = ({
  selectedFeedRow,
  isOpen = false,
  history,
  query,
  location,
}) => {
  const { regexPrefix = '' } = selectedFeedRow;

  const path = getPathFromLocation(regexPrefix, 'Any');
  const source = getSourceFromLocation(regexPrefix, 'Any');
  const name = regexPrefix.substr(regexPrefix.lastIndexOf('/') + 1);

  return (
    <AsidePanel
      title={'Issue Detail'}
      isOpen
      onClose={() =>
        handleOnClose({ query, location, historyPush: history.push })
      }
      className={classnames(styles.asidePanel, { [styles.withAside]: isOpen })}
    >
      {Object.keys(selectedFeedRow).length ? (
        <div className={styles.values}>
          <h4 className={styles.subheader}>Feed Name</h4>
          <div data-cy="fdd-panel-feed-name">
            <Link
              to={{
                pathname: '/data-feed/delivery',
                search: queryString.stringify({
                  name: name,
                  path: path,
                  source: source,
                  deliveryAfter: query.createdAfter,
                  processingState: 'Any',
                }),
              }}
            >
              {selectedFeedRow.name}
            </Link>
          </div>
          <IssueDescription descriptions={selectedFeedRow.descriptions} />
          <EmailSentTo recipients={selectedFeedRow.recipients} />
        </div>
      ) : (
        <></>
      )}
    </AsidePanel>
  );
};

DataFeedEvaluationAside.propTypes = {
  selectedFeedRow: PropTypes.shape({
    date: PropTypes.string,
    name: PropTypes.string,
    regexPrefix: PropTypes.string,
    recipients: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
    descriptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  }),
  isOpen: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  query: PropTypes.shape({
    selectedRow: PropTypes.string,
    createdAfter: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default DataFeedEvaluationAside;
