import {
  CASEFLOW_ACTIONS,
  CASEFLOW_ALL,
  CASEFLOW_ASSESSMENTS,
  CASEFLOW_ATTACHMENTS,
  CASEFLOW_EMAILS,
  CASEFLOW_NONE,
  CASEFLOW_NOTES,
  CASEFLOW_RADAR,
} from '../consts';

/**
 * filter function for printing caseflow events
 *
 * @param event
 * @param selectedCaseflowOption
 * @param caseflowFieldsToInclude
 * @returns {boolean}
 */
export function matchCaseflowPrintOptions(
  event,
  selectedCaseflowOption = '',
  caseflowFieldsToInclude = [],
  filteredAssessmentsForPrint = []
) {
  let { key = '' } = event;

  const assessmentKeys = filteredAssessmentsForPrint.map(
    ({ minEventDate }) => CASEFLOW_ASSESSMENTS + minEventDate
  );

  if (key.substr(0, 10) === CASEFLOW_ASSESSMENTS) {
    return assessmentKeys.includes(key);
  } else if (selectedCaseflowOption === CASEFLOW_NONE) {
    return false;
  } else if (selectedCaseflowOption === CASEFLOW_ALL) return true;
  else {
    if (key.includes(CASEFLOW_NOTES)) key = CASEFLOW_NOTES;
    if (key.includes(CASEFLOW_ACTIONS) || key.includes(CASEFLOW_RADAR))
      key = CASEFLOW_ACTIONS;
    if (key.includes(CASEFLOW_EMAILS)) key = CASEFLOW_EMAILS;
    if (key.includes(CASEFLOW_ATTACHMENTS)) key = CASEFLOW_ATTACHMENTS;
    if ((caseflowFieldsToInclude || []).map(f => f.value).includes(key))
      return true;
    else return false;
  }
}
