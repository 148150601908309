import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { observer } from 'mobx-react';

import { Authenticated, HTMLEntities } from 'common';
import CaseAuthorizationsStore from '../stores/CaseAuthorizationsStore';

/**
 * Helper function to format a date String
 * @param {String} dateString - the datetime string to convert to a Date Object
 *                              and then format
 * @return {String} Formatted date string
 */
function formatDate(dateString) {
  return moment(dateString).format('l');
}

/**
 * Helper method to produce a display string for the status of an authorized access
 * @param {Object} auth - the Authorized Access for which to create a
 *                        displayable status
 * @return {String} The authorized access status
 */
function authStatus(auth) {
  const status = auth.status;
  return (
    (status && `${status.charAt(0)}${status.substr(1).toLowerCase()}`) ||
    'Active'
  );
}

const CaseViewAuthPermissions = observer(
  ({ userId, patientId, permissionView }) => {
    const label = permissionView ? 'Permissions' : 'Authorizations';
    const auths = permissionView
      ? CaseAuthorizationsStore.permissions
      : CaseAuthorizationsStore.auths;

    let items;

    if (auths.length) {
      items = auths.map(auth => {
        const linkPrefix = permissionView
          ? '/authorizations/temporary'
          : '/authorizations';

        return (
          <li key={`accessAuth-${auth.id}`}>
            <Link to={`${linkPrefix}/${auth.id}`}>
              {formatDate(auth.start)}
              {` ${HTMLEntities.endash} `}
              {auth.revocationDate
                ? formatDate(auth.revocationDate)
                : formatDate(auth.requestedEnd)}
            </Link>
            <span className="status">{authStatus(auth)}</span>
          </li>
        );
      });
    } else {
      items = <li key="noAccessAuths" className="disabled">{`No ${label}`}</li>;
    }
    return (
      <Authenticated permission="ACCESS_AUTH_SEARCH">
        <section className={permissionView ? 'permissions' : 'authorizations'}>
          <div className="item">
            <h5 className="float-left">{label}</h5>
            {!permissionView && (
              <Authenticated permission="ACCESS_AUTH_CREATE">
                <Link
                  className="strong header-link"
                  to={`/authorizations/create/patient/${patientId}/employee/${userId}`}
                >
                  Add Authorization
                </Link>
              </Authenticated>
            )}
            <ul>{items}</ul>
          </div>
        </section>
      </Authenticated>
    );
  }
);

CaseViewAuthPermissions.propTypes = {
  patientId: PropTypes.string.isRequired,
  permissionView: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

CaseViewAuthPermissions.defaultProps = {
  permissionView: false,
};

export default CaseViewAuthPermissions;
