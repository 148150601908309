/**
 * Mapping of HTML Entities to their unicode values, for including inside of
 * strings to avoid escaping issues.
 * @constant
 */
export var HTMLEntities = {
  emdash: "\u2014",
  endash: "\u2013",
  gt: ">",
  lt: "<",
  rsquo: "\u2019",
  lsquo: "\u2018",
  rdquo: "\u201D",
  ldquo: "\u201C"
};