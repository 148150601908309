import { MultiRecordMetadataSummary } from '../MultiRecordMetadataSummary';
import { MultiRecordViewContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../../utils/RouteHelper';

export function mapProps({ match, multiRecordUserMetadataStore }) {
  return {
    personType: multiRecordUserMetadataStore.personType,
    metadataTypes: multiRecordUserMetadataStore.metadataTypes,
    match,
  };
}

export const MultiRecordUserMetadataSummaryContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordMetadataSummary,
  })
);
