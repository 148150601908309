import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  reaction,
} from 'mobx';
import GeneralSettingsStore from '../GeneralSettingsStore';

class PrintSettings extends GeneralSettingsStore {
  category = 'services.readWrite';
  key = 'general.printSettings';

  constructor() {
    super();

    autorun(() => {
      this.refresh();
    }, true);

    reaction(
      () => [this.printSettings],
      () => {
        Object.keys(this.printSettingsEditValues).forEach(name => {
          this.printSettingsEditValues[name] = this.printSettings[name];
        });
      }
    );
  }

  // observable
  printSettingsEditValues = {
    EMRUserAddress: false,
    dob: false,
    phoneNumber: false,
    accountSecurityRole: false,
  };

  // Computed
  get settings() {
    const { properties } = this.response || {};
    return properties || {};
  }

  get printSettings() {
    // The value is stored as strings in the DB, it will be either 'true' or 'false'. This line is to make sure we are getting a true value check and coercing it to a boolean.
    return {
      EMRUserAddress: this.settings.EMRUserAddress === 'true',
      dob: this.settings.dob === 'true',
      phoneNumber: this.settings.phoneNumber === 'true',
      accountSecurityRole: this.settings.accountSecurityRole === 'true',
    };
  }

  get isDirty() {
    return Object.keys(this.printSettings)
      .map(k1 => this.printSettings[k1] === this.printSettingsEditValues[k1])
      .includes(false);
  }

  // action
  toggleValue = name => {
    this.printSettingsEditValues = {
      ...this.printSettingsEditValues,
      [name]: !this.printSettingsEditValues[name],
    };
  };

  updateSettings = () => {
    const newObj = {};
    Object.keys(this.printSettingsEditValues).forEach(name => {
      if (this.printSettings[name] !== this.printSettingsEditValues[name]) {
        Object.assign(newObj, { [name]: this.printSettingsEditValues[name] });
      }
    });
    this.update(newObj);
  };
}

decorate(PrintSettings, {
  toggleValue: action,
  printSettingsEditValues: observable,
  isDirty: computed,
  printSettings: computed,
  settings: computed,
});

const PrintSettingsStore = new PrintSettings();
export default PrintSettingsStore;
