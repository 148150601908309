import React from 'react';

export default function ExclusiveFilterLabel(value, item, key, exclusive) {
  switch (key) {
    case 'bundle':
      if (exclusive)
        return (
          <span>
            Case <strong className="text-danger">Is Not In </strong> Bundle
          </span>
        );
      return (
        <span>
          Case <strong>Is In </strong>bundle
        </span>
      );
    case 'actionsTaken':
      if (exclusive)
        return (
          <span>
            Actions <strong className="text-danger">Not </strong> Taken
          </span>
        );
      return (
        <span>
          Actions <strong>Were </strong>Taken
        </span>
      );
    case 'created':
    case 'lastModified':
      if (value) {
        if (exclusive)
          return (
            <span>
              Case <strong className="text-danger">Was Not</strong>{' '}
              {item.filterLabel.slice(4)}: <strong>{item.label}</strong>
            </span>
          );
        return (
          <span>
            Case <strong>Was </strong> {item.filterLabel.slice(4)}:{' '}
            <strong>{item.label}</strong>
          </span>
        );
      }
      return <span>{item.label}</span>;
    case 'resolutionDate':
      if (value) {
        if (exclusive)
          return (
            <span>
              {item.filterLabel} <strong className="text-danger">Is Not</strong>
              : <strong>{item.label}</strong>
            </span>
          );
        return (
          <span>
            {item.filterLabel} <strong>Is</strong>:{' '}
            <strong>{item.label}</strong>
          </span>
        );
      }
      return <span>{item.label}</span>;
    case 'violationDate':
      if (value) {
        if (exclusive)
          return (
            <span>
              {item.filterLabel}{' '}
              <strong className="text-danger">Was Not</strong>:{' '}
              <strong>{item.label}</strong>
            </span>
          );
        return (
          <span>
            {item.filterLabel} <strong>Was</strong>:{' '}
            <strong>{item.label}</strong>
          </span>
        );
      }
      return <span>{item.label}</span>;
    case 'actionsDate':
      if (value) {
        return (
          <span>
            Actions <strong>Were </strong>Taken During:{' '}
            <strong>{item.label}</strong>
          </span>
        );
      }
      return <span>{item.label}</span>;
    case 'timeToResolve':
    case 'suspicionScore':
      if (item.value === 'all') return <span>{item.filterLabel}</span>;
      if (exclusive)
        return (
          <span>
            {item.filterLabel}
            <strong className="text-danger"> Is Not</strong>:&nbsp;
            <strong>{item.label}</strong>
          </span>
        );
      return (
        <span>
          {item.filterLabel} <strong> Is</strong>:&nbsp;
          <strong>{item.label}</strong>
        </span>
      );
    case 'owner':
    case 'createdBy':
    case 'category':
    case 'resolution':
    case 'userOrganization':
    case 'userDepartment':
    case 'userType':
    case 'userTags':
      if (exclusive)
        return (
          <span>
            {item} <strong className="text-danger">Is Not</strong>{' '}
          </span>
        );
      return (
        <span>
          {item} <strong>Is</strong>{' '}
        </span>
      );
    case 'resolvedBy':
      if (exclusive)
        return (
          <span>
            Case <strong className="text-danger">Was Not </strong>Resolved By{' '}
          </span>
        );
      return (
        <span>
          Case <strong>Was </strong>Resolved By{' '}
        </span>
      );
    default:
      return <span>{item.label}</span>;
  }
}
