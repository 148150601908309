import { computed, decorate, observable, toJS } from 'mobx';

import BundledCasesStore from '../../../stores/BundledCasesStore';

class BundleDataStore {
  // Observable
  active = null;

  // Computed
  get data() {
    const resolutions = [
      {
        type: 'UNRESOLVED',
        group: 'Unresolved',
        count: 0,
        color: '#FFF',
      },
      {
        type: 'VIOLATION',
        group: 'Violation',
        count: 0,
        color: '#abbaf4',
      },
      {
        type: 'NOT_VIOLATION',
        group: 'Not a Violation',
        count: 0,
        color: '#7e97ee',
      },
    ];

    BundledCasesStore.visibleCases.forEach(c => {
      const category = resolutions.find(r => {
        if (c.resolution) return r.type === c.resolution;
        return r.type === 'UNRESOLVED';
      });

      if (category) category.count += 1;
    });

    return toJS(resolutions);
  }

  margin = {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10,
  };
}

decorate(BundleDataStore, {
  active: observable,
  data: computed,
});

const store = new BundleDataStore();
export { store as BundleDataStore };
export default store;
