import React from 'react';

import { Authenticated, ProtectedRoute } from 'common';
import { Switch } from 'react-router-dom';
import { DocumentTitle } from '../../../ui';
import EnumsListViewContainer from '../EnumsListViewContainer';

import styles from './index.module.scss';
import EnumsManageFormContainer from '../EnumsManageFormContainer';

const EnumsRootView = () => (
  <Authenticated appRole="admin">
    <DocumentTitle text="Enums Management" />
    <div className={styles.rootView}>
      <EnumsListViewContainer />
      <Switch>
        <ProtectedRoute
          exact
          path="/settings/enums/create"
          component={EnumsManageFormContainer}
        />
        <ProtectedRoute
          exact
          path="/settings/enums/:id"
          component={EnumsManageFormContainer}
        />
      </Switch>
    </div>
  </Authenticated>
);

export default EnumsRootView;
