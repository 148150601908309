import React from 'react';
import PropTypes from 'prop-types';
import ExclusionMultiselect from '../../../ui/Filters/ExclusionMultiselect';
import {
  INCIDENTS_TEXT_FIELD,
  INCIDENTS_VALUE_FIELD,
} from '../stores/IncidentsPrintOptionsStore/consts';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { toJS } from 'mobx';
import styles from './index.module.scss';

const IncidentsCustomFilterOptions = ({
  timeRangeOptions,
  statusOptions,
  typeOptions,
  selectedIncidentTimeRanges,
  selectedIncidentTypes,
  selectedIncidentStatuses,
  handleStatusesOnChange,
  handleTimeRangesOnChange,
  handleTypesOnChange,
  timeRangesLabel,
  statusesLabel,
  typesLabel,
}) => {
  return (
    <div className={styles.wrapper}>
      <ExclusionMultiselect
        label={timeRangesLabel}
        options={timeRangeOptions}
        value={toJS(selectedIncidentTimeRanges)}
        textField={INCIDENTS_TEXT_FIELD}
        valueField={INCIDENTS_VALUE_FIELD}
        onChange={handleTimeRangesOnChange}
        hideExclusion
        hideExclusionSpacing
      />
      <ExclusionMultiselect
        label={statusesLabel}
        options={statusOptions}
        value={toJS(selectedIncidentStatuses)}
        textField={INCIDENTS_TEXT_FIELD}
        valueField={INCIDENTS_VALUE_FIELD}
        onChange={handleStatusesOnChange}
        hideExclusion
        hideExclusionSpacing
      />
      <ExclusionMultiselect
        label={typesLabel}
        options={typeOptions}
        value={toJS(selectedIncidentTypes)}
        textField={INCIDENTS_TEXT_FIELD}
        valueField={INCIDENTS_VALUE_FIELD}
        onChange={handleTypesOnChange}
        hideExclusion
        hideExclusionSpacing
      />
    </div>
  );
};

IncidentsCustomFilterOptions.propTypes = {
  timeRangeOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  statusOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  typeOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  selectedIncidentTimeRanges: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  selectedIncidentTypes: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  selectedIncidentStatuses: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  handleStatusesOnChange: PropTypes.func.isRequired,
  handleTimeRangesOnChange: PropTypes.func.isRequired,
  handleTypesOnChange: PropTypes.func.isRequired,
  timeRangesLabel: PropTypes.string,
  statusesLabel: PropTypes.string,
  typesLabel: PropTypes.string,
};

export default IncidentsCustomFilterOptions;
