import { RouterContainer, HalUtils } from 'common';

class TagHelpers {
  goTo(baseUrl, data) {
    RouterContainer.go(`${baseUrl}/${HalUtils.getId(data)}`);
  }

  getId(data) {
    return HalUtils.getId(data);
  }
}

const me = new TagHelpers();
export { me as TagHelpers };
export default me;
