import {
  action,
  computed,
  decorate,
  observable,
  reaction,
  comparer,
} from 'mobx';
import {
  CaseBundleClient,
  HalUtils,
  SingletonStore,
  convertMultiValue,
} from 'common';

class CaseBundleStoreClass extends SingletonStore {
  constructor() {
    super();

    this.refresh();

    reaction(
      () => [this.query.group, this.valid],
      () => {
        this.refresh();
      },
      {
        equals: comparer.structural,
      }
    );
  }

  // Observables
  caseToBundles = new Map();
  // If we try to load too many bundles at once, they might not fit in the
  // aggregation pipeline. We will use the loadAllPages() feature of PagedStore
  // to load all bundles.
  size = 100;
  projection = 'full';
  redirect = false;
  query = {};

  // Computed
  get allBundles() {
    return this.result || [];
  }

  // Computed
  get allVisibleBundles() {
    return this.allBundles.filter(bundle => bundle.isVisible);
  }

  getBundle(bundleId) {
    return this.allBundles.find(bundle => HalUtils.getId(bundle) === bundleId);
  }

  casesFor(bundleId, visibleOnly) {
    const bundle = this.getBundle(bundleId);
    let caseIds;

    if (bundle && bundle.cases) {
      caseIds = bundle.cases
        .filter(c => !visibleOnly || c.number)
        .map(c => c.id);
    } else {
      caseIds = [];
    }

    return caseIds;
  }

  caseHasBundles = caseId => {
    return this.bundlesFor(caseId).length > 0;
  };

  reset() {
    this.caseToBundles = new Map();
    this.redirect = false;
  }

  delete = bundleId => {
    return CaseBundleClient.delete(bundleId).then(() => {
      this.refresh();
      this.redirect = true;
    });
  };

  // Actions
  /**
   * Sets query parameters in store from URL
   * @param  {Object} newQuery - the Object containing params to be converted
   */
  setQueryFromURL = newQuery => {
    this.query = {
      ...newQuery,
    };
  };

  fetch() {
    return CaseBundleClient.getAllBundles({
      groups: convertMultiValue(this.query.group),
    });
  }
}

decorate(CaseBundleStoreClass, {
  caseToBundles: observable,
  size: observable,
  projection: observable,
  allBundles: computed,
  allVisibleBundles: computed,
  reset: action,
  setQueryFromURL: action,
  query: observable,
});

const store = new CaseBundleStoreClass();
export { store as CaseBundleStore, CaseBundleStoreClass };
export default store;
