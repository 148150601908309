import React from 'react';
import ListItem from '../../ui/SideList/ListItem';
import PropTypes from 'prop-types';
import Field from '../../ui/Field';
import ViaSource from '../../ui/ViaSource';
import intersperse from '../../utils/intersperse';
import { generateHighlightedText } from '../../mrv/utils/helpers';

export const getLabel = type =>
  type ? `${type.capitalizeFirstLetter()} Address` : 'Address';

const Address = ({ address, className, usage, filteredTerm }) => {
  const { address1, address2, type, city, state, zip, source } = address,
    street = intersperse(
      [address1, address2].filter(s => s !== undefined),
      <br key="street-sep" />
    ),
    cityState = [city, state].filter(s => s !== undefined).join(', '),
    cityStateZip = [cityState, zip].filter(s => s !== undefined).join(' '),
    fullAddress = intersperse(
      [street, cityStateZip].filter(s => s !== undefined && s.length !== 0),
      <br key="address-sep" />
    );

  if (usage === 'forPersonDetail')
    return (
      <Field label={getLabel(type)} width="auto">
        <p>{fullAddress}</p>
        <ViaSource source={source} />
      </Field>
    );
  else if (usage === 'forMRV')
    return (
      <p className={className}>
        {fullAddress.map(address => {
          // pull this out into own function
          if (typeof address === 'string') {
            return generateHighlightedText(address, filteredTerm);
          }
          if (Array.isArray(address)) {
            return address.map(address => {
              if (typeof address === 'string') {
                return generateHighlightedText(address, filteredTerm);
              }
              return address;
            });
          }
          return address;
        })}
      </p>
    );
  else return <ListItem label={getLabel(type)} value={fullAddress} />;
};

Address.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    type: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    source: PropTypes.string,
  }),
  usage: PropTypes.string,
  filteredTerm: PropTypes.string,
};

export default Address;
