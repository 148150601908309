import React from 'react';
import { max, scaleLinear } from 'd3';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import CaseReportsPropTypes from '../../reports/PropTypes';
import { Pandy } from 'common';
import styles from './index.module.scss';
import { reportsColors } from '../../reports/ChartAndTable/BarChartTheme';
import Tooltip from '../Tooltip';
import { compareAndPrioritize } from '../../utils/compare';

const TOO_MANY_RESULTS =
  'Too many results to display a clear bar chart. Use filters to reduce the number of results.';
const NO_RESULTS = 'No data found for this chart.';

const compare = compareAndPrioritize('Uncategorized');

export const sortByTimeRangeValue = flatChartData => {
  return flatChartData.sort((a, b) =>
    compare(a.timeRangeValue || '', b.timeRangeValue || '')
  );
};

/**
 * Generate tooltip content for each bar.
 *
 * @param bucketName {string} Name associated with the bar's group. If it
 *                            happens to be "Uncategorized", we'll just ignore it.
 * @param x {string} x-axis value.
 * @param y {number} y-axis value.
 * @return {string}
 */
const tooltipContent = ({ bucketName, x, y }) => {
  if (bucketName === 'Uncategorized') bucketName = '';
  return `${[x, bucketName].filter(Boolean).join(', ')}: ${y}`;
};

// Magical numbers. Adjust these to have bars breathe more or less.
// A smaller ratio means it takes up less space, thus more breathing room.
const BAR_WIDTH_RATIO = 0.4;
const BAR_SPACING_RATIO = 0.2;
const Y_AXIS_BREATHING_PX = 46;
const MAX_BAR_WIDTH = 100;

/**
 * A bar chart that supports groups of bars.
 */
const GroupedBarChart = ({
  averageData,
  chartData = [],
  configuration: { maxBars } = {},
  configuration: { singleColorBars } = false,
  configuration: { showAverageLine } = false,
  isPrinterFriendly = false,
  height = 0,
  width = 0,
  skipAlphaNumericSort,
}) => {
  // We allow space on the left for the y-axis, so this takes away from usable
  // width for the bars.
  width -= Y_AXIS_BREATHING_PX;

  // Ensure we're always dealing with an array of arrays and not just a flat set
  // of coordinates.
  chartData = chartData[0] instanceof Array ? chartData : [chartData];
  // Make a copy of the chart data in flat form so we know how many bars we are
  // dealing with.
  let flatChartData = chartData.flatMap(d => d);

  // For Days to Resolution chart, there's a chance that queried cases are 'Unresolved'
  // which results the aggregation to return nothing
  const noData = !flatChartData.length;

  if (skipAlphaNumericSort) flatChartData = sortByTimeRangeValue(flatChartData);

  if (flatChartData.length > maxBars || noData) {
    return (
      <Pandy
        visible
        mood="sad"
        className={classnames(styles.pandyWrapper, {
          forPrint: isPrinterFriendly,
          forScreen: !isPrinterFriendly,
        })}
      >
        <span>{noData ? NO_RESULTS : TOO_MANY_RESULTS}</span>
      </Pandy>
    );
  }

  const xValues = [
    ...flatChartData.reduce((set, { x }) => {
      set.add(x);
      return set;
    }, new Set()),
  ];

  if (!skipAlphaNumericSort) xValues.sort((a, b) => compare(a || '', b || ''));

  const barGroups = xValues.map(x =>
    chartData
      .map((coords, colorIndex) => {
        const coord = coords.find(c => c.x === x);
        if (coord) {
          return { ...coord, colorIndex };
        }
        return null;
      })
      .filter(Boolean)
  );

  // The following calculations could be avoided if IE didn't have a bug with
  // nested space-around containers.

  // Calculate the width each bar will have.
  const barWidth = Math.min(
    MAX_BAR_WIDTH,
    (width / (flatChartData.length || 1)) * BAR_WIDTH_RATIO
  );
  // barSpacing will be the amount of space to the left and right of each bar.
  const barSpacing = barWidth * BAR_SPACING_RATIO;
  // leftoverSpace is the amount of whitespace left after we consider the widths
  // of all bars and the space to the left and right of all bars.
  const leftoverSpace =
    width -
    barWidth * flatChartData.length -
    barSpacing * flatChartData.length * 2;
  // groupSpacing is the space to the left and right of each group.
  const groupSpacing = leftoverSpace / barGroups.length / 2;

  // This getter will determine how much space a group takes up on the x-axis.
  const barGroupWidth = barGroup =>
    barGroup.length * barWidth +
    barGroup.length * barSpacing * 2 +
    groupSpacing * 2;

  const needsDiagonalLabels = !!barGroups.find(
    barGroup => barGroupWidth(barGroup) < 100
  );

  // Allow for a bit of padding at the bottom for x-axis text and top for
  // breathing room.
  const marginTop = 10;
  if (height > 0) {
    height -= marginTop;
    height -= needsDiagonalLabels ? 50 : 20;
  }
  // We'll need to map y values to pixel height values.
  const numTicks = height / 50;
  const yScale = scaleLinear()
    .domain([0, max(flatChartData, ({ y }) => y)])
    .nice(numTicks)
    .range([0, height]);

  const yTicks = yScale.ticks(numTicks);

  return (
    <div
      className={classnames(styles.barChart, {
        forPrint: isPrinterFriendly,
        forScreen: !isPrinterFriendly,
      })}
      style={{ marginTop, height }}
    >
      {/*Render the horizontal y ticks.*/}
      {yTicks.map(y => (
        <div
          key={y}
          className={styles.horizontalTick}
          style={{ bottom: yScale(y) }}
        >
          {/*Render the tick number at the left of each horizontal line.*/}
          <span className={styles.tickNumber}>
            {y > 0 && Number.isInteger(y) ? y : null}
          </span>
        </div>
      ))}
      {showAverageLine && (
        <Tooltip
          content={tooltipContent({
            bucketName: 'Average',
            y: averageData,
          })}
          hideOnClick={false}
        >
          <div
            className={styles.averageBar}
            style={{ bottom: yScale(averageData) }}
          ></div>
        </Tooltip>
      )}
      {/*.barArea is the canvas on which we render the groups of bars.*/}
      <div className={styles.barArea} data-cy="bar-area">
        {barGroups.map(barGroup => {
          return (
            <div
              key={barGroup[0].x}
              className={classnames(styles.barGroup, {
                [styles.diagonalLabel]: needsDiagonalLabels,
              })}
              style={{
                marginLeft: groupSpacing,
                marginRight: groupSpacing,
              }}
            >
              {barGroup.map(({ x, y, bucketName, colorIndex }) => (
                <Tooltip
                  key={colorIndex}
                  content={tooltipContent({ bucketName, x, y })}
                  hideOnClick={false}
                >
                  <div
                    className={styles.bar}
                    style={{
                      width: barWidth,
                      height: Math.max(2, yScale(Math.max(0, y))), // we want to show a couple pixels for zero values
                      backgroundColor: singleColorBars
                        ? reportsColors[0]
                        : reportsColors[colorIndex],
                      marginLeft: barSpacing,
                      marginRight: barSpacing,
                    }}
                  />
                </Tooltip>
              ))}
              <span
                className={styles.barGroupLabel}
                title={barGroup[0].x}
                style={{ maxWidth: `calc(100% + ${groupSpacing * 1.75}px)` }}
                data-cy="bar-area--value"
              >
                {barGroup[0].x}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const chartDatumType = PropTypes.shape({
  x: PropTypes.string.isRequired,
  y: PropTypes.number.isRequired,
  bucketName: PropTypes.string,
});

GroupedBarChart.propTypes = {
  averageData: PropTypes.string,
  chartData: PropTypes.arrayOf(
    PropTypes.oneOfType([chartDatumType, PropTypes.arrayOf(chartDatumType)])
  ),
  configuration: CaseReportsPropTypes.configuration,
  isPrinterFriendly: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  skipAlphaNumericSort: PropTypes.bool,
};

export default GroupedBarChart;
