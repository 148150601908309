import React from 'react';
import SelectMenu from '../SelectMenu';

const DateIntervalSelect = ({ ...props }) => {
  const data = [
    {
      value: 'year',
      label: 'Year',
    },
    {
      value: 'month',
      label: 'Month',
    },
    {
      value: 'week',
      label: 'Week',
    },
    {
      value: 'day',
      label: 'Day',
    },
  ];

  return <SelectMenu data={data} {...props} />;
};

export default DateIntervalSelect;
