import React from 'react';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';

import BulkCaseActionFormActions from '../BulkCaseActionFormActions';
import { MultilineInput } from '../..';
import ActionPropTypes from '../PropTypes';

const OverwriteCaseAssessment = observer(
  class OverwriteCaseAssessment extends React.Component {
    static propTypes = {
      store: ActionPropTypes.store.isRequired,
    };

    // Observables
    newAssessment = '';

    /**
     * Set the final case assessment to a new value. This is the onClick handler
     * for this form. Pulls the data from the form and calls bulk action method
     * from the associated store.
     * @param {Object} e the event
     * @return {undefined}
     */
    onSubmit = e => {
      e.preventDefault();
      const { store } = this.props;

      store.performBulkAction('update', { assessment: this.newAssessment });
    };

    /**
     * Handle the assessment value change. This is the onChange callback handler
     * for the editor.
     * @param {Object} value    - the new value being set
     *
     * @return {void}
     */
    onChange = value => {
      this.newAssessment = value;
    };

    render() {
      const { store } = this.props;
      const { performingBulkAction } = store;

      return (
        <form className="form" onSubmit={this.onSubmit}>
          <ul>
            <li>
              <div className="input-section">
                <MultilineInput
                  label="Final Assessment"
                  value={this.newAssessment}
                  onChange={this.onChange}
                  readOnly={performingBulkAction}
                  bordered
                />
              </div>
            </li>
            <BulkCaseActionFormActions
              store={store}
              buttonActionType="updateAssessment"
              helpText="This action will overwrite the Final Assessment for all selected cases and cannot be undone."
              isList
            />
          </ul>
        </form>
      );
    }
  }
);

decorate(OverwriteCaseAssessment, {
  newAssessment: observable,
});

OverwriteCaseAssessment.displayName = 'OverwriteCaseAssessment';

export default OverwriteCaseAssessment;
