import React from 'react';

import CaseReportTotalOpen from '../CaseReportTotalOpen';
import CaseReportOpenAges from '../CaseReportOpenAges';

const CaseReportsOpen = () => (
  <section className="home__cases-open">
    <header>
      <h3>Unresolved Cases</h3>
    </header>
    <div>
      <CaseReportTotalOpen />
      <CaseReportOpenAges />
    </div>
  </section>
);

export default CaseReportsOpen;
