import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';

import { withRouter } from 'react-router-dom';
import { DateHelpers, HalUtils } from 'common';
import { Column, Cell } from 'fixed-data-table-2';
import {
  Registry,
  DateCell,
  CreatedByCell,
  ModifiedByCell,
  PatientCell,
  RegistrySortableHeaderCell,
  StatusCell,
} from '../../ui';
import AsideHelpers from '../../ui/AsidePanel/AsideHelpers';
import ToggleAllCell from '../../ui/ToggleAllCell';
import ToggleCell from '../../ui/ToggleCell';

const HeaderCell = ({
  label,
  propName,
  vipsStore,
  baseUrl,
  'data-cy': dataCy,
}) => {
  return (
    <RegistrySortableHeaderCell
      label={label}
      propName={propName}
      store={vipsStore}
      baseUrl={baseUrl}
      data-cy={dataCy}
    />
  );
};

HeaderCell.propTypes = {
  label: PropTypes.string,
  propName: PropTypes.string,
  baseUrl: PropTypes.string,
  vipsStore: PropTypes.shape({}),
  'data-cy': PropTypes.string,
};

const tablePadding = 50;

const List = ({
  history,
  vipsStore,
  advancedFiltersVisible,
  showCheckboxes,
  ...other
}) => {
  const timezone = DateHelpers.getCurrentAppUserTimezone();
  const [width, setWidth] = useState(100);
  const table = useRef(null);

  const setSize = useCallback(() => {
    const node = table.current;
    setWidth(node.offsetParent.offsetWidth - tablePadding);
  }, []);

  useEffect(() => {
    advancedFiltersVisible === true
      ? AsideHelpers.openPanel()
      : AsideHelpers.closePanel();

    window.addEventListener('resize', setSize);

    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [advancedFiltersVisible, setSize]);

  const setCellWidth = percentage => {
    const totalWidth = width - (showCheckboxes ? 60 : 0);
    percentage /= 100;
    return totalWidth * percentage;
  };

  const data = vipsStore.results;

  const headerBaseUrl = showCheckboxes ? '/vips/update' : '/vips';

  return (
    <div ref={table} data-cy="vip-table">
      <Registry
        dataCount={data.length || 0}
        width={width}
        onClick={index => history.push(`/vips/${HalUtils.getId(data[index])}`)}
        data-cy="registry-table"
        {...other}
      >
        {showCheckboxes && (
          <Column
            header={<ToggleAllCell store={vipsStore} />}
            width={60}
            data={data}
            cellClassName="no-wrap"
            cell={({ rowIndex }) => (
              <ToggleCell option={data[rowIndex]} store={vipsStore} />
            )}
          />
        )}
        <Column
          header={
            <HeaderCell
              vipsStore={vipsStore}
              label="VIP Name"
              propName="patientFullName"
              baseUrl={headerBaseUrl}
              data-cy="vip-name--header"
            />
          }
          width={setCellWidth(32)}
          cell={({ rowIndex }) => {
            const { patient } = data[rowIndex];
            return <PatientCell patient={patient} />;
          }}
        />
        <Column
          header={<Cell>Status</Cell>}
          width={setCellWidth(17)}
          cell={({ rowIndex }) => {
            const { status, formattedTypes } = data[rowIndex];
            return <StatusCell status={status} tags={formattedTypes} />;
          }}
        />
        <Column
          header={
            <HeaderCell
              vipsStore={vipsStore}
              label="Expiration"
              propName="endDate"
              baseUrl={headerBaseUrl}
              data-cy="vip-expiration--header"
            />
          }
          cellClassName="no-wrap"
          width={setCellWidth(17)}
          cell={({ rowIndex }) => (
            <DateCell date={data[rowIndex].endDate} timezone={timezone} />
          )}
        />
        <Column
          header={
            <HeaderCell
              vipsStore={vipsStore}
              label="Created"
              propName="createdBy"
              baseUrl={headerBaseUrl}
              data-cy="vip-created-by--header"
            />
          }
          width={setCellWidth(17)}
          cell={({ rowIndex }) => {
            const { created, createdBy } = data[rowIndex];
            return <CreatedByCell date={created} person={createdBy} />;
          }}
        />
        <Column
          header={
            <HeaderCell
              vipsStore={vipsStore}
              label="Modified"
              propName="lastModified"
              baseUrl={headerBaseUrl}
              data-cy="vip-last-modified--header"
            />
          }
          width={setCellWidth(17)}
          cell={({ rowIndex }) => {
            const { lastModified, lastModifiedBy } = data[rowIndex];
            return (
              <ModifiedByCell date={lastModified} person={lastModifiedBy} />
            );
          }}
        />
      </Registry>
    </div>
  );
};

List.propTypes = {
  advancedFiltersVisible: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  showCheckboxes: PropTypes.bool.isRequired,
  vipsStore: PropTypes.shape({
    results: PropTypes.oneOfType([
      PropTypes.shape({}),
      mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    ]),
  }).isRequired,
};

List.defaultProps = {
  showCheckboxes: false,
};

List.displayName = 'List';

export default withRouter(List);
