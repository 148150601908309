import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const NoteByline = ({ note }) => {
  const { lastModified, created, createdBy, lastModifiedBy } = note;
  let edited;
  let name = createdBy.firstName || 'Unknown';
  name += createdBy.lastName ? ` ${createdBy.lastName}` : '';

  if (moment(lastModified).isAfter(created)) {
    let editor = lastModifiedBy.firstName || 'Unknown';
    editor += lastModifiedBy.lastName ? ` ${lastModifiedBy.lastName}` : '';
    edited = `(edited last by ${editor} on ${moment(lastModified).format(
      'lll'
    )})`;
  }

  return (
    <p>
      {name}
      <span className="text-subtle">
        {' '}
        {moment(created).format('lll')} {edited}{' '}
      </span>
    </p>
  );
};

NoteByline.propTypes = {
  note: PropTypes.shape({
    notes: PropTypes.string,
    lastModifiedBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    createdBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    lastModified: PropTypes.string,
    created: PropTypes.string,
  }),
};

NoteByline.defaultProps = {
  note: {},
};

export default NoteByline;
