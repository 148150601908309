import React, { useEffect } from 'react';
import { ItemComponent, ValueComponent } from './dropdownComponents';
import { DropdownList } from 'react-widgets';
import styles from './index.module.scss';
import { PropTypes } from 'prop-types';

const MultiRecordInformationDropdown = ({
  options = [],
  onChange,
  disabledOption = [],
  previouslySelected,
  setPreviouslySelected,
  sessionStorageKey,
}) => {
  const disabled = options.filter(opt => disabledOption.includes(opt.value));

  useEffect(() => {
    if (previouslySelected?.value === disabled[0]?.value) {
      setPreviouslySelected(sessionStorageKey, options[0]?.value);
      onChange(options[0]);
    } else {
      onChange(previouslySelected);
    }
  }, [
    previouslySelected,
    onChange,
    disabled,
    options,
    setPreviouslySelected,
    sessionStorageKey,
  ]);

  return (
    <div className={styles.dropdownWrapper}>
      <DropdownList
        data={options}
        disabled={disabled}
        value={previouslySelected || options[0]}
        onChange={value => {
          onChange(value);
          setPreviouslySelected(sessionStorageKey, value.value);
        }}
        valueComponent={ValueComponent}
        itemComponent={ItemComponent}
      />
    </div>
  );
};

MultiRecordInformationDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func.isRequired,
  disabledOption: PropTypes.arrayOf(PropTypes.string),
  previouslySelected: PropTypes.shape({
    value: PropTypes.string,
  }),
  sessionStorageKey: PropTypes.string,
  information: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  setInformation: PropTypes.func,
  setPreviouslySelected: PropTypes.func,
};

export default MultiRecordInformationDropdown;
