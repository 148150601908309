import React from 'react';
import styles from './index.module.scss';

const Actions = ({ children }) => (
  <div className={styles.floatRight}>{children}</div>
);

Actions.propTypes = {
  children: function({ children }) {
    if (children.length < 1)
      return new Error('At least one action item is required');
  },
};

Actions.defaultProps = {
  children: [],
};

export default Actions;
