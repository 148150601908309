import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Pandy, ScrollPager } from 'common';

import IncidentGroupList from '../../ui/IncidentGroupList/';
import PromptWrapper, { DEFAULT_MESSAGE } from '../../ui/PromptWrapper';
import { Loader, StaggeredList } from '../../ui';
import styles from './index.module.scss';
import { useReactiveRef } from '../../utils/hooks';

const IncidentListing = ({
  hasMore,
  incidents,
  loaded,
  showSlowLoadingPrompt,
  store,
  hasUnsavedNotes,
}) => {
  const [scrollerElement, refForScroller] = useReactiveRef();

  const messageForPrompt = nextLocation => {
    if (nextLocation.pathname.startsWith('/incidents')) {
      return 'Oh no! Unsaved changes you have made will be lost if you update incident filters. Are you sure?';
    } else {
      return DEFAULT_MESSAGE;
    }
  };

  return (
    <div
      className={styles.listing}
      ref={refForScroller}
      data-cy="incident-listing"
    >
      <PromptWrapper when={hasUnsavedNotes} message={messageForPrompt} />
      <ScrollPager.Component elt={scrollerElement} store={store} />
      <Loader loaded={loaded}>
        <Pandy visible={loaded && incidents.length === 0} mood="happy">
          <div className="speechBubble">
            <span>No matches. Sorry!</span>
          </div>
        </Pandy>
        <StaggeredList
          pandyHasMore={hasMore}
          pandyText="Matching Incidents"
          useSmallPandy
          parentNodeRef={scrollerElement}
          data-cy={hasMore ? 'pandy-has-more' : 'pandy-has-no-more'}
        >
          <IncidentGroupList
            fadeIn
            medicationIncidents={incidents}
            showCaseLink
          />
        </StaggeredList>
      </Loader>
      <div className={styles.loadingPandy}>
        <Pandy visible={showSlowLoadingPrompt} mood="sad">
          <div className="speechBubble">
            <span>
              This might take a while. Do you want to narrow your search?
            </span>
          </div>
        </Pandy>
      </div>
    </div>
  );
};

IncidentListing.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  incidents: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  loaded: PropTypes.bool.isRequired,
  store: PropTypes.shape({}).isRequired,
  showSlowLoadingPrompt: PropTypes.bool.isRequired,
  hasUnsavedNotes: PropTypes.bool.isRequired,
};

IncidentListing.defaults = {
  incidents: [],
};

export default IncidentListing;
