import { PRIVACY_PRODUCT, DIVERSION_PRODUCT, GRC_PRODUCT } from 'common';
import { compare } from './compare';

// Resolution descriptions.
export const DIVERSION = 'DIVERSION';
export const FALSE_POSITIVE = 'FALSE_POSITIVE';
export const GOOD_CATCH = 'GOOD_CATCH';
export const POLICY = 'POLICY';
export const PRIVACY = 'PRIVACY';

/**
 * Provide the human-readable version of the given resolutionDescription.
 *
 * @param resolutionDescription {string|null} Resolution description enum value.
 * @return {string} Display name.
 */
export function resolutionDescriptionDisplayName(resolutionDescription) {
  switch (resolutionDescription) {
    case DIVERSION:
      return 'Diversion';
    case FALSE_POSITIVE:
      return 'False Positive';
    case GOOD_CATCH:
      return 'Good Catch';
    case POLICY:
      return 'Policy';
    case PRIVACY:
      return 'Privacy';
    default:
      return 'Unknown';
  }
}

/**
 * Is the provided resolution description valid for the given case type and
 * resolution state?
 *
 * This logic is ported from services. See CaseResolutionDescription.java.
 *
 * @param resolutionDescription {string} A resolution description value.
 * @param type {string} Case type; e.g. 'privacy' or 'diversion'
 * @param resolution {string} Resolution state; e.g. 'VIOLATION' or 'NOT_VIOLATION'
 * @return {boolean}
 */
export function isResolutionDescriptionValidFor(
  resolutionDescription,
  type,
  resolution
) {
  if (!resolutionDescription || !resolution || !type) return false;

  // Not a violation is the same for all case types.
  // Either false positive or good catch is allowed.
  if (resolution === 'NOT_VIOLATION') {
    return (
      resolutionDescription === FALSE_POSITIVE ||
      resolutionDescription === GOOD_CATCH
    );
  }

  // We are dealing with a violation. Policy is allowed regardless of class type.
  if (resolutionDescription === POLICY) return true;

  // Otherwise the class determines which enum is appropriate.
  return (
    (type === 'privacy' && resolutionDescription === PRIVACY) ||
    (type === 'diversion' && resolutionDescription === DIVERSION) ||
    (type === 'grc' && resolutionDescription === PRIVACY)
  );
}

/**
 * Gets a list of resolution description filter options that can be used in a dropdown, filtered
 * by the list of products that a user can access.
 *
 * @param   {String[]}  products  a list of products that the user can access
 */
export function getResolutionDescriptionFilterOptions(products) {
  const resolutionDescriptions = [FALSE_POSITIVE, GOOD_CATCH, POLICY];
  if (products.includes(PRIVACY_PRODUCT) || products.includes(GRC_PRODUCT))
    resolutionDescriptions.push(PRIVACY);
  if (products.includes(DIVERSION_PRODUCT))
    resolutionDescriptions.push(DIVERSION);

  return resolutionDescriptions
    .map(description => ({
      id: description,
      name: resolutionDescriptionDisplayName(description),
    }))
    .sort((a, b) => compare(a.name, b.name));
}

/**
 * Generate display text for the case's current resolution state
 *
 * @param {Object} caseLike - the case Object from which the resolution state is
 *                            being retrieved
 * @return {String}           the display-ready resolution text
 */
export function getResolutionDisplay(caseLike) {
  const { resolution, resolutionDescription } = caseLike;
  return !resolution
    ? 'Unresolved'
    : `${
        resolution === 'VIOLATION' ? 'Violation' : 'Not a Violation'
      } - ${resolutionDescriptionDisplayName(resolutionDescription)}`;
}
