import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './index.module.scss';
import PersonSwitchButton from './PersonSwitchButton';
import { USER_ICON, PATIENT_ICON } from './const';
import {
  PERSON_TYPE_PATIENT,
  PERSON_TYPE_USER,
} from '../../../mrv/stores/const';

const MultiRecordPersonHeader = ({
  person,
  personLink,
  personSwitchLink,
  personType,
}) => {
  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div
          className={classNames(styles.iconWrapper, {
            [styles.patient]: personType === PERSON_TYPE_PATIENT,
            [styles.user]: personType === PERSON_TYPE_USER,
          })}
        >
          <i
            className={classNames('material-icons', {
              [USER_ICON]: personType === PERSON_TYPE_USER,
              [PATIENT_ICON]: personType === PERSON_TYPE_PATIENT,
            })}
          />
        </div>
        <div>
          <h2 className={styles.personName}>
            <Link to={personLink}>{person.fullName}</Link>
          </h2>
        </div>
        <div className={styles.spacer} />
        <PersonSwitchButton mode={personType} link={personSwitchLink} />
      </div>
    </div>
  );
};

MultiRecordPersonHeader.propTypes = {
  person: PropTypes.shape({
    fullName: PropTypes.string,
  }),
  personLink: PropTypes.string,
  personSwitchLink: PropTypes.string,
  personType: PropTypes.oneOf([PERSON_TYPE_USER, PERSON_TYPE_PATIENT])
    .isRequired,
};

export default MultiRecordPersonHeader;
