import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Authenticated, PermissionSets, RouterContainer } from 'common';

import NavBarItem from '../../navbar/NavBarItem';
import TopNavBar from '../../navbar/TopNavBar';
import Tooltip from '../../ui/Tooltip';

import BulkCaseActionsStore from '../../stores/BulkCaseActionsStore';
import SearchStore from '../stores/SearchStore';

/* eslint-disable no-unused-vars */
const IconItemInner = ({
  icon,
  filterProperty,
  filterValue,
  visible,
  innerRef,
  ...props
}) => (
  <NavBarItem {...props} ref={innerRef}>
    {visible && <i className={`material-icons ${icon}`} />}
  </NavBarItem>
);

let IconItem = (props, ref) => <IconItemInner {...props} innerRef={ref} />;

IconItemInner.propTypes = {
  icon: PropTypes.string,
  filterProperty: PropTypes.string,
  filterValue: PropTypes.string,
  visible: PropTypes.bool,
  innerRef: PropTypes.func,
};

IconItem = forwardRef(IconItem);

const SearchViewActionNav = observer(
  class SearchViewActionNav extends React.Component {
    goToBulkActions = e => {
      e.preventDefault(); // don't do any link or button things
      SearchStore.fetchIds().then(r => {
        BulkCaseActionsStore.bootstrapCases(r._embedded.case);
        RouterContainer.go('/bulkCaseActions', {});
      });
    };

    renderCount() {
      const { store, criteria, type } = SearchStore;
      let count = store.totalElements;

      if (store.maxed) count += '+';
      let description = '';

      if (SearchStore.type === 'users') {
        description = <span> Matching EMR User</span>;
      } else if (type === 'patients') {
        description = <span> Matching Patient</span>;
      } else if (type === 'cases') {
        description = <span> Matching Case</span>;
      } else if (type === 'comments') {
        description = <span> Matching Comment</span>;
      } else if (type === 'authorizations') {
        description = <span> Matching Authorization</span>;
      } else {
        description = <span> Search Result</span>;
      }

      const items = [
        <NavBarItem key="result-count">
          <strong>{count}</strong> {description}
          {count === 1 ? '' : 's'} Containing{' '}
          <ins>{SearchStore.getQueryParam(criteria)}</ins>
        </NavBarItem>,
      ];

      return items;
    }

    renderBulkActionsButton() {
      if (SearchStore.type === 'cases') {
        return (
          <Authenticated anyPermission permission={PermissionSets.bulkActions}>
            <Tooltip content="Bulk Actions" placement="bottom">
              <IconItem
                className="icon"
                onClick={this.goToBulkActions}
                icon="icon-done_all"
                visible={SearchStore.store.size !== 0}
              />
            </Tooltip>
          </Authenticated>
        );
      }
    }

    render() {
      return (
        <TopNavBar actionBar>
          {this.renderCount()}
          <li className="pull-right">
            <ul>{this.renderBulkActionsButton()}</ul>
          </li>
        </TopNavBar>
      );
    }
  }
);

SearchViewActionNav.displayName = 'SearchViewActionNav';

export default SearchViewActionNav;
