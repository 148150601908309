// This file contains features that are in development and not yet ready for
// release.
//
// The keys are to be used as constants in application logic.
// The values are for display in the user menu.

export default {
  FAMILY_MEMBER_RELATIONSHIPS: 'Family members'
};

/**
 * A helper function for checking localStorage for a flag. Not as great because
 * it won't update when the flag is toggled via the UI, but a fine last resort.
 *
 * @param flag - A constant from FeatureFlags.js
 * @return {boolean}
 */
export var localStorageHasFlag = function localStorageHasFlag(flag) {
  return !!localStorage.getItem("featureFlag-".concat(flag));
};