import React from 'react';
import { observer } from 'mobx-react';
import CaseStore from '../../stores/CaseStore';
import Incidents from '../Incidents';
import { withRouterAndQuery } from '../../../utils/RouteHelper';

export function mapProps({ CaseStore, query }) {
  return {
    setIncidentFilterParams: CaseStore.setIncidentFilterParams,
    query,
    incidentsStore: CaseStore,
    incidentsForPrintMainOption: CaseStore.incidentsForPrintMainOption,
    caseIncidentsLoading: CaseStore.caseIncidentsLoading,
  };
}

const IncidentsContainer = withRouterAndQuery(
  observer(({ query }) => (
    <Incidents
      {...mapProps({
        CaseStore,
        query,
      })}
    />
  ))
);

export default IncidentsContainer;
