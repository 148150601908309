import { action, decorate } from 'mobx';
import { toast } from 'react-toastify';
import React from 'react';

/**
 * Interface for managing the display of toast notifications.
 */
class NotificationStore {
  /**
   * Add a new notification to the screen. Sets sane defaults (currently sets
   * the toast notification to show up in the lower-left of the screen,
   * overrides the "close" button to use our internal styles, and will set the
   * toast notification's level, if set).
   *
   * @param {Object} opts  - the configuration for the toast notification.
   *                        Accepts all options defined here:
   *                        https://www.npmjs.com/package/react-toastify#toast-type-object
   *
   * @return {Number} the Toast's ID (for use in dismissing the toast notification)
   */
  // Action
  add(opts) {
    const { level, content, ...props } = opts;

    // trying to add a toast that is already active
    if (props.toastId && toast.isActive(props.toastId)) return null;
    if (level && Object.prototype.hasOwnProperty.call(toast, level)) {
      return toast[level](content, props);
    }

    return toast(content, props);
  }

  addSuccess(message) {
    const content = (
      <span>
        <i className="material-icons icon-check_circle" />
        {message}
      </span>
    );

    this.add({ level: 'success', content });
  }

  addError(message) {
    const content = (
      <span>
        <i className="material-icons icon-warning" />
        {message}
      </span>
    );
    this.add({ level: 'warning', content });
  }

  // Action
  dismiss(toastId) {
    if (!toastId) return; // do nothing if toastId is not provided

    toast.dismiss(toastId);
  }

  // Action
  dismissAll() {
    toast.dismiss();
  }
}

decorate(NotificationStore, {
  add: action,
  addSucess: action,
  addError: action,
  dismiss: action,
  dismissAll: action,
});

const store = new NotificationStore();
export { store as NotificationStore };
export default store;
