import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import {
  DOUBLE_EMPLOYEE_PATIENT,
  DOUBLE_PATIENT_EMPLOYEE,
  PERSON_TYPE_PATIENT,
  PERSON_TYPE_USER,
  DOUBLE_EMPLOYEE_COLUMN,
  DOUBLE_PATIENT_COLUMN,
} from '../../stores/const';
import MultiRecordPersonHeaderSectionContainer from '../../MultiRecordPersonHeaderSectionContainer';
import { MultiRecordUserMetadataContainer } from '../../multiRecordPersonMetadata/MultiRecordUserMetadataContainer';
import { MultiRecordPatientMetadataContainer } from '../../multiRecordPersonMetadata/MultiRecordPatientMetadataContainer';
import { DoubleViewContext } from '../../Context';
import { useEphemeralStore } from '../../../utils/hooks';
import { MultiRecordInformationDropdownStore } from '../../stores/MultiRecordInformationDropdownStore';
import DoubleViewEmployeeDropdownContainer from '../../views/double/DoubleViewEmployeeDropdownContainer';
import DoubleViewPatientDropdownContainer from '../../views/double/DoubleViewPatientDropdownContainer';
import DoubleViewEmployeeColumnContainer from '../../views/double/DoubleViewEmployeeColumnContainer';
import DoubleViewPatientColumnContainer from '../../views/double/DoubleViewPatientColumnContainer';
import { MultiRecordEntityColumnStore } from '../../stores/MultiRecordEntityColumnStore';
import MultiRecordViewClient from 'common/src/app/clients/MultiRecordViewClient';
import { VisibleSystemStore } from 'common';
import MultiRecordActivityChartContainer from '../../MultiRecordActivityChartContainer';

const DoubleView = ({ mode }) => {
  const employeeDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: DOUBLE_EMPLOYEE_COLUMN,
      })
  );

  const patientDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: DOUBLE_PATIENT_COLUMN,
      })
  );

  const employeeColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );

  const patientColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );

  const employeeBox = (
    <div className={styles.employeeBox}>
      <MultiRecordPersonHeaderSectionContainer
        personType={PERSON_TYPE_USER}
        forPage={'activity'}
      />
      <MultiRecordUserMetadataContainer />
      <div className={styles.column}>
        <DoubleViewEmployeeDropdownContainer />
        <DoubleViewEmployeeColumnContainer />
      </div>
    </div>
  );
  const patientBox = (
    <div className={styles.patientBox}>
      <MultiRecordPersonHeaderSectionContainer
        personType={PERSON_TYPE_PATIENT}
        forPage={'activity'}
      />
      <MultiRecordPatientMetadataContainer />
      <div className={styles.column}>
        <DoubleViewPatientDropdownContainer />
        <DoubleViewPatientColumnContainer />
      </div>
    </div>
  );

  return (
    <DoubleViewContext.Provider
      value={{
        employeeDropdownStore,
        patientDropdownStore,
        employeeColumnStore,
        patientColumnStore,
      }}
    >
      <div className={styles.doubleLayout}>
        {mode === DOUBLE_EMPLOYEE_PATIENT && (
          <>
            {employeeBox}
            <div className={styles.activityChartBox}>
              <MultiRecordActivityChartContainer />
            </div>
            {patientBox}
          </>
        )}
        {mode === DOUBLE_PATIENT_EMPLOYEE && (
          <>
            {patientBox}
            <div className={styles.activityChartBox}>
              <MultiRecordActivityChartContainer />
            </div>
            {employeeBox}
          </>
        )}
      </div>
    </DoubleViewContext.Provider>
  );
};

DoubleView.propTypes = {
  mode: PropTypes.string,
};

export default DoubleView;
