import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Anchor from '../Anchor';
import { FullName } from 'common';
import { person } from './PropTypes';

const CustodyChangeRow = ({ user, custodyChangeUser, link }) => {
  return (
    <tr className={styles.printTable}>
      <td className={styles.dateColumn}>-</td>
      <td>-</td>
      <td>-</td>
      <td>
        <span>
          <strong>Change in Custody</strong>{' '}
          <FullName person={user} condensed /> to{' '}
          <Anchor tag="a" href={link}>
            <FullName person={custodyChangeUser} condensed />
          </Anchor>
        </span>
      </td>
    </tr>
  );
};

CustodyChangeRow.propTypes = {
  user: person,
  custodyChangeUser: person,
  link: PropTypes.string,
};

CustodyChangeRow.defaultProps = {
  user: {},
  custodyChangeUser: {},
  link: '/',
};

export default CustodyChangeRow;
