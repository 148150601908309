import { computed, decorate, reaction } from 'mobx';

import { HalUtils, SingletonStore, TimeEntryClient } from 'common';
import MetalogStore from '../MetalogStore';
import Store from '../Store';

class TimeEntryStore extends SingletonStore {
  constructor() {
    super();

    reaction(
      () => [this.shouldFetch, this.fetchParams],
      () => this.refresh()
    );
  }

  fetch() {
    if (this.shouldFetch) {
      return TimeEntryClient.getByUsers(...this.fetchParams);
    }
  }

  // Computed
  get shouldFetch() {
    return (
      Store.isUserFocus &&
      Store.timeEntriesVisible &&
      MetalogStore.filteredMetalogs.length > 0
    );
  }

  // Computed
  get fetchParams() {
    return [
      Store.activeUsers.split(),
      Store.fromDate.clone(),
      Store.toDate.clone().add(1, 'days'),
    ];
  }

  // Computed
  get timeEntries() {
    return HalUtils.getData(this.result);
  }
}

decorate(TimeEntryStore, {
  timeEntries: computed,
  shouldFetch: computed,
  fetchParams: computed,
});

export default new TimeEntryStore();
