import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '../../ui';
import moment from 'moment';
import styles from './index.module.scss';

function ModifyOption({ modify }) {
  const [date, setDate] = useState(null);

  const isValid = dateValue => {
    return (
      dateValue === null ||
      moment(dateValue)
        .startOf('day')
        .isSameOrAfter(moment().startOf('day'))
    );
  };

  return (
    <>
      <div>
        Any expired registrations will be renewed. If no expiration date is
        provided, any existing expiration dates will be cleared.
      </div>
      <DatePicker
        className={styles.date}
        onChange={dateValue => {
          if (isValid(dateValue)) setDate(dateValue);
          else setDate(moment());
        }}
        value={date}
        minDate={moment()}
        label="Expiration Date - Optional"
      />
      <button className={styles.fullWidth} onClick={() => modify(date)}>
        Modify Expiration Date
      </button>
    </>
  );
}

ModifyOption.propTypes = {
  modify: PropTypes.func.isRequired,
};

export default ModifyOption;
