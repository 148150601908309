import IncidentsPrintOptions from '../IncidentsPrintOptions';
import { CaseCustomPrintContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';

export function mapProps({ incidentsPrintOptionsStore, caseStore }) {
  return {
    selectedMainOption: incidentsPrintOptionsStore.selectedMainOption,
    incidentIdsToInclude: incidentsPrintOptionsStore.incidentIdsToInclude,
    mainOptions: incidentsPrintOptionsStore.mainOptions,
    incidentIdOptions: incidentsPrintOptionsStore.incidentIdOptions,
    incidentIdLabel: incidentsPrintOptionsStore.incidentIdLabel,
    handleMainOptionOnChange:
      incidentsPrintOptionsStore.handleMainOptionOnChange,
    setIncidentIdsToInclude: incidentsPrintOptionsStore.setIncidentIdsToInclude,
    isCustomizing: incidentsPrintOptionsStore.isCustomizing,
    isSelectingIncidentIds: incidentsPrintOptionsStore.isSelectingIncidentIds,
    filteredIncidentsForPrint:
      incidentsPrintOptionsStore.filteredIncidentsForPrint,
    filteredIncidentsCount: incidentsPrintOptionsStore.filteredIncidentsCount,
    setIncidentPrintOptionsForCaseStore:
      caseStore.setIncidentPrintOptionsForCaseStore,
    incidents: caseStore.medicationIncidents,
    setIncidentsFromCase: incidentsPrintOptionsStore.setIncidentsFromCase,
  };
}

const IncidentPrintOptionsContainer = observerWrappedContainerFactory({
  Context: CaseCustomPrintContext,
  mapProps,
  PresentationalComponent: IncidentsPrintOptions,
});

export default IncidentPrintOptionsContainer;
