import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DateHelpers } from 'common';

import styles from './index.module.scss';

import TimePlusCalendarSelector from '../TimePlusCalendarSelector';
import ExclusionButton from './ExclusionToggle';
import ListItem from './ListItem';

const {
  TIME_PERIOD_VALUES: {
    ALL,
    DAY,
    WEEK,
    MONTH,
    NINETY_DAYS,
    QUARTER,
    YEAR,
    CUSTOM,
  },
} = DateHelpers;

function DateSelector({
  alignDropdownRight,
  dropUp,
  onToggleExclusion,
  value,
  label,
  filters,
  onChangeDropdown,
  onCalendarChange,
  whiteDatePickerBackground,
  'data-cy': dataCy,
}) {
  const isExcluding =
    filters[`${value}Exclude`] && filters[`${value}Range`] !== null;
  const isDisabled = !filters[`${value}Range`];

  return (
    <ul
      className={classnames(styles.excludable, {
        [styles.whiteDatePickerBackground]: whiteDatePickerBackground,
      })}
      data-cy={dataCy}
    >
      <ExclusionButton
        isDisabled={isDisabled}
        isExcluding={isExcluding}
        toggleFilter={onToggleExclusion}
      />
      <TimePlusCalendarSelector
        createdInformation={[
          filters[`${value}After`],
          filters[`${value}Before`],
        ]}
        label={label}
        filterKey={value}
        defaultValue={filters[`${value}Range`]}
        onCalendarChange={onCalendarChange}
        onChangeDropdown={onChangeDropdown}
        hiddenCalendar={filters[`${value}Range`] === CUSTOM}
        options={[ALL, DAY, WEEK, MONTH, NINETY_DAYS, QUARTER, YEAR, CUSTOM]}
        exclusive={isExcluding}
        CustomListItem={ListItem}
        dropUp={dropUp}
        alignDropdownRight={alignDropdownRight}
      />
    </ul>
  );
}

DateSelector.propTypes = {
  alignDropdownRight: PropTypes.bool,
  dropUp: PropTypes.bool,
  onToggleExclusion: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    /* the shape for the contents of the filters needs to follow the pattern of
    `${value}Exclude`
    `${value}Range`
    `${value}After`
    `${value}Before`
     */
  }).isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onCalendarChange: PropTypes.func.isRequired,
  whiteDatePickerBackground: PropTypes.bool,
  'data-cy': PropTypes.string,
};

DateSelector.defaultProps = {
  alignDropdownRight: false,
  dropUp: false,
  whiteDatePickerBackground: false,
};
export default DateSelector;
