import React from 'react';
import PropTypes from 'prop-types';
import formatBytes from '../../../utils/formatBytes';
import { Cell } from 'fixed-data-table-2';
import { NOT_APPLICABLE } from './const';

const FileSizeCell = ({ size }) => {
  return <Cell>{size === undefined ? NOT_APPLICABLE : formatBytes(size)}</Cell>;
};

FileSizeCell.propTypes = {
  size: PropTypes.number,
};

export default FileSizeCell;
