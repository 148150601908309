import React, { useEffect } from 'react';
import { FlagContext } from '../context';

const FlagGrabberInner = ({ flags, setFlags }) => {
  useEffect(() => {
    const lsFlags = Object.keys(localStorage).filter(key =>
      /^featureFlag-/.test(key)
    );
    if (lsFlags.length !== flags.length) setFlags(lsFlags);
  }, [flags, setFlags]);
  return null;
};

const FlagGrabber = () => (
  <FlagContext.Consumer>
    {({ flags, setFlags }) => (
      <FlagGrabberInner flags={flags} setFlags={setFlags} />
    )}
  </FlagContext.Consumer>
);

export default FlagGrabber;
