import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import classnames from 'classnames';
import { Pandy, RegistrySearchStore } from 'common';
import Input from '../../Input';

import ResultsList from '../../ResultsList';

const RegistrySearch = class RegistrySearch extends React.Component {
  static propTypes = {
    sourceStore: PropTypes.shape({
      user: PropTypes.shape({}),
      patient: PropTypes.shape({}),
    }),
  };

  componentWillUnmount() {
    RegistrySearchStore.reset();
  }

  onSubmit(e) {
    if (e) e.preventDefault();

    if (RegistrySearchStore.searchTerm !== null) {
      RegistrySearchStore.setCriteria(RegistrySearchStore.searchTerm);
    }
  }

  render() {
    const searchStore = RegistrySearchStore.store;
    const search = RegistrySearchStore.searchTerm;

    return (
      <section>
        <form onSubmit={this.onSubmit.bind(this)} className="registry__search">
          <ul className="input-group">
            <Input
              autoFocus
              bordered
              className="input-addon"
              onChange={val => (RegistrySearchStore.searchTerm = val)}
              label="Search by Name, MRN, Employee ID, or Email"
              value={search || ''}
              name="authorization-input"
            />
            <li
              className={classnames('input-group--addon', {
                'input-group--admin with-help-text': !!search,
              })}
            >
              <i
                className="material-icons icon-search"
                onClick={this.onSubmit.bind(this)}
              />
              <span className="help-text">Press Return to search</span>
            </li>
          </ul>
        </form>

        <div
          className={classnames('pandy-container', {
            hidden: !RegistrySearchStore.criteria || searchStore.size > 0,
          })}
        >
          <Pandy
            visible={!searchStore.loading && searchStore.size === 0}
            mood="sad"
          >
            <div className="speechBubble">
              <span>No matches. Sorry!</span>
            </div>
          </Pandy>
        </div>

        <div
          className={classnames('registry__search-results', {
            hidden: searchStore.size < 1,
          })}
        >
          <ResultsList store={searchStore} />
        </div>
      </section>
    );
  }
};

export default observer(RegistrySearch);
