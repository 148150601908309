import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  ApplyBodyClassName,
  DocumentTitle,
  Button,
  DatePicker,
} from '../../ui';

import styles from './index.module.scss';

const RADIO_VALS = {
  authOnly: 'Include Login Events Only',
  epcsOnly: 'Include EPCS Events',
  epcsAuth: 'Include Login and EPCS Events',
};

const EPCSView = ({ download, startDate, radioOption, endDate, setValue }) => {
  return (
    <main>
      <ApplyBodyClassName className="epcs_view" />
      <DocumentTitle text="EPCS Report Builder" />
      <div className={styles.container}>
        <div>
          <h2 className={styles.header}>EPCS Report Builder</h2>
          <p>Select a start date, end date, and content options:</p>
        </div>
        <div className={styles.dates}>
          <DatePicker
            label="Start Date"
            onChange={(date, valid) => {
              if (valid) setValue('startDate', moment(date));
            }}
            placeholder="Select a Start Date"
            value={startDate}
            maxDate={endDate || moment()}
          />
          <DatePicker
            label="End Date"
            placeholder="Select an End Date"
            onChange={(date, valid) => {
              if (valid) setValue('endDate', moment(date));
            }}
            value={endDate}
            minDate={startDate}
            maxDate={moment()}
          />
        </div>
        <ul className={styles.radioButtons}>
          {Object.keys(RADIO_VALS).map(key => {
            return (
              <li className="radio" key={key}>
                <input
                  id={key}
                  type="radio"
                  value={key}
                  checked={key === radioOption}
                  onChange={() => setValue('radioOption', key)}
                />
                <label htmlFor={key}>{RADIO_VALS[key]}</label>
              </li>
            );
          })}
        </ul>
        <div className={styles.button}>
          <Button
            type="submit"
            disabled={!startDate || !endDate || !radioOption}
            onClick={download}
            value="Download Report"
          />
        </div>
      </div>
    </main>
  );
};

export default EPCSView;

EPCSView.propTypes = {
  download: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment),
  endDate: PropTypes.instanceOf(moment),
  radioOption: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};
