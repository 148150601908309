import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { CaseViewContext } from '../../../Context';
import MultiRecordInformationDropdown from '../../../../ui/MultiRecordInformationDropdown';
import {
  employeeDropdownMapProps,
  employeeSecondDropdownMapProps,
} from '../../mapProps';

export const CaseViewEmployeeDropdownContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseViewContext,
    mapProps: employeeDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);

export const CaseViewEmployeeDropdownContainerSecond = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseViewContext,
    mapProps: employeeSecondDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);
