import React from 'react';
import PropTypes from 'prop-types';
import { AsidePanel, TimePlusCalendarSelector } from '../../ui';
import { DropdownList } from 'react-widgets';
import cn from 'classnames';
import {
  formatFilterDate,
  handleOnChange,
  hasThisOption,
  mapFilterKeyToParamKey,
  mapKeyToDisplayLabel,
  ADC_KEY,
  DATES_KEY,
  GROUPS_KEY,
  INCIDENT_IDS_KEY,
  MEDICATIONS_KEY,
  PATIENT_FULL_NAMES_KEY,
  STATUS_KEY,
  TYPES_KEY,
  USER_FULL_NAMES_KEY,
} from '../IncidentFilterBar/helpers';
import styles from './index.module.scss';
import DropdownItem from './DropdownItem';
import ExclusionMultiselect from '../../ui/Filters/ExclusionMultiselect';
import ExclusionFreeForm from '../../ui/Filters/ExclusionFreeForm';
import commaSeparatedToArray from '../../utils/commaSeparatedToArray';

const renderComponentFromFilterEntry = (entry, queryCriteria, history) => {
  const [key, options] = entry;
  const isExcluding = queryCriteria.excludeFilters?.includes(
    mapFilterKeyToParamKey(key)
  );
  const onToggleExclusion = val => {
    handleOnChange(key, val, queryCriteria, history.push, !isExcluding);
  };
  const label = mapKeyToDisplayLabel(key);
  const SHARED_FREEFORM_PROPS = {
    key,
    label,
    handleOnChange,
    inputKey: key,
    queryCriteria,
    history,
    isExcluding,
  };
  const SHARED_MULTISELECT_PROPS = {
    key,
    label,
    options,
    textField: 'name',
    isExcluding,
    hideExclusion: false,
  };

  switch (key) {
    case DATES_KEY:
      return (
        <TimePlusCalendarSelector
          className={styles.filterMargin}
          CustomListItem={DropdownItem}
          key={key}
          filterKey="range"
          createdInformation={[
            queryCriteria.rangeAfter,
            queryCriteria.rangeBefore,
          ]}
          label={label}
          options={options}
          defaultValue={queryCriteria.range}
          onCalendarChange={(date, valid, beforeOrAfter) => {
            if (valid) {
              handleOnChange(
                beforeOrAfter,
                formatFilterDate(date),
                queryCriteria,
                history.push
              );
            }
          }}
          onChangeDropdown={val =>
            handleOnChange(key, val, queryCriteria, history.push)
          }
          hiddenCalendar={queryCriteria.range === 'custom'}
          restrictFutureDate
        />
      );
    case USER_FULL_NAMES_KEY:
    case PATIENT_FULL_NAMES_KEY:
    case INCIDENT_IDS_KEY: {
      const value = commaSeparatedToArray(
        queryCriteria[mapFilterKeyToParamKey(key)]
      );
      return (
        <ExclusionFreeForm
          {...SHARED_FREEFORM_PROPS}
          selectedValues={value}
          hideExclusion={true}
          onToggleExclusion={() => onToggleExclusion(value)}
        />
      );
    }
    case MEDICATIONS_KEY: {
      const value = commaSeparatedToArray(
        queryCriteria[mapFilterKeyToParamKey(key)]
      );
      return (
        <ExclusionFreeForm
          {...SHARED_FREEFORM_PROPS}
          selectedValues={value}
          hideExclusion={false}
          value={value}
          onToggleExclusion={() => onToggleExclusion(value)}
        />
      );
    }
    case GROUPS_KEY:
    case TYPES_KEY:
    case ADC_KEY: {
      const value = options.filter(({ value, id }) => {
        let criteria = queryCriteria[mapFilterKeyToParamKey(key)] || [];
        if (typeof criteria === 'string') {
          criteria = criteria.split(',');
        }
        return criteria.includes(key === GROUPS_KEY ? id : value);
      });

      return (
        <ExclusionMultiselect
          {...SHARED_MULTISELECT_PROPS}
          disabled={options.length <= 1}
          value={value}
          onChange={values => {
            handleOnChange(
              key,
              values,
              queryCriteria,
              history.push,
              isExcluding
            );
          }}
          valueField={key === GROUPS_KEY ? 'id' : 'value'}
          onToggleExclusion={() => onToggleExclusion(value)}
        />
      );
    }
    case STATUS_KEY: {
      const value = options.filter(({ value }) => {
        let criteria = queryCriteria[mapFilterKeyToParamKey(key)] || [];
        if (typeof criteria === 'string') {
          criteria = criteria.split(',');
        }
        return criteria.includes(value);
      });

      return (
        options.length > 1 && (
          <ExclusionMultiselect
            {...SHARED_MULTISELECT_PROPS}
            value={value}
            valueField="value"
            onChange={val =>
              handleOnChange(key, val, queryCriteria, history.push, isExcluding)
            }
            onToggleExclusion={() => onToggleExclusion(value)}
          />
        )
      );
    }
    // TODO: Catch-all case, not sure if we need this. If we don't, remove it
    default: {
      console.warn(
        `Unable to map filter entry key ${key}; rendering default dropdown`
      );
      const value = hasThisOption(
        queryCriteria[mapFilterKeyToParamKey(key)],
        options
      ) || {
        label: options[0]?.label,
        value: -1,
        name: null,
      };

      return (
        <DropdownList
          key={key}
          className={styles.filterMargin}
          data={options}
          valueField="value"
          textField="name"
          value={value}
          onChange={val =>
            handleOnChange(key, val, queryCriteria, history.push)
          }
          valueComponent={DropdownItem}
        />
      );
    }
  }
};

const IncidentAdvancedFilters = ({
  isOpen,
  history,
  filters,
  queryCriteria,
  toggleOpen,
}) => {
  return (
    <AsidePanel
      title="Advanced Filters"
      leftAnimation
      isOpen={isOpen}
      onClose={toggleOpen}
    >
      <div className={styles.values}>
        <h4 className={`${styles.subheader} ${styles.filterMargin} `}>
          <i className={cn('material-icons icon-assignment', styles.icon)} />
          Incident Details
        </h4>
        {Object.entries({ ...filters, [INCIDENT_IDS_KEY]: true }).map(entry =>
          renderComponentFromFilterEntry(entry, queryCriteria, history)
        )}
      </div>
    </AsidePanel>
  );
};

IncidentAdvancedFilters.displayName = 'IncidentAdvancedFilters';

IncidentAdvancedFilters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  queryCriteria: PropTypes.shape({
    range: PropTypes.string,
    rangeAfter: PropTypes.string,
    rangeBefore: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  filters: PropTypes.shape({}).isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default IncidentAdvancedFilters;
