import { computed, decorate } from 'mobx';

import { HalUtils, SingletonStore, NoteTemplateClient } from 'common';

class CaseNoteTemplatesStore extends SingletonStore {
  fetch() {
    return NoteTemplateClient.findAll();
  }

  // Computed
  get templates() {
    return HalUtils.getData(this.result);
  }
}

decorate(CaseNoteTemplatesStore, {
  templates: computed,
});

export default new CaseNoteTemplatesStore();
