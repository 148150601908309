import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';

const RecordsCell = ({ records }) => {
  let str = '-';
  if (records >= 0) str = records.toLocaleString();
  return <Cell>{str}</Cell>;
};

RecordsCell.propTypes = {
  records: PropTypes.number,
};

export default RecordsCell;
