import * as d3 from 'd3';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { autorun, computed, decorate } from 'mobx';

import ChartStore from '../../stores/ChartStore';
import MetalogStore from '../../stores/MetalogStore';
import AuthorizationLayer from './AuthorizationLayer';
import PastFutureAccessLayer from './PastFutureAccessLayer';
import UserOnlyRowLayer from '../UserOnlyRowLayer';
import {
  sqSubFilter1,
  sqSubFilter2,
} from '../../options/EventSubFilter/constants';

/**
 * Layer responsible for drawing user/patient rows including the various sub-layers.
 * @extends React.Component
 */
export default class FullRowLayer extends UserOnlyRowLayer {
  constructor({ routerContainer, store }) {
    super();
    this.routerContainer = routerContainer;
    this.store = store;
  }

  componentDidMount() {
    super.componentDidMount();

    this.disposers.push(
      autorun(() => {
        if (MetalogStore.filteredMetalogs && !MetalogStore.loading) {
          const rows = d3.select(this.groupRef.current).selectAll('g.row');
          PastFutureAccessLayer.render(rows.select('g.past-future-accesses'));
          AuthorizationLayer.render(rows.select('g.authorized-accesses'));
        }
      })
    );
  }

  // Computed
  get data() {
    return ChartStore.data;
  }

  clickedRow(keys) {
    const opts = {};
    let newPath = '/activity';

    if (this.store.focus === 'patient') {
      newPath += `/patient/${keys[0]}/employee/${keys[1]}`;
    } else {
      newPath += `/employee/${keys[0]}/patient/${keys[1]}`;
    }

    // pass any visible system options along as well
    const systemOpts = queryString.parse(
      this.routerContainer.history.location.search
    )?.system;

    const params = this.store.getQuery(opts);
    if (systemOpts) {
      params.system = systemOpts;
    }

    const cleanFields = { ...params };

    if (cleanFields.subFilter1) {
      const storageKey = sqSubFilter1.timeSet(cleanFields.subFilter1);
      cleanFields.subFilter1 = storageKey;
    }

    if (cleanFields.subFilter2) {
      const storageKey = sqSubFilter2.timeSet(cleanFields.subFilter2);
      cleanFields.subFilter2 = storageKey;
    }

    this.routerContainer.go(newPath, cleanFields);
  }

  isSelected(data) {
    return data.key === ChartStore.selectedKey;
  }

  transform(rowData) {
    return `translate(0, ${ChartStore.yScale(rowData.key)})`;
  }

  label(rowData) {
    return ChartStore.yLabel(rowData.values);
  }

  transition(rowData, element) {
    element.attr('transform', this.transform(rowData));
  }
}

decorate(FullRowLayer, {
  data: computed,
});

FullRowLayer.propTypes = {
  routerContainer: PropTypes.object,
  store: PropTypes.object,
};
