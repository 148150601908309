import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';
import Tooltip from '../Tooltip';
import { fromCamelCase } from 'common';
/**
 * Renders a CSS table. Also can pass a method for row click event.
 * Make sure to pass an unique ID as that is used for row click event handler
 * Optionally can highlight (make the row text thicker) the last row if needed
 *
 * example:
 * [{
 *   column1: value,
 *   column2: value,
 *   ...,
 *   id: rowId,
 * }, // first row
 * {
 *   column:1: value,
 *   ...,
 * }, // second row
 * ...
 *]
 *
 * @param className<br />
 * @param data <br />
 * @param disableSelect <br />
 * @param highlightLastRow <br />
 * @param noDefaultStyles <br />
 * @param scrollable <br />
 * @param scrollableMaxRows <br />
 * @param selectedRow <br />
 * @param selectRowHandler <br />
 * @param tooltips <br />
 * @returns {*} <br />
 */
const Table = ({
  className = null,
  data = [],
  disableSelect = false,
  highlightLastRow = false,
  noDefaultStyles = false,
  scrollable = false,
  scrollableMaxRows = 0,
  selectedRow = null,
  selectRowHandler,
  tooltips = {},
}) => {
  const selectTableRow = row => {
    if (selectRowHandler) selectRowHandler(row);
  };
  const key = () => Math.random();

  const columns = data.length
    ? Object.keys(data[0]).filter(k => k !== 'id')
    : [];

  const headers = columns.map(column => data[0][column].displayName ?? column);

  const tableRowRef = useRef();
  const [scrollableMaxHeightStyle, setScrollableMaxHeightStyle] = useState({});

  useEffect(() => {
    // If we have a scrollable container, calculate the scrollable max height as the
    // (number of scrollable rows + 1) * rowHeight + (0.5 * rowHeight). The extra row accounts
    // for the table header.
    if (scrollable && tableRowRef && tableRowRef.current) {
      const rowHeight = tableRowRef.current.clientHeight;

      if (rowHeight > 0 && scrollableMaxRows > 0) {
        setScrollableMaxHeightStyle({
          maxHeight: (scrollableMaxRows + 1) * rowHeight + 0.5 * rowHeight,
        });
      }
    }
  }, [scrollable, scrollableMaxRows, tableRowRef]);

  return (
    <div
      id="table-container"
      style={scrollableMaxHeightStyle}
      className={classnames({
        [styles.scrollable]: scrollable,
      })}
    >
      <table
        className={classnames(className, {
          [styles.table]: !noDefaultStyles,
        })}
      >
        <thead>
          <tr>
            {headers.map(h => (
              <th key={key()}>
                <Tooltip content={typeof h === 'string' && tooltips[h]}>
                  <span>{typeof h === 'string' && fromCamelCase(h)}</span>
                </Tooltip>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr
              ref={i === data.length - 1 ? tableRowRef : undefined}
              key={key()}
              onClick={() => (disableSelect ? null : selectTableRow(d.id))}
              className={classnames({
                [styles.selectedRow]: selectedRow === d.id,
                [styles.highlightLastRow]:
                  i === data.length - 1 && highlightLastRow,
              })}
            >
              {columns.map(column => (
                <td key={key()}>{d[column]?.value ?? d[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  disableSelect: PropTypes.bool,
  highlightLastRow: PropTypes.bool,
  noDefaultStyles: PropTypes.bool,
  scrollable: PropTypes.bool,
  scrollableMaxRows: PropTypes.number,
  selectedRow: PropTypes.string,
  selectRowHandler: PropTypes.func,
  tooltips: PropTypes.object,
};

export default Table;
