export const NOT_APPLICABLE = 'N/A';

export const TOOLTIP_CONTENT = {
  name: 'Filename of file delivered',
  source: 'Location of file delivered',
  fileSize: 'Size of file delivered',
  records: 'Number of records found in file',
  pending: 'Awaiting file processing',
  processed: 'File processing completed',
  unprocessed:
    'File was not processed due to incorrect file name, it is not a required file as part of our data specifications, or it is a file that is not currently used for your Protenus implementation.',
  failed: 'File processing completed with problems',
};

export const FILTER_TARGET = ['wikipedia', 'mongo', 'google'];
