import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { Column, Cell } from 'fixed-data-table-2';
import dimensions from 'react-dimensions';

import { HalUtils, RouterContainer, FullName } from 'common';

import { DocumentTitle, Registry } from '../../ui';

const isSorted = (store, propName) => store.sortBy === propName;

/**
 * Creates a formatted Date string in the M/D/YYYY format.
 * @param {String|Date|Moment} d - the date to format; must be either an
 *                                 ISO-friendly datetime string or a Date Object
 * @return {String}              - the formatted date string
 */
const formatDate = d => d && moment(d).format('l');

/**
 * Creates a formatted DateTime string in the M/D/YYYY h:mm:ss A format.
 * @param {String|Date|Moment} d - the date to format; must be either an
 *                                 ISO-friendly datetime string or a Date Object
 * @return {String}              - the formatted date string
 */
const formatDateTime = (d, timezone) =>
  d &&
  moment(d)
    .tz(timezone)
    .format('l LTS z');

/**
 * Change the sort order of the results
 *
 * @param {Object} store - Auth Store or Temp Permission Store
 * @param {String} propName - the column property being used for the sort
 *
 * @returns undefined
 */
const sortBy = (store, propName) => {
  const oldSortBy = store.sortBy,
    oldSortDir = store.sortDir;
  let newSortDir = 'asc';

  if (oldSortBy === propName) {
    newSortDir = oldSortDir === 'desc' ? 'asc' : 'desc'; // swap directions for persistent
  }

  const newSort = [`${propName},${newSortDir}`];

  RouterContainer.go(undefined, {
    sort: newSort,
    user: store.userId,
    patient: store.patientId,
    name: store.fullName,
  });
};

const goTo = (data, title) => {
  if (title === 'Authorized Accesses')
    RouterContainer.go(`/authorizations/${HalUtils.getId(data)}`);
  else RouterContainer.go(`/authorizations/temporary/${HalUtils.getId(data)}`);
};

const HeaderCell = ({ store, label, propName }) => {
  const _isSorted = isSorted(store, propName),
    sortDir = store.sortDir;

  return (
    <Cell
      className={classnames('registry__table-header table-header-sortable', {
        'registry__table-header--active': _isSorted,
      })}
      onClick={() => sortBy(store, propName)}
    >
      {label}
      <i
        className={classnames('material-icons', {
          hidden: !_isSorted,
          'icon-arrow_upward': sortDir === 'asc',
          'icon-arrow_downward': sortDir === 'desc',
        })}
      />
    </Cell>
  );
};

const cellProps = {
  data: PropTypes.shape({
    user: PropTypes.shape({}),
    patient: PropTypes.shape({}),
    start: PropTypes.string,
    requestedEnd: PropTypes.string,
    createdBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    lastModified: PropTypes.string,
    revocationDate: PropTypes.string,
    status: PropTypes.string,
  }),
  title: PropTypes.string,
  store: PropTypes.shape({
    sortDir: PropTypes.string,
  }),
  label: PropTypes.string,
  propName: PropTypes.string,
};

HeaderCell.propTypes = {
  store: PropTypes.shape({
    sortDir: PropTypes.string,
  }),
  label: PropTypes.string,
  propName: PropTypes.string,
};

const UserCell = ({ data, title }) => (
  <Cell onClick={() => goTo(data, title)}>
    <FullName person={data.user} lastNameFirst />
  </Cell>
);

UserCell.propTypes = cellProps;

const PatientCell = ({ data, title }) => (
  <Cell onClick={() => goTo(data, title)}>
    <FullName person={data.patient} lastNameFirst />
  </Cell>
);

PatientCell.propTypes = cellProps;

const StartDateCell = ({ data, title }) => (
  <Cell onClick={() => goTo(data, title)}>{formatDate(data.start)}</Cell>
);

StartDateCell.propTypes = cellProps;

const EndDateCell = ({ data, title }) => (
  <Cell onClick={() => goTo(data, title)}>{formatDate(data.requestedEnd)}</Cell>
);

EndDateCell.propTypes = cellProps;

const CreatedByCell = ({ data, title }) => {
  const { createdBy } = data;
  return (
    <Cell onClick={() => goTo(data, title)}>
      {createdBy.firstName}
      &nbsp;
      {createdBy.lastName}
    </Cell>
  );
};

CreatedByCell.propTypes = cellProps;

const LastModifiedCell = ({ data, title, timezone }) => (
  <Cell onClick={() => goTo(data, title)}>
    {formatDateTime(data.lastModified, timezone)}
  </Cell>
);

LastModifiedCell.propTypes = cellProps;

const StatusCell = ({ data, title }) => {
  let content = null;
  let status = null;

  if (data.status) status = data.status.toLowerCase();

  if (status === 'pending') {
    content = (
      <span>
        <em className="text-warning">Pending</em>
      </span>
    );
  } else if (status === 'revoked') {
    content = (
      <span>
        <em className="text-danger">Revoked</em>
        &nbsp;&ndash;&nbsp;
        {formatDate(data.revocationDate)}
      </span>
    );
  } else if (status === 'expired') {
    content = <em className="text-danger">Expired</em>;
  } else if (data.revocationDate && status === 'active') {
    content = (
      <span>
        <em className="text-danger">Revoking</em>
        &ndash;&nbsp;
        {formatDate(data.revocationDate)}
      </span>
    );
  } else {
    content = <span>Active</span>;
  }

  return <Cell onClick={() => goTo(data, title)}>{content}</Cell>;
};

StatusCell.propTypes = cellProps;

const AuthorizationsList = ({
  updateDimensions,
  containerWidth,
  title,
  store,
  timezone,
}) => {
  useEffect(() => {
    updateDimensions();
  }, [updateDimensions, containerWidth]);

  const setCellWidth = percentage => {
    percentage /= 100;
    return containerWidth * percentage;
  };

  const data = store.results;

  return (
    <>
      <DocumentTitle text={title} />
      <Registry dataCount={store.size} width={containerWidth} title={title}>
        <Column
          header={
            <HeaderCell
              label="Patient Name"
              propName="patientFullName"
              store={store}
            />
          }
          width={setCellWidth(16)}
          cellClassName="no-wrap"
          data={data}
          cell={({ rowIndex }) => (
            <PatientCell data={data[rowIndex]} title={title} />
          )}
        />

        <Column
          header={
            <HeaderCell
              label="EMR User Name"
              propName="userFullName"
              store={store}
            />
          }
          width={setCellWidth(16)}
          cellClassName="no-wrap"
          data={data}
          cell={({ rowIndex }) => (
            <UserCell data={data[rowIndex]} title={title} />
          )}
        />

        <Column
          header={
            <HeaderCell label="Start Date" propName="start" store={store} />
          }
          width={setCellWidth(12)}
          cellClassName="no-wrap"
          data={data}
          cell={({ rowIndex }) => (
            <StartDateCell data={data[rowIndex]} title={title} />
          )}
        />

        <Column
          header={
            <HeaderCell
              label="End Date"
              propName="requestedEnd"
              store={store}
            />
          }
          width={setCellWidth(11)}
          cellClassName="no-wrap"
          data={data}
          cell={({ rowIndex }) => (
            <EndDateCell data={data[rowIndex]} title={title} />
          )}
        />

        <Column
          header={
            <HeaderCell
              label="Status"
              propName="revocationDate"
              store={store}
            />
          }
          width={setCellWidth(16)}
          cellClassName="no-wrap"
          cell={({ rowIndex }) => (
            <StatusCell data={data[rowIndex]} title={title} />
          )}
        />

        <Column
          header={
            <HeaderCell label="Created By" propName="createdBy" store={store} />
          }
          width={setCellWidth(14)}
          cellClassName="no-wrap"
          data={data}
          cell={({ rowIndex }) => (
            <CreatedByCell data={data[rowIndex]} title={title} />
          )}
        />

        <Column
          header={
            <HeaderCell
              label="Last Modified"
              propName="lastModified"
              store={store}
            />
          }
          width={setCellWidth(15)}
          cellClassName="no-wrap"
          data={data}
          cell={({ rowIndex }) => (
            <LastModifiedCell
              data={data[rowIndex]}
              title={title}
              timezone={timezone}
            />
          )}
        />
      </Registry>
    </>
  );
};

const ListWithForwardRef = forwardRef((props, refs) => (
  <AuthorizationsList {...props} innerRef={refs} />
));

AuthorizationsList.propTypes = {
  updateDimensions: PropTypes.func,
  containerWidth: PropTypes.number,
  title: PropTypes.string,
  store: PropTypes.shape({
    size: PropTypes.number,
    results: PropTypes.shape({}),
  }),
  timezone: PropTypes.string,
};

ListWithForwardRef.displayName = 'AuthorizationsListWithForwardRefs';

export default dimensions({
  elementResize: true,
})(ListWithForwardRef);
