import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import classnames from 'classnames';
import { formatCategoryName, formatSuspiciousActivityLink } from 'common';

const CountCell = observer(
  ({ groupSize = 0, category, groupActive, store }) => {
    if (category) {
      const count = store.countCases(
        formatCategoryName(category, groupSize > 1)
      );
      return (
        <Cell
          className={classnames('settings__table-cell', {
            inactive: !groupActive,
          })}
        >
          {count === 0 ? (
            0
          ) : (
            <Link
              to={{
                pathname: 'cases',
                search: $.param(
                  store.routeParams(formatSuspiciousActivityLink(category))
                ),
              }}
            >
              {count}
            </Link>
          )}
        </Cell>
      );
    }
    return null;
  }
);

CountCell.displayName = 'CountCell';

CountCell.propTypes = {
  groupSize: PropTypes.number,
  category: PropTypes.shape({}),
  groupActive: PropTypes.bool,
};

export default CountCell;
