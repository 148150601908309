import React from 'react';
import { AppUserStore } from 'common';
import AppUserSelect from '../AppUserSelect';
import CaseAgesChart from '../CaseAgesChart';
import CaseAgesList from '../CaseAgesList';
import CaseAgesStore from '../stores/CaseAgesStore';
import { observer } from 'mobx-react';

const CaseReportOpenAges = () => (
  <section className="home-sub-section report-ages" data-cy="report-ages">
    <h2>Case Ages</h2>
    <AppUserSelect
      paramName="caseAgesOwner"
      value={CaseAgesStore.owner}
      users={AppUserStore.enabledActiveCaseOwners}
      inverted
    />
    <CaseAgesChart />
    <CaseAgesList />
  </section>
);

export default observer(CaseReportOpenAges);
