import { computed, decorate } from 'mobx';
import {
  AppUserStore,
  AlertCategoryStore,
  CaseClient,
  DateHelpers,
  EnumValueStore,
  GroupStore,
  HalUtils,
  PermissionStore,
  SingletonStore,
} from 'common';
import CaseBundleFilterOptionsStore from '../CaseBundleFilterOptionsStore';
import { getResolutionDescriptionFilterOptions } from '../../utils/resolutionDescriptions';

const timeAsRange = [
  {
    value: 'all',
    label: 'Show All',
  },
  {
    value: 'lessThan7',
    label: 'Less Than 7 Days',
  },
  {
    value: 'lessThan30',
    label: 'Less Than 30 Days',
  },
  {
    value: 'lessThan60',
    label: 'Less Than 60 Days',
  },
  {
    value: 'lessThan90',
    label: 'Less Than 90 Days',
  },
  {
    value: 'moreThan90',
    label: 'More Than 90 Days',
  },
];

class DepartmentSearchStore extends SingletonStore {
  fetch() {
    return CaseClient.getDepartments();
  }

  // Computed
  get options() {
    return this.result;
  }
}

decorate(DepartmentSearchStore, {
  options: computed,
});

class OrganizationSearchStore extends SingletonStore {
  fetch() {
    return CaseClient.getOrganizations();
  }

  // Computed
  get options() {
    if (this.result) return this.result.map(o => ({ id: o, name: o }));
    return null;
  }
}

decorate(OrganizationSearchStore, {
  options: computed,
});

const departmentSearchStore = new DepartmentSearchStore();
const organizationSearchStore = new OrganizationSearchStore();

/**
 * Map the provided Array and add a filterLabel property to each child Object in the Array.
 * @param {Array} arr          - the Array to clone and update
 * @param {String} filterLabel - the label to apply to the children to display alongside the child value
 *
 * @return {Array} a copy of the Array with the updated properties
 */
function applyLabel(arr, filterLabel) {
  return arr.map(d => Object.assign({ filterLabel }, d));
}

class CaseResultsFiltersStore {
  constructor({ alertCategoryStore, groupStore, permissionStore }) {
    this.alertCategoryStore = alertCategoryStore;
    this.groupStore = groupStore;
    this.permissionStore = permissionStore;
    this.caseBundleFilterOptionsStore = new CaseBundleFilterOptionsStore({
      groupStore,
    });
  }

  loadDepartments = () => {
    if (!departmentSearchStore.result) departmentSearchStore.refresh();
  };

  loadOrganizations = () => {
    if (!organizationSearchStore.result) organizationSearchStore.refresh();
  };

  get caseCreatedOptions() {
    return applyLabel(DateHelpers.timePeriods, 'Case Created');
  }

  get caseModifiedOptions() {
    return applyLabel(DateHelpers.timePeriods, 'Case Last Modified');
  }

  get violationDateOptions() {
    return applyLabel(DateHelpers.timePeriods, 'Case Event Dates');
  }

  get resolutionDateOptions() {
    return applyLabel(DateHelpers.timePeriods, 'Resolution Date');
  }

  get timeToResolveOptions() {
    return applyLabel(timeAsRange, 'Time to Resolution');
  }

  get actionsDateOptions() {
    return applyLabel(DateHelpers.timePeriods, 'Actions Taken During');
  }

  get caseOwners() {
    const options = [
      {
        id: 'null',
        name: 'Unassigned',
      },
    ].concat(
      AppUserStore.caseOwners.map(u => ({
        id: HalUtils.getId(u),
        name: `${u.firstName} ${u.lastName}`,
      }))
    );
    // return options;
    return applyLabel(options, 'Case Owner');
  }

  get caseCreators() {
    const options = [
      {
        id: 'null',
        name: 'Protenus',
      },
    ].concat(
      AppUserStore.caseCreators.map(u => ({
        id: HalUtils.getId(u),
        name: `${u.firstName} ${u.lastName}`,
      }))
    );

    return applyLabel(options, 'Case Creator');
  }

  get caseResolvers() {
    const options = [
      {
        id: 'null',
        name: 'Unresolved',
      },
    ].concat(
      AppUserStore.caseResolvers.map(u => ({
        id: HalUtils.getId(u),
        name: `${u.firstName} ${u.lastName}`,
      }))
    );

    return applyLabel(options, 'Resolved By');
  }

  get userTypes() {
    return applyLabel(
      EnumValueStore.allUserTypes.map(type => {
        const id = HalUtils.getId(type);
        return {
          id,
          name: type.name,
          deprecated: type.deprecated,
        };
      }),
      'Role'
    );
  }

  get userTags() {
    return applyLabel(
      EnumValueStore.userTags.map(tag => {
        const id = HalUtils.getId(tag);
        return {
          id,
          name: tag.name,
          deprecated: tag.deprecated,
        };
      }),
      'Tag'
    );
  }

  get caseActions() {
    const options = [
      {
        id: 'null',
        name: 'None',
        deprecated: false,
      },
    ].concat(
      EnumValueStore.caseActions.map(action => {
        const id = HalUtils.getId(action);
        return {
          id,
          name: action.name,
          deprecated: action.deprecated,
        };
      })
    );
    return applyLabel(options, 'Action');
  }

  get caseBundles() {
    return applyLabel(
      this.caseBundleFilterOptionsStore.allBundles.map(bundle => ({
        id: bundle.id,
        name: bundle.name,
      })),
      'Bundle'
    ).sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
      if (a.name.toUpperCase() > b.name.toUpperCase()) return 1;
      return 0;
    });
  }

  get groups() {
    return GroupStore.groups;
  }

  get groupNames() {
    return GroupStore.groupNames;
  }

  get alertCategories() {
    const options = [
      {
        id: 'null',
        name: 'None',
      },
    ].concat(
      this.alertCategoryStore.categories
        .filter(c => this.groupNames.includes(c.group?.name))
        .map(c => {
          let reportName;
          if (c.name.toLowerCase() === 'suspicious activity') {
            if (c.product.toLowerCase() !== 'unknown') {
              reportName = `${c.product} ${c.name}`;
            }
          } else {
            reportName = c.name;
          }
          return {
            id: HalUtils.getId(c),
            name: c.name,
            reportName,
            group: (c.group && c.useGroups && c.group.name) || null,
          };
        })
    );

    return applyLabel(options, 'Case Type');
  }

  /**
   * Names of active alert categories. We change the "Suspicious Activity"
   * names to be prefixed with the product. This matches expectations in
   * services.
   */
  get alertCategoryNames() {
    const uniqueNames = [
      ...new Set(this.alertCategories.map(c => c.reportName).filter(Boolean)),
    ].sort();

    const options = uniqueNames.map(name => ({
      id: name,
      name,
    }));

    return applyLabel(options, 'Case Type');
  }

  get userDepartments() {
    if (departmentSearchStore.options)
      return applyLabel(departmentSearchStore.options, 'Department');
    return null;
  }

  get userOrganizations() {
    if (organizationSearchStore.options)
      return applyLabel(organizationSearchStore.options, 'Organization');
    return null;
  }

  get resolutions() {
    return applyLabel(
      [
        {
          id: 'null',
          name: 'Unresolved',
        },
        {
          id: 'NOT_VIOLATION',
          name: 'Not Violation',
        },
        {
          id: 'VIOLATION',
          name: 'Violation',
        },
      ],
      'Resolution'
    );
  }

  get suspicionScores() {
    const values = [9, 8, 7, 6, 5, 4, 3, 2, 1].map(num => ({
      value: (num * 0.1).toFixed(1).toString(),
      label: `${num * 10} and higher`,
    }));
    return applyLabel(
      [
        {
          value: 'all',
          label: 'All',
        },
        ...values,
      ],
      'Suspicion Score'
    );
  }

  get resolutionDescriptionOptions() {
    return applyLabel(
      getResolutionDescriptionFilterOptions(this.permissionStore.getProducts()),
      'Resolution Description'
    );
  }
}

decorate(CaseResultsFiltersStore, {
  actionsDateOptions: computed,
  alertCategories: computed,
  alertCategoryNames: computed,
  caseActions: computed,
  caseBundles: computed,
  caseCreatedOptions: computed,
  caseCreators: computed,
  caseModifiedOptions: computed,
  caseOwners: computed,
  caseResolvers: computed,
  groups: computed,
  resolutionDateOptions: computed,
  resolutionDescriptionOptions: computed,
  resolutions: computed,
  suspicionScores: computed,
  timeToResolveOptions: computed,
  userDepartments: computed,
  userOrganizations: computed,
  userTags: computed,
  userTypes: computed,
  violationDateOptions: computed,
});

export default new CaseResultsFiltersStore({
  alertCategoryStore: AlertCategoryStore,
  groupStore: GroupStore,
  permissionStore: PermissionStore,
});
