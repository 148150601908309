import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { AppRolesStore } from 'common';
import RolesListView from '../RolesListView';

export function mapProps({ AppRolesStore, history }) {
  return {
    allPermissions: AppRolesStore.allPermissions,
    roles: AppRolesStore.roles,
    editRole: name => history.push(`/settings/roles/${name}`),
    isOpen: AppRolesStore.isFormOpen,
  };
}

const RolesListViewContainer = withRouter(
  observer(({ history }) => (
    <RolesListView
      {...mapProps({
        AppRolesStore,
        history,
      })}
    />
  ))
);

RolesListViewContainer.displayName = 'RolesListViewContainer';

export default RolesListViewContainer;
