import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const Circle = ({ children, color }) => {
  const classes = classnames(styles.circle, styles[color]);

  return <div className={classes}>{children}</div>;
};

Circle.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

Circle.defaultProps = {
  children: null,
  color: 'secondary',
};

export default Circle;
