import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';
import BoxSection from '../../people/PersonBox/BoxSection';
import SideList from '../../ui/SideList';
import ListItem from '../../ui/SideList/ListItem';
import Field from '../../ui/Field';
import Address from '../../people/Address';
import ViaSource from '../../ui/ViaSource';
import ShowHideMore from '../../ui/ShowHideMore';
import { Anchor } from '../../ui';
import { fromCamelCase } from 'common';

export const Other = ({ other = {} }) =>
  Object.entries(other).map((o, i) => {
    const key = o[0],
      value = o[1];
    if (typeof key !== 'string' || typeof value !== 'string') return null;
    if (key.toLowerCase().includes('email')) {
      return (
        <ListItem
          label={fromCamelCase(key)}
          value={<Email email={value} />}
          key={key + i}
        />
      );
    }
    return (
      <ListItem
        label={fromCamelCase(key)}
        value={value.capitalizeFirstLetter()}
        key={key + i}
      />
    );
  });

export const Email = ({ email = '' }) => (
  <Anchor tag={'a'} href={`mailto:${email}`}>
    {email}
  </Anchor>
);

export const Role = ({ role = {} }) => {
  const r = role,
    dateFormat = 'l';

  return (
    <Field label="role" width="auto">
      <p>{r.name}</p>
      <ViaSource source={r.source} />
      <SideList>
        <ListItem
          label="Start Date"
          value={
            r.startDate &&
            moment(r.startDate)
              .utc()
              .format(dateFormat)
          }
        />
        <ListItem
          label="End Date"
          value={
            r.endDate &&
            moment(r.endDate)
              .utc()
              .format(dateFormat)
          }
        />
        {!r.address || !Object.keys(r.address).length ? null : (
          <Address address={r.address} />
        )}
        {r.email && (
          <ListItem label="Work Email" value={<Email email={r.email} />} />
        )}
        {r.phoneNumber && <ListItem label="Work Phone" value={r.phoneNumber} />}
        {r.department && <ListItem label="Department" value={r.department} />}
        {r.organization && (
          <ListItem label="Organization" value={r.organization} />
        )}
        <Other other={r.other} />
      </SideList>
    </Field>
  );
};

const Roles = ({ roles = [] }) => {
  if (!roles.length)
    return (
      <BoxSection>
        <h2>Associated Roles</h2>
        <em className="muted">No Roles</em>
      </BoxSection>
    );

  const rolesList = roles.map((r, i) => <Role role={r} key={r.name + i} />);

  return (
    <BoxSection>
      <h2>
        Associated Roles <span className="text-subtle">{roles.length}</span>
      </h2>
      <ShowHideMore numberToShow={2} label="role">
        {rolesList}
      </ShowHideMore>
    </BoxSection>
  );
};

Email.propTypes = {
  email: PropTypes.string,
};

Other.propTypes = {
  other: PropTypes.shape({}),
};

Role.propTypes = {
  role: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    name: PropTypes.string,
    source: PropTypes.string,
    address: PropTypes.shape({}),
    phoneNumber: PropTypes.string,
  }),
};

Roles.propTypes = {
  roles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
};

export default Roles;
