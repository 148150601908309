import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { EnumValueStore } from 'common';
import EnumsListView from '../EnumsListView';

export function mapProps({ EnumValueStore, match, history }) {
  return {
    allEnums: EnumValueStore.valuesWithoutUsertag,
    selectedEnumValueId: match.params.id,
    onEditEnumValue: id => history.push(`/settings/enums/${id}`),
  };
}

const EnumsListViewContainer = withRouter(
  observer(({ match, history }) => (
    <EnumsListView
      {...mapProps({
        EnumValueStore,
        match,
        history,
      })}
    />
  ))
);

EnumsListViewContainer.displayName = 'EnumsListViewContainer';

export default EnumsListViewContainer;
