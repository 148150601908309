import React from 'react';
import { observer } from 'mobx-react';
import { autorun, reaction } from 'mobx';
import { FlashBannerStore, GroupStore, Pandy, RouterContainer } from 'common';
import Store from '../../stores/BulkCaseActionsStore';
import BulkCaseActions from '../BulkCaseActions';
import Cases from '../Cases';
import { DocumentTitle, Loader, RegistryContainer } from '../../ui';

const CaseList = observer(
  class CaseList extends React.Component {
    constructor() {
      super();
      autorun(() => {
        const { failure } = Store;
        if (failure) FlashBannerStore.add(failure, 'danger');
      });

      reaction(
        () => [GroupStore.groups],
        () => {
          if (GroupStore.groups.length) {
            Store.refreshCases();
          } else {
            Store.setCases(new Map());
          }
        }
      );
    }

    componentDidMount() {
      Store.reset();

      window.onbeforeunload = e => {
        if (Store.performingBulkAction) {
          e.returnValue =
            'Are you sure you want to leave? If you do, your cases may not be updated.';
          return e.returnValue;
        }
      };
    }

    componentWillUnmount() {
      Store.killRequests();
      Store.setParams(null);
    }

    goBack = () => {
      // Return the user to the advanced filter case list view. Do not persist
      // the query params because it's possible for the user to enter an invalid
      // parameter (which is likely how they ended up with no results anyway)
      // which cannot then be removed from the advanced filters.
      RouterContainer.go('/cases', { showFilters: true });
    };

    render() {
      return (
        <div className="bulk-case-actions__registry registry--flex with-aside">
          <DocumentTitle text="Bulk Case Actions" />
          <section className="panel__main">
            <Loader loaded={!Store.loading}>
              <Pandy visible={Store.size === 0 && !Store.loading} mood="happy">
                <div className="speechBubble">
                  <span>
                    No cases found.{' '}
                    <b className="prot-a" onClick={this.goBack}>
                      Please try another search
                    </b>
                    .
                  </span>
                </div>
              </Pandy>
              <RegistryContainer
                disableScrollPager
                store={Store}
                viewContent={false}
                className="registry"
              >
                <Cases rowHeight={50} store={Store} />
              </RegistryContainer>
            </Loader>
          </section>
          <BulkCaseActions store={Store} size={Store.selected.size} />
        </div>
      );
    }
  }
);

CaseList.displayName = 'CaseList';

export default CaseList;
