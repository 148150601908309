import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import 'moment-duration-format';

import {
  LoginStore,
  Authenticated,
  PasswordExpirationBannerStore,
  FlashBannerStore,
  ProtectedRoute,
  SysAlertStore,
  AlertCategoryStore,
  RouterContainer,
} from 'common';
import { NotificationContainer } from './ui';

import AccountView from './account/AccountView';
import ActivityView from './activity/ActivityView';
import AuthRootView from './authorization/AuthRootView';
import BulkCaseActionsView from './bulkCaseActions/BulkCaseActionsView';
import CaseBundlesView from './bundle/CaseBundlesView';
import CaseResultsView from './search/CaseResultsViewContainer';
import CaseRootView from './case/CaseRootView';
import DataFeedRootView from './dataFeed/DataFeedRootView';
import HomeViewContainer from './home/HomeViewContainer';
import Login from './account/Login';
import LostPassword from './account/LostPassword';
import MultifactorSetupView from './account/MultifactorSetupView';
import IncidentViewContainer from './incident/IncidentViewContainer';
import ReportsRootView from './reports/ReportsRootView';
import ResetPassword from './account/ResetPassword';
import SearchView from './search/SearchViewContainer';
import SettingsRootView from './settings/SettingsRootView';
import UserTagsRootView from './userTags/RootView';
import VipRootView from './vip/RootView';
import AuditLogRoot from './auditLog/AuditLogRoot';
import NotificationStore from './stores/NotificationStore';
import FiltersBannerStore from './stores/FiltersBannerStore';
import CaseResultsStore from './search/stores/CaseResultsStore';

import NavBar from './navbar/NavBar';
import SysAlert from './ui/SysAlert';
import FlagGrabber from './ui/FlagGrabber';
import { FlagContext } from './ui/context';
import EPCSViewContainer from './epcs/EPCSViewContainer';
import MultiRecordRootView from './mrv/MultiRecordRootView';

const AlertBanner = observer(() => {
  const handleHideBanner = () => {
    SysAlertStore.dismiss();
  };

  const content = SysAlertStore.content || '';
  let level;
  if (SysAlertStore.level === 'danger') level = 'error';
  else if (SysAlertStore.level === 'secondary') level = 'info';
  else level = SysAlertStore.level;
  if (SysAlertStore.shouldShow) {
    return (
      <div className="toast-container">
        {NotificationStore.add({
          level,
          content,
          autoClose: false,
          onClose: () => handleHideBanner(),
          toastId: 'sysalert',
        })}
      </div>
    );
  }
  return null;
});

const AdvFiltersBanner = observer(() => {
  const handleHideBanner = () => {
    FiltersBannerStore.dismissBanner();
  };

  const onLinkClick = e => {
    e.preventDefault();
    CaseResultsStore.navigateToAdvancedFilters({
      resolution: null,
      owner: LoginStore.id,
    });
  };

  const totalCases = FiltersBannerStore.totalCases;
  const content = (
    <span>
      {' '}
      Wow! Your organization has {totalCases} cases! <br />
      Visit the{' '}
      <Link to="/cases" onClick={onLinkClick}>
        Case View
      </Link>{' '}
      to learn more about Advanced Filters.
    </span>
  );

  if (FiltersBannerStore.shouldShowBanner) {
    return (
      <div className="toast-container">
        {NotificationStore.add({
          type: 'advancedFilters',
          level: 'info',
          position: 'bottom-left',
          content,
          autoClose: false,
          onClose: () => handleHideBanner(),
          toastId: 'advFilter',
        })}
      </div>
    );
  }
  return null;
});

const CaseCreationPausedBanner = observer(() => {
  const handleHideBanner = () => {
    AlertCategoryStore.dismissBanner();
  };

  const content = <span>Automatic case creation is paused</span>;

  if (AlertCategoryStore.shouldShowBanner) {
    return (
      <div className="toast-container">
        {NotificationStore.add({
          type: 'caseCreation',
          level: 'warn',
          position: 'bottom-left',
          content,
          autoClose: false,
          onClose: () => handleHideBanner(),
          toastId: 'creationPaused',
        })}
      </div>
    );
  }
  return null;
});

const FlashBanner = observer(() => {
  const handleHideBanner = () => {
    FlashBannerStore.dismiss();
  };

  const bannerMarkup = () => {
    return { __html: FlashBannerStore.flash?.content };
  };

  let banner = <span />;
  if (FlashBannerStore.any) {
    banner = (
      <SysAlert
        level={FlashBannerStore.flash?.level}
        onHide={handleHideBanner}
        timeout={5000}
      >
        <span dangerouslySetInnerHTML={bannerMarkup()} />
      </SysAlert>
    );
  }
  return banner;
});

// do not make this reactive!!!
export default class Viewport extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.shape(),
    location: PropTypes.shape(),
  };

  toastId = null;

  setFlags = flags => {
    this.setState({ flags });
  };

  state = {
    flags: [],
    setFlags: this.setFlags,
  };

  componentDidMount() {
    RouterContainer.setHistory(this.props.history);
    RouterContainer.setLocation(this.props.location);
  }

  componentDidUpdate() {
    if (LoginStore.loggedIn && !LoginStore.shellUser) {
      if (PasswordExpirationBannerStore.shouldShow) {
        const number = PasswordExpirationBannerStore.daysTillExpiration();

        const content = (
          <span>
            Your password is set to expire in {number} day
            {number > 1 ? 's' : ''}. Please follow the instructions on our{' '}
            <a href="https://help.protenus.com/hc/en-us/articles/360000531507-Reset-Protenus-Password">
              Help Desk
            </a>
            &nbsp;to reset your password.
          </span>
        );

        this.toastId = NotificationStore.add({
          type: 'passwordExpirationBanner',
          level: 'warning',
          content,
          position: 'bottom-left',
          autoClose: false,
          onClose: () => PasswordExpirationBannerStore.dismiss(),
          toastId: 'password',
        });
      }
    }
    RouterContainer.setLocation(this.props.location);
  }

  render() {
    LoginStore.renewCheck();

    // TODO: assign ActivityView to MultiRecordRootView once feature-flag is removed
    const ActivityViewComponent = LoginStore?.user?.roles?.includes('BETA')
      ? MultiRecordRootView
      : ActivityView;

    return (
      <FlagContext.Provider value={this.state}>
        <div id="appContainer">
          <FlagGrabber />
          <Authenticated>
            <NavBar />
            <AlertBanner />
            <FlashBanner />
            <AdvFiltersBanner />
            <CaseCreationPausedBanner />
          </Authenticated>
          {this.props.children}

          <Switch>
            <ProtectedRoute path="/cases" component={CaseResultsView} />
            <ProtectedRoute path="/epcs" component={EPCSViewContainer} />
            <ProtectedRoute path="/authorizations" component={AuthRootView} />
            <ProtectedRoute
              path="/incidents"
              component={IncidentViewContainer}
            />
            <ProtectedRoute path="/reports" component={ReportsRootView} />
            <ProtectedRoute path="/vips" component={VipRootView} />
            <ProtectedRoute path="/user-tags" component={UserTagsRootView} />
            <ProtectedRoute path="/search/:searchType" component={SearchView} />
            <ProtectedRoute path="/case" component={CaseRootView} />
            <ProtectedRoute
              path="/employee/:employeeId/patient/:patientId"
              component={ActivityViewComponent}
            />
            <ProtectedRoute
              path="/patient/:patientId/employee/:employeeId"
              component={ActivityViewComponent}
            />
            <ProtectedRoute
              path="/activity/patient/:patientId/employee/:employeeId"
              component={ActivityViewComponent}
            />
            <ProtectedRoute
              path="/activity/patient/:patientId"
              component={ActivityViewComponent}
            />
            <ProtectedRoute
              path="/activity/employee/:employeeId/patient/:patientId"
              component={ActivityViewComponent}
            />
            <ProtectedRoute
              path="/activity/employee/:employeeId/accesses"
              component={ActivityViewComponent}
            />
            <ProtectedRoute
              path="/activity/employee/:employeeId/incidents"
              component={ActivityViewComponent}
            />
            <ProtectedRoute
              path="/activity/employee/:employeeId"
              component={ActivityViewComponent}
            />
            <Route path="/login/:jwt" component={Login} />
            <Route path="/login" component={Login} />
            <Route
              path="/logout"
              render={() => {
                LoginStore.logout();
                return null;
              }}
            />
            <ProtectedRoute path="/account" component={AccountView} />
            <ProtectedRoute path="/auditLog" component={AuditLogRoot} />
            <ProtectedRoute path="/data-feed" component={DataFeedRootView} />
            <Route path="/mfa" component={MultifactorSetupView} />
            <Route path="/lostPassword" component={LostPassword} />
            <Route path="/resetPassword" component={ResetPassword} />
            <ProtectedRoute path="/settings" component={SettingsRootView} />
            <ProtectedRoute
              path="/bulkCaseActions"
              component={BulkCaseActionsView}
            />
            <ProtectedRoute path="/bundles" component={CaseBundlesView} />
            <ProtectedRoute path="/" component={HomeViewContainer} />
            <Redirect to="/" />
          </Switch>
          <NotificationContainer />
        </div>
      </FlagContext.Provider>
    );
  }
}
