import React from 'react';
import { observer } from 'mobx-react';
import Caseflow from '../Caseflow';
import CaseStore from '../../stores/CaseStore';
import CaseLogStore from '../../stores/CaseLogStore';
import CaseViewStore from '../../stores/CaseViewStore';

export function mapProps(CaseStore, CaseLogStore, CaseViewStore) {
  return {
    /* case actions */
    canModifyActions: CaseStore.canModifyActions,
    deletingAction: CaseStore.deletingAction,
    removeAction: CaseStore.removeAction,
    /* case actions */
    analyticAssessments: CaseStore.analyticAssessments,
    analyticAssessmentLogs: CaseLogStore.analyticAssessmentLogs,
    assessmentsHeadlines: CaseStore.assessmentsHeadlines,
    incidentsByPeriod: CaseStore.incidentsByPeriod,
    linkTo: CaseStore.assessmentLinkTo,
    lastViewedByUser: CaseViewStore.lastViewedByUser,
    unreconciledDrugs: CaseStore.unreconciledDrugs,
    /* case attachments */
    canModifyAttachments: CaseStore.canModifyAttachments,
    deleting: CaseStore.deleting,
    deletingAttachment: CaseStore.deletingAttachment,
    downloadAttachment: CaseStore.downloadAttachment,
    removeAttachment: CaseStore.removeAttachment,
    /* case notes */
    canModifyNotes: CaseStore.canModifyNotes,
    deletingNote: CaseStore.deletingNote,
    draftNotes: CaseStore.draftNotes,
    editNote: CaseStore.editNote,
    editingNote: CaseStore.editingNote,
    removeNote: CaseStore.removeNote,
    /* case resolution */
    canReopen: CaseStore.canReopen,
    reopen: CaseStore.reopen,
    reopeningCaseFromTimeline: CaseStore.reopeningCaseFromTimeline,
    resolveCreatedBy: CaseLogStore.resolveLog.createdBy,
    /* final assessment */
    assessmentLog: CaseLogStore.assessmentLog,
    canModifyAssessment: CaseStore.canModifyAssessment,
    editingAssessment: CaseStore.editingAssessment,
    deletingAssessment: CaseStore.deletingAssessment,
    draftAssessment: CaseStore.draftAssessment,
    /* general case props */
    caseType: CaseStore.type,
    editable: CaseStore.editable,
    kase: CaseStore.case,
    patientId: CaseStore.patientId,
    userId: CaseStore.userId,
    /* case event functions */
    reset: CaseStore.reset,
    setValue: CaseStore.setValue,
    updateCase: CaseStore.updateCase,
    /* RADAR sync/incident event props */
    radarId: CaseStore.externalId,
    radarLink: CaseStore.radarLink,
    syncLogs: CaseLogStore.syncLogs,
    /* Print */
    caseflowFieldsToInclude: CaseStore.caseflowFieldsToInclude,
    selectedCaseflowOption: CaseStore.selectedCaseflowOption,
  };
}

const CaseflowContainer = () => (
  <Caseflow {...mapProps(CaseStore, CaseLogStore, CaseViewStore)} />
);

export default observer(CaseflowContainer);
