import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

/* use of the aside component (left, right position) depends on where the aside panel is placed in the dom. */
const AsidePanel = ({
  title,
  isOpen,
  onClose,
  closeLinkTo,
  className,
  children,
  leftAnimation,
  actionNavHeight,
  disableClose,
  disableAnimation,
  'data-cy': dataCy,
}) => {
  const [hasEverBeenOpened, setHasEverBeenOpened] = useState(false);
  /* the open class will trigger the aside to open when isOpen is set to true.
  The onClose function that is passed in will handle the closing
  leftAnimation determines animation direction NOT aside position
  */

  useEffect(() => {
    if (!hasEverBeenOpened && isOpen) {
      setHasEverBeenOpened(true);
    }
  }, [hasEverBeenOpened, isOpen]);

  // If we have never been opened and we are still not open, do not render
  // anything. This prevents any animation to the closed position when first
  // appearing.
  if (!hasEverBeenOpened && !isOpen) return null;

  const wrapperClasses = classnames(className, {
    [styles.right]: !disableAnimation && !leftAnimation,
    [styles.left]: !disableAnimation && leftAnimation,
    [styles.open]: isOpen,
    [styles.close]: !isOpen,
    [styles.actionNavHeight]: actionNavHeight,
  });

  return (
    <aside
      className={classnames(styles.aside, className, wrapperClasses)}
      data-cy={dataCy}
    >
      <header className={styles.header}>
        <span data-cy="aside-panel-title">{title}</span>
        {!disableClose && (
          <>
            {closeLinkTo ? (
              <Link to={closeLinkTo}>
                <i
                  className="material-icons icon-close"
                  data-cy="aside-panel--close-btn"
                />
              </Link>
            ) : (
              <span onClick={onClose}>
                <i
                  className="material-icons icon-close"
                  data-cy="aside-panel--close-btn"
                />
              </span>
            )}
          </>
        )}
      </header>
      <div className={styles.body}>{children}</div>
    </aside>
  );
};

AsidePanel.propTypes = {
  actionNavHeight: PropTypes.bool,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  leftAnimation: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  closeLinkTo: PropTypes.string,
  children: PropTypes.node.isRequired,
  disableClose: PropTypes.bool,
  disableAnimation: PropTypes.bool,
  'data-cy': PropTypes.string,
};

AsidePanel.defaultProps = {
  actionNavHeight: false,
  className: '',
  isOpen: false,
  leftAnimation: false,
  title: '',
  link: '',
  onClose: () => {},
  closeLinkTo: null,
  disableClose: false,
  disableAnimation: false,
};

export default AsidePanel;
