import { Viewport, RouterContainer } from 'common';

function animatePanel(callback) {
  Viewport.intervalResize(800, callback);
}

class AsideHelpers {
  openPanel() {
    animatePanel();
  }

  closePanel(dest) {
    let callback;

    if (dest) {
      callback = () => {
        RouterContainer.go(dest);
      };
    }

    animatePanel(callback);
  }
}

export default new AsideHelpers();
