import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';
import Checkbox from '../../../ui/Checkbox';
import CaseIntegrationLock from './Lock';
import CaseIntegrationSource from './Source';
import { TABLE_CELL_TYPE } from './const';

const CaseIntegrationTable = ({ data, headers }) => (
  <table className={styles.caseIntegrationTable}>
    {headers && (
      <thead className={styles.caseIntegrationHeader}>
        <tr className={styles.caseIntegrationRow}>
          {headers?.map((header, i) => (
            <th
              className={classnames(styles.bold, styles.caseIntegrationHead, {
                [styles.offsetHeader]: i === 0,
              })}
              key={`caseIntegrationHeader${i}`}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
    )}
    <tbody className={styles.caseIntegrationBody}>
      {data?.map((row, i) => (
        <tr
          key={`caseIntegrationRow${i}`}
          className={styles.caseIntegrationRow}
        >
          {row?.map((cell, j) => (
            <td
              key={`caseIntegrationCell${j}`}
              className={classnames(
                { [styles.bold]: cell?.bold ?? false },
                styles.caseIntegrationData
              )}
            >
              {cell?.type === TABLE_CELL_TYPE.lock ? (
                <CaseIntegrationLock text={cell?.text} />
              ) : cell?.type === TABLE_CELL_TYPE.source ? (
                <CaseIntegrationSource
                  text={cell?.text}
                  arrowBack={cell?.arrowBack}
                />
              ) : cell?.type === TABLE_CELL_TYPE.checkbox ? (
                <div className={styles.caseIntegrationIcon}>
                  <Checkbox
                    checked={cell?.isChecked}
                    tabIndex={0}
                    color={'secondary'}
                    onChange={cell?.onChange}
                  >
                    {cell?.text}
                  </Checkbox>
                </div>
              ) : null}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

CaseIntegrationTable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
};

export default CaseIntegrationTable;
