import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Registry, RegistrySortableHeaderCell } from '../../../ui';
import { Cell, Column } from 'fixed-data-table-2';
import AsideHelpers from '../../../ui/AsidePanel/AsideHelpers';
import UserCell from './cells/UserCell';
import EmailCell from './cells/EmailCell';
import UserStatusCell from './cells/UserStatusCell';
import RolesCell from './cells/RolesCell';
import GroupsCell from './cells/GroupsCell';
import styles from './index.module.scss';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { VIEW_PATH } from '../const';

const CustomerAdministeredAccountsTable = ({
  appUsers,
  createUpdateAsideVisible,
  customerAdministeredAccountsListStore,
  history,
  updateRoute,
}) => {
  const table = useRef(null);
  const tablePadding = 50;
  const [width, setWidth] = useState(100);

  const headerHeight = 60;

  const setSize = useCallback(() => {
    const node = table.current;

    setWidth(node.offsetParent.offsetWidth - tablePadding);
  }, []);

  useEffect(() => {
    createUpdateAsideVisible === true
      ? AsideHelpers.openPanel()
      : AsideHelpers.closePanel();

    window.addEventListener('resize', setSize);

    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [createUpdateAsideVisible, setSize]);

  const setCellWidth = percentage => {
    return width * (percentage / 100);
  };

  const isActive = id => {
    const path = history.location.pathname;
    const pathId = path.substring(path.lastIndexOf('/') + 1);
    return pathId === id;
  };

  return (
    <div
      id="customer-administered-accounts-table"
      ref={table}
      className={styles.table}
    >
      <Registry
        onClick={index =>
          history.push(updateRoute.replace(':id', appUsers[index].id))
        }
        dataCount={appUsers.length || 0}
        headerHeight={headerHeight}
        width={width}
      >
        <Column
          header={
            <RegistrySortableHeaderCell
              baseUrl={VIEW_PATH}
              label="Name"
              propName="lastName"
              secondaryLabel="Username"
              store={customerAdministeredAccountsListStore}
              useSecondaryLabel
            />
          }
          width={setCellWidth(15)}
          cell={({ rowIndex }) => {
            const { id, firstName, lastName, username } = appUsers[rowIndex];

            return (
              <UserCell
                isActive={isActive(id)}
                firstName={firstName}
                lastName={lastName}
                username={username}
              />
            );
          }}
        />

        <Column
          header={
            <RegistrySortableHeaderCell
              store={customerAdministeredAccountsListStore}
              label="Email"
              propName="email"
              baseUrl={VIEW_PATH}
            />
          }
          width={setCellWidth(22)}
          cell={({ rowIndex }) => {
            const { id, email } = appUsers[rowIndex];
            return (
              <EmailCell
                email={email}
                isActive={isActive(id)}
                data-cy="app-users--email-cell--text"
              />
            );
          }}
        />

        <Column
          header={<Cell>Status</Cell>}
          width={setCellWidth(12)}
          cell={({ rowIndex }) => {
            const { id, attemptsLeft, enabled, triage } = appUsers[rowIndex];
            return (
              <UserStatusCell
                attemptsLeft={attemptsLeft}
                enabled={enabled}
                isActive={isActive(id)}
                triage={triage}
              />
            );
          }}
        />

        <Column
          header={<Cell>Role</Cell>}
          width={setCellWidth(20)}
          cell={({ rowIndex }) => {
            const { id, roles } = appUsers[rowIndex];
            return <RolesCell isActive={isActive(id)} roles={roles} />;
          }}
        />

        <Column
          header={<Cell>Groups</Cell>}
          width={setCellWidth(34)}
          cell={({ rowIndex }) => {
            const { id, groups } = appUsers[rowIndex];
            return <GroupsCell isActive={isActive(id)} groups={groups} />;
          }}
        />
      </Registry>
    </div>
  );
};

CustomerAdministeredAccountsTable.propTypes = {
  appUsers: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  createUpdateAsideVisible: PropTypes.bool,
  customerAdministeredAccountsListStore: PropTypes.object,
  history: PropTypes.object,
  updateRoute: PropTypes.string,
};

export default CustomerAdministeredAccountsTable;
