import React from 'react';
import { observer } from 'mobx-react';

import CaseResultsStore from '../stores/CaseResultsStore';
import CaseResultsFiltersStore from '../../stores/CaseResultsFiltersStore';
import TimePeriodFilters from '../TimePeriodFilters';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({
  CaseResultsStore,
  CaseResultsFiltersStore,
  history,
  location,
  query,
}) {
  return {
    history,
    location,
    query,

    queryForToggle: CaseResultsStore.queryForToggle,
    queryForSelectionChange: CaseResultsStore.queryForSelectionChange,

    createdRangeSelection: CaseResultsStore.createdRange,
    createdAfter: CaseResultsStore.createdAfter,
    createdBefore: CaseResultsStore.createdBefore,
    createdExcluded: CaseResultsStore.createdExclude,

    lastModifiedRangeSelection: CaseResultsStore.lastModifiedRange,
    lastModifiedAfter: CaseResultsStore.lastModifiedAfter,
    lastModifiedBefore: CaseResultsStore.lastModifiedBefore,
    lastModifiedExcluded: CaseResultsStore.lastModifiedExclude,

    violationDateRangeSelection: CaseResultsStore.violationDateRange,
    violationDateAfter: CaseResultsStore.violationDateAfter,
    violationDateBefore: CaseResultsStore.violationDateBefore,
    violationDateExcluded: CaseResultsStore.violationDateExclude,

    resolutionDateRangeSelection: CaseResultsStore.resolutionDateRange,
    resolutionDateAfter: CaseResultsStore.resolutionDateAfter,
    resolutionDateBefore: CaseResultsStore.resolutionDateBefore,
    resolutionDateExcluded: CaseResultsStore.resolutionDateExclude,

    timeToResolveOptions: CaseResultsFiltersStore.timeToResolveOptions,
    timeToResolveSelection: CaseResultsStore.timeToResolve,
    timeToResolveExcluded: CaseResultsStore.timeToResolveExclude,
  };
}

const TimePeriodFiltersContainer = observer(({ history, location, query }) => (
  <TimePeriodFilters
    {...mapProps({
      CaseResultsStore,
      CaseResultsFiltersStore,
      history,
      location,
      query,
    })}
  />
));

TimePeriodFiltersContainer.displayName = 'TimePeriodFiltersContainer';

export default withRouterAndQuery(TimePeriodFiltersContainer);
