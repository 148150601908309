import moment from 'moment';

/**
 * Typical group by, but sorts the keys and allows a getter in addition to a raw
 * property.
 *
 * @param objArr {Object[]} Array to group.
 * @param property {string|Function} Property or getter.
 * @return {{}} Keys are property values, values are array elements.
 */
export const groupBy = (objArr, property = 'group') => {
  const data = objArr.reduce((acc, obj) => {
    const key =
      ('function' === typeof property ? property(obj) : obj[property]) ||
      'Uncategorized';

    if (!acc[key]) acc[key] = [];
    acc[key].push(obj);
    return acc;
  }, {});

  return Object.keys(data).reduce(function(acc, key) {
    acc[key] = data[key];
    return acc;
  }, {});
};

export function groupFieldFor(groupByField, { groups } = { groups: [] }) {
  const index = groups.indexOf(groupByField);
  if (index === -1) return '';
  return `group${index > 0 ? index + 1 : ''}`;
}

export function getResolution(key) {
  if (key === 'NOT_VIOLATION') return 'Not Violation';
  if (key === 'VIOLATION') return 'Violation';
  return 'Unresolved';
}

export const getResolutionDescription = key => {
  switch (key) {
    case 'DIVERSION':
      return 'Diversion';
    case 'FALSE_POSITIVE':
      return 'False Positive';
    case 'GOOD_CATCH':
      return 'Good Catch';
    case 'POLICY':
      return 'Policy';
    case 'PRIVACY':
      return 'Privacy';
    default:
      return 'None';
  }
};

export const addTotals = (arr, primaryFieldDisplayName, timeRangeColumns) => {
  const totalsRow = {
    [primaryFieldDisplayName]: 'Total',
    total: arr.reduce((a, { total: b }) => a + b, 0),
  };
  timeRangeColumns.map(
    column =>
      (totalsRow[column] = arr.reduce(
        (a, row) => a + (row[column]?.value ?? row[column]),
        0
      ))
  );

  return [...arr, totalsRow];
};

export const addPercentage = arr => {
  return arr.map((r, i) => {
    r['%'] = ((r.total / arr[arr.length - 1].total) * 100 || 0).toFixed(2);
    r.id = i;
    return r;
  });
};

export function formatInterval(time, interval, includeYear) {
  const format =
    interval === 'year' ? 'M/YY' : includeYear ? 'M/D/YYYY' : 'M/D';

  return moment(time).format(format);
}

export function getEndDate(time, interval, includeYear) {
  const date = moment(time)
    .add(1, interval)
    .subtract(1, 'day');
  return formatInterval(date, interval, includeYear);
}

export function getTimeRange(time, interval, includeYear) {
  const startDate = formatInterval(time, interval, includeYear);
  const endDate = getEndDate(time, interval, includeYear);
  if (interval === 'day') return startDate;
  return `${startDate} - ${endDate}`;
}

export function displayValue(groupField, value) {
  switch (groupField) {
    case 'resolution':
      return getResolution(value);
    case 'resolutionDescription':
      return getResolutionDescription(value);
    default:
      return value || 'Uncategorized';
  }
}

/**
 * Determine the formatting of the dates
 * omit the year display if all of them are in the same year
 *
 * @param data
 * @returns {boolean}
 */
export function showYear(data) {
  return (
    data.reduce((acc, cur) => {
      const year = moment(cur.group).year();
      if (acc.indexOf(year) === -1) acc.push(year);
      return acc;
    }, []).length > 1
  );
}

/**
 * Format date strings based on time window
 *
 * @param data
 * @param window
 * @param format
 * @returns {*}
 */
export function formatDates(data = [], window = 'week', format = 'M/D') {
  return data.map(stats => {
    const date = moment(stats.group).clone();
    const from = date.clone();
    let rangeFormatted;

    if (window === 'day') {
      rangeFormatted = from.format(format);
    } else {
      const to = date
        .add(1, window)
        .subtract(1, 'day')
        .clone();
      rangeFormatted = from.format(format) + ' - ' + to.format(format);
    }
    // adds timeRangeValue for easier sorting
    stats.timeRangeValue = stats.group;
    stats.group = rangeFormatted;
    return stats;
  });
}
