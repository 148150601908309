import React from 'react';
import PropTypes from 'prop-types';
import { ORIGAMI_EXPLANATION } from '../../../stores/CaseIntegrationStore/const';
import Checkbox from '../../../../ui/Checkbox';
import styles from './index.module.scss';
import OrigamiIntegrationTable from '../OrigamiIntegrationTable';

export const OrigamiCaseIntegration = ({ isEnabled, setEnabled }) => {
  return (
    <form className="content other">
      <p>{ORIGAMI_EXPLANATION}</p>
      <div
        className={styles.bottomSpacing}
        data-cy="integration-origami-enable"
      >
        <Checkbox
          checked={isEnabled}
          tabIndex={0}
          color={'secondary'}
          onChange={() => setEnabled(!isEnabled)}
        >
          Enable Integration
        </Checkbox>
      </div>
      <OrigamiIntegrationTable />
    </form>
  );
};

OrigamiCaseIntegration.propTypes = {
  isEnabled: PropTypes.bool,
  setEnabled: PropTypes.func.isRequired,
};

export default OrigamiCaseIntegration;
