import { SingletonStore } from 'common';
import { computed, decorate } from 'mobx';

export const PATH_FILTER_PARAMS = {
  size: 10000,
  sort: 'name,asc',
};

/**
 * Houses dropdown options for path
 *
 */
class DeliveryFilterStore extends SingletonStore {
  constructor({ halUtils, dataFeedClient }) {
    super();

    this.dataFeedClient = dataFeedClient;
    this.halUtils = halUtils;

    this.refresh();
  }

  get groupPrefixes() {
    const res = this.halUtils.getData(this.result);

    const initialOption = this.loading
      ? { value: '', label: 'Loading ...' }
      : { value: '', label: 'Any' };

    // If source contains "ingest/" then we want everything after that. Also trim any trailing slashes, remove duplicates,
    // and remove sources that contain known regexp expressions.
    return [
      initialOption,
      ...res
        .map(r => {
          let option = r.regexPrefix
            .replace(/\/$/, '')
            .replace(/\.\*/, '')
            .replace(/\(\?!-\)/, '');

          option = option.substr(option.lastIndexOf('/') + 1);

          return {
            value: option,
            label: option,
          };
        })
        .filter(
          (item, index, array) =>
            array.findIndex(option => option.value === item.value) === index
        ),
    ];
  }

  fetch() {
    return this.dataFeedClient.statusRuleGroups(PATH_FILTER_PARAMS);
  }
}

decorate(DeliveryFilterStore, {
  groupPrefixes: computed,
});

export { DeliveryFilterStore };
