import React from 'react';
import { AuditLogContext } from '../Context';
import { AuditLogStore } from '../stores/AuditLogStore';
import AuditLogContainer from '../AuditLogContainer';
import { AppAuditClient } from 'common';
import { useEphemeralStore } from '../../utils/hooks';
import AuditLogFilterStore from '../stores/AuditLogFilterStore';

export const AuditLogRoot = () => {
  const auditLogStore = useEphemeralStore(
    () =>
      new AuditLogStore({
        appAuditClient: AppAuditClient,
        auditLogFilterStore: AuditLogFilterStore,
      })
  );

  return (
    <AuditLogContext.Provider
      value={{
        auditLogStore,
        auditLogFilterStore: AuditLogFilterStore,
      }}
    >
      <AuditLogContainer />
    </AuditLogContext.Provider>
  );
};

export default AuditLogRoot;
