import React from 'react';
import PropTypes from 'prop-types';

const CATEGORY_MEDICATIONS = 'Medications';
const CATEGORY_REASONS = 'Reasons';

const DropdownItem = ({ item }) => {
  if (!item) return <span />;
  const { category, name } = item;

  let label;
  if (category === CATEGORY_MEDICATIONS)
    label = <strong>Incidents Involving Medication: </strong>;
  else if (category === CATEGORY_REASONS)
    label = <strong>Incidents of Activity: </strong>;

  return (
    <span>
      {label}
      {name}
    </span>
  );
};

DropdownItem.propTypes = {
  item: PropTypes.shape({
    category: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

DropdownItem.displayName = 'MedicationIncidentDropdownItem';

export default DropdownItem;
