import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import Store from '../stores/Store';
import EventDetailedStore from '../stores/EventDetailedStore';
import Checkbox from '../../ui/Checkbox';

const ChecksCell = ({ rowIndex, toggler, data }) => {
  let visible = true;
  // event has to belong to the focused user (not an aliased access)
  visible = visible && data[rowIndex].class === 'access';
  visible = visible && data[rowIndex].user.id === Store.userIdParam;
  // event needs a patient if it's an access
  visible = visible && Boolean(data[rowIndex].patient);
  if (visible) {
    const checkedRow = EventDetailedStore.flagged._data.has(data[rowIndex].id);

    return (
      <Cell>
        <Checkbox
          checked={checkedRow}
          tooltip="Add to Case"
          onChange={() => toggler(rowIndex)}
        />
      </Cell>
    );
  }
  return null;
};

ChecksCell.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      class: PropTypes.string,
      id: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.string,
      }),
      patient: PropTypes.shape({}),
    })
  ).isRequired,
  rowIndex: PropTypes.number,
  toggler: PropTypes.func.isRequired,
};

ChecksCell.defaultProps = {
  rowIndex: 0,
};

export default ChecksCell;
