import React from 'react';
import { action, computed, decorate, observable, reaction } from 'mobx';

import { AutomatedEmailStore, LoginStore, validateEmail } from 'common';
import moment from 'moment';
import CaseNoteTemplateStore from '../CaseNoteTemplateStore';
import CaseNoteTemplatesStore from '../CaseNoteTemplatesStore';
import EmailTemplateStore from '../EmailTemplateStore';
import EmailTemplatesStore from '../EmailTemplatesStore';
import NotificationStore from '../../../stores/NotificationStore';

import CaseStore from '../CaseStore';
import PatientEncountersStore from '../../../patient/stores/PatientEncountersStore';
import CaseTemplate from '../../CaseTemplate';

function validateEmails(emails) {
  if (emails.length === 0) return false;
  return emails.reduce((acc, elm) => acc && validateEmail(elm), true);
}

function cleanupEmail(email) {
  const emailIdx = email.indexOf(';');
  let e = email;

  if (emailIdx > -1) {
    e = email.replace(';', '');
  }
  return e.replace(/\s/g, '');
}

class TemplateStore {
  constructor() {
    reaction(
      () => [CaseStore.caseId],
      () => {
        this.reset();
      }
    );
    reaction(
      () => [AutomatedEmailStore.cc],
      () => {
        if (AutomatedEmailStore.cc) {
          this.ccEmails = [AutomatedEmailStore.cc];
          this.defaultCC = [AutomatedEmailStore.cc];
        } else {
          this.ccEmails = [];
          this.defaultCC = [];
        }
      }
    );
  }
  // Observable
  modifyingTemplate = false;
  creatingNewTemplate = false;
  textareaValue = null;
  templateTitle = '';
  invalidList = [];
  templateType = 'note'; // note is default
  deletingTemplate = false;
  tagErrors = new Map();
  // email observables
  toEmails = CaseStore.userRoleEmails;
  ccEmails = [AutomatedEmailStore.cc];
  defaultCC = [AutomatedEmailStore.cc];
  emailSubject = '';
  sendError = false;
  totext = '';
  cctext = '';
  sending = false;

  // Computed
  get templates() {
    return this.templatesTypeStore.templates;
  }

  // Computed
  get canModifyTemplates() {
    return this.templateTypeStore.canModifyTemplates;
  }

  // Computed
  get selectedTemplate() {
    return this.templateTypeStore.selected;
  }

  // The next two ARE different! this is the SINGULAR store
  // Computed
  get templateTypeStore() {
    return this.templateType === 'note'
      ? CaseNoteTemplateStore
      : EmailTemplateStore;
  }

  // This is the PLURAL store
  // Computed
  get templatesTypeStore() {
    return this.templateType === 'note'
      ? CaseNoteTemplatesStore
      : EmailTemplatesStore;
  }

  // Computed
  get templateDirty() {
    const s = this.templateTypeStore.template;
    const templateTitle = (this.templateTitle || '').trim();
    const textareaValue = (this.textareaValue || '').trim();
    const emailSubject = (this.emailSubject || '').trim();

    if (s) {
      if (templateTitle.length && s.name !== templateTitle) return true;
      if (textareaValue.length && s.content !== textareaValue) return true;
      if (
        this.templateType === 'email' &&
        emailSubject.length &&
        s.subject !== emailSubject
      )
        return true;
    } else if (templateTitle.length && textareaValue.length) return true;

    return false;
  }

  // Computed
  get template() {
    return this.templateTypeStore.template;
  }

  // Computed
  get validateCCEmails() {
    // unlike the recipients we dont need to have CC emails, so if there are no emails return true
    if (this.ccEmails.length !== 0) return validateEmails(this.ccEmails);
    return true;
  }

  // Computed
  get validateSubject() {
    return Boolean(this.emailSubject && this.emailSubject.trim().length);
  }

  // Computed
  get validateTitle() {
    return Boolean(this.templateTitle && this.templateTitle.trim().length);
  }

  // Computed
  get validateContent() {
    return Boolean(this.textareaValue && this.textareaValue.trim().length);
  }

  // Computed
  get validated() {
    return this.tagErrors.size === 0 && this.invalidList.length === 0;
  }

  // Computed
  get validTags() {
    const { case: caze = {} } = CaseStore;
    let { userSnapshot: uss = {}, patientSnapshot: pss = {} } = caze;
    const {
      minEventDate: minDate = {},
      maxEventDate: maxDate = {},
      number = {},
      owner = {},
    } = caze;
    const {
      futureEncounters: fes = {},
      pastEncounters: pes = {},
    } = PatientEncountersStore;

    // Same as EmployeeBox, assign user/patient obj if user/patient Snapshot doesn't exist
    if (!uss) uss = caze.user || {};
    if (!pss) pss = caze.patient || {};
    const stripTZ = caze?.category?.product === 'GRC';

    // Event Date
    const eventDate =
      minDate && maxDate
        ? CaseTemplate.renderDates(minDate, maxDate, true, stripTZ)
        : undefined;

    // Case category
    const caseCategory = caze.category ? caze.category.name : undefined;

    // Email
    const userEmail =
      uss &&
      uss.roles &&
      uss.roles.filter(r => r.email !== undefined).map(rf => rf.email);

    // Extra formatting & checking
    const patientDOB =
      pss.dateOfBirth &&
      moment(pss.dateOfBirth)
        .utc()
        .format('MM/DD/YYYY');
    const futureEncounterDate =
      fes.length &&
      fes[0].startTime &&
      moment(fes[0].startTime).format('MM/DD/YYYY');
    const futureEncounterDept =
      fes.length && fes[0].department && fes[0].department.name;
    const pastEncounterDate =
      pes.length &&
      pes[0].startTime &&
      moment(pes[0].startTime).format('MM/DD/YYYY');
    const pastEncounterDept =
      pes.length && pes[0].department && pes[0].department.name;

    return {
      '{Case_Event_Date}': eventDate || '{Case_Event_Date}',
      '{Case_Number}': number || '{Case_Number}',
      '{Case_Owner}': owner.fullName || '{Case_Owner}',
      '{Case_Type}': caseCategory || '{Case_Type}',
      '{EMR_User_Dept}': uss.recentDepartment || '{EMR_User_Dept}',
      '{EMR_User_Email}':
        (userEmail && userEmail[userEmail.length - 1]) || '{EMR_User_Email}',
      '{EMR_User_Name}': uss.fullName || '{EMR_User_Name}',
      '{EMR_User_Org}': uss.recentOrganization || '{EMR_User_Org}',
      '{EMR_User_Role}': uss.recentRoleName || '{EMR_User_Role}',
      '{Future_Encounter_Date}':
        futureEncounterDate || '{Future_Encounter_Date}',
      '{Future_Encounter_Dept}':
        futureEncounterDept || '{Future_Encounter_Dept}',
      '{Last_Encounter_Date}': pastEncounterDate || '{Last_Encounter_Date}',
      '{Last_Encounter_Dept}': pastEncounterDept || '{Last_Encounter_Dept}',
      '{Patient_DOB}': patientDOB || '{Patient_DOB}',
      '{Patient_Name}': pss.fullName || '{Patient_Name}',
    };
  }

  // Action
  masterEmailValidate() {
    // an array of bool computeds and the values that need updating
    const values = [
      [validateEmails(this.toEmails), 'toEmail'],
      [this.validateCCEmails, 'ccEmail'],
      [this.validateSubject, 'emailSubject'],
      [this.validateContent, 'textareaValue'],
    ];
    return values.reduce((acc, elm) => {
      // if the computed is false add the string to the invalid list
      if (!elm[0]) this.setInvalidList(elm[1]);
      return acc && elm[0];
    }, true);
  }

  // Action
  onDeleteTemplate = successCallback => {
    this.templateTypeStore
      .delete()
      .then(() => {
        const content = (
          <span>
            <i className="material-icons icon-check_circle" />
            Case template deletion successful
          </span>
        );
        NotificationStore.add({ level: 'success', content });
        this.reset();
        successCallback(true);
      })
      .fail(() => {
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            An error occurred while delete this template. Please check
            connectivity or contact support.
          </span>
        );
        NotificationStore.add({ level: 'warning', content });
        successCallback(false);
      });
  };

  // Action
  onUpdateTemplate = successCallback => {
    const updated = {};
    const { template } = this.templateTypeStore;

    updated.content = template.content;
    updated.name = template.name;

    if (this.templateType === 'email') {
      updated.subject = template.subject;
    }

    if (this.templateTitle !== template.name)
      updated.name = this.templateTitle.trim();

    if (
      this.textareaValue !== template.content &&
      this.validate(this.textareaValue, 'textareaValue', successCallback)
    ) {
      updated.content = this.textareaValue.trim();
    }
    if (
      this.templateType === 'email' &&
      this.emailSubject !== template.subject &&
      this.validate(template.subject, 'emailSubject', successCallback)
    ) {
      updated.subject = this.emailSubject.trim();
    }
    // checking if the template has a title is a safty check to catch any notes they may have made in the past with no title
    if (
      (Object.prototype.hasOwnProperty.call(updated, 'name') &&
        !/[1-9a-zA-z]/.test(updated.name)) ||
      !this.templateTitle
    ) {
      this.setInvalidList('templateTitle', successCallback);
    }

    // only let the update go though if there are no invalid things
    this.validated &&
      this.templateTypeStore.update(updated).then(
        () => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Successfully updated template
            </span>
          );
          NotificationStore.add({ level: 'success', content });
          this.modifyingTemplate = false;
          const updatedValue = this.replaceTagsWithValues(updated);
          this.textareaValue = updatedValue.content;
          if (this.templateType === 'email')
            this.emailSubject = updatedValue.subject;
          successCallback(true);
          this.templateTypeStore.refresh();
          this.templatesTypeStore.refresh();
        },
        () => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              There was a problem updating this template
            </span>
          );
          NotificationStore.add({ level: 'warning', content });
          successCallback(false);
        }
      );
  };

  // Action
  templateStateChange = templateAction => {
    if (templateAction === 'cancel') {
      this.modifyingTemplate = false;
      this.textareaValue = this.replaceTagsWithValues(
        this.templateTypeStore.template
      ).content;
      if (this.templateType === 'email') {
        this.emailSubject = this.replaceTagsWithValues(
          this.templateTypeStore.template
        ).subject;
      }
    }

    if (templateAction === 'modify') {
      this.modifyingTemplate = true;
      this.textareaValue = this.templateTypeStore.template.content;
      this.emailSubject = this.templateTypeStore.template.subject;
    }

    this.templateTitle = this.templateTypeStore.template.name;
    this.removeInvalidList('all');
    this.clearTagErrors();
  };

  // Action
  onTemplateChange = (selected, replaceTags = false) => {
    // replaceTags is used when actually using a template
    if (replaceTags) selected = this.replaceTagsWithValues(selected);
    this.templateTypeStore.selected = selected;
    this.textareaValue = selected.content;
    this.templateTitle = selected.name;
    if (this.templateType === 'email') {
      this.sendError = false;
      this.emailSubject = selected.subject;
    }
    this.removeInvalidList('all');
    this.clearTagErrors();
  };

  // Action
  setInvalidList = (value, callback = () => {}) => {
    this.invalidList.push(value);
    callback(false);
  };

  // Action
  removeInvalidList(value) {
    if (value === 'all') this.invalidList = [];
    this.invalidList = this.invalidList.filter(el => el !== value);
  }

  // Action
  clearTagErrors() {
    if (this.tagErrors.size !== 0) this.tagErrors = new Map();
  }

  // Action
  onChange = (value, key) => {
    // remove any newline characters if editing a subject
    if (key === 'emailSubject') value = value.replace(/\r?\n|\r/g, '');

    this[key] = value;
    this.removeInvalidList(key);
    this.clearTagErrors();
    // keep the user active if they're typing up some complex template text
    LoginStore.renewCheck();
  };

  // Action
  onEmailsChange = (email, addOrRemove, emailType) => {
    this.removeInvalidList(`${emailType}Email`);
    if (addOrRemove === 'add') {
      if (email && email.length !== 0 && cleanupEmail(email) !== '')
        this[`${emailType}Emails`].push(cleanupEmail(email));
      this[`${emailType}text`] = '';
    } else if (addOrRemove.action === 'remove' && addOrRemove.dataItem) {
      const dataItemIdx = this[`${emailType}Emails`].indexOf(
        addOrRemove.dataItem
      );
      this[`${emailType}Emails`].splice(dataItemIdx, 1);
    }
  };

  // Action
  reset = () => {
    this.modifyingTemplate = false;
    this.creatingNewTemplate = false;
    this.textareaValue = null;
    this.templateTitle = '';
    this.removeInvalidList('all');
    this.templateTypeStore.selected = null;
    this.deletingTemplate = false;
    this.templateTypeStore.refresh();
    CaseNoteTemplatesStore.refresh();
    EmailTemplatesStore.refresh();
    this.clearTagErrors();
    if (this.templateType === 'email') {
      this.emailSubject = '';
      this.toEmails = [];
      this.ccEmails = AutomatedEmailStore.cc ? [AutomatedEmailStore.cc] : [];
      this.invalidList = [];
      this.sendError = false;
      this.sending = false;
    }
  };

  // Action
  handleNewTemplate = () => {
    this.creatingNewTemplate = true;
  };

  // Action
  handleCreate = toOrCc => {
    this.onEmailsChange(this[`${toOrCc}text`], 'add', toOrCc);
  };

  // Action
  handleKeyUp = (toOrCc, e) => {
    this.removeInvalidList(`${toOrCc}Email`);
    if (e.keyCode === 188 || e.keyCode === 186 || e.keyCode === 32) {
      this.onEmailsChange(this[`${toOrCc}text`], 'add', toOrCc);
    }
  };

  // Action
  handleSearch = (toOrCc, t) => {
    this[`${toOrCc}text`] = t;
  };

  // Action
  setTemplateType(type) {
    this.templateType = type;
  }

  // Action
  toggleDelete(bool) {
    this.deletingTemplate = bool;
  }

  // can be called with an object or string will replace all tags with the proper values
  replaceTagsWithValues(toFormat) {
    if (!toFormat) return;
    const type = typeof toFormat;
    let formatted = type === 'string' ? toFormat : Object.assign({}, toFormat);

    const replaceFunc = freshKey => {
      for (const key in this.validTags) {
        const regex = new RegExp(key, 'g');
        type === 'string'
          ? (formatted = formatted.replace(regex, this.validTags[key]))
          : (formatted[freshKey] = formatted[freshKey].replace(
              regex,
              this.validTags[key]
            ));
      }
    };

    if (formatted.content) replaceFunc('content');
    if (formatted.subject) replaceFunc('subject');
    return formatted;
  }

  validateTemplateTags(textToCheck) {
    if (!textToCheck) return true;
    // find all the tags in the string
    const foundTagsArray = textToCheck.match(/{.*?}/g) || [];

    // filter though them and check that they are valid
    const filteredTagsArray = foundTagsArray.filter(foundTag => {
      if (!this.validTags[foundTag]) this.tagErrors.set(foundTag, foundTag);
      return this.validTags[foundTag] && foundTag;
    });
    // check to see that we have pulled out any inappropriate tags
    return filteredTagsArray.length === foundTagsArray.length;
  }

  validate(valueToCheck, invalidListTitle, callback) {
    if (!this.validateTemplateTags(valueToCheck)) {
      this.setInvalidList(invalidListTitle, callback);
      return false;
    }
    return true;
  }

  createNewTemplate = callback => {
    this.clearTagErrors();

    const temp = {
      name: this.templateTitle,
      content: (this.textareaValue || '').trim(),
    };

    if (this.templateType === 'email') {
      temp.subject = this.emailSubject;
      this.validate(temp.subject, 'emailSubject', callback);
    }

    if (!/[1-9a-zA-Z]/.test(this.templateTitle))
      return this.setInvalidList('templateTitle', callback);

    this.validate(temp.content, 'textareaValue', callback);
    this.validate(temp.name, 'templateTitle', callback);

    this.validated &&
      this.templateTypeStore.create(temp).then(
        () => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Successfully created a new template
            </span>
          );
          NotificationStore.add({ level: 'success', content });
          this.reset();
          callback(true);
        },
        () => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              There was a problem creating this template
            </span>
          );
          NotificationStore.add({ level: 'warning', content });
          callback(false);
        }
      );
  };

  save = () => {
    if (this.templateType === 'note') {
      CaseStore.addNote({ notes: this.textareaValue.trim() }).then(id => {
        CaseStore.currentAction = null;
        this.reset();
        return id;
      });
    } else if (this.masterEmailValidate()) {
      this.sending = true;
      const email = {
        toEmails: this.toEmails,
        subject: this.emailSubject,
        body: this.textareaValue,
      };

      if (this.ccEmails.length > 0) {
        email.ccEmails = this.ccEmails;
      }

      this.templateTypeStore.sendEmail(email).then(
        id => {
          CaseStore.currentAction = null;
          this.reset();
          CaseStore.refresh();
          return id;
        },
        () => {
          this.sending = false;
          this.sendError = true;
        }
      );
    }
  };
}

decorate(TemplateStore, {
  // Observables
  modifyingTemplate: observable,
  creatingNewTemplate: observable,
  textareaValue: observable,
  templateTitle: observable,
  invalidList: observable,
  templateType: observable,
  deletingTemplate: observable,
  toEmails: observable,
  ccEmails: observable,
  emailSubject: observable,
  sendError: observable,
  tagErrors: observable,
  totext: observable,
  cctext: observable,
  sending: observable,
  defaultCC: observable,
  // Computeds
  templates: computed,
  canModifyTemplates: computed,
  selectedTemplate: computed,
  templateTypeStore: computed,
  templatesTypeStore: computed,
  templateDirty: computed,
  template: computed,
  validateCCEmails: computed,
  validateSubject: computed,
  validateContent: computed,
  validated: computed,
  validTags: computed,
  // Actions
  clearTagErrors: action,
  masterEmailValidate: action,
  onDeleteTemplate: action,
  onUpdateTemplate: action,
  templateStateChange: action,
  onTemplateChange: action,
  setInvalidList: action,
  removeInvalidList: action,
  onChange: action,
  onEmailsChange: action,
  reset: action,
  handleNewTemplate: action,
  handleCreate: action,
  handleKeyUp: action,
  handleSearch: action,
  setTemplateType: action,
  toggleDelete: action,
});

export default new TemplateStore();
