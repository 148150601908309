import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import moment from 'moment';

import {
  AppUserStore,
  RouterContainer,
  GroupStore,
  convertMultiValue,
} from 'common';

import AppUserSelect from '../AppUserSelect';
import CasesReportCountStore from '../stores/CasesReportCountStore';

/**
 * Component to render the details of cases that have been recently closed.
 * @extends React.Component
 */
const CaseReportsClosed = observer(
  class CaseReportsClosed extends React.Component {
    linkParameters() {
      const params = {};
      params.showFilters = true;
      if (CasesReportCountStore.closedOwner !== null)
        params.owner = CasesReportCountStore.closedOwner;
      if (CasesReportCountStore.range !== null) {
        params.resolutionDateRange = CasesReportCountStore.range;
        if (CasesReportCountStore.range === 'all') {
          // for most scenarios, a date range of all == no date filter at all
          // however we want to capture when this date field is set so we need to set a really wide range
          params.resolutionDateRange = 'custom';
          params.resolutionDateAfter = moment(0);
          params.resolutionDateBefore = moment();
        } else if (CasesReportCountStore.range === 'custom') {
          const range = CasesReportCountStore.getRange();
          params.resolutionDateAfter = range[0];
          params.resolutionDateBefore = range[1];
        }
      }

      CasesReportCountStore.group
        ? (params.group = convertMultiValue(CasesReportCountStore.group))
        : (params.group = convertMultiValue(GroupStore.groupIds));

      return params;
    }

    /**
     * Create a DOM node that holds the value of the total number of cases closed
     * for the given time period.
     * @return {Object} a React DOM Object
     */
    renderTotal() {
      let count = 0;

      if (
        CasesReportCountStore.closedCases &&
        CasesReportCountStore.closedCases.length
      ) {
        count = CasesReportCountStore.closedCases
          .filter(d => d.group)
          .map(d => d.count)
          .reduce((a, b) => a + b, 0);
      }

      return (
        <li className="column">
          <span>Total Closed</span>
          <span
            onClick={() => RouterContainer.go(`/cases`, this.linkParameters())}
            className={classnames('data__count prot-a', {
              shrinkText: count > 999,
            })}
          >
            {count}
          </span>
        </li>
      );
    }

    /**
     * Create a DOM node that holds the total number of cases that were resolved
     * as Violations for the given time period.
     * @return {Object} a React DOM Object
     */
    renderViolations() {
      let count = 0;

      if (CasesReportCountStore.closedCases) {
        const violations = CasesReportCountStore.closedCases.find(
          c => c.group && c.group.toLowerCase() === 'violation'
        );
        if (violations) count = violations.count;
      }

      const params = this.linkParameters();

      params.resolution = 'VIOLATION';

      return (
        <li className="column">
          <span>Violations</span>
          <span
            onClick={() => RouterContainer.go('/cases', params)}
            className={classnames('data__count prot-a', {
              shrinkText: count > 999,
            })}
          >
            {count}
          </span>
        </li>
      );
    }

    /**
     * Render the component to the DOM.
     * @return {Object} React DOM Object
     */
    render() {
      return (
        <section
          className="home-sub-section home__cases-closed"
          data-cy="cases-closed"
        >
          <h2>Closed Cases</h2>
          <AppUserSelect
            paramName="closedOwner"
            value={CasesReportCountStore.closedOwner}
            users={AppUserStore.enabledActiveCaseOwners}
            inverted
            dropUp
          />
          <ul className="columns data">
            {this.renderTotal()}
            {this.renderViolations()}
          </ul>
        </section>
      );
    }
  }
);

CaseReportsClosed.displayName = 'CaseReportsClosed';

export default CaseReportsClosed;
