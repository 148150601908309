import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CaseStore from '../../stores/CaseStore';
import CaseIncidentsFilterBar from '../CaseIncidentsFilterBar';

export function mapProps({ CaseStore, history }) {
  return {
    incidentFilterParams: CaseStore.incidentFilterParams,
    incidentRangeFilterOptions: CaseStore.incidentRangeFilterOptions,
    incidentStatusFilterOptions: CaseStore.incidentStatusFilterOptions,
    incidentTypeFilterOptions: CaseStore.incidentTypeFilterOptions,
    caseId: CaseStore.caseId,
    history,
  };
}

const CaseIncidentsFilterBarContainer = withRouter(
  observer(({ history }) => (
    <CaseIncidentsFilterBar
      {...mapProps({
        CaseStore,
        history,
      })}
    />
  ))
);

CaseIncidentsFilterBarContainer.displayName = 'CaseIncidentsFilterBarContainer';

export default CaseIncidentsFilterBarContainer;
