import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import { Source } from '../../ui';

const SourceCell = ({ data }) => {
  if (data.source) {
    return (
      <Cell>
        <Source
          source={data.source}
          instance={data.instance}
          environment={data.environment}
          className="float-none"
        />
      </Cell>
    );
  }

  return null;
};

SourceCell.propTypes = {
  data: PropTypes.shape({
    source: PropTypes.string,
    instance: PropTypes.string,
    environment: PropTypes.string,
  }).isRequired,
};

export default SourceCell;
