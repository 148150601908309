import React from 'react';
import { computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import moment from 'moment';

import { DropdownList } from 'react-widgets';

import { EnumValueStore, HalUtils } from 'common';
import BulkCaseActionFormActions from '../BulkCaseActionFormActions';
import { DatePicker, MultilineInput } from '../../index';
import ActionPropTypes from '../PropTypes';

const AddCaseAction = observer(
  class AddCaseAction extends React.Component {
    static propTypes = {
      store: ActionPropTypes.store.isRequired,
    };

    // Observables
    startDate = moment();
    action = null;
    valid = true;
    notes = '';
    dirty = false;

    // Computed
    get caseActions() {
      return EnumValueStore.caseActions
        .filter(r => !r.deprecated)
        .map(r => {
          const id = HalUtils.getId(r);
          return {
            id,
            name: r.name,
          };
        });
    }

    // Computed
    get selectedAction() {
      let selected = {
        id: null,
        name: 'Select an action',
      };

      if (this.action !== null) {
        selected = this.caseActions.find(action => action.id === this.action);
      }

      return selected;
    }

    // Computed
    get disableDateInput() {
      const { store } = this.props;
      const { performingBulkAction } = store;

      return performingBulkAction || (this.dirty ? null : 'true');
    }

    /**
     * Handle the action value change. This is the onChange callback handler
     * for the dropdown menu.
     * @param {String} val    - the new value being set
     * @return {undefined}
     */
    onActionChange = val => {
      this.action = val.id;
      this.dirty = true;
    };

    onDateChange = (date, valid) => {
      this.valid = valid;
      if (valid) this.startDate = moment(date);
    };

    onNotesChange = value => {
      this.notes = value;
    };

    /**
     * Set the case owner to a new value. This is the onClick handler for
     * this form. Pulls the data from the form and calls bulk action method from
     * the associated store.
     * @param {Object} e - the Event Object
     * @return {undefined}
     */
    onSubmit = e => {
      e.preventDefault();
      const { store } = this.props;

      if (this.valid) {
        store.performBulkAction('addAction', {
          type: this.action,
          date: this.startDate.toISOString(),
          notes: this.notes,
        });
      }
    };

    render() {
      const { store } = this.props;
      const { performingBulkAction } = store;

      return (
        <form onSubmit={this.onSubmit} className="tools form">
          <ul>
            <li>
              <DropdownList
                data={this.caseActions}
                value={this.selectedAction}
                valueField="id"
                onChange={this.onActionChange}
                readOnly={performingBulkAction}
                textField="name"
              />
            </li>
            <li>
              <div
                className={classnames('tool tools__date input-container', {
                  hidden: !this.dirty,
                })}
              >
                <DatePicker
                  onChange={this.onDateChange}
                  value={this.startDate}
                  maxDate={new Date()}
                  label="Action Date"
                />
              </div>
            </li>
            <li>
              <div
                className={classnames('input-section', {
                  hidden: !this.action,
                })}
              >
                <MultilineInput
                  label="Include a note (optional)"
                  onChange={this.onNotesChange}
                  value={this.notes}
                  bordered
                />
              </div>
            </li>
            <BulkCaseActionFormActions
              store={store}
              buttonActionType="addAction"
              hidden={!this.dirty}
              buttonDisabled={!this.valid}
              helpText="This Action will be added to all selected cases and cannot be undone."
              isList
            />
          </ul>
        </form>
      );
    }
  }
);

decorate(AddCaseAction, {
  startDate: observable,
  action: observable,
  valid: observable,
  notes: observable,
  dirty: observable,
  caseActions: computed,
  selectedAction: computed,
  disableDateInput: computed,
});

export default AddCaseAction;
