import React from 'react';
import moment from 'moment';
import { constructResolutionLogText } from '../../case/stores/CaseLogStore';
import CasePrintLogValue from '../../case/print/CasePrintLogValue';

/**
 * Component to render the value of an activity item.
 * @return {Object} A React DOM Object
 */
const LogValue = ({ log, strong }) => {
  const parameters = { ...log.parameters };
  const action = log.action;
  // exclude the moreLikeThis property from the display since it's not something that gets rendered to the screen
  const keys = ((parameters && Object.keys(parameters).slice()) || []).filter(
    k => k !== 'moreLikeThis'
  );

  let node = null;

  if (action === 'RESOLVE') {
    const logText = constructResolutionLogText({ ...parameters });
    if (logText) parameters.resolution[0] = parameters.resolution[0] + logText;
  }

  if (action === 'EXTERNAL_SYNC') {
    const { initialSynchronization } = parameters;

    // detect whether it's the first time
    if (initialSynchronization[0] === 'true')
      log.description = log.description.replace('synchronized', 'created');

    // swap names 1234:Radar -> Radar:1234
    const temp = keys[0];
    keys[0] = keys[1];
    keys[1] = temp;
  }

  if (action === 'PRINT')
    node = <CasePrintLogValue printOptions={parameters} />;

  if (parameters && parameters.dateRange) {
    // convert string dates into formatted dates
    parameters.dateRange = [
      parameters.dateRange
        .map(string => {
          const d = moment(string, moment.ISO_8601);

          return d.isValid() ? d.format('l') : string;
        })
        .join(' - '),
    ];
  }

  if (parameters && keys.length !== 0 && action !== 'PRINT') {
    if (strong) {
      if (keys[0] === 'resolution' && parameters[keys[0]]) {
        node = (
          <span>
            <strong>{parameters[keys[0]]}</strong>
          </span>
        );
      } else if (parameters[keys[1]] && parameters[keys[1]][0]) {
        node = (
          <span>
            <strong>
              {parameters[keys[1]][0]}: {parameters[keys[0]]}
            </strong>
          </span>
        );
      } else node = <strong>{parameters[keys[0]]}</strong>;
    } else {
      if (keys[0] === 'resolution' && parameters[keys[0]]) {
        node = <span>{parameters[keys[0]]}</span>;
      } else if (keys[1] && parameters[keys[1]][0]) {
        node = (
          <span>
            <strong>{parameters[keys[1]][0]}</strong>: {parameters[keys[0]]}
          </span>
        );
      } else {
        node = <span>{parameters[keys[0]]}</span>;
      }
    }
  }

  if (Array.isArray(log) && log.length > 1) node = <span>multiple times</span>;

  return node;
};

export default LogValue;
