import React from 'react';
import moment from 'moment';
import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  reaction,
} from 'mobx';
import { CaseClient, HalUtils, RouterContainer, SingletonStore } from 'common';
import NotificationStore from '../../../stores/NotificationStore';
import CaseUtils from '../../../utils/CaseUtils';

class GRCCaseStore extends SingletonStore {
  constructor() {
    super();

    autorun(() => {
      this.setValue('title', this.caseTitle);
      /* defaults to current day if caseStartDate/caseEndDate values are undefined */
      this.setValue('startDate', moment(this.caseStartDate).startOf('day'));
      this.setValue('endDate', moment(this.caseEndDate).endOf('day'));
    });

    reaction(
      () => [this.caseId],
      () => {
        this.refresh();
      }
    );
  }

  // Observables
  caseId = null;
  category = null;
  startDate = moment().startOf('day');
  endDate = moment().endOf('day');
  submitting = false;
  title = null;

  // Action
  setValue = (key, value) => {
    this[key] = value;
  };

  // computed
  get case() {
    return this.result;
  }

  // Computed
  get caseType() {
    return this.case && CaseUtils.whichCaseType(this.case);
  }

  // Computed
  get caseResolution() {
    return this.case && this.case.resolution;
  }

  // computed
  get caseTitle() {
    return this.case && this.case.title;
  }

  // computed
  get caseStartDate() {
    return (this.case && this.case.minEventDate) || undefined;
  }

  // computed
  get caseEndDate() {
    return (this.case && this.case.maxEventDate) || undefined;
  }

  // computed
  get titleValid() {
    if (!this.title || this.title.length < 1) return false;
    const regex = /[0-9a-zA-Z]/;
    return regex.test(this.title);
  }

  // Computed
  get startDateValid() {
    return (
      !!this.startDate && this.startDate.isBefore(this.endDate || moment())
    );
  }

  // Computed
  get endDateValid() {
    return !!this.endDate && this.endDate.isAfter(this.startDate);
  }

  // Computed
  get categoryValid() {
    return !!this.category;
  }

  // Computed
  get editDirty() {
    return (
      (this.title && this.title.trim() !== this.caseTitle) ||
      !moment(this.startDate).isSame(this.caseStartDate, 'day') ||
      !moment(this.endDate).isSame(this.caseEndDate, 'day')
    );
  }

  // Computed
  get canSubmit() {
    // checks if each value is valid
    const values = [
      this.titleValid,
      this.startDateValid,
      this.endDateValid,
      this.categoryValid,
    ];
    return values.every(val => val === true);
  }

  get canSubmitEdit() {
    const values = [
      this.titleValid,
      this.startDateValid,
      this.endDateValid,
      this.editDirty,
    ];
    return values.every(val => val === true);
  }

  // strip timezone on endDate when creating/updating GRC case
  get formattedEndDate() {
    return moment(this.endDate).format('YYYY-MM-DDTHH:mm:ss');
  }

  // Action
  fetch() {
    if (this.caseId) {
      return CaseClient.get(this.caseId);
    }
  }

  updateCase = () => {
    if (this.canSubmitEdit) {
      this.submitting = true;
      const updated = {
        title: this.title.trim(),
        minEventDate: this.startDate,
        maxEventDate: this.formattedEndDate,
      };

      const href = HalUtils.getSelf(this.case);

      return CaseClient.update(href, updated, true)
        .then(() => {
          this.submitting = false;
          RouterContainer.go(`/case/${this.caseId}`);
        })
        .catch(() => {
          this.submitting = false;
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              Oh No! There was an error while editing this case. Please try
              again. If this error persists, contact support.
            </span>
          );

          NotificationStore.add({ level: 'warning', content });
        });
    }
  };

  createCase = () => {
    // The user should not be able to click the create button if
    // we aren't in a valid state, but just double checking
    if (this.canSubmit) {
      const newCase = {
        title: this.title,
        minEventDate: this.startDate,
        maxEventDate: this.formattedEndDate,
        category: HalUtils.getSelf(this.category),
        _class: 'GRCCase',
      };

      this.submitting = true;
      CaseClient.create(newCase)
        .then(id => {
          this.submitting = false;
          RouterContainer.go(`/case/${id}`);
        })
        .catch(() => {
          this.submitting = false;
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              Oh No! There was an error while creating this case. Please try
              again. If this error persists, contact support
            </span>
          );

          NotificationStore.add({ level: 'warning', content });
        });
    }
  };

  reset = () => {
    this.category = null;
    this.caseId = null;
    this.startDate = moment().startOf('day');
    this.endDate = moment().endOf('day');
    this.submitting = false;
    this.title = null;
  };
}

decorate(GRCCaseStore, {
  case: computed,
  caseId: observable,
  caseResolution: computed,
  caseType: computed,
  category: observable,
  categoryValid: computed,
  caseTitle: computed,
  caseStartDate: computed,
  caseEndDate: computed,
  canSubmit: computed,
  canSubmitEdit: computed,
  editDirty: computed,
  endDate: observable,
  endDateValid: computed,
  formattedEndDate: computed,
  startDate: observable,
  startDateValid: computed,
  submitting: observable,
  setValue: action,
  title: observable,
  titleValid: computed,
});

export default new GRCCaseStore();
