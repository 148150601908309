import React from 'react';
import { observer } from 'mobx-react';
import CaseStore from '../../stores/CaseStore';
import CaseViewStore from '../../stores/CaseViewStore';
import CaseLogStore from '../../stores/CaseLogStore';
import AssessmentsForCaseType from '../AssessmentsForCaseType';

export function mapProps({ CaseStore, CaseViewStore, CaseLogStore }) {
  return {
    // common props
    type: CaseStore.type,
    analyticAssessments: CaseStore.analyticAssessments,
    analyticAssessmentLogs: CaseLogStore.analyticAssessmentLogs,
    assessmentsHeadlines: CaseStore.assessmentsHeadlines,
    linkTo: CaseStore.assessmentLinkTo,
    // diversion specific
    unreconciledDrugs: CaseStore.unreconciledDrugs,
    lastViewedByUser: CaseViewStore.lastViewedByUser,
    incidentsByPeriod: CaseStore.incidentsByPeriod,
    // privacy specific
    caseId: CaseStore.caseId,
    caseNumber: CaseStore.number,
    userId: CaseStore.userId,
    patientId: CaseStore.patientId,
  };
}

const AssessmentsForCaseTypeContainer = observer(() => (
  <AssessmentsForCaseType
    {...mapProps({
      CaseStore,
      CaseViewStore,
      CaseLogStore,
    })}
  />
));

export default AssessmentsForCaseTypeContainer;
