import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DateHelpers } from 'common';
import styles from './index.module.scss';
import PrintableFilterSection from './PrintableFilterSection';
import PrintableSectionHeader from './PrintableSectionHeader';

export const inclusiveOrExclusive = (label, isExcluded) => {
  if (label === 'Actions Taken')
    return isExcluded ? label + ' were not' : label + ' were';

  if (label === 'Resolved')
    return isExcluded ? 'Case was not resolved by' : 'Case was resolved by';

  return isExcluded ? label + ' is not' : label + ' is';
};

const PrintableFilters = ({
  caseActionDateRangeSelection,
  caseActionDateAfter,
  caseActionDateBefore,
  caseActionSelections,
  caseBundleSelections,
  casesByOptions,
  casesBy,
  caseCreatedBySelections,
  caseOwnerSelections,
  caseTypeSelections,
  departmentSelections,
  groupSelections,
  organizationSelections,
  pivotOn,
  pivotOnOptions,
  reportSettingsDateRange,
  reportSettingsDateAfter,
  reportSettingsDateBefore,
  resolutionSelections,
  resolvedBySelections,
  roleSelections,
  suspicionScoreOptions,
  suspicionScoreSelection,
  tagSelections,
  caseOwnerExcluded,
  caseCreatedByExcluded,
  groupExcluded,
  caseBundleExcluded,
  resolutionExcluded,
  resolvedByExcluded,
  caseActionExcluded,
  suspicionScoreExcluded,
  roleExcluded,
  tagExcluded,
}) => {
  const renderReportSettingsSection =
    casesBy || reportSettingsDateRange || pivotOn;

  const renderCaseDetailsSection =
    (caseOwnerSelections && caseOwnerSelections.length) ||
    (caseCreatedBySelections && caseCreatedBySelections.length > 0) ||
    (groupSelections && groupSelections.length > 0) ||
    (caseTypeSelections && caseTypeSelections.length > 0) ||
    (caseBundleSelections && caseBundleSelections.length > 0) ||
    (resolutionSelections && resolutionSelections.length > 0) ||
    (resolvedBySelections && resolvedBySelections.length > 0) ||
    (caseActionSelections && caseActionSelections.length > 0) ||
    caseActionDateRangeSelection ||
    suspicionScoreSelection;

  const renderUsersSection =
    (organizationSelections && organizationSelections.length > 0) ||
    (departmentSelections && departmentSelections.length > 0) ||
    (roleSelections && roleSelections.length > 0) ||
    (tagSelections && tagSelections.length > 0);

  const getSingleSelectFilterValue = (options, value) => {
    return options.find(option => option.value === value)?.label;
  };

  const getMultiSelectFilterValue = selections => {
    return selections.map(sel => sel.name).join(', ');
  };

  return (
    <section className={styles.printableFilters}>
      <div className={styles.header}>
        <strong>Performance </strong>
        <span>Cases</span>
      </div>

      {renderReportSettingsSection && (
        <>
          <PrintableSectionHeader icon="icon-speed" title="Report Details" />
          <PrintableFilterSection
            label="Select Cases By"
            value={getSingleSelectFilterValue(casesByOptions, casesBy)}
          />
          <PrintableFilterSection
            label="Time Range is"
            value={DateHelpers.getDateRangeString(
              reportSettingsDateRange,
              reportSettingsDateAfter,
              reportSettingsDateBefore,
              true
            )}
          />
          <PrintableFilterSection
            label="Pivot By"
            value={getSingleSelectFilterValue(pivotOnOptions, pivotOn)}
          />
        </>
      )}

      {renderCaseDetailsSection && (
        <>
          <PrintableSectionHeader icon="icon-assignment" title="Case Details" />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Case Owner', caseOwnerExcluded)}
            value={getMultiSelectFilterValue(caseOwnerSelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Case Creator', caseCreatedByExcluded)}
            value={getMultiSelectFilterValue(caseCreatedBySelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Group', groupExcluded)}
            value={getMultiSelectFilterValue(groupSelections)}
          />
          <PrintableFilterSection
            label="Case Type is"
            value={getMultiSelectFilterValue(caseTypeSelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Case Bundle', caseBundleExcluded)}
            value={getMultiSelectFilterValue(caseBundleSelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Resolution', resolutionExcluded)}
            value={getMultiSelectFilterValue(resolutionSelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Resolved', resolvedByExcluded)}
            value={getMultiSelectFilterValue(resolvedBySelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Actions Taken', caseActionExcluded)}
            value={getMultiSelectFilterValue(caseActionSelections)}
          />
          <PrintableFilterSection
            label="Actions Were Taken During"
            value={DateHelpers.getDateRangeString(
              caseActionDateRangeSelection,
              caseActionDateAfter,
              caseActionDateBefore,
              true
            )}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive(
              'Suspicion Score',
              suspicionScoreExcluded
            )}
            value={getSingleSelectFilterValue(
              suspicionScoreOptions,
              suspicionScoreSelection
            )}
          />
        </>
      )}

      {renderUsersSection && (
        <>
          <PrintableSectionHeader
            icon="icon-hospital_building"
            title="EMR User Details"
          />
          <PrintableFilterSection
            label="Organization is"
            value={getMultiSelectFilterValue(organizationSelections)}
          />
          <PrintableFilterSection
            label="Department is"
            value={getMultiSelectFilterValue(departmentSelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Role', roleExcluded)}
            value={getMultiSelectFilterValue(roleSelections)}
          />
          <PrintableFilterSection
            label={inclusiveOrExclusive('Tag', tagExcluded)}
            value={getMultiSelectFilterValue(tagSelections)}
          />
        </>
      )}
    </section>
  );
};

PrintableFilters.propTypes = {
  caseActionDateRangeSelection: PropTypes.string,
  caseActionDateAfter: PropTypes.string,
  caseActionDateBefore: PropTypes.string,
  caseActionSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseBundleSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  casesBy: PropTypes.string,
  casesByOptions: PropTypes.array,
  caseCreatedBySelections: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ).isRequired,
  caseOwnerSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseTypeSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  departmentSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  groupSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  organizationSelections: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ).isRequired,
  pivotOn: PropTypes.string,
  pivotOnOptions: PropTypes.array,
  reportSettingsDateRange: PropTypes.string,
  reportSettingsDateAfter: PropTypes.string,
  reportSettingsDateBefore: PropTypes.string,
  resolutionSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  resolvedBySelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  roleSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  suspicionScoreOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ).isRequired,
  suspicionScoreSelection: PropTypes.string,
  tagSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseOwnerExcluded: PropTypes.bool,
  caseCreatedByExcluded: PropTypes.bool,
  groupExcluded: PropTypes.bool,
  caseBundleExcluded: PropTypes.bool,
  resolutionExcluded: PropTypes.bool,
  resolvedByExcluded: PropTypes.bool,
  caseActionExcluded: PropTypes.bool,
  suspicionScoreExcluded: PropTypes.bool,
  roleExcluded: PropTypes.bool,
  tagExcluded: PropTypes.bool,
};

export default PrintableFilters;
