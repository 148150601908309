import React from 'react';
import { observer } from 'mobx-react';
import CaseIntegration from '../CaseIntegration';
import { CaseIntegrationStore } from '../../stores/CaseIntegrationStore';

export function mapProps({ caseIntegrationStore }) {
  return {
    caseIntegration: caseIntegrationStore.caseIntegration,
    caseIntegrationId: caseIntegrationStore.caseIntegrationId,
    isEnabled: caseIntegrationStore.isEnabled,
    tokenDisplay: caseIntegrationStore.tokenDisplay,
    canSave: caseIntegrationStore.canSave,
    isDeleteConfirmationVisible:
      caseIntegrationStore.isDeleteConfirmationVisible,
    setEnabled: caseIntegrationStore.setEnabled,
    setToken: caseIntegrationStore.setToken,
    timezone: caseIntegrationStore.timezone,
    setTimezone: caseIntegrationStore.setTimezone,
    setDeleteConfirmationVisible:
      caseIntegrationStore.setDeleteConfirmationVisible,
    save: caseIntegrationStore.save,
    saveIntegration: caseIntegrationStore.saveIntegration,
    deleteIntegration: caseIntegrationStore.delete,
    caseSyncSettings: caseIntegrationStore.activeCaseSyncSettings,
    setCaseSyncSettings: caseIntegrationStore.setRadarSyncSetting,
    integrationType: caseIntegrationStore.integrationType,
    setIntegrationType: caseIntegrationStore.setIntegrationType,
  };
}

const CaseIntegrationContainer = observer(() => {
  return (
    <CaseIntegration
      {...mapProps({ caseIntegrationStore: CaseIntegrationStore })}
    />
  );
});

CaseIntegrationContainer.displayName = 'CaseIntegrationContainer';

export default CaseIntegrationContainer;
