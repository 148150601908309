import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DropdownList } from 'react-widgets';

import { DateHelpers } from 'common';

import FilterDateRange from '../../search/FilterDateRange';
import ExclusiveFilterLabel from '../../search/ExclusiveFilterLabel';
import styles from './index.module.scss';

function applyLabel(filterLabel, options) {
  return DateHelpers.timePeriodsForValues(...options).map(p => ({
    filterLabel,
    ...p,
  }));
}

function FilterListItem(defaultValue, item, key, exclusive) {
  if (key)
    return (
      <span>{ExclusiveFilterLabel(defaultValue, item, key, exclusive)}</span>
    );
  if (defaultValue && item.filterLabel)
    return (
      <span>
        <strong>{item.filterLabel}:</strong>&nbsp;{item.label}
      </span>
    );
  return <span>{item.label}</span>;
}

export default function TimePlusCalendarSelector({
  CustomListItem,
  hiddenCalendar,
  options,
  onChangeDropdown,
  label,
  defaultValue,
  filterKey,
  onCalendarChange,
  createdInformation,
  exclusive,
  alignDropdownRight,
  restrictFutureDate,
  'data-cy': dataCy,
  ...other
}) {
  const items = applyLabel(label, options);

  let defaultItem =
    typeof items[0].label === 'string'
      ? { label: items[0].filterLabel }
      : items[0].filterLabel;
  if (defaultValue) {
    const match = items.find(el => el.value === defaultValue);
    if (match) defaultItem = match;
    // default value has been disabled, switch to the first option instead
    else onChangeDropdown(items[0], `${filterKey}Range`);
  }

  return (
    <div
      className={classnames('dropdownComponent', {
        [styles.excludable]: exclusive,
      })}
      data-cy={dataCy}
    >
      <DropdownList
        data={items}
        valueField="value"
        textField="label"
        defaultValue={defaultItem}
        value={defaultItem}
        onChange={obj => onChangeDropdown(obj, `${filterKey}Range`)}
        valueComponent={() => {
          if (CustomListItem)
            return <CustomListItem item={defaultItem} exclusive={exclusive} />;
          return FilterListItem(
            defaultValue,
            defaultItem,
            filterKey,
            exclusive
          );
        }}
        {...other}
      />
      <FilterDateRange
        className={classnames('expando', {
          hidden: !hiddenCalendar,
        })}
        dates={[...createdInformation]}
        filterKey={filterKey}
        onChange={onCalendarChange}
        alignDropdownRight={alignDropdownRight}
        restrictFutureDate={restrictFutureDate}
      />
    </div>
  );
}

TimePlusCalendarSelector.propTypes = {
  CustomListItem: PropTypes.elementType,
  hiddenCalendar: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  onChangeDropdown: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  filterKey: PropTypes.string,
  onCalendarChange: PropTypes.func,
  createdInformation: PropTypes.array,
  exclusive: PropTypes.bool,
  alignDropdownRight: PropTypes.bool,
  restrictFutureDate: PropTypes.bool,
  'data-cy': PropTypes.string,
};
