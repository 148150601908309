import { capitalizeFirstLetter } from './app/utils';

// In testing contexts, we might end up requiring this module several times
// due to jest mocks. Do not try to apply polyfills and extensions again.
//
if (!global.__extensionsInitialized) {
  Object.defineProperty(Array.prototype, 'groupBy', {
    enumerable: false,
    value: function value(prop) {
      return this.reduce(function (groups, item) {
        var val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    }
  });
  String.prototype.capitalizeFirstLetter = function () {
    return capitalizeFirstLetter(this);
  };

  // Based on the Mozilla polyfill https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/forEach
  if (!DOMTokenList.prototype.forEach) {
    DOMTokenList.prototype.forEach = function (callback, thisArg) {
      var T, k;
      if (this === null) {
        throw new TypeError(' this is null or not defined');
      }
      var O = Object(this);
      var len = O.length >>> 0;
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function');
      }
      if (arguments.length > 1) {
        T = thisArg;
      }
      k = 0;
      while (k < len) {
        var kValue;
        if (k in O) {
          kValue = O[k];
          callback.call(T, kValue, k, O);
        }
        k++;
      }
    };
  }
  Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
  };
  Storage.prototype.getObject = function (key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
  };

  // IE does not support the "contains" method for SVG elements. Popper.js needs
  // this to function properly.
  if (!SVGElement.prototype.contains) {
    SVGElement.prototype.contains = HTMLElement.prototype.contains;
  }
  global.__extensionsInitialized = true;
}