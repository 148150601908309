import React from 'react';
import { Tooltip } from '../../ui';
import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';
import CaseSummaryContainer from '../CaseSummaryContainer';

export const ActionNav = ({
  caseReportsCaseResolutionsStore,
  caseReportsFilterSelectionsStore,
  handleSetTableCollapsed,
  tableCollapsed,
}) => {
  return (
    <TopNavBar actionBar secondary>
      <CaseSummaryContainer
        caseReportsCaseResolutionsStore={caseReportsCaseResolutionsStore}
        caseReportsFilterSelectionsStore={caseReportsFilterSelectionsStore}
        isPrinted={false}
      />
      <div className={styles.spacer} />
      <Tooltip content="Toggle table" placement="bottom">
        <NavBarItem className="icon prot-a">
          <i
            onClick={handleSetTableCollapsed}
            className={classnames('material-icons icon-table_chart', {
              [styles.tableCollapsed]: tableCollapsed,
            })}
          />
        </NavBarItem>
      </Tooltip>
      <Tooltip content="Print" placement="bottom">
        <NavBarItem className="icon prot-a">
          <i
            onClick={() => window.print()}
            className="material-icons icon-print"
          />
        </NavBarItem>
      </Tooltip>
    </TopNavBar>
  );
};

ActionNav.propTypes = {
  caseReportsCaseResolutionsStore: PropTypes.shape({}),
  caseReportsFilterSelectionsStore: PropTypes.shape({}),
  handleSetTableCollapsed: PropTypes.func.isRequired,
  tableCollapsed: PropTypes.bool,
};

export default ActionNav;
