import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const Input = ({
  autoFocus,
  autoFocusDelay,
  bordered,
  borderRadius,
  className,
  darkBackground,
  disabled,
  error,
  errorMessage,
  font,
  coloredFont,
  label,
  margin,
  maxLength,
  max,
  min,
  name,
  noToggle,
  onChange,
  onKeyPress,
  onKeyDown,
  padding,
  placeholder,
  readOnly,
  type,
  value,
}) => {
  const [isFocused, setIsFocused] = useState(autoFocus);
  const [id] = useState(Math.random());

  /* autofocus element by default if autoFocus prop is true */
  const callbackRef = useCallback(
    inputElement => {
      if (inputElement && autoFocus) {
        setTimeout(() => {
          inputElement.focus();
        }, autoFocusDelay);
      }
    },
    [autoFocus, autoFocusDelay]
  );

  const wrapperClasses = classnames(styles.container, className, {
    [styles.bordered]: bordered,
    [styles.darkBackground]: darkBackground,
    [styles.disabled]: disabled,
    [styles.error]: error,
    [styles.paddingSm]: padding === 'sm',
    [styles.paddingMd]: padding === 'md',
    [styles.fontXs]: font === 'xs',
    [styles.fontSm]: font === 'sm',
    [styles.coloredFont]: coloredFont,
    [styles.marginMd]: margin === 'md',
    [styles.marginLg]: margin === 'lg',
    [styles.smBorderRadius]: borderRadius === 'sm',
    [styles.mdBorderRadius]: borderRadius === 'md',
    [styles.readOnly]: readOnly,
    [styles.showLabel]: noToggle || (label && (isFocused || !!value)),
  });

  return (
    <div className={wrapperClasses}>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
          {errorMessage && ' '}
          {errorMessage && errorMessage}
        </label>
      )}
      <input
        className={styles.input}
        disabled={disabled}
        id={id}
        min={min}
        max={max}
        maxLength={maxLength}
        name={name}
        data-cy={name}
        onChange={e => onChange(e.target.value)}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        placeholder={placeholder || label}
        readOnly={readOnly}
        ref={callbackRef}
        type={type}
        value={value}
      />
    </div>
  );
};

Input.propTypes = {
  autoFocus: PropTypes.bool,
  autoFocusDelay: PropTypes.number,
  bordered: PropTypes.bool,
  borderRadius: PropTypes.oneOf(['', 'sm', 'md']),
  coloredFont: PropTypes.bool,
  className: PropTypes.string,
  darkBackground: PropTypes.bool,
  font: PropTypes.oneOf(['', 'xs', 'sm']),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.oneOf(['', 'md', 'lg']),
  maxLength: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  noToggle: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  padding: PropTypes.oneOf(['', 'sm', 'md']),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  autoFocus: false,
  autoFocusDelay: 0,
  bordered: false,
  borderRadius: '',
  className: '',
  coloredFont: false,
  darkBackground: false,
  disabled: false,
  error: false,
  errorMessage: null,
  font: '',
  label: '',
  margin: '',
  maxLength: 1000,
  min: 0,
  max: 100,
  name: '',
  noToggle: false,
  onChange: () => {},
  onKeyPress: () => {},
  onKeyDown: () => {},
  padding: '',
  placeholder: '',
  readOnly: false,
  type: 'text',
  value: '',
};

export default Input;
