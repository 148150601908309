import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import styles from './index.module.scss';

const PatientResultsActionNav = ({
  onToggleAdvancedSearch,
  isAdvancedSearchActive,
  size,
  isMaxed,
  loading,
  areResultsFromAdvancedSearch,
  searchTerm,
}) => {
  const plural = size === 0 || size > 1;

  return (
    <TopNavBar actionBar>
      <NavBarItem>
        <i
          className={classnames(
            'material-icons icon-tune',
            styles.toggleButton
          )}
          onClick={onToggleAdvancedSearch}
          role="button"
          tabIndex="0"
          onKeyDown={({ key }) =>
            (key === 'Enter' || key === ' ') && onToggleAdvancedSearch()
          }
        />
      </NavBarItem>

      {/* Helper text urging you to use advanced search. */}
      {!isAdvancedSearchActive && loading && (
        <NavBarItem>
          This might take a while, want to narrow your patient search? Try{' '}
          <span
            role="button"
            onClick={onToggleAdvancedSearch}
            tabIndex="0"
            onKeyDown={({ key }) =>
              (key === 'Enter' || key === ' ') && onToggleAdvancedSearch()
            }
            className={styles.toggleLink}
          >
            Advanced Patient Search
          </span>
          .
        </NavBarItem>
      )}

      {/* Text describing completed results. */}
      {!loading && (
        <NavBarItem>
          <strong>
            {size}
            {isMaxed && '+'}
          </strong>{' '}
          {areResultsFromAdvancedSearch ? (
            `Search Result${plural ? 's' : ''} from Advanced Patient Search`
          ) : (
            <span>
              {`Matching Patient${plural ? 's' : ''} Containing`}{' '}
              <u>{searchTerm}</u>
            </span>
          )}
        </NavBarItem>
      )}
    </TopNavBar>
  );
};

PatientResultsActionNav.propTypes = {
  onToggleAdvancedSearch: PropTypes.func.isRequired,
  isAdvancedSearchActive: PropTypes.bool.isRequired,
  size: PropTypes.number.isRequired,
  isMaxed: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  areResultsFromAdvancedSearch: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string,
};

PatientResultsActionNav.defaultProps = {
  searchTerm: '',
};

export default PatientResultsActionNav;
