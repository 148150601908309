import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Authenticated, HTMLEntities, Pandy } from 'common';
import $ from 'jquery';
import List from '../List';
import ActionNav from '../../navbar/ActionNav';
import TopNavBar from '../../navbar/TopNavBar';
import { DocumentTitle, RegistryContainer } from '../../ui';
import VipsFiltersContainer from '../VipsFiltersContainer';
import AdvancedFiltersContainer from '../AdvancedFiltersContainer';
import styles from './index.module.scss';

const ListView = ({
  advancedFiltersVisible,
  query,
  setFilters,
  downloadCSV,
  noFilters,
  totalElements,
  size,
  loading,
  storeForRegistry,
  onToggleAdvancedFilters,
}) => {
  useEffect(() => {
    setFilters(query);
  }, [query, setFilters]);

  const onDownloadCSV = e => {
    e.preventDefault();
    downloadCSV();
  };

  const uploadIcon = {
    label: 'Bulk Registration',
    icon: 'add_to_photos',
    path: 'vips/upload',
  };

  const updateIcon = {
    label: 'Bulk Actions',
    icon: 'done_all',
    path: `vips/update?${$.param(query)}`,
  };

  const additionalIcons = [uploadIcon];
  if (size > 0) additionalIcons.unshift(updateIcon);

  return (
    <div className="vip--view">
      <DocumentTitle text="VIP Registrations" />
      <AdvancedFiltersContainer />
      <div
        className={cn([styles.mainPanel], {
          [styles.withAside]: advancedFiltersVisible,
        })}
      >
        <TopNavBar filterBar>
          <VipsFiltersContainer />
        </TopNavBar>
        <ActionNav
          secondary={false}
          createIcon="add_circle"
          advancedFiltersOnClick={onToggleAdvancedFilters}
          createLabel="Add New Registration"
          createPath="/vips/create"
          createPermission={['VIP_REGISTRATION_CREATE']}
          count={totalElements}
          additionalIcons={additionalIcons}
          downloadCSV={onDownloadCSV}
          title={`Matching VIP Registration`}
        />
        <Authenticated permission="VIP_REGISTRATION_SEARCH_ALL">
          <RegistryContainer
            store={storeForRegistry}
            title="VIP Registry"
            className="registry"
            pandyText={`Matching VIP Registrations`}
          >
            <Pandy
              visible={!loading && size === 0}
              mood="happy"
              data-cy="vip-pandy"
            >
              <div className="speechBubble">
                {noFilters ? (
                  <span>VIP registry is empty. Add some!</span>
                ) : (
                  <span>{`No Matching VIP Registrations Found`}</span>
                )}
              </div>
              {noFilters && (
                <React.Fragment>
                  <p>
                    Click the {HTMLEntities.rsquo}Register a VIP
                    {HTMLEntities.rsquo} button to get started.
                  </p>
                  <Link to="/vips/create" className="button">
                    Register a VIP
                  </Link>
                </React.Fragment>
              )}
            </Pandy>
            <List
              vipsStore={storeForRegistry}
              advancedFiltersVisible={advancedFiltersVisible}
            />
          </RegistryContainer>
        </Authenticated>
      </div>
    </div>
  );
};

ListView.propTypes = {
  advancedFiltersVisible: PropTypes.bool,
  onToggleAdvancedFilters: PropTypes.func,
  query: PropTypes.shape({
    status: PropTypes.string,
    sort: PropTypes.string,
  }).isRequired,
  failure: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  downloadCSV: PropTypes.func.isRequired,
  noFilters: PropTypes.bool,
  totalElements: PropTypes.number,
  size: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  storeForRegistry: PropTypes.shape().isRequired,
};

ListView.defaultProps = {
  advancedFiltersVisible: false,
  onToggleAdvancedFilters: () => {},
  failure: null,
  noFilters: false,
  totalElements: 0,
  size: 0,
};

export default ListView;
