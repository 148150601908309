import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DropdownList } from 'react-widgets';
import Checkbox from '../../../ui/Checkbox';
import styles from './index.module.scss';
import { TIMEZONES } from '../../../stores/PinnedTimezoneStore';

const PinnedTimezone = ({
  isDirty,
  pinnedTimezoneSettingsEditValue,
  update,
  updateTimezone,
}) => {
  return (
    <div id="settings__wrapper-PinnedTimezone" className="wrapper">
      <div className="card_header">
        <h1>Timezone</h1>
        <span
          className={classnames('prot-a', { muted: !isDirty })}
          onClick={update}
        >
          Save Changes
        </span>
      </div>
      <div className="content">
        <p>
          Ability to select the timezone for your Protenus UI to display all
          source system event data for all end users. If you opt to not select a
          timezone, then source system event data will remain in end users'
          timezone. Please note any selection will persist for all end users.
          Any updates should be aligned with your company's procedures.
        </p>
        <div className={styles.dropdown}>
          <DropdownList
            data={TIMEZONES}
            dropUp
            onChange={({ value }) => updateTimezone(value)}
            value={pinnedTimezoneSettingsEditValue}
            valueField="value"
            textField="key"
            valueComponent={({ item }) => (
              <span>
                <strong>Timezone:</strong> {item?.key}
              </span>
            )}
          />
        </div>
      </div>
    </div>
  );
};

PinnedTimezone.propTypes = {};

PinnedTimezone.defaultProps = {};

PinnedTimezone.displayName = 'PinnedTimezone';

export default PinnedTimezone;
