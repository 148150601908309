import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import MultiRecordCompositeFieldSection from '../../MultiRecordCompositeFieldSection';
import { MultiRecordCompositeSectionFilterStore } from '../../stores/MultiRecordCompositeSectionFilterStore';
import { useEphemeralStore } from '../../../utils/hooks';

export const MultiRecordPatientInformation = ({
  patientInformationSections,
}) => {
  const multiRecordCompositeSectionFilterStore = useEphemeralStore(
    () => new MultiRecordCompositeSectionFilterStore()
  );
  return (
    <div className={styles.wrapper}>
      {patientInformationSections?.map((group, idx) => {
        return (
          <div className={styles.patientInformationGroup} key={idx}>
            <MultiRecordCompositeFieldSection
              name={group?.name}
              isCountVisible={group?.isCountVisible}
              isExpandable={group?.isExpandable}
              groups={group?.groups}
              multiRecordCompositeSectionFilterStore={
                multiRecordCompositeSectionFilterStore
              }
            />
          </div>
        );
      })}
    </div>
  );
};

MultiRecordPatientInformation.propTypes = {
  patientInformationSections: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]),
};
