import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { CompareViewContext } from '../../../Context';
import MultiRecordEntityColumn from '../../../MultiRecordEntityColumn';
import {
  patientColumnMapProps,
  patientSecondColumnMapProps,
} from '../../mapProps';

export const CompareViewPatientColumnContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CompareViewContext,
    mapProps: patientColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);

export const CompareViewPatientColumnContainerSecond = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CompareViewContext,
    mapProps: patientSecondColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);
