import React from 'react';
import { observer } from 'mobx-react';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import IncidentFilterBar from '../IncidentFilterBar';
import IncidentReportFilterStore from '../stores/IncidentReportFilterStore';

export function mapProps({ IncidentReportFilterStore, query, history }) {
  return {
    query,
    history,
    queryCriteria: IncidentReportFilterStore.query,
    setQueryFromURL: IncidentReportFilterStore.setQueryFromURL,
    filters: IncidentReportFilterStore.subnavFilterOptions,
    sortBy: IncidentReportFilterStore.allSortByOptions,
    groupBy: IncidentReportFilterStore.allGroupByOptions,
  };
}

const IncidentFilterBarContainer = withRouterAndQuery(
  observer(({ query, history }) => (
    <IncidentFilterBar
      {...mapProps({
        IncidentReportFilterStore,
        query,
        history,
      })}
    />
  ))
);

export default IncidentFilterBarContainer;
