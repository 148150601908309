import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { CustomerAdministeredAccountsContext } from '../stores/Context';
import CustomerAdministeredAccountsManageForm from '../CustomerAdministeredAccountsManageForm';

export function mapProps({
  alertCategoryGroupStore,
  customerAdministeredAccountsEditUserStore,
  customerAdministeredAccountsListStore,
  history,
  loginStore,
}) {
  return {
    availableRoles: customerAdministeredAccountsEditUserStore.availableRoles,
    editedFields: customerAdministeredAccountsEditUserStore.editedFields,
    emailValidityProperties:
      customerAdministeredAccountsEditUserStore.emailValidityProperties,
    groups: alertCategoryGroupStore.groups,
    history: history,
    isFormDirty: customerAdministeredAccountsEditUserStore.isFormDirty,
    isFormValid: customerAdministeredAccountsEditUserStore.isFormValid,
    isSamlEnabled: loginStore.samlConfiguration?.required ?? false,
    isSavedUserEnabled:
      customerAdministeredAccountsEditUserStore.isSavedUserEnabled,
    isSavedUserTriage:
      customerAdministeredAccountsEditUserStore.isSavedUserTriage,
    isUserLocked: customerAdministeredAccountsEditUserStore.isUserLocked,
    licenseLimit: customerAdministeredAccountsEditUserStore.licenseLimit,
    licensesRemaining:
      customerAdministeredAccountsEditUserStore.licensesRemaining,
    licenseLimitReachedFromEdit:
      customerAdministeredAccountsEditUserStore.licenseLimitReachedFromEdit,
    resetMFA: customerAdministeredAccountsEditUserStore.resetMFA,
    save: customerAdministeredAccountsEditUserStore.save,
    saveError: customerAdministeredAccountsEditUserStore.saveError,
    sendWelcomeEmail:
      customerAdministeredAccountsEditUserStore.sendWelcomeEmail,
    setField: customerAdministeredAccountsEditUserStore.setField,
    setUserId: customerAdministeredAccountsEditUserStore.setUserId,
    unlockAccount: customerAdministeredAccountsEditUserStore.unlockAccount,
    userId: customerAdministeredAccountsEditUserStore.userId,
    viewRoute: customerAdministeredAccountsListStore.viewRoute,
  };
}

const CustomerAdministeredAccountsManageFormContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CustomerAdministeredAccountsContext,
    mapProps,
    PresentationalComponent: CustomerAdministeredAccountsManageForm,
  })
);

CustomerAdministeredAccountsManageFormContainer.displayName =
  'CustomerAdministeredAccountsManageFormContainer';

export default CustomerAdministeredAccountsManageFormContainer;
