import React from 'react';
import { observer } from 'mobx-react';
import EPCSStore from '../EPCSStore';
import EPCSView from '../EPCSView';

export function mapProps({ EPCSStore }) {
  return {
    download: EPCSStore.downloadCSV,
    startDate: EPCSStore.startDate,
    radioOption: EPCSStore.radioOption,
    endDate: EPCSStore.endDate,
    setValue: EPCSStore.setValue,
  };
}

const EPCSViewContainer = observer(() => {
  return (
    <EPCSView
      {...mapProps({
        EPCSStore,
      })}
    />
  );
});

export default EPCSViewContainer;
