import VipsFilters from '../VipsFilters';
import { ListContext } from '../Context';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';

export function mapProps({ vipsStore, query, history }) {
  return {
    statusFilterOptions: vipsStore.statusFilterOptions,
    createdByFilterOptions: vipsStore.createdByFilterOptions,
    lastModifiedByFilterOptions: vipsStore.lastModifiedByFilterOptions,
    query: query,
    historyPush: history.push,
    advancedFiltersOpen: !!vipsStore.showFilters,
  };
}

const VipsFiltersContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: ListContext,
    mapProps: mapProps,
    PresentationalComponent: VipsFilters,
  })
);

VipsFiltersContainer.displayName = 'VipsFiltersContainer';

export default VipsFiltersContainer;
