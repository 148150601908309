import PropTypes from 'prop-types';
import Status from '../Status';
import { BulkVipContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';

export function mapProps({ bulkVipStore, rowIndex }) {
  const datum = bulkVipStore.data[rowIndex];
  return {
    completedRequests: bulkVipStore.completedRequests,
    hasSubmit: bulkVipStore.registering || bulkVipStore.complete,
    toggleActions: {
      toggleSelect: bulkVipStore.toggleSelect,
      isSelected: bulkVipStore.isSelected,
    },
    rowErrorMessage: datum.errorMessage,
    matchedPatient: datum.matchedPatient,
  };
}

const StatusContainer = observerWrappedContainerFactory({
  Context: BulkVipContext,
  mapProps: mapProps,
  PresentationalComponent: Status,
});

StatusContainer.propTypes = {
  rowIndex: PropTypes.number,
};

StatusContainer.defaultProps = {
  rowIndex: null,
};

StatusContainer.displayName = 'Status Container';

export default StatusContainer;
