import React from 'react';
import PropTypes from 'prop-types';
import {
  EntityHeaderADC,
  EntityHeaderDate,
  EntityHeaderPatient,
  EntityHeaderUser,
} from '../EntityHeader';

const componentMap = {
  Date: EntityHeaderDate,
  ADC: EntityHeaderADC,
  Patient: EntityHeaderPatient,
  User: EntityHeaderUser,
};

function EntityHeaderWrapper({ topText, bottomText, extraText }) {
  const MatchedHeaderComponent = componentMap[topText];
  return (
    <MatchedHeaderComponent
      topText={topText}
      bottomText={bottomText}
      extraText={extraText}
    />
  );
}

EntityHeaderWrapper.propTypes = {
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
  extraText: PropTypes.string,
};

EntityHeaderWrapper.defaultProps = {
  extraText: null,
};

EntityHeaderWrapper.displayName = 'EntityHeaderWrapper';

export default EntityHeaderWrapper;
