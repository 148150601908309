import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import { RouterContainer } from 'common';
import Tooltip from '../../ui/Tooltip';

const handleClick = caseId => {
  RouterContainer.go(`/case/${caseId}`, {});
};

const CaseCell = ({ caze }) => {
  if (caze) {
    let caseSVG = 'case';
    if (caze.resolution === 'NOT_VIOLATION') caseSVG = 'caseNotViolation';
    if (caze.resolution === 'VIOLATION') caseSVG = 'caseViolation';

    return (
      <Cell onClick={() => handleClick(caze.caseId)} data-cy="triangle-tool">
        <Tooltip content="Event is part of a case">
          <img
            className="case"
            src={`assets/img/${caseSVG}.svg`}
            alt="Event is part of a case"
          />
        </Tooltip>
      </Cell>
    );
  }

  return null;
};

CaseCell.propTypes = {
  caze: PropTypes.shape({
    resolution: PropTypes.string,
    caseId: PropTypes.string,
  }),
};

CaseCell.defaultProps = {
  caze: null,
};

export default CaseCell;
