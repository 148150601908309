import React from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';
import classnames from 'classnames';

import filtersStyles from '../index.module.scss';
import ExclusionButton from '../ExclusionToggle';
import styles from './index.module.scss';

const ExclusionDropdown = ({
  allowNoneLabel,
  disabled,
  dropUp,
  hideExclusion,
  isExcluding,
  label,
  onChange,
  onToggleExclusion,
  options,
  textField,
  value,
  valueField,
  'data-cy': dataCy,
}) => {
  const rawValue = (value || {})[valueField];

  return (
    <div
      className={classnames(filtersStyles.excludable, {
        [styles.hideExclusion]: hideExclusion,
      })}
      data-cy={dataCy}
    >
      {!hideExclusion && (
        <ExclusionButton
          isDisabled={!rawValue || rawValue === 'all' || disabled}
          isExcluding={isExcluding}
          toggleFilter={onToggleExclusion}
        />
      )}

      <DropdownList
        data={options}
        value={value}
        onChange={onChange}
        placeholder={label}
        valueField={valueField}
        textField={textField}
        dropUp={dropUp}
        disabled={disabled}
        data-cy={dataCy}
        valueComponent={() => {
          if (!allowNoneLabel && (!rawValue || rawValue === 'all'))
            return <span>{label}</span>;

          const fragment = allowNoneLabel ? '' : !isExcluding ? 'Is' : 'Is Not';

          return (
            <span>
              {label}
              {allowNoneLabel ? '' : ' '}
              {isExcluding ? (
                <strong className="text-danger">{fragment}</strong>
              ) : (
                <strong>{fragment}</strong>
              )}
              :{' '}
              <strong data-cy={dataCy + '--text-field'}>
                {value[textField]}
              </strong>
            </span>
          );
        }}
      />
    </div>
  );
};

ExclusionDropdown.propTypes = {
  allowNoneLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  dropUp: PropTypes.bool,
  hideExclusion: PropTypes.bool,
  isExcluding: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggleExclusion: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  textField: PropTypes.string.isRequired,
  value: PropTypes.object,
  valueField: PropTypes.string.isRequired,
  'data-cy': PropTypes.string,
};

ExclusionDropdown.defaultProps = {
  allowNoneLabel: false,
  dropUp: false,
  disabled: false,
  hideExclusion: false,
};

export default ExclusionDropdown;
