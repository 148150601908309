import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CaseReportsPropTypes from '../PropTypes';
import { useContainerDimension } from './hooks/useContainerDimension';
import CustomLegends from './CustomLegends';
import styles from './index.module.scss';
import LineChart from './LineChart';
import GroupedBarChart from '../../ui/GroupedBarChart';
import classnames from 'classnames';

const Chart = ({
  averageData,
  chartData = [],
  chartDomainPadding,
  chartType = 'barChart',
  configuration,
  legendData = [],
  showLegend,
  skipAlphaNumericSort,
}) => {
  const chartContainer = useRef(null);

  const dimension = useContainerDimension(chartContainer);

  const willShowPandy = (chartType, configuration) => {
    return (
      (chartType === 'barChart' && chartData.length > configuration.maxBars) ||
      (chartType === 'lineChart' &&
        chartData.length > configuration.maxLines) ||
      !chartData.length
    );
  };

  // We only want to show the legend if there's legend data and if the charts
  // themselves will render.
  const shouldShowLegend =
    !!chartData.length &&
    (showLegend || legendData.length >= 2) &&
    ((chartType === 'barChart' && chartData.length <= configuration.maxBars) ||
      (chartType === 'lineChart' &&
        chartData.length <= configuration.maxLines));

  return (
    <div
      className={classnames(styles.chartWrapper, {
        [styles.hidePandyOnPrint]: willShowPandy(chartType, configuration),
      })}
    >
      <div ref={chartContainer} className={styles.chart}>
        {chartType === 'barChart' ? (
          <>
            <GroupedBarChart
              averageData={averageData}
              chartData={chartData}
              configuration={configuration}
              height={dimension.height}
              width={dimension.width}
              skipAlphaNumericSort={skipAlphaNumericSort}
            />
            <GroupedBarChart
              averageData={averageData}
              chartData={chartData}
              configuration={configuration}
              isPrinterFriendly
              height={dimension.height}
              width={700}
              skipAlphaNumericSort={skipAlphaNumericSort}
            />
          </>
        ) : (
          <LineChart
            chartData={chartData}
            configuration={configuration}
            width={dimension.width}
            height={dimension.height}
            domainPadding={chartDomainPadding}
          />
        )}
      </div>
      {shouldShowLegend && <CustomLegends data={legendData} />}
    </div>
  );
};

Chart.propTypes = {
  averageData: PropTypes.string,
  chartData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  chartDomainPadding: PropTypes.number,
  chartType: PropTypes.string,
  configuration: CaseReportsPropTypes.configuration,
  legendData: PropTypes.arrayOf(PropTypes.shape({})),
  showLegend: PropTypes.bool,
  skipAlphaNumericSort: PropTypes.bool,
};

export default Chart;
