import React from 'react';
import { observer } from 'mobx-react';
import MultiRecordSearch from '../MultiRecordSearch';

export function mapProps({ multiRecordCompositeSectionFilterStore }) {
  return {
    value: multiRecordCompositeSectionFilterStore.filterTerm,
    setValue: multiRecordCompositeSectionFilterStore.setFilterTerm,
  };
}

const MultiRecordSearchContainer = observer(
  ({ multiRecordCompositeSectionFilterStore }) => (
    <MultiRecordSearch
      {...mapProps({ multiRecordCompositeSectionFilterStore })}
    />
  )
);

MultiRecordSearchContainer.displayName = MultiRecordSearchContainer;
export default MultiRecordSearchContainer;
