import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';

import {
  AsidePanel,
  Button,
  Checkbox,
  Input,
  PromptWrapper,
} from '../../../ui';
import styles from './index.module.scss';

const RolesManageForm = ({
  allPermissions,
  isDirty,
  // eslint-disable-next-line no-unused-vars
  isValid,
  onChangeSelectedRole,
  onCloseForm,
  onDeleteRole,
  onSubmit,
  roleFields,
  setRoleFields,
  selectedRoleName,
  setIsOpen,
  redirect,
  loading,
  showPrompt,
  isEditDirty,
}) => {
  useEffect(() => {
    onChangeSelectedRole(selectedRoleName);
    return () => onChangeSelectedRole(null);
  }, [onChangeSelectedRole, selectedRoleName]);

  useEffect(() => {
    setIsOpen(true);
    return () => setIsOpen(false);
  }, [setIsOpen]);

  const title = selectedRoleName ? 'Update Role' : 'Create New Role';
  const systemRole = roleFields.systemRole;

  if (redirect) {
    return <Redirect to="/settings/roles" />;
  }

  return (
    <AsidePanel title={title} isOpen onClose={onCloseForm}>
      <form
        className={styles.form}
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Input
          bordered
          margin="md"
          placeholder="Title (Required)"
          disabled={!!systemRole || !!selectedRoleName}
          label="Title"
          value={roleFields.name}
          onChange={value => setRoleFields('name', value)}
        />
        <Input
          bordered
          margin="md"
          placeholder="Description (Required)"
          disabled={!!systemRole}
          label="Description"
          value={roleFields.description}
          onChange={value => setRoleFields('description', value)}
        />
        <div className={styles.checkboxWrapper}>
          {allPermissions.map(permission => (
            <Checkbox
              key={permission}
              disabled={!!systemRole || loading}
              label={permission.split('_').join(' ')}
              checked={(roleFields.permissions || []).includes(permission)}
              onChange={() => setRoleFields('permissions', permission)}
            />
          ))}
        </div>
        <div className={styles.buttonDiv}>
          <Button
            type="submit"
            value={selectedRoleName ? 'Update' : 'Create'}
            disabled={!isDirty || loading}
          />
          {selectedRoleName && (
            <Button
              onClick={() => onDeleteRole()}
              value="Delete"
              disabled={!!systemRole || loading}
            />
          )}
        </div>
      </form>
      <PromptWrapper when={selectedRoleName ? isEditDirty : showPrompt} />
    </AsidePanel>
  );
};

RolesManageForm.propTypes = {
  allPermissions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
  isDirty: PropTypes.bool,
  isValid: PropTypes.bool,
  onChangeSelectedRole: PropTypes.func,
  onCloseForm: PropTypes.func,
  onDeleteRole: PropTypes.func,
  onSubmit: PropTypes.func,
  roleFields: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    permissions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
    systemRole: PropTypes.bool,
  }),
  setRoleFields: PropTypes.func,
  setIsOpen: PropTypes.func,
  selectedRoleName: PropTypes.string,
  redirect: PropTypes.bool,
  loading: PropTypes.bool,
  showPrompt: PropTypes.bool,
  isEditDirty: PropTypes.bool,
};

RolesManageForm.defaultProps = {
  allPermissions: [],
  isDirty: false,
  isValid: false,
  onChangeSelectedRole: () => {},
  onCloseForm: () => {},
  onDeleteRole: () => {},
  onSubmit: () => {},
  roleFields: {},
  setRoleFields: () => {},
  setIsOpen: () => {},
  selectedRoleName: null,
  redirect: false,
  loading: false,
  showPrompt: false,
  isEditDirty: false,
};

export default RolesManageForm;
