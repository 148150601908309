import React from 'react';
import moment from 'moment';
import Tooltip from '../Tooltip';

const score = (score, comma) =>
  `${score ? Math.round(score * 100) : 'Unknown'}${comma ? ', ' : ''}`;

export function dateContent(minDate, maxDate, format) {
  const sameDay = minDate.isSame(maxDate, 'day');
  return sameDay
    ? minDate.format(format)
    : `${minDate.format(format)} - ${maxDate.format(format)}`;
}

export function scoreWithoutTooltip(analyticAssessments) {
  return analyticAssessments.reduce(
    (formattedScores, { suspicionScore }, idx) => {
      formattedScores.unshift(score(suspicionScore, idx === 0 ? '' : ', '));
      return formattedScores;
    },
    []
  );
}

export function scoreWithTooltip(analyticAssessments) {
  return analyticAssessments.reduce(
    (formattedScores, { minEventDate, maxEventDate, suspicionScore }, idx) => {
      // unshift is to reverse the array and place the most recent SS as the first element
      formattedScores.unshift(
        <Tooltip
          content={dateContent(
            moment(minEventDate),
            moment(maxEventDate),
            'll'
          )}
          key={`${suspicionScore}${minEventDate}`}
        >
          <span>{score(suspicionScore, idx === 0 ? '' : ', ')}</span>
        </Tooltip>
      );
      return formattedScores;
    },
    []
  );
}
