import React from 'react';
import { observer } from 'mobx-react';
import { FlashBannerStore, PermissionStore } from 'common';

import Store from '../stores/UserTagsStore';

import UserTagsListView from '../UserTagsListView';
import { withRouterAndQuery } from '../../../utils/RouteHelper';

const filterMsg = localStorage.getItem('filterMsg');

export function mapProps({
  flashBannerStore,
  store,
  permissionStore,
  query,
  location,
  match,
  // eslint-disable-next-line no-unused-vars
  filterMsg,
}) {
  const pathname = location.pathname;
  const editPath = new RegExp(/\/user-tags\/\w+/);
  const isCreate = pathname === '/user-tags/create';
  const isEdit = pathname.match(editPath) && !isCreate;

  return {
    activeTags: store.activeTags,
    downloadCSV: store.downloadCSV,
    failure: store.failure,
    setFilters: store.setFilters,
    loading: store.loading,
    store,
    hasPermission: permissionStore.has('PERSON_TAG_CREATE'),
    addBanner: (msg, level) => flashBannerStore.add(msg, level),
    query,
    tagId: match.params.tagId,
    setActiveUserTagId: store.setActiveUserTagId,
    isCreate,
    isEdit,
  };
}

const UserTagsListViewContainer = withRouterAndQuery(
  observer(({ query, location, match }) => (
    <UserTagsListView
      {...mapProps({
        flashBannerStore: FlashBannerStore,
        store: Store,
        permissionStore: PermissionStore,
        query,
        location,
        match,
        filterMsg,
      })}
    />
  ))
);

export default UserTagsListViewContainer;
