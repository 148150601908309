import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  AppUserStore,
  Authenticated,
  HalUtils,
  LoginStore,
  PermissionSets,
  RouterContainer,
} from 'common';

import NavBarItem from '../../navbar/NavBarItem';
import TopNavBar from '../../navbar/TopNavBar';
import Tooltip from '../../ui/Tooltip';

import BulkCaseActionsStore from '../../stores/BulkCaseActionsStore';
import CaseResultsStore from '../stores/CaseResultsStore';

const IconItemInner = ({
  icon,
  filterProperty,
  filterValue,
  visible,
  innerRef,
  ...props
}) => (
  <NavBarItem
    ref={innerRef}
    route={CaseResultsStore.path({ [filterProperty]: filterValue })}
    {...props}
  >
    {visible && <i className={`material-icons ${icon}`} />}
  </NavBarItem>
);

IconItemInner.propTypes = {
  icon: PropTypes.string,
  filterProperty: PropTypes.string,
  filterValue: PropTypes.bool,
  visible: PropTypes.bool,
  innerRef: PropTypes.func,
};

let IconItem = (props, ref) => <IconItemInner {...props} innerRef={ref} />;

IconItem = forwardRef(IconItem);

const CaseResultsActionNav = observer(
  class CaseResultsActionNav extends React.Component {
    downloadCSV = e => {
      e.preventDefault();

      CaseResultsStore.downloadCSV();
    };

    goToBulkActions = e => {
      e.preventDefault(); // don't do any link or button things

      // bulkaction store need to have the params in order to refresh the query
      BulkCaseActionsStore.setParams(CaseResultsStore.params);

      CaseResultsStore.fetchIds().then(r => {
        BulkCaseActionsStore.bootstrapCases(r._embedded.case);
        RouterContainer.go('/bulkCaseActions', {});
      });
    };

    renderToggleAdvFilters() {
      return (
        <Tooltip content="Advanced Filters" placement="bottom">
          <IconItem
            active={Boolean(CaseResultsStore.showFilters)}
            className="icon advFilters"
            icon="icon-tune"
            filterProperty="showFilters"
            filterValue={!CaseResultsStore.showFilters}
            onClick={CaseResultsStore.toggleAdvFilters}
            visible
          />
        </Tooltip>
      );
    }

    renderCount() {
      const {
        resultsCount,
        owner,
        user,
        userInfo,
        patient,
        patientInfo,
      } = CaseResultsStore;
      let description = '';

      if (!CaseResultsStore.showFilters) {
        if (owner && owner !== LoginStore.id && owner !== 'null') {
          const appUser = AppUserStore.caseOwners.find(
            u => HalUtils.getId(u) === owner
          );
          description = (
            <span>
              Owned by{' '}
              <ins>{appUser && `${appUser.firstName} ${appUser.lastName}`}</ins>
            </span>
          );
        } else if (user) {
          description = (
            <span>
              With EMR User{' '}
              <ins>
                {userInfo &&
                  `${userInfo.firstName || 'Unknown'} ${userInfo.lastName ||
                    'Unknown'}`}
              </ins>
            </span>
          );
        } else if (patient) {
          description = (
            <span>
              With Patient{' '}
              <ins>
                {patientInfo &&
                  `${patientInfo.firstName ||
                    'Unknown'} ${patientInfo.lastName || 'Unknown'}`}
              </ins>
            </span>
          );
        }
      }
      const items = [
        <NavBarItem key="result-count">
          <strong data-cy="case-count">{resultsCount}</strong> Matching Case
          {resultsCount === 1 ? '' : 's'} {description}
        </NavBarItem>,
      ];

      if (description) {
        items.push(
          <NavBarItem key="clear">
            <span
              className="advFilters--clear"
              onClick={CaseResultsStore.removeAdvFilters}
            >
              <i className="material-icons icon-clear" />
            </span>
          </NavBarItem>
        );
      }

      return items;
    }

    renderBulkActionsButton() {
      return (
        <Authenticated anyPermission permission={PermissionSets.bulkActions}>
          <Tooltip content="Bulk Actions" placement="bottom">
            <IconItem
              className="icon"
              onClick={this.goToBulkActions}
              icon="icon-done_all"
              visible={CaseResultsStore.size !== 0}
            />
          </Tooltip>
        </Authenticated>
      );
    }

    renderCSVExportButton() {
      return (
        <Authenticated permission="CASE_SEARCH">
          <Tooltip content="Export CSV" placement="bottom">
            <IconItem
              className="icon"
              onClick={this.downloadCSV}
              icon="icon-get_app"
              visible={CaseResultsStore.size !== 0}
            />
          </Tooltip>
        </Authenticated>
      );
    }

    renderAddGRCCaseButton() {
      return (
        <Authenticated permission="CASE_CREATE_GRC">
          <Tooltip content="Create New Case" placement="bottom">
            <IconItem
              className="icon"
              onClick={e => {
                e.preventDefault();
                RouterContainer.go('/case/create', {});
              }}
              icon="icon-add_circle"
              visible
            />
          </Tooltip>
        </Authenticated>
      );
    }

    render() {
      return (
        <TopNavBar actionBar>
          {this.renderToggleAdvFilters()}
          {this.renderCount()}
          <li className="pull-right">
            <ul>
              {this.renderBulkActionsButton()}
              {this.renderCSVExportButton()}
              {this.renderAddGRCCaseButton()}
            </ul>
          </li>
        </TopNavBar>
      );
    }
  }
);

CaseResultsActionNav.displayName = 'CaseResultsActionNav';

export default CaseResultsActionNav;
