import React from 'react';
import PropTypes from 'prop-types';

const IncidentSummary = ({
  suspicionScore,
  unreconciledDrugs,
  incidentsLen,
}) => {
  return (
    <div className="incident-summary-container">
      <div className="incident-summary-block">
        <p className="incident-summary-block-label">Suspicion Score</p>
        <p className="incident-summary-block-content">
          {Math.round(suspicionScore * 100) || 'Unknown'}
        </p>
      </div>
      <div className="incident-summary-block">
        <p className="incident-summary-block-label">Unreconciled Drugs</p>
        <p className="incident-summary-block-content">
          {unreconciledDrugs ? unreconciledDrugs : 'No'}
        </p>
      </div>
      <div className="incident-summary-block">
        <p className="incident-summary-block-label">Incidents</p>
        <p className="incident-summary-block-content">{incidentsLen}</p>
      </div>
    </div>
  );
};

const strOrNum = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

IncidentSummary.propTypes = {
  suspicionScore: strOrNum,
  unreconciledDrugs: strOrNum,
  incidentsLen: PropTypes.number,
};

IncidentSummary.defaultProps = {
  suspicionScore: '',
  unreconciledDrugs: '',
  incidentsLen: 0,
};

export default IncidentSummary;
