import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import OtherAlias from '../../people/PersonBox/OtherAlias';
import styles from './index.module.scss';
import UserTagsAttribute from '../UserTagsAttribute';
import AuthAccessAttribute from '../../people/PersonBox/AuthAccessAttribute';
import { useFetchUserAuths } from '../../stores/EmployeeAuthorizationsStore/useFetchUserAuths';

const SubEmployeeBox = ({
  authorizations,
  canManageAuths,
  canViewAuths,
  employeeId,
  otherAliasId,
  patientId,
  patientLinkForOppositeAlias,
  tagListProps,
}) => {
  useFetchUserAuths(employeeId);

  return (
    <ul className={styles.subEmployeeBox} data-cy="employee-box">
      <OtherAlias
        link={otherAliasId && patientLinkForOppositeAlias}
        mode={'user'}
      />
      {tagListProps?.canViewTags && (
        <UserTagsAttribute
          canManageTags={tagListProps?.canManageTags}
          tags={tagListProps?.tags}
          userId={employeeId}
        />
      )}
      {canViewAuths && (
        <AuthAccessAttribute
          auths={authorizations}
          canManageAuths={canManageAuths}
          patientId={patientId}
          type="user"
          userId={employeeId}
        />
      )}
    </ul>
  );
};

SubEmployeeBox.propTypes = {
  authorizations: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  canManageAuths: PropTypes.bool,
  canViewAuths: PropTypes.bool,
  employeeId: PropTypes.string,
  otherAliasId: PropTypes.string,
  patientId: PropTypes.string,
  patientLinkForOppositeAlias: PropTypes.string,
  tagListProps: PropTypes.shape({
    tags: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    canViewTags: PropTypes.bool,
  }),
};

export default SubEmployeeBox;
