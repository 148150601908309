import moment from 'moment';
import pluralize from './pluralize';

/**
 * Produce a human-readable version of somebody's age.
 *
 * @param dateOfBirth
 * @param deceased {boolean}
 * @param dateOfDeath
 * @return {string|null} Formatted age, e.g. "52 years old"
 */
export default function formattedAge({ dateOfBirth, deceased, dateOfDeath }) {
  if (!dateOfBirth) return null;

  if (deceased && !dateOfDeath) {
    // Unknown age at time of death..
    return null;
  }

  // Date of birth is always stored as UTC midnight.
  dateOfBirth = moment.utc(dateOfBirth);

  const todayLocal = moment();
  const livesUntil = dateOfDeath
    ? // Date of death is always stored as UTC midnight.
      moment.utc(dateOfDeath)
    : // Construct a UTC midnight date using our local timezone current
      // year-month-date. This is needed because moment.utc()'s calendar date
      // may not reflect the local calendar date depending on time of day.
      moment.utc([todayLocal.year(), todayLocal.month(), todayLocal.date()]);

  const ageInYears = livesUntil.diff(dateOfBirth, 'years');
  const ageInMonths = livesUntil.diff(dateOfBirth, 'months');

  if (ageInYears > 1) {
    return `${ageInYears} years old`;
  } else {
    // Babes and toddlers get formatted in months. "My LO is 23 months!"
    return `${ageInMonths} ${pluralize(ageInMonths, 'month')} old`;
  }
}
