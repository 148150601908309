import PrintableFilters from '../PrintableFilters';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { CaseReportsContext } from '../Context';

export function mapProps({
  caseReportsFilterSelectionsStore,
  caseResultsFiltersStore,
}) {
  return {
    caseActionDateRangeSelection:
      caseReportsFilterSelectionsStore.actionsDateRange,
    caseActionDateAfter: caseReportsFilterSelectionsStore.actionsDateAfter,
    caseActionDateBefore: caseReportsFilterSelectionsStore.actionsDateBefore,
    caseActionSelections: caseReportsFilterSelectionsStore.displayActions,
    caseBundleSelections: caseReportsFilterSelectionsStore.displayBundle,
    casesByOptions: caseReportsFilterSelectionsStore.casesByOptions,
    casesBy: caseReportsFilterSelectionsStore.casesBy,
    caseCreatedBySelections: caseReportsFilterSelectionsStore.displayCreatedBy,
    caseOwnerSelections: caseReportsFilterSelectionsStore.displayOwner,
    caseTypeSelections: caseReportsFilterSelectionsStore.displayCategory,
    departmentSelections: caseReportsFilterSelectionsStore.displayDepartment,
    groupSelections: caseReportsFilterSelectionsStore.displayGroup,
    organizationSelections:
      caseReportsFilterSelectionsStore.displayOrganization,
    pivotOn: caseReportsFilterSelectionsStore.pivotOn,
    pivotOnOptions: caseReportsFilterSelectionsStore.pivotOnOptions,
    reportSettingsDateRange:
      caseReportsFilterSelectionsStore.reportSettingsDateRange,
    reportSettingsDateAfter:
      caseReportsFilterSelectionsStore.reportSettingsDateAfter,
    reportSettingsDateBefore:
      caseReportsFilterSelectionsStore.reportSettingsDateBefore,
    resolutionSelections: caseReportsFilterSelectionsStore.displayResolution,
    resolvedBySelections: caseReportsFilterSelectionsStore.displayResolvedBy,
    roleSelections: caseReportsFilterSelectionsStore.displayUserType,
    suspicionScoreOptions: caseResultsFiltersStore.suspicionScores,
    suspicionScoreSelection: caseReportsFilterSelectionsStore.suspicionScore,
    tagSelections: caseReportsFilterSelectionsStore.displayTags,

    caseOwnerExcluded: caseReportsFilterSelectionsStore.ownerExclude,
    caseCreatedByExcluded: caseReportsFilterSelectionsStore.createdByExclude,
    groupExcluded: caseReportsFilterSelectionsStore.groupExclude,
    caseBundleExcluded: caseReportsFilterSelectionsStore.bundleExclude,
    resolutionExcluded: caseReportsFilterSelectionsStore.resolutionExclude,
    resolvedByExcluded: caseReportsFilterSelectionsStore.resolvedByExclude,
    caseActionExcluded: caseReportsFilterSelectionsStore.actionsTakenExclude,
    suspicionScoreExcluded:
      caseReportsFilterSelectionsStore.suspicionScoreExclude,
    roleExcluded: caseReportsFilterSelectionsStore.userTypeExclude,
    tagExcluded: caseReportsFilterSelectionsStore.userTagsExclude,
  };
}

const PrintableFiltersContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseReportsContext,
    mapProps,
    PresentationalComponent: PrintableFilters,
  })
);

PrintableFiltersContainer.displayName = 'PrintableFiltersContainer';

export default PrintableFiltersContainer;
