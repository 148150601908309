/**
 * Weave the given separator between every item of the given array.
 *
 * @param array {*[]} Array to intersperse.
 * @param separator {function(number):*|*} Getter or raw value to place between
 *                                         each element of the array.
 * @return {*[]}
 */
export default function intersperse(array, separator) {
  if (!array || !array.slice || array.length === 0) {
    return [];
  }

  const getSeparator =
    'function' === typeof separator ? separator : () => separator;

  return array
    .slice(1)
    .reduce((interspersed, x, i) => [...interspersed, getSeparator(i), x], [
      array[0],
    ]);
}
