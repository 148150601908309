import React from 'react';
import PropTypes from 'prop-types';
import { decorate, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { Input, MultilineInput, Button } from '..';
import NotificationStore from '../../stores/NotificationStore';

const capitalizeFirst = text => text.charAt(0).toUpperCase() + text.slice(1);

const DeleteForm = class DeleteForm extends React.Component {
  // Observables
  reasonValid = false;
  hasEnteredReason = false;
  reasonValue = '';
  confirmText = null;
  submitted = false;

  setSubmitted = val => (this.submitted = val);

  onSubmit = e => {
    e.preventDefault();

    this.setSubmitted(true);

    this.props
      .submit(this.reasonValue.trim())
      .then(() => {
        const noun = this.props.remove ? 'removal' : 'deletion';
        const content = (
          <span>
            <i className="material-icons icon-check_circle" />
            {capitalizeFirst(this.props.subject)} {noun} successful.
          </span>
        );
        if (this.props.notify)
          NotificationStore.add({ level: 'success', content });
      })
      .fail(() => {
        const gerund = this.props.remove ? 'removing' : 'deleting';
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            An undefined error occurred {gerund} the {this.props.subject}.
            Please check connectivity or contact support.
          </span>
        );
        if (this.props.notify)
          NotificationStore.add({ level: 'warning', content });
      })
      .always(() => this.setSubmitted(false));
  };

  onReasonChange = value => {
    this.hasEnteredReason = true;
    this.reasonValue = value;
    this.reasonValid = value.trim() && value.trim().length > 0;
  };

  renderReason() {
    const { plural, reason, remove, subject } = this.props;
    if (reason) {
      const noun = remove ? 'removal' : 'deletion';
      const gerund = remove ? 'removing' : 'deleting';
      const reasonLabel = `Reason for ${capitalizeFirst(noun)}`;

      return (
        <div>
          <p>
            Please enter a reason for {gerund} {plural ? 'these' : 'this'}{' '}
            {subject}
            {plural ? 's' : ''}. This is required.
          </p>
          <ul>
            <MultilineInput
              label={reasonLabel}
              value={this.reasonValue}
              warn={this.hasEnteredReason && !this.reasonValid}
              onChange={this.onReasonChange}
              bordered
            />
          </ul>
        </div>
      );
    }
  }

  render() {
    const {
      description,
      modal,
      padding,
      plural,
      reason,
      subject,
      remove,
    } = this.props;

    const verb = remove ? 'remove' : 'delete';
    const noun = remove ? 'removal' : 'deletion';

    const actionLabel = `${capitalizeFirst(verb)} ${capitalizeFirst(subject)}${
      plural ? 's' : ''
    }`;
    const confirmLabel = `Enter "${verb.toUpperCase()}", case-sensitive`;

    const textValid = this.confirmText === verb.toUpperCase();
    const error = this.confirmText !== null && !textValid;
    const valid = textValid && (!reason || this.reasonValid);

    return (
      <div>
        <form
          className={classnames('form', { 'padd-all': padding })}
          onSubmit={this.onSubmit}
        >
          {modal && <h2>{actionLabel}</h2>}
          <p>{description}</p>
          <p>
            Type &quot;{verb.toUpperCase()}&quot;, case-sensitive, to confirm{' '}
            {noun} of {plural ? 'these' : 'this'} {subject}
            {plural ? 's' : ''}.
          </p>
          <ul>
            <Input
              autoFocus={modal}
              error={error}
              bordered
              name="confirm"
              label={confirmLabel}
              onChange={val => (this.confirmText = val)}
              value={this.confirmText || ''}
            />
          </ul>
          {this.renderReason()}
          <Button
            type="submit"
            value={actionLabel}
            disabled={!valid || this.submitted}
          />
        </form>
      </div>
    );
  }
};

decorate(DeleteForm, {
  reasonValid: observable,
  hasEnteredReason: observable,
  reasonValue: observable,
  confirmText: observable,
  submitted: observable,
  setSubmitted: action,
});

DeleteForm.propTypes = {
  description: PropTypes.string.isRequired,
  modal: PropTypes.bool,
  notify: PropTypes.bool,
  padding: PropTypes.bool,
  plural: PropTypes.bool,
  reason: PropTypes.bool,
  remove: PropTypes.bool,
  subject: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
};

DeleteForm.defaultProps = {
  modal: false,
  notify: true,
  padding: true,
  plural: false,
  reason: false,
  remove: false,
};

DeleteForm.displayName = 'DeleteForm';

export default observer(DeleteForm);
