import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DropdownList } from 'react-widgets';
import { toJS } from 'mobx';
import styles from './index.module.scss';
import {
  CASEFLOW_CUSTOM,
  CASEFLOW_LABEL,
  CASEFLOW_MULTISELECT_PLACEHOLDER,
  CASEFLOW_TEXT_FIELD,
  CASEFLOW_VALUE_FIELD,
} from '../stores/CaseCustomPrintStore/consts';
import ExclusionMultiselect from '../../../ui/Filters/ExclusionMultiselect';

const CaseflowPrintOptions = ({
  selectedCaseflowOption,
  caseflowOptions,
  caseflowFieldsToInclude,
  caseflowFields,
  handleCaseflowOnChange,
  setCaseflowFieldsToInclude,
  setCaseflowPrintOptionsForCaseStore,
  filteredAssessmentsForPrint,
}) => {
  useEffect(() => {
    if (caseflowFieldsToInclude)
      setCaseflowPrintOptionsForCaseStore(
        selectedCaseflowOption,
        caseflowFieldsToInclude,
        filteredAssessmentsForPrint
      );
  }, [
    setCaseflowPrintOptionsForCaseStore,
    caseflowFieldsToInclude,
    selectedCaseflowOption,
    filteredAssessmentsForPrint,
  ]);

  const isCustomizing = selectedCaseflowOption === CASEFLOW_CUSTOM;

  return (
    <div className={styles.dropdownWrapper}>
      <p className={styles.label}>{CASEFLOW_LABEL}</p>
      <DropdownList
        data={caseflowOptions}
        textField={CASEFLOW_TEXT_FIELD}
        valueField={CASEFLOW_VALUE_FIELD}
        value={selectedCaseflowOption}
        onChange={handleCaseflowOnChange}
      />
      {isCustomizing && (
        <ExclusionMultiselect
          label={CASEFLOW_MULTISELECT_PLACEHOLDER}
          options={caseflowFields}
          value={toJS(caseflowFieldsToInclude)}
          textField={CASEFLOW_TEXT_FIELD}
          valueField={CASEFLOW_VALUE_FIELD}
          onChange={setCaseflowFieldsToInclude}
          hideExclusion
          hideExclusionSpacing
        />
      )}
    </div>
  );
};

CaseflowPrintOptions.propTypes = {
  selectedCaseflowOption: PropTypes.string,
  caseflowOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  caseflowFields: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  caseflowFieldsToInclude: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  handleCaseflowOnChange: PropTypes.func.isRequired,
  setCaseflowFieldsToInclude: PropTypes.func.isRequired,
  setCaseflowPrintOptionsForCaseStore: PropTypes.func.isRequired,
  filteredAssessmentsForPrint: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
};

export default CaseflowPrintOptions;
