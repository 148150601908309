import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, decorate, autorun, reaction } from 'mobx';
import { DropdownList } from 'react-widgets';
import { SysAlertStore } from 'common';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MultilineInput } from '../../../ui';

const LEVELS = [
  {
    value: 'secondary',
    label: 'Secondary',
  },
  {
    value: 'success',
    label: 'Success',
  },
  {
    value: 'warning',
    label: 'Warning',
  },
  {
    value: 'danger',
    label: 'Danger',
  },
];

const SiteBanner = observer(
  class SiteBanner extends React.Component {
    // observables
    content;
    level;

    static propTypes = {
      onDirtyChange: PropTypes.func.isRequired,
    };

    constructor() {
      super();
      autorun(() => {
        this.level = SysAlertStore.level || LEVELS[0];
      });
      reaction(
        () => [this.dirty],
        ([dirty]) => {
          this.props.onDirtyChange(dirty);
        }
      );
    }

    // computed
    get dirty() {
      return (
        (this.content !== undefined &&
          this.content !== SysAlertStore.content) ||
        (this.level && this.level !== SysAlertStore.level)
      );
    }
    get currentLevel() {
      let level = LEVELS[0];
      if (SysAlertStore.level) {
        level = LEVELS.find(l => {
          return l.value === SysAlertStore.level;
        });
      }
      if (this.level) level = this.level;
      return level;
    }

    // methods
    handleSubmit(e) {
      e.preventDefault();
      if (!this.dirty) return;
      SysAlertStore.update(this.content, this.level);
    }
    handleContentChange = val => (this.content = val);
    handleDropdownChange = selected => (this.level = selected.value);

    render() {
      const { lastModified: modified, content, level } = SysAlertStore;

      let currentContent = content;
      if (this.content !== undefined) currentContent = this.content;

      let currentLevel = level;
      if (this.level !== undefined) currentLevel = this.level;

      return (
        <div id="settings__wrapper-Site_Banner" className="wrapper">
          <div className="card_header">
            <h1>Site Banner</h1>
            <div>
              <span
                className={classnames('prot-a', { muted: !this.dirty })}
                onClick={this.handleSubmit.bind(this)}
                role="button"
              >
                Save Changes
              </span>
            </div>
          </div>
          <div className="content">
            <div className="banner_header">
              <h2>HTML Content</h2>
              <DropdownList
                data={LEVELS}
                valueField="label"
                textField="value"
                value={currentLevel}
                onChange={this.handleDropdownChange}
                defaultValue={LEVELS[0]}
                className="dropdown"
              />
            </div>
            <MultilineInput
              label="Content"
              placeholder="Add your banner message"
              value={currentContent || ''}
              onChange={this.handleContentChange}
              bordered
            />
            <h5>
              Last Modified:{' '}
              {modified
                ? moment(modified).format('MM/DD/YYYY hh:mm:ss A')
                : 'Never'}
            </h5>
          </div>
        </div>
      );
    }
  }
);

decorate(SiteBanner, {
  content: observable,
  level: observable,
  dirty: computed,
  currentLevel: computed,
});

SiteBanner.displayName = 'SiteBanner';

export default SiteBanner;
