import React from 'react';
import PropTypes from 'prop-types';
import { DoubleLineCell } from '../../..';
import moment from 'moment';

const ModifiedByCell = ({ date, person }) => {
  const lineOne = date === null ? '-' : moment(date).format('l');
  const lineTwo =
    person === null
      ? '-'
      : `${person.firstName || 'Unknown'} ${person.lastName || 'Unknown'}`;

  return <DoubleLineCell lineOne={lineOne} lineTwo={lineTwo} />;
};

export default ModifiedByCell;

ModifiedByCell.propTypes = {
  date: PropTypes.string,
  person: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

ModifiedByCell.defaultProps = {
  date: null,
  person: null,
};
