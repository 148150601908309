import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './printableSectionHeader.module.scss';

export const PrintableSectionHeader = ({ icon, title }) => {
  return (
    <div className={styles.printableSectionHeader}>
      <i className={classnames('material-icons', styles.icon, icon)} />
      {title}
    </div>
  );
};

PrintableSectionHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PrintableSectionHeader;
