import React from 'react';
import { observer } from 'mobx-react';

import TimeEntryStore from '../../../stores/TimeEntryStore';
import ChartStore from '../../../stores/ChartStore';
import EventDetailedStore from '../../../stores/EventDetailedStore';

import TimeEntryLayer from '../TimeEntryLayer';

export function mapProps({ TimeEntryStore, ChartStore, EventDetailedStore }) {
  return {
    loading: TimeEntryStore.loading,
    timeEntries: TimeEntryStore.timeEntries,
    brushedXScale: ChartStore.brushedXScale,
    margin: ChartStore.margin,
    detailView: EventDetailedStore.detailView,
  };
}

const TimeEntryLayerContainer = observer(() => (
  <TimeEntryLayer
    {...mapProps({
      TimeEntryStore,
      ChartStore,
      EventDetailedStore,
    })}
  />
));

export default TimeEntryLayerContainer;
