import React from 'react';
import PropTypes from 'prop-types';
import ValueOrUnknown from '../ValueOrUnknown';
import intersperse from '../../../utils/intersperse';

const FormattedEncounterDepartment = ({ encounter }) => {
  let name = encounter && encounter.department && encounter.department.name;
  if (name && encounter.department.other && encounter.department.other.facility)
    name += ` (${encounter.department.other.facility})`;
  const department = intersperse(
    [encounter && encounter.type, name].filter(x => x),
    ', '
  );

  return <ValueOrUnknown>{department}</ValueOrUnknown>;
};

FormattedEncounterDepartment.propTypes = {
  encounter: PropTypes.shape({
    department: PropTypes.shape({
      name: PropTypes.string,
      other: PropTypes.shape({
        facility: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
  }),
};

FormattedEncounterDepartment.defaultProps = {
  encounter: null,
};

export default FormattedEncounterDepartment;
