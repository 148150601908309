import React from 'react';
import Field from '../Field';
import PropTypes from 'prop-types';

const EncounterType = ({ encounter }) => {
  const labels = [];
  if (encounter.type) labels.push(encounter.type);
  if (encounter.department) {
    let name = encounter.department.name;
    if (
      name &&
      encounter.department.other &&
      encounter.department.other.facility
    ) {
      name += ` (${encounter.department.other.facility})`;
    }
    labels.push(name);
  }

  return <Field label="Encounter" width="inherit" value={labels.join(', ')} />;
};

EncounterType.propTypes = {
  encounter: PropTypes.object,
};

export default EncounterType;
