import React from 'react';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import Tooltip from '../../ui/Tooltip';
import { scoreWithoutTooltip } from '../../ui/SuspicionScore';

const formatScores = (scores, length) => {
  if (length > 4) {
    return (
      <Tooltip content={scores} key={scores}>
        <span>{`${scores.slice(0, 3).join('')}...`}</span>
      </Tooltip>
    );
  }
  return <span>{scores}</span>;
};

const SuspicionRow = ({ analyticAssessments }) => {
  const length = analyticAssessments.length;
  if (length === 0) return null;
  const scores = scoreWithoutTooltip(analyticAssessments);
  return formatScores(scores, length);
};

SuspicionRow.propTypes = {
  analyticAssessments: MobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      suspicionScore: PropTypes.number,
      minEventDate: PropTypes.string,
      maxEventDate: PropTypes.string,
    })
  ),
};

SuspicionRow.defaultProps = {
  analyticAssessments: [],
};

export default SuspicionRow;
