import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { CardBadge, CardHeader } from '../../../ui/Card';
import { HalUtils } from 'common';
import CaseLink from '../../../ui/CaseLink';
import UserTagsBadge from './UserTagsBadge';
import Anchor from '../../../ui/Anchor';

const UserCardHeader = ({
  cases,
  fullName,
  id,
  instance,
  openLinkNew,
  source,
  tags,
}) => {
  const title = (
    <Anchor
      tag="a"
      href={HalUtils.buildActivityViewLink({ type: 'user', userId: id })}
      target={openLinkNew ? '_blank' : '_self'}
    >
      {fullName}
    </Anchor>
  );

  let casesLink;
  const caseCount = cases?.length;

  if (caseCount) {
    let caseId;
    // only pull the case ID if there's a single case
    if (caseCount === 1) caseId = HalUtils.getId(cases[0]);

    const text = `${caseCount} Existing Case${caseCount === 1 ? '' : 's'}`;

    casesLink = (
      <CaseLink active user={id} cases={caseCount} caseId={caseId}>
        {text}
      </CaseLink>
    );
  }

  return (
    <CardHeader instance={instance} source={source} title={title}>
      {casesLink}
      {Boolean(tags?.length) && <UserTagsBadge tags={tags} />}
      <CardBadge title="EMR User" label="user" icon="icon-local_hospital" />
    </CardHeader>
  );
};

UserCardHeader.propTypes = {
  cases: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  fullName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  instance: PropTypes.string,
  openLinkNew: PropTypes.bool,
  source: PropTypes.string,
  tags: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
};

UserCardHeader.defaultProps = {
  cases: [],
  openLinkNew: false,
  tags: [],
};

export default UserCardHeader;
