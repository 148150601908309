import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useQueryFromURI } from '../hooks/useQueryFromURI';
import { DEFAULT_QUERY } from '../stores/DeliveryStore';
import DataFeedDeliveryTableContainer from '../DataFeedDeliveryTableContainer';
import ActionNav from './ActionNav';
import DataFeedDeliveryAsideContainer from '../DataFeedDeliveryAsideContainer';
import { Loader } from '../../ui';
import styles from './index.module.scss';

const DataFeedDeliveryView = ({
  setQueryFromURI,
  query,
  history,
  totalElements,
  failure,
  failureStatus,
}) => {
  useQueryFromURI(
    query,
    setQueryFromURI,
    history,
    '/data-feed/delivery',
    DEFAULT_QUERY
  );

  return (
    <Loader
      // so the loader can properly block the page w/ status code
      loaded={true}
      failure={failure}
      failureStatus={failureStatus}
    >
      <section className={classnames('fadeIn', styles.container)}>
        <DataFeedDeliveryAsideContainer />
        <div className={styles.body}>
          <ActionNav count={totalElements} />
          <DataFeedDeliveryTableContainer />
        </div>
      </section>
    </Loader>
  );
};

DataFeedDeliveryView.propTypes = {
  query: PropTypes.shape({}),
  history: PropTypes.shape({}),
  setQueryFromURI: PropTypes.func,
  totalElements: PropTypes.number,
  failure: PropTypes.string,
  failureStatus: PropTypes.number,
};

export default DataFeedDeliveryView;
