import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Anchor from '../Anchor';
import pluralize from '../../utils/pluralize';
import styles from './index.module.scss';
import cn from 'classnames';

const getText = (isHidden, itemLength, label, pluralLabel, customLabel) => {
  const showHideText = isHidden ? `Show ${itemLength}` : 'Hide';
  return `${showHideText} ${customLabel || 'Additional'} ${pluralize(
    itemLength,
    label,
    pluralLabel
  )}`;
};

const ShowHideMore = ({
  children,
  label,
  pluralLabel,
  numberToShow,
  customLabel,
}) => {
  const [isHidden, toggleVisiblity] = useState(true);
  const firstChildren = React.Children.toArray(children).slice(0, numberToShow);
  const lastChildren = React.Children.toArray(children).slice(numberToShow);
  return (
    <div className={styles.wrapper}>
      {firstChildren}
      <div className={cn({ hidden: isHidden })}>{lastChildren}</div>
      {!!lastChildren.length && (
        <Anchor
          disabled={true}
          className={cn(styles.span, 'forScreen')}
          tag="span"
          onClick={() => toggleVisiblity(!isHidden)}
        >
          {getText(
            isHidden,
            lastChildren.length,
            label,
            pluralLabel,
            customLabel
          )}
        </Anchor>
      )}
    </div>
  );
};

ShowHideMore.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  customLabel: PropTypes.string,
  pluralLabel: PropTypes.string,
  numberToShow: PropTypes.number,
};

ShowHideMore.defaultProps = {
  numberToShow: 2,
  customLabel: '',
};

export default ShowHideMore;
