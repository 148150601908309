import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { CompareViewContext } from '../../../Context';
import MultiRecordEntityColumn from '../../../MultiRecordEntityColumn';
import {
  employeeColumnMapProps,
  employeeSecondColumnMapProps,
} from '../../mapProps';

export const CompareViewEmployeeColumnContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CompareViewContext,
    mapProps: employeeColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);

export const CompareViewEmployeeColumnContainerSecond = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CompareViewContext,
    mapProps: employeeSecondColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);
