import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { ReasonsType, ClusterReasonsType } from '../../types/reasonsTypes';
import moment from 'moment';
import ReasonStats from './ReasonStats';
import styles from './index.module.scss';

const IncidentStats = ({
  reasons,
  clusterReasons,
  lastUpdatedByAG,
}: {
  reasons?: ReasonsType[];
  clusterReasons?: ClusterReasonsType[];
  lastUpdatedByAG: string;
}) => {
  return (
    <div className="reason-stats-container">
      <ReasonStats reasons={reasons} clusterReasons={clusterReasons} />
      <div>
        {lastUpdatedByAG && (
          <div className={styles.reasonFooter}>
            {' '}
            Assessments and suspicion score generated based on data available:{' '}
            {moment.utc(lastUpdatedByAG).format('l')}{' '}
          </div>
        )}
      </div>
    </div>
  );
};

IncidentStats.propTypes = {
  reasons: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  clusterReasons: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  lastUpdatedByAG: PropTypes.string,
};

IncidentStats.defaultProps = {
  reasons: [],
  clusterReasons: [],
  lastUpdatedByAG: null,
};

export default IncidentStats;
