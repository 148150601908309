import elementResizeEvent from 'element-resize-event';

/**
 * Wrapper class that alerts you of changes to an element's sizing.
 * If available, we use the ResizeObserver.
 * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
 *
 * Otherwise we fall back to the element-resize-event library.
 *
 * Historical context: the element-resize-event library is buggy in Chrome.
 * It doesn't always trigger appropriately. The official ResizeObserver is
 * supported in Chrome, so this allows us to work around the bug.
 */
export default class ResizeWatcher {
  /**
   * Constructor.
   *
   * @param {HTMLElement} element DOM element to watch.
   * @param {Function} handler Handler to invoke when its size changes.
   */
  constructor(element, handler) {
    this.element = element;

    if (window.ResizeObserver) {
      this.resizeObserver = new window.ResizeObserver(handler);
      this.resizeObserver.observe(this.element);
    } else {
      elementResizeEvent(this.element, handler);
    }
  }

  /**
   * Stops watching. Used when you are unmounting a component or otherwise
   * no longer care about sizing updates.
   *
   * @return {void}
   */
  off() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.element);
    } else {
      elementResizeEvent.unbind(this.element);
    }
  }
}
