import { computed, decorate, reaction } from 'mobx';
import GeneralSettingsStore from '../GeneralSettingsStore';
import { LoginStore } from 'common';

class UserAccountLimitSettingsStore extends GeneralSettingsStore {
  category = 'services.read';
  key = 'general.userAccountLimitSettings';

  constructor() {
    super();

    reaction(
      () => [LoginStore.id, this.valid],
      () => {
        this.refresh();
      },
      {
        fireImmediately: true,
      }
    );
  }

  // Computed
  get userAccountLimitSettings() {
    const { value } = this.response || {};
    // the value is saved as a string in mongo (non-existent represents unlimited)
    return value ? Number(value) : null;
  }

  updateSettings = editableLimit => {
    // only update on new limits
    if (this.userAccountLimitSettings !== editableLimit)
      this.update(editableLimit);
  };
}

decorate(UserAccountLimitSettingsStore, {
  userAccountLimitSettings: computed,
});

export default new UserAccountLimitSettingsStore();
