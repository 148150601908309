/**
 * Pluralize a term if needed.
 *
 * @param numItems {number}     If 1, use singular term. Otherwise use plural term.
 * @param singularTerm {string} Singular term.
 * @param [pluralTerm] {string} Plural term. If missing, we add "s" to the singular term.
 *
 * @return {string} Term in correct form.
 */
export default function pluralize(numItems, singularTerm, pluralTerm) {
  if (!pluralTerm) {
    if (singularTerm.slice(-1) === 's') {
      pluralTerm = singularTerm + 'es';
    } else {
      pluralTerm = singularTerm + 's';
    }
  }
  return !numItems || numItems > 1 ? pluralTerm : singularTerm;
}
