import { AuthorizationClient, InfoCacheStore, PermissionStore } from 'common';

class EmployeeAuthorizationsStore extends InfoCacheStore {
  sort = ['requestedEnd,desc'];

  fetchData(id) {
    const params = { sort: this.sort, user: id };
    return AuthorizationClient.getReport(params);
  }

  hasPermission() {
    return PermissionStore.has('ACCESS_AUTH_SEARCH');
  }
}

export default new EmployeeAuthorizationsStore();
