import { CaseCustomPrintContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import AssessmentsPrintOptions from '../AssessmentsPrintOptions';

export function mapProps({ assessmentsPrintOptionsStore }) {
  return {
    selectedMainOption: assessmentsPrintOptionsStore.selectedMainOption,
    selectedAssessmentsTimeRanges:
      assessmentsPrintOptionsStore.selectedAssessmentsTimeRanges,
    mainOptions: assessmentsPrintOptionsStore.mainOptions,
    timeRangeOptions: assessmentsPrintOptionsStore.timeRangeOptions,
    isCustomizing: assessmentsPrintOptionsStore.isCustomizing,
    timeRangesLabel: assessmentsPrintOptionsStore.timeRangesLabel,
    filteredAssessmentsCount:
      assessmentsPrintOptionsStore.filteredAssessmentsCount,
    handleMainOptionOnChange:
      assessmentsPrintOptionsStore.handleMainOptionOnChange,
    handleTimeRangesOnChange:
      assessmentsPrintOptionsStore.handleTimeRangesOnChange,
    type: assessmentsPrintOptionsStore.type,
    hasClusterReasons: assessmentsPrintOptionsStore.hasClusterReasons,
  };
}

const AssessmentsPrintOptionsContainer = observerWrappedContainerFactory({
  Context: CaseCustomPrintContext,
  mapProps,
  PresentationalComponent: AssessmentsPrintOptions,
});

export default AssessmentsPrintOptionsContainer;
