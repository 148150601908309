import React from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as mobxPropTypes } from 'mobx-react';

import { HalUtils, ScrollPager } from 'common';

import Loader from '../Loader';
import StaggeredList from '../StaggeredList';

import UserCardContainer from '../../people/cards/UserCardContainer';
import PatientCardContainer from '../../people/cards/PatientCardContainer';

class ResultsList extends React.Component {
  static propTypes = {
    rowHeight: PropTypes.number,
    store: PropTypes.shape({
      setUser: PropTypes.func,
      setPatient: PropTypes.func,
      loading: PropTypes.bool,
      selectedUser: PropTypes.shape({
        id: PropTypes.string,
      }),
      results: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape()),
      selectedPatient: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    rowHeight: 134,
  };

  state = {
    pagerElement: null,
  };

  onSelectPatient(selected, patient) {
    this.props.store.setPatient(selected ? patient : null);
  }

  onSelectUser(selected, user) {
    this.props.store.setUser(selected ? user : null);
  }

  get renderResults() {
    return this.props.store.results.map(i => {
      const { store } = this.props;
      if (HalUtils.getEndpoint(i) === 'users') {
        return (
          <li key={i.id} data-cy="">
            <UserCardContainer
              disabled={store.selectedUser && store.selectedUser?.id !== i.id}
              user={i}
              onSelect={value => this.onSelectUser(value, i)}
              openLinkNew
              selectable
              selected={store.selectedUser?.id === i.id}
            />
          </li>
        );
      } else {
        return (
          <li key={i.id}>
            <PatientCardContainer
              disabled={
                store.selectedPatient && store.selectedPatient?.id !== i.id
              }
              patient={i}
              onSelect={value => this.onSelectPatient(value, i)}
              openLinkNew
              selectable
              selected={store.selectedPatient?.id === i.id}
            />
          </li>
        );
      }
    });
  }

  render() {
    const { store, rowHeight } = this.props;
    const { pagerElement } = this.state;

    return (
      <div
        className="list-contained"
        ref={node => {
          if (node && pagerElement !== node)
            this.setState({ pagerElement: node });
        }}
      >
        <ScrollPager.Component
          elt={pagerElement}
          store={store}
          rowHeight={rowHeight}
        />
        <Loader loaded={!store.loading}>
          <StaggeredList
            className="card_holder"
            toAdd="fadeIn"
            drag={5}
            store={store}
          >
            {this.renderResults}
          </StaggeredList>
        </Loader>
      </div>
    );
  }
}

export default observer(ResultsList);
