import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Cell } from 'fixed-data-table-2';

const DateCell = ({ data, timezone }) => {
  return (
    <Cell data-cy="date-cell">
      {data.eventDate.tz(timezone).format('l HH:mm:ss z')}
    </Cell>
  );
};

DateCell.propTypes = {
  data: PropTypes.shape({
    eventDate: PropTypes.instanceOf(moment).isRequired,
  }).isRequired,
  timezone: PropTypes.string,
};

export default DateCell;
