import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { SingleViewContext } from '../../../Context';
import MultiRecordInformationDropdown from '../../../../ui/MultiRecordInformationDropdown';
import {
  patientDropdownMapProps,
  patientSecondDropdownMapProps,
} from '../../mapProps';

export const SingleViewPatientDropdownContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: SingleViewContext,
    mapProps: patientDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);

export const SingleViewPatientDropdownContainerSecond = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: SingleViewContext,
    mapProps: patientSecondDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);
