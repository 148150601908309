import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const CaseIntegrationLock = ({ text }) => (
  <div className={styles.caseIntegrationIcon}>
    <i className="material-icons icon-lock"></i>
    <p className={styles.alignText}>{text}</p>
  </div>
);

CaseIntegrationLock.propTypes = {
  text: PropTypes.string,
};

export default CaseIntegrationLock;
