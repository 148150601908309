import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

function ExpireOption({ expire }) {
  return (
    <>
      <div>
        Expire as of today. Already expired registrations will be unchanged
      </div>
      <button className={styles.fullWidth} onClick={expire}>
        Expire Registrations
      </button>
    </>
  );
}

ExpireOption.propTypes = {
  expire: PropTypes.func.isRequired,
};

export default ExpireOption;
