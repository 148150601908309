import * as d3 from 'd3';
import moment from 'moment';

import EventDetailedStore from '../../stores/EventDetailedStore';
import ChartStore from '../../stores/ChartStore';

/**
 * Layer responsible for drawing metalogs. A sub-component of RowLayer.
 */
class LogLayer {
  clickedEvent(metalog) {
    const curYKey = ChartStore.selectedKey;
    const newYKey = ChartStore.yKey(metalog);
    const delay = curYKey === newYKey ? 0 : 700;
    setTimeout(() => {
      EventDetailedStore.focusOn(metalog);
    }, delay);
  }

  render(selection) {
    const me = this;
    const transition = ChartStore.scrollTransition;
    // dates are drawn on top of the row structure
    const logRect = selection.selectAll('g.metalog').data(
      d => d.values || [],
      d => d.id
    );
    // new nodes
    this.update(logRect, transition);

    const rects = logRect
      .enter()
      .append('g')
      .classed('metalog', true)
      .classed('aliased', d => Boolean(d.user.aliasId));
    rects
      .append('rect')
      .attr('rx', '4px')
      .attr('height', '8px')
      .on('click', d => {
        me.clickedEvent(d);
      })
      .each(function(d) {
        // will not draw circle if endTime is before startTime
        const me = d3.select(this);
        const x0 = ChartStore.brushedXScale(d.startTimeDate);
        const x1 = ChartStore.brushedXScale(d.endTimeDate);
        const width = x1 - x0 + 10; // add 8 so that the rectangle appears 'inclusive'
        me.attr('width', width);
      });

    this.update(rects, false);

    this.updateTooltips(logRect.selectAll('rect'));

    logRect.exit().remove();
  }

  update(selection, transition) {
    transition = transition !== false;
    selection
      .classed('flagged', d => EventDetailedStore.isFlagged(d))
      .classed('drawer', d => {
        if (!EventDetailedStore.detailFocus) return false;
        const drawer = EventDetailedStore.detailFocus;
        const start = drawer.dateOfAccess || drawer.startTime;
        const end = drawer.dateOfAccess || drawer.endTime;
        const drawerY = ChartStore.yKey(drawer);
        const myY = ChartStore.yKey(d);
        return drawerY === myY && d.startTime <= start && end <= d.endTime;
      });

    if (transition) selection = selection.transition().duration(500);

    selection.attr('transform', d => {
      const date = moment(d.startTime).toDate();
      const x = ChartStore.brushedXScale(date) + (ChartStore.margin.left - 4);
      const y = ChartStore.rowHeight / 2 - 3; // minus a little less than half of the dot height so that the dots show in the middle of the row
      const trans = `translate(${x},${y})`;
      return trans;
    });
    selection.select('rect').attr('width', d => {
      const start = moment(d.startTime).toDate();
      const end = moment(d.endTime).toDate();
      const x0 = ChartStore.brushedXScale(start);
      const x1 = ChartStore.brushedXScale(end);
      return x1 - x0 + 8;
    });
  }

  updateTooltips(rects) {
    rects.each(function(d) {
      const me = d3.select(this);
      const diff = moment(d.endTimeDate).diff(d.startTimeDate, 'm');
      const duration = moment.duration(diff, 'm').humanize();
      const count = d.eventCount || 'Multiple';
      // update the tippy content and prevent duplicated tippy
      if (me.node()?._tippy) me.node()._tippy.destroy();
      me.attr(
        'data-tippy-content',
        `${count} event${count === 1 ? '' : 's'} over ${duration}`
      );
      me.attr('data-tippy-animation', 'fade');
      me.attr('data-tippy-arrow', 'true');
      me.attr('data-tippy-placement', 'top');
      me.attr('data-tippy-followCursor', 'true');
    });
  }
}

export default new LogLayer();
