import React from 'react';
import PropTypes from 'prop-types';
import Field from '../../../ui/Field';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PropTypes as mobxPropTypes } from 'mobx-react';

const PatientCardFields = ({
  addresses,
  canCreateAuths,
  canViewAuths,
  children,
  dateOfBirth,
  existingAuths,
  id,
  medicalRecordNumbers,
  showAuthInfo,
}) => {
  // 1. DOB
  let dob;
  if (dateOfBirth) {
    dob = moment(dateOfBirth)
      .utc()
      .format('l');
  }

  // 2. MRN(s)
  let mrn;
  if (medicalRecordNumbers?.length === 1) mrn = medicalRecordNumbers[0];
  else if (medicalRecordNumbers?.length > 1)
    mrn = `${medicalRecordNumbers.length} MRNs`;

  // 3. Address
  let addressField;
  // ignore patient's work addresses
  const filteredAddresses = (addresses || []).filter(
    a => !/work/i.test(a.type)
  );

  if (filteredAddresses?.length) {
    const addr = filteredAddresses[filteredAddresses.length - 1];
    const label = `Address 1 ${addr.type ? `(${addr.type})` : ''}`;
    const value = [addr.address1, addr.city, addr.state, addr.zip]
      .filter(v => v)
      .join(', ');

    addressField = (
      <Field
        label={label}
        value={value}
        length={addresses.length}
        width="wider3"
      />
    );
  }

  // 4. Authorized Accesses
  let authField;
  if (showAuthInfo && canViewAuths) {
    let existing = <span>None</span>;
    if (existingAuths)
      existing = <Link to={`/authorizations?patient=${id}`}>View</Link>;

    let create;
    if (canCreateAuths) {
      create = (
        <span>
          &nbsp;|&nbsp;
          <Link to={`/authorizations/create/patient/${id}`}>Add New</Link>
        </span>
      );
    }

    authField = (
      <Field
        label="Authorized Accesses"
        value={
          <>
            {existing}
            {create}
          </>
        }
        rightAlign
      />
    );
  }

  return (
    <ul>
      <Field label="DOB" value={dob} />
      <Field label="Patient MRN" value={mrn} width="wider1" />
      {addressField}
      {authField}
      {children}
    </ul>
  );
};

PatientCardFields.propTypes = {
  addresses: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      address1: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
    })
  ),
  canCreateAuths: PropTypes.bool,
  canViewAuths: PropTypes.bool,
  children: PropTypes.node,
  dateOfBirth: PropTypes.string,
  existingAuths: PropTypes.bool,
  id: PropTypes.string.isRequired,
  medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.string
  ),
  showAuthInfo: PropTypes.bool,
};

PatientCardFields.defaultProps = {
  addresses: [],
  canCreateAuths: false,
  canViewAuths: false,
  children: null,
  dateOfBirth: null,
  existingAuths: false,
  medicalRecordNumbers: [],
  showAuthInfo: false,
};

export default PatientCardFields;
