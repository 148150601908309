import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import {
  DateHelpers,
  RouterContainer,
  AppUserStore,
  convertMultiValue,
} from 'common';

import NewCasesChart from '../NewCasesChart';
import CasesReportCountStore from '../stores/CasesReportCountStore';
import TimePlusCalendarSelector from '../../ui/TimePlusCalendarSelector';
import AppUserSelect from '../AppUserSelect';

const {
  TIME_PERIOD_VALUES: {
    ALL,
    DAY,
    WEEK,
    MONTH,
    NINETY_DAYS,
    QUARTER,
    YEAR,
    CUSTOM,
  },
} = DateHelpers;

function calendarClick(date, valid, beforeOrAfter) {
  if (valid) {
    const params = Object.assign({}, RouterContainer.query || {}, {
      [`created${beforeOrAfter}`]: moment(date).format('YYYY-MM-DD'),
    });
    if (params.group) params.group = convertMultiValue(params.group);
    RouterContainer.go('/', params);
  }
}

/**
 * Primary component for the display of reports for cases which were recently
 * created and closed.
 * @param {Object} props - the component's properties
 * @return {Object} a React DOM Object
 */
const CaseReportsNew = observer(
  class extends React.Component {
    /**
     * Event handler for the onChange event fired by the dropdownlist of
     * available date ranges. Bound to the class instance context.
     * @param {Object} e - the Event that triggered the handler
     */
    onChangeDropdown = e => {
      const params = this.combineParams({
        newCasesRange: e.value,
      });
      if (params.group) params.group = convertMultiValue(params.group);
      localStorage.setItem('newCasesRange', e.value);
      RouterContainer.go('/', params);
    };

    /**
     * Utility method to help combine new query parameters with existing query
     * parameters.
     * @param {Object} params - the new query parameters to be applied
     * @return {Object} the parameters Object
     */
    combineParams(params) {
      return Object.assign({}, RouterContainer.query || {}, params);
    }

    buildDates(start, end) {
      const checkedStart = start ? moment(start) : moment().subtract(1, 'year');
      const checkedEnd = end ? moment(end) : moment();
      if (localStorage.getItem('newCasesRange') === 'custom') return null;
      return (
        <div className="currentDates">
          <i className="material-icons icon-date_range" />
          <span>
            {start === null && end === null
              ? 'All time'
              : `${checkedStart.format('MMM DD, YYYY')} - ${checkedEnd.format(
                  'MMM DD, YYYY'
                )}`}
          </span>
        </div>
      );
    }

    renderDate() {
      return CasesReportCountStore.getRange();
    }

    render() {
      return (
        <section
          className="home-sub-section home__cases-new"
          data-cy="new-cases"
        >
          <TimePlusCalendarSelector
            createdInformation={[
              RouterContainer.query.createdAfter
                ? RouterContainer.query.createdAfter
                : CasesReportCountStore.customStart,
              RouterContainer.query.createdBefore
                ? RouterContainer.query.createdBefore
                : CasesReportCountStore.customEnd,
            ]}
            label="Date Range"
            defaultValue={localStorage.getItem('newCasesRange') || WEEK}
            onCalendarChange={calendarClick}
            onChangeDropdown={this.onChangeDropdown}
            hiddenCalendar={localStorage.getItem('newCasesRange') === CUSTOM}
            options={[
              ALL,
              DAY,
              WEEK,
              MONTH,
              NINETY_DAYS,
              QUARTER,
              YEAR,
              CUSTOM,
            ]}
            className="dropdown--inverse"
          />
          {this.buildDates(this.renderDate()[0], this.renderDate()[1])}
          <span>
            <h2>New Cases</h2>
            <AppUserSelect
              paramName="newOwner"
              value={CasesReportCountStore.newOwner}
              users={AppUserStore.enabledActiveCaseOwners}
              inverted
            />
          </span>
          <NewCasesChart />
        </section>
      );
    }
  }
);

CaseReportsNew.displayName = 'CaseReportsNew';

export default CaseReportsNew;
