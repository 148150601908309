import React from 'react';
import IncidentAdvancedFilters from '../IncidentAdvancedFilters';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import IncidentReportFilterStore from '../stores/IncidentReportFilterStore';
import { observer } from 'mobx-react';

export function mapProps({ history, IncidentReportFilterStore }) {
  return {
    isOpen: IncidentReportFilterStore.isAdvancedFiltersOpen,
    toggleOpen: () =>
      history.push(IncidentReportFilterStore.nextAdvancedFiltersURL),
    history,
    filters: IncidentReportFilterStore.allFilterOptions,
    queryCriteria: IncidentReportFilterStore.query,
  };
}

const IncidentAdvancedFiltersContainer = withRouterAndQuery(
  observer(({ history }) => (
    <IncidentAdvancedFilters
      {...mapProps({
        history,
        IncidentReportFilterStore,
      })}
    />
  ))
);

IncidentAdvancedFiltersContainer.displayName =
  'IncidentAdvancedFiltersContainer';

export default IncidentAdvancedFiltersContainer;
