import React from 'react';
import { Tooltip } from '../index';
import { DateHelpers } from 'common';
import moment from 'moment';
import classnames from 'classnames';
import styles from './index.module.scss';
import ListItem from '../SideList/ListItem';
import PropTypes from 'prop-types';

const EncounterDateTime = ({ encounter, showDuration, timezone }) => {
  let dates = DateHelpers.formatDates(encounter, timezone);

  // 4th element is duration
  if (!showDuration) dates = dates.slice(0, 3);
  const dateString = dates.join(' ');

  const incorrectData = (
    <div>
      {dateString}
      <span alt="Possible incorrect data">
        <Tooltip content="Possible incorrect data">
          <i
            className={classnames(
              'material-icons',
              'icon-report_problem',
              styles.reportIcon
            )}
          />
        </Tooltip>
      </span>
    </div>
  );
  return (
    <ListItem
      label="Date/Time"
      value={
        encounter.endTime < encounter.startTime ? incorrectData : dateString
      }
    />
  );
};

EncounterDateTime.propTypes = {
  encounter: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
  showDuration: PropTypes.bool,
  timezone: PropTypes.string,
};

EncounterDateTime.defaultProps = {
  showDuration: false,
};

export default EncounterDateTime;
