import { CaseCustomPrintContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import IncidentsCustomFilterOptions from '../IncidentsCustomFilterOptions';

export function mapProps({ incidentsPrintOptionsStore }) {
  return {
    timeRangeOptions: incidentsPrintOptionsStore.timeRangeOptions,
    statusOptions: incidentsPrintOptionsStore.statusOptions,
    typeOptions: incidentsPrintOptionsStore.typeOptions,
    selectedIncidentTimeRanges:
      incidentsPrintOptionsStore.selectedIncidentTimeRanges,
    selectedIncidentTypes: incidentsPrintOptionsStore.selectedIncidentTypes,
    selectedIncidentStatuses:
      incidentsPrintOptionsStore.selectedIncidentStatuses,
    handleStatusesOnChange: incidentsPrintOptionsStore.handleStatusesOnChange,
    handleTimeRangesOnChange:
      incidentsPrintOptionsStore.handleTimeRangesOnChange,
    handleTypesOnChange: incidentsPrintOptionsStore.handleTypesOnChange,
    timeRangesLabel: incidentsPrintOptionsStore.timeRangesLabel,
    statusesLabel: incidentsPrintOptionsStore.statusesLabel,
    typesLabel: incidentsPrintOptionsStore.typesLabel,
  };
}

const IncidentsCustomFilterOptionsContainer = observerWrappedContainerFactory({
  Context: CaseCustomPrintContext,
  mapProps,
  PresentationalComponent: IncidentsCustomFilterOptions,
});

export default IncidentsCustomFilterOptionsContainer;
