import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const SideList = ({ children }) => {
  return <ul className={styles.container}>{children}</ul>;
};

SideList.propTypes = {
  children: PropTypes.node,
};

export default SideList;
