import { autorun, decorate, observable } from 'mobx';
import $ from 'jquery';

import { LoginStore, BaseURL } from 'common';

class ActivityDescriptionStore {
  constructor() {
    // this will run whenever loggedIn changes
    autorun(() => {
      if (this.loaded || !LoginStore.loggedIn) return;
      this.load();
    });
  }

  // Observables
  defsMap = {};

  load() {
    const url = BaseURL.service('descriptions/activity');

    $.ajax({
      url,
      dataType: 'json',
    }).then(r => {
      this.defsMap = r;
      this.loaded = true;
    });
  }

  get = k => {
    if (k === undefined) return undefined;
    return this.defsMap[k] || `Could not find definition for activity ${k}`;
  };
}

decorate(ActivityDescriptionStore, {
  defsMap: observable,
});

export default new ActivityDescriptionStore();
