/**
 * Factory function for generating a comparing function.
 * Supports comparing numbers or strings.
 * Prioritized items will be deemed less than any non-prioritized items.
 *
 * @param prioritizedItems
 * @return {function(*, *): number}
 */

export const compareAndPrioritize = (prioritizedItems = []) => {
  if (!Array.isArray(prioritizedItems)) prioritizedItems = [prioritizedItems];

  return (a, b) => {
    const specialDiff = [a, b]
      .map(i => prioritizedItems.indexOf(i))
      .map(i => (i === -1 ? Number.MAX_SAFE_INTEGER : i))
      .reduce((i, j, index) => (index === 0 ? j : i - j), 0);

    if (specialDiff > 0) return 1;
    if (specialDiff < 0) return -1;

    if (typeof a !== typeof b) {
      return 0;
    }

    if ('number' === typeof a) {
      return a - b;
    }

    if ('string' === typeof a) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }

    // Unsupported type.
    return 0;
  };
};

export const compare = compareAndPrioritize();
