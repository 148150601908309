import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Encounter from '../../../ui/Encounter';
import EncounterStore from '../../../stores/EncounterStore';

const LoadableEncounter = observer(
  class LoadableEncounter extends React.Component {
    static propTypes = {
      data: PropTypes.shape({
        id: PropTypes.string,
      }),
      store: PropTypes.shape({
        complete: PropTypes.bool,
        selected: PropTypes.bool,
        performingBulkAction: PropTypes.bool.isRequired,
        percentComplete: PropTypes.number.isRequired,
      }),
      action: PropTypes.string,
      hidden: PropTypes.bool,
    };

    componentDidMount() {
      const { data } = this.props;
      if (data && data.id) EncounterStore.fetch(data.id);
    }

    componentDidUpdate() {
      const { data } = this.props;
      if (data && data.id) EncounterStore.fetch(data.id);
    }

    render() {
      const { data } = this.props;
      const id = data && data.id;
      const app = EncounterStore.infoById.get(id);

      if (!app) {
        return (
          <li>
            <h5>Encounter</h5>
            <em>Loading Encounter Details...</em>
          </li>
        );
      }

      if (Object.keys(app).length < 4) {
        return (
          <li>
            <h5>Encounter</h5>
            <em>Details still to be processed</em>
          </li>
        );
      }

      return (
        <li>
          <Encounter encounter={app} showDuration />
        </li>
      );
    }
  }
);

LoadableEncounter.displayName = 'LoadableEncounter';

export default LoadableEncounter;
