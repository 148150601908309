import React from 'react';
import PropTypes from 'prop-types';
import CaseStore from '../../stores/CaseStore';
import styles from './index.module.scss';
import { CaseCustomPrintStore } from '../stores/CaseCustomPrintStore';
import { useEphemeralStore } from '../../../utils/hooks';
import CaseflowPrintOptionsContainer from '../CaseflowPrintOptionsContainer';
import DetailsPrintOptionsContainer from '../DetailsPrintOptionsContainer';
import { CaseCustomPrintContext } from '../../Context';
import {
  BUTTON_TEXT,
  ERROR_MSG,
  MAIN_LABEL,
} from '../stores/CaseCustomPrintStore/consts';
import { Button } from '../../../ui';
import { IncidentsPrintOptionsStore } from '../stores/IncidentsPrintOptionsStore';
import IncidentPrintOptionsContainer from '../IncidentsPrintOptionsContainer';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { AssessmentsPrintOptionsStore } from '../stores/AssessmentsPrintOptionsStore';
import AssessmentsPrintOptionsContainer from '../AssessmentsPrintOptionsContainer';
import { CasePrintLogStore } from '../stores/CasePrintLogStore';
import { CaseLogClient } from 'common';

/**
 * Main entry point of print options for individual cases
 * responsible for serving the store, handling the lifecycle of the store
 * Checking the type of the case and rendering relevant option UI logic
 * should go here
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CaseCustomPrint = ({
  caseId,
  caseType,
  incidents = [],
  assessments = [],
  incidentRangeFilterOptions = [],
  incidentStatusFilterOptions = [],
  incidentTypeFilterOptions = [],
  refreshLogs,
}) => {
  const caseCustomPrintStore = useEphemeralStore(
    () => new CaseCustomPrintStore()
  );

  const incidentsPrintOptionsStore = new IncidentsPrintOptionsStore({
    incidentsFromCase: incidents,
    incidentTypeFilterOptions,
    incidentStatusFilterOptions,
    incidentRangeFilterOptions,
  });

  const assessmentsPrintOptionsStore = useEphemeralStore(
    () =>
      new AssessmentsPrintOptionsStore({
        assessmentsFromCase: assessments,
        caseType,
      })
  );

  const casePrintLogStore = useEphemeralStore(
    () =>
      new CasePrintLogStore({
        caseCustomPrintStore,
        incidentsPrintOptionsStore,
        assessmentsPrintOptionsStore,
        incidentsFromCase: incidents,
        assessmentsFromCase: assessments,
        caseType,
        caseId,
        caseLogClient: CaseLogClient,
        refreshLogs,
      })
  );

  if (!caseId) return <p>{ERROR_MSG}</p>;

  return (
    <CaseCustomPrintContext.Provider
      value={{
        caseStore: CaseStore,
        caseCustomPrintStore,
        incidentsPrintOptionsStore,
        assessmentsPrintOptionsStore,
        casePrintLogStore,
      }}
    >
      <div className={styles.main}>
        <p className={styles.label}>{MAIN_LABEL}</p>
        <CaseflowPrintOptionsContainer />
        {incidents.length > 0 && <IncidentPrintOptionsContainer />}
        {assessments.length > 0 && <AssessmentsPrintOptionsContainer />}
        {caseType !== 'GRC' && <DetailsPrintOptionsContainer />}
        <span className={styles.spacer} />
        <div className={styles.button}>
          <CaseCustomPrintContext.Consumer>
            {({ casePrintLogStore }) => (
              <Button
                type="button"
                value={BUTTON_TEXT}
                onClick={casePrintLogStore.print}
                data-cy="case-print-button"
              />
            )}
          </CaseCustomPrintContext.Consumer>
        </div>
      </div>
    </CaseCustomPrintContext.Provider>
  );
};

CaseCustomPrint.propTypes = {
  caseId: PropTypes.string,
  caseType: PropTypes.string,
  incidents: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  assessments: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  incidentTypeFilterOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  incidentRangeFilterOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  incidentStatusFilterOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  refreshLogs: PropTypes.func.isRequired,
};

export default CaseCustomPrint;
