import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';
import pluralize from '../../../utils/pluralize';

const DrawerHeader = ({ count, children, type }) => (
  <div className={styles.drawerHeader}>
    <strong>{count}</strong>
    {` ${pluralize(count, type)}`}
    {children}
  </div>
);

DrawerHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  count: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

DrawerHeader.defaultProps = {
  children: null,
};

DrawerHeader.displayName = 'DrawerHeader';

export default DrawerHeader;
