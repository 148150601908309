/**
 * Gets the width of an element.
 *
 * @param {string} selector The identifier for the element to be measured.
 * @param {number} defaultValue The default width of the element, if the element cannot be found. Mainly used for testing.
 * @returns {number} Either the width of the selected element or its default value.
 */

export default function getWidthofElement(selector, defaultValue) {
  const el = document.querySelector(selector);
  if (el) {
    return parseInt(el.offsetWidth.toString(), 10);
  }
  return defaultValue;
}
