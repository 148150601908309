import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import classnames from 'classnames';

import { Pandy, ScrollPager } from 'common';
import CaseResultsActionNav from '../CaseResultsActionNav';
import CaseResultsTopNav from '../CaseResultsTopNav';

import CaseResultsFilters from '../CaseResultsFilters';

import CaseLI from '../CaseLI/CaseCard';
import {
  ApplyBodyClassName,
  DocumentTitle,
  Loader,
  StaggeredList,
} from '../../ui';
import { useSetFiltersFromQuery, useSetAllowFetch } from './hooks';

const ROW_HEIGHT = 135;

const CaseResultsView = ({
  failure,
  hasMore,
  showFilters,
  storeParams,
  toggleAdvFilters,
  cases = [],
  storePagingProps,
  expandoVisible,
  shouldShowMsg,
  useGroups,
  query,
}) => {
  const pagerElement = useRef();

  useSetAllowFetch();

  useSetFiltersFromQuery({ query, shouldShowMsg });

  return (
    <main
      className={classnames({
        'advanced-filters': showFilters,
        'expando-visible': expandoVisible && !showFilters,
      })}
    >
      <DocumentTitle text="Cases" />
      <ApplyBodyClassName className="cases" />
      <ScrollPager.Component
        elt={pagerElement.current}
        rowHeight={ROW_HEIGHT}
        store={storePagingProps}
      />
      <CaseResultsTopNav />
      <CaseResultsActionNav />
      <CaseResultsFilters
        showMsg={!!shouldShowMsg}
        onClose={toggleAdvFilters}
        isOpen={!!showFilters}
      />
      <div className="view-content" ref={pagerElement}>
        <div className="row no-gutter">
          <div
            className={classnames({
              'col-xs-10 col-xs-offset-1': !showFilters,
              'col-xs-12': showFilters,
            })}
          >
            <Loader
              loaded={!storePagingProps.loading || storePagingProps.size !== 0}
            >
              <div className="minHeightWrapper">
                <Pandy
                  visible={
                    !storePagingProps.loading && storePagingProps.size === 0
                  }
                  mood="happy"
                >
                  <div className="speechBubble">
                    <span>No matches. Sorry!</span>
                  </div>
                </Pandy>
                <Pandy visible={Boolean(failure)} mood="sad">
                  <div className="speechBubble">
                    <span>Uh oh...</span>
                  </div>
                  <p>{failure}</p>
                </Pandy>
              </div>
              {failure && <p className="error">{failure}</p>}
              <StaggeredList
                className="card_holder"
                toAdd="fadeIn"
                drag={0}
                useSmallPandy
                pandyHasMore={hasMore}
                pandyText="Matching Cases"
                topOfListScrollValues={{
                  elementToScroll: 'view-content',
                  watchedElements: [storeParams],
                }}
                parentNodeRef={pagerElement.current}
              >
                {cases.map(c => (
                  <CaseLI fadeIn key={c.id} case={c} useGroups={useGroups} />
                ))}
              </StaggeredList>
            </Loader>
          </div>
        </div>
      </div>
    </main>
  );
};

CaseResultsView.propTypes = {
  failure: PropTypes.string,
  hasMore: PropTypes.bool,
  showFilters: PropTypes.bool,
  storeParams: PropTypes.shape({}),
  toggleAdvFilters: PropTypes.func.isRequired,
  cases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  storePagingProps: PropTypes.shape({
    nextPage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    paging: PropTypes.bool,
    size: PropTypes.number,
  }).isRequired,
  expandoVisible: PropTypes.bool,
  shouldShowMsg: PropTypes.bool,
  filterMsg: PropTypes.string,
  useGroups: PropTypes.bool,
  query: PropTypes.shape({
    showFilters: PropTypes.string,
    user: PropTypes.string,
    patient: PropTypes.string,
  }),
};

export default CaseResultsView;
