import React from 'react';
import PropTypes from 'prop-types';
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Table, Column, Cell } from 'fixed-data-table-2';

import {
  CaseCell,
  ChecksCell,
  DateCell,
  DetailsCell,
  EncounterCell,
  IconCell,
  SortHeaderCell,
  SourceCell,
} from '../eventTableCells';
import EventDetailedStore from '../stores/EventDetailedStore';
import { DateHelpers } from 'common';
import Store from '../stores/Store';
import Checkbox from '../../ui/Checkbox';
import CaseStore from '../stores/CaseStore';

const EventTable = observer(
  class extends React.Component {
    static propTypes = {
      canCheck: PropTypes.bool.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    };

    onRowClick(e, index) {
      const event = EventDetailedStore.results[index];
      // forces the detail to open again if the same event is selected twice
      EventDetailedStore.detailFocus = null;
      EventDetailedStore.detailFocus = event;
    }

    // Computed
    get checksHeaderMode() {
      if (EventDetailedStore.someFlagged && EventDetailedStore.someNotFlagged)
        return 'selected_some';
      if (EventDetailedStore.someFlagged) return 'selected_all';
      return 'selected_none';
    }

    isAuthorized(model) {
      const { authorizations } = EventDetailedStore;

      const authorization = authorizations.find(auth => {
        const authUser = auth.user;
        const authPatient = auth.patient;

        let isAuthorized = false;

        // ensure that this only applies to rows for the correct user and patient combination
        if (
          Store.userIdParam === authUser.id &&
          Store.patientIdParam === authPatient.id
        ) {
          const { start, end } = auth;
          isAuthorized =
            model.eventDate >= start &&
            ((end && model.eventDate <= end) || !end);
        }

        return isAuthorized;
      });

      return Boolean(authorization);
    }

    toggleChecked(model) {
      EventDetailedStore.toggleFlag(model);
    }

    toggleAll() {
      EventDetailedStore.toggleFlags();
    }

    rowClassNameGetter(rowIndex) {
      const rowData = EventDetailedStore.results[rowIndex];
      const { loading } = EventDetailedStore;
      const df = EventDetailedStore.detailFocus;

      return classNames({
        selected: Boolean(EventDetailedStore.isFlagged(rowData)),
        drawerRow: rowData && rowData === df,
        'highlight highlight-success':
          this.isAuthorized(rowData) && rowData.user && rowData.patient,
        'highlight highlight-UOA': !rowData.patient,
        'highlight highlight-alias':
          !loading &&
          rowData.patient &&
          rowData.patient.id !== Store.patientIdParam,
      });
    }

    renderCheckColumn(data) {
      const toggler = index => {
        this.toggleChecked(data[index]);
      };

      const checksHeader = () => {
        const selected = this.checksHeaderMode;
        const classes = classNames(selected, 'table_selector');

        return (
          <Cell className={classes}>
            <div className={selected}>
              <Checkbox
                checked={selected === 'selected_all'}
                indeterminate={selected === 'selected_some'}
                onChange={this.toggleAll.bind(this)}
                tooltip="Toggle All"
              />
            </div>
          </Cell>
        );
      };

      return (
        <Column
          header={checksHeader()}
          width={35}
          cell={<ChecksCell toggler={toggler} data={data} />}
        />
      );
    }

    render() {
      // we have to eagerly load some observable attributes here, since mobx won't detect
      // observations in the table callback functions

      const { canCheck, height, width } = this.props;
      const { results, detailFocusIndex, loading } = EventDetailedStore;
      const { eventMap } = CaseStore;
      const data = results;
      const classes = classNames({ loading });
      let checkColumn = <Column width={35} />;
      if (canCheck) checkColumn = this.renderCheckColumn(data);
      const timezone = DateHelpers.getCurrentAppUserTimezone();

      return (
        <Table
          className={classes}
          rowHeight={50}
          headerHeight={50}
          rowsCount={data.length}
          width={width}
          height={height}
          onRowClick={this.onRowClick.bind(this)}
          scrollToRow={detailFocusIndex}
          rowClassNameGetter={this.rowClassNameGetter.bind(this)}
        >
          {checkColumn}
          <Column
            header={<SortHeaderCell> Date </SortHeaderCell>}
            width={225}
            cell={({ rowIndex }) => (
              <DateCell data={data[rowIndex]} timezone={timezone} />
            )}
          />
          <Column
            width={15}
            cell={({ rowIndex }) => <IconCell data={data[rowIndex]} />}
          />
          <Column
            width={15}
            cell={({ rowIndex }) => {
              const event = data[rowIndex];
              return (
                <CaseCell caze={eventMap.get(event.class).get(event.id)} />
              );
            }}
          />
          <Column
            width={15}
            cell={({ rowIndex }) => <EncounterCell data={data[rowIndex]} />}
          />
          <Column
            header={<Cell>Details</Cell>}
            width={120}
            cell={({ rowIndex }) => <DetailsCell data={data[rowIndex]} />}
            flexGrow={1}
          />
          <Column
            width={120}
            cell={({ rowIndex }) => <SourceCell data={data[rowIndex]} />}
          />
        </Table>
      );
    }
  }
);

decorate(EventTable, {
  checksHeaderMode: computed,
});

EventTable.displayName = 'EventTable';

export default EventTable;
