import React from 'react';
import PropTypes from 'prop-types';

const ValueOrUnknown = ({ children }) => {
  if (Array.isArray(children) && children.length === 0) {
    children = null;
  }
  return children || <em>Unknown</em>;
};

ValueOrUnknown.propTypes = {
  children: PropTypes.node,
};

ValueOrUnknown.defaultProps = {
  children: null,
};

export default ValueOrUnknown;
