import React, { useEffect } from 'react';
import TopNavBar from '../../../navbar/TopNavBar';
import GroupQuickFilter from '../../GroupQuickFilter';
import PropTypes from 'prop-types';
import {
  groupPropType,
  quickFilterDataPropType,
} from '../../GroupQuickFilter/propTypes';

const TopNav = ({
  quickFilterOptions,
  savedQuickFilterOptions = [],
  hasMultipleGroups,
  handleUpdateQuickFilterOptions,
  setQuickFilterOptionsFromQueryParams,
  handleSetSavedQuickFilterOptions,
  query = {},
  pathname,
  historyReplace,
  useTopNav,
  showRememberFilterButton,
}) => {
  useEffect(() => {
    if (savedQuickFilterOptions.length && !query.group)
      handleUpdateQuickFilterOptions(
        null,
        'savedOptions',
        { ...query, group: savedQuickFilterOptions },
        pathname,
        historyReplace
      );
  }, [
    handleUpdateQuickFilterOptions,
    historyReplace,
    pathname,
    query,
    savedQuickFilterOptions,
  ]);

  useEffect(() => {
    setQuickFilterOptionsFromQueryParams(query);
  }, [query, setQuickFilterOptionsFromQueryParams]);

  // hide if there's only one group or advanced filter is open
  if (!hasMultipleGroups || query.showFilters === 'true') return null;

  const filter = (
    <GroupQuickFilter
      data={quickFilterOptions}
      handleCheckboxOnChange={id =>
        handleUpdateQuickFilterOptions(
          id,
          'checkbox',
          query,
          pathname,
          historyReplace
        )
      }
      handleSetThisGroupOnly={id =>
        handleUpdateQuickFilterOptions(
          id,
          'groupOnly',
          query,
          pathname,
          historyReplace
        )
      }
      handleSetLocalStorage={() =>
        handleSetSavedQuickFilterOptions(query.group)
      }
      showRememberFilterButton={showRememberFilterButton}
    />
  );

  if (useTopNav) return <TopNavBar secondary>{filter}</TopNavBar>;
  else return filter;
};

TopNav.propTypes = {
  quickFilterOptions: quickFilterDataPropType,
  savedQuickFilterOptions: groupPropType,
  handleUpdateQuickFilterOptions: PropTypes.func.isRequired,
  hasMultipleGroups: PropTypes.bool.isRequired,
  setQuickFilterOptionsFromQueryParams: PropTypes.func.isRequired,
  handleSetSavedQuickFilterOptions: PropTypes.func.isRequired,
  query: PropTypes.shape({
    group: groupPropType,
    showFilters: PropTypes.string,
  }),
  pathname: PropTypes.string,
  historyReplace: PropTypes.func.isRequired,
  useTopNav: PropTypes.bool,
  showRememberFilterButton: PropTypes.bool,
};

export default TopNav;
