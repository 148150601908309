/**
 * Convert a comma-separated string to an array.
 * If it's already an array, return a copy of it.
 * If it's falsy, return an empty array.
 *
 * @param src {string|string[]} String to split apart if needed.
 * @return {string[]}
 */
export default function commaSeparatedToArray(src) {
  src = src ?? [];
  return src instanceof Array ? src.slice() : src.split(',');
}
