import { action, computed, decorate, observable } from 'mobx';
import { icons } from '../../../ui/MultiRecordInformationDropdown/const';
import {
  DROPDOWN_EMPLOYEE_OPT_LABEL,
  DROPDOWN_PATIENT_OPT_LABEL,
  DROPDOWN_PERSONAL_OPT_LABEL,
  PERSONAL_INFORMATION,
  EMPLOYEE_INFORMATION,
  PATIENT_INFORMATION,
} from '../const';

class MultiRecordInformationDropdownStore {
  constructor({ sessionStorageKey }) {
    this.sessionStorageKey = sessionStorageKey;
  }

  get options() {
    return [
      {
        label: DROPDOWN_PERSONAL_OPT_LABEL,
        value: PERSONAL_INFORMATION,
        icon: icons.person,
      },
      {
        label: DROPDOWN_EMPLOYEE_OPT_LABEL,
        value: EMPLOYEE_INFORMATION,
        icon: icons.user,
      },
      {
        label: DROPDOWN_PATIENT_OPT_LABEL,
        value: PATIENT_INFORMATION,
        icon: icons.patient,
      },
    ];
  }

  selectedOption = PERSONAL_INFORMATION;
  previouslySelected;

  getPreviouslySelected = entity => {
    if (entity) {
      const remembered = sessionStorage.getItem(`MRVDropdown-${entity}`);
      if (remembered)
        return (this.previouslySelected = this.options.find(
          opt => opt.value === remembered
        ));
    }
  };

  setPreviouslySelected = (sessionStorageKey, value) => {
    if (sessionStorageKey && value) {
      return sessionStorage.setItem(`MRVDropdown-${sessionStorageKey}`, value);
    }
  };

  setSelectedOption = opt => (this.selectedOption = opt.value);
}

decorate(MultiRecordInformationDropdownStore, {
  selectedOption: observable,
  previouslySelected: observable,
  options: computed,
  setSelectedOption: action,
  getPreviouslySelected: action,
  setPreviouslySelected: action,
});

export { MultiRecordInformationDropdownStore };
