import { decorate, observable } from 'mobx';

import { HalUtils, PersonTagsClient } from 'common';

/*
 * @class UserTagInfoStore
 * Data store for retrieving the TagInfo entry for a user.
 */
class UserTagInfoStore {
  // Observable
  tagInfoByUserId = new Map();

  loading = {};

  /*
   * @method fetch
   * Fetch the TagInfo entry for a user
   * @param userId {String} - the user id to use to look up the TagInfo
   */
  fetch(userId) {
    if (!userId || this.loading[userId]) return;
    this.loading[userId] = true;

    PersonTagsClient.getTagInfoByUser(userId).then(r => {
      const totalElements = (r && r.page && r.page.totalElements) || 0;

      if (totalElements === 1) {
        const embedded = HalUtils.getData(r),
          id = embedded && embedded[0] && HalUtils.getId(embedded[0]);
        this.tagInfoByUserId.set(userId, id);
      }

      this.loading[userId] = false;
    });
  }
}

decorate(UserTagInfoStore, {
  tagInfoByUserId: observable,
});

export default new UserTagInfoStore();
