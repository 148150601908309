import { useEffect } from 'react';

const ApplyBodyClassName = ({ className }) => {
  useEffect(() => {
    document.body.className = className;
  }, [className]);
  return null;
};

export default ApplyBodyClassName;
