import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import classnames from 'classnames';

const ActionLink = ({ iconClass, onClick, text, 'data-cy': dataCy }) => {
  return (
    <div className={styles.actionLinkContainer}>
      <span
        className={classnames('prot-a', styles.actionLink)}
        onClick={onClick}
        data-cy={dataCy}
      >
        <i
          className={classnames(
            'material-icons',
            iconClass,
            styles.actionLinkIcon
          )}
        />
        {text}
      </span>
    </div>
  );
};

ActionLink.propTypes = {
  'data-cy': PropTypes.string,
  iconClass: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default ActionLink;
