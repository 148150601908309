import { BaseURL } from 'common';
import {
  INCIDENT_SUMMARY,
  INCIDENT_SUMMARY_AND_EVENT_DETAILS,
  INCIDENT_SUMMARY_AND_EVENT_DETAILS_BY_IDS_URL,
  INCIDENT_SUMMARY_BY_IDS_URL,
} from '../incident/stores/consts';

function getIncidentExportURL(eventType) {
  if (eventType === INCIDENT_SUMMARY)
    return BaseURL.service(INCIDENT_SUMMARY_BY_IDS_URL);
  if (eventType === INCIDENT_SUMMARY_AND_EVENT_DETAILS)
    return BaseURL.service(INCIDENT_SUMMARY_AND_EVENT_DETAILS_BY_IDS_URL);
}

export { getIncidentExportURL };
