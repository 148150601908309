import { MultiRecordMetadataDetails } from '../MultiRecordMetadataDetails';
import { MultiRecordViewContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';

export function mapProps({
  multiRecordMainStore,
  multiRecordUserMetadataStore,
}) {
  return {
    pageMode: multiRecordMainStore.pageMode,
    personType: multiRecordUserMetadataStore.personType,
    metadataTypes: multiRecordUserMetadataStore.metadataTypes,
    metadataTypeColumns: multiRecordUserMetadataStore.metadataTypeColumns,
  };
}

export const MultiRecordUserMetadataDetailsContainer = observerWrappedContainerFactory(
  {
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordMetadataDetails,
  }
);
