import React from 'react';
import PropTypes from 'prop-types';
import {
  RADAR_INTEGRATION,
  SAI_360_INTEGRATION,
  ORIGAMI_INTEGRATION,
} from '../../stores/CaseIntegrationStore/const';
import { PromptWrapper, RadioButton } from '../../../ui';
import classnames from 'classnames';
import styles from './index.module.scss';
import { SAI360CaseIntegrationContainer } from '../caseIntegrations/SAI360CaseIntegrationContainer';
import { RadarCaseIntegrationContainer } from '../caseIntegrations/RadarCaseIntegrationContainer';
import { OrigamiCaseIntegrationContainer } from '../caseIntegrations/OrigamiCaseIntegrationContainer';

const CaseIntegration = ({
  caseIntegrationId,
  canSave,
  isDeleteConfirmationVisible,
  setDeleteConfirmationVisible,
  saveIntegration,
  deleteIntegration,
  integrationType,
  setIntegrationType,
}) => {
  return (
    <div
      id="case_integration"
      className={classnames('wrapper', {
        [styles.confirmDelete]: isDeleteConfirmationVisible,
      })}
      data-cy="integration-container"
    >
      <div className="card_header">
        <h1>Case Integration</h1>

        {!isDeleteConfirmationVisible && (
          <div id="main-toolbar">
            <span
              id="save-button"
              className={classnames('prot-a', {
                muted: !canSave,
                [styles.disabledLink]: !canSave,
              })}
              onClick={saveIntegration}
              role="button"
              data-cy="integration-save"
            >
              Save Changes
            </span>
            {caseIntegrationId && (
              <span
                id="delete-integration-button"
                className="prot-a text-danger"
                onClick={() =>
                  setDeleteConfirmationVisible(!isDeleteConfirmationVisible)
                }
                role="button"
                data-cy="integration-delete"
              >
                Delete Integration
              </span>
            )}
          </div>
        )}
        {isDeleteConfirmationVisible && (
          <div id="delete-confirmation-toolbar">
            <span
              id="delete-integration-cancel-button"
              className="prot-a"
              onClick={() =>
                setDeleteConfirmationVisible(!isDeleteConfirmationVisible)
              }
              role="button"
            >
              Cancel
            </span>
            <span
              id="delete-integration-confirm-button"
              className="prot-a text-danger"
              onClick={deleteIntegration}
              role="button"
              data-cy="integration-delete-confirm"
            >
              Confirm Delete
            </span>
          </div>
        )}
      </div>
      <div
        className={styles.caseIntegrationSelection}
        data-cy="integration-radio-select"
      >
        <p>Select a Case Integration</p>
        <RadioButton
          options={{
            [RADAR_INTEGRATION]: 'Radar',
            [SAI_360_INTEGRATION]: 'SAI 360',
            [ORIGAMI_INTEGRATION]: 'Origami',
          }}
          selectedOption={integrationType}
          setValue={value => setIntegrationType(value)}
          disabled={!!caseIntegrationId}
        />
      </div>
      {integrationType === RADAR_INTEGRATION ? (
        <RadarCaseIntegrationContainer />
      ) : integrationType === SAI_360_INTEGRATION ? (
        <SAI360CaseIntegrationContainer />
      ) : (
        (integrationType = ORIGAMI_INTEGRATION ? (
          <OrigamiCaseIntegrationContainer />
        ) : null)
      )}
      <PromptWrapper when={canSave} />
    </div>
  );
};

CaseIntegration.propTypes = {
  caseIntegrationId: PropTypes.string,
  canSave: PropTypes.bool,
  isDeleteConfirmationVisible: PropTypes.bool,
  setDeleteConfirmationVisible: PropTypes.func.isRequired,
  saveIntegration: PropTypes.func,
  deleteIntegration: PropTypes.func.isRequired,
  integrationType: PropTypes.string,
  setIntegrationType: PropTypes.func,
};

export default CaseIntegration;
