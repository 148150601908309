import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

class CaseLink extends React.Component {
  render() {
    const { active, cases, user, patient, caseId, ...other } = this.props;

    if (cases && cases > 0) {
      if (!active) {
        return <span {...other}>{this.props.children}</span>;
      }
      if (caseId) {
        return (
          <Link to={`/case/${caseId}`} {...other}>
            {this.props.children}
          </Link>
        );
      }
      return (
        <Link
          to={{
            pathname: '/cases',
            search: queryString.stringify({ user, patient }),
          }}
          {...other}
        >
          {this.props.children}
        </Link>
      );
    }
    return this.props.children;
  }
}

CaseLink.propTypes = {
  active: PropTypes.bool,
  cases: PropTypes.number,
  children: PropTypes.node,
  user: PropTypes.string,
  caseId: PropTypes.string,
  patient: PropTypes.string,
};

export default observer(CaseLink);
