import React from 'react';
import PropTypes from 'prop-types';
import ValueOrUnknown from '../ValueOrUnknown';

const FormattedSex = ({ sex }) => {
  return (
    <ValueOrUnknown>
      {sex && sex.toLowerCase().capitalizeFirstLetter()}
    </ValueOrUnknown>
  );
};

FormattedSex.propTypes = {
  sex: PropTypes.string,
};

FormattedSex.defaultProps = {
  sex: null,
};

export default FormattedSex;
