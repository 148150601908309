import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  COMPARE_EMPLOYEE_PATIENT,
  COMPARE_PATIENT_EMPLOYEE,
  DOUBLE_EMPLOYEE_PATIENT,
  DOUBLE_PATIENT_EMPLOYEE,
  EMR_EMPLOYEE_INCIDENTS,
  EMR_EMPLOYEE_ONLY_ACCESSES,
  SINGLE_EMPLOYEE,
  SINGLE_PATIENT,
} from '../stores/const';
import styles from './index.module.scss';
import DoubleView from './layout/DoubleView';
import SingleView from './layout/SingleView';
import CompareView from './layout/CompareView';

const MultiRecordMain = ({ mode, setCurrentPath, path }) => {
  useEffect(() => {
    if (path) setCurrentPath(path);
  }, [path, setCurrentPath]);

  let layout;

  switch (mode) {
    case DOUBLE_EMPLOYEE_PATIENT:
    case EMR_EMPLOYEE_INCIDENTS:
    case EMR_EMPLOYEE_ONLY_ACCESSES:
      layout = <DoubleView mode={DOUBLE_EMPLOYEE_PATIENT} />;
      break;
    case DOUBLE_PATIENT_EMPLOYEE:
      layout = <DoubleView mode={DOUBLE_PATIENT_EMPLOYEE} />;
      break;
    case SINGLE_PATIENT:
      layout = <SingleView mode={SINGLE_PATIENT} />;
      break;
    case SINGLE_EMPLOYEE:
      layout = <SingleView mode={SINGLE_EMPLOYEE} />;
      break;
    case COMPARE_PATIENT_EMPLOYEE:
      layout = <CompareView mode={COMPARE_PATIENT_EMPLOYEE} />;
      break;
    case COMPARE_EMPLOYEE_PATIENT:
      layout = <CompareView mode={COMPARE_EMPLOYEE_PATIENT} />;
      break;
    default:
      layout = null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.flex}>{layout}</div>
    </div>
  );
};

MultiRecordMain.propTypes = {
  mode: PropTypes.string,
  setCurrentPath: PropTypes.func,
  path: PropTypes.string,
};

export default MultiRecordMain;
