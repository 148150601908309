import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import $ from 'jquery';

import { Authenticated, HTMLEntities, Pandy } from 'common';
import UserTagCreateForm from '../UserTagCreateForm';
import UserTagEditForm from '../UserTagEditForm';

import TagsList from '../../TagsList';
import ActionNav from '../../../navbar/ActionNav';
import { AsidePanel, DocumentTitle, RegistryContainer } from '../../../ui';
import AsideHelpers from '../../../ui/AsidePanel/AsideHelpers';

import styles from './index.module.scss';

const ROW_HEIGHT = 50;

const UserTagsListView = ({
  addBanner,
  loading,
  activeTags,
  downloadCSV,
  setFilters,
  failure,
  query,
  tagId,
  hasPermission,
  store,
  isCreate,
  isEdit,
  setActiveUserTagId,
}) => {
  let createPath = '/user-tags/create';
  const isEditOrCreate = isEdit || isCreate;

  useEffect(() => {
    if (failure) addBanner(failure, 'danger');
  }, [addBanner, failure]);

  useEffect(() => {
    if (query) setFilters(query);
  }, [query, setFilters]);

  useEffect(() => {
    setActiveUserTagId(tagId);
  }, [tagId, setActiveUserTagId]);

  useEffect(() => {
    isEditOrCreate ? AsideHelpers.openPanel() : AsideHelpers.closePanel();
  }, [isEditOrCreate]);

  /**
   * Create a downloadable blob of the response from the CSV endpoint.
   * @param {Object} e The Event Object
   */
  const download = e => {
    e.preventDefault();
    downloadCSV();
  };

  const renderForm = () => {
    const linkTo = '/user-tags';
    const title = isCreate ? 'CREATE USER TAG' : 'TAG DETAIL';

    return (
      <AsidePanel
        closeLinkTo={linkTo}
        isOpen
        actionNavHeight
        title={title}
        className={styles.asidePanel}
      >
        {isCreate ? (
          <UserTagCreateForm linkTo={linkTo} />
        ) : (
          <UserTagEditForm linkTo={linkTo} baseUrl={linkTo} />
        )}
      </AsidePanel>
    );
  };

  const newQuery = $.param(query),
    shouldShow = !loading && activeTags?.length === 0;

  if (newQuery) createPath = `${createPath}?${newQuery}`;

  return (
    <div className="tags--view">
      <DocumentTitle text="User Tags" />
      <Authenticated permission="PERSON_TAG_VIEW">
        <div
          className={classnames('tags__registry registry--flex', {
            'with-aside': isEditOrCreate,
          })}
        >
          <section className="panel__main">
            <ActionNav
              secondary
              count={activeTags.length}
              createPath={createPath}
              createPermission="PERSON_TAG_CREATE"
              createIcon="add_circle"
              title="User Tag"
              createLabel="Create Tag"
              downloadCSV={download}
            />
            <RegistryContainer
              store={store}
              title="User Tags"
              className="registry"
            >
              <Pandy visible={shouldShow} mood="happy">
                <div className="speechBubble">
                  <span>
                    There are no user tags.
                    {hasPermission ? ' Add some!' : ''}
                  </span>
                </div>
                <Authenticated permission="PERSON_TAG_CREATE">
                  <div className="tags__empty-actions">
                    <p>
                      Click the {HTMLEntities.lsquo}Add User Tag
                      {HTMLEntities.rsquo} button to get started.
                    </p>
                    <Link to={createPath} className="button">
                      Add User Tag
                    </Link>
                  </div>
                </Authenticated>
              </Pandy>
              <TagsList
                rowHeight={ROW_HEIGHT}
                store={store}
                className={classnames({
                  hidden: shouldShow,
                })}
                label="EMR User"
                baseUrl="/user-tags"
              />
            </RegistryContainer>
          </section>
          {isEditOrCreate && renderForm()}
        </div>
      </Authenticated>
    </div>
  );
};

UserTagsListView.propTypes = {
  activeTags: PropTypes.array,
  downloadCSV: PropTypes.func,
  loading: PropTypes.bool,
  addBanner: PropTypes.func,
  setFilters: PropTypes.func,
  failure: PropTypes.string,
  hasPermission: PropTypes.bool,
  isCreate: PropTypes.bool,
  isEdit: PropTypes.bool,
  query: PropTypes.shape({}),
  store: PropTypes.shape({}),
  setActiveUserTagId: PropTypes.func,
  tagId: PropTypes.string,
};

export default UserTagsListView;
