import React from 'react';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';
import { LoginStore, ResetPasswordStore, RouterContainer } from 'common';
import LoginFooter from '../LoginFooter';
import { ApplyBodyClassName, DocumentTitle, Input, Tooltip } from '../../ui';

const ResetPassword = observer(
  class ResetPassword extends React.Component {
    // Obesrvables
    // password and password confirmation attributes
    newPassword = null;
    passwordConfirm = null;

    // UI states -- processing is used to disable inputs, errorMessage helps
    // users understand why a request failed
    processing = false;
    errorMessage = null;

    // End of Observables

    // COMPUTED disabled state management for the submit button
    get enableSubmit() {
      return (
        !this.processing && this.newPasswordValid && this.passwordConfirmValid
      );
    }

    get newPasswordValid() {
      return (
        ResetPasswordStore.strongPasswordValidator(this.newPassword) === true
      );
    }

    get newPassError() {
      const error = ResetPasswordStore.strongPasswordValidator(
        this.newPassword
      );
      return typeof error === 'string' ? error : null;
    }

    get passwordConfirmValid() {
      return (
        this.matchingValidator(this.passwordConfirm, this.newPassword) === true
      );
    }

    get passConfirmError() {
      const error = this.matchingValidator(
        this.passwordConfirm,
        this.newPassword
      );
      return typeof error === 'string' ? error : null;
    }

    matchingValidator = value =>
      ResetPasswordStore.matchingValidator(value, this.newPassword);

    handleSubmit(e) {
      e.preventDefault();

      this.processing = true;
      this.errorMessage = null;

      if (this.newPassword.length > 0) {
        if (!this.newPasswordValid || !this.passwordConfirmValid) return;
      }

      ResetPasswordStore.resetPassword(this.newPassword)
        .done(() => {
          // End the temporary session and allow the user to log in with the newly reset password.
          LoginStore.logout();
          ResetPasswordStore.passwordChanged =
            'Password successfully changed. Please log in.';
          RouterContainer.go('/');
        })
        .fail(xhr => {
          // clear out the input fields
          this.newPassword = '';
          this.passwordConfirm = '';
          let failure = 'Could not reach server or other failure occurred.';
          if (xhr.responseJSON && xhr.responseJSON.message) {
            failure = xhr.responseJSON.message;
          }
          this.errorMessage = failure;
        })
        .always(() => {
          this.processing = false;
        });
    }

    render() {
      const passwordResetTooltipContent = (
        <div
          dangerouslySetInnerHTML={{
            __html: ResetPasswordStore.passwordPolicy,
          }}
        />
      );

      if (!LoginStore.forcePasswordChange) {
        return <Redirect to="/" />;
      }

      return (
        <section className="login">
          <DocumentTitle text="Reset Password" />
          <ApplyBodyClassName className="login_view" />
          <article className="login-container">
            <header className="login-header">
              <h1>Welcome to Protenus</h1>
              <p>Please create a new password.</p>
            </header>
            <form
              className="form"
              autoComplete="off"
              onSubmit={this.handleSubmit.bind(this)}
            >
              <ul>
                <Tooltip content={passwordResetTooltipContent} placement="left">
                  <li>
                    <Input
                      borderRadius="sm"
                      darkBackground
                      error={
                        this.newPassword !== null && !this.newPasswordValid
                      }
                      errorMessage={this.newPassError}
                      disabled={this.processing}
                      coloredFont
                      label="New Password"
                      margin="lg"
                      name="password"
                      onChange={val => (this.newPassword = val)}
                      type="password"
                      value={this.newPassword || ''}
                    />
                  </li>
                </Tooltip>

                <li>
                  <Input
                    borderRadius="sm"
                    darkBackground
                    error={
                      this.passwordConfirm !== null &&
                      !this.passwordConfirmValid
                    }
                    errorMessage={this.passConfirmError}
                    margin="lg"
                    type="password"
                    name="password"
                    label="Confirm New Password"
                    disabled={this.processing}
                    onChange={val => (this.passwordConfirm = val)}
                    value={this.passwordConfirm || ''}
                  />
                </li>

                <li>
                  <p className="login-form--error">{this.errorMessage}</p>
                  <p>
                    <input
                      className="button"
                      type="submit"
                      value="Save Password"
                      disabled={!this.enableSubmit}
                    />
                  </p>
                </li>
              </ul>
            </form>
            <div>
              <small>
                <span
                  className="prot-a"
                  style={{ textDecoration: 'underline' }}
                  onClick={() => LoginStore.logout()}
                >
                  Return to Login
                </span>
              </small>
              <small>
                <a
                  href={`mailto:support@protenus.com?${$.param(
                    { subject: 'Reset Password Help' },
                    true
                  )}`}
                >
                  I need help with my password
                </a>
              </small>
            </div>
          </article>
          <LoginFooter />
        </section>
      );
    }
  }
);

decorate(ResetPassword, {
  newPassword: observable,
  passwordConfirm: observable,
  newPasswordValid: computed,
  passwordConfirmValid: computed,
  newPassError: computed,
  passConfirmError: computed,
  processing: observable,
  errorMessage: observable,
  enableSubmit: computed,
});

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
