import { autorun, computed, decorate, observable } from 'mobx';

import {
  SingletonStore,
  AuthorizationClient,
  HalUtils,
  PermissionStore,
} from 'common';

class AuthorizationPermissionStore extends SingletonStore {
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  id = null;

  // Computed
  get relatedCase() {
    return this.result.relatedCase;
  }

  // Computed
  get user() {
    return this.result.user;
  }

  // Computed
  get patient() {
    return this.result.patient;
  }

  // Computed
  get authorization() {
    return this.result;
  }

  // Computed
  get href() {
    return HalUtils.getSelf(this.authorization);
  }

  // Computed
  get isLoading() {
    return this.loading;
  }

  // Computed
  get readOnly() {
    return !PermissionStore.has('ACCESS_AUTH_MODIFY');
  }

  // Computed
  get canView() {
    return PermissionStore.has('ACCESS_AUTH_SEARCH');
  }

  // Store these values in a centrally accessible manner so we can easily
  // access the map of these values.
  get authTypes() {
    return [
      {
        value: 'APPROVAL_NOT_REQUIRED',
        label: 'Approval not required',
      },
      {
        value: 'BY_EXCEPTION',
        label: 'Authorized by exception',
      },
      {
        value: 'PATIENT_OR_GUARDIAN',
        label: "Authorized by the patient or patient's legal representative",
      },
      {
        value: 'THIRD_PARTY',
        label: 'Authorized by third party legal representative documentation',
      },
    ];
  }

  fetch() {
    if (!this.canView || this.id === null) return [];

    return AuthorizationClient.find(this.id);
  }

  update(updated) {
    return AuthorizationClient.update(this.href, updated).then(() => {
      this.refresh();
    });
  }

  revoke(revocationDate, revocationReason) {
    return AuthorizationClient.revoke(
      this.href,
      revocationDate,
      revocationReason
    ).then(() => {
      this.refresh();
    });
  }

  delete() {
    return AuthorizationClient.delete(this.href);
  }
}

decorate(AuthorizationPermissionStore, {
  authorization: computed,
  canView: computed,
  href: computed,
  id: observable,
  isLoading: computed,
  patient: computed,
  readOnly: computed,
  user: computed,
});

export default new AuthorizationPermissionStore();
