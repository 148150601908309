import React from 'react';
import { observer } from 'mobx-react';
import { RegistrySearchStore, HalUtils } from 'common';

import AssignStore from '../stores/AssignStore';

import AssignView from '../AssignView';
import { withRouter } from 'react-router-dom';

const filterMsg = localStorage.getItem('filterMsg');

export function mapProps({
  assignStore,
  location,
  match,
  searchStore,
  HalUtils,
}) {
  const reset = () => {
    resetSearchStore();
    assignStore.reset();
  };

  const resetSearchStore = () => {
    searchStore.reset();
  };

  return {
    assignStore,
    isLoading: assignStore.isLoading,
    employeeId: match.params.employeeId,
    pathname: location.pathname,
    reset,
    resetSearchStore,
    searchStore,
    selectedUserId: HalUtils.getId(searchStore.store.selectedUser),
    setType: searchStore.setType,
  };
}

const AssignViewContainer = withRouter(
  observer(({ location, match }) => (
    <AssignView
      {...mapProps({
        assignStore: AssignStore,
        location,
        match,
        filterMsg,
        searchStore: RegistrySearchStore,
        HalUtils,
      })}
    />
  ))
);

export default AssignViewContainer;
