import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { Card, CardHeader } from '../../ui/Card';

const personName = function(person) {
  const { firstName, lastName } = person;

  let name = '';
  if (firstName) {
    name += firstName;
    if (lastName) name += ' ';
  }
  if (lastName) name += lastName;
  return name;
};

export default class BundleLI extends Component {
  static propTypes = {
    bundle: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      created: PropTypes.string,
      casesCount: PropTypes.number,
      resolvedCount: PropTypes.number,
      unresolvedCount: PropTypes.number,
      userCount: PropTypes.number,
      patientCount: PropTypes.number,
      singleUser: PropTypes.shape({ id: PropTypes.string }),
      singlePatient: PropTypes.shape({ id: PropTypes.string }),
    }),
    query: PropTypes.shape({}),
    timezone: PropTypes.string,
  };

  displayDate(timezone) {
    const { bundle } = this.props;
    return moment(bundle.created)
      .tz(timezone)
      .format('lll z');
  }

  displayCaseCount() {
    const { casesCount } = this.props.bundle;
    return `${casesCount} Case${casesCount === 1 ? '' : 's'}`;
  }

  displayResolvedCaseCount() {
    const { resolvedCount } = this.props.bundle;
    return `${resolvedCount} Case${resolvedCount === 1 ? '' : 's'}`;
  }

  displayUnresolvedCaseCount() {
    const { unresolvedCount } = this.props.bundle;
    return `${unresolvedCount} Case${unresolvedCount === 1 ? '' : 's'}`;
  }

  renderUser() {
    const { userCount, singleUser } = this.props.bundle;

    if (userCount === 1) {
      if (!singleUser) return `Unknown`;
      return (
        <Link to={`/activity/employee/${singleUser.id}`}>
          {personName(singleUser)}
        </Link>
      );
    } else {
      return `${userCount} Users`;
    }
  }

  renderPatient() {
    const { patientCount, singlePatient } = this.props.bundle;

    if (patientCount === 1) {
      if (!singlePatient) return `Unknown`;
      return (
        <Link to={`/activity/patient/${singlePatient.id}`}>
          {personName(singlePatient)}
        </Link>
      );
    } else {
      return `${patientCount} Patients`;
    }
  }

  render() {
    const {
      bundle: { id, name },
      timezone,
    } = this.props;
    const link = {
      pathname: `/bundles/${id}`,
      search: queryString.stringify({ ...this.props.query }),
    };

    return (
      <li>
        <Card className="bundle">
          <CardHeader title={name} link={link} />
          <ul>
            <li className="card__meta-item">
              <h5>Bundle</h5>
              {this.displayCaseCount()}
            </li>
            <li className="card__meta-item card__action-date">
              <h5>Created</h5>
              <p>{this.displayDate(timezone)}</p>
            </li>
            <li className="card__meta-item">
              <h5>Resolved</h5>
              <p>{this.displayResolvedCaseCount()}</p>
            </li>
            <li className="card__meta-item">
              <h5>Unresolved</h5>
              <p>{this.displayUnresolvedCaseCount()}</p>
            </li>
            <li className="card__meta-item card__user">
              <h5>EMR User</h5>
              <p>{this.renderUser()}</p>
            </li>
            <li className="card__meta-item card__patient">
              <h5>Patient</h5>
              <p>{this.renderPatient()}</p>
            </li>
          </ul>
        </Card>
      </li>
    );
  }
}
