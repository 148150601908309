import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import classnames from 'classnames';

const unknown = <em>Unknown</em>;

const Field = ({
  author,
  blankWhenEmpty,
  label,
  value,
  children,
  length,
  rightAlign,
  width,
}) => {
  if (blankWhenEmpty && !value) {
    return (
      <li
        className={classnames(styles[width], styles.field, {
          [styles.rightAlign]: rightAlign,
        })}
      />
    );
  }

  return (
    <li
      className={classnames(styles[width], styles.field, {
        [styles.rightAlign]: rightAlign,
      })}
    >
      <h5>
        {label}
        {length > 0 && (
          <span className={classnames('text-muted', styles.spaceLeft)}>
            1 of {length}
          </span>
        )}
      </h5>
      {children ? (
        children
      ) : (
        <p className={styles.values}>
          <span
            className={classnames({ ellipsis: !author && width !== 'inherit' })}
          >
            {value || unknown}
          </span>
          {author && (
            <span
              className={classnames('text-muted ellipsis', styles.spaceLeft)}
            >
              by {author}
            </span>
          )}
        </p>
      )}
    </li>
  );
};

Field.propTypes = {
  author: PropTypes.string,
  blankWhenEmpty: PropTypes.bool,
  label: PropTypes.string.isRequired,
  length: PropTypes.number,
  rightAlign: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.node,
  width: PropTypes.oneOf([
    'auto',
    'defaultWidth',
    'inherit',
    'wider1',
    'wider2',
    'wider3',
  ]),
};

Field.defaultProps = {
  author: null,
  blankWhenEmpty: false,
  length: null,
  rightAlign: false,
  value: null,
  children: null,
  width: 'defaultWidth',
};

export default Field;
