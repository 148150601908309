import moment from 'moment-timezone';
/*
Takes, an array of incidents
Returns, an object of the incidents grouped by the primaryEvent
The returned object shape, the keys are the ids of the primaryEvent, the values
are arrays of all the incidents that correspond to that primaryEvent
*/
export function groupIncidents(incidents = []) {
  return incidents.reduce((groups, incident) => {
    const { primaryEvent } = incident;

    if (groups[primaryEvent]) groups[primaryEvent].push(incident);
    else {
      groups[primaryEvent] = [incident];
    }
    return groups;
  }, {});
}

/**
 *
 * @param incident - the incident to pull the matching value from
 * @param type - the groupBy value the user has selected
 * @returns {*|string} - the incident's value based off the groupBy type, used for incident comparison and text in the header
 */
export function getTypeMatchValue(incident = {}, type) {
  switch (type) {
    case 'station': {
      return incident.station || 'No ADC';
    }
    case 'patient':
      return incident.patient?.fullName || 'No Patient';
    case 'user':
      return incident.user?.fullName || 'No User';
    default:
      // Date is default
      return incident.startTime
        ? moment(incident.startTime).format('L')
        : 'No Date';
  }
}

/**
 *
 * @param item - incident item
 * @param incidents - incident list
 * @returns {Array} updated incident list
 */
export function updatedIncidentList({ item = {}, incidents = [] }) {
  return incidents.map(inc => (inc.id === item.id ? item : inc));
}
