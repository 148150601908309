import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const FilterSectionHeader = ({ icon, title }) => (
  <h4 className={styles.header}>
    <i className={classnames('material-icons', styles.icon, icon)} />
    {title}
  </h4>
);

FilterSectionHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FilterSectionHeader;
