import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flagged } from '../index';

const AndThen = ({ then }) => {
  useEffect(() => {
    then();
  }, [then]);
  return null;
};

AndThen.propTypes = {
  then: PropTypes.func.isRequired,
};

const FlaggedAndThen = ({ flag, then, orElse }) => (
  <Flagged flag={flag} fallback={<AndThen then={orElse} />}>
    <AndThen then={then} />
  </Flagged>
);

FlaggedAndThen.propTypes = {
  flag: PropTypes.string.isRequired,
  then: PropTypes.func.isRequired,
  orElse: PropTypes.func,
};

FlaggedAndThen.defaultProps = {
  orElse: () => {},
};

export default FlaggedAndThen;
