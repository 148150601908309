import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import intersperse from '../../utils/intersperse';

export const Legend = ({ color, labels }) => {
  return (
    <div className={styles.legend}>
      <div className={styles.circle} style={{ background: color }} />
      <p className={styles.text}>
        {intersperse(labels, () => (
          <br key={Math.random()} />
        ))}
      </p>
    </div>
  );
};

const CustomLegends = ({ data = [] }) => {
  return (
    <div className={styles.legends}>
      {data.map((d, i) => (
        <Legend color={d.color} labels={d.labels} key={i} />
      ))}
    </div>
  );
};

Legend.propTypes = {
  color: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
};

CustomLegends.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CustomLegends;
