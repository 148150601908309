import React from 'react';
import PropTypes from 'prop-types';
import styles from './printableFilterSection.module.scss';

export const PrintableFilterSection = ({ label, value }) => {
  return (
    <>
      {value && (
        <div className={styles.printableFilterSection}>
          <strong>{label}</strong>
          <div className={styles.value}>{value}</div>
        </div>
      )}
    </>
  );
};

PrintableFilterSection.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default PrintableFilterSection;
