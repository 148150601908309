import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, decorate } from 'mobx';
import AnimateHeight from 'react-animate-height';
import Tooltip from '../Tooltip';
import styles from './index.module.scss';

class CustomDropdown extends React.Component {
  // Observables
  dropdownOpen = false;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick);
  }

  toggleFilters = () => {
    this.dropdownOpen = !this.dropdownOpen;
  };

  handleOutsideClick = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.dropdownOpen = false;
    }
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClick = () => {
    const { cb, children, menuDisabled } = this.props;

    if (cb) cb();
    if (!menuDisabled && children.props.children) this.toggleFilters();
  };

  render() {
    const {
      wrapperClass,
      buttonClass,
      buttonText,
      iClass,
      iTip,
      iPlace,
      children,
      'data-cy': dataCy,
    } = this.props;
    const cn = buttonClass ? buttonClass : 'icon prot-a';
    const button = (
      <div
        className={cn}
        onClick={this.handleClick}
        role="button"
        tabIndex="-2"
      >
        <i className={iClass} />
        {buttonText || null}
      </div>
    );

    return (
      <div className="customDropdown" ref={this.setWrapperRef}>
        {iTip ? (
          <Tooltip content={iTip} placement={iPlace}>
            {button}
          </Tooltip>
        ) : (
          button
        )}
        <AnimateHeight
          height={this.dropdownOpen ? 'auto' : 0}
          duration={250}
          className={wrapperClass}
          animationStateClasses={{
            staticHeightZero: styles.staticHeightZero,
          }}
          data-cy={dataCy}
        >
          <div onMouseLeave={() => (this.dropdownOpen = false)}>{children}</div>
        </AnimateHeight>
      </div>
    );
  }
}

CustomDropdown.propTypes = {
  'data-cy': PropTypes.string,
  wrapperClass: PropTypes.string,
  buttonClass: PropTypes.string,
  buttonText: PropTypes.element,
  iClass: PropTypes.string,
  iTip: PropTypes.string,
  iPlace: PropTypes.string,
  children: PropTypes.element,
  cb: PropTypes.func,
  menuDisabled: PropTypes.bool,
};

CustomDropdown.defaultProps = {
  menuDisabled: false,
};

decorate(CustomDropdown, {
  dropdownOpen: observable,
});

export default observer(CustomDropdown);
