import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';
import ActivityDescriptionStore from '../stores/ActivityDescriptionStore';

const DetailsCell = observer(({ data, link }) => {
  let desc = null;
  if (!Object.prototype.hasOwnProperty.call(data, 'eventDescription')) {
    if (data.activityId === undefined) desc = data.type;
    else
      desc =
        ActivityDescriptionStore.defsMap[data.activityId] ||
        `Could not find definition for activity ${data.activityId}`;
  }

  let innards = (
    <span className="access_desc">{desc || data.eventDescription}</span>
  );

  if (link) innards = <a href={link}>{innards}</a>;

  return <Cell>{innards}</Cell>;
});

DetailsCell.propTypes = {
  data: PropTypes.shape({
    eventDescription: PropTypes.string,
  }).isRequired,
  link: PropTypes.string,
};

DetailsCell.defaultProps = {
  link: undefined,
};

export default DetailsCell;
