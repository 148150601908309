import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import classnames from 'classnames';
import { DropdownList } from 'react-widgets';

import EventSubFilter from '../EventSubFilter';
import ExportMenu from '../../../ui/ExportMenu';
import Tooltip from '../../../ui/Tooltip';

import { useOptions, useSortOrder, useToggled, useTooltip } from './hooks';
import { makeNewSortQuery, makeNewLabelQuery } from './helpers';
import { sortOptions } from './const';

export const DisplayItem = ({ item }) => (
  <span>
    <strong>Display:</strong> {item.label}
  </span>
);

export const SortToggle = ({ onChange, value, sortOptions }) => (
  <Tooltip content="Toggle Sort">
    <button type="button" className="button" onClick={onChange} tabIndex="-1">
      <i
        className={classnames('material-icons', {
          'icon-access_time': sortOptions[value] === 'time',
          'icon-arrow_upward': sortOptions[value] === 'asc',
          'icon-arrow_downward': sortOptions[value] === 'desc',
        })}
      />
    </button>
  </Tooltip>
);

const ActivityOptions = ({
  storeFocus,
  isUserFocus,
  storeActiveLabel,
  storeSort,
  canViewSuspicionScore,
  canDownloadMedicationAdmin,
  patientId,
  userId,
  getQuery,
  downloadCSV,
  noData,
  eventTypes,
  downloadIncidents,
  routerContainerGo,
  visible,
}) => {
  const toggled = useToggled(visible);
  const options = useOptions(isUserFocus, canViewSuspicionScore);
  const tooltip = useTooltip(storeFocus, patientId, userId);

  const [sortOrder, setSortOrder] = useState(0);
  useSortOrder(storeSort, setSortOrder);

  const onLabelChange = newValue => {
    const newQuery = makeNewLabelQuery({
      newValue,
      isUserFocus,
      sortOptions,
      sortOrder,
      getQuery,
    });
    if (newQuery) routerContainerGo(undefined, newQuery);
  };

  const onSortChange = () => {
    const newQuery = makeNewSortQuery({
      sortOrder: (sortOrder + 1) % sortOptions.length,
      storeActiveLabel,
      isUserFocus,
      getQuery,
    });
    if (newQuery) routerContainerGo(undefined, newQuery);
  };

  const onExport = () => {
    // diversion users are shown a menu, privacy users simply download accesses
    if (!canDownloadMedicationAdmin) {
      downloadCSV('Accesses');
    }
  };

  return (
    <div
      className={classnames('activity_view__options', {
        active: visible,
        inactive: !visible && toggled,
      })}
    >
      <div>
        <ExportMenu
          wrapperClass="export-list"
          buttonClass={classnames('button float-right', {
            hidden: noData,
          })}
          cb={onExport}
          exportCb={eventType => {
            if (
              eventType === 'Incident Summary' ||
              eventType === 'Incident Summary & Event Details'
            )
              downloadIncidents(eventType);
            else downloadCSV(eventType);
          }}
          eventTypes={eventTypes}
          iTip={tooltip}
        />
        <div className="selectMenu">
          <DropdownList
            data={options}
            valueField="id"
            textField="label"
            value={storeActiveLabel}
            valueComponent={DisplayItem}
            onChange={onLabelChange}
          />
          <SortToggle
            value={sortOrder}
            onChange={onSortChange}
            sortOptions={sortOptions}
          />
          <EventSubFilter />
        </div>
      </div>
    </div>
  );
};

ActivityOptions.propTypes = {
  visible: PropTypes.bool,
  storeFocus: PropTypes.string,
  isUserFocus: PropTypes.bool,
  storeActiveLabel: PropTypes.string,
  storeSort: PropTypes.string.isRequired,
  canViewSuspicionScore: PropTypes.bool,
  canDownloadMedicationAdmin: PropTypes.bool,
  patientId: PropTypes.string,
  userId: PropTypes.string,
  getQuery: PropTypes.func,
  downloadCSV: PropTypes.func,
  noData: PropTypes.bool,
  eventTypes: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  downloadIncidents: PropTypes.func,
  routerContainerGo: PropTypes.func,
};

ActivityOptions.defaultProps = {
  visible: false,
  storeSort: '',
};

DisplayItem.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};

SortToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ActivityOptions;
