import PropTypes from 'prop-types';

const colorDefaults = {
  secondary: 'secondary',
  teal: 'teal',
};

const presetPropTypes = {
  topText: PropTypes.string,
  bottomText: PropTypes.string,
  extraText: PropTypes.string,
  color: PropTypes.string,
};

const presetDefaults = {
  topText: 'Unknown',
  bottomText: 'Unknown',
  extraText: '',
};

export { colorDefaults, presetPropTypes, presetDefaults };
