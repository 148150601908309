import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { MultiRecordViewContext } from '../../../mrv/Context';
import TopNav from '../../../ui/VisibleSystemQuickFilterWithVisibleSystemStore/Filter';

export function mapProps({
  caseVisibleSystemFilterStore,
  query,
  location,
  history,
}) {
  return {
    quickFilterOptions: caseVisibleSystemFilterStore.quickFilterOptions,
    isCaseView: location.pathname.includes('/case/'),
    isUserDetailsView: location.pathname.includes('/userDetails'),
    handleUpdateQuickFilterOptions:
      caseVisibleSystemFilterStore.handleUpdateQuickFilterOptions,
    hasMultipleSystems: caseVisibleSystemFilterStore.hasMultipleSystems,
    setQuickFilterOptionsFromQueryParams:
      caseVisibleSystemFilterStore.setQuickFilterOptionsFromQueryParams,
    allSelected: caseVisibleSystemFilterStore.allSelected,
    query,
    historyReplace: history.replace,
    pathname: location.pathname,
    sources: caseVisibleSystemFilterStore.sources,
    showRememberFilterButton: false,
    sourceIds: caseVisibleSystemFilterStore.sourceIds,
  };
}

export const CaseVisibleSystemQuickFilterContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: TopNav,
  })
);

export default CaseVisibleSystemQuickFilterContainer;
