import styles from './index.module.sass';
import { Modal } from '../../../ui';
import PropTypes from 'prop-types';
import React from 'react';

function BuildVersionInfo({ versionInfo }) {
  return (
    <Modal closable ignoreRoute>
      <div className={styles.modalTitle}>Build Version Info</div>
      <div className={styles.modalText}>
        {'Branch name: ' + versionInfo?.branch.toString()}
      </div>
      <div className={styles.modalText}>
        {'Commit: ' + versionInfo?.commit?.id.toString()}
      </div>
      <div className={styles.modalText}>
        {'Commit Time: ' + versionInfo?.commit?.time.toString()}
      </div>
    </Modal>
  );
}

export default BuildVersionInfo;

BuildVersionInfo.propTypes = {
  versionInfo: PropTypes.any,
};
