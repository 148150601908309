import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fromCamelCase, DateHelpers } from 'common';

import Detail from '../Detail';

const format = (date, timezone) =>
  date
    ? moment(date)
        .tz(timezone)
        .format('l h:mm A z')
    : undefined;

const TimeEntry = ({ timeEntry }) => {
  const {
    start,
    end,
    source,
    endReason,
    startReason,
    eventDate,
    eventDescription,
    location,
    other,
    relatedTimes,
  } = timeEntry;
  const timezone = DateHelpers.getCurrentAppUserTimezone();

  return (
    <ul className="list-unstyled">
      <Detail
        label={format(eventDate, timezone)}
        value={eventDescription}
        source={source}
      />
      <Detail
        label="Start Date"
        value={format(start, timezone) || 'No Start Time Provided'}
      />
      <Detail
        label="End Date"
        value={format(end, timezone) || 'No End Time Provided'}
      />
      {startReason && <Detail label="Start Reason" value={startReason} />}
      {endReason && <Detail label="End Reason" value={endReason} />}
      {location && <Detail label="Location" value={location} />}
      {relatedTimes &&
        Object.entries(relatedTimes).map(entry => (
          <Detail
            key={entry[0]}
            label={fromCamelCase(entry[0])}
            value={format(entry[1], timezone)}
          />
        ))}
      {other &&
        Object.entries(other).map(entry => (
          <Detail
            key={entry[0]}
            label={fromCamelCase(entry[0])}
            value={entry[1]}
          />
        ))}
    </ul>
  );
};

TimeEntry.propTypes = {
  timeEntry: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
    source: PropTypes.string,
    endReason: PropTypes.string,
    startReason: PropTypes.string,
    eventDate: PropTypes.shape({}),
    eventDescription: PropTypes.string,
    location: PropTypes.string,
    other: PropTypes.shape({}),
    relatedTimes: PropTypes.shape({}),
  }).isRequired,
};

export default TimeEntry;
