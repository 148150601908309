import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { MetadataDetail } from './MetadataDetail';
import { SINGLE_PATIENT, SINGLE_EMPLOYEE } from '../../stores/const';

/* eslint-disable no-unused-vars */
export const MultiRecordMetadataDetails = ({
  pageMode,
  metadataTypes,
  metadataTypeColumns,
}) => {
  if (pageMode === SINGLE_EMPLOYEE || pageMode === SINGLE_PATIENT)
    return (
      <div className={styles.metadataDetailDoubleColumnMode}>
        <div className={styles.metadataDetailColumn}>
          {metadataTypeColumns[0]?.map((metadata, i) => {
            return <MetadataDetail key={metadata.title} metadata={metadata} />;
          })}
        </div>
        <div className={styles.metadataDetailColumn}>
          {metadataTypeColumns[1]?.map((metadata, i) => {
            return <MetadataDetail key={metadata.title} metadata={metadata} />;
          })}
        </div>
      </div>
    );
  return (
    <div className={styles.metadataDetailSingleColumnMode}>
      {metadataTypes?.map((metadata, i) => {
        return <MetadataDetail key={metadata.title} metadata={metadata} />;
      })}
    </div>
  );
};

MultiRecordMetadataDetails.propTypes = {
  metadataTypes: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  metadataTypeColumns: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.array),
  pageMode: PropTypes.string,
};
