import { action, autorun, computed, decorate, observable } from 'mobx';
import $ from 'jquery';

import {
  PagedStore,
  PermissionStore,
  AuthorizationClient,
  DownloadUtils,
} from 'common';

const DEFAULT_SORT = ['lastModified,desc'];

const parseSort = sort => {
  const splits = sort.split(',');
  return {
    sortBy: splits[0],
    sortDir: splits[1],
  };
};

class AuthorizationsStore extends PagedStore {
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  initialized = false;
  sort = DEFAULT_SORT;
  fullName;
  userId;
  patientId;
  temporaryPermissions;

  // Computed
  get sortBy() {
    const { sortBy } = parseSort(this.sort[0]);
    return sortBy;
  }

  // Computed
  get sortDir() {
    const { sortDir } = parseSort(this.sort[0]);
    return sortDir;
  }

  // Action
  setFilters(query, temporaryPermissions) {
    const { name, user, patient, sort } = query;
    const newSort = sort ? [sort] : DEFAULT_SORT;

    const { sortBy, sortDir } = parseSort(newSort[0]);

    /*
     * Special handling for revocationDate (status - revocationDate)
     */
    if (sortBy === 'revocationDate') {
      newSort.push(`requestedEnd,${sortDir === 'desc' ? 'asc' : 'desc'}`);
    }

    this.sort = newSort;

    // Set user and/or patient IDs
    this.fullName = name;
    this.userId = user;
    this.patientId = patient;

    this.temporaryPermissions = temporaryPermissions;

    this.initialized = true;
  }

  path(temporaryPermissions) {
    let path = '/authorizations';

    if (temporaryPermissions) path += '/temporary';

    const params = {};
    if (this.userId) params.user = this.userId;
    if (this.patientId) params.patient = this.patientId;
    if (this.fullName) params.name = this.fullName;

    const paramString = $.param(params, true);
    return paramString ? `${path}?` + paramString : path;
  }

  clear() {
    super.clear();
    this.setFilters({}, this.temporaryPermissions);
  }

  downloadCSV() {
    const params = { size: 10000, type: 'TEMPORARY_PERMISSION' };
    if (this.userId) params.user = this.userId;
    if (this.patientId) params.patient = this.patientId;

    // if we're not looking at temporary permissions, exclude them
    if (!this.temporaryPermissions) params.excludeFilters = ['type'];

    const csvHref = AuthorizationClient.url('export', params);
    DownloadUtils.downloadFromServer(
      csvHref,
      this.temporaryPermissions
        ? 'TemporaryPermissions.csv'
        : 'AuthorizedAccesses.csv'
    );
  }

  reset() {
    this.initialized = false;
    this.sort = DEFAULT_SORT;
    this.fullName = null;
    this.userId = null;
    this.patientId = null;
    this.temporaryPermissions = null;
  }

  fetch() {
    if (!PermissionStore.has('ACCESS_AUTH_SEARCH') || !this.initialized)
      return [];

    const params = {
      sort: this.sort.toJSON(),
      size: 30,
      type: 'TEMPORARY_PERMISSION',
    };

    // if we're not looking at temporary permissions, exclude them
    if (!this.temporaryPermissions) params.excludeFilters = ['type'];

    if (this.patientId) params.patient = this.patientId;
    if (this.userId) params.user = this.userId;

    if (PermissionStore.has('ACCESS_AUTH_SEARCH_ALL'))
      return AuthorizationClient.getReport(params);

    return [];
  }
}

decorate(AuthorizationsStore, {
  setFilters: action,
  sortBy: computed,
  sortDir: computed,
  fullName: observable,
  initialized: observable,
  patientId: observable,
  sort: observable,
  temporaryPermissions: observable,
  userId: observable,
  reset: action,
});

export default new AuthorizationsStore();
