import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CaseReportsPropTypes from '../PropTypes';
import {
  LineSegment,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryVoronoiContainer,
} from 'victory';
import { ReportsBarChartTheme } from './BarChartTheme';
import { DateHelpers, Pandy } from 'common';
import styles from './index.module.scss';
import { useReactiveRef } from '../../utils/hooks';
import { Tooltip } from '../../ui';
import { NO_RESULTS_TEXT, TOO_MANY_RESULTS_TEXT } from '../stores/const';

const LineChart = ({
  chartData = [],
  configuration,
  domainPadding = 100,
  height = 0,
  width = 0,
}) => {
  const [ref, setRef] = useReactiveRef();
  const [tooltipLabels, setTooltipLabels] = useState([]);

  if (chartData.length > configuration.maxLines) {
    return (
      <Pandy visible mood="sad" className={styles.pandyWrapper}>
        <span>{TOO_MANY_RESULTS_TEXT}</span>
      </Pandy>
    );
  }

  if (!chartData.length) {
    return (
      <Pandy visible mood="sad" className={styles.pandyWrapper}>
        <span>{NO_RESULTS_TEXT}</span>
      </Pandy>
    );
  }

  return (
    <>
      <Tooltip
        reference={ref}
        content={tooltipLabels}
        followCursor
        hideOnClick={false}
        className={styles.tooltipOverrides}
      />
      <VictoryChart
        domainPadding={{ x: domainPadding }}
        padding={{
          top: 20,
          bottom: 30,
          left: 35,
        }}
        height={height}
        width={width}
        theme={ReportsBarChartTheme}
        containerComponent={
          <VictoryVoronoiContainer
            onActivated={points => {
              setTooltipLabels(
                <div>
                  {(points || []).map(({ bucketName, y }) => (
                    <div key={bucketName} className={styles.tooltipRow}>
                      <span className={styles.tooltipLabel}>{bucketName}:</span>
                      <span>{y}</span>
                    </div>
                  ))}
                  <div className={styles.tooltipTitle}>
                    {points.length > 0 && points[0].xName}
                  </div>
                </div>
              );
            }}
            containerRef={setRef}
            voronoiDimension="x"
          />
        }
      >
        <VictoryAxis
          dependentAxis
          axisComponent={<LineSegment style={{ strokeWidth: '0px' }} />}
          tickFormat={y => (Number.isInteger(y) ? y : '')}
        />
        <VictoryAxis
          gridComponent={<LineSegment style={{ strokeWidth: '0px' }} />}
          tickFormat={x => DateHelpers.trimYearFromDateRange(x)}
        />
        <VictoryGroup>
          {chartData.map((d, i) => (
            <VictoryLine key={i} data={d} />
          ))}
        </VictoryGroup>
      </VictoryChart>
    </>
  );
};

LineChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  configuration: CaseReportsPropTypes.configuration,
  domainPadding: PropTypes.number,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default LineChart;
