import React from 'react';
import PropTypes from 'prop-types';
import CaseReportsPropTypes from '../PropTypes';
import Table from '../../ui/Table';
import Chart from './Chart';
import Header from './Header';
import styles from './index.module.scss';

const ChartAndTable = ({
  averageData,
  casesBy,
  chartBarOffset,
  chartData,
  chartDomainPadding,
  chartType,
  configuration,
  disabledIntervals,
  downloadCSV,
  highlightLastRow = true,
  interval,
  legendData,
  setChartCasesBy,
  setChartInterval,
  setChartStatisticCalculation,
  showChartStatisticCalculation,
  showChartCasesBy,
  showIntervalFilter,
  showLegend,
  skipAlphaNumericSort,
  statisticCalculation,
  tableCollapsed = false,
  tableData,
  title,
  type,
  'data-cy': dataCy,
}) => (
  <div>
    <div className={styles.wrapperContainer} data-cy={dataCy}>
      <Header
        action={downloadCSV}
        casesBy={casesBy}
        disabledIntervals={disabledIntervals}
        interval={interval}
        setChartCasesBy={setChartCasesBy}
        setChartInterval={setChartInterval}
        showChartCasesBy={showChartCasesBy}
        setChartStatisticCalculation={setChartStatisticCalculation}
        showChartStatisticCalculation={showChartStatisticCalculation}
        showIntervalFilter={showIntervalFilter}
        statisticCalculation={statisticCalculation}
        title={title}
        type={type}
      />
      <Chart
        averageData={averageData}
        chartData={chartData}
        chartType={chartType}
        chartDomainPadding={chartDomainPadding}
        chartBarOffset={chartBarOffset}
        configuration={configuration}
        legendData={legendData}
        showLegend={showLegend}
        skipAlphaNumericSort={skipAlphaNumericSort}
      />
      {!tableCollapsed && !!chartData.length && (
        <div className={styles.tableWrapper} data-cy="table-wrapper">
          <Table
            className={styles.table}
            data={tableData}
            disableSelect
            highlightLastRow={highlightLastRow}
            noDefaultStyles
            scrollable
            scrollableMaxRows={configuration.maxRowsBeforeScroll}
          />
        </div>
      )}
    </div>
  </div>
);

ChartAndTable.propTypes = {
  averageData: PropTypes.string,
  casesBy: PropTypes.string,
  chartBarOffset: PropTypes.number,
  chartData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  chartDomainPadding: PropTypes.number,
  chartType: PropTypes.string,
  configuration: CaseReportsPropTypes.configuration,
  downloadCSV: PropTypes.func.isRequired,
  disabledIntervals: PropTypes.arrayOf(PropTypes.string),
  highlightLastRow: PropTypes.bool,
  interval: PropTypes.string,
  legendData: PropTypes.arrayOf(PropTypes.shape({})),
  setChartCasesBy: PropTypes.func,
  setChartInterval: PropTypes.func,
  showChartCasesBy: PropTypes.bool,
  showIntervalFilter: PropTypes.bool,
  showLegend: PropTypes.bool,
  setChartStatisticCalculation: PropTypes.func,
  showChartStatisticCalculation: PropTypes.bool,
  skipAlphaNumericSort: PropTypes.bool,
  statisticCalculation: PropTypes.string,
  tableCollapsed: PropTypes.bool,
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  'data-cy': PropTypes.string,
};

export default ChartAndTable;
