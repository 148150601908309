import CaseReportsChartAndTableBaseStore from '../CaseReportsChartAndTableBaseStore';

class CaseReportsCaseResolutionsOverTimeStore extends CaseReportsChartAndTableBaseStore {
  get title() {
    return 'Case Resolutions Over Time';
  }

  get groupBy() {
    return ['created', 'resolution'];
  }

  get primaryField() {
    return 'resolution';
  }

  get chartDataGroupBy() {
    return [this.primaryFieldDisplayName, this.pivotDisplayName];
  }

  get chartDataX() {
    return datum => datum.timeRangeColumnName;
  }

  get legendValueGetter() {
    return ({
      [this.primaryFieldDisplayName]: primary = '',
      [this.pivotDisplayName]: pivot = '',
    }) => [primary, pivot].filter(Boolean);
  }
}

export default CaseReportsCaseResolutionsOverTimeStore;
