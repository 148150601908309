import CaseReportsChartAndTableBaseStore from '../CaseReportsChartAndTableBaseStore';
import { PRIVACY_PRODUCT } from 'common';

class CaseReportsCaseResolutionsStore extends CaseReportsChartAndTableBaseStore {
  constructor({
    caseClient,
    caseReportsFilterSelectionsStore,
    groupStore,
    processParams,
    permissionStore,
  }) {
    super({
      caseClient,
      caseReportsFilterSelectionsStore,
      groupStore,
      processParams,
    });
    this.permissionStore = permissionStore;
  }
  get title() {
    return 'Case Resolutions';
  }

  get groupBy() {
    return ['resolution'];
  }

  get primaryField() {
    return 'resolution';
  }

  get needsZeroCounts() {
    return true;
  }

  get zeroCountExclusions() {
    // Note: if services surfaces violation+false positive or violation+good catch then it will not be excluded
    const exclusions = {
      undefined: [
        'DIVERSION',
        'POLICY',
        'PRIVACY',
        'FALSE_POSITIVE',
        'GOOD_CATCH',
      ],
      VIOLATION: ['FALSE_POSITIVE', 'GOOD_CATCH'],
      NOT_VIOLATION: ['DIVERSION', 'POLICY', 'PRIVACY', undefined],
    };
    // combinations of violation/no resolution description are only possible if user has access to the privacy product
    if (!this.permissionStore.getProducts().includes(PRIVACY_PRODUCT)) {
      exclusions.VIOLATION.push(undefined);
    }
    return exclusions;
  }

  get casesTotal() {
    return this.tableData.length > 0
      ? this.tableData[this.tableData.length - 1].total
      : 0;
  }
}

export default CaseReportsCaseResolutionsStore;
