import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import queryString from 'query-string';

import ExclusionMultiselect from '../Filters/ExclusionMultiselect';
import FilterSectionHeader from '../FilterSectionHeader';

const UserFilters = ({
  history,
  location,
  query,
  loadOrganizations,
  loadDepartments,
  queryForSelectionChange,
  queryForToggle,
  organizationOptions,
  organizationSelections,
  departmentOptions,
  departmentSelections,
  roleOptions,
  roleSelections,
  roleExcluded,
  tagOptions,
  tagSelections,
  tagExcluded,
  disableAllExclusion,
}) => {
  useEffect(() => {
    loadOrganizations();
    loadDepartments();
  }, [loadOrganizations, loadDepartments]);

  const navigateOnChange = newQuery => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ ...query, ...newQuery }),
    });
  };

  return (
    <section>
      <FilterSectionHeader icon="icon-hospital_building" title="EMR Users" />
      <ul className="userFilters">
        {/*Organization*/}
        <ExclusionMultiselect
          label="Organization"
          options={organizationOptions || []}
          value={organizationSelections}
          hideExclusion
          onChange={values => {
            navigateOnChange(
              queryForSelectionChange('userOrganization', values)
            );
          }}
          textField="name"
          valueField="id"
          dropUp
          multiselectProps={{
            filter: 'contains',
            messages: {
              emptyList: organizationOptions
                ? 'No Organizations Found'
                : 'Retrieving organizations...',
            },
          }}
        />

        {/*Department*/}
        <ExclusionMultiselect
          label="Department"
          options={departmentOptions || []}
          value={departmentSelections}
          hideExclusion
          onChange={values => {
            navigateOnChange(queryForSelectionChange('userDepartment', values));
          }}
          textField="name"
          valueField="id"
          dropUp
          multiselectProps={{
            filter: 'contains',
            messages: {
              emptyList: departmentOptions
                ? 'No Departments Found'
                : 'Retrieving departments...',
            },
          }}
        />

        {/*Role*/}
        {roleOptions.length > 0 && (
          <ExclusionMultiselect
            label="User Type"
            options={roleOptions || []}
            value={roleSelections}
            onChange={values => {
              navigateOnChange(queryForSelectionChange('userType', values));
            }}
            onToggleExclusion={() => {
              navigateOnChange(queryForToggle('userType'));
            }}
            isExcluding={roleExcluded}
            textField="name"
            valueField="id"
            dropUp
            hideExclusion={disableAllExclusion}
          />
        )}

        {/*Tag*/}
        {tagOptions.length > 0 && (
          <ExclusionMultiselect
            label="Tag"
            options={tagOptions || []}
            value={tagSelections}
            onChange={values => {
              navigateOnChange(queryForSelectionChange('userTags', values));
            }}
            onToggleExclusion={() => {
              navigateOnChange(queryForToggle('userTags'));
            }}
            isExcluding={tagExcluded}
            textField="name"
            valueField="id"
            dropUp
            hideExclusion={disableAllExclusion}
          />
        )}
      </ul>
    </section>
  );
};

UserFilters.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  query: PropTypes.object.isRequired,

  loadDepartments: PropTypes.func.isRequired,
  loadOrganizations: PropTypes.func.isRequired,
  queryForToggle: PropTypes.func.isRequired,
  queryForSelectionChange: PropTypes.func.isRequired,

  departmentOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  departmentSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  departmentExcluded: PropTypes.bool,

  organizationOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  organizationSelections: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ).isRequired,
  organizationExcluded: PropTypes.bool,

  roleOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  roleSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  roleExcluded: PropTypes.bool,

  tagOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  tagSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  tagExcluded: PropTypes.bool,

  disableAllExclusion: PropTypes.bool,
};

export default UserFilters;
