import DataFeedEvaluationNav from '../DataFeedEvaluationNav';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { DataFeedContext } from '../Context';

export function mapProps({ history, location, query, evaluationStore }) {
  return {
    history,
    location,
    query,
    createdAfter: evaluationStore.createdAfter,
  };
}

const DataFeedEvaluationNavContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DataFeedContext,
    mapProps,
    PresentationalComponent: DataFeedEvaluationNav,
  })
);

export default DataFeedEvaluationNavContainer;
