import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Anchor, Checkbox } from '../index';
import { capitalizeFirstLetter } from 'common';
import AnimateHeight from 'react-animate-height';
import quickFilterPropTypeDefaults from './propTypes';
import classnames from 'classnames';

/**
 *
 * @param handleOnClick {Function}
 * @param count {String}
 * @param isCaseView {Boolean}
 * @returns {*}
 * @constructor
 */
export const DropdownButton = ({ handleOnClick, count = '', isCaseView }) => {
  return (
    <div
      className={classnames(styles.button, {
        [styles.buttonForUserDetailsFilter]: isCaseView,
      })}
      role="button"
      onClick={handleOnClick}
    >
      <div className={styles.buttonInner}>
        <span>Visible Systems</span>
        <p>{count}</p>
        <i className="material-icons icon-arrow_drop_down" />
      </div>
    </div>
  );
};

DropdownButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  count: PropTypes.string,
  isCaseView: PropTypes.bool,
};

/**
 *
 * @param data {Array}
 * @param handleCheckboxOnChange {Function}
 * @param handleSetThisSystemOnly {Function}
 * @param handleSetLocalStorage {Function}
 * @param showRememberFilterButton {Boolean}
 * @param allSelected {Boolean}
 * @param isCaseView {Boolean}
 * @returns {*}
 * @constructor
 */

const VisibleSystemQuickFilter = ({
  data = [],
  allSelected,
  handleCheckboxOnChange,
  handleSetThisSystemOnly,
  handleSetLocalStorage,
  showRememberFilterButton,
  isCaseView,
}) => {
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState('');

  // handles calculating count
  useEffect(() => {
    const total = data.length;
    const enabled = data.filter(d => d.enabled).length;

    if (total) setCount(`${enabled} / ${total}`);
  }, [data]);

  // handles auto-closing
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = e => {
    e.stopPropagation();
    if (!dropdownRef?.current?.contains(e.target)) setIsOpen(false);
  };

  const toggleDropdown = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const sourceIds = data.map(d => d.id);
  const enabledOptions = data.filter(source => source.enabled);

  return (
    <div className={styles.wrapper} ref={dropdownRef}>
      <DropdownButton
        handleOnClick={e => toggleDropdown(e)}
        count={count}
        isCaseView={isCaseView}
      />
      <ul
        className={classnames(styles.dropdownRow, {
          [styles.dropdownRowForUserDetailsFilter]: isCaseView,
        })}
      >
        <div
          className={classnames({
            [styles.rememberFiltersOpen]: showRememberFilterButton,
          })}
        >
          <AnimateHeight height={isOpen ? 'auto' : 0} duration={250}>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                color="warning"
                onChange={() => handleCheckboxOnChange(sourceIds)}
                checked={allSelected || false}
                indeterminate={!allSelected && enabledOptions.length > 0}
                label={
                  <span className={styles.allSystemsLabel}>All Systems</span>
                }
              />
              {data.map(d => (
                <li key={d.id}>
                  <Checkbox
                    color="warning"
                    onChange={() => handleCheckboxOnChange(d.id)}
                    checked={d.enabled || false}
                    label={
                      <span className={styles.checkboxLabel}>
                        {capitalizeFirstLetter(d.name)}
                      </span>
                    }
                  />
                  <span className={styles.spacer} />
                  <Anchor
                    tag="a"
                    role="button"
                    className={styles.thisSystemOnly}
                    onClick={() => handleSetThisSystemOnly(d.id)}
                  >
                    This System Only
                  </Anchor>
                </li>
              ))}
            </div>
            {showRememberFilterButton && (
              <div className={styles.rememberContainer}>
                <span className={styles.border} />
                <div
                  className={styles.rememberButton}
                  role="button"
                  onClick={handleSetLocalStorage}
                >
                  Remember Visible System Settings
                </div>
              </div>
            )}
          </AnimateHeight>
        </div>
      </ul>
    </div>
  );
};

VisibleSystemQuickFilter.propTypes = quickFilterPropTypeDefaults;

export default VisibleSystemQuickFilter;
