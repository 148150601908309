import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { CustomerAdministeredAccountsContext } from '../stores/Context';
import CustomerAdministeredAccountsListView from '../CustomerAdministeredAccountsListView';

export function mapProps({
  createUpdateAsideVisible,
  customerAdministeredAccountsListStore,
  history,
  query,
}) {
  return {
    appUsers: customerAdministeredAccountsListStore.appUsers,
    createRoute: customerAdministeredAccountsListStore.createRoute,
    createUpdateAsideVisible,
    customerAdministeredAccountsListStore,
    hideProtenusUsers: customerAdministeredAccountsListStore.hideProtenusUsers,
    history,
    isAdmin: customerAdministeredAccountsListStore.isAdmin,
    query,
    setFilters: customerAdministeredAccountsListStore.setFilters,
    showHideProtenusUsersLinkText:
      customerAdministeredAccountsListStore.showHideProtenusUsersLinkText,
    showHideProtenusUsersLinkQueryString:
      customerAdministeredAccountsListStore.showHideProtenusUsersLinkQueryString,
    totalAppUsers: customerAdministeredAccountsListStore.totalElements,
    updateRoute: customerAdministeredAccountsListStore.updateRoute,
    viewRoute: customerAdministeredAccountsListStore.viewRoute,
  };
}

const CustomerAdministeredAccountsListViewContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CustomerAdministeredAccountsContext,
    mapProps,
    PresentationalComponent: CustomerAdministeredAccountsListView,
  })
);

CustomerAdministeredAccountsListViewContainer.displayName =
  'CustomerAdministeredAccountsListViewContainer';

export default CustomerAdministeredAccountsListViewContainer;
