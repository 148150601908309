import $ from 'jquery'

class BaseURL {
  service(relPath, parameters) {
    let path
    if (/^http/.test(relPath)) {
      path = relPath
    } else {
      path = '/services/v1/' + relPath
    }

    if (parameters) path += '?' + $.param(parameters, true)
    return path
  }
}

const me = new BaseURL()
export { me as BaseURL }
export default me
