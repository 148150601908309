import React from 'react';
import { observer } from 'mobx-react';
import CaseIntegrationSync from '../CaseIntegrationSync';
import CaseStore from '../stores/CaseStore';
import CaseLogStore from '../stores/CaseLogStore';
import { CaseIntegrationStore } from '../../settings/stores/CaseIntegrationStore';

export function mapProps(CaseStore, CaseLogStore, CaseIntegrationStore) {
  return {
    externalId: CaseStore.externalId,
    lastSyncDate: CaseLogStore.lastSyncDate,
    radarLink: CaseStore.radarLink,
    sync: CaseStore.sync,
    isSyncing: CaseStore.isSyncing,
    syncLoadingMsg: CaseStore.syncLoadingMsg,
    integrationType: CaseIntegrationStore.integrationType,
    integrationLabel: CaseIntegrationStore.integrationLabel,
  };
}

const CaseIntegrationSyncContainer = observer(() => (
  <CaseIntegrationSync
    {...mapProps(CaseStore, CaseLogStore, CaseIntegrationStore)}
  />
));

CaseIntegrationSyncContainer.displayName = 'CaseIntegrationSyncContainer';

export default CaseIntegrationSyncContainer;
