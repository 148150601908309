import { computed, decorate } from 'mobx';

/**
 * Certain browsers have a different rendering technique that causes SVG elements to be drawn at a
 * different location. This utility class will adjust the element's positioning.
 */
class BrowserAdjustments {
  /**
   * COMPUTED
   * Whether or not the current browser is made by MS
   */
  get isMicrosoft() {
    return (
      /Edge\/\d./i.test(navigator.userAgent) ||
      (window.MSInputMethodContext && document.documentMode)
    );
  }

  /**
   * COMPUTED
   * The spacing adjustment in pixels needed for row elements (accesses, cases, auth accesses)
   */
  get rowSpacing() {
    return this.isMicrosoft ? 6 : 0;
  }
}

decorate(BrowserAdjustments, {
  isMicrosoft: computed,
  rowSpacing: computed,
});

export default new BrowserAdjustments();
