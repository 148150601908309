// basic
export const INCIDENTS_LABEL = 'Incidents';
export const INCIDENT_IDS_LABEL = 'Incident Id Included';
export const INCIDENT_TIME_RANGES_LABEL = 'Time Ranges';
export const INCIDENT_STATUSES_LABEL = 'Statuses';
export const INCIDENT_TYPES_LABEL = 'Incident Types';

// main incidents print options
export const INCIDENTS_ALL = 'all';
export const INCIDENTS_CUSTOM = 'custom';
export const INCIDENTS_ID = 'incidentId';
export const INCIDENTS_NONE = 'none';

// react widget specific
export const INCIDENTS_ID_PLACEHOLDER = 'Type to Search by Incident Id';
export const INCIDENTS_VALUE_FIELD = 'value';
export const INCIDENTS_TEXT_FIELD = 'name';

// custom filter specific
export const INCIDENT_TYPES_ALL = 'All Incident Types';
export const INCIDENT_STATUSES_ALL = 'All Statuses';
export const INCIDENT_TIME_RANGES_ALL = 'All Time Ranges';
