import {
  action,
  autorun,
  computed,
  decorate,
  observable,
  reaction,
} from 'mobx';
import GeneralSettingsStore from '../GeneralSettingsStore';

class NewAppExperienceSettings extends GeneralSettingsStore {
  category = 'services.read';
  key = 'general.newAppExperience';

  constructor() {
    super();

    autorun(() => {
      this.refresh();
    }, true);

    reaction(
      () => [this.appSettings],
      () => {
        Object.keys(this.appSettingsEditValues).forEach(name => {
          this.appSettingsEditValues[name] = this.appSettings[name];
        });
      }
    );
  }

  // observable
  appSettingsEditValues = {
    casePage: false,
  };

  // Computed
  get settings() {
    const { properties } = this.response || {};
    return properties || {};
  }

  get appSettings() {
    // The value is stored as strings in the DB, it will be either 'true' or 'false'. This line is to make sure we are getting a true value check and coercing it to a boolean.
    return {
      casePage: this.settings.casePage === 'true',
    };
  }

  get isDirty() {
    return Object.entries(this.appSettings)
      .map(([key, value]) => value === this.appSettingsEditValues[key])
      .includes(false);
  }

  // action
  toggleValue = name => {
    this.appSettingsEditValues = {
      ...this.appSettingsEditValues,
      [name]: !this.appSettingsEditValues[name],
    };
  };

  updateSettings = () => {
    const newObj = Object.fromEntries(
      Object.entries(this.appSettingsEditValues).filter(
        ([key, value]) => this.appSettings[key] !== value
      )
    );

    this.update(newObj);
  };
}

decorate(NewAppExperienceSettings, {
  toggleValue: action,
  appSettingsEditValues: observable,
  isDirty: computed,
  appSettings: computed,
  settings: computed,
});

const NewAppExperienceStore = new NewAppExperienceSettings();
export default NewAppExperienceStore;
