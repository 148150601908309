export const TYPE_ENUMS = {
  unknown: 'UNKNOWN',
  string: 'STRING',
  email: 'EMAIL',
  address: 'ADDRESS',
  personLink: 'PERSON_LINK',
  date: 'DATE',
  object: 'OBJECT',
  empty: 'EMPTY',
  filtered: 'FILTERED',
  multiPersonLink: 'MULTI_PERSON_LINK',
  activityLink: 'ACTIVITY_LINK',
};

export const FILTERED_TEXT = 'Filtered off';
