import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders to elements in a row. Ideal for rendering a header with content that
 * appears to float to its right.
 *
 * The left one will take up as much space as it can.
 * The right one will only take as much space as it needs.
 */

const FlexRow = ({ left, right }) => {
  return (
    <div className="flex-row">
      {left}
      {right}
    </div>
  );
};

FlexRow.propTypes = {
  left: PropTypes.node.isRequired,
  right: PropTypes.node,
};

FlexRow.defaultProps = {
  right: null,
};

export default FlexRow;
