import { computed, decorate, reaction } from 'mobx';
import moment from 'moment';
import { SingletonStore, CaseClient, LoginStore } from 'common';

import CaseStore from '../CaseStore';

class CaseViewStore extends SingletonStore {
  constructor({ LoginStore }) {
    super();
    reaction(
      () => [CaseStore.case],
      () => this.refresh()
    );
    this.loginStoreId = LoginStore.id;
  }

  fetch() {
    if (CaseStore.caseId) return CaseClient.getViews(CaseStore.caseId);
    return [];
  }

  // Computed
  get views() {
    return this.result || [];
  }

  // Computed
  get lastViewedByUser() {
    if (this.result === null || this.loading) {
      // This is to prevent flashing of 'NEW' icon when fetching request.
      return new Date().toISOString();
    }

    const lastView = this.views
      .filter(
        ({ created, createdBy }) =>
          // ignore the user's most recent page view when they loaded the page
          moment(created) < moment(Date.now() - 30000) &&
          createdBy.id === this.loginStoreId
      )
      .shift();

    // Fall back to epoch date string if we don't find any views for this
    // person.
    return lastView?.created || new Date(0).toISOString();
  }
}

decorate(CaseViewStore, {
  views: computed,
  lastViewedByUser: computed,
});

export default new CaseViewStore({ LoginStore });
