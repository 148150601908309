import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { RouterContainer } from 'common';

const NavBarItem = observer(
  class extends React.Component {
    static propTypes = {
      active: PropTypes.bool,
      children: PropTypes.node,
      className: PropTypes.string,
      disabled: PropTypes.bool,
      external: PropTypes.bool,
      href: PropTypes.string,
      onClick: PropTypes.func,
      route: PropTypes.string,
      getRoute: PropTypes.func,
      innerRef: PropTypes.func,
    };

    static defaultProps = {
      active: undefined,
      className: undefined,
      disabled: undefined,
      external: undefined,
      href: undefined,
      onClick: undefined,
      route: undefined,
      getRoute: undefined,
      innerRef: undefined,
    };

    get isActive() {
      if (this.props.active !== undefined) return this.props.active;

      const curPath = RouterContainer.path || '/';
      const curSearch = RouterContainer.search || '';
      const active =
        !!this.props.route &&
        (curPath === this.props.route ||
          curPath + curSearch === this.props.route);

      return active;
    }

    renderContent() {
      const { route, getRoute, onClick, href, external, disabled } = this.props;

      let to = '#';
      let target = undefined;

      if (route) to = route;
      else if (getRoute) to = getRoute();
      if (href) {
        target = external ? '_blank' : '_self';
        to = href;
      }

      const clickfunc = onClick || null;

      if (external && to) {
        return (
          <a href={to} target="_blank" rel="noopener noreferrer">
            {this.props.children}
          </a>
        );
      } else if (to !== '#' || clickfunc) {
        return (
          <Link to={to} onClick={clickfunc} disabled={disabled} target={target}>
            {this.props.children}
          </Link>
        );
      }

      return <span disabled={disabled}>{this.props.children}</span>;
    }

    render() {
      const cn = classnames(this.props.className, {
        active: this.isActive,
      });

      /* eslint-disable no-unused-vars */
      const {
        onClick,
        href,
        route,
        getRoute,
        className,
        children,
        active,
        external,
        innerRef,
        ...props
      } = this.props;

      return (
        <li ref={innerRef} className={cn} {...props} data-cy="nav-item">
          {this.renderContent()}
        </li>
      );
    }
  }
);

const NavBarItemWithForwardRef = forwardRef((props, ref) => (
  <NavBarItem {...props} innerRef={ref} />
));
NavBarItemWithForwardRef.displayName = 'NavBarItem';

export default NavBarItemWithForwardRef;
