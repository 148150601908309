import DataFeedDeliveryView from '../DataFeedDeliveryView';
import { DataFeedContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({ deliveryStore, query, history }) {
  return {
    failure: deliveryStore.failure,
    failureStatus: deliveryStore.failureStatus,
    setQueryFromURI: deliveryStore.setQueryFromURI,
    totalElements: deliveryStore.totalElements,
    query,
    history,
  };
}

const DataFeedDeliveryViewContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DataFeedContext,
    mapProps: mapProps,
    PresentationalComponent: DataFeedDeliveryView,
  })
);

export default DataFeedDeliveryViewContainer;
