import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const FormattedDateOfDeath = ({ dateOfDeath, deceased }) => {
  if (!dateOfDeath && !deceased) return null;

  const status = dateOfDeath
    ? moment(dateOfDeath)
        .utc()
        .format('l')
    : 'Deceased (Date Unknown)';
  return <p>{status}</p>;
};

FormattedDateOfDeath.propTypes = {
  dateOfDeath: PropTypes.string,
  deceased: PropTypes.bool,
};

FormattedDateOfDeath.defaultProps = {
  dateOfDeath: undefined,
  deceased: false,
};

export default FormattedDateOfDeath;
