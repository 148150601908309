import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CaseflowContainer from '../CaseflowContainer';
import IncidentsContainer from '../IncidentsContainer';
import AssessmentsForCaseTypeContainer from '../AssessmentsForCaseTypeContainer';
import AccessesContainer from '../AccessesContainer';
import UserPatientDetailsContainer from '../UserPatientDetailsContainer';
import styles from './index.module.scss';
import { VisibleSystemStore, LoginStore } from 'common';
import { MultiRecordViewContext } from '../../../mrv/Context';
import { useEphemeralStore } from '../../../utils/hooks';
import { MultiRecordMainStore } from '../../../mrv/stores/MultiRecordMainStore';
import { getPageMode } from '../../../mrv/stores/utils/RouteUtils';
import { MultiRecordPersonHeaderStore } from '../../../mrv/stores/MultiRecordPersonHeaderStore';
import { MultiRecordPersonMetadataStore } from '../../../mrv/stores/MultiRecordPersonMetadataStore';
import MultiRecordViewClient from 'common/src/app/clients/MultiRecordViewClient';
import {
  PERSON_TYPE_USER,
  PERSON_TYPE_PATIENT,
} from '../../../mrv/stores/const';
import { MultiRecordUserPatientDetailsContainer } from '../../../mrv/MultiRecordUserPatientDetailsContainer';
import CaseStore from '../../stores/CaseStore';
import Store from '../../../activity/stores/Store';

const Switcher = ({ match, caseType }) => {
  const path = match.path.replace(/[/]$/, '');
  const multiRecordMainStore = useEphemeralStore(
    () => new MultiRecordMainStore({ getPageMode })
  );

  const multiRecordPersonHeaderStore = useEphemeralStore(
    () =>
      new MultiRecordPersonHeaderStore({
        multiRecordViewClient: MultiRecordViewClient,
      })
  );

  const multiRecordPatientMetadataStore = useEphemeralStore(
    () =>
      new MultiRecordPersonMetadataStore({
        multiRecordViewClient: MultiRecordViewClient,
        personType: PERSON_TYPE_PATIENT,
      })
  );

  const multiRecordUserMetadataStore = useEphemeralStore(
    () =>
      new MultiRecordPersonMetadataStore({
        multiRecordViewClient: MultiRecordViewClient,
        personType: PERSON_TYPE_USER,
      })
  );

  const caseVisibleSystemFilterStore = useEphemeralStore(
    () => VisibleSystemStore
  );

  const contents = [
    {
      Component: CaseflowContainer,
      isShowing: false,
      key: 'CaseflowContainer',
    },
    caseType === 'diversion'
      ? {
          Component: IncidentsContainer,
          isShowing: path.endsWith('incidents'),
          key: 'IncidentsContainer',
        }
      : null,
    {
      Component: AssessmentsForCaseTypeContainer,
      isShowing: path.endsWith('assessments'),
      key: 'AssessmentsForCaseTypeContainer',
      className: 'forScreen',
    },
    caseType === 'privacy'
      ? {
          Component: AccessesContainer,
          isShowing: path.endsWith('accesses'),
          key: 'AccessesContainer',
          className: 'forScreen',
        }
      : null,
    caseType !== 'GRC'
      ? {
          Component: LoginStore?.user?.roles?.includes('BETA')
            ? MultiRecordUserPatientDetailsContainer
            : UserPatientDetailsContainer,
          isShowing: path.includes('userDetails'),
          key: LoginStore?.user?.roles?.includes('BETA')
            ? 'MultiRecordUserPatientDetailsContainer'
            : 'UserPatientDetailsContainer',
        }
      : null,
  ].filter(Boolean);

  if (contents.map(c => c.isShowing).filter(Boolean).length === 0) {
    contents[0].isShowing = true;
  }

  return (
    <div className={styles.container}>
      {contents.map(({ Component, isShowing, key, className }) => (
        <div
          className={classnames(
            styles.content,
            !isShowing && styles.hidden,
            className
          )}
          key={key}
        >
          <MultiRecordViewContext.Provider
            value={{
              multiRecordMainStore,
              multiRecordPersonHeaderStore,
              multiRecordPatientMetadataStore,
              multiRecordUserMetadataStore,
              caseVisibleSystemFilterStore,
              CaseStore,
              Store,
            }}
          >
            <Component />
          </MultiRecordViewContext.Provider>
        </div>
      ))}
    </div>
  );
};

Switcher.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
  caseType: PropTypes.string,
};

export default Switcher;
