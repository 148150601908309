import AuditLogAdvancedFilters from '../AuditLogAdvancedFilters';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { AuditLogContext } from '../Context';

export function mapProps({ history, auditLogFilterStore }) {
  const {
    query,
    dateFilterOptions,
    isAdvancedFiltersOpen,
    nextAdvancedFiltersURL,
    selectedUsers,
    selectedEmployees,
    selectedPatients,
    selectedRange,
    setSelectedPeople,
    setSelectedDateRange,
    setSelectedCalendarRange,
    protenusUsers,
  } = auditLogFilterStore;

  return {
    toggleOpen: () => history.push(nextAdvancedFiltersURL),
    history,
    dateFilterOptions,
    query,
    isAdvancedFiltersOpen,
    selectedUsers,
    selectedEmployees,
    selectedPatients,
    selectedRange,
    setSelectedPeople,
    setSelectedDateRange,
    setSelectedCalendarRange,
    protenusUsers,
  };
}

const AuditLogAdvancedFiltersContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: AuditLogContext,
    mapProps,
    PresentationalComponent: AuditLogAdvancedFilters,
  })
);

AuditLogAdvancedFiltersContainer.displayName =
  'AuditLogAdvancedFiltersContainer';

export default AuditLogAdvancedFiltersContainer;
