import React from 'react';
import PropTypes from 'prop-types';
import { computed, decorate, observable, toJS } from 'mobx';
import { observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { Multiselect } from 'react-widgets';
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';

import {
  AlertCategoryStore,
  AppUserStore,
  formatCategoryName,
  HalUtils,
  PermissionStore,
} from 'common';
import { DimensionWatcher } from '../../../ui';

const AssignmentCell = observer(
  class AssignmentCell extends React.Component {
    static propTypes = {
      category: PropTypes.shape({
        assignees: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
        enabled: PropTypes.bool,
        group: PropTypes.shape({
          name: PropTypes.string,
        }),
        limit: PropTypes.number,
      }).isRequired,
      updateRow: PropTypes.func.isRequired,
      resize: PropTypes.func.isRequired,
      groupActive: PropTypes.bool,
    };

    // Observable
    open = false;
    shortened =
      this.props.category.assignees &&
      this.props.category.assignees.length >= this.shortenedThreshold;

    // NOT Observable: number of assignees before tags/names are truncated to fit
    shortenedThreshold = 4;

    // Computed
    get owners() {
      // format the owners array if the system uses groups then also filter the owners that are
      // assigned to the categories groups
      const { category } = this.props;

      const owners = AppUserStore.caseOwners.reduce((acc, u) => {
        const option = { id: HalUtils.getId(u) };

        if (this.shortened)
          option.name = `${u.firstName.substr(0, 1)}. ${u.lastName}`;
        else option.name = `${u.firstName} ${u.lastName}`;

        // if the system uses more than the default 1 group only add the users that actually belong to the groups
        if (u.enabled) {
          if (AlertCategoryStore.allGroups.length <= 1) {
            acc.push(option);
          } else if (u.groups) {
            const matchingGroups = u.groups.filter(
              group =>
                (group && group.name) ===
                (category.group && category.group.name)
            );
            if (matchingGroups.length) acc.push(option);
          }
        }

        return acc;
      }, []);

      return owners;
    }

    changeAssignment = values => {
      const { category, updateRow } = this.props;
      updateRow(
        category,
        undefined,
        values.map(v => v.id)
      );
    };

    placeholder(cat) {
      return `New ${formatCategoryName(cat)} cases will be unassigned`;
    }

    render() {
      const { category, groupActive, resize } = this.props;
      const enabled =
        category.enabled &&
        category.limit !== 0 &&
        PermissionStore.has('ALERT_CATEGORY_SAVE');

      return (
        <Cell
          className={classnames('settings__table-cell', {
            inactive: !groupActive,
          })}
        >
          <DimensionWatcher onChange={(width, height) => resize(height)}>
            <Multiselect
              className={classnames('inline-tags', {
                'shortened-tags': this.shortened,
              })}
              placeholder={this.placeholder(category) + '   '} //Added extra space to end of placeholder to override Multiselect width
              data={this.owners}
              disabled={!enabled || !groupActive}
              dropUp
              value={toJS(category.assignees)}
              valueField="id"
              textField="name"
              onChange={this.changeAssignment}
              onFocus={() => {
                this.open = enabled;
              }}
              onBlur={() => {
                this.open = false;
              }}
              onKeyDown={e => {
                this.open = enabled && e.keyCode !== 27 && e.keyCode !== 8;
              }}
              onClick={e => {
                this.open = enabled && e.target.tagName !== 'LI';
              }}
              onSelect={() => {
                this.open = false;
              }}
              open={this.open}
              onToggle={() => {}}
            />
          </DimensionWatcher>
        </Cell>
      );
    }
  }
);

decorate(AssignmentCell, {
  open: observable,
  shortened: observable,
  owners: computed,
});

AssignmentCell.displayName = 'AssignmentCell';

export default AssignmentCell;
