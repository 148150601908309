import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import {
  RegistryContainer,
  Registry,
  DateCell,
  Tooltip,
  Loader,
} from '../../ui';
import { Pandy, DateHelpers } from 'common';
import { Column, Cell } from 'fixed-data-table-2';
import HeaderCell from './Cells/HeaderCell';
import ProcessingCell from './Cells/ProcessingCell';
import NameCell from './Cells/NameCell';
import SourceCell from './Cells/SourceCell';
import PathCell from './Cells/PathCell';
import RecordsCell from './Cells/RecordsCell';
import styles from './index.module.scss';
import FileSizeCell from './Cells/FileSizeCell';
import { TOOLTIP_CONTENT } from './Cells/const';

const DataFeedDeliveryTable = ({
  data,
  sortStore,
  store,
  loaded,
  noResults,
}) => {
  const table = useRef();
  const [width, setWidth] = useState(1);
  const timezone = DateHelpers.getCurrentAppUserTimezone();

  const setSize = useCallback(() => {
    const node = table.current;
    setWidth(node?.offsetWidth);
  }, []);

  useEffect(() => {
    setSize();
    window.addEventListener('resize', setSize);

    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [setSize]);

  const setCellWidth = percentage => {
    percentage /= 100;
    return width * percentage;
  };
  return (
    <Loader loaded={loaded}>
      <Pandy visible={noResults} mood="happy">
        <div className="speechBubble">
          <span>No matches. Sorry!</span>
        </div>
      </Pandy>
      <div className={styles.table} ref={table} data-cy="fdd-delivery-table">
        <RegistryContainer
          store={store}
          viewContent={false}
          className="registry"
          pandyText="File Delivery Statuses"
        >
          <Registry dataCount={data.length || 0} width={width} scroll={false}>
            <Column
              header={
                <HeaderCell
                  label="Delivered"
                  propName="created"
                  store={sortStore}
                />
              }
              width={setCellWidth(15)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) => (
                <DateCell
                  timezone={timezone}
                  date={data[rowIndex].created}
                  format="l h:mm A z"
                />
              )}
            />
            <Column
              header={
                <Cell>
                  Name
                  <Tooltip content={TOOLTIP_CONTENT.name}>
                    <span
                      className={styles.tooltip}
                      data-cy="fdd-delivery-tooltip"
                    />
                  </Tooltip>
                </Cell>
              }
              width={setCellWidth(30)}
              cell={({ rowIndex }) => (
                <NameCell
                  location={
                    data[rowIndex].location &&
                    window.decodeURI(data[rowIndex].location)
                  }
                />
              )}
            />
            <Column
              header={
                <HeaderCell
                  label="Source"
                  propName="location"
                  store={sortStore}
                  tooltipContent={TOOLTIP_CONTENT.source}
                />
              }
              width={setCellWidth(15)}
              cell={({ rowIndex }) => (
                <SourceCell
                  location={
                    data[rowIndex].location &&
                    window.decodeURI(data[rowIndex].location)
                  }
                />
              )}
            />

            <Column
              header={
                <HeaderCell label="Path" propName="path" store={sortStore} />
              }
              width={setCellWidth(10)}
              cell={({ rowIndex }) => (
                <PathCell location={data[rowIndex].location} />
              )}
            />
            <Column
              header={
                <HeaderCell
                  label="Filesize"
                  propName="size"
                  store={sortStore}
                  tooltipContent={TOOLTIP_CONTENT.fileSize}
                />
              }
              width={setCellWidth(10)}
              cellClassName="no-wrap right-align"
              cell={({ rowIndex }) => (
                <FileSizeCell size={data[rowIndex].size} />
              )}
            />
            <Column
              header={
                <HeaderCell
                  label="Records"
                  propName="result.records"
                  store={sortStore}
                  tooltipContent={TOOLTIP_CONTENT.records}
                />
              }
              width={setCellWidth(10)}
              cellClassName="no-wrap right-align"
              cell={({ rowIndex }) => (
                <RecordsCell records={data[rowIndex].result?.records} />
              )}
            />
            <Column
              header={<Cell>Processing</Cell>}
              width={setCellWidth(10)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) => {
                const d = data[rowIndex].result || {};
                return (
                  <ProcessingCell
                    completed={d.completed}
                    results={d.results}
                    errorMessage={d.errorMessage}
                    ignored={d.ignored}
                  />
                );
              }}
            />
          </Registry>
        </RegistryContainer>
      </div>
    </Loader>
  );
};

DataFeedDeliveryTable.propTypes = {
  loaded: PropTypes.bool,
  noResults: PropTypes.bool,
  data: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  width: PropTypes.number,
  sortStore: PropTypes.shape({}),
  store: PropTypes.shape({}),
};

export default DataFeedDeliveryTable;
