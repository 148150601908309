import React, { useState, useEffect } from 'react';
import ExpandCollapse from '../../../ui/ExpandCollapse';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { MultiRecordPatientMetadataDetailsContainer } from '../MultiRecordPatientMetadataDetailsContainer';
import { MultiRecordUserMetadataDetailsContainer } from '../MultiRecordUserMetadataDetailsContainer';
import { MultiRecordPatientMetadataSummaryContainer } from '../MultiRecordPatientMetadataSummaryContainer';
import { MultiRecordUserMetadataSummaryContainer } from '../MultiRecordUserMetadataSummaryContainer';
import { PERSON_TYPE_PATIENT, PERSON_TYPE_USER } from '../../stores/const';

const MultiRecordMetadataLayout = ({
  pageMode,
  isDoubleColumnMode,
  match,
  setCurrentMatch,
  personType,
}) => {
  useEffect(() => {
    if (match) setCurrentMatch(match);
  }, [match, setCurrentMatch]);

  const [showSummary, setShowSummary] = useState(true);

  const onExpand = () => setShowSummary(false);
  const onCollapse = () => setShowSummary(true);

  return (
    <div className={styles.section}>
      <ExpandCollapse useArrow onExpand={onExpand} onCollapse={onCollapse}>
        <ExpandCollapse.Header>
          {showSummary && (
            <div className={styles.iconWrapper}>
              {personType === PERSON_TYPE_PATIENT ? (
                <MultiRecordPatientMetadataSummaryContainer />
              ) : personType === PERSON_TYPE_USER ? (
                <MultiRecordUserMetadataSummaryContainer />
              ) : null}
            </div>
          )}
          <span className={styles.spacer} />
          {isDoubleColumnMode(pageMode) && showSummary && (
            <span className={styles.expandText}>View Details</span>
          )}
        </ExpandCollapse.Header>
        <ExpandCollapse.Section>
          <div className={styles.detailSectionWrapper}>
            {personType === PERSON_TYPE_PATIENT ? (
              <MultiRecordPatientMetadataDetailsContainer />
            ) : personType === PERSON_TYPE_USER ? (
              <MultiRecordUserMetadataDetailsContainer />
            ) : null}
          </div>
        </ExpandCollapse.Section>
      </ExpandCollapse>
    </div>
  );
};

MultiRecordMetadataLayout.propTypes = {
  pageMode: PropTypes.string,
  isDoubleColumnMode: PropTypes.func.isRequired,
  match: PropTypes.object,
  setCurrentMatch: PropTypes.func,
  personType: PropTypes.string,
};

export default MultiRecordMetadataLayout;
