import React from 'react';
import PropTypes from 'prop-types';
import CaseIntegrationTable from '../../CaseIntegration/Table';
import { TABLE_CELL_TYPE } from '../../CaseIntegration/const';
import styles from './index.module.scss';

const SAI360IntegrationTable = ({ caseSyncSettings, setCaseSyncSettings }) => {
  const onChangeForSetting = setting => () =>
    setCaseSyncSettings(setting, !caseSyncSettings[setting]);

  const protenusFromSAI360 = [
    [{ type: TABLE_CELL_TYPE.lock, text: 'Case number' }],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'EMR user name',
        isChecked: caseSyncSettings?.emrUserName,
        onChange: onChangeForSetting('emrUserName'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Patient name',
        isChecked: caseSyncSettings?.patientName,
        onChange: onChangeForSetting('patientName'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case title',
        isChecked: caseSyncSettings?.caseTitle,
        onChange: onChangeForSetting('caseTitle'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case group',
        isChecked: caseSyncSettings?.caseGroup,
        onChange: onChangeForSetting('caseGroup'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case type',
        isChecked: caseSyncSettings?.caseType,
        onChange: onChangeForSetting('caseType'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case assessment reasons',
        isChecked: caseSyncSettings?.caseAssessmentReasons,
        onChange: onChangeForSetting('caseAssessmentReasons'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Date of earliest access in case',
        isChecked: caseSyncSettings?.earliestAccessDate,
        onChange: onChangeForSetting('earliestAccessDate'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case creation date',
        isChecked: caseSyncSettings?.caseCreationDate,
        onChange: onChangeForSetting('caseCreationDate'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case owner name',
        isChecked: caseSyncSettings?.caseOwnerName,
        onChange: onChangeForSetting('caseOwnerName'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case notes',
        isChecked: caseSyncSettings?.caseNotes,
        onChange: onChangeForSetting('caseNotes'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case actions',
        isChecked: caseSyncSettings?.caseActions,
        onChange: onChangeForSetting('caseActions'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case attachment filename and notes',
        isChecked: caseSyncSettings?.caseAttachments,
        onChange: onChangeForSetting('caseAttachments'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case emails',
        isChecked: caseSyncSettings?.caseEmails,
        onChange: onChangeForSetting('caseEmails'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case resolution',
        isChecked: caseSyncSettings?.caseResolution,
        onChange: onChangeForSetting('caseResolution'),
      },
    ],
    [
      {
        type: TABLE_CELL_TYPE.checkbox,
        text: 'Case final assessment',
        isChecked: caseSyncSettings?.caseFinalAssessment,
        onChange: onChangeForSetting('caseFinalAssessment'),
      },
    ],
  ];

  return (
    <div
      className={styles.sai360IntegrationTable}
      data-cy="integration-sai360-table"
    >
      <div className={styles.topSpacing}>
        <p>Fields to be added to SAI 360 from Protenus:</p>
        <CaseIntegrationTable data={protenusFromSAI360} />
      </div>
    </div>
  );
};

SAI360IntegrationTable.propTypes = {
  caseSyncSettings: PropTypes.shape({
    importId: PropTypes.bool,
    name: PropTypes.bool,
    description: PropTypes.bool,
    incidentDate: PropTypes.bool,
    dateReceived: PropTypes.bool,
    responsibleParty: PropTypes.bool,
    caseNotes: PropTypes.bool,
    caseActions: PropTypes.bool,
    caseResolution: PropTypes.bool,
    caseAttachments: PropTypes.bool,
    caseEmails: PropTypes.bool,
    caseFinalAssessment: PropTypes.bool,
    emrUserName: PropTypes.bool,
  }),
  setCaseSyncSettings: PropTypes.func,
};

export default SAI360IntegrationTable;
