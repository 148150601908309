import React, { forwardRef } from 'react';
import { observer } from 'mobx-react';
import { action, computed, decorate, observable } from 'mobx';
import classnames from 'classnames';
import moment from 'moment';
import { PermissionStore } from 'common';
import PropTypes from 'prop-types';

import TemplateSettingsAside from '../TemplateSettingsAside';
import TopNavBar from '../../../navbar/TopNavBar';
import NavBarItem from '../../../navbar/NavBarItem';
import Template from '../../../case/Template';
import {
  Tooltip,
  TextFormatter,
  PromptWrapper,
  DocumentTitle,
} from '../../../ui';

import CaseNoteTemplatesStore from '../../../case/stores/CaseNoteTemplatesStore';
import CaseEmailTemplatesStore from '../../../case/stores/EmailTemplatesStore';
import EmailTemplateStore from '../../../case/stores/EmailTemplateStore';

const IconItemInner = ({ icon, onClick, innerRef, ...props }) => (
  <NavBarItem {...props} ref={innerRef}>
    <i onClick={onClick} className={`material-icons ${icon}`} />
  </NavBarItem>
);

IconItemInner.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  innerRef: PropTypes.func,
};

const forwardRefIcon = (props, ref) => (
  <IconItemInner {...props} innerRef={ref} />
);

const IconItem = forwardRef(forwardRefIcon);

const emptyValue = type => (
  <span className="settingsTemplate__content-empty">{`No ${type}`}</span>
);

const TemplateSettingsView = observer(
  class TemplateSettingsView extends React.Component {
    constructor(props) {
      super(props);

      CaseNoteTemplatesStore.refresh();
      CaseEmailTemplatesStore.refresh();
    }

    // Observables
    modifyingTemplate = false;
    createNewTemplate = false;
    activeEdit = false;

    // Computed
    get shouldMute() {
      if (this.activeEdit) return true;
      return false;
    }

    // Computed
    get datatipContent() {
      if (this.shouldMute) return 'Only one template can be edited at a time';
      return null;
    }

    // Action
    toggleActive(activate, whichObservable, key) {
      if (!activate) {
        this[whichObservable] = false;
        this.activeEdit = false;
      } else if (activate && !this.activeEdit) {
        this[whichObservable] = key;
        this.activeEdit = true;
      }
    }

    print = () => {
      window.print();
    };

    renderLastEditInfo(template) {
      const {
        lastModifiedBy: { firstName, lastName } = {},
        lastModified,
        created,
      } = template;
      const pastAction = lastModified === created ? 'created' : 'last modified';
      return (
        <p className="template-lastEdit">{`This template was ${pastAction} on ${moment(
          lastModified
        ).format('LL')} by ${firstName} ${lastName} `}</p>
      );
    }

    renderTemplateInnards(type, template, key) {
      const { content, subject } = template;

      const innards = (
        <div
          className={classnames('borderBox', {
            'print-only': this.modifyingTemplate === key,
          })}
        >
          <div className={classnames({ hidden: type !== 'Email' })}>
            {subject || emptyValue('subject')}
          </div>
          <div>{<TextFormatter text={content} /> || emptyValue('content')}</div>
          {this.renderLastEditInfo(template)}
        </div>
      );

      const callback = (wasSuccessful = true) => {
        if (wasSuccessful) {
          this.toggleActive(false, 'modifyingTemplate', key);
        }
      };

      if (this.modifyingTemplate === key) {
        return (
          <div>
            <div className="dont-print">
              <Template
                type={type.toLowerCase()}
                startAt="modify"
                template={template}
                settingsCallback={callback}
              />
            </div>
            {innards}
          </div>
        );
      }

      return innards;
    }

    renderTemplate(type, template) {
      const { name, created } = template;
      const key = `${created}-${name}`;
      let modifyContent = null;

      if (PermissionStore.has(`CASE_MODIFY_${type.toUpperCase()}_TEMPLATES`)) {
        modifyContent = (
          <Tooltip content={this.datatipContent} placement="left">
            <span
              className={classnames(
                'settingsTemplate__header-modify dont-print',
                {
                  hidden: this.modifyingTemplate === key,
                  muted: this.shouldMute,
                }
              )}
              onClick={() => this.toggleActive(true, 'modifyingTemplate', key)}
              data-cy="modify-template-button"
            >
              Modify Template
            </span>
          </Tooltip>
        );
      }

      return (
        <div key={key} className="settingsTemplate">
          <hr className="print-only" />
          <div className="settingsTemplate__header">
            <h2
              id={name && name.replace(/[\W_]+/g, '')}
              className="settingsTemplate__header-title"
            >
              {this.modifyingTemplate === key ? null : name}
            </h2>
            {modifyContent}
          </div>
          <hr className="dont-print" />
          <div className="settingsTemplate__content">
            {this.renderTemplateInnards(type, template, key)}
          </div>
        </div>
      );
    }

    renderCreateTemplate(type) {
      const callback = (wasSuccessful = true) => {
        if (wasSuccessful) {
          this.toggleActive(false, 'createNewTemplate', type);
        }
      };
      if (this.createNewTemplate === type) {
        return (
          <Template
            type={type.toLowerCase()}
            startAt="createNew"
            settingsCallback={callback}
          />
        );
      }
      return null;
    }

    renderCreateNewTemplate(type) {
      if (PermissionStore.has(`CASE_MODIFY_${type.toUpperCase()}_TEMPLATES`)) {
        return (
          <div
            id={`CreateNew${type}Template`}
            data-cy={`add-${type}-template`}
            className={classnames('settingsTemplate create dont-print', {
              closed: this.createNewTemplate !== type,
            })}
          >
            {this.createNewTemplate === type ? null : (
              <h2>Create New {type} Template</h2>
            )}
            <Tooltip content={this.datatipContent} placement="left">
              <i
                className={classnames('material-icons icon-add_circle', {
                  hidden: this.createNewTemplate === type,
                  muted: this.shouldMute,
                })}
                onClick={() =>
                  this.toggleActive(true, 'createNewTemplate', type)
                }
              />
            </Tooltip>
            {this.createNewTemplate === type ? <hr /> : null}
            {this.renderCreateTemplate(type)}
          </div>
        );
      }
      return null;
    }

    renderTemplateCard(type) {
      const storeString =
        type === 'Note' ? CaseNoteTemplatesStore : CaseEmailTemplatesStore;
      if (type === 'Email' && !EmailTemplateStore.canSendEmail) return null;

      return (
        <div id={`templates__wrapper-${type}`} className="wrapper">
          <div className="card_header">
            <h1 id={`${type}Template-header`}>
              {type}
              {' Templates'}
            </h1>
          </div>
          <div className="content" data-cy={`${type}-template-content`}>
            {this.renderCreateNewTemplate(type)}
            {storeString.templates.map(template =>
              this.renderTemplate(type, template)
            )}
          </div>
        </div>
      );
    }

    render() {
      const { modifyingTemplate, createNewTemplate, activeEdit } = this;

      return (
        <div className="templateSettings__view">
          <DocumentTitle text="Template Settings" />
          <div className="print-only print-title">
            <img alt="logo" className="float-left" src="assets/img/logo.png" />
            <p className="float-right">
              Report Generated
              <strong>{moment().format('LL')}</strong>
            </p>
          </div>
          <TemplateSettingsAside
            createFunc={type =>
              this.toggleActive(true, 'createNewTemplate', type)
            }
            activeTemplate={this.modifyingTemplate || this.createNewTemplate}
            activeEdit={this.activeEdit}
          />
          <section className="templateSettings_view__body">
            <TopNavBar actionBar>
              {!modifyingTemplate && !createNewTemplate && !activeEdit ? (
                <Tooltip content="Print" placement="left">
                  <IconItem
                    className="float-right icon"
                    icon="icon-print"
                    onClick={this.print}
                  />
                </Tooltip>
              ) : null}
            </TopNavBar>
            <div>
              {this.renderTemplateCard('Note')}
              {this.renderTemplateCard('Email')}
            </div>
          </section>
          <PromptWrapper when={this.activeEdit} />
        </div>
      );
    }
  }
);

decorate(TemplateSettingsView, {
  modifyingTemplate: observable,
  createNewTemplate: observable,
  activeEdit: observable,
  shouldMute: computed,
  datatipContent: computed,
  toggleActive: action,
});

TemplateSettingsView.displayName = 'TemplateSettingsView';

export default TemplateSettingsView;
