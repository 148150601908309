import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { RegistryContainer } from '../../../ui';
import CustomerAdministeredAccountsTable from '../CustomerAdministeredAccountsTable';
import classnames from 'classnames';
import ActionNav from '../../../navbar/ActionNav';
import { PropTypes as mobxPropTypes } from 'mobx-react';

const CustomerAdministeredAccountsListView = ({
  appUsers,
  createUpdateAsideVisible,
  customerAdministeredAccountsListStore,
  history,
  isAdmin,
  query,
  setFilters,
  showHideProtenusUsersLinkText,
  showHideProtenusUsersLinkQueryString,
  totalAppUsers,
  createRoute,
  updateRoute,
  viewRoute,
}) => {
  useEffect(() => {
    setFilters(query);
  }, [query, setFilters]);

  return (
    <div
      id="customer-administered-accounts-list-view"
      className={classnames(
        'settings__customer-administered-accounts-list-view',
        styles.customerAdministeredAccountsListView
      )}
    >
      <div
        className={classnames([styles.mainPanel], {
          [styles.withAside]: createUpdateAsideVisible,
        })}
      >
        <ActionNav
          secondary
          count={totalAppUsers}
          createPath={createRoute}
          createPermission="APP_USER_CREATE"
          createIcon="add_circle"
          title="App User"
          createLabel="Create New App User"
          showLink={isAdmin}
          linkRoute={`${history.location.pathname}${showHideProtenusUsersLinkQueryString}`}
          linkText={showHideProtenusUsersLinkText}
        />

        <RegistryContainer
          className="registry"
          store={customerAdministeredAccountsListStore}
        >
          <CustomerAdministeredAccountsTable
            appUsers={appUsers}
            createUpdateAsideVisible={createUpdateAsideVisible}
            customerAdministeredAccountsListStore={
              customerAdministeredAccountsListStore
            }
            history={history}
            updateRoute={updateRoute}
            viewRoute={viewRoute}
          />
        </RegistryContainer>
      </div>
    </div>
  );
};

CustomerAdministeredAccountsListView.propTypes = {
  appUsers: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  createRoute: PropTypes.string,
  createUpdateAsideVisible: PropTypes.bool,
  customerAdministeredAccountsListStore: PropTypes.object,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
  query: PropTypes.object,
  setFilters: PropTypes.func,
  showHideProtenusUsersRoute: PropTypes.string,
  showHideProtenusUsersLinkText: PropTypes.string,
  showHideProtenusUsersLinkQueryString: PropTypes.string,
  totalAppUsers: PropTypes.number,
  updateRoute: PropTypes.string,
  viewRoute: PropTypes.string,
};

export default CustomerAdministeredAccountsListView;
