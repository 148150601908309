import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Button from '../../ui/Button';
import Checkbox from '../../ui/Checkbox';
import styles from './index.module.scss';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

const clearFilters = (location, history, query, clearStorage) => {
  if (clearStorage) clearStorage();
  history.push({
    pathname: location.pathname,
    search: queryString.stringify(query),
  });
};

export const FilterActions = ({
  location,
  history,
  rememberFilters,
  toggleRememberFilters,
  clearFilterQuery,
  clearStorage,
}) => (
  <div className={classnames('forScreen', styles.actions)}>
    <div
      id="checkbox-wrapper"
      onKeyPress={({ key }) => {
        if (key === ' ') toggleRememberFilters();
      }}
      tabIndex={0}
    >
      <Checkbox
        checked={rememberFilters}
        onChange={toggleRememberFilters}
        color="secondary"
      >
        <span className={styles.filterText}>Remember Filters</span>
      </Checkbox>
    </div>
    <div>
      <Button
        type="button"
        value="Clear Filters"
        data-cy="cases-adv-filters--clear-filters--button"
        onClick={() =>
          clearFilters(location, history, clearFilterQuery, clearStorage)
        }
      />
    </div>
  </div>
);

FilterActions.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  rememberFilters: PropTypes.bool.isRequired,
  toggleRememberFilters: PropTypes.func.isRequired,
  clearFilterQuery: PropTypes.object.isRequired,
  clearStorage: PropTypes.func,
};

FilterActions.displayName = 'FilterActions';

export default withRouter(FilterActions);
