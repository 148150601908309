import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const Column = ({ children, printOnly }) => {
  return (
    <div className={classnames(styles.column, { forPrint: printOnly })}>
      {children}
    </div>
  );
};

Column.propTypes = {
  children: PropTypes.node.isRequired,
  printOnly: PropTypes.bool.isRequired,
};

export default Column;
