import MultiRecordPersonHeaderSection from '../MultiRecordPersonHeaderSection';
import { MultiRecordViewContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({
  match,
  personType,
  multiRecordPersonHeaderStore,
  CaseStore,
  Store,
  forPage,
  query,
}) {
  const employeeId =
    forPage === 'activity' ? Store.userIdParam : CaseStore.userId;
  const patientId =
    forPage === 'activity' ? Store.patientIdParam : CaseStore.patientId;

  return {
    personType,
    personHeaders: multiRecordPersonHeaderStore.personHeaders,
    match,
    setCurrentMatch: multiRecordPersonHeaderStore.setCurrentMatch,
    userLink:
      forPage === 'activity'
        ? Store.getUserLink({
            userId: employeeId,
          })
        : CaseStore.personLink('user'),
    patientLink:
      forPage === 'activity'
        ? Store.getPatientLink({
            patientId: patientId,
          })
        : CaseStore.personLink('patient'),
    forPage,
    employeeId,
    patientId,
    query,
    loading: multiRecordPersonHeaderStore.loading,
  };
}

const MultiRecordPersonHeaderSectionContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordPersonHeaderSection,
  })
);

export default MultiRecordPersonHeaderSectionContainer;
