import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

const FormattedLastActivityDate = ({ userId, date }) => {
  const dateAsMoment = moment(date);
  const formattedForDisplay = dateAsMoment.format('l');
  const formattedForUrl = dateAsMoment.format('MM-DD-YYYY');

  return (
    <Link
      to={`/activity/employee/${userId}?fromDate=${formattedForUrl}&toDate=${formattedForUrl}`}
    >
      {formattedForDisplay}
    </Link>
  );
};

FormattedLastActivityDate.propTypes = {
  userId: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]).isRequired,
};

export default FormattedLastActivityDate;
