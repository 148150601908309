import React from 'react';
import styles from './index.module.scss';
import classnames from 'classnames';
import { TYPE_ENUMS } from './const';
import PropTypes from 'prop-types';
import MultiRecordCompositeFieldValue from './Value';
import { generateHighlightedText } from '../utils/helpers';

const MultiRecordCompositeFieldTieredValue = ({
  fieldWithTieredValues,
  filteredTerm,
}) => (
  <div className={styles.compositeTieredValue}>
    {/* Don't render tiered value root node, it was already rendered */}
    {fieldWithTieredValues?.name && (
      <div className={classnames(styles.tieredFieldRow, styles.padBottom)}>
        <h3 className={classnames(styles.text, styles.tieredFieldName)}>
          {generateHighlightedText(fieldWithTieredValues.name, filteredTerm)}
        </h3>
        <p className={classnames(styles.text, styles.tieredFieldValue)}>
          {/* bolds filtered text */}
          {generateHighlightedText(fieldWithTieredValues.value, filteredTerm)}
        </p>
      </div>
    )}
    {fieldWithTieredValues?.tieredValues?.map((tieredValue, i) => (
      <div
        className={classnames(styles.tieredField, styles.tieredFieldRow, {
          // don't add bottom padding to last element
          [styles.padBottom]: i < fieldWithTieredValues.tieredValues.length - 1,
        })}
        key={`${tieredValue.name}${tieredValue.tier}${Math.random() * 100}`}
      >
        {tieredValue.type !== TYPE_ENUMS.object && (
          <h3 className={classnames(styles.text, styles.tieredFieldValue)}>
            {generateHighlightedText(tieredValue.name, filteredTerm)}
          </h3>
        )}
        <MultiRecordCompositeFieldValue
          field={tieredValue}
          filteredTerm={filteredTerm}
        />
      </div>
    ))}
  </div>
);

MultiRecordCompositeFieldTieredValue.propTypes = {
  fieldWithTieredValues: PropTypes.object,
  filteredTerm: PropTypes.string,
};

export default MultiRecordCompositeFieldTieredValue;
