import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import Field from '../../ui/Field';
import ScreenOnly from '../../ui/ScreenOnly';
import FormattedEncounterDate from '../../people/PersonBox/FormattedEncounterDate';
import FormattedEncounterDepartment from '../../people/PersonBox/FormattedEncounterDepartment';
import FormattedDobAndAge from '../../people/PersonBox/FormattedDobAndAge';
import FormattedDateOfDeath from '../../people/PersonBox/FormattedDateOfDeath';
import FormattedSex from '../../people/PersonBox/FormattedSex';
import FormattedMRNs from '../../people/PersonBox/FormattedMRNs';
import ValueOrUnknown from '../../people/PersonBox/ValueOrUnknown';
import FormattedEmails from '../../people/PersonBox/FormattedEmails';
import pluralize from '../../utils/pluralize';
import BoxSection from '../../people/PersonBox/BoxSection';
import FormattedLabels from '../../people/PersonBox/FormattedLabels';
import Addresses from '../../people/Addresses';
import PhoneNumbers from '../../people/PhoneNumbers';
import Relationships from '../../people/Relationships';

const PatientDetails = ({
  isSelfAccess,
  patient,
  mostRecentEncounter,
  printSettings,
  patientRelationships,
  timezone,
}) => {
  return (
    <BoxSection>
      <h2>Details</h2>

      <Relationships relationships={patientRelationships} />

      {patient.labels?.length > 0 && (
        <Field label={pluralize(patient.labels.length, 'Label')} width="auto">
          <FormattedLabels labels={patient.labels} />
        </Field>
      )}

      <Field label="Last Encounter Date" width="auto">
        <FormattedEncounterDate
          encounter={mostRecentEncounter}
          timezone={timezone}
        />
      </Field>

      <Field label="Last Encounter Department" width="auto">
        <FormattedEncounterDepartment encounter={mostRecentEncounter} />
      </Field>

      <ScreenOnly hideOnPrint={isSelfAccess && printSettings?.dob}>
        <Field label="Date of Birth" width="auto">
          <FormattedDobAndAge
            dateOfBirth={patient.dateOfBirth}
            deceased={patient.deceased}
            dateOfDeath={patient.dateOfDeath}
          />
        </Field>
      </ScreenOnly>

      {(patient.dateOfDeath || patient.deceased) && (
        <Field label="Date of Death" width="auto">
          <FormattedDateOfDeath
            dateOfDeath={patient.dateOfDeath}
            deceased={patient.deceased}
          />
        </Field>
      )}

      <Field label="Sex" width="auto">
        <FormattedSex sex={patient.sex} />
      </Field>

      <Field label="MRN" width="auto">
        <FormattedMRNs medicalRecordNumbers={patient.medicalRecordNumbers} />
      </Field>

      <Field label="ID" width="auto">
        <ValueOrUnknown>{patient.patientId}</ValueOrUnknown>
      </Field>

      <Field label="Employer" width="auto">
        <ValueOrUnknown>{patient.employer}</ValueOrUnknown>
      </Field>

      <Field
        label={pluralize(patient.emails?.length || 0, 'Email')}
        width="auto"
      >
        <FormattedEmails emails={patient.emails} />
      </Field>

      <Addresses
        addresses={patient.addresses}
        hideOnPrint={isSelfAccess && printSettings.EMRUserAddress}
      />

      <PhoneNumbers
        numbers={patient.phoneNumbers}
        hideOnPrint={isSelfAccess && printSettings.phoneNumber}
      />
    </BoxSection>
  );
};

PatientDetails.propTypes = {
  isSelfAccess: PropTypes.bool,
  patient: PropTypes.shape({
    addresses: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape()),
    dateOfBirth: PropTypes.string,
    dateOfDeath: PropTypes.string,
    deceased: PropTypes.bool,
    emails: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
    employer: PropTypes.string,
    labels: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        source: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.string
    ),
    patientId: PropTypes.string,
    phoneNumbers: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape()),
    sex: PropTypes.string,
  }),
  mostRecentEncounter: PropTypes.shape({
    department: PropTypes.shape({
      name: PropTypes.string,
      other: PropTypes.shape({
        facility: PropTypes.string,
      }),
    }),
    endTime: PropTypes.string,
    startTime: PropTypes.string,
    type: PropTypes.string,
  }),
  printSettings: PropTypes.shape({
    EMRUserAddress: PropTypes.bool,
    dob: PropTypes.bool,
    phoneNumber: PropTypes.bool,
  }),
  patientRelationships: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
};

PatientDetails.defaultProps = {
  isSelfAccess: false,
};

export default PatientDetails;
