import moment from 'moment';
import { convertMultiValue, DateHelpers } from 'common';

/**
 *
 * A lot of this mirrors CaseResultsStore. The conversion is necessary
 * for endpoint to create match criteria for aggregation
 *
 * @param filterValuesForChartAndTable
 * @param getRange
 * @param casesBy
 * @returns {{}}
 */
export const processParams = ({
  filterValuesForChartAndTable = {},
  getRange = DateHelpers.getDateParamsFromRange,
  casesBy,
}) => {
  const params = {
    ...filterValuesForChartAndTable,
  };

  /*
  Report Settings Filters
   */
  if (params.reportSettingsDateRange && params.casesBy) {
    const selectedCasesByField = casesBy ?? params.casesBy;
    if (params.reportSettingsDateRange === 'custom') {
      const after = moment(params.reportSettingsDateAfter)
          .startOf('hour')
          .toDate(),
        before = moment(params.reportSettingsDateBefore)
          .startOf('hour')
          .toDate();

      params[`${selectedCasesByField}After`] = after;
      params[`${selectedCasesByField}Before`] = before;
    } else {
      const [after, before] = getRange(params.reportSettingsDateRange);
      params[`${selectedCasesByField}After`] = after;
      if (before) params[`${selectedCasesByField}Before`] = before;
    }

    delete params.reportSettingsDateRange;
    delete params.reportSettingsDateAfter;
    delete params.reportSettingsDateBefore;
    delete params.casesBy;
  }

  /*
  Case Detail Filters
   */
  if (params.owner) params.owner = convertMultiValue(params.owner);
  if (params.createdBy) params.createdBy = convertMultiValue(params.createdBy);
  if (params.group) params.group = convertMultiValue(params.group);
  if (params.category) {
    params['category.name'] = convertMultiValue(params.category);
    delete params.category;
  }
  if (params.bundle) params.bundle = convertMultiValue(params.bundle);
  if (params.resolutionDescription)
    params.resolutionDescription = convertMultiValue(
      params.resolutionDescription
    );
  if (params.resolvedBy)
    params.resolvedBy = convertMultiValue(params.resolvedBy);
  if (params.actionsTaken) {
    params['actions.type'] = convertMultiValue(params.actionsTaken);
    delete params.actionsTaken;
  }
  if (params.suspicionScore) {
    params.suspicionScoreGreaterThan = params.suspicionScore;
    params.suspicionScoreLessThan = 1.0;
    delete params.suspicionScore;
  }

  if (params.actionsDateRange) {
    if (params.actionsDateRange === 'custom') {
      params[`actions.dateAfter`] = params.actionsDateAfter;
      params[`actions.dateBefore`] = params.actionsDateBefore;
    } else {
      const [after, before] = getRange(params.actionsDateRange);
      params[`actions.dateAfter`] = after;
      if (before) params[`actions.dateBefore`] = before;
    }
    delete params.actionsDateBefore;
    delete params.actionsDateAfter;
    delete params.actionsDateRange;
  }

  if (params.resolution) {
    params.resolution = convertMultiValue(params.resolution);
  }

  /*
  EMR Users filters
   */
  if (params.userOrganization) {
    params['userSnapshot.roles.organization'] = convertMultiValue(
      params.userOrganization
    );
    delete params.userOrganization;
  }
  if (params.userDepartment) {
    params.departments = convertMultiValue(params.userDepartment);
    delete params.userDepartment;
  }
  if (params.userType) params.userType = convertMultiValue(params.userType);
  if (params.userTags) params.userTags = convertMultiValue(params.userTags);

  /*
  Exclude filters
   */
  if (params.excludeFilters) {
    const excFilterParams = convertMultiValue(params.excludeFilters);
    const actionsIdx = excFilterParams.indexOf('actionsTaken');

    if (actionsIdx > -1) {
      params['actions.excludeFilters'] = ['actions'];
    }

    params.excludeFilters = excFilterParams;
  }

  /*
 Delete query strings that endpoint won't consume
  */
  delete params.pivotOn;

  return params;
};
