import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import classnames from 'classnames';

const AccountStatusDropdownItem = ({ item, label, renderLabel }) => {
  return (
    <span>
      {renderLabel && (
        <span className={styles.accountStatusDropdownItemLabel}>{label}</span>
      )}

      <i
        className={classnames(
          'material-icons',
          styles.accountStatusDropdownItemIcon,
          item.value ? 'icon-check' : 'icon-close'
        )}
        data-cy="create-app-user--account-status--dropdown"
      />

      {item.label}
    </span>
  );
};

AccountStatusDropdownItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.bool,
  }),
  label: PropTypes.string,
  renderLabel: PropTypes.bool,
};

export default AccountStatusDropdownItem;
