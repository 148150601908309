import React from 'react';
import { observer } from 'mobx-react';
import CaseStore from '../../stores/CaseStore';
import CaseViewStore from '../../stores/CaseViewStore';
import CaseMedicationIncidents from '../CaseMedicationIncidents';

export function mapProps({ CaseStore, CaseViewStore }) {
  return {
    filteredIncidents: CaseStore.incidentsFilteredByParams,
    lastViewedByUser: CaseViewStore.lastViewedByUser,
  };
}

const CaseMedicationIncidentsContainer = observer(() => (
  <CaseMedicationIncidents
    {...mapProps({
      CaseStore,
      CaseViewStore,
    })}
  />
));

CaseMedicationIncidentsContainer.displayName =
  'CaseMedicationIncidentsContainer';

export default CaseMedicationIncidentsContainer;
