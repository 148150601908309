import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { renderTrend, formatStat } from './utils';

const buildTable = (data, prevStatement) => {
  return data.map((curr, i) => (
    <tr key={curr.title + i}>
      <td> {renderTrend(curr.data.curr, curr.data.prev, prevStatement)} </td>
      <td> {curr.title} </td>
      <td> {formatStat(curr)} </td>
    </tr>
  ));
};

const PrintInfo = ({
  groupStatus,
  subtitle,
  globalPHCStats,
  groupPHCStats,
  prevStatement,
  noGroupPHCStats,
  prevStartDate,
  prevEndDate,
}) => {
  const prevStart = prevStartDate
    ? moment(new Date(prevStartDate))
        .utc()
        .format('LL')
    : null;
  const prevEnd = prevEndDate
    ? moment(new Date(prevEndDate)).format('LL')
    : null;
  return (
    <div className="print-only byTheNumbers">
      <table>
        <tbody>
          <tr>
            <th>Trend</th>
            <th>Title</th>
            <th>Stat</th>
          </tr>
          <tr>
            <td>For Entire Organization</td>
          </tr>
          {buildTable(globalPHCStats, prevStatement)}
          {groupStatus ? null : (
            <tr>
              <td>{subtitle}</td>
            </tr>
          )}
          {buildTable(groupPHCStats, prevStatement)}
          {groupStatus ? buildTable(noGroupPHCStats, prevStatement) : null}
        </tbody>
      </table>
      <div className="footer">
        <p>
          <small className="muted">
            * trend shows the trend from previous statement
            {prevStart && prevEnd
              ? ` (${prevStart} - ${prevEnd})`
              : ` (no available data for previous statement)`}
          </small>
        </p>
        <p>
          <small className="muted">
            * stat shows the current statement&apos;s numbers
          </small>
        </p>
      </div>
    </div>
  );
};

PrintInfo.propTypes = {
  subtitle: PropTypes.string,
  groupStatus: PropTypes.bool,
  groupPHCStats: PropTypes.array,
  noGroupPHCStats: PropTypes.array,
  globalPHCStats: PropTypes.array,
  prevEndDate: PropTypes.string,
  prevStartDate: PropTypes.string,
  prevStatement: PropTypes.shape({}),
};

export default PrintInfo;
