import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';

class BulkCaseActionHelpText extends React.Component {
  static propTypes = {
    store: PropTypes.shape({
      performingBulkAction: PropTypes.bool.isRequired,
    }),
    hidden: PropTypes.bool,
    helpText: PropTypes.string,
  };

  renderHelpText() {
    const { store, helpText } = this.props;

    if (store.performingBulkAction) {
      return 'If you leave the page, any incomplete action will fail.';
    } else {
      return (
        helpText ||
        'This action will be applied to all selected cases and cannot be undone.'
      );
    }
  }

  render() {
    const { hidden } = this.props;

    return (
      <p
        className={classnames('help-text', {
          hidden: hidden,
        })}
      >
        {this.renderHelpText()}
      </p>
    );
  }
}

export default observer(BulkCaseActionHelpText);
