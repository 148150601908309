import React from 'react';
import { observer } from 'mobx-react';
import IncidentView from '../IncidentView';
import IncidentReportStore from '../stores/IncidentReportStore';
import IncidentNoteAndResolutionStore from '../../stores/IncidentNoteAndResolutionStore';
import IncidentReportFilterStore from '../stores/IncidentReportFilterStore';
import { IncidentContext } from '../../ui/IncidentGroupList/Context';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import PropTypes from 'prop-types';

export function mapProps({
  IncidentReportStore,
  IncidentReportFilterStore,
  history,
}) {
  return {
    totalElements:
      IncidentReportStore.totalElements || IncidentReportStore.size,
    downloadCSV: IncidentReportStore.downloadCSV,
    downloadCSVIncidentSummary: IncidentReportStore.downloadCSVIncidentSummary,
    downloadCSVIncidentSummaryAndDetails:
      IncidentReportStore.downloadCSVIncidentSummaryAndDetails,
    isOpen: IncidentReportFilterStore.isAdvancedFiltersOpen,
    toggleOpen: () =>
      history.push(IncidentReportFilterStore.nextAdvancedFiltersURL),
  };
}

class IncidentViewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.incidentNoteAndResolutionStore = IncidentNoteAndResolutionStore({
      incidentsStore: IncidentReportStore,
    });
  }

  render() {
    return (
      <IncidentContext.Provider
        value={{
          incidentStateStore: this.incidentNoteAndResolutionStore,
          incidentReportStore: IncidentReportStore,
        }}
      >
        <IncidentView
          {...mapProps({
            IncidentReportStore,
            IncidentReportFilterStore,
            ...this.props,
          })}
        />
      </IncidentContext.Provider>
    );
  }
}

IncidentViewContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

IncidentViewContainer.displayName = 'IncidentViewContainer';

export default withRouterAndQuery(observer(IncidentViewContainer));
