import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DropdownList } from 'react-widgets';
import {
  INCIDENTS_LABEL,
  INCIDENTS_TEXT_FIELD,
  INCIDENTS_VALUE_FIELD,
} from '../stores/IncidentsPrintOptionsStore/consts';
import ExclusionMultiselect from '../../../ui/Filters/ExclusionMultiselect';
import { toJS } from 'mobx';
import IncidentsCustomFilterOptionsContainer from '../IncidentsCustomFilterOptionsContainer';
import styles from './index.module.scss';

const IncidentsPrintOptions = ({
  selectedMainOption,
  incidentIdsToInclude,
  mainOptions,
  incidentIdOptions,
  handleMainOptionOnChange,
  setIncidentIdsToInclude,
  incidentIdLabel,
  isSelectingIncidentIds = false,
  isCustomizing = false,
  setIncidentPrintOptionsForCaseStore,
  filteredIncidentsForPrint,
  filteredIncidentsCount,
  incidents,
  setIncidentsFromCase,
}) => {
  useEffect(() => {
    setIncidentPrintOptionsForCaseStore(
      selectedMainOption,
      filteredIncidentsForPrint
    );
  }, [
    setIncidentPrintOptionsForCaseStore,
    selectedMainOption,
    filteredIncidentsForPrint,
  ]);

  // refresh the store w/ updated incidents (this happens when notes get added)
  useEffect(() => {
    setIncidentsFromCase(incidents);
  }, [incidents, setIncidentsFromCase]);

  return (
    <div className={styles.dropdownWrapper}>
      <p
        className={styles.label}
      >{`${INCIDENTS_LABEL} - ${filteredIncidentsCount} Matching Incidents`}</p>
      <DropdownList
        data={mainOptions}
        textField={INCIDENTS_TEXT_FIELD}
        valueField={INCIDENTS_VALUE_FIELD}
        value={selectedMainOption}
        onChange={handleMainOptionOnChange}
      />
      {isSelectingIncidentIds && (
        <ExclusionMultiselect
          label={incidentIdLabel}
          options={incidentIdOptions}
          value={toJS(incidentIdsToInclude)}
          textField={INCIDENTS_TEXT_FIELD}
          valueField={INCIDENTS_VALUE_FIELD}
          onChange={setIncidentIdsToInclude}
          hideExclusion
          hideExclusionSpacing
        />
      )}
      {isCustomizing && <IncidentsCustomFilterOptionsContainer />}
    </div>
  );
};

IncidentsPrintOptions.propTypes = {
  selectedMainOption: PropTypes.string,
  mainOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  incidentIdsToInclude: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  incidentIdOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  handleMainOptionOnChange: PropTypes.func.isRequired,
  setIncidentIdsToInclude: PropTypes.func.isRequired,
  incidentIdLabel: PropTypes.string,
  isSelectingIncidentIds: PropTypes.bool,
  isCustomizing: PropTypes.bool,
  filteredIncidentsForPrint: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  setIncidentPrintOptionsForCaseStore: PropTypes.func.isRequired,
  filteredIncidentsCount: PropTypes.number,
  incidents: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  setIncidentsFromCase: PropTypes.func.isRequired,
};

export default IncidentsPrintOptions;
