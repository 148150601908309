import React from 'react';
import { action, autorun, decorate, computed, observable } from 'mobx';
import $ from 'jquery';

import {
  LoginStore,
  CaseClient,
  PermissionStore,
  PermissionSets,
  CaseBundleClient,
  mapValuesToArray,
  RouterContainer,
} from 'common';
import CaseUtils from '../../utils/CaseUtils';
import NotificationStore from '../NotificationStore';
import { isResolutionDescriptionValidFor } from '../../utils/resolutionDescriptions';
import { BundleStore } from '../../bundle/stores/BundleStore';

const ACTION_TYPES = {
  update: 'update',
  assign: 'assign',
  unassign: 'unassign',
  resolve: 'resolve',
  reopen: 'reopen',
  delete: 'delete',
  addAction: 'addAction',
  addNote: 'addNote',
  changeType: 'changeType',
  createBundle: 'createBundle',
  updateBundle: 'updateBundle',
};

/**
 * Store class for performing bulk actions on cases. This collects all of the
 * cases for the provided filtered quest and then identifies on which cases a
 * bulk action can be performed.
 *
 * This extends CaseSearchResultsStore since it needs almost exactly the same
 * properties and methods, but there are some specific things this store needs
 * to be able to do that that one does not.
 */
class BulkCaseActionsStoreClass {
  constructor() {
    autorun(() => {
      if (!this.cases.size) {
        let localCases;
        if (!localStorage.getItem('bulkCases')) {
          localCases = JSON.parse(localStorage.getItem('bulkCases'));
        }
        if (localCases) {
          this.bootstrapCases(localCases);
        }
      }
    });
  }

  // Observables
  cases = new Map();
  selected = new Map();
  completedRequests = new Map();
  completed = false;
  performingBulkAction = false;
  loading = false;
  params;

  // NOT Observables
  currentRequest;
  exiting = false;

  /**
   * Helper method to see if a given case (`caseLike`) can be modified in the
   * requested way by the current user. This prevents users from modifying
   * other users' cases (unless they're allowed to do so) or incorrectly
   * changing states (Violation => Not Violation without first reopening the
   * case).
   * @param {Object} caseLike - the case object to evalute
   * @param {String} action   - the action name being performed; mostly useful
   *                            for determining if the case is being reopened
   * @param {Object} data     - details of the edit
   * @return {Boolean|String} - either a Boolean value or a string description
   *                            for the failure
   */
  canEdit(caseLike, action, data) {
    const defaultReason = "You don't have permission to modify the case.",
      { resolution } = caseLike,
      isOwner = caseLike.owner && caseLike.owner.id === LoginStore.id,
      isCreator = caseLike.createdBy && caseLike.createdBy.id === LoginStore.id;

    let canEdit = true;

    switch (action) {
      case ACTION_TYPES.update: // case notes and case assessment
        if (resolution) {
          // there's a resolution, check to see if the user can modify a resolved case
          if (
            !PermissionStore.hasAll(
              PermissionSets.changeResolvedNotesAssessment
            )
          ) {
            canEdit = 'The case is already resolved.';
          }
        } else {
          // no resolution, do they own it?
          if (
            isOwner &&
            !PermissionStore.hasAll(PermissionSets.changeOwnedNotesAssessment)
          ) {
            // they do; see if they can edit their own cases
            canEdit = defaultReason;
          } else if (
            !isOwner &&
            !PermissionStore.hasAll(
              PermissionSets.changeCaseNotes.concat(
                PermissionSets.changeCaseAssessment
              )
            )
          ) {
            // they do not; see if they can edit others' cases
            canEdit = defaultReason;
          }
        }

        return canEdit;

      case ACTION_TYPES.assign:
      case ACTION_TYPES.unassign:
        if (!PermissionStore.has('CASE_ASSIGN')) {
          canEdit = defaultReason;
        }

        return canEdit;

      case ACTION_TYPES.resolve:
        if (resolution) {
          canEdit = 'The case is already resolved.';
        } else if (
          !(
            PermissionStore.hasAll(PermissionSets.resolveAnyCase) ||
            (isOwner && PermissionStore.has('CASE_RESOLVE_OWNED'))
          )
        ) {
          canEdit =
            'The case is not assigned to you or you can not modify the case.';
        } else if (
          data.resolutionDescription &&
          !isResolutionDescriptionValidFor(
            data.resolutionDescription,
            CaseUtils.whichCaseType(caseLike),
            data.resolution
          )
        )
          canEdit = `Invalid resolution description for ${CaseUtils.whichCaseType(
            caseLike
          )} case.`;
        return canEdit;

      case ACTION_TYPES.reopen:
        if (!resolution) {
          return 'The case is already open.';
        } else if (
          !(
            PermissionStore.hasAll(PermissionSets.reopenAnyCase) ||
            (isOwner && PermissionStore.has('CASE_REOPEN_OWNED'))
          )
        ) {
          canEdit =
            'The case is not assigned to you or you can not modify the case.';
        }

        return canEdit;

      case ACTION_TYPES.delete:
        if (
          !(
            PermissionStore.has('CASE_DELETE') ||
            (isOwner && PermissionStore.has('CASE_DELETE_OWNED')) ||
            (isCreator && PermissionStore.has('CASE_DELETE_CREATED_BY'))
          )
        ) {
          canEdit = 'You did not create this case.';
        }

        return canEdit;

      case ACTION_TYPES.addAction: // add a case action
        if (resolution) {
          canEdit = 'The case is already resolved.';
        } else if (
          !(
            PermissionStore.has('CASE_MODIFY_ACTIONS') ||
            (isOwner && PermissionStore.has('CASE_MODIFY_ACTIONS_OWNED'))
          )
        ) {
          canEdit = 'You did not create this case.';
        }

        return canEdit;

      case ACTION_TYPES.addNote:
        if (resolution) {
          canEdit = 'The case is already resolved.';
        } else if (
          !(
            PermissionStore.has('CASE_MODIFY_NOTES') ||
            (isOwner && PermissionStore.has('CASE_MODIFY_NOTES_OWNED'))
          )
        ) {
          canEdit = 'You do not own this case';
        }

        return canEdit;

      case ACTION_TYPES.changeType:
        if (!PermissionStore.has('CASE_MODIFY_CATEGORY'))
          canEdit = defaultReason;
        return canEdit;

      case ACTION_TYPES.createBundle:
        if (!PermissionStore.has('BUNDLE_CREATE')) canEdit = defaultReason;
        return canEdit;

      case ACTION_TYPES.updateBundle:
        if (!PermissionStore.has('BUNDLE_MODIFY')) canEdit = defaultReason;
        return canEdit;

      default:
        // if we don't know what to do with it, we don't let them do anything
        return defaultReason;
    }
  }

  filterCallback(r) {
    if (r.status && r.status >= 200 && r.status < 300) {
      return 1;
    }
  }

  isSelected(c) {
    return this.selected.has(c.id);
  }

  clearSelection() {
    this.selected.clear();
  }

  storeLocally(cases) {
    const storedCases = cases.forEach(c => ({ id: c.id }));

    if (storedCases)
      localStorage.setItem('bulkCases', JSON.stringify(storedCases));
  }

  killRequests() {
    if (this.performingBulkAction) {
      this.reset();
      this.exiting = true;
      this.currentRequest && this.currentRequest.abort();
    }
  }

  clearLocalStorage() {
    localStorage.removeItem('bulkCases');
  }

  // Computed
  get someSelected() {
    if (!this.visibleCases.length) return false;
    return (
      this.selected.size > 0 && this.selected.size < this.visibleCases.length
    );
  }

  // Computed
  get allSelected() {
    if (!this.visibleCases.length) return false;
    return this.selected.size === this.visibleCases.length;
  }

  // compute the percentage of completed requests.
  // Computed
  get percentComplete() {
    if (!this.cases.size || !this.selected.size || !this.completedRequests.size)
      return 0;
    return Math.min(
      Math.ceil((this.completedRequests.size / this.selected.size) * 100),
      100
    ); // don't exceed 100%
  }

  // Computed
  get completeSuccessCount() {
    return mapValuesToArray(this.completedRequests).filter(
      r => r.status && r.status >= 200 && r.status < 300
    ).length;
  }

  // Computed
  get completeFailureCount() {
    return mapValuesToArray(this.completedRequests).filter(
      r => !r.status || (r.status >= 400 && r.status < 600)
    ).length;
  }

  // Computed
  get size() {
    return this.cases.size;
  }

  // Computed
  get visibleCases() {
    // hidden cases will consist only of an id field, check for the ubiquitous number field to ensure we have the full case
    return mapValuesToArray(this.cases).filter(c => c.number);
  }

  // Computed
  get actions() {
    const actions = [];

    if (PermissionStore.hasAny(PermissionSets.changeCaseResolution)) {
      actions.push({
        value: 'resolution',
        label: 'Change Case Resolution',
      });
    }

    if (PermissionStore.has('CASE_ASSIGN')) {
      actions.push({
        value: 'owner',
        label: 'Change Case Owner',
      });
    }

    if (PermissionStore.has('CASE_MODIFY_CATEGORY')) {
      actions.push({
        value: 'type',
        label: 'Change Case Type',
      });
    }

    if (PermissionStore.hasAny(PermissionSets.changeCaseActions)) {
      actions.push({
        value: 'action',
        label: 'Add an Action',
      });
    }

    if (PermissionStore.hasAny(PermissionSets.changeCaseNotes)) {
      actions.push({
        value: 'notes',
        label: 'Add Case Notes',
      });
    }

    if (PermissionStore.hasAny(PermissionSets.changeCaseAssessment)) {
      actions.push({
        value: 'assessment',
        label: 'Overwrite Final Assessment',
      });
    }

    if (PermissionStore.hasAny(PermissionSets.bundleCases)) {
      actions.push({
        value: 'bundle',
        label: 'Add to Bundle',
      });
    }

    if (PermissionStore.hasAny(PermissionSets.deleteCases)) {
      actions.push({
        value: 'delete',
        label: 'Delete Cases',
      });
    }

    return actions.sort((a, b) => {
      if (a.label.toUpperCase() < b.label.toUpperCase()) return -1;
      if (a.label.toUpperCase() > b.label.toUpperCase()) return 1;
      return 0;
    });
  }

  // Action
  toggleSelect(c) {
    const { id } = c;
    if (this.selected.has(id)) {
      this.selected.delete(id);
    } else {
      this.select(c);
    }
  }

  // Action
  toggleSelectAll() {
    if (this.allSelected) {
      this.clearSelection();
    } else {
      this.visibleCases.forEach(c => this.select(c));
    }
  }

  // Action
  select(c) {
    const { id } = c;
    this.selected.set(id, c);
  }

  // select all cases, regardless of state
  // Action
  selectAll() {
    this.visibleCases.forEach(c => this.select(c));
  }

  /**
   * Empties out the different state collections (selected, completedRequests,
   * etc) and returns the store to a starting state where data is available
   * (this.cases) but nothing has been "done" yet.
   */
  // Action
  clear() {
    this.reset();

    // clear out the data collections
    this.selected.clear();
    this.cases.clear();
    // clear out localStorage
    this.clearLocalStorage();
  }

  /**
   * Return the store to its starting state
   */
  // Action
  reset() {
    // clear out the data collections
    this.completedRequests.clear();
    // reset the boolean state options
    this.performingBulkAction = false;
    this.completed = false;
  }

  // Action
  performBulkAction(action, data) {
    this.completed = false;
    this.completedRequests.clear();
    this.performingBulkAction = true;
    this.exiting = false;
    /**
     * Private recursive function that iterates over the list of cases, making
     * a bulk action request for each of them. The requests will be fired
     * sequentially so as to not overload the browser with potentially hundreds
     * of requests causing a poor user experience.
     * @param {Array}   caseList - the list of cases for which the requests
     *                             should be made
     * @param {String}  action   - the name of the action being performed
     * @param {Object}  data     - the data being used to perform the action
     * @return {undefined}
     */
    const makeRequest = (caseList, action, data) => {
      // The base call to makeRequest caseList is a Map Object and we need to pull the values out into an array and use that.
      if (!Array.isArray(caseList)) caseList = mapValuesToArray(caseList);
      const caseLike = caseList[0];
      const newCaseList = caseList.slice(1); // copy the caseList without the current case Object
      const canEdit = this.canEdit(caseLike, action, data);
      const onComplete = (c, xhr, data) => {
        this.completedRequests.set(c.id, xhr);

        const halfFailed =
          xhr.status === 500 &&
          xhr.responseJSON &&
          xhr.responseJSON.message &&
          xhr.responseJSON.message.indexOf('amazonaws') !== -1;

        if ((xhr.status >= 200 && xhr.status < 300) || halfFailed) {
          // update the local data if the request was successful
          if (
            action === ACTION_TYPES.assign ||
            action === ACTION_TYPES.unassign
          ) {
            this.getCaseInfo(c);
          } else if (action === ACTION_TYPES.delete) {
            this.cases.delete(c.id);
          } else {
            let updatedCase = {};

            if (action === ACTION_TYPES.reopen) {
              updatedCase = Object.assign(c, data, { resolution: null });
            } else {
              const id = c.id;
              updatedCase = Object.assign(c, data);
              updatedCase.id = id;
              if (action === ACTION_TYPES.changeType) {
                this.getCaseInfo(c);
              }
            }

            this.cases.set(c.id, updatedCase);
          }
        }

        if (this.exiting) return; // don't keep running the requests if exiting

        if (
          newCaseList.length > 0 &&
          action !== ACTION_TYPES.createBundle &&
          action !== ACTION_TYPES.updateBundle
        ) {
          // take advantage of the current scope and that action and data are
          // already defined
          makeRequest(newCaseList, action, data);
        } else {
          this.completed = true;
          this.performingBulkAction = false;

          if (action === ACTION_TYPES.resolve) {
            if (data && data.tempPermission && xhr.status < 400) {
              const content = (
                <span>
                  <i className="material-icons icon-check_circle" />
                  Successfully created{' '}
                  <a href={RouterContainer.href('/authorizations/temporary')}>
                    temporary permissions.
                  </a>
                </span>
              );
              NotificationStore.add({ level: 'success', content: content });
            }
          }

          if (RouterContainer.path === '/bulkCaseActions') this.refreshCases();
          if (RouterContainer.path.includes('bundles')) BundleStore.refresh();

          CaseUtils.synchronize();
        }
      };

      if (canEdit === true) {
        if (action === ACTION_TYPES.createBundle) {
          this.currentRequest = CaseBundleClient.create(data, {
            complete: xhr => {
              onComplete(caseLike, xhr, data);
            },
          });
        } else if (action === ACTION_TYPES.updateBundle) {
          const { id, cases } = data;
          this.currentRequest = CaseBundleClient.addCases(id, cases, {
            complete: xhr => {
              onComplete(caseLike, xhr, data);
            },
          });
        } else {
          this.currentRequest = CaseClient.bulkAction(caseLike, action, data, {
            complete: xhr => {
              onComplete(caseLike, xhr, data);
            },
          });
        }

        return this.currentRequest;
      } else {
        // permissions check failed
        // create and reject a promise so the status is updated properly
        const dfd = $.Deferred();
        dfd.then(null, xhr => {
          // the success path doesn't matter here since we're manually failing the request
          onComplete(caseLike, xhr, data);
        });

        dfd.reject({
          status: 500,
          statusText: canEdit,
        });

        return dfd;
      }
    };
    return makeRequest(this.selected, action, data);
  }

  // Action
  getCaseInfo(caseLike) {
    return CaseClient.get(caseLike.id, { projection: 'minimum' }).then(r => {
      this.cases.set(r.id, r);
      if (this.selected.has(r.id)) {
        // toggle the select state twice to update the data in this.selected
        this.toggleSelect(r);
        this.toggleSelect(r);
      }
    });
  }

  // Action
  bootstrapCases(cases) {
    if (!cases) return null;

    this.clear(); // don't keep anything in localStorage when we're bootstrapping, clear out any stored data

    const queuedRequests = [];
    const onAllComplete = () => {
      this.selectAll();
      this.loading = false;
    };

    this.loading = true;

    cases.forEach(c => {
      this.cases.set(c.id, c);
    });

    this.cases.forEach(c => {
      if (!Object.prototype.hasOwnProperty.call(c, 'number')) {
        queuedRequests.push(this.getCaseInfo(c));
      }
    });

    this.storeLocally(this.cases);

    setTimeout(() => {
      if (!queuedRequests.length) {
        this.selectAll();
        this.loading = false;
      }
    }, 100);

    $.when(...queuedRequests).then(onAllComplete, onAllComplete);
  }

  // Computed
  // computed that determines if the selected cases are made of mixed case types
  get mixedCaseTypesSelected() {
    const cases = mapValuesToArray(this.selected);
    const hasPatient = Boolean(cases[0].patientSummary);
    // check for the existence of a patient, standard privacy cases are now
    // the only case types with a patient
    return Boolean(
      !cases.every(caze => Boolean(caze.patientSummary) === hasPatient)
    );
  }

  // Computed
  get standardPrivacyCasesSelected() {
    const cases = mapValuesToArray(this.selected);
    return Boolean(
      cases.find(
        caze =>
          CaseUtils.whichCaseType(caze) === 'privacy' && !!caze.patientSummary
      )
    );
  }

  // Computed
  get selectionIncludesPrivacyCases() {
    const cases = mapValuesToArray(this.selected);
    return Boolean(
      cases.find(caze => CaseUtils.whichCaseType(caze) === 'privacy')
    );
  }

  // Computed
  get selectionIncludesDiversionCases() {
    const cases = mapValuesToArray(this.selected);
    return Boolean(
      cases.find(caze => CaseUtils.whichCaseType(caze) === 'diversion')
    );
  }

  // Used for refreshing query results from /cases
  setParams = params => (this.params = params);

  setCases = map => (this.cases = map);

  refreshCases() {
    if (!this.performingBulkAction && !this.loading && this.params) {
      CaseClient.getIds(this.params).then(r => {
        this.bootstrapCases(r._embedded.case);
      });
    }
  }
}

decorate(BulkCaseActionsStoreClass, {
  // Observables
  cases: observable,
  selected: observable,
  completedRequests: observable,
  completed: observable,
  performingBulkAction: observable,
  loading: observable,
  params: observable,
  // Computeds
  someSelected: computed,
  allSelected: computed,
  percentComplete: computed,
  completeSuccessCount: computed,
  completeFailureCount: computed,
  size: computed,
  visibleCases: computed,
  actions: computed,
  mixedCaseTypesSelected: computed,
  standardPrivacyCasesSelected: computed,
  selectionIncludesPrivacyCases: computed,
  selectionIncludesDiversionCases: computed,
  // Actions
  toggleSelect: action,
  toggleSelectAll: action,
  select: action,
  selectAll: action,
  clear: action,
  reset: action,
  performBulkAction: action,
  getCaseInfo: action,
  bootstrapCases: action,
  refreshCases: action,
  setParams: action,
  setCases: action,
});

const store = new BulkCaseActionsStoreClass();
export { store as BulkCaseActionsStore, BulkCaseActionsStoreClass };
export default store;
