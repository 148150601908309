import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { PandyOutline } from 'common';
import MultiRecordCompositeFieldGroup from '../MultiRecordCompositeFieldGroup';
import MultiRecordCompositeFieldTieredGroup from '../MultiRecordCompositeFieldGroup/Tiered.jsx';
import ShowHideMore from '../../ui/ShowHideMore';
import { TYPE_ENUMS } from '../MultiRecordCompositeField/const';
import { singularize } from '../utils/helpers';

const MultiRecordCompositeFilteredSection = ({
  name,
  groups,
  isExpandable,
  expansionThreshold,
  matchingGroups,
  nonMatchingGroups,
  filterTerm,
}) => {
  // if no groups, show Pandy
  if (groups.length === 0) return <PandyOutline name={name} />;
  return (
    <div>
      {/* handles uncategorized sections */}
      {name === null || name === '' ? (
        groups?.map((group, i) => (
          <div key={`${group.fieldName}${i}`}>
            {group.fields[0].type !== TYPE_ENUMS.object ? (
              <MultiRecordCompositeFieldGroup
                fields={group.fields}
                fieldName={group.fieldName}
                filteredTerm={filterTerm}
              />
            ) : (
              <>
                {group?.fields?.map((tieredField, i) => (
                  <MultiRecordCompositeFieldTieredGroup
                    key={i}
                    tieredField={tieredField}
                    fieldName={group.fieldName}
                    filteredTerm={filterTerm}
                  />
                ))}
              </>
            )}
          </div>
        ))
      ) : /* handles when user is filtering */
      filterTerm.length > 0 ? (
        <>
          {matchingGroups?.map((group, i) => (
            <div key={`${group.fieldName}${i}`}>
              {group.fields[0].type !== TYPE_ENUMS.object ? (
                <MultiRecordCompositeFieldGroup
                  fields={group.fields}
                  fieldName={group.fieldName}
                  filteredTerm={filterTerm}
                />
              ) : (
                <>
                  {group?.fields?.map(tieredField => (
                    <MultiRecordCompositeFieldTieredGroup
                      key={`tiered${tieredField.field}${i}`}
                      tieredField={tieredField}
                      fieldName={group.fieldName}
                      filteredTerm={filterTerm}
                    />
                  ))}
                </>
              )}
            </div>
          ))}
          <ShowHideMore
            numberToShow={0}
            label={singularize(name)}
            customLabel={'Non-Matching'}
          >
            {nonMatchingGroups?.map((group, i) => (
              <div key={`${group.fieldName}${i}`}>
                {group.fields[0].type !== TYPE_ENUMS.object ? (
                  <MultiRecordCompositeFieldGroup
                    fields={group.fields}
                    fieldName={group.fieldName}
                    filteredTerm={filterTerm}
                  />
                ) : (
                  <>
                    {group?.fields?.map(tieredField => (
                      <MultiRecordCompositeFieldTieredGroup
                        key={`tiered${tieredField.field}${i}`}
                        tieredField={tieredField}
                        fieldName={group.fieldName}
                        filteredTerm={filterTerm}
                      />
                    ))}
                  </>
                )}
              </div>
            ))}
          </ShowHideMore>
        </>
      ) : (
        /* handles default, user not filtering */
        <ShowHideMore
          numberToShow={!isExpandable ? 1000 : expansionThreshold}
          label={singularize(name)}
        >
          {groups?.map((group, i) => (
            <div key={`${group.fieldName}${i}`}>
              {group.fields[0].type !== TYPE_ENUMS.object ? (
                <>
                  <MultiRecordCompositeFieldGroup
                    fields={group.fields}
                    fieldName={group.fieldName}
                    filteredTerm={filterTerm}
                  />
                </>
              ) : (
                <>
                  {group?.fields?.map((tieredField, i) => (
                    <MultiRecordCompositeFieldTieredGroup
                      key={`tiered${tieredField.field}${i}`}
                      tieredField={tieredField}
                      fieldName={group.fieldName}
                      filteredTerm={filterTerm}
                    />
                  ))}
                </>
              )}
            </div>
          ))}
        </ShowHideMore>
      )}
    </div>
  );
};

MultiRecordCompositeFilteredSection.propTypes = {
  name: PropTypes.string,
  groups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  isExpandable: PropTypes.bool,
  expansionThreshold: PropTypes.number,
  matchingGroups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  nonMatchingGroups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
  filterTerm: PropTypes.string,
};

MultiRecordCompositeFilteredSection.defaultProps = {
  name: null,
  groups: [],
  isExpandable: false,
  expansionThreshold: 2,
  filterTerm: '',
  matchingGroups: [],
  nonMatchingGroups: [],
};

export default MultiRecordCompositeFilteredSection;
