import { sortMap, sortOptions } from './const';

export const makeNewLabelQuery = ({
  newValue,
  isUserFocus,
  sortOrder,
  getQuery = () => {},
}) => {
  const overrides = {};

  if (!newValue) return overrides;

  if (isUserFocus) overrides.patientLabel = newValue.id;
  else overrides.userLabel = newValue.id;

  if (sortOptions[sortOrder] === 'time') {
    overrides.sort = 'startTime,asc';
  } else {
    let prefix = '';
    if (newValue.id !== 'suspicionScore')
      prefix = isUserFocus ? 'patient.' : 'user.';
    overrides.sort = `${prefix}${sortMap[newValue.id]},${
      sortOptions[sortOrder]
    }`;
  }

  // clear filters on label change
  overrides.subFilter1 = '';
  overrides.subFilter2 = '';

  return getQuery(overrides);
};

export const makeNewSortQuery = ({
  sortOrder,
  storeActiveLabel,
  isUserFocus,
  getQuery = () => {},
}) => {
  const overrides = {};

  if (sortOptions[sortOrder] === 'time') {
    overrides.sort = 'startTime,asc';
  } else {
    let prefix = '';
    if (storeActiveLabel !== 'suspicionScore')
      prefix = isUserFocus ? 'patient.' : 'user.';
    overrides.sort = `${prefix}${sortMap[storeActiveLabel]},${sortOptions[sortOrder]}`;
  }

  return getQuery(overrides);
};
