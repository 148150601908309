import moment from 'moment';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { ReasonStatistics, IncidentDescriptionStore } from 'common';
import Anchor from '../Anchor';
import { Link } from 'react-router-dom';
import { IncidentsType } from '../../types/incidentsTypes';
import { ReasonsType } from '../../types/reasonsTypes';
import incidentPropTypes from '../../propTypes/incidentPropTypes';

interface IncidentHeadlineProps {
  endDate: string;
  headlineReasons: ReasonsType[];
  incidents: IncidentsType[];
  page: string;
  onClick: (arg: IncidentsType[]) => void;
  startDate: string;
}

// this is not a part of Header component and only applies to diversion
const IncidentHeadline = ({
  endDate,
  headlineReasons,
  incidents,
  onClick,
  page,
  startDate,
}: IncidentHeadlineProps) => {
  const builtLink = headlineReasons.map(reason => {
    const params: Record<string, any> = {};

    if (page.startsWith('/incident')) {
      // Linking to incident report page
      params.range = 'custom';
      params.showFilters = true;
      params.type = IncidentDescriptionStore.defsMap[reason.identifier];
      params.rangeAfter = moment(startDate).format('YYYY-MM-DD');
      params.rangeBefore = moment(endDate).format('YYYY-MM-DD');
    } else {
      // Linking to incident tab on case page
      params.range = [startDate, endDate]
        .map(date => moment(date).toISOString())
        .join(',');
      params.type = reason.identifier;
    }

    const queryString = Object.entries(params)
      .map(e => `${e[0]}=${e[1]}`)
      .join('&');

    return {
      pathname: page,
      search: `?${queryString}`,
    };
  });

  const incs = headlineReasons.map(reason => {
    if (incidents) {
      return incidents.filter(inc => inc.type === reason.identifier);
    }

    return incidents;
  });

  return (
    <div className="incident-headline-container" data-cy="assessment-incidents">
      <p className="incident-label">Incidents</p>
      {headlineReasons.map((reason, i) => {
        return (
          <div className="incident-reason-container" key={i}>
            <p className="content">
              {`${reason.description} `}
              {builtLink ? (
                <Link data-cy="assessment-incidents-content" to={builtLink[i]}>
                  View Incidents
                </Link>
              ) : (
                onClick && (
                  <Anchor
                    data-cy="assessment-incidents-content"
                    disabled={false}
                    className={null}
                    tag={'span'}
                    onClick={() => onClick(incs[0] && incs[0])}
                  >
                    View Incidents
                  </Anchor>
                )
              )}
            </p>
            {reason.statistics !== undefined && (
              <ReasonStatistics statistics={reason.statistics} />
            )}
          </div>
        );
      })}
    </div>
  );
};

IncidentHeadline.propTypes = {
  headlineReasons: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      indentifier: PropTypes.string,
      statistics: PropTypes.shape({
        mean: PropTypes.number,
        zscore: PropTypes.number,
      }),
    })
  ),
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  incidents: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape(incidentPropTypes)
  ),
};

IncidentHeadline.defaultProps = {
  headlineReasons: [],
  onClick: () => {},
};

export default IncidentHeadline;
