import { autorun, computed, decorate, observable } from 'mobx';

import { SingletonStore, PersonTagsClient, PermissionStore } from 'common';

class PersonTagsCountStore extends SingletonStore {
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  initialized = false;

  // Computed
  get canView() {
    return PermissionStore.has('PERSON_TAG_VIEW');
  }

  // Computed
  get counts() {
    const tagCounts = {};

    if (this.response) {
      this.response.forEach(ptr => {
        tagCounts[ptr.group.name] = ptr.count;
      });
    }

    return tagCounts;
  }

  countFor(tagName) {
    return this.counts[tagName];
  }

  init() {
    this.initialized = true;
  }

  fetch() {
    if (this.initialized && this.canView) {
      return PersonTagsClient.getPeopleCount();
    } else {
      return [];
    }
  }
}

decorate(PersonTagsCountStore, {
  initialized: observable,
  canView: computed,
  counts: computed,
});

export default new PersonTagsCountStore();
