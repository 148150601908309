import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { DoubleViewContext } from '../../../Context';
import MultiRecordEntityColumn from '../../../MultiRecordEntityColumn';
import { employeeColumnMapProps } from '../../mapProps';

const DoubleViewEmployeeColumnContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DoubleViewContext,
    mapProps: employeeColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);

export default DoubleViewEmployeeColumnContainer;
