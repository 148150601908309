import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import AnimateHeight from 'react-animate-height';
import { Anchor, Checkbox, Input } from '../../../ui';
import styles from './index.module.scss';

const CaseNotifications = ({
  onSave,
  onChangeSetting,
  editedGroups,
  isDirty,
}) => {
  const groupHasDayReminder = (group, day) =>
    group.caseReminderPreferences.dayReminders.includes(day);

  return (
    <div id="settings__wrapper-Case_Notifications" className="wrapper">
      <div className="card_header">
        <h1>Case Age Notifications</h1>
        <Anchor
          tag="span"
          onClick={onSave}
          className={classnames({ muted: !isDirty })}
        >
          Save Changes
        </Anchor>
      </div>

      <div className="content">
        <div className={styles.notificationText}>
          Send an email to a case&#39;s owner when that case approaches its
          maximum expected case age. Users with multiple reminders will be sent
          one email digest per day.
        </div>

        {editedGroups.length > 1 ? (
          <div className={styles.notificationText}>
            Enable Case Age Notifications for cases belonging to:
          </div>
        ) : null}

        {editedGroups.map(group => (
          <div key={group.id}>
            <div>
              <Checkbox
                onChange={() => onChangeSetting(group, 'enabled')}
                checked={group.caseReminderPreferences.enabled}
                label={
                  editedGroups.length === 1
                    ? 'Enable Case Age Notifications'
                    : group.name
                }
                color="secondary"
              />
            </div>

            <AnimateHeight
              height={group.caseReminderPreferences.enabled ? 'auto' : 0}
            >
              <ul className={styles.indentedSection}>
                {editedGroups.length > 1 ? (
                  <div>
                    The maximum expected case age for cases in this category:
                  </div>
                ) : (
                  <div>The maximum expected case age:</div>
                )}
                <li>
                  <Input
                    className={styles.daysInput}
                    label="Days"
                    value={group.caseReminderPreferences.deadline}
                    onChange={value =>
                      onChangeSetting(group, 'deadline', value)
                    }
                    type="number"
                    min={1}
                    max={99999}
                    bordered
                    error={!group.caseReminderPreferences.deadline}
                  />
                </li>
                <div>Send a notification to the case owner when a case is:</div>
                <li>
                  <Checkbox
                    onChange={() => onChangeSetting(group, 'send30')}
                    checked={groupHasDayReminder(group, 30)}
                    label="30 days away from the maximum case age"
                    color="secondary"
                  />
                </li>
                <li>
                  <Checkbox
                    onChange={() => onChangeSetting(group, 'send7')}
                    checked={groupHasDayReminder(group, 7)}
                    label="7 days away from the maximum case age"
                    color="secondary"
                  />
                </li>
                <li>
                  <Checkbox
                    onChange={() => onChangeSetting(group, 'send1')}
                    checked={groupHasDayReminder(group, 1)}
                    label="1 day away from the maximum case age"
                    color="secondary"
                  />
                </li>
              </ul>
            </AnimateHeight>
          </div>
        ))}
        <br />
        <h3>
          <i className="material-icons icon-notifications tip" />
          Tip: Notifications will not be sent for unassigned cases.
        </h3>
      </div>
    </div>
  );
};

CaseNotifications.propTypes = {
  onSave: PropTypes.func.isRequired,
  onChangeSetting: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  editedGroups: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape())
    .isRequired,
};

export default CaseNotifications;
