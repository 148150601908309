import { PERSON_TYPE_USER, PERSON_TYPE_PATIENT } from '../stores/const';

export function employeeColumnMapProps({
  employeeDropdownStore,
  employeeColumnStore,
  match,
}) {
  return {
    selectedOption: employeeDropdownStore.selectedOption,
    information: employeeColumnStore.information,
    setInformationType: employeeColumnStore.setInformationType,
    setEmployeeId: employeeColumnStore.setEmployeeId,
    employeeId: match?.params?.employeeId,
    setCaseId: employeeColumnStore.setCaseId,
    caseId: match?.params?.caseId,
    personalInformationSections:
      employeeColumnStore.personalInformationSections,
    employeeInformationSections:
      employeeColumnStore.employeeInformationSections,
    patientInformationSections: employeeColumnStore.patientInformationSections,
    personType: PERSON_TYPE_USER,
  };
}

export function employeeDropdownMapProps({
  employeeDropdownStore,
  employeeColumnStore,
}) {
  return {
    selectedOption: employeeDropdownStore.selectedOption,
    onChange: employeeDropdownStore.setSelectedOption,
    options: employeeDropdownStore.options,
    disabledOption: employeeColumnStore.disabledOption,
    previouslySelected: employeeDropdownStore.getPreviouslySelected(
      employeeDropdownStore.sessionStorageKey
    ),
    setPreviouslySelected: employeeDropdownStore.setPreviouslySelected,
    information: employeeColumnStore.information,
    setInformation: employeeDropdownStore.setInformation,
    sessionStorageKey: employeeDropdownStore.sessionStorageKey,
  };
}

export function patientColumnMapProps({
  patientDropdownStore,
  patientColumnStore,
  match,
}) {
  return {
    selectedOption: patientDropdownStore.selectedOption,
    information: patientColumnStore.information,
    setInformationType: patientColumnStore.setInformationType,
    setPatientId: patientColumnStore.setPatientId,
    patientId: match?.params?.patientId,
    setCaseId: patientColumnStore.setCaseId,
    caseId: match?.params?.caseId,
    personalInformationSections:
      patientColumnStore?.personalInformationSections,
    employeeInformationSections:
      patientColumnStore?.employeeInformationSections,
    patientInformationSections: patientColumnStore?.patientInformationSections,
    personType: PERSON_TYPE_PATIENT,
  };
}

export function patientDropdownMapProps({
  patientDropdownStore,
  patientColumnStore,
}) {
  return {
    selectedOption: patientDropdownStore.selectedOption,
    onChange: patientDropdownStore.setSelectedOption,
    options: patientDropdownStore.options,
    disabledOption: patientColumnStore.disabledOption,
    previouslySelected: patientDropdownStore.getPreviouslySelected(
      patientDropdownStore.sessionStorageKey
    ),
    setPreviouslySelected: patientDropdownStore.setPreviouslySelected,
    sessionStorageKey: patientDropdownStore.sessionStorageKey,
  };
}

export function employeeSecondColumnMapProps({
  employeeDropdownStoreSecond,
  employeeColumnStoreSecond,
  match,
}) {
  return {
    selectedOption: employeeDropdownStoreSecond.selectedOption,
    information: employeeColumnStoreSecond.information,
    setInformationType: employeeColumnStoreSecond.setInformationType,
    setEmployeeId: employeeColumnStoreSecond.setEmployeeId,
    employeeId: match?.params?.employeeId,
    setCaseId: employeeColumnStoreSecond.setCaseId,
    caseId: match?.params?.caseId,
    personalInformationSections:
      employeeColumnStoreSecond.personalInformationSections,
    employeeInformationSections:
      employeeColumnStoreSecond.employeeInformationSections,
    patientInformationSections:
      employeeColumnStoreSecond.patientInformationSections,
    personType: PERSON_TYPE_USER,
  };
}

export function employeeSecondDropdownMapProps({
  employeeDropdownStoreSecond,
  employeeColumnStoreSecond,
}) {
  return {
    selectedOption: employeeDropdownStoreSecond.selectedOption,
    onChange: employeeDropdownStoreSecond.setSelectedOption,
    options: employeeDropdownStoreSecond.options,
    disabledOption: employeeColumnStoreSecond.disabledOption,
    previouslySelected: employeeDropdownStoreSecond.getPreviouslySelected(
      employeeDropdownStoreSecond.sessionStorageKey
    ),
    setPreviouslySelected: employeeDropdownStoreSecond.setPreviouslySelected,
    sessionStorageKey: employeeDropdownStoreSecond.sessionStorageKey,
  };
}

export function patientSecondColumnMapProps({
  patientDropdownStoreSecond,
  patientColumnStoreSecond,
  match,
}) {
  return {
    selectedOption: patientDropdownStoreSecond.selectedOption,
    information: patientColumnStoreSecond.information,
    setInformationType: patientColumnStoreSecond.setInformationType,
    setPatientId: patientColumnStoreSecond.setPatientId,
    patientId: match?.params?.patientId,
    setCaseId: patientColumnStoreSecond.setCaseId,
    caseId: match?.params?.caseId,
    personalInformationSections:
      patientColumnStoreSecond.personalInformationSections,
    employeeInformationSections:
      patientColumnStoreSecond.employeeInformationSections,
    patientInformationSections:
      patientColumnStoreSecond.patientInformationSections,
    personType: PERSON_TYPE_PATIENT,
  };
}

export function patientSecondDropdownMapProps({
  patientDropdownStoreSecond,
  patientColumnStoreSecond,
}) {
  return {
    selectedOption: patientDropdownStoreSecond.selectedOption,
    onChange: patientDropdownStoreSecond.setSelectedOption,
    options: patientDropdownStoreSecond.options,
    disabledOption: patientColumnStoreSecond.disabledOption,
    previouslySelected: patientDropdownStoreSecond.getPreviouslySelected(
      patientDropdownStoreSecond.sessionStorageKey
    ),
    setPreviouslySelected: patientDropdownStoreSecond.setPreviouslySelected,
    sessionStorageKey: patientDropdownStoreSecond.sessionStorageKey,
  };
}
