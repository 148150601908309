import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';

import { CategoryComponent, DateHelpers, RouterContainer } from 'common';
import CaseTemplate from '../../../case/CaseTemplate';

import { Card, CardHeader } from '../../../ui/Card';
import SuspicionScore from '../SuspicionScore';
import CaseBundleIndicator from '../../../ui/CaseBundleIndicator';
import { getResolutionDisplay } from '../../../utils/resolutionDescriptions';

const CaseLI = class extends React.Component {
  static propTypes = {
    case: PropTypes.shape({
      owner: PropTypes.object,
      resolution: PropTypes.string,
    }).isRequired,
    comment: PropTypes.shape({
      created: PropTypes.string,
      createdBy: PropTypes.object,
      description: PropTypes.string,
    }),
    fadeIn: PropTypes.bool,
    omitDetails: PropTypes.bool,
    useGroups: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    comment: undefined,
    fadeIn: false,
    omitDetails: false,
  };

  handleOwnerSelect() {
    RouterContainer.go('/cases', {
      owner: this.props.case.owner.id,
    });
  }

  formatDateWithTimezone(date, timezone) {
    const m = moment(date).tz(timezone);
    const midnight = moment()
      .tz(timezone)
      .millisecond(0)
      .second(0)
      .minute(0)
      .hour(0);
    const yesterday = moment(midnight).subtract(1, 'days');

    if (m.diff(midnight) >= 0) return m.fromNow();
    else if (m.diff(yesterday) >= 0) return `Yesterday ${m.format('h:mm A z')}`;
    return m.format('lll z');
  }

  formatDate(date) {
    const m = moment(date);
    const midnight = moment()
      .millisecond(0)
      .second(0)
      .minute(0)
      .hour(0);
    const yesterday = moment(midnight).subtract(1, 'days');

    if (m.diff(midnight) >= 0) return m.fromNow();
    else if (m.diff(yesterday) >= 0) return `Yesterday ${m.format('h:mm A')}`;
    return m.format('lll');
  }

  renderResolution() {
    const { resolution } = this.props.case;

    const labelClass =
      resolution === 'VIOLATION'
        ? 'label-danger'
        : resolution === 'NOT_VIOLATION'
        ? 'label-success'
        : 'label-warning';
    const labelText = getResolutionDisplay(this.props.case);
    return <span className={classnames('label', labelClass)}>{labelText}</span>;
  }

  renderCaseType() {
    const { case: c, useGroups } = this.props;
    if (c.category) {
      return (
        <li className="card__meta-item">
          <h5>Case Type</h5>
          <p className="card__meta-item__type">
            <CategoryComponent item={{ ...c.category, useGroups }} />
          </p>
        </li>
      );
    }
  }

  renderCaseDetails() {
    const timezone = DateHelpers.getCurrentAppUserTimezone();
    const { omitDetails, case: c } = this.props;

    return (
      <ul className={classnames({ omitDetails })}>
        <li className="card__meta-item card__action-date">
          <h5>Case Created</h5>
          <p>{this.formatDateWithTimezone(c.created, timezone)}</p>
        </li>
        <li className="card__meta-item card__action-date">
          <h5>Last Modified</h5>
          <p>{this.formatDateWithTimezone(c.lastModified, timezone)}</p>
        </li>
        {this.renderCaseType()}
        <SuspicionScore analyticAssessments={c.analyticAssessments} />
      </ul>
    );
  }

  renderCommentDetails() {
    const { comment } = this.props;

    return (
      <ul>
        <li className="card__meta-item card__comment">
          <h5>
            {comment.createdBy.fullName} Commented,{' '}
            {this.formatDate(comment.created)}
          </h5>
          <p>{comment.description}</p>
        </li>
      </ul>
    );
  }

  render() {
    const { case: c, comment, fadeIn, omitDetails } = this.props;
    const link = `/case/${c.id}`;
    const stripTZ = c?.category?.product === 'GRC';

    let owner = 'Unassigned';
    if (c.owner) {
      owner = (
        <div className="prot-a" onClick={this.handleOwnerSelect.bind(this)}>
          {(c.owner && c.owner.fullName) || ''}
        </div>
      );
    }

    const title = (
      <div>
        <span className="case-card-title">
          {CaseTemplate.title(c.title, c.minEventDate, c.maxEventDate, stripTZ)}
        </span>
        <CaseBundleIndicator caseId={c.id} />
      </div>
    );

    const subtitle = (
      <div>
        <strong>Case Number</strong>{' '}
        <span data-cy="case-card-header--case-number">{c.number}</span>
      </div>
    );

    return (
      <li>
        <Card className={classnames('case', { fadeIn, omitDetails })}>
          <CardHeader title={title} subtitle={subtitle} link={link}>
            <span className="small">{owner}</span>
            {this.renderResolution()}
          </CardHeader>
          {comment ? this.renderCommentDetails() : this.renderCaseDetails()}
        </Card>
      </li>
    );
  }
};

CaseLI.displayName = 'CaseLI';

export default CaseLI;
