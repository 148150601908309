import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
import styles from '../index.module.scss';

const LinkCell = ({ to }) => (
  <Cell className={styles.doubleLine}>
    <Link to={to}>{to}</Link>
  </Cell>
);

export default LinkCell;

LinkCell.propTypes = {
  to: PropTypes.string,
};

LinkCell.defaultProps = {
  to: '/',
};
