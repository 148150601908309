import React from 'react';
import { decorate, observable, reaction, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { DropdownList } from 'react-widgets';

import { AlertCategoryStore, GroupStore, CategoryComponent } from 'common';

import BulkCaseActionBase from '../BulkCaseActionBase';
import BulkCaseActionFormActions from '../BulkCaseActionFormActions';
import NotificationStore from '../../../stores/NotificationStore';

const ChangeCaseType = observer(
  class extends BulkCaseActionBase {
    constructor() {
      super();
      reaction(
        () => [this.hiddenCategory],
        () => {
          if (this.hiddenCategory) {
            const content = (
              <span>
                <i className="material-icons icon-warning" />
                By changing the Case Type you will lose visibility to this case
                based on your group settings or permissions.
              </span>
            );
            NotificationStore.add({ level: 'warning', content });
          }
        }
      );
    }

    // Observable
    hiddenCategory = false;

    /**
     * Set the case type to a new value. This is the onClick handler for
     * this form. Pulls the data from the form and calls bulk action method from
     * the associated store.
     * @param {Object} e the submit event
     * @return {undefined}
     */
    onSubmit = e => {
      e.preventDefault();
      const { store } = this.props;

      store.performBulkAction('changeType', { category: store.newType });
    };

    /**
     * Handle the type value change. This is the onChange callback handler
     * for the dropdown menu.
     * @param {String} val    - the new value being set
     * @return {undefined}
     */
    onChange = val => {
      const { store } = this.props;
      store.newType = val.id;
      this.hiddenCategory =
        val.group && !GroupStore.groups.find(g => g.id === val.group.id);
      super.onChange(val, true);
    };

    render() {
      const { store } = this.props;
      const { performingBulkAction } = store;

      let helpText =
        'This action will change the Case Type for all selected cases and cannot be undone.';
      if (this.hiddenCategory)
        helpText = `By changing the Case Type you will lose visibility to these cases based on your group settings or permissions. ${helpText}`;

      return (
        <form className="form" onSubmit={this.onSubmit}>
          <ul>
            <li>
              <DropdownList
                onChange={this.onChange}
                data={toJS(AlertCategoryStore.categories)}
                placeholder="Select a Case Type"
                textField="name"
                disabled={performingBulkAction}
                valueComponent={CategoryComponent}
                itemComponent={CategoryComponent}
              />
            </li>
            <BulkCaseActionFormActions
              store={store}
              buttonActionType="changeType"
              hidden={!this.dirty}
              helpText={helpText}
              isList
            />
          </ul>
        </form>
      );
    }
  }
);

decorate(ChangeCaseType, {
  hiddenCategory: observable,
});

export default ChangeCaseType;
