import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { MultilineInput } from '../../ui';
import { DropdownList } from 'react-widgets';
import { observer } from 'mobx-react';
import { computed, decorate, observable, toJS } from 'mobx';
import { HalUtils, EnumValueStore, LoginStore, RouterContainer } from 'common';
import formatBytes from '../../utils/formatBytes';

import CaseStore from '../stores/CaseStore';
import CaseAuthorizationsStore from '../stores/CaseAuthorizationsStore';
import CaseNoteTemplateStore from '../stores/CaseNoteTemplateStore';
import EmailTemplateStore from '../stores/EmailTemplateStore';
import NotificationStore from '../../stores/NotificationStore';
import TemporaryPermissionsSettingsStore from '../../stores/TemporaryPermissionsSettingsStore';

import Template from '../Template';
import DatePicker from '../../ui/DatePicker';
import Checkbox from '../../ui/Checkbox';
import { toLocalDateString } from '../../utils/LocalDateUtils';

import styles from './index.module.scss';
import { withFlags } from '../../ui/Flagged';
import {
  DIVERSION,
  FALSE_POSITIVE,
  GOOD_CATCH,
  POLICY,
  PRIVACY,
  resolutionDescriptionDisplayName,
} from '../../utils/resolutionDescriptions';

const ALL_ACTIONS = {
  action: 'Add Action',
  attach: 'Add Attachments',
  assessment: 'Set Final Assessment',
  note: 'Add note',
  resolve: 'Resolve Case',
  noteTemplate: 'Add Note From Template',
  emailTemplate: 'Send an Email from Template',
};

const CaseEventNew = observer(
  class extends React.Component {
    static propTypes = {
      flags: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    // Observable
    allowedTypes = null;
    attachedFiles = [];
    maxBytes = null;
    maxRequestBytes = null;
    checkboxValue = false;
    textareaValue = null;
    dropdownValue = null;
    dropdownValue2 = null;
    dateValue = moment();

    maxFiles = 20;

    componentWillUnmount() {
      this.reset();
    }

    onActionChange(selected) {
      CaseStore.currentAction = selected.id;
    }

    onTextareaChange(val) {
      this.textareaValue = val;
      // keep the user active if they're typing up some complex notes/assessments
      LoginStore.renewCheck();
    }

    onDropdownChange = selected => {
      this.dropdownValue = selected.id;
      // Reset the next dropdown if applicable.
      this.dropdownValue2 = null;
      // Reset the temporary permission checkbox if relevant.
      this.checkboxValue = false;

      // If resolving as false positive, set the dateValue to the default future
      // date for duration of temporary permission.
      // If configured via general settings, they'll be able to change this
      // date with the date picker.
      if (
        TemporaryPermissionsSettingsStore.enabled &&
        CaseStore.type === 'privacy' &&
        this.dropdownValue === 'NOT_VIOLATION_FALSE_POSITIVE'
      ) {
        this.dateValue = moment().add(
          TemporaryPermissionsSettingsStore.duration,
          'days'
        );
      }
    };

    onDropdown2Change = selected => {
      this.dropdownValue2 = selected.id;

      // If resolving as false positive, set the dateValue to the default future
      // date for duration of temporary permission.
      // If configured via general settings, they'll be able to change this
      // date with the date picker.
      if (
        TemporaryPermissionsSettingsStore.enabled &&
        CaseStore.type === 'privacy' &&
        this.dropdownValue2 === FALSE_POSITIVE
      ) {
        this.dateValue = moment().add(
          TemporaryPermissionsSettingsStore.duration,
          'days'
        );
      }
    };

    onDateChange = (date, valid) => {
      if (valid) this.dateValue = date;
    };

    onFileSelect() {
      this.fileInput.click();
    }

    onFileChange(e) {
      this.attachedFiles = this.attachedFiles
        .concat(Array.from(e.target.files))
        .slice(0, this.maxFiles);
      e.target.value = null;
    }

    onCancel(e) {
      e.preventDefault();
      this.reset();
    }

    onSave(e) {
      e.preventDefault();
      let promise = Promise.resolve();

      if (CaseStore.currentAction === 'note') {
        promise = CaseStore.addNote({ notes: this.textareaValue.trim() });
      } else if (CaseStore.currentAction === 'assessment') {
        promise = CaseStore.updateCase({
          assessment: this.textareaValue.trim(),
        });
      } else if (CaseStore.currentAction === 'action') {
        promise = CaseStore.addAction({
          type: this.dropdownValue,
          date: this.dateValue.toISOString(),
          notes: this.textareaValue,
        });
      } else if (CaseStore.currentAction === 'attach') {
        CaseStore.uploadingAttachment = true;
        const formData = new FormData();
        this.attachedFiles.forEach(file => formData.append('files', file));
        if (this.textareaValue) formData.append('notes', this.textareaValue);
        promise = CaseStore.addAttachments(formData);
      } else if (CaseStore.currentAction === 'resolve') {
        let params;

        params = {
          resolution: this.dropdownValue,
          resolutionDescription: this.dropdownValue2,
        };
        if (this.createTemporaryPermission) {
          params = {
            ...params,
            tempPermission: true,
            tempPermissionEnd: toLocalDateString(this.dateValue),
            tempPermissionReason: this.textareaValue,
          };
        }

        promise = CaseStore.resolve(params);
      }

      promise
        .then(() => {
          if (this.createTemporaryPermission) {
            CaseAuthorizationsStore.refresh().then(() => {
              if (CaseAuthorizationsStore.permissions.length) {
                const content = (
                  <a
                    href={RouterContainer.href(
                      `/authorizations/${CaseAuthorizationsStore.permissions[0].id}`
                    )}
                  >
                    <i className="material-icons icon-check_circle" />
                    Successfully created temporary permission
                  </a>
                );

                NotificationStore.add({ level: 'success', content });
              }
            });
          }
          this.reset();
        })
        .catch(xhr => {
          CaseStore.uploadingAttachment = false;
          if (
            xhr.responseJSON &&
            xhr.responseJSON.message &&
            xhr.responseJSON.message.indexOf('maximum permitted size') !== -1
          ) {
            // hit a file size limit
            const size = xhr.responseJSON.message.match(/\d+/g);
            if (size) this.maxBytes = size[0];
          } else if (
            xhr.responseJSON &&
            xhr.responseJSON.message &&
            xhr.responseJSON.message.indexOf(
              'request was rejected because its size'
            ) !== -1
          ) {
            // hit a request size limit
            const size = xhr.responseJSON.message.match(/\d+/g);
            if (size && size.length === 2) this.maxRequestBytes = size[1];
          } else if (
            xhr.responseText &&
            xhr.responseText.indexOf('Unsupported content type') !== -1
          ) {
            // uploaded an unsupported file type
            const types = xhr.responseText.match(/\w+\/\w+/g);
            if (types) this.allowedTypes = types;
          } else {
            const content = (
              <span>
                <i className="material-icons icon-warning" />
                There was a problem updating this case
              </span>
            );
            NotificationStore.add({ level: 'warning', content });
          }
        });
    }

    // Computed
    get canSave() {
      if (
        CaseStore.currentAction === 'note' ||
        CaseStore.currentAction === 'assessment'
      )
        return Boolean((this.textareaValue || '').trim());
      if (CaseStore.currentAction === 'action')
        return Boolean(this.dropdownValue);
      if (CaseStore.currentAction === 'resolve') {
        return Boolean(this.dropdownValue && this.dropdownValue2);
      }
      if (
        CaseStore.currentAction === 'attach' &&
        !CaseStore.uploadingAttachment
      )
        return Boolean(this.attachedFiles && this.attachedFiles.length);

      return false;
    }

    // Computed
    get caseActions() {
      return EnumValueStore.caseActions
        .filter(r => !r.deprecated)
        .map(r => {
          const id = HalUtils.getId(r);
          return {
            id,
            name: r.name,
          };
        });
    }

    // Computed
    get temporaryPermissionAvailable() {
      return (
        TemporaryPermissionsSettingsStore.enabled &&
        TemporaryPermissionsSettingsStore.creationPrompt &&
        CaseStore.type === 'privacy' &&
        (this.dropdownValue === 'NOT_VIOLATION_FALSE_POSITIVE' ||
          (this.dropdownValue === 'NOT_VIOLATION' &&
            this.dropdownValue2 === FALSE_POSITIVE))
      );
    }

    // Computed
    get createTemporaryPermission() {
      return (
        CaseStore.type === 'privacy' &&
        CaseStore.currentAction === 'resolve' &&
        (this.dropdownValue === 'NOT_VIOLATION_FALSE_POSITIVE' ||
          (this.dropdownValue === 'NOT_VIOLATION' &&
            this.dropdownValue2 === FALSE_POSITIVE)) &&
        (this.checkboxValue ||
          (TemporaryPermissionsSettingsStore.enabled &&
            !TemporaryPermissionsSettingsStore.creationPrompt))
      );
    }

    removeFile(index) {
      this.attachedFiles.splice(index, 1);
    }

    reset() {
      CaseStore.currentAction = null;
      CaseStore.uploadingAttachment = null;
      CaseNoteTemplateStore.selected = null;
      CaseStore.setNewResolution(null);
      this.textareaValue = null;
      this.dropdownValue = null;
      this.attachedFiles = [];
      this.allowedTypes = null;
      this.maxBytes = null;
      this.maxRequestBytes = null;
      this.dateValue = moment();
    }

    renderHeader() {
      if (CaseStore.currentAction) {
        return (
          <div className="case-event__header">
            <strong>{ALL_ACTIONS[CaseStore.currentAction]}</strong>
            <div className="actions">{this.renderActions()}</div>
          </div>
        );
      }

      const availableActions = [];

      if (CaseStore.canModifyActions)
        availableActions.push({ id: 'action', name: ALL_ACTIONS.action });
      if (CaseStore.canModifyAttachments)
        availableActions.push({ id: 'attach', name: ALL_ACTIONS.attach });
      if (CaseStore.canModifyNotes)
        availableActions.push({ id: 'note', name: ALL_ACTIONS.note });
      if (CaseStore.canModifyNotes)
        availableActions.push({
          id: 'noteTemplate',
          name: ALL_ACTIONS.noteTemplate,
        });
      if (CaseStore.canResolve)
        availableActions.push({ id: 'resolve', name: ALL_ACTIONS.resolve });
      if (EmailTemplateStore.canSendEmail)
        availableActions.push({
          id: 'emailTemplate',
          name: ALL_ACTIONS.emailTemplate,
        });
      if (CaseStore.canModifyAssessment)
        availableActions.push({
          id: 'assessment',
          name: ALL_ACTIONS.assessment,
        });

      return (
        <div
          className="case-event__header dropdown-header"
          data-cy="add-to-case-dropdown"
        >
          <DropdownList
            data={availableActions}
            onChange={this.onActionChange.bind(this)}
            placeholder="Add to Case"
            textField="name"
            value={CaseStore.currentAction}
            valueField="id"
            disabled={CaseStore.case.assessment ? ['assessment'] : false}
          />
        </div>
      );
    }

    renderActions() {
      const actions = [];

      if (
        CaseStore.currentAction === 'attach' &&
        CaseStore.uploadingAttachment
      ) {
        actions.push(
          <span key="saving" className="text-subtle">
            Saving...
          </span>
        );
      } else {
        actions.push(
          <span
            key="cancel"
            onClick={this.onCancel.bind(this)}
            className="text-danger prot-a"
          >
            Cancel
          </span>
        );
      }

      if (this.canSave) {
        let saveText = 'Save';
        if (this.createTemporaryPermission)
          saveText += ' & Create Temporary Permission';
        actions.push(
          <span
            key="save"
            className="prot-a"
            role="button"
            onClick={this.onSave.bind(this)}
          >
            {saveText}
          </span>
        );
      }

      return actions;
    }

    renderContent() {
      if (CaseStore.currentAction) {
        let placeholder = 'Type to add a note';
        if (CaseStore.currentAction === 'assessment')
          placeholder = 'Type to add an assessment';
        if (
          CaseStore.currentAction === 'action' ||
          CaseStore.currentAction === 'resolve' ||
          CaseStore.currentAction === 'attach'
        )
          placeholder += ' (optional)';

        let formChildren = [
          <MultilineInput
            autoFocus
            key="textarea"
            borderRadius
            darkBackground
            onChange={val => this.onTextareaChange(val)}
            placeholder={placeholder}
            rows={5}
            value={this.textareaValue}
          />,
        ];

        if (CaseStore.currentAction === 'action') {
          formChildren.unshift(
            <DropdownList
              key="dropdown"
              className="float-left"
              data={toJS(this.caseActions)}
              onChange={this.onDropdownChange.bind(this)}
              placeholder="Select an Action"
              textField="name"
              value={this.dropdownValue}
              valueField="id"
            />,
            <DatePicker
              key="date"
              onChange={this.onDateChange}
              value={this.dateValue}
              className={styles.actionDatePicker}
            />
          );
        } else if (CaseStore.currentAction === 'attach') {
          if (this.attachedFiles && this.attachedFiles.length) {
            const files = [];

            this.attachedFiles.forEach((file, i) => {
              const invalid =
                (this.maxBytes && this.maxBytes < file.size) ||
                (this.allowedTypes && !this.allowedTypes.includes(file.type));
              files.push(
                <li key={file.name} className={classnames({ invalid })}>
                  {file.name}
                  {!CaseStore.uploadingAttachment && (
                    <span
                      className="prot-a"
                      onClick={this.removeFile.bind(this, i)}
                    >
                      remove
                    </span>
                  )}
                </li>
              );
            });

            formChildren.unshift(
              <ul key="fileListing" className="case-event__file-listing">
                {files}
              </ul>
            );
          }

          if (
            this.maxBytes &&
            this.attachedFiles.some(file => this.maxBytes < file.size)
          ) {
            formChildren.unshift(
              <span key="file-warning" className="text-danger align-super">
                {`Sorry, one of your files exceeds file size limitations (${formatBytes(
                  this.maxBytes
                )})`}
              </span>
            );
          } else if (
            this.maxRequestBytes &&
            this.maxRequestBytes <
              this.attachedFiles.map(file => file.size).reduce((a, b) => a + b)
          ) {
            formChildren.unshift(
              <span key="file-warning" className="text-danger align-super">
                {`Sorry, the total file size exceeds size limitations (${formatBytes(
                  this.maxRequestBytes
                )})`}
              </span>
            );
          } else if (
            this.allowedTypes &&
            this.attachedFiles.some(
              file => !this.allowedTypes.includes(file.type)
            )
          ) {
            formChildren.unshift(
              <span key="file-warning" className="text-danger align-super">
                Sorry, you&apos;ve tried to upload a restricted file type
              </span>
            );
          }

          formChildren.unshift(
            <input
              type="file"
              className="hidden"
              key="fileInput"
              ref={c => {
                this.fileInput = c;
              }}
              onChange={this.onFileChange.bind(this)}
              multiple
            />
          );

          if (this.attachedFiles.length < this.maxFiles) {
            formChildren.unshift(
              <button
                disabled={CaseStore.uploadingAttachment}
                type="button"
                key="fileSelect"
                onClick={this.onFileSelect.bind(this)}
                className="select-files"
              >
                Select Files
              </button>
            );
          } else {
            formChildren.unshift(
              <span key="file-warning" className="text-danger align-super">
                The total number of files is limited to {this.maxFiles}
              </span>
            );
          }
        } else if (CaseStore.currentAction === 'resolve') {
          const resolutions = [
            {
              id: 'NOT_VIOLATION',
              name: 'Not a Violation',
            },
            { id: 'VIOLATION', name: 'Violation' },
          ];

          const resolutionDescriptions =
            this.dropdownValue === 'NOT_VIOLATION'
              ? [
                  {
                    id: GOOD_CATCH,
                    name: resolutionDescriptionDisplayName(GOOD_CATCH),
                  },
                  {
                    id: FALSE_POSITIVE,
                    name: resolutionDescriptionDisplayName(FALSE_POSITIVE),
                  },
                ]
              : CaseStore.type === 'privacy'
              ? [
                  {
                    id: POLICY,
                    name: resolutionDescriptionDisplayName(POLICY),
                  },
                  {
                    id: PRIVACY,
                    name: resolutionDescriptionDisplayName(PRIVACY),
                  },
                ]
              : CaseStore.type === 'diversion'
              ? [
                  {
                    id: POLICY,
                    name: resolutionDescriptionDisplayName(POLICY),
                  },
                  {
                    id: DIVERSION,
                    name: resolutionDescriptionDisplayName(DIVERSION),
                  },
                ]
              : [
                  {
                    id: POLICY,
                    name: resolutionDescriptionDisplayName(POLICY),
                  },
                  {
                    id: PRIVACY,
                    name: resolutionDescriptionDisplayName(PRIVACY),
                  },
                ];

          formChildren = [
            <DropdownList
              key="dropdown"
              className="float-left"
              data={resolutions}
              onChange={this.onDropdownChange}
              placeholder="Select a Resolution"
              textField="name"
              value={this.dropdownValue}
              valueField="id"
            />,
            this.dropdownValue ? (
              <DropdownList
                key="dropdown2"
                className="float-left"
                data={resolutionDescriptions}
                onChange={this.onDropdown2Change}
                placeholder="Select a Resolution Description"
                textField="name"
                value={this.dropdownValue2}
                valueField="id"
              />
            ) : null,
          ];

          if (this.temporaryPermissionAvailable) {
            formChildren.push(
              <div className="case-event__permission-check" key="checkbox">
                <Checkbox
                  checked={this.checkboxValue}
                  onChange={() => (this.checkboxValue = !this.checkboxValue)}
                  color={'secondary'}
                  label={'Create Temporary Permission'}
                />
              </div>
            );
          }
          formChildren.push(<div className="clearfix" key="clear" />);

          if (this.checkboxValue) {
            formChildren.push(
              <DatePicker
                key="date"
                className="labeled-date-picker"
                label="End Date"
                onChange={this.onDateChange}
                value={this.dateValue}
                minDate={CaseStore.case.created}
              />,
              <MultilineInput
                autoFocus
                key="textarea"
                borderRadius
                darkBackground
                onChange={val => this.onTextareaChange(val)}
                placeholder="Temporary permission reason (optional)"
                rows={5}
                value={this.textareaValue}
              />
            );
          }
        }

        if (CaseStore.currentAction === 'noteTemplate')
          return <Template type="note" />;
        if (CaseStore.currentAction === 'emailTemplate')
          return <Template type="email" />;

        return <form>{formChildren}</form>;
      }

      return null;
    }

    render() {
      const content = this.renderContent();
      return (
        <div className={classnames('case-event', 'case-event--pending')}>
          {this.renderHeader()}
          {content && <div className="case-event__content">{content}</div>}
        </div>
      );
    }
  }
);

decorate(CaseEventNew, {
  allowedTypes: observable,
  attachedFiles: observable,
  checkboxValue: observable,
  dateValue: observable,
  dropdownValue: observable,
  dropdownValue2: observable,
  maxBytes: observable,
  maxRequestBytes: observable,
  textareaValue: observable,
  canSave: computed,
  caseActions: computed,
  createTemporaryPermission: computed,
  temporaryPermissionAvailable: computed,
});

CaseEventNew.displayName = 'CaseEventNew';

export default withFlags(CaseEventNew);
