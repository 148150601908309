import React from 'react';
import { autorun, computed, decorate, observable } from 'mobx';

import {
  SingletonStore,
  UserClient,
  PatientClient,
  AuthorizationClient,
} from 'common';

import NotificationStore from '../../../stores/NotificationStore';

class UserStore extends SingletonStore {
  constructor(params) {
    super(params);

    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  id = null;

  fetch() {
    if (this.id) return UserClient.getById(this.id);
  }
}

decorate(UserStore, {
  id: observable,
});

const AuthUserStore = new UserStore();

class PatientStore extends SingletonStore {
  constructor(params) {
    super(params);

    autorun(() => {
      this.refresh();
    });
  }

  //observable
  id = null;

  fetch() {
    if (this.id) return PatientClient.getById(this.id);
  }
}

decorate(PatientStore, {
  id: observable,
});

const AuthPatientStore = new PatientStore();

class CreateAuthStore extends SingletonStore {
  constructor({ notificationStore }) {
    super();
    this.notificationStore = notificationStore;
  }

  //observable
  createAuthId = null;
  disabled = false;

  // Computed
  get user() {
    return AuthUserStore.result;
  }

  // Computed
  get patient() {
    return AuthPatientStore.result;
  }

  // Computed
  get isLoading() {
    return this.loading || AuthUserStore.loading || AuthPatientStore.loading;
  }

  setUser = id => {
    AuthUserStore.id = id;
  };

  removeUser = () => {
    this.setUser(null);
  };

  setPatient = id => {
    AuthPatientStore.id = id;
  };

  removePatient = () => {
    this.setPatient(null);
  };

  setParams(params) {
    if (params.patientId) {
      this.setPatient(params.patientId);
    }

    if (params.employeeId) {
      this.setUser(params.employeeId);
    }
  }

  reset() {
    AuthUserStore.id = null;
    AuthPatientStore.id = null;
    this.createAuthId = null;
    this.disabled = false;
  }

  create(params) {
    this.disabled = true;

    const newAuthorization = {
      user: this.user,
      patient: this.patient,
      startDate: params.startDate,
      endDate: params.endDate,
      reason: params.reason,
      type: params.type,
    };

    return AuthorizationClient.create(newAuthorization)
      .then(id => {
        const content = (
          <span>
            <i className="material-icons icon-check_circle" />
            Successfully created authorized access
          </span>
        );
        this.createAuthId = id;
        this.notificationStore.add({ level: 'success', content });
        this.reset();
      })
      .catch(() => {
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            There was a problem creating this authorized access
          </span>
        );
        this.notificationStore.add({ level: 'warning', content });
        this.disabled = false;
      });
  }
}

decorate(CreateAuthStore, {
  createAuthId: observable,
  disabled: observable,
  user: computed,
  patient: computed,
  isLoading: computed,
});

export default new CreateAuthStore({
  notificationStore: NotificationStore,
});
