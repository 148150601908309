import queryString from 'query-string';

/**
 *
 * A simple method to update query object
 *
 * @param query
 * @param location
 * @param selectedRow
 * @param showAsidePanel
 * @returns {{search: string, pathname: *}}
 */
export const updateQueryParams = ({
  query,
  location,
  selectedRow = '',
  showAsidePanel,
}) => {
  const { pathname } = location;

  const newParams = {
    ...query,
    showAsidePanel: showAsidePanel === undefined ? undefined : showAsidePanel,
  };

  if (selectedRow) {
    delete newParams.selectedRow;
    newParams.selectedRow = selectedRow;
  }

  return { pathname, search: queryString.stringify(newParams) };
};
