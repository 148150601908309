import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const MultiRecordSearch = ({
  value,
  setValue,
  placeholder = 'Type to filter',
}) => (
  <div className={styles.searchContainer}>
    <input
      className={styles.searchInput}
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={e => setValue(e.target.value)}
    />
    {value && (
      <button className={styles.searchButton} onClick={() => setValue('')}>
        <span className="material-icons">highlight_off</span>
      </button>
    )}
  </div>
);

MultiRecordSearch.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default MultiRecordSearch;
