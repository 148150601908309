import React from 'react';
import { observer } from 'mobx-react';
import PrintOptions from '../PrintOptions';
import PrintSettingsStore from '../../../stores/PrintSettingsStore';

export function mapProps({ PrintSettingsStore }) {
  return {
    update: PrintSettingsStore.updateSettings,
    toggleValue: PrintSettingsStore.toggleValue,
    printValues: PrintSettingsStore.printSettingsEditValues,
    isDirty: PrintSettingsStore.isDirty,
  };
}

const PrintOptionsContainer = observer(() => {
  return <PrintOptions {...mapProps({ PrintSettingsStore })} />;
});

PrintOptionsContainer.displayName = 'PrintOptionsContainer';

export default PrintOptionsContainer;
