import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { CaseReportsContext } from '../Context';
import ReportSettingsFilters from '../ReportSettingsFilters';

export function mapProps({
  history,
  location,
  query,
  caseReportsFilterSelectionsStore,
}) {
  return {
    history,
    location,
    query,
    queryForSelectionChange:
      caseReportsFilterSelectionsStore.queryForSelectionChange,
    casesByOptions: caseReportsFilterSelectionsStore.casesByOptions,
    casesBy: caseReportsFilterSelectionsStore.casesBy,
    pivotOn: caseReportsFilterSelectionsStore.pivotOn,
    pivotOnOptions: caseReportsFilterSelectionsStore.pivotOnOptions,
    reportSettingsDateRange:
      caseReportsFilterSelectionsStore.reportSettingsDateRange,
    reportSettingsDateAfter:
      caseReportsFilterSelectionsStore.reportSettingsDateAfter,
    reportSettingsDateBefore:
      caseReportsFilterSelectionsStore.reportSettingsDateBefore,
  };
}

const ReportSettingsFiltersContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseReportsContext,
    mapProps,
    PresentationalComponent: ReportSettingsFilters,
  })
);

ReportSettingsFiltersContainer.displayName = 'ReportSettingsFiltersContainer';

export default ReportSettingsFiltersContainer;
