import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';

import { Pandy, ProtectedRoute } from 'common';

import PrivacyStatementAside from '../PrivacyStatementAside';
import PrivacyStatementOverview from '../PrivacyStatementOverview';
import { ApplyBodyClassName, DocumentTitle, Loader } from '../../../ui';

const PrivacyStatementView = ({
  categories,
  response,
  crunchingNumbers,
  periods,
  failureStatus,
  setCurrentStatementId,
  currentStatementId,
  paramsId,
  failure,
  filterValue,
  setValue,
  routeToStatement,
  routeToReportSummary,
  onMount,
  groups,
  query,
}) => {
  useEffect(() => {
    if (paramsId !== currentStatementId) setCurrentStatementId(paramsId);
  }, [currentStatementId, paramsId, setCurrentStatementId]);

  useEffect(() => {
    onMount();
    // eslint-disable-next-line
  }, []);

  return (
    <section className="statements_view__body">
      <DocumentTitle text="Protenus Statements" />
      <ApplyBodyClassName className="statements_view" />
      <Loader
        loaded={Boolean(!crunchingNumbers || failure) && !!response}
        failure={failure}
        failureStatus={failureStatus}
        customResponses={{
          404: "We couldn't find a statement with that ID. Sorry!",
        }}
      >
        {!periods?.length ? (
          <div>
            <Pandy visible mood="sad">
              <div className="speechBubble">
                <span>No numbers to crunch, sorry!</span>
              </div>
              <p>Statements are not available, please check back next month.</p>
            </Pandy>
          </div>
        ) : (
          <div className="loader-parent">
            <div>
              <PrivacyStatementAside
                filterValue={filterValue}
                setValue={setValue}
                categories={categories}
                routeToStatement={routeToStatement}
                routeToReportSummary={routeToReportSummary}
                periods={periods}
                currentStatementId={currentStatementId}
                groups={groups}
                query={query}
              />
              <ProtectedRoute
                path="/reports/summary/:statementId?"
                component={PrivacyStatementOverview}
              />
            </div>
          </div>
        )}
      </Loader>
    </section>
  );
};

PrivacyStatementView.propTypes = {
  categories: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  paramsId: PropTypes.string,
  currentStatementId: PropTypes.string,
  setCurrentStatementId: PropTypes.func,
  failureStatus: PropTypes.bool,
  failure: PropTypes.bool,
  groups: PropTypes.array,
  crunchingNumbers: PropTypes.bool,
  periods: PropTypes.array,
  response: PropTypes.shape({}),
  filterValue: PropTypes.string,
  setValue: PropTypes.func,
  routeToStatement: PropTypes.func,
  routeToReportSummary: PropTypes.func,
  onMount: PropTypes.func.isRequired,
  query: PropTypes.object,
};

PrivacyStatementView.displayName = 'PrivacyStatementView';

export default PrivacyStatementView;
