import React from 'react';
import { nodeIsType } from '../../utils/ComponentUtils';
import Tab from './Tab';
import Actions from './Actions';
import styles from './index.module.scss';

const TabBar = ({ children }) => {
  if (!Array.isArray(children)) children = [children];
  const tabs = children.filter(node => nodeIsType(node, TabBar.Tab));
  const actions = children.find(node => nodeIsType(node, TabBar.Actions));
  return (
    <div className={styles.bar} data-cy="case-tabs">
      {tabs}
      {actions}
    </div>
  );
};

TabBar.propTypes = {
  children: function({ children }) {
    if (!Array.isArray(children)) children = [children];
    if (children.filter(node => nodeIsType(node, TabBar.Tab)).length < 1)
      return new Error('At least one tab item is required.');
  },
};

TabBar.defaultProps = {
  children: [],
};

export default TabBar;

TabBar.Tab = Tab;
TabBar.Actions = Actions;
