import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FullName } from 'common';
import { ExistingCasesBubble } from '../../../ui';
import Aliases from '../Aliases';
import styles from './index.module.scss';

const PersonHeader = ({
  aliases,
  currentView,
  person,
  linkToOnlyCase,
  personLink,
  personType,
  personId,
  numCases,
  caseIdIfOnlyOneCaseForPerson,
  findLink,
}) => {
  let aliasCount = aliases.length;
  return (
    <div className={styles.container}>
      <Aliases
        aliases={aliases}
        current={person}
        disabled={currentView === 'case'}
        findLink={alias => findLink(alias)}
        mode={personType === 'user' ? 'user' : 'patient'}
      />
      <div className={classNames('titleCard', styles.border)}>
        <div
          className={classNames('titleCard--id', {
            patient: personType === 'patient',
            employee: personType === 'user',
          })}
        >
          {personId && (
            <ExistingCasesBubble
              count={numCases}
              caseId={caseIdIfOnlyOneCaseForPerson}
              caseView={!linkToOnlyCase}
              personId={personId}
              personType={personType}
            />
          )}
          <i
            className={classNames('material-icons', {
              'icon-local_hospital': personType === 'user',
              'icon-folder_shared': personType === 'patient',
            })}
          />
        </div>
        <div className="personInformation" data-cy="person-info">
          <h2>
            <Link to={personLink}>
              <FullName person={person} />
            </Link>
          </h2>
          <h5>
            {aliasCount > 0 ? ++aliasCount : ''}
            {personType === 'user'
              ? ` EMR User Record`
              : ` Patient Medical Record`}
            {aliasCount > 0 ? 's' : ''}
          </h5>
        </div>
      </div>
    </div>
  );
};

PersonHeader.propTypes = {
  aliases: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  currentView: PropTypes.string,
  person: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    suffix: PropTypes.string,
  }),
  linkToOnlyCase: PropTypes.bool.isRequired,
  personLink: PropTypes.string.isRequired,
  personType: PropTypes.oneOf(['user', 'patient']).isRequired,
  personId: PropTypes.string,
  numCases: PropTypes.number,
  caseIdIfOnlyOneCaseForPerson: PropTypes.string,
  findLink: PropTypes.func,
};

PersonHeader.defaultProps = {
  aliases: [],
  currentView: '',
  personId: null,
  caseIdIfOnlyOneCaseForPerson: null,
};

export default PersonHeader;
