import React from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as mobxPropTypes } from 'mobx-react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { decorate, observable } from 'mobx';
import moment from 'moment';

import { MultilineInput, ExpandCollapseCard, TextFormatter } from '../../ui';
import Header from './Header';
import DiversionAssessment from '../assessment/DiversionAssessment';
import PrivacyAssessment from '../assessment/PrivacyAssessment';

class CaseEvent extends React.Component {
  static propTypes = {
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        callback: PropTypes.func.isRequired,
        enabled: PropTypes.bool.isRequired,
        name: PropTypes.string.isRequired,
      })
    ),
    author: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    ]),
    ccEmails: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    dateCreated: PropTypes.instanceOf(moment),
    dateLastModified: PropTypes.shape({
      _isAMomentObject: PropTypes.bool,
    }).isRequired,
    downloadAttachment: PropTypes.func,
    files: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        filename: PropTypes.string.isRequired,
      })
    ),
    fromEmail: PropTypes.string,
    lastModifiedBy: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    replyTo: PropTypes.string,
    subject: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    toEmails: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
    type: PropTypes.string,
    className: PropTypes.string,
    confirmDelete: PropTypes.bool,
    editing: PropTypes.bool,
    onChange: PropTypes.func,
    assessment: PropTypes.shape({
      minEventDate: PropTypes.string,
      maxEventDate: PropTypes.string,
    }),
    index: PropTypes.number,
    lastViewedByUser: PropTypes.string,
    linkTo: PropTypes.string,
    unreconciledDrugs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    incidentsByPeriod: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.array),
    assessmentsHeadlines: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    caseType: PropTypes.string,
    afterResolution: PropTypes.bool,
    caseId: PropTypes.string,
    userId: PropTypes.string,
    caseNumber: PropTypes.number,
    patientId: PropTypes.string,
    print: PropTypes.bool,
  };

  static defaultProps = {
    unreconciledDrugs: [],
    assessmentsHeadlines: [],
    author: {},
    ccEmails: undefined,
    content: '',
    downloadAttachment: undefined,
    files: [],
    fromEmail: '',
    lastModifiedBy: {},
    replyTo: undefined,
    subject: '',
    toEmails: [],
    type: 'default',
    actions: [],
    className: null,
    confirmDelete: false,
    editing: false,
    afterResolution: false,
    onChange: () => {},
    print: false,
  };

  // Observable
  textareaValue = null;

  renderFiles() {
    const { files } = this.props;
    return (
      <ul className={styles.fileListing}>
        {files.map(f => {
          const { downloadAttachment } = this.props;
          return (
            <li
              key={f.fileId}
              onClick={downloadAttachment.bind(this, f.fileId, f.filename)}
            >
              {f.filename}
            </li>
          );
        })}
      </ul>
    );
  }

  renderEmailSummary() {
    const { fromEmail, ccEmails, replyTo, subject, toEmails } = this.props;

    return (
      <div>
        <div>
          <strong>To:</strong> {toEmails.join(', ')}
        </div>
        <div>
          <strong>From:</strong> {fromEmail}
        </div>
        {replyTo ? (
          <div>
            <strong>Reply-To:</strong> {replyTo}
          </div>
        ) : null}
        {ccEmails ? (
          <div>
            <strong>CC:</strong> {ccEmails.join(', ')}
          </div>
        ) : null}
        <div>
          <strong>Subject:</strong> {subject}
        </div>
      </div>
    );
  }

  onTextareaChange(val, type) {
    const { onChange } = this.props;
    this.textareaValue = val;
    onChange(val, type);
  }

  renderContent() {
    const { content, type, editing, print } = this.props;

    if (type === 'attachment') {
      return (
        <div>
          {this.renderFiles()}
          <TextFormatter text={content} />
        </div>
      );
    }

    if ((type === 'draftNotes' || type === 'draftAssessment') && editing) {
      const value = this.textareaValue === null ? content : this.textareaValue;
      const placeholder = `Start typing to edit ${
        type === 'draftNotes' ? 'a note' : 'the assessment'
      }`;
      if (print) {
        return <TextFormatter text={content} />;
      }
      return (
        <MultilineInput
          autoFocus
          borderRadius
          darkBackground
          onChange={val => this.onTextareaChange(val, type)}
          placeholder={placeholder}
          value={value}
        />
      );
    }

    if (typeof content === 'string') {
      return <TextFormatter text={content} />;
    }

    return content;
  }

  render() {
    const {
      className,
      type,
      dateCreated,
      dateLastModified,
      actions,
      author,
      lastModifiedBy,
      title,
      content,
      confirmDelete,
      toEmails,
      files,
      assessment,
      index,
      lastViewedByUser,
      linkTo,
      unreconciledDrugs,
      incidentsByPeriod,
      assessmentsHeadlines,
      caseType,
      afterResolution,
      caseId,
      userId,
      caseNumber,
      patientId,
    } = this.props;

    // For email events, show a subtitle with the first recipient's email.
    // If there are 2+ recipients, show "and [n-1] more."
    let subtitle = null;
    if (type === 'email' && toEmails.length > 0) {
      subtitle = `to ${toEmails[0]}`;
      if (toEmails.length > 1) {
        subtitle += ` and ${toEmails.length - 1} more`;
      }
    }

    return (
      // add minevent maxevent date to assessments for filtering on screen assessments via time ranges
      <div
        data-cy="case-event-created"
        className={classnames(
          styles.caseEvent,
          styles[type],
          'case-event',
          {
            'case-event--after-resolution': afterResolution,
          },
          `case-event--${type} ${
            type === 'analyticalAssessment'
              ? `${moment(assessment.minEventDate).toISOString()},${moment(
                  assessment.maxEventDate
                ).toISOString()}`
              : ''
          }`,
          className
        )}
      >
        {type !== 'analyticalAssessment' && (
          <ExpandCollapseCard
            warning={confirmDelete}
            defaultCollapsed={type === 'email'}
          >
            <ExpandCollapseCard.Header>
              <Header
                title={title}
                subtitle={subtitle}
                dateCreated={dateCreated}
                dateLastModified={dateLastModified}
                author={author}
                lastModifiedBy={lastModifiedBy}
                actions={actions}
                confirmDelete={confirmDelete}
              />
            </ExpandCollapseCard.Header>
            {type === 'email' && (
              <ExpandCollapseCard.Summary>
                {this.renderEmailSummary()}
              </ExpandCollapseCard.Summary>
            )}

            {type !== 'email' && (content || files.length) && (
              <ExpandCollapseCard.Summary>
                <div className="case-event__content">
                  {this.renderContent()}
                </div>
              </ExpandCollapseCard.Summary>
            )}

            {type === 'email' && (
              <ExpandCollapseCard.Detail>
                <div>
                  <TextFormatter text={content} />
                </div>
              </ExpandCollapseCard.Detail>
            )}
          </ExpandCollapseCard>
        )}
        {type === 'analyticalAssessment' && caseType === 'diversion' && (
          <DiversionAssessment
            type={caseType}
            analyticAssessment={Object.assign(assessment, {
              dateAdded: dateCreated.toISOString(),
            })}
            assessmentsHeadlines={assessmentsHeadlines[index]}
            author={author}
            lastViewedByUser={lastViewedByUser}
            linkTo={linkTo}
            unreconciledDrugs={unreconciledDrugs[index]}
            incidentsByPeriod={incidentsByPeriod[index]}
          />
        )}
        {type === 'analyticalAssessment' && caseType === 'privacy' && (
          <PrivacyAssessment
            type={caseType}
            analyticAssessment={Object.assign(assessment, {
              dateAdded: dateCreated.toISOString(),
            })}
            author={author}
            headline={assessmentsHeadlines[index]}
            caseId={caseId}
            linkTo={linkTo}
            userId={userId}
            caseNumber={caseNumber}
            patientId={patientId}
          />
        )}
      </div>
    );
  }
}

decorate(CaseEvent, {
  textareaValue: observable,
});

export default observer(CaseEvent);
