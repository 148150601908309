// These are the 5 epic types we see in almost all cases. We show the
// corresponding icon and the type text as the tooltip.
// If we have an epic access with a different type, we show no icon.
//
const EPIC_LOWERCASE_TYPE_TO_ICON = {
  cancel: 'icon-cancel',
  export: 'icon-get_app',
  modify: 'icon-edit',
  system: 'icon-computer',
  view: 'icon-visibility',
};

function epicTypeToIcon(type = '') {
  return EPIC_LOWERCASE_TYPE_TO_ICON[type.toLowerCase()];
}

/**
 * Helper to compute icon class and tooltip string for a particular access.
 *
 * @param dataClass
 * @param source
 * @param type
 * @param search
 * @param advancedSearch
 * @returns {{icon: string | null, tooltipContent: string | null}}
 */
export function getIconAndTooltipContent({
  dataClass,
  source = '',
  type,
  search,
  advancedSearch,
}) {
  let icon = null;
  let tooltipContent = null;
  if (dataClass === 'administration') {
    icon = 'icon-local_pharmacy';
    tooltipContent = 'Medication Administration';
  } else if (dataClass === 'discrepancy') {
    icon = 'icon-delete_sweep';
    tooltipContent = 'Medication Discrepancy';
  } else if (dataClass === 'handling') {
    icon = 'icon-pan_tool';
    tooltipContent = 'Medication Handling';
  } else if (dataClass === 'order') {
    icon = 'icon-receipt';
    tooltipContent = 'Medication Order';
  } else if (dataClass === 'timeEntry') {
    icon = 'icon-schedule';
    tooltipContent = 'Time Entry';
  } else if (search || advancedSearch) {
    icon = 'icon-search';
    tooltipContent = search
      ? `Search Text: ${search}`
      : `Search for ${Object.keys(advancedSearch).join('/')}`;
  } else if (
    dataClass === 'access' &&
    source.startsWith('Epic') &&
    type &&
    epicTypeToIcon(type)
  ) {
    icon = epicTypeToIcon(type);
    tooltipContent = type;
  }

  return { icon, tooltipContent };
}
