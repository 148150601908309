import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { PermissionStore } from 'common';
import $ from 'jquery';

import CaseNoteTemplatesStore from '../../../case/stores/CaseNoteTemplatesStore';
import CaseEmailTemplatesStore from '../../../case/stores/EmailTemplatesStore';
import EmailTemplateStore from '../../../case/stores/EmailTemplateStore';

const TemplateSettingsAside = observer(
  class TemplateSettingsAside extends React.Component {
    static propTypes = {
      activeTemplate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
      activeEdit: PropTypes.bool,
      templates: PropTypes.string,
      createFunc: PropTypes.func,
    };

    scroll(title, section) {
      let scrollAmount = $(`#${title}`).position().top + 60;

      if (section === 'Email') {
        scrollAmount += $('#templates__wrapper-Note').height() + 30;
      }

      $('.templateSettings_view__body').animate(
        {
          scrollTop: scrollAmount,
        },
        300
      );

      if (title === `CreateNew${section}Template`) {
        this.props.createFunc(section);
      }
    }

    mapTemplatesByType(templates, type) {
      if (templates.length) {
        return (
          <div data-cy={`${type}-templates-list`}>
            {templates.map((t, i) => {
              const inactiveBool =
                `${t.created}-${t.name}` !== this.props.activeTemplate &&
                this.props.activeEdit;
              const editBool =
                `${t.created}-${t.name}` === this.props.activeTemplate &&
                this.props.activeEdit;

              return (
                <span
                  key={`${t.name}-${i}`}
                  className={classnames('ellipsis', { editing: editBool })}
                >
                  {editBool ? <i className="material-icons icon-edit" /> : null}
                  <p
                    className={classnames('aside-item', {
                      inactive: inactiveBool,
                    })}
                    onClick={() =>
                      this.scroll(t.name && t.name.replace(/[\W_]+/g, ''), type)
                    }
                  >
                    {t.name}
                  </p>
                </span>
              );
            })}
          </div>
        );
      }
      return null;
    }

    renderEmailTemplates() {
      const templates = CaseEmailTemplatesStore.templates;
      const { activeTemplate, activeEdit } = this.props;
      const editBool = activeTemplate === 'Email' && activeEdit;

      if (EmailTemplateStore.canSendEmail) {
        return (
          <ul>
            <h2 onClick={() => this.scroll('EmailTemplate-header', 'Email')}>
              {' '}
              Email Templates{' '}
            </h2>
            <li>
              {PermissionStore.has('CASE_MODIFY_EMAIL_TEMPLATES') ? (
                <p
                  className={classnames('aside-item', {
                    inactive: 'Email' !== activeTemplate && activeEdit,
                    editing: editBool,
                  })}
                  data-cy="create-new-email-template"
                  onClick={() => this.scroll('CreateNewEmailTemplate', 'Email')}
                >
                  <i
                    className={`material-icons icon-${
                      editBool ? 'edit' : 'add_circle'
                    }`}
                  />{' '}
                  Create New Email Template{' '}
                </p>
              ) : null}
              {this.mapTemplatesByType(templates, 'Email')}
            </li>
          </ul>
        );
      }
      return null;
    }

    render() {
      const templates = CaseNoteTemplatesStore.templates;
      const { activeTemplate, activeEdit } = this.props;
      const editBool = activeTemplate === 'Note' && activeEdit;

      return (
        <aside className="dont-print">
          <h1> Template Settings </h1>
          <ul>
            <li>
              <ul>
                <h2 onClick={() => this.scroll('NoteTemplate-header', 'Note')}>
                  {' '}
                  Note Templates{' '}
                </h2>
                <li>
                  {PermissionStore.has('CASE_MODIFY_NOTE_TEMPLATES') ? (
                    <p
                      className={classnames('aside-item', {
                        inactive: `Note` !== activeTemplate && activeEdit,
                        editing: editBool,
                      })}
                      data-cy="create-new-note-template"
                      onClick={() =>
                        this.scroll('CreateNewNoteTemplate', 'Note')
                      }
                    >
                      <i
                        className={`material-icons icon-${
                          editBool ? 'edit' : 'add_circle'
                        }`}
                      />{' '}
                      Create New Note Template{' '}
                    </p>
                  ) : null}
                  {this.mapTemplatesByType(templates, 'Note')}
                </li>
              </ul>
            </li>
            <li>{this.renderEmailTemplates()}</li>
          </ul>
        </aside>
      );
    }
  }
);

export default TemplateSettingsAside;
