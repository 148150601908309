import moment from 'moment';
import React from 'react';
import Field from '../../../ui/Field';
import ViaSource from '../../../ui/ViaSource';
import PropTypes from 'prop-types';

const TerminationInfo = ({
  terminated,
  terminationDate,
  terminationSource,
}) => {
  if (terminationDate === null && terminated === null) return null;
  let status = null;
  let termDate = terminationDate ? moment(terminationDate).utc() : null;

  // termination date is so far into the future that we won't respect it
  if (termDate && termDate.diff(moment(), 'years') >= 10) termDate = null;

  if (termDate && termDate.isAfter(moment()))
    status = `Upcoming Termination on ${termDate.format('l')}`;
  else if (termDate && terminated)
    status = `Terminated on ${termDate.format('l')}`;
  else if (terminated) status = 'Terminated';

  if (status) {
    return (
      <Field label="Employment Status" width="auto">
        <p>{status}</p>
        {terminationSource && <ViaSource source={terminationSource} />}
      </Field>
    );
  }
  return null;
};

TerminationInfo.propTypes = {
  terminated: PropTypes.bool,
  terminationDate: PropTypes.string,
  terminationSource: PropTypes.string,
};

TerminationInfo.defaultProps = {
  terminated: null,
  terminationDate: null,
  terminationSource: null,
};

export default TerminationInfo;
