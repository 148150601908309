import React from 'react';
import { observer } from 'mobx-react';
import { RadarCaseIntegration } from '../RadarCaseIntegration';
import { CaseIntegrationStore } from '../../../stores/CaseIntegrationStore';

export function mapProps({ caseIntegrationStore }) {
  return {
    isEnabled: caseIntegrationStore.isEnabled,
    tokenDisplay: caseIntegrationStore.tokenDisplay,
    setEnabled: caseIntegrationStore.setEnabled,
    setToken: caseIntegrationStore.setToken,
    timezone: caseIntegrationStore.timezone,
    setTimezone: caseIntegrationStore.setTimezone,
    caseSyncSettings: caseIntegrationStore.activeCaseSyncSettings,
    setCaseSyncSettings: caseIntegrationStore.setRadarSyncSetting,
  };
}

export const RadarCaseIntegrationContainer = observer(() => {
  return (
    <RadarCaseIntegration
      {...mapProps({ caseIntegrationStore: CaseIntegrationStore })}
    />
  );
});

RadarCaseIntegrationContainer.displayName = 'RadarCaseIntegrationContainer';
