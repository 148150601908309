import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import styles from './index.module.scss';

const Header = ({ kase, count, date, medication, showNewIcon }) => {
  return (
    <div className={styles.header}>
      <div className={cn(styles.row, styles.medicationRow)}>
        <h3 className={styles.medication}>
          {medication}{' '}
          <small>
            {date && `created on ${moment(date).format('MM/DD/YYYY')}`}
          </small>
          {showNewIcon && (
            <i
              className={`material-icons icon-fiber_new ${styles.newIcon} forScreen`}
            />
          )}
        </h3>
        <div className={styles.incCaseInfo}>
          {kase && (
            <Link to={`/case/${kase.id}`} className={styles.caseLink}>
              <i className="material-icons icon-folder" />
              <span>Case {kase?.number}</span>
            </Link>
          )}
          <span className={styles.incidentCount}>{`${count} Incident${
            count > 1 ? 's' : ''
          }`}</span>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  count: PropTypes.number.isRequired,
  date: PropTypes.string,
  kase: PropTypes.shape({
    id: PropTypes.string,
    number: PropTypes.number,
  }),
  medication: PropTypes.any,
  showNewIcon: PropTypes.bool,
};

Header.defaultProps = {
  date: null,
  kase: null,
  medication: [],
};

Header.displayName = 'Header';

export default Header;
