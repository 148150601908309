import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import CaseEvent from '../../CaseEvent';
import CaseEventNew from '../../CaseEventNew';
import styles from './index.module.scss';
import { computeAssessmentAddedDate, computeAssessmentAddedBy } from './helper';
import PrintTitle from '../../../ui/PrintTitle';
import { DateHelpers } from 'common';
import { withFlags } from '../../../ui/Flagged';
import { getResolutionDisplay } from '../../../utils/resolutionDescriptions';
import { matchCaseflowPrintOptions } from '../../print/stores/CaseCustomPrintStore/helpers';
import { CASEFLOW_NONE } from '../../print/stores/CaseCustomPrintStore/consts';

export const Caseflow = ({
  /* case actions */
  canModifyActions,
  deletingAction,
  removeAction,
  /* case analytic assessments */
  analyticAssessments,
  analyticAssessmentLogs,
  assessmentsHeadlines,
  incidentsByPeriod,
  linkTo,
  lastViewedByUser,
  unreconciledDrugs,
  /* case attachments */
  canModifyAttachments,
  deleting,
  deletingAttachment,
  downloadAttachment,
  removeAttachment,
  /* case notes */
  canModifyNotes,
  deletingNote,
  draftNotes,
  editNote,
  editingNote,
  removeNote,
  /* case resolution */
  canReopen,
  reopen,
  reopeningCaseFromTimeline,
  resolveCreatedBy,
  /* final assessment */
  assessmentLog,
  canModifyAssessment,
  editingAssessment,
  deletingAssessment,
  draftAssessment,
  /* general case props */
  caseType,
  editable,
  kase: {
    createdBy,
    id: caseId,
    number: caseNumber,
    assessment,
    created: caseCreated,
    resolution,
    resolutionDescription,
    resolutionDate,
    notes,
    actions,
    attachments,
    emails,
  },
  patientId,
  userId,
  /* case event functions */
  reset,
  setValue,
  updateCase,
  /* RADAR sync event props */
  radarId,
  syncLogs,
  radarLink,
  /* component specific / print */
  print,
  caseflowFieldsToInclude,
  selectedCaseflowOption,
  /* feature flags */
  // eslint-disable-next-line no-unused-vars
  flags,
}) => {
  const timezone = DateHelpers.getCurrentAppUserTimezone();

  const getEventActions = (name, enabled, dangerText, fn) => {
    const key = Math.random();
    switch (name) {
      case 'Cancel':
        return {
          callback: e => {
            e.preventDefault();
            reset();
          },
          key,
          enabled: true,
          name: 'Cancel',
          dangerText,
        };
      default:
        return {
          callback: e => {
            e.preventDefault();
            fn();
          },
          key,
          enabled,
          name,
          dangerText,
        };
    }
  };

  /**
   * Used to check if CaseEvent was created after the case was resolved.
   */
  const isAfterResolution = createdMoment => {
    if (!createdMoment) return false;
    return createdMoment.isAfter(resolutionDate);
  };

  const getActions = () =>
    actions.map((action, i) => {
      const actions = [];
      const enabled = !!canModifyActions && !resolution;
      let confirmDelete;

      if (deletingAction === i) {
        confirmDelete = true;
        const fn = () => removeAction(i);
        actions.push(getEventActions('Confirm Delete', enabled, true, fn));
        actions.push(getEventActions('Cancel', true));
      } else {
        const fn = () => setValue(i, 'deletingAction');
        actions.push(getEventActions('Delete', enabled, false, fn));
      }

      return {
        actions,
        author: action.createdBy,
        content: action.notes || '',
        dateCreated: moment(action.created).tz(timezone),
        dateLastModified: moment(action.lastModified).tz(timezone),
        title: `${action.type.name}, ${moment(action.date).format('l')}`,
        confirmDelete,
        key: `action${action.created}${action.date}${action.notes}${i}`,
      };
    });

  const getAssessments = () =>
    analyticAssessments.map((assessment, i) => {
      const { minEventDate, maxEventDate } = assessment;
      const dateAdded = computeAssessmentAddedDate(
        caseCreated,
        minEventDate,
        maxEventDate,
        analyticAssessmentLogs
      );

      const addedBy = computeAssessmentAddedBy(
        minEventDate,
        maxEventDate,
        analyticAssessmentLogs
      );

      return {
        title: '',
        author: addedBy,
        dateCreated: dateAdded,
        dateLastModified: dateAdded,
        key: `assessment${minEventDate}`,
        type: 'analyticalAssessment',
        linkTo,
        lastViewedByUser,
        unreconciledDrugs,
        incidentsByPeriod,
        assessmentsHeadlines,
        assessment,
        index: i,
        caseType,
        caseId,
        userId,
        caseNumber,
        patientId,
      };
    });

  const getAttachments = () =>
    attachments.map((attachment, i) => {
      const { created, createdBy, notes, lastModified, files } = attachment;
      const actions = [];
      let confirmDelete;
      const enabled = !!canModifyAttachments && !resolution;

      if (deletingAttachment === i) {
        if (deleting) {
          actions.push(getEventActions('Deleting...', true, false, () => {}));
        } else {
          confirmDelete = true;
          const fn = () => removeAttachment(i);
          actions.push(getEventActions('Confirm Delete', enabled, true, fn));
          actions.push(getEventActions('Cancel', true));
        }
      } else {
        const fn = () => setValue(i, 'deletingAttachment');
        actions.push(getEventActions('Delete', enabled, false, fn));
      }

      const downloadFunc = (fileIndex, fileName) =>
        downloadAttachment(i, fileIndex, fileName);

      return {
        actions,
        author: createdBy,
        content: notes || '',
        dateCreated: moment(created).tz(timezone),
        dateLastModified: moment(lastModified).tz(timezone),
        downloadAttachment: downloadFunc,
        files: files,
        title: `File${files.length === 1 ? '' : 's'} Attached`,
        type: 'attachment',
        confirmDelete,
        key: `attachment${created}`,
      };
    });

  const getResolution = () => {
    const actions = [];
    let confirmDelete;
    let resolutionText = 'Violation';
    let type = 'violation';

    if (resolution !== 'VIOLATION') {
      type = 'not-violation';
    }

    resolutionText = getResolutionDisplay({
      resolution,
      resolutionDescription,
    });

    if (reopeningCaseFromTimeline) {
      confirmDelete = true;
      const fn = () => reopen();
      actions.push(getEventActions('Confirm Reopen', true, true, fn));
      actions.push(getEventActions('Cancel', true));
    } else {
      const fn = () => setValue(true, 'reopeningCaseFromTimeline');
      actions.push(getEventActions('Reopen Case', canReopen, false, fn));
    }

    return {
      actions,
      author: resolveCreatedBy,
      dateCreated: moment(resolutionDate).tz(timezone),
      dateLastModified: moment(resolutionDate).tz(timezone),
      type,
      title: `Case Resolved as ${resolutionText}`,
      confirmDelete,
      key: 'resolution',
    };
  };

  const getEmails = () =>
    emails.map(email => {
      return {
        author: email.createdBy,
        ccEmails: email.ccEmails,
        content: email.body,
        dateCreated: moment(email.created).tz(timezone),
        dateLastModified: moment(email.lastModified).tz(timezone),
        replyTo: email.replyTo,
        subject: email.subject,
        title: 'Email Sent',
        toEmails: email.toEmails,
        fromEmail: email.fromEmail,
        type: 'email',
        key: `email${email.created}`,
      };
    });

  const getNotes = () =>
    notes.map((note, i) => {
      const actions = [];
      let confirmDelete;
      let editing;
      const enabled = !!canModifyNotes && !resolution;

      if (editingNote === i) {
        editing = true;
        const canSave = !!draftNotes && draftNotes.trim() !== note.notes;
        const saveFn = () => editNote(i, { notes: draftNotes.trim() });
        actions.push(getEventActions('Save', canSave, false, saveFn));
        actions.push(getEventActions('Cancel', true, true));
      } else if (deletingNote === i) {
        confirmDelete = true;
        const deleteFn = () => removeNote(i);
        actions.push(
          getEventActions('Confirm Delete', enabled, true, deleteFn)
        );
        actions.push(getEventActions('Cancel', true));
      } else {
        const editFn = () => setValue(i, 'editingNote');
        actions.push(getEventActions('Edit', enabled, false, editFn));
        const deleteFn = () => setValue(i, 'deletingNote');
        actions.push(getEventActions('Delete', enabled, false, deleteFn));
      }

      return {
        actions,
        author: note.createdBy,
        confirmDelete,
        content: note.notes,
        dateCreated: moment(note.created).tz(timezone),
        dateLastModified: moment(note.lastModified).tz(timezone),
        editing,
        key: `notes${note.created}${note.notes}`,
        lastModifiedBy: note.lastModifiedBy,
        onChange: setValue,
        title: 'Note Added',
        type: 'draftNotes',
      };
    });

  const getFinalAssessment = () => {
    const actions = [];
    let confirmDelete;
    const enabled = !!canModifyAssessment && !resolution;
    let editing;

    if (editingAssessment) {
      editing = true;
      const canSave =
        !!draftAssessment && draftAssessment.trim() !== assessment;
      const fn = () => updateCase({ assessment: draftAssessment.trim() });
      actions.push(getEventActions('Save', canSave, false, fn));
      actions.push(getEventActions('Cancel', true, true));
    } else if (deletingAssessment) {
      confirmDelete = true;
      const fn = () => updateCase({ assessment: null });
      actions.push(getEventActions('Confirm Delete', enabled, true, fn));
      actions.push(getEventActions('Cancel', true));
    } else {
      const editFn = () => setValue(true, 'editingAssessment');
      actions.push(getEventActions('Edit', enabled, false, editFn));
      const deleteFn = () => setValue(true, 'deletingAssessment');
      actions.push(getEventActions('Delete', enabled, false, deleteFn));
    }

    return {
      actions,
      author: assessmentLog && assessmentLog.createdBy,
      content: assessment,
      dateCreated: assessmentLog.created
        ? moment(assessmentLog.created).tz(timezone)
        : null,
      dateLastModified: moment(assessmentLog.lastModified).tz(timezone),
      title: 'Final Assessment Set',
      type: 'draftAssessment',
      editing,
      confirmDelete,
      key: 'final-assessment',
      onChange: setValue,
    };
  };

  const getSyncLogs = () => {
    return syncLogs.map(log => {
      // this seems to be a boolean represented as a string
      const isInitialSync =
        log?.parameters?.initialSynchronization?.[0] === 'true';
      const productName =
        log?.parameters?.externalProductName?.[0] || 'Unknown';

      return {
        author: log.createdBy,
        dateCreated: moment(log.created).tz(timezone),
        dateLastModified: moment(log.lastModified).tz(timezone),
        title: (
          <>
            {'Incident '}
            {productName === 'Radar' && (
              <a href={radarLink} target="_blank" rel="noopener noreferrer">
                {radarId}
              </a>
            )}
            {isInitialSync
              ? ` Created in ${productName}`
              : ` Synced in ${productName}`}
          </>
        ),
        key: isInitialSync
          ? `${productName}-incident-created${log.created}`
          : `${productName}-sync${log.created}`,
      };
    });
  };

  let caseEvents = [];

  const caseCreatedDate = moment(caseCreated).tz(timezone);

  /* case creation */
  caseEvents.push({
    author: createdBy || { firstName: 'Protenus' },
    dateCreated: caseCreatedDate,
    dateLastModified: caseCreatedDate,
    title: 'Case Created',
    type: 'origin',
    key: 'case-created',
  });

  if (actions) {
    caseEvents.push(...getActions());
  }

  if (analyticAssessments.length) {
    caseEvents.push(...getAssessments());
  }

  if (attachments) {
    caseEvents.push(...getAttachments());
  }

  if (emails) {
    caseEvents.push(...getEmails());
  }

  if (notes) {
    caseEvents.push(...getNotes());
  }

  if (resolution) {
    caseEvents.push(getResolution());
  }

  if (assessment) {
    caseEvents.push(getFinalAssessment());
  }

  if (syncLogs?.length) {
    caseEvents.push(...getSyncLogs());
  }

  // Sort by event date
  caseEvents = caseEvents
    .sort((a, b) => moment(a.dateCreated).diff(b.dateCreated))
    .reverse()
    .map(e => ({ ...e, afterResolution: isAfterResolution(e.dateCreated) }));

  const getEventElements = () =>
    caseEvents.map(event => (
      <CaseEvent
        key={event.key}
        actions={event.actions}
        author={event.author}
        content={event.content}
        dateCreated={event.dateCreated}
        dateLastModified={event.dateLastModified}
        title={event.title}
        type={event.type}
        className={event.classes}
        confirmDelete={event.confirmDelete}
        editing={event.editing}
        linkTo={event.linkTo}
        lastViewedByUser={lastViewedByUser}
        onChange={event.onChange}
        print={print}
        externalLink={event.externalLink}
        linkText={event.linkText}
        {...event}
      />
    ));

  const eventElements = getEventElements()
    .slice()
    .filter(e =>
      matchCaseflowPrintOptions(
        e,
        selectedCaseflowOption,
        caseflowFieldsToInclude,
        getEventElements()
          .filter(e => e.key.substr(0, 10) === 'assessment')
          .map(r => r.props.assessment)
          .reverse()
      )
    )
    .map((event, i) => <React.Fragment key={i}>{event}</React.Fragment>);

  if (editable && !resolution)
    eventElements.unshift(<CaseEventNew key="new" />);

  const renderCaseflow = () => (
    <section className={'case-event-container ' + classnames(styles.container)}>
      {eventElements}
    </section>
  );

  return (
    <div>
      {selectedCaseflowOption !== CASEFLOW_NONE && (
        <PrintTitle>Caseflow</PrintTitle>
      )}
      {renderCaseflow()}
    </div>
  );
};

export default withFlags(Caseflow);

Caseflow.propTypes = {
  /* case actions */

  canModifyActions: PropTypes.bool,
  deletingAction: PropTypes.number,
  removeAction: PropTypes.func,
  /* case analytic assessments */
  analyticAssessments: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  analyticAssessmentLogs: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  assessmentsHeadlines: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
  incidentsByPeriod: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.array),
  linkTo: PropTypes.string,
  lastViewedByUser: PropTypes.string,
  unreconciledDrugs: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.arrayOf(PropTypes.string)
  ),
  /* case attachments */
  canModifyAttachments: PropTypes.bool,
  deleting: PropTypes.bool,
  deletingAttachment: PropTypes.number,
  downloadAttachment: PropTypes.func,
  removeAttachment: PropTypes.func,
  /* case notes */
  canModifyNotes: PropTypes.bool,
  deletingNote: PropTypes.number,
  draftNotes: PropTypes.string,
  editingNote: PropTypes.number,
  editNote: PropTypes.func,
  removeNote: PropTypes.func,
  /* final assessment */
  assessmentLog: PropTypes.shape({
    createdBy: PropTypes.shape({}),
    created: PropTypes.string,
    lastModified: PropTypes.string,
  }),
  canModifyAssessment: PropTypes.bool,
  deletingAssessment: PropTypes.bool,
  draftAssessment: PropTypes.string,
  editingAssessment: PropTypes.bool,
  /* case resolution */
  canReopen: PropTypes.bool,
  reopen: PropTypes.func,
  reopeningCaseFromTimeline: PropTypes.bool,
  resolveCreatedBy: PropTypes.shape({}),
  /* general case props */
  caseType: PropTypes.string,
  editable: PropTypes.bool,
  kase: PropTypes.shape({
    actions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    attachments: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    assessment: PropTypes.string,
    id: PropTypes.string,
    incidents: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    emails: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    notes: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    number: PropTypes.number,
    resolution: PropTypes.string,
    resolutionDescription: PropTypes.string,
    resolutionDate: PropTypes.string,
    lastModified: PropTypes.string,
    created: PropTypes.string,
    createdBy: PropTypes.shape({}),
    /* externalId only references the RADAR incident id at the moment*/
    externalId: PropTypes.string,
  }),
  userId: PropTypes.string,
  patientId: PropTypes.string,
  /* case event functions */
  reset: PropTypes.func,
  setValue: PropTypes.func,
  updateCase: PropTypes.func,
  /* RADAR sync event props */
  radarId: PropTypes.string,
  syncLogs: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  radarLink: PropTypes.string,
  print: PropTypes.bool,
  flags: PropTypes.arrayOf(PropTypes.string),
  caseflowFieldsToInclude: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  selectedCaseflowOption: PropTypes.string,
};

Caseflow.defaultProps = {
  /* case actions */
  canModifyActions: false,
  deletingAction: null,
  removeAction: () => {},
  /* case analytic assessments */
  analyticAssessments: [],
  assessmentsHeadlines: [],
  incidentsByPeriod: [],
  lastViewedByUser: '',
  linkTo: '',
  /* case attachments */
  canModifyAttachments: false,
  deleting: false,
  deletingAttachment: null,
  downloadAttachment: () => {},
  removeAttachment: () => {},
  /* case notes */
  canModifyNotes: false,
  deletingNote: null,
  draftNotes: null,
  editNote: () => {},
  editingNote: null,
  removeNote: () => {},
  /* case resolution */
  canReopen: false,
  reopen: () => {},
  reopeningCaseFromTimeline: false,
  resolveCreatedBy: undefined,
  resolutionDate: null,
  /* final assessment */
  assessmentLog: {},
  canModifyAssessment: false,
  editingAssessment: false,
  deletingAssessment: false,
  draftAssessment: null,
  /* general case props */
  caseType: '',
  editable: false,
  kase: {},
  patientId: '',
  userId: '',
  /* case event functions */
  reset: () => {},
  setValue: () => {},
  updateCase: () => {},
  /* RADAR sync event props */
  radarId: null,
  syncLogs: null,
  radarLink: null,
  /* component / print props */
  print: false,
  caseflowFieldsToInclude: [],
  selectedCaseflowOption: '',
  flags: [],
};
