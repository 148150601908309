import React from 'react';
import { observer } from 'mobx-react';
import CaseCustomPrint from '../CaseCustomPrint';
import CaseStore from '../../stores/CaseStore';
import CaseLogStore from '../../stores/CaseLogStore';

export function mapProps({ CaseStore, CaseLogStore }) {
  return {
    caseId: CaseStore.caseId,
    caseType: CaseStore.type,
    incidents: CaseStore.medicationIncidents,
    assessments: CaseStore.analyticAssessments,
    incidentRangeFilterOptions: CaseStore.incidentRangeFilterOptions,
    incidentStatusFilterOptions: CaseStore.incidentStatusFilterOptions,
    incidentTypeFilterOptions: CaseStore.incidentTypeFilterOptions,
    refreshLogs: CaseLogStore.refresh,
  };
}

const CaseCustomPrintContainer = observer(() => (
  <CaseCustomPrint {...mapProps({ CaseStore, CaseLogStore })} />
));

export default CaseCustomPrintContainer;
