import { safeParse } from './json';
export var sessionSet = function sessionSet(key, value) {
  return sessionStorage.setItem(key, JSON.stringify(value));
};
export var makeSessionStorageForKey = function makeSessionStorageForKey(storageKey) {
  var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var cache = function cache() {
    return new Map(safeParse(sessionStorage.getItem(storageKey), defaultValue));
  };
  var set = function set(key, value) {
    var map = cache();
    if (key && value) map.set(key, value);
    sessionSet(storageKey, Array.from(map.entries()));
  };
  var timeSet = function timeSet(value) {
    var map = cache();
    var now = '' + Date.now();
    if (value) map.set(now, value);
    sessionSet(storageKey, Array.from(map.entries()));
    return now;
  };
  var get = function get(key) {
    var def = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var map = cache();
    return map.get(key) || def;
  };
  return {
    cache: cache,
    get: get,
    sessionSet: sessionSet,
    set: set,
    timeSet: timeSet
  };
};