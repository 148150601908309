import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CaseBundleClient } from 'common';
import { useEphemeralStore } from '../../utils/hooks';
import CaseBundleIndicatorStore from '../../stores/CaseBundleIndicatorStore';
import { Tooltip } from '../index';
import { Observer } from 'mobx-react';
import styles from './index.module.scss';

/**
 * Renders an icon indicator with a tooltip showing what bundles a case belongs
 * to.
 * @param caseId
 * @return {JSX.Element}
 * @constructor
 */
const CaseBundleIndicator = ({ caseId }) => {
  // Usually we'd have a *Root component create the ephemeral store,
  // and render a container component around a presentational one, but this
  // use case is small enough that it's all jammed together in one.
  const store = useEphemeralStore(
    () =>
      new CaseBundleIndicatorStore({
        caseId,
        caseBundleClient: CaseBundleClient,
      })
  );

  useEffect(() => {
    store.setCaseId(caseId);
  }, [caseId, store]);

  return (
    <Observer>
      {() =>
        store.bundles.length > 0 && (
          <Tooltip
            content={
              <span className={styles.tooltipContent}>
                {store.bundles
                  .map(b => b.name)
                  .sort()
                  .join(', ')}
              </span>
            }
          >
            <i className="material-icons icon-bundle" />
          </Tooltip>
        )
      }
    </Observer>
  );
};

CaseBundleIndicator.propTypes = {
  caseId: PropTypes.string.isRequired,
};

export default CaseBundleIndicator;
