import * as d3 from 'd3';

import { RouterContainer } from 'common';

import BrowserAdjustments from '../BrowserAdjustments';
import EventDetailedStore from '../../stores/EventDetailedStore';
import ChartStore from '../../stores/ChartStore';

/**
 * Layer responsible for drawing authorized access lines between a user and patient. A sub-component
 * of RowLayer.
 */
class AuthorizationLayer {
  render(selection) {
    const transition = ChartStore.scrollTransition,
      authRect = selection.selectAll('g.authorized-access').data(
        d => EventDetailedStore.authorizationsByYKey[d.key] || [],
        d => d.id
      );

    // new nodes
    this.update(authRect, transition);

    const rects = authRect
      .enter()
      .append('g')
      .classed('authorized-access', true);

    rects
      .append('rect')
      .attr('height', '2px')
      .on('click', d => {
        d3.event.stopPropagation();
        if (d.type === 'TEMPORARY_PERMISSION')
          RouterContainer.go(`/authorizations/temporary/${d.id}`);
        else RouterContainer.go(`/authorizations/${d.id}`);
      })
      .each(function(d) {
        const me = d3.select(this);
        const x0 = ChartStore.brushedXScale(d.startDate);
        const x1 = ChartStore.brushedXScale(d.endDate);
        const width = x1 - x0;
        const tipStart =
          d.type === 'TEMPORARY_PERMISSION'
            ? 'Temporary Permission'
            : 'Access Authorized';
        const dataTip = `${tipStart} ${d.displayStartDate} - ${d.displayEndDate}`;

        me.attr('width', width)
          .attr('data-tippy-content', dataTip)
          .attr('data-tippy-animation', 'fade')
          .attr('data-tippy-arrow', 'true')
          .attr('data-tippy-followCursor', 'true')
          .attr('data-tippy-placement', 'top');
      });

    this.update(rects, false);

    authRect.exit().remove();
  }

  update(selection, transition) {
    transition = transition !== false;

    if (transition) selection = selection.transition().duration(500);

    selection.attr('transform', d => {
      const date = new Date(d.startDate),
        x = ChartStore.brushedXScale(date) + (ChartStore.margin.left - 4),
        y = ChartStore.rowHeight / 2 + BrowserAdjustments.rowSpacing * 0.33,
        trans = `translate(${x},${y})`;
      return trans;
    });

    selection.select('rect').attr('width', d => {
      const start = new Date(d.startDate),
        end = new Date(d.endDate),
        x0 = ChartStore.brushedXScale(start),
        x1 = ChartStore.brushedXScale(end);

      return Math.max(0, x1 - x0);
    });
  }
}

export default new AuthorizationLayer();
