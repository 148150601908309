import React from 'react';
import PropTypes from 'prop-types';
import { pluralize } from 'common';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DoubleLineCell } from '../../../../ui';
import { compare } from '../../../../utils/compare';
import classnames from 'classnames';
import styles from '../index.module.scss';

const GroupsCell = ({ isActive = false, groups = [] }) => {
  const groupCountString = `${groups.length} ${pluralize(
    groups.length,
    'Group'
  )}`;

  const groupsString = groups
    .slice()
    .sort((a, b) => compare(a.name, b.name))
    .map(group => group.name)
    .join(', ');

  return (
    <DoubleLineCell
      className={classnames({ [styles.isActive]: isActive })}
      lineOne={groupCountString}
      lineTwo={groupsString}
    />
  );
};

export default GroupsCell;

GroupsCell.propTypes = {
  isActive: PropTypes.bool,
  groups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
};
