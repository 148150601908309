import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BaseCell from '../BaseCell';

const NameCell = observer(({ data, ...other }) => {
  return (
    <BaseCell data={data} {...other}>
      {data.name}
    </BaseCell>
  );
});

NameCell.propTypes = {
  data: PropTypes.shape({}),
};

NameCell.displayName = 'NameCell';

export default NameCell;
