export const sortOptions = ['time', 'asc', 'desc'];
export const sortMap = {
  dob: 'dateOfBirth',
  dod: 'dateOfDeath',
  id: 'userId',
  patientId: 'patientId',
  mrn: 'medicalRecordNumbers',
  name: 'lastName',
  organization: 'organization',
  sex: 'sex',
  suspicionScore: 'suspicionScore',
  title: 'title',
};
