import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DropdownList } from 'react-widgets';

const DropdownOption = ({ ...props }) => {
  const { prefix, label } = props.item;

  if (prefix) {
    return (
      <span>
        <strong>{prefix}:</strong>&nbsp;{label}
      </span>
    );
  } else {
    return <span>{label}</span>;
  }
};

/**
 * Creates a DropdownList component using a common configuration employed
 * throughout the product. This component can be either controlled or
 * uncontrolled, and leverages the same API as the DropdownList (all properties
 * not explicitly managed by this component are passed along to that component).
 *
 * The big advantage to using this component over going with a bare
 * DropdownMenu component is that you have much easier access to things like
 * valueComponent (already built in, and activated when there is a prefix
 * property on the component) as well as some other sane default settings
 * that match our implementations of DropdownMenu throughout the application.
 */
class SelectMenu extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    valueField: PropTypes.string,
    textField: PropTypes.string,
    exclude: PropTypes.array,
    onChange: PropTypes.func,
    valueComponent: PropTypes.func,
    defaultValue: PropTypes.string,
    prefix: PropTypes.string,
  };

  static defaultProps = {
    valueField: 'value',
    textField: 'label',
    exclude: [],
    valueComponent: DropdownOption,
  };

  get dropdownDefault() {
    const { defaultValue } = this.props;
    return this.options.find(opt => opt.value === defaultValue);
  }

  get options() {
    const { data, exclude, prefix, textField } = this.props;

    if (prefix) {
      data.forEach(d => {
        d.prefix = prefix;
        d.label = d[textField]; // the DropdownOption that supports this prefixing requires a `label` prop
      });
    }

    return data.filter(opt => exclude.indexOf(opt.value) === -1);
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      data,
      valueField,
      valueComponent,
      prefix,
      exclude,
      defaultValue,
      textField,
      onChange,
      ...other
    } = this.props;

    return (
      <DropdownList
        data={this.options /* gets manipulated slightly */}
        valueField={valueField}
        textField={textField}
        defaultValue={this.dropdownDefault}
        onChange={onChange}
        valueComponent={valueComponent}
        {...other}
      />
    );
  }
}

export default observer(SelectMenu);
