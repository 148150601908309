import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import {
  COMPARE_PATIENT_EMPLOYEE,
  COMPARE_EMPLOYEE_PATIENT,
  PERSON_TYPE_USER,
  PERSON_TYPE_PATIENT,
  COMPARE_EMPLOYEE_LEFT_COLUMN,
  COMPARE_EMPLOYEE_RIGHT_COLUMN,
  COMPARE_PATIENT_LEFT_COLUMN,
  COMPARE_PATIENT_RIGHT_COLUMN,
} from '../../stores/const';
import MultiRecordPersonHeaderSectionContainer from '../../MultiRecordPersonHeaderSectionContainer';
import { MultiRecordUserMetadataContainer } from '../../multiRecordPersonMetadata/MultiRecordUserMetadataContainer';
import { MultiRecordPatientMetadataContainer } from '../../multiRecordPersonMetadata/MultiRecordPatientMetadataContainer';
import { CompareViewContext } from '../../Context';
import { useEphemeralStore } from '../../../utils/hooks';
import { MultiRecordInformationDropdownStore } from '../../stores/MultiRecordInformationDropdownStore';
import { MultiRecordEntityColumnStore } from '../../stores/MultiRecordEntityColumnStore';
import MultiRecordViewClient from 'common/src/app/clients/MultiRecordViewClient';
import { VisibleSystemStore } from 'common';
import {
  CompareViewPatientDropdownContainer,
  CompareViewPatientDropdownContainerSecond,
} from '../../views/compare/CompareViewPatientDropdownContainer';
import {
  CompareViewPatientColumnContainer,
  CompareViewPatientColumnContainerSecond,
} from '../../views/compare/CompareViewPatientColumnContainer';
import {
  CompareViewEmployeeDropdownContainer,
  CompareViewEmployeeDropdownContainerSecond,
} from '../../views/compare/CompareViewEmployeeDropdownContainer';
import {
  CompareViewEmployeeColumnContainer,
  CompareViewEmployeeColumnContainerSecond,
} from '../../views/compare/CompareViewEmployeeColumnContainer';

const CompareView = ({ mode }) => {
  const employeeDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: COMPARE_EMPLOYEE_LEFT_COLUMN,
      })
  );
  const employeeDropdownStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: COMPARE_EMPLOYEE_RIGHT_COLUMN,
      })
  );
  const patientDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: COMPARE_PATIENT_LEFT_COLUMN,
      })
  );
  const patientDropdownStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: COMPARE_PATIENT_RIGHT_COLUMN,
      })
  );
  const employeeColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );
  const employeeColumnStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );
  const patientColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );
  const patientColumnStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );

  const patient = (
    <div className={styles.patientBox}>
      <MultiRecordPersonHeaderSectionContainer
        personType={PERSON_TYPE_PATIENT}
        forPage={'activity'}
      />
      <MultiRecordPatientMetadataContainer />
      <div className={styles.doubleColumn}>
        <div className={styles.column}>
          <CompareViewPatientDropdownContainer />
          <CompareViewPatientColumnContainer />
        </div>
        <div className={styles.column}>
          <CompareViewPatientDropdownContainerSecond />
          <CompareViewPatientColumnContainerSecond />
        </div>
      </div>
    </div>
  );

  const user = (
    <div className={styles.employeeBox}>
      <MultiRecordPersonHeaderSectionContainer
        personType={PERSON_TYPE_USER}
        forPage={'activity'}
      />
      <MultiRecordUserMetadataContainer />
      <div className={styles.doubleColumn}>
        <div className={styles.column}>
          <CompareViewEmployeeDropdownContainer />
          <CompareViewEmployeeColumnContainer />
        </div>
        <div className={styles.column}>
          <CompareViewEmployeeDropdownContainerSecond />
          <CompareViewEmployeeColumnContainerSecond />
        </div>
      </div>
    </div>
  );

  return (
    <CompareViewContext.Provider
      value={{
        employeeDropdownStore,
        patientDropdownStore,
        employeeColumnStore,
        patientColumnStore,
        employeeDropdownStoreSecond,
        patientDropdownStoreSecond,
        employeeColumnStoreSecond,
        patientColumnStoreSecond,
      }}
    >
      <div className={styles.doubleLayout}>
        {mode === COMPARE_PATIENT_EMPLOYEE && (
          <>
            {patient}
            {user}
          </>
        )}
        {mode === COMPARE_EMPLOYEE_PATIENT && (
          <>
            {user}
            {patient}
          </>
        )}
      </div>
    </CompareViewContext.Provider>
  );
};

CompareView.propTypes = {
  mode: PropTypes.string,
};

export default CompareView;
