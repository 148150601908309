import $ from 'jquery';
import BaseURL from '../../utils/BaseURL';
import { defaultErrorThrower } from '../../utils/defaultErrorThrower';

class MultiRecordViewClient {
  constructor() {
    this.url = BaseURL.service('multiRecordView');
  }

  getPersonHeaders(params) {
    return $.ajax({
      url: BaseURL.service('multiRecordView/getPersonHeaders', params),
      dataType: 'json',
      cache: false,
      error: defaultErrorThrower,
    });
  }

  getPersonMetadata(params) {
    return $.ajax({
      url: BaseURL.service('multiRecordView/getPersonMetadata', params),
      dataType: 'json',
      cache: false,
      error: defaultErrorThrower,
    });
  }

  getPersonalInformation(params) {
    return $.ajax({
      url: BaseURL.service('multiRecordView/getPersonalInformation', params),
      dataType: 'json',
      cache: false,
      error: defaultErrorThrower,
    });
  }

  getEmployeeInformation(params) {
    return $.ajax({
      url: BaseURL.service('multiRecordView/getEmployeeInformation', params),
      dataType: 'json',
      cache: false,
      error: defaultErrorThrower,
    });
  }

  getPatientInformation(params) {
    return $.ajax({
      url: BaseURL.service('multiRecordView/getPatientInformation', params),
      dataType: 'json',
      cache: false,
      error: defaultErrorThrower,
    });
  }
}

const me = new MultiRecordViewClient();
export { me as MultiRecordViewClient };
export default me;
