import React from 'react';
import { observer } from 'mobx-react';

import CaseResultsStore from '../stores/CaseResultsStore';
import AdvancedSort from '../AdvancedSort';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({ history, location, query, CaseResultsStore }) {
  return {
    history,
    location,
    query,
    sortAttr: CaseResultsStore.sortAttr,
    sortDir: CaseResultsStore.sortDir,
  };
}

const AdvancedSortContainer = withRouterAndQuery(
  observer(({ history, location, query }) => (
    <AdvancedSort
      {...mapProps({
        history,
        location,
        query,
        CaseResultsStore,
      })}
    />
  ))
);

AdvancedSortContainer.displayName = 'AdvancedSortContainer';

export default AdvancedSortContainer;
