import $ from 'jquery';

/**
 *
 * @param filterOpt
 * @param options
 * @returns {null|*}
 */
export function hasThisOption({ filterOpt, options = [] }) {
  if (!filterOpt) return null;
  return options.find(opt => opt.value === filterOpt) || null;
}

/**
 *
 * @param key
 * @param opt
 * @param queryCriteria
 * @param historyPush
 * @returns {*}
 */
export function handleOnChange({ key, opt, queryCriteria, historyPush }) {
  try {
    const newCriteria = { ...queryCriteria };
    const val = opt.value;

    delete newCriteria[key];
    if (val != null) newCriteria[key] = val;

    return historyPush({
      pathname: `/vips`,
      search: '?' + $.param(newCriteria),
    });
  } catch (e) {
    // Uncomment below to debug
    // return console.log(e)
  }
}
