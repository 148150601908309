import { PERSON_TYPE_USER, PERSON_TYPE_PATIENT } from '../stores/const.js';
import {
  CASE_ICON,
  USER_ICON,
  PATIENT_ICON,
  VIP_ICON,
  USER_TAG_ICON,
  AUTHORIZED_USER_ICON,
} from '../../ui/const.js';

// metadata type titles
export const EXISTING_CASE_METADATA_TITLE = 'Existing Case';
export const USER_RECORD_METADATA_TITLE = 'User Record';
export const MEDICAL_RECORD_METADATA_TITLE = 'Medical Record';
export const VIP_REGISTRATION_METADATA_TITLE = 'VIP Registration';
export const USER_TAG_METADATA_TITLE = 'User Tag';
export const AUTHORIZED_ACCESSES_METADATA_TITLE = 'Authorized Accesses';

// metadata types
export const metadataTypes = [
  {
    title: EXISTING_CASE_METADATA_TITLE,
    icon: CASE_ICON,
  },
  {
    title: USER_RECORD_METADATA_TITLE,
    icon: USER_ICON,
  },
  {
    title: MEDICAL_RECORD_METADATA_TITLE,
    icon: PATIENT_ICON,
  },
  {
    title: VIP_REGISTRATION_METADATA_TITLE,
    personType: PERSON_TYPE_PATIENT,
    icon: VIP_ICON,
  },
  {
    title: USER_TAG_METADATA_TITLE,
    personType: PERSON_TYPE_USER,
    icon: USER_TAG_ICON,
  },
  {
    title: AUTHORIZED_ACCESSES_METADATA_TITLE,
    icon: AUTHORIZED_USER_ICON,
  },
];

// vip types
export const EHR_VIP_TYPE = 'ehr';
export const NEWS_VIP_TYPE = 'news';
export const WIKI_VIP_TYPE = 'wiki';
