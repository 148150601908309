import { CaseClient, InfoCacheStore } from 'common';
import CaseStore from '../../case/stores/CaseStore';
import Store from '../../activity/stores/Store';
import { reaction } from 'mobx';

class PatientCasesStore extends InfoCacheStore {
  sort = 'lastModified,desc';

  constructor({ caseStore = {}, store = {} }) {
    super();

    this.caseStore = caseStore;
    this.store = store;

    reaction(
      () => [this.caseStore.patientId],
      ([patientId]) => {
        this.fetch(patientId);
      }
    );

    reaction(
      () => [this.store.patientIdParam],
      ([patientId]) => {
        this.fetch(patientId);
      }
    );
  }

  fetchData(id) {
    return CaseClient.findByPatient(id, this.sort);
  }
}

export default new PatientCasesStore({
  caseStore: CaseStore,
  store: Store,
});
