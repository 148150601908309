import React, { useState, useEffect, useCallback, useRef } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import ActionNav from '../../../navbar/ActionNav';
import { Column, Cell } from 'fixed-data-table-2';
import { Registry } from '../../../ui';
import CheckCell from '../../../ui/registry/cells/CheckCell';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';

const permissionsWidth = 360;
const columnWidth = 80;
const headerHeight = 150;
const tablePadding = 50;
const subNavHeight = 38;
const asidePanel = 450;
const CREATE_PATH = '/settings/roles/create';

const RolesListView = ({ allPermissions, roles, editRole, isOpen }) => {
  const [height, setHeight] = useState(1);
  const [width, setWidth] = useState(1);
  const rolesListTable = useRef(null);

  const setSize = useCallback(() => {
    const node = rolesListTable.current;
    setHeight(node.offsetParent.offsetHeight - headerHeight - subNavHeight);
    if (isOpen) {
      setWidth(node.offsetParent.offsetWidth - tablePadding - asidePanel);
    } else {
      setWidth(node.offsetParent.offsetWidth - tablePadding);
    }
  }, [isOpen]);

  useEffect(() => {
    setSize();
    window.addEventListener('resize', setSize);

    return () => {
      window.removeEventListener('resize', setSize);
    };
  }, [isOpen, setSize]);

  return (
    <section className={styles.tablePanel}>
      <ActionNav
        secondary
        count={roles.length}
        title="App User Role"
        createRole="admin"
        createPath={CREATE_PATH}
        createIcon="add_circle"
        createLabel="Create New User Role"
      />
      <div className={styles.tableArea} ref={rolesListTable}>
        <h2>App User Roles</h2>
        <Registry
          dataCount={allPermissions.length}
          headerHeight={headerHeight}
          height={height}
          scroll={true}
          scrollX={true}
          width={width}
        >
          <Column
            header={<Cell>Permissions</Cell>}
            width={permissionsWidth}
            cell={({ rowIndex }) => {
              const permission = allPermissions[rowIndex];
              return <Cell>{permission}</Cell>;
            }}
          />
          {roles.map(role => {
            return (
              <Column
                header={() => (
                  <Cell
                    className={classnames(styles.rotate, {
                      [styles.lock]: role.systemRole,
                    })}
                    onClick={() => editRole(role.name)}
                  >
                    <div className={styles.roleNames}>{role.name}</div>
                  </Cell>
                )}
                width={columnWidth}
                key={role.name}
                cell={({ rowIndex }) => {
                  const permission = allPermissions[rowIndex];
                  return (
                    <CheckCell
                      value={(role.permissions || []).includes(permission)}
                    />
                  );
                }}
              />
            );
          })}
        </Registry>
      </div>
    </section>
  );
};

export default RolesListView;

RolesListView.propTypes = {
  allPermissions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string)
    .isRequired,
  roles: PropTypes.array,
  editRole: PropTypes.func,
  isOpen: PropTypes.bool,
};
