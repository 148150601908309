import React from 'react';
import { observer } from 'mobx-react';
import CaseBundleStore from '../../stores/CaseBundleStore';
import Bundles from '../Bundles';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({ caseBundleStore, query }) {
  return {
    query,
    allBundles: caseBundleStore.allBundles,
    failure: caseBundleStore.failure,
    hasMore: caseBundleStore.hasMore,
    loading: caseBundleStore.loading,
    setQueryFromURL: caseBundleStore.setQueryFromURL,
  };
}

const BundlesContainer = withRouterAndQuery(
  observer(({ query }) => {
    return (
      <Bundles
        {...mapProps({
          caseBundleStore: CaseBundleStore,
          query,
        })}
      />
    );
  })
);

BundlesContainer.displayName = 'BundlesContainer';

export default BundlesContainer;
