import moment from 'moment';
import { action, computed, decorate, observable, reaction } from 'mobx';
import {
  DiversionActivityClient,
  getUnaccountedMap,
  DownloadUtils,
  IncidentDescriptionStore,
  PagedStore,
} from 'common';
import Store from '../Store';
import { updatedIncidentList } from '../../../utils/IncidentUtils';
import { getAssessmentsHeadlines } from '../../../utils/AssessmentUtils';
import { compare } from '../../../utils/compare';
import {
  INCIDENT_SUMMARY,
  INCIDENT_SUMMARY_AND_EVENT_DETAILS,
} from '../../../incident/stores/consts';
import { getIncidentExportURL } from '../../../utils/incidentExportHelper';

// The time in days for each statistic window, needed to pull back partial
// statistics and their incidents
const STATISTICS_WINDOW = 30;

/**
 * Given an array of assessments, produce an array of flattened incidents in
 * each assessment. assessment.incidents is actually an array of objects
 * representing incident groups with the same primary event.
 *
 * @param assessments {Object[]} Assessment objects.
 * @return {Object[][]} Array of incident arrays.
 */
function flattenedIncidentsForAssessments(assessments) {
  return (
    assessments
      // assessment.incidents is an array of incident groups. Each group has the
      // shape of an incident plus a linkedIncidents array property.
      // We want to pull out all items from the linkedIncidents.
      // [[{linkedIncidents}, {linkedIncidents}, ...], ...]
      .map(assessment => assessment.incidents)
      .map(arrayOfLinkedIncidents =>
        arrayOfLinkedIncidents.flatMap(({ linkedIncidents }) => linkedIncidents)
      )
  );
}

// Only used inside of DiversionActivityStore. Houses the diversion incidents.
class IncidentStore extends PagedStore {
  constructor(Store) {
    super();
    this.Store = Store;
  }

  fetch() {
    if (!this.Store.shouldViewDiversionActivity) return [];

    return DiversionActivityClient.getIncidentsByUsers(
      this.Store.activeUsers.split(','),
      this.Store.fromDate.clone().subtract(STATISTICS_WINDOW, 'days'),
      this.Store.toDate.clone().add(1 + STATISTICS_WINDOW, 'days')
    );
  }
}

// Only used inside of DiversionActivityStore. Houses the diversion statistics.
class StatisticStore extends PagedStore {
  constructor(Store) {
    super();
    this.Store = Store;
  }

  fetch() {
    if (!this.Store.shouldViewDiversionActivity) return [];

    return DiversionActivityClient.getStatisticsByUsers(
      this.Store.activeUsers.split(','),
      this.Store.fromDate.clone().subtract(STATISTICS_WINDOW, 'days'),
      this.Store.toDate.clone().add(1, 'days')
    );
  }
}

function coerceResultsToArray(results) {
  if (!results || !results.map) return [];
  return results;
}

/**
 * Encapsulates data representing the diversion activity visualization.
 * Refreshes whenever the user(s) or start/end dates in question update.
 *
 * Clears itself of data any time you are unable to view this information.
 */
class DiversionActivityStore {
  constructor({ Store, incidentDescriptionStore = {} }) {
    this.Store = Store;
    this.incidentStore = new IncidentStore(this.Store);
    this.statisticStore = new StatisticStore(this.Store);
    this.updatedIncidentList = updatedIncidentList;
    this.incidentDescriptionStore = incidentDescriptionStore;

    reaction(
      () => [
        Store.activeUsers,
        Store.fromDate,
        Store.toDate,
        Store.shouldViewDiversionActivity,
        this.incidentDescriptionStore.defsMap,
      ],
      () => {
        this.refresh();
        this.flagged = new Map();
      }
    );

    reaction(
      () => [Store.activePatients],
      () => {
        // moved to patient row, clear out flagged statistics
        this.flagged = new Map();
      }
    );

    reaction(
      () => [Store.activeUsers],
      () => {
        this.clearSourceStartDate();
      }
    );

    reaction(
      () => [Store.fromDate, Store.toDate],
      this.maybeResetselectedStatisticSourceStartDate
    );

    // Clear out the selected statistic period if the focus isn't on the incidents row and there is a selected statistic
    reaction(
      () => [Store.focus],
      () => {
        if (
          Store.focus !== 'employee_incidents' &&
          this.selectedStatisticSourceStartDate
        ) {
          this.clearSourceStartDate();
        }
      }
    );
  }

  // Observables
  scrollToStatistic = null;
  selectedStatisticSourceStartDate = null;
  flagged = new Map();

  // Computed
  /**
   * Formats the incidents objects to be condensed by the primaryEvent key to be used in the activity view for selection
   * adds a `linkedIncidents` to the object to list each event in the incident group
   * uses the `primaryEvent` value as the id for each grouping so the activity view selection logic works correctly
   * @returns {array of grouped incidents}
   */
  get collatedIncidentsByKey() {
    return Object.values(
      coerceResultsToArray(this.incidentStore.results).reduce(
        (collatedIncidents, incident) => {
          const { primaryEvent } = incident;

          // if the collated incidents already has the primary event add the current incident to the linked incidnets
          if (collatedIncidents[primaryEvent])
            collatedIncidents[primaryEvent].linkedIncidents.push(incident);
          else {
            // add a new bucket for the primary event
            collatedIncidents[primaryEvent] = {
              // spread the incident values so that we can use the incident values start/end times etc in the activity view
              ...incident,
              // Using the primaryEvent as the ID for the activity view selection logic
              id: primaryEvent,
              // make a list of the linked incidents that also belong to the same primary event starting with the current one
              linkedIncidents: [incident],
            };
          }
          return collatedIncidents;
        },
        {}
      )
    );
  }

  // Computed
  get statistics() {
    const checkedUserIds = [...this.flagged.values()].map(
      statistic => statistic.user.id
    );
    const statistics = coerceResultsToArray(this.statisticStore.results).map(
      s => {
        const key = `${s.user.source}_${s.startTime}`;
        return {
          ...s,
          checked: this.flagged.has(key),
          checkEnabled:
            checkedUserIds.length === 0 || checkedUserIds.includes(s.user.id),
          selected: s.startTime === this.selectedStatisticSourceStartDate,
          incidents: [],
        };
      }
    );

    const statisticIndexForDateBySource = (date, source) => {
      date = moment(date);
      for (let i = 0; i < statistics.length; i++) {
        const startMoment = moment(statistics[i].startTime);
        const endMoment = moment(statistics[i].endTime);
        const statSource = statistics[i].user && statistics[i].user.source;
        if (
          startMoment.isSameOrBefore(date) &&
          date.isSameOrBefore(endMoment) &&
          statSource === source
        ) {
          return i;
        }
      }
      return -1;
    };

    const incidents = this.collatedIncidentsByKey || [];
    if (!incidents.length) return statistics;

    // This structure will house any incidents we come across that do not fall
    // within an assessment. They will be grouped by source and will be
    // separated into two buckets per source - incidents before the known
    // assessments and incidents after the known assessments.
    const outOfBoundsContainers = {};
    // Note the minimum start time on known assessments so we can bucket the
    // out of bounds incidents as described above.
    const minAssessmentStartDate = statistics.map(s => s.startTime).sort()[0];

    const statisticsWithIncidents = incidents.reduce(
      (statistics, incidentGrouping) => {
        const { linkedIncidents } = incidentGrouping;
        const incident = linkedIncidents[0];
        // Find which bucket the incidentGrouping belongs to.
        const incidentDate = incident.startTime || incident.endTime;
        const source = incident.user?.source;
        const index = statisticIndexForDateBySource(incidentDate, source);

        if (index >= 0) statistics[index].incidents.push(incidentGrouping);
        else {
          // The incident group is not within the assessments date range.
          // Determine if it is still within the chart date range and
          // push it to the result and flag it with outOfBound value of true.
          const start = moment(incidentGrouping.startTime);
          const end = moment(incidentGrouping.endTime);
          if (
            start.isSameOrAfter(this.Store.fromDate) &&
            end.isSameOrBefore(this.Store.toDate.clone().add(1, 'days'))
          ) {
            const outOfBoundsIndex = start.isBefore(minAssessmentStartDate)
              ? 0
              : 1;
            outOfBoundsContainers[source] = outOfBoundsContainers[source] || [
              [], // will hold incidents falling before all known assessments
              [], // will hold incidents falling after all known assessments
            ];
            outOfBoundsContainers[source][outOfBoundsIndex].push(
              incidentGrouping
            );
          }
        }
        return statistics;
      },
      statistics
    );

    // "Fake" assessments that will house incidents that fall before or after
    // all known assessments.
    const beforeOutOfBounds = [];
    const afterOutOfBounds = [];

    Object.values(outOfBoundsContainers).forEach(
      ([beforeIncidents, afterIncidents]) => {
        if (beforeIncidents.length) {
          beforeOutOfBounds.push({
            incidents: beforeIncidents,
            outOfBound: true,
            user: beforeIncidents[0].user,
          });
        }

        if (afterIncidents.length) {
          afterOutOfBounds.push({
            incidents: afterIncidents,
            outOfBound: true,
            user: afterIncidents[0].user,
          });
        }
      }
    );

    // Sort the out of bounds groups by source.
    beforeOutOfBounds.sort((a, b) => compare(a.user.source, b.user.source));
    afterOutOfBounds.sort((a, b) => compare(a.user.source, b.user.source));

    return [
      ...beforeOutOfBounds,
      ...statisticsWithIncidents,
      ...afterOutOfBounds,
    ];
  }

  // Filter out out of bound incidents that were meant to be used only in
  // DrawerIncidents component
  get statisticsWithoutOutOfBoundIncidents() {
    return (this.statistics || []).filter(s => !s.outOfBound);
  }

  /**
   * Computed
   *
   * An array of length equal to the number of assessments.
   * Each item contains an array of unique unaccounted drug names.
   * @return {string[][]}
   */
  get unreconciledDrugs() {
    const arrayOfIncidentArrays = flattenedIncidentsForAssessments(
      this.statistics
    );
    return arrayOfIncidentArrays.map(incidents =>
      Object.keys(getUnaccountedMap(incidents))
    );
  }

  /**
   * Computed
   *
   * An array of length equal to the number of assessments.
   * Each item contains an array of reason objects whose identifier matches an
   * incident type present in the assessment.
   *
   * @return {Object[][]}
   */
  get assessmentsHeadlines() {
    const arrayOfIncidentArrays = flattenedIncidentsForAssessments(
      this.statisticsWithoutOutOfBoundIncidents
    );
    return getAssessmentsHeadlines({
      assessments: this.statisticsWithoutOutOfBoundIncidents,
      incidents: arrayOfIncidentArrays,
      type: 'diversion',
    });
  }

  // Computed
  get suspicionScores() {
    return this.statistics
      .filter(s => s.suspicionScore !== undefined)
      .map(s => Math.round(s.suspicionScore * 100));
  }

  // Computed
  get selectedStatistic() {
    return this.statistics.find(stat => stat.selected);
  }

  // Computed
  get loading() {
    return this.incidentStore.loading || this.statisticStore.loading;
  }
  /**
   * ACTION
   * Handler for selecting a statistic period.
   *
   * @param {string} startDateString  Selected start date.
   *
   * @param {string} index  Index of selected statistic period
   *
   * @return {void}
   */
  selectStatisticSourceStartDate = (startDateString, index) => {
    if (Store.activeTab !== 'Assessments') Store.setDrawerTab('Assessments');
    this.selectedStatisticSourceStartDate = startDateString;
    if (index > -1) {
      // if selecting an assessment in the event table, scroll to the assessment that was clicked
      this.scrollToStatistic = index;
    } else {
      // if selecting assessments in the activity view, scroll to the first selected assessment
      this.scrollToStatistic = this.statistics.findIndex(
        statistic => statistic.selected
      );
    }
  };

  // Action
  clearSourceStartDate = () => {
    this.selectedStatisticSourceStartDate = null;
  };

  // Action
  clearScrollTo = () => {
    this.scrollToStatistic = null;
  };

  // Action
  toggleFlag = statistic => {
    const {
      startTime,
      user: { source },
    } = statistic;
    const key = `${source}_${startTime}`;
    if (this.flagged.has(key)) {
      this.flagged.delete(key);
    } else {
      this.flagged.set(key, statistic);
    }
  };

  // Action
  clearFlags() {
    this.flagged.clear();
  }

  refresh = () => {
    this.incidentStore.refresh();
    this.statisticStore.refresh();
  };

  // Reset the period selection only if it would be outside the bounds of our
  // dates.
  maybeResetselectedStatisticSourceStartDate = ([fromMoment, toMoment]) => {
    if (!this.selectedStatisticSourceStartDate || !this.statistics.length)
      return;

    const statisticStartDate = this.selectedStatisticSourceStartDate;
    const selectedStartMoment = moment(statisticStartDate);

    const matchingStatistic = this.statistics.find(
      s => s.startTime === this.selectedStatisticSourceStartDate
    );

    // fallback to something out of range if there was no matchingStatistic
    const selectedEndMoment = moment(
      matchingStatistic ? matchingStatistic.endTime : 0
    );

    if (
      (selectedStartMoment.isSameOrAfter(fromMoment) &&
        selectedStartMoment.isSameOrBefore(toMoment)) ||
      (selectedEndMoment.isSameOrAfter(fromMoment) &&
        selectedEndMoment.isSameOrBefore(toMoment))
    )
      return;

    this.clearSourceStartDate();
  };

  downloadIncidents = eventType => {
    let title = '';
    const fp = Store.filterParams;

    const statisticDates = this.statistics
      .map(s => [moment(s.startTime), moment(s.endTime)])
      .reduce((a, b) => a.concat(b), []);

    const fromDate = moment.min(statisticDates);
    const toDate = moment.max(statisticDates);

    // User only events
    let csvHref = DiversionActivityClient.getURL(
      Store.activeUsers.split(),
      '',
      fromDate,
      toDate,
      true,
      fp.filterField,
      fp.filterValues,
      undefined,
      10000,
      true
    );

    title = `${Store.userName} ${eventType} from ${fromDate.format(
      'l'
    )} to ${toDate.format('l')}.csv`;

    if (
      eventType === INCIDENT_SUMMARY ||
      eventType === INCIDENT_SUMMARY_AND_EVENT_DETAILS
    ) {
      const ids = this.statistics
        .flatMap(s => s.incidents)
        .flatMap(inc => inc.linkedIncidents)
        .map(inc => inc.id);

      csvHref = getIncidentExportURL(eventType);
      DownloadUtils.downloadFromServerWithIds(csvHref, ids, title);
    } else {
      DownloadUtils.downloadFromServer(csvHref, title);
    }
  };

  updateIncidentList = item => {
    if (item.id)
      this.incidentStore.results = this.updatedIncidentList({
        item,
        incidents: this.incidentStore.results,
      });
    else this.refresh();
  };
}

decorate(DiversionActivityStore, {
  flagged: observable,
  scrollToStatistic: observable,
  selectedStatisticSourceStartDate: observable,
  assessmentsHeadlines: computed,
  unreconciledDrugs: computed,
  collatedIncidentsByKey: computed,
  statistics: computed,
  statisticsWithoutOutOfBoundIncidents: computed,
  suspicionScores: computed,
  loading: computed,
  clearFlags: action,
  selectStatisticSourceStartDate: action,
  clearSourceStartDate: action,
  toggleFlag: action,
  updateIncidentList: action,
  clearScrollTo: action,
});

export { DiversionActivityStore as DiversionActivityStoreClass };

export default new DiversionActivityStore({
  Store,
  incidentDescriptionStore: IncidentDescriptionStore,
});
