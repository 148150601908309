import { action, decorate, computed } from 'mobx';
import $ from 'jquery';

import { PermissionStore, CaseBundleClient, mapKeysToArray } from 'common';
import BundleStore from '../../bundle/stores/BundleStore';
import { BulkCaseActionsStoreClass } from '../BulkCaseActionsStore';

class BundledCasesStore extends BulkCaseActionsStoreClass {
  getIdsFrom(cases) {
    return mapKeysToArray(cases, c => {
      if (typeof c === 'string') {
        return c;
      }
    });
  }

  // Action
  bootstrapCases(cases) {
    if (!cases) return;
    this.clear();
    this.loading = true;

    const queuedRequests = [];
    const onAllComplete = () => {
      this.selectAll();
      this.loading = false;
    };

    (cases || []).forEach(c => this.cases.set(c.id, c));

    $.when(...queuedRequests).then(() => {
      if (this.loading) {
        onAllComplete();
      }
    }, onAllComplete);
  }

  // Action
  removeCases(cases) {
    const bundleId = BundleStore.bundleId;
    const selected = this.getIdsFrom(cases);

    CaseBundleClient.removeCases(bundleId, selected).then(() => {
      BundleStore.refresh().then(res => {
        this.bootstrapCases(res.cases);
      });
    });
  }

  // Computed
  get actionList() {
    if (PermissionStore.has('BUNDLE_MODIFY')) {
      return super.actions.concat([
        {
          value: 'removeFromBundle',
          label: 'Remove From Bundle',
        },
      ]);
    }
    return null;
  }
}

decorate(BundledCasesStore, {
  actionList: computed,
  bootstrapCases: action,
  removeCases: action,
});

const store = new BundledCasesStore();
export { store as BundledCasesStore };
export default store;
