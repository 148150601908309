import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from '../index.module.scss';

const SelectButton = ({ onSelect, selected }) => {
  const content = selected ? <i className="material-icons" /> : 'Select';

  return (
    <li className={styles.selectField}>
      <button
        className={classnames(
          'button',
          'button--outlined',
          'float-right',
          styles.selectButton,
          { [styles.selected]: selected }
        )}
        onClick={() => onSelect(!selected)}
        data-cy="select-button"
      >
        {content}
      </button>
    </li>
  );
};

SelectButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

SelectButton.defaultProps = {
  selected: false,
};

export default SelectButton;
