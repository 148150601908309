// basics
export const MAIN_LABEL = 'Print Options';
export const BUTTON_TEXT = 'Print';
export const CASEFLOW_LABEL = 'Caseflow';
export const PRIVACY_DETAILS_LABEL = 'User and Patient Details';
export const DIVERSION_DETAILS_LABEL = 'User Details';

// caseflow static options
export const CASEFLOW_ALL = 'all';
export const CASEFLOW_CUSTOM = 'custom';
export const CASEFLOW_NONE = 'none';

// caseflow custom fields (case history)
export const CASEFLOW_ATTACHMENTS = 'attachment';
export const CASEFLOW_ACTIONS = 'action';
export const CASEFLOW_NOTES = 'notes';
export const CASEFLOW_EMAILS = 'email';
export const CASEFLOW_FINAL_ASSESSMENTS = 'final-assessment';
export const CASEFLOW_RESOLUTION = 'resolution';
export const CASEFLOW_ASSESSMENTS = 'assessment';
export const CASEFLOW_RADAR = 'radar';

// caseflow react widget specific
export const CASEFLOW_MULTISELECT_PLACEHOLDER = 'Custom Caseflow Included';
export const CASEFLOW_VALUE_FIELD = 'value';
export const CASEFLOW_TEXT_FIELD = 'name';

// details static options
export const DETAILS_ALL = 'all';
export const DETAILS_NONE = 'none';

// details react widget specific
export const DETAILS_VALUE_FIELD = 'value';
export const DETAILS_TEXT_FIELD = 'name';

// misc
export const ERROR_MSG =
  'Oops, something seems off. Please check the URL or network connection and try again';
export const DIVERSION_CASETYPE = 'diversion';
