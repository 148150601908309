import React from 'react';

export const roundNumber = num => {
  if (isNaN(num)) return 'n/a';
  if (num === 0 || num === 1) return `${num * 100}%`;
  return `${parseFloat((num * 100).toFixed(6))}%`;
};

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatStat = stat => {
  if (stat.title.includes('Percent')) return roundNumber(stat.data.curr);
  return numberWithCommas(stat.data.curr);
};

export const renderTrend = (curr, prev, prevStatement) => {
  let trend;

  if (
    Object.keys(prevStatement).length === 0 &&
    prevStatement.constructor === Object
  )
    trend = 'n/a';
  else if (prev > curr) trend = 'down';
  else if (prev === curr) trend = 'flat';
  else if (prev < curr) trend = 'up';

  if (trend === 'n/a') {
    return <span className="empty-space" />;
  }
  return <i className={`material-icons icon-trending_${trend}`} />;
};
