import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import Field from '../../ui/Field';
import ShowHideMore from '../../ui/ShowHideMore';
import ViaSource from '../../ui/ViaSource';
import ScreenOnly from '../../ui/ScreenOnly';

export const getLabel = type => (type ? `${type} Phone` : 'Phone');

export const NoPhone = () => (
  <Field label={'Phone'} width="auto">
    <p>None</p>
  </Field>
);

export const PhoneNumber = ({ type, number, source }) => (
  <Field label={getLabel(type)} width="auto">
    <p>{number}</p>
    <ViaSource source={source} />
  </Field>
);

export const PhoneNumberGroup = ({ group, type, hideOnPrint }) => {
  return (
    <ScreenOnly hideOnPrint={hideOnPrint}>
      <ShowHideMore
        numberToShow={1}
        label={getLabel(type)}
        pluralLabel={getLabel(type) + 's'}
      >
        {group.map((phoneNumberInfo, i) => (
          <PhoneNumber {...phoneNumberInfo} key={i} />
        ))}
      </ShowHideMore>
    </ScreenOnly>
  );
};

const PhoneNumbers = ({ numbers = [], hideOnPrint }) => {
  if (!numbers.length) return <NoPhone />;

  const phoneNumberGroup = [];
  const reverseAndGroupByType = numbers
    .slice()
    .map(a => {
      if (!a.type) {
        a.type = '';
      }
      return a;
    })
    .reverse()
    .groupBy('type');

  Object.keys(reverseAndGroupByType).forEach((type, i) =>
    phoneNumberGroup.push(
      <PhoneNumberGroup
        group={reverseAndGroupByType[type]}
        hideOnPrint={type !== 'work' && hideOnPrint}
        type={type}
        key={i + type}
      />
    )
  );

  return phoneNumberGroup;
};

PhoneNumber.propTypes = {
  number: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
  hideOnPrint: PropTypes.bool,
};

PhoneNumbers.propTypes = {
  numbers: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  hideOnPrint: PropTypes.bool,
};

PhoneNumberGroup.propTypes = {
  group: PropTypes.arrayOf(PropTypes.shape({})),
  hideOnPrint: PropTypes.bool,
  type: PropTypes.string,
};

export default PhoneNumbers;
