import React from 'react';
import PropTypes from 'prop-types';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import TextareaAutosize from 'react-autosize-textarea';
import styles from './index.module.scss';

const DropdownItem = ({ entity: { name } }) => <div>{name}</div>;

DropdownItem.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string,
  }),
};

const TextArea = props => <TextareaAutosize {...props} />;

const TextareaAutocomplete = ({
  autoFillDataProvider,
  field,
  onChange,
  placeholder,
  value,
  rows,
  maxRows,
}) => {
  return (
    <div className={styles.textareaAutosize} data-cy="template-textarea">
      <ReactTextareaAutocomplete
        /* loading component is required even though our dataProvider isn't async */
        loadingComponent={() => <span>Loading</span>}
        dropdownClassName={styles.dropdown}
        itemClassName={styles.item}
        minChar={0}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value, field)}
        renderToBody
        movePopupAsYouType
        textAreaComponent={{
          component: TextArea,
          ref: 'innerRef',
        }}
        rows={rows}
        maxRows={maxRows}
        trigger={{
          '{': {
            dataProvider: autoFillDataProvider,
            component: DropdownItem,
            output: ({ char }) => ({ text: char, caretPosition: 'end' }),
          },
        }}
      />
    </div>
  );
};

TextareaAutocomplete.propTypes = {
  autoFillDataProvider: PropTypes.func,
  field: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  entity: PropTypes.shape({
    name: PropTypes.string,
  }),
};

TextareaAutocomplete.defaultProps = {
  autoFillDataProvider: () => {},
  field: '',
  onChange: () => {},
  placeholder: '',
  value: '',
  rows: 5,
  maxRows: null,
};

export default TextareaAutocomplete;
