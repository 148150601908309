import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateTimePicker } from 'react-widgets';
import { parse, isValid } from './helpers';

const DateInput = ({
  disabled,
  formats,
  inclusive,
  maxDate,
  minDate,
  onChange,
  placeholder,
  value,
  'data-cy': dataCy,
}) => {
  const [open, setOpen] = useState(false);
  const min = minDate ? moment(minDate).toDate() : new Date(1900, 0, 1);
  const max = maxDate ? moment(maxDate).toDate() : new Date(2099, 11, 31);
  const dateValue = value ? moment(value).toDate() : undefined;

  // library provides min/max protection for date selection, but not for manually typed dates
  const onChangeInternal = currValue => {
    const valid = isValid(currValue, formats, min, max, inclusive);
    if (valid) {
      const formatted = parse(currValue, formats);
      onChange(formatted, true);
    } else {
      onChange(undefined, false);
    }
  };

  // we want the picker to show upon clicks
  const onClick = e => {
    if (e.target?.tagName === 'INPUT' && !disabled) {
      setOpen(true);
    }
  };

  const onToggle = () => setOpen(!open);

  // prevent library from moving selected date around, move text cursor instead
  const onKeyDown = e => {
    if (e.keyCode === 37 || e.keyCode === 39) {
      e.preventDefault();
      const cursorPos = e.target.selectionStart + (e.keyCode === 37 ? -1 : 1);
      e.target.setSelectionRange(
        Math.max(cursorPos, 0),
        Math.max(cursorPos, 0)
      );
      setOpen(false);
    } else if (e.keyCode === 38 || e.keyCode === 40) {
      // prevent up and down arrow date selection movement
      e.preventDefault();
      setOpen(false);
    } else if (e.keyCode === 13) {
      e.preventDefault();
      setOpen(false);
      onChangeInternal(e.target.value);
    }
  };

  return (
    <DateTimePicker
      time={false}
      value={dateValue}
      disabled={disabled}
      onChange={onChangeInternal}
      onClick={onClick}
      onKeyDown={onKeyDown}
      open={open ? 'date' : false}
      onToggle={onToggle}
      placeholder={placeholder}
      min={min}
      max={max}
      format="M/D/YYYY"
      data-cy={dataCy}
    />
  );
};

DateInput.defaultProps = {
  dateFormat: 'MM/DD/YYYY',
  disabled: false,
  formats: ['MM/DD/YYYY', 'M/D/YYYY'],
  inclusive: true,
  onChange() {},
  value: 'M/D/YYYY',
};

DateInput.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
  formats: PropTypes.arrayOf(PropTypes.string),
  inclusive: PropTypes.bool,
  'data-cy': PropTypes.string,
};

export default DateInput;
