import { useState, useEffect } from 'react';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { SuspiciousLabel } from 'common';
import { formatDecimal } from '../../utils/formatDecimal';
import { ReasonsType, ClusterReasonsType } from '../../types/reasonsTypes';
import { DropdownList } from 'react-widgets';

const ReasonStats = ({
  reasons,
  clusterReasons,
}: {
  reasons?: ReasonsType[];
  clusterReasons?: ClusterReasonsType[];
}) => {
  const [viewAll, setViewAll] = useState(false);

  let substringToBold: string = '';

  // add label, key, name key value pairs that we need for the dropdown
  const getPeerGroupOptions = () => {
    const sortedClusterReasons = clusterReasons?.sort(
      (a, b) => b.rank - a.rank
    );
    const updatedClusterReasons =
      sortedClusterReasons
        ?.filter(r => r?.type !== 'institutional')
        ?.map((r, i) => ({
          ...r,
          key: i,
          label: 'Peer Group',
          name:
            r?.type === 'adc'
              ? `${
                  i === 0
                    ? 'Primary ADCs'
                    : i === 1
                    ? 'Secondary ADCs'
                    : 'Tertiary ADCs'
                }`
              : `${r?.type?.charAt(0).toUpperCase() +
                  r?.type?.slice(1).replace('functional', '')}Role`, // uppercase 'a' or 'p' in anesthesia/pharmacy
        }))
        .slice(0, 3) || [];

    const institutionalReason = sortedClusterReasons?.find(
      r => r?.type === 'institutional'
    );
    // Move 'All ADCs' option to bottom
    if (institutionalReason) {
      const firstClusterReason = Object.assign(institutionalReason, {
        label: 'Peer Group',
        name: 'All ADCs',
        key: 3,
      });
      updatedClusterReasons.push(firstClusterReason);
      return updatedClusterReasons;
    }
    return updatedClusterReasons;
  };

  const peerGroupDropdownOptions = getPeerGroupOptions();

  const [peerGroup, setPeerGroup] = useState(
    peerGroupDropdownOptions[0]?.label + ' ' + peerGroupDropdownOptions[0]?.name
  );
  const [boldedSubstring, setBoldedSubstring] = useState(substringToBold);

  const hasSubCluster =
    reasons !== undefined && reasons.length > 0
      ? reasons?.filter(
          reason => reason.identifier === 'PrimarySubclusterStationsTranslator'
        )
      : peerGroupDropdownOptions
          .filter(p => p.label + ' ' + p.name === peerGroup)
          .map(r =>
            r.reasons.filter(
              r => r.identifier === 'PrimarySubclusterStationsTranslator'
            )
          )
          .flat();

  // check if anesthesia or pharmacy and adjust options array
  hasSubCluster.map(r => {
    if (r.description.includes('Role:')) {
      substringToBold = 'Role:';
    } else if (r.description.includes('Peer Group ADCs:')) {
      substringToBold = 'Peer Group ADCs:';
    } else {
      substringToBold = 'ADCs:';
    }
    return substringToBold;
  });

  useEffect(() => {
    setBoldedSubstring(substringToBold);
  }, [substringToBold]);

  let groupedBy: string;
  const peerGroupSelected =
    peerGroup === 'Peer Group All ADCs' ||
    peerGroup === 'Peer Group Primary ADCs' ||
    peerGroup === 'Peer Group Secondary ADCs' ||
    peerGroup === 'Peer Group Tertiary ADCs';
  if (peerGroupSelected) {
    groupedBy = 'ADCs';
  } else if (
    peerGroup === 'Peer Group Anesthesia Role' ||
    peerGroup === 'Peer Group Pharmacy Role'
  ) {
    groupedBy = 'Role';
  } else {
    groupedBy = 'Unknown';
  }

  return (
    <>
      {clusterReasons !== undefined && clusterReasons.length > 0 ? (
        <div className="reason-stats-title-and-filter">
          <p className="reason-stats-table-label">
            <strong>Statistics</strong> Ordered by Z-Score
          </p>
          <div className="grouped-by-and-dropdown-container">
            <p className="grouped-by" id={groupedBy}>
              <i className="material-icons icon-group" />
              <span>
                <strong>Grouped By</strong> {groupedBy}
              </span>
            </p>
            <DropdownList
              containerClassName={
                peerGroupDropdownOptions.length <= 1 ? 'peer-group' : ''
              }
              disabled={peerGroupDropdownOptions.length <= 1}
              data={peerGroupDropdownOptions}
              dataKey="key"
              textField="name"
              value={peerGroup}
              onChange={(value: ClusterReasonsType) => {
                setPeerGroup(value.label + ' ' + value.name);
              }}
              valueComponent={DropdownItem}
              className="reasonStatsDropdown"
            />
          </div>
        </div>
      ) : null}
      {clusterReasons !== undefined && clusterReasons.length > 0 ? (
        <>
          {peerGroupDropdownOptions
            .filter(p => p.label + ' ' + p.name === peerGroup)
            .map(r =>
              r.reasons
                .filter(
                  r => r.identifier === 'PrimarySubclusterStationsTranslator'
                )
                .map((r, i) => {
                  const boldedPeerGroup = r.description.replace(
                    boldedSubstring,
                    `<b>${boldedSubstring}</b>`
                  );
                  return (
                    <p
                      className="reason-stats-peer-group"
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: boldedPeerGroup,
                      }}
                    />
                  );
                })
            )}
          <table className="reason-stats-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Mean</th>
                <th>Z-Score</th>
                <th>Suspicion</th>
              </tr>
            </thead>
            <tbody>
              <>
                {peerGroupDropdownOptions
                  .filter(p => p.label + ' ' + p.name === peerGroup)
                  .map(r =>
                    r.reasons
                      .filter(
                        r =>
                          r.identifier !== 'PrimarySubclusterStationsTranslator'
                      )
                      .sort(
                        (a, b) => b.statistics?.zscore - a.statistics?.zscore
                      )
                      .map((r, i) => {
                        return (
                          <tr key={i}>
                            <td>{r.description}</td>
                            <td>{formatDecimal(r.statistics?.mean)}</td>
                            <td>{formatDecimal(r.statistics?.zscore)}</td>
                            <td>
                              <SuspiciousLabel zscore={r.statistics?.zscore} />
                            </td>
                          </tr>
                        );
                      })
                      .slice(0, viewAll ? r.reasons.length : 10)
                  )}
              </>
            </tbody>
          </table>
          {peerGroupDropdownOptions
            .filter(p => p.label + ' ' + p.name === peerGroup)
            .map(r => r.reasons.length)[0] > 10 ? (
            <button
              className="reason-stats-table-button"
              onClick={() => setViewAll(!viewAll)}
            >
              {viewAll ? 'View Less Statistics' : 'View All Statistics'}
            </button>
          ) : null}
        </>
      ) : null}

      {reasons !== undefined && reasons.length > 0 ? (
        <>
          <p className="reason-stats-table-label">
            <strong>Statistics</strong> Ordered by Z-Score
          </p>
          {hasSubCluster.map((r, i) => {
            const boldedPeerGroup = r.description.replace(
              boldedSubstring,
              `<b>${boldedSubstring}</b>`
            );
            return (
              <p
                className="reason-stats-peer-group"
                key={i}
                dangerouslySetInnerHTML={{
                  __html: boldedPeerGroup,
                }}
              />
            );
          })}
          <table className="reason-stats-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Mean</th>
                <th>Z-Score</th>
                <th>Suspicion</th>
              </tr>
            </thead>
            <tbody>
              {reasons
                ?.filter(
                  reason =>
                    reason.identifier !== 'PrimarySubclusterStationsTranslator'
                )
                .sort((a, b) => b.statistics?.zscore - a.statistics?.zscore)
                .map((reason, i) => {
                  return (
                    <tr key={i}>
                      <td>{reason.description}</td>
                      <td>{formatDecimal(reason.statistics?.mean)}</td>
                      <td>{formatDecimal(reason.statistics?.zscore)}</td>
                      <td>
                        <SuspiciousLabel zscore={reason.statistics?.zscore} />
                      </td>
                    </tr>
                  );
                })
                .slice(0, viewAll ? reasons.length : 10)}
            </tbody>
          </table>
          {reasons.length > 10 ? (
            <button
              className="reason-stats-table-button"
              onClick={() => setViewAll(!viewAll)}
            >
              {viewAll ? 'View Less Statistics' : 'View All Statistics'}
            </button>
          ) : null}
        </>
      ) : null}
    </>
  );
};

ReasonStats.propTypes = {
  reasons: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      statistics: PropTypes.shape({
        mean: PropTypes.number,
        zscore: PropTypes.number,
      }),
      description: PropTypes.string,
      identifier: PropTypes.string,
    })
  ),
  clusterReasons: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      label: PropTypes.string,
      name: PropTypes.string,
      reasons: mobxPropTypes.arrayOrObservableArrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          identifier: PropTypes.string,
          statistics: PropTypes.shape({
            mean: PropTypes.number,
            zscore: PropTypes.number,
          }),
        })
      ),
      rank: PropTypes.number,
      type: PropTypes.string,
    })
  ),
};

ReasonStats.defaultProps = {
  reasons: [],
  clusterReasons: [],
};

export default ReasonStats;

// need this helper function to bold text in dropdown component, can be removed when react-widgets gets upgraded to v5 as its supported natively in that version
const DropdownItem = ({ item }) => {
  const substringToBold = 'Peer Group';
  const boldPeerGroup = item.replace(
    substringToBold,
    `<b>${substringToBold}</b>`
  );

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: boldPeerGroup,
      }}
    />
  );
};

DropdownItem.propTypes = {
  item: PropTypes.string,
};
