import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

/**
 * Layer responsible for drawing labels for diversion activity.
 */

const DiversionActivityLabelLayer = ({ height, leftMargin }) => {
  // Add a smidge to the text transform y value in order to vertically center
  // the text. We _could_ use dominantBaseline="middle", but IE does not support
  // it.
  return (
    <g>
      <text
        className={styles.rowTitle}
        transform={`translate(${leftMargin - 10}, ${height / 2 + 5})`}
        textAnchor="end"
      >
        Drug Incidents & Assessments
      </text>
    </g>
  );
};

DiversionActivityLabelLayer.propTypes = {
  height: PropTypes.number.isRequired,
  leftMargin: PropTypes.number.isRequired,
};

export default DiversionActivityLabelLayer;
