import React from 'react';
import PropTypes from 'prop-types';
import Tippy, { tippy } from '@tippyjs/react';
import { followCursor as followCursorPlugin } from 'tippy.js';

const Tooltip = ({
  content,
  placement,
  followCursor,
  hideOnClick,
  offset,
  children,
  reference,
  className,
  visible,
}) => {
  if (!content) {
    return children;
  }

  const tippyProps = {
    plugins: [followCursorPlugin],
    arrow: true,
    animation: 'fade',
    children,
    reference,
    content,
    placement,
    followCursor,
    hideOnClick,
    className,
    visible,
  };
  if (offset) tippyProps.offset = offset;

  return <Tippy {...tippyProps} />;
};

Tooltip.propTypes = {
  content: PropTypes.node,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  offset: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node,
  className: PropTypes.string,
  reference: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({ current: PropTypes.node }),
  ]),
  followCursor: PropTypes.bool,
  hideOnClick: PropTypes.bool,
  visible: PropTypes.bool,
};

Tooltip.defaultProps = {
  content: null,
  placement: 'top',
  offset: undefined,
  followCursor: false,
  hideOnClick: true,
  className: '',
};

export default Tooltip;

export function rebuildTooltipsOnMutation() {
  function debounce(fn, ms) {
    let timeout;
    return function() {
      clearTimeout(timeout);
      timeout = setTimeout(fn, ms);
    };
  }

  const rebuild = debounce(() => {
    // IE for some reason seems to have cached _tippy references sticking around
    // on elements. If tippy.js sees these, it will not refresh the content.
    Array.from(document.querySelectorAll('[data-tippy-content]')).forEach(
      e => (e._tippy = null)
    );
    tippy('[data-tippy-content]', {
      plugins: [followCursorPlugin],
    });
  }, 100);

  new window.MutationObserver(rebuild).observe(document.body, {
    attributes: true,
    subtree: true,
    attributeFilter: ['data-tippy-content', 'data-tippy-placement'],
  });
}
