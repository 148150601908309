import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { observable, decorate } from 'mobx';
import styles from './index.module.scss';
import { RouterContainer } from 'common';

import Store from '../stores/Store';
import BrushChart from '../BrushChart';
import { CustomDropdown, DatePicker, Tooltip } from '../../ui';
import ChartStore from '../stores/ChartStore';

const OptionToggle = observer(({ onChange }) => (
  <Tooltip content="Export/Sorting Options" placement="left">
    <button type="button" className="button" onClick={onChange}>
      <i
        className={classnames('material-icons', {
          'icon-keyboard_arrow_up': !Store.optionsVisible,
          'icon-keyboard_arrow_down': Store.optionsVisible,
        })}
      />
    </button>
  </Tooltip>
));

OptionToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const ActivityFilters = observer(
  class ActivityFilters extends React.Component {
    // Observables
    filtersOpen = false;

    onFromDateChange(newDate, valid) {
      if (valid)
        RouterContainer.go(undefined, Store.getQuery({ fromDate: newDate }));
    }

    onToDateChange(newDate, valid) {
      if (valid)
        RouterContainer.go(undefined, Store.getQuery({ toDate: newDate }));
    }

    toggleOptions() {
      Store.optionsVisible = !Store.optionsVisible;
    }

    handleSelection(type) {
      Store[`${type}Visible`] = !Store[`${type}Visible`];
      if (Store[`${type}Visible`]) {
        localStorage.setItem(`activity-${type}Visible`, true);
      } else {
        localStorage.setItem(`activity-${type}Visible`, false);
      }
    }

    toggleFilters = () => {
      this.filtersOpen = !this.filtersOpen;
    };

    render() {
      return (
        <header>
          <div
            className={classnames('metalog-filters', {
              hidden: Store.filterOptions.length === 1,
            })}
          >
            <CustomDropdown
              buttonClass="metalog-filters-button"
              iClass="material-icons icon-bubble_chart"
              buttonText={
                <span className={styles.filterText}>
                  <span className="muted">{Store.activeFiltersCount} /</span>{' '}
                  {Store.filterOptions.length}
                </span>
              }
              wrapperClass="metalog-filters-dropdown"
            >
              <ul>
                {Store.filterOptions.map(type => (
                  <li onClick={() => this.handleSelection(type)} key={type}>
                    <i
                      className={classnames('material-icons', {
                        'icon-visibility': Store[`${type}Visible`],
                        'icon-visibility_off': !Store[`${type}Visible`],
                      })}
                    />
                    <span>
                      {' '}
                      {type === 'timeEntries'
                        ? 'Time Entries '
                        : type.capitalizeFirstLetter()}{' '}
                    </span>
                  </li>
                ))}
              </ul>
            </CustomDropdown>
          </div>
          <form className="form form__inline form__small form__from-date">
            <ul>
              <li className="input-container">
                <DatePicker
                  className="fromDate"
                  onChange={this.onFromDateChange.bind(this)}
                  value={Store.fromDate}
                  tabIndex={1}
                  maxDate={Store.toDate}
                />
              </li>
            </ul>
          </form>
          <div>
            <BrushChart
              allowStartCut={ChartStore.brushChartAllowStartCut}
              allowEndCut={ChartStore.brushChartAllowEndCut}
              xScale={ChartStore.brushChartXScale}
              width={ChartStore.brushChartWidth}
              isHidden={ChartStore.brushChartIsHidden}
              selectedDates={ChartStore.brushExtent.slice(0, 2)}
              onSelectDates={ChartStore.selectDates}
              onCut={ChartStore.brushChartCut}
            />
          </div>
          <form className="form form__inline form__small form__to-date">
            <ul>
              <li className="input-container">
                <DatePicker
                  className="toDate"
                  onChange={this.onToDateChange.bind(this)}
                  value={Store.toDate}
                  minDate={Store.fromDate}
                />
              </li>
            </ul>
          </form>
          <OptionToggle onChange={this.toggleOptions} />
        </header>
      );
    }
  }
);

ActivityFilters.displayName = 'ActivityFilters';

decorate(ActivityFilters, {
  filtersOpen: observable,
});

export default ActivityFilters;
