import BulkActionsView from '../BulkActionsView';
import { BulkActionsContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({ vipsStore, query }) {
  return {
    onExpire: vipsStore.onExpire,
    onModify: vipsStore.onModify,
    onReason: vipsStore.onReason,
    query,
    setFilters: vipsStore.setFilters,
    storeForRegistry: vipsStore,
    size: vipsStore.numberOfVipRegistrationsToUpdate,
    bulkActionComplete: vipsStore.bulkActionComplete,
  };
}

const BulkActionsContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: BulkActionsContext,
    mapProps,
    PresentationalComponent: BulkActionsView,
  })
);

BulkActionsContainer.displayName = 'BulkActionsContainer';

export default BulkActionsContainer;
