import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FullName } from 'common';

import Store from '../stores/Store';
import Detail from '../eventTableDetails/Detail';

export default function WitnessedUser(props) {
  const { witness, warning } = props;

  if (witness) {
    const ref = Store.getUserLink({ userId: witness.id });
    return (
      <Detail
        label="Witness User"
        value={
          <Link key={witness.id} to={ref}>
            {witness.firstName || witness.lastName ? (
              <FullName person={witness} />
            ) : (
              'Missing name data for witnessed user'
            )}
          </Link>
        }
      />
    );
  } else if (!witness && warning) {
    return (
      <Detail
        label="Witness User"
        value={<span className="text-danger">No Witnessing User</span>}
        key="handling-unwitnessed"
      />
    );
  }
  return null;
}

WitnessedUser.propTypes = {
  warning: PropTypes.bool,
  witness: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
  }),
};

WitnessedUser.defaultProps = {
  warning: false,
  witness: null,
};
