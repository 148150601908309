import React from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';

import { Column, Cell } from 'fixed-data-table-2';

import Registry from '../../ui/registry/Registry';
import SortableHeaderCell from '../../ui/registry/RegistrySortableHeaderCell';
import NameCell from '../NameCell';
import CreatedByCell from '../CreatedByCell';
import TaggedCell from '../TaggedCell';

const TagsList = observer(
  class TagsList extends React.Component {
    // Observable
    width = 100;

    static propTypes = {
      baseUrl: PropTypes.string.isRequired,
      label: PropTypes.string,
      labelPlural: PropTypes.string,
      rowHeight: PropTypes.number.isRequired,
      store: PropTypes.object.isRequired,
    };

    static defaultProps = {
      label: 'Tag',
    };

    componentDidMount() {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
      this.width = this.node.offsetWidth;
    };

    setCellWidth(percentage) {
      percentage /= 100;
      return this.width * percentage;
    }

    render() {
      const { label, labelPlural, baseUrl, store, ...other } = this.props;
      const data = store.activeTags;

      return (
        <Registry
          ref={node => (this.node = node)}
          dataCount={data.length}
          width={this.width}
          {...other}
        >
          <Column
            header={
              <SortableHeaderCell
                label="Tag"
                propName="name"
                store={store}
                baseUrl={baseUrl}
              />
            }
            width={this.setCellWidth(34)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <NameCell data={data[rowIndex]} baseUrl={baseUrl} store={store} />
            )}
          />

          <Column
            header={<Cell>Tagged</Cell>}
            width={this.setCellWidth(33)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <TaggedCell
                data={data[rowIndex]}
                store={store}
                label={label}
                labelPlural={labelPlural}
                baseUrl={baseUrl}
              />
            )}
          />

          <Column
            header={
              <SortableHeaderCell
                label="Created By"
                propName="createdBy"
                store={store}
                baseUrl={baseUrl}
              />
            }
            width={this.setCellWidth(33)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => (
              <CreatedByCell
                data={data[rowIndex]}
                baseUrl={baseUrl}
                store={store}
              />
            )}
          />
        </Registry>
      );
    }
  }
);

decorate(TagsList, {
  width: observable,
});

TagsList.displayName = 'TagsList';

export default TagsList;
