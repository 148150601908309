import React from 'react';
import { observer } from 'mobx-react';

import GutterLayer from '../GutterLayer';
import UserOnlyRowLayer from '../UserOnlyRowLayer';
import VerticalRuleLayer from '../VerticalRuleLayer';
import ChartStore from '../../stores/ChartStore';
import TimeEntryLayerContainer from '../layers/TimeEntryLayerContainer';

/**
 * The user only chart displayed within the activity view. This displays user only events in a
 * time-based chart.
 * @extends React.Component
 */
const UserOnlyActivityChart = observer(() => {
  return (
    <svg
      className="chart accesses_chart"
      width="100%"
      height={ChartStore.rowHeight}
    >
      <UserOnlyRowLayer />
      <TimeEntryLayerContainer />
      <VerticalRuleLayer />
      <GutterLayer
        leftMargin={ChartStore.margin.left}
        leftPadding={ChartStore.padding.left}
        rightPadding={ChartStore.padding.right}
      />
    </svg>
  );
});

UserOnlyActivityChart.displayName = 'UserOnlyActivityChart';

export default UserOnlyActivityChart;
