import React from 'react';
import PropTypes from 'prop-types';
import { DoubleLineCell } from '../../../../ui';
import classnames from 'classnames';
import styles from '../index.module.scss';

const UserCell = ({ isActive, firstName, lastName, username }) => {
  return (
    <DoubleLineCell
      className={classnames({ [styles.isActive]: isActive })}
      hidePadding
      lineOne={`${lastName}, ${firstName}`}
      lineTwo={username}
    />
  );
};

export default UserCell;

UserCell.propTypes = {
  isActive: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  username: PropTypes.string,
};
