import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { FullName } from 'common';
import { Anchor } from '../../../ui';
import styles from './index.module.scss';

const Header = ({
  title,
  subtitle,
  actions,
  dateCreated,
  author,
  dateLastModified,
  lastModifiedBy,
}) => {
  const actionItems = actions
    .filter(action => action.enabled)
    .map(action => (
      <Anchor
        tag="span"
        key={action.name}
        className={classnames(styles.action, 'forScreen', {
          'text-danger': action.dangerText,
        })}
        onClick={action.callback}
        role="button"
      >
        {action.name}
      </Anchor>
    ));

  let edited = null;
  if (dateLastModified.isAfter(dateCreated)) {
    edited = (
      <>
        {' '}
        (edited last by <FullName condensed person={lastModifiedBy} /> on{' '}
        {dateLastModified.format('lll')})
      </>
    );
  }

  const byline = (
    <div className={styles.byline}>
      <FullName condensed person={author} />
      <span className="text-subtle">
        {' '}
        {dateCreated ? dateCreated.format('lll z') : ''}
        {edited}
      </span>
    </div>
  );

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <span>
          <strong>{title}</strong>
          {subtitle && <> {subtitle}</>}
        </span>
        {byline}
      </div>
      <div className={classnames(styles.actions, 'headerActions')}>
        {actionItems}
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      callback: PropTypes.func.isRequired,
      enabled: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  dateCreated: PropTypes.instanceOf(moment),
  dateLastModified: PropTypes.instanceOf(moment).isRequired,
  author: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  lastModifiedBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

Header.defaultProps = {
  subtitle: null,
  actions: [],
  author: null,
  lastModifiedBy: null,
};

export default Header;
