import React from 'react';
import PropTypes from 'prop-types';
import { computed, observable, autorun, decorate, action } from 'mobx';
import { observer } from 'mobx-react';
import { Checkbox, Input, MultilineInput } from '../../../ui';

const SamlProperty = observer(
  class SamlProperty extends React.Component {
    static propTypes = {
      propKey: PropTypes.string.isRequired,
      name: PropTypes.string,
      description: PropTypes.string,
      properties: PropTypes.object,
      type: PropTypes.string,
    };

    static defaultProps = {
      name: null,
      type: 'text',
      description: null,
      properties: {},
    };

    constructor(props) {
      super(props);
      autorun(() => {
        const currentProperty = this.props.properties[this.props.propKey];
        if (this.props.type === 'checkbox') {
          if (currentProperty === 'true' || currentProperty === true)
            this.setCurrentValue(true);
          else this.setCurrentValue(false);
        } else {
          this.currentValue = this.props.properties[this.props.propKey] || '';
        }
      });
    }

    // observables
    currentValue;

    // computed
    get dirty() {
      const { type } = this.props;
      const isCheckbox = type === 'checkbox';
      // Sometimes the current value is missing (falsy). If we're dealing with
      // a checkbox, we treat it as being unchecked.
      const current =
        this.props.properties[this.props.propKey] ||
        (isCheckbox ? 'false' : '');
      let editing = this.currentValue || '';
      if (this.props.type === 'checkbox') editing = `${this.currentValue}`;
      return current !== editing;
    }

    get propKey() {
      return this.props.propKey;
    }

    setCurrentValue = val => (this.currentValue = val);

    render() {
      if (this.props.type === 'checkbox') {
        return (
          <div className="saml-fields">
            <Checkbox
              checked={this.currentValue}
              onChange={() => this.setCurrentValue(!this.currentValue)}
              color="secondary"
            >
              <strong>{this.props.name}</strong> {this.props.description}
            </Checkbox>
          </div>
        );
      }

      let description;
      if (this.props.description) description = <p>{this.props.description}</p>;
      let control = (
        <Input
          bordered
          label={this.props.name || this.props.propKey}
          margin="md"
          value={this.currentValue || ''}
          onChange={val => (this.currentValue = val)}
        />
      );
      if (this.props.type === 'textarea') {
        control = (
          <MultilineInput
            label={this.props.name || this.props.propKey}
            placeholder={this.props.name || this.props.propKey}
            value={this.currentValue || ''}
            onChange={val => (this.currentValue = val)}
            bordered
          />
        );
      }
      return (
        <React.Fragment>
          {description}
          {control}
        </React.Fragment>
      );
    }
  }
);

decorate(SamlProperty, {
  currentValue: observable,
  dirty: computed,
  setCurrentValue: action,
});

SamlProperty.displayName = 'SamlProperty';

export default SamlProperty;
