import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import ProgressButton from '../ProgressButton';

const BulkProgressButton = observer(
  class BulkProgressButton extends React.Component {
    static propTypes = {
      store: PropTypes.shape({
        complete: PropTypes.bool,
        selected: PropTypes.shape({
          size: PropTypes.number,
        }),
        performingBulkAction: PropTypes.bool.isRequired,
        percentComplete: PropTypes.number.isRequired,
      }).isRequired,
      action: PropTypes.string,
      hidden: PropTypes.bool,
    };

    static defaultProps = {
      action: null,
      hidden: false,
    };

    /**
     * Render a label for the button. Will update to include the percentage
     * complete when the requests are processing.
     * @return {Object} a ReactDOM node Object
     */
    renderLabel() {
      const { store, action } = this.props,
        { performingBulkAction, percentComplete } = store;

      let suffix;

      if (store.complete) {
        suffix = <span className="button--progress__complete">Complete</span>;
      } else if (performingBulkAction) {
        suffix = (
          <span className="button--progress__complete">
            {percentComplete}% Complete
          </span>
        );
      }

      let label;

      switch (action) {
        case 'addAction':
          label = 'Add Action';
          break;
        case 'resolve':
          label = 'Change Resolution';
          break;
        case 'resolve-createTemporary':
          label = 'Resolve Case & Create Temporary Permission';
          break;
        case 'reopen':
          label = 'Change Resolution';
          break;
        case 'assign':
          label = 'Change Case Owner';
          break;
        case 'changeType':
          label = 'Change Case Type';
          break;
        case 'updateNotes':
          label = 'Add Case Notes';
          break;
        case 'updateAssessment':
          label = 'Update Final Assessment';
          break;
        default:
          label = 'Perform Action';
          break;
      }

      if (suffix) {
        return (
          <span>
            {label} {suffix}
          </span>
        );
      } else {
        return <span>{label}</span>;
      }
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { store, action, hidden, ...props } = this.props,
        { performingBulkAction, selected, percentComplete } = store;

      return (
        <ProgressButton
          className={classnames('button--block', {
            hidden: !store.complete && hidden,
          })}
          loading={performingBulkAction}
          disabled={selected.size < 1}
          percentage={percentComplete}
          {...props}
        >
          {this.renderLabel()}
        </ProgressButton>
      );
    }
  }
);

export default BulkProgressButton;
