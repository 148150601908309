import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Authenticated, DateHelpers } from 'common';

import CaseViewAuthPermissions from '../../CaseViewAuthPermissions';
import CaseViewBundles from '../../CaseViewBundles';
import CaseViewUserCases from '../../CaseViewUserCases';
import AssignResolveForm from '../../assignResolve/AssignResolveForm';
import CaseIntegrationSyncContainer from '../../CaseIntegrationSyncContainer';
import CaseHistory from '../../CaseHistory';
import { DeleteForm, ExpandoSection, SysAlert } from '../../../ui';
import TypeRoleForm from '../../TypeRoleForm';
import CaseCustomPrintContainer from '../../print/CaseCustomPrintContainer';

const INDEXES = {
  INDEX_INFO: 0,
  INDEX_CASES: 1,
  INDEX_BUNDLES: 2,
  INDEX_AUTH: 3,
  INDEX_HISTORY: 4,
  INDEX_DELETE: 5,
  INDEX_PRINT: 6,
};

const INDEX_LENGTH = Object.keys(INDEXES).length;

const CaseViewAside = ({
  caseType,
  canSync,
  canViewBundles,
  canViewAuths,
  permissionsCount,
  caseCreated,
  caseLastModified,
  creatingCase,
  bundlesCount,
  bundles,
  bundlesRefresh,
  editReason,
  patientId,
  patientFullName,
  patientLink,
  caseIntegration,
  readOnly,
  userId,
  userLink,
  otherUserCases,
  canDelete,
  onDeleteCase,
  userFullName,
}) => {
  const timezone = DateHelpers.getCurrentAppUserTimezone();
  let arr = Array(INDEX_LENGTH).fill(false);
  arr[0] = true;

  const [visibilities, setVisibilities] = useState(arr);

  const toggleVisible = index => {
    let next = index + 1;
    if (next === INDEXES.INDEX_AUTH && caseType === 'diversion') next++;
    next %= INDEX_LENGTH;

    const currentVisiblity = visibilities[index];

    // Skip the hidden expandos if not available
    if (
      (next === INDEXES.INDEX_BUNDLES && !canViewBundles) ||
      (next === INDEXES.INDEX_AUTH && !canViewAuths) ||
      next === INDEXES.INDEX_DELETE
    ) {
      next = (next + 1) % INDEX_LENGTH;
    }

    arr = Array(INDEX_LENGTH).fill(false);
    arr[index] = !currentVisiblity;
    arr[next] = currentVisiblity;

    setVisibilities(arr);
  };

  const readonlymsg = `${editReason} Timeline events are read only.`;
  return (
    <aside
      className={cn('caseView__aside', {
        GRCCreate: creatingCase,
      })}
      data-cy="case-aside"
    >
      <section>
        <SysAlert
          visible={readOnly}
          level="lock"
          position="relative"
          hideable={false}
        >
          {readonlymsg}
        </SysAlert>
        <ul className="action-group list--button_group">
          <ExpandoSection
            label="Case Information"
            className="action-group__action"
            icon="icon-info"
            expandoClassName="info"
            visible={visibilities[INDEXES.INDEX_INFO]}
            onClick={() => toggleVisible(INDEXES.INDEX_INFO)}
          >
            {!creatingCase && (
              <div>
                {caseType === 'GRC' ? null : (
                  <section>
                    {userId && (
                      <div className="item">
                        <h5>EMR User</h5>
                        <span>
                          <Link data-cy="emr-user-link" to={userLink}>
                            {userFullName}
                          </Link>
                        </span>
                      </div>
                    )}
                    {patientId && (
                      <div className="item">
                        <h5>Patient</h5>
                        <span>
                          {patientId ? (
                            <Link to={patientLink}>{patientFullName}</Link>
                          ) : (
                            <p>{patientFullName}</p>
                          )}
                        </span>
                      </div>
                    )}
                  </section>
                )}
                <TypeRoleForm />

                <section>
                  <div className="item">
                    <h5>Created</h5>
                    <span>
                      {moment(caseCreated)
                        .tz(timezone)
                        .format('LLL z')}
                    </span>
                  </div>
                  <div className="item">
                    <h5>Last Modified</h5>
                    <span>
                      {moment(caseLastModified)
                        .tz(timezone)
                        .format('LLL z')}
                    </span>
                  </div>
                </section>

                {canSync &&
                  caseType === 'privacy' &&
                  caseIntegration.deprecated === false && (
                    <CaseIntegrationSyncContainer />
                  )}
                <AssignResolveForm />

                <Authenticated
                  anyPermission
                  permission={['CASE_MODIFY_RESOLVED', 'CASE_MODIFY']}
                >
                  <p className="small text-center">
                    You have administrative permissions and can make changes to
                    resolved cases. All actions are recorded in the case
                    history.
                  </p>
                </Authenticated>
              </div>
            )}
          </ExpandoSection>
          {caseType !== 'GRC' && (
            <ExpandoSection
              label="User's Other Cases"
              subLabel={otherUserCases.length}
              className="action-group__action"
              icon="icon-folder"
              visible={visibilities[INDEXES.INDEX_CASES]}
              onClick={() => toggleVisible(INDEXES.INDEX_CASES)}
            >
              <CaseViewUserCases userCases={otherUserCases} />
            </ExpandoSection>
          )}
          <Authenticated permission="BUNDLE_VIEW">
            <ExpandoSection
              label="Bundles"
              subLabel={bundlesCount}
              className="action-group__action"
              icon="icon-bundle"
              expandoClassName="bundles"
              visible={visibilities[INDEXES.INDEX_BUNDLES]}
              onClick={() => toggleVisible(INDEXES.INDEX_BUNDLES)}
            >
              <CaseViewBundles
                bundles={bundles}
                bundlesRefresh={bundlesRefresh}
              />
            </ExpandoSection>
          </Authenticated>
          {canViewAuths && (
            <Authenticated permission="ACCESS_AUTH_SEARCH">
              <ExpandoSection
                label="Authorizations &amp; Permissions"
                subLabel={permissionsCount}
                className="action-group__action"
                icon="icon-verified_user"
                expandoClassName="authorizations"
                visible={visibilities[INDEXES.INDEX_AUTH]}
                onClick={() => toggleVisible(INDEXES.INDEX_AUTH)}
              >
                <CaseViewAuthPermissions
                  userId={userId}
                  patientId={patientId}
                />
                <CaseViewAuthPermissions
                  userId={userId}
                  patientId={patientId}
                  permissionView
                />
              </ExpandoSection>
            </Authenticated>
          )}
          <ExpandoSection
            label="Case Log"
            className="action-group__action"
            icon="icon-history"
            expandoClassName="log"
            visible={visibilities[INDEXES.INDEX_HISTORY]}
            onClick={() => toggleVisible(INDEXES.INDEX_HISTORY)}
          >
            <CaseHistory />
          </ExpandoSection>
          <ExpandoSection
            label="Print"
            className="action-group__action"
            icon="icon-print"
            expandoClassName="print"
            visible={visibilities[INDEXES.INDEX_PRINT]}
            onClick={() => toggleVisible(INDEXES.INDEX_PRINT)}
          >
            <CaseCustomPrintContainer />
          </ExpandoSection>
          {canDelete && (
            <ExpandoSection
              label="Delete Case"
              icon="icon-delete"
              className="text-danger action-group__action"
              expandoClassName="delete"
              visible={visibilities[INDEXES.INDEX_DELETE]}
              onClick={() => toggleVisible(INDEXES.INDEX_DELETE)}
            >
              <DeleteForm
                description="This case will be permanently deleted and cannot be recovered."
                subject="case"
                submit={onDeleteCase}
                reason
              />
            </ExpandoSection>
          )}
        </ul>
      </section>
    </aside>
  );
};

CaseViewAside.propTypes = {
  canViewAuths: PropTypes.bool,
  canViewBundles: PropTypes.bool,
  permissionsCount: PropTypes.number.isRequired,
  canDelete: PropTypes.bool,
  canSync: PropTypes.bool,
  caseType: PropTypes.string,
  caseCreated: PropTypes.string,
  caseLastModified: PropTypes.string,
  creatingCase: PropTypes.bool.isRequired,
  bundlesCount: PropTypes.number,
  bundles: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  bundlesRefresh: PropTypes.func,
  editReason: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onDeleteCase: PropTypes.func.isRequired,
  patientId: PropTypes.string,
  patientFullName: PropTypes.string,
  patientLink: PropTypes.string.isRequired,
  caseIntegration: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  userId: PropTypes.string,
  userFullName: PropTypes.string,
  userLink: PropTypes.string.isRequired,
  otherUserCases: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
};

CaseViewAside.defaultProps = {
  canViewAuths: false,
  canViewBundles: false,
  canDelete: false,
  canSync: false,
  caseType: null,
  caseCreated: null,
  caseLastModified: null,
  bundlesCount: 0,
  editReason: false,
  patientId: '',
  patientFullName: '',
  caseIntegration: {},
  userId: '',
  userFullName: '',
  otherUserCases: [],
};

export default CaseViewAside;
