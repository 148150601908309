import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import CreateStore from '../stores/CreateStore';
import CreateView from '../CreateView';
import PatientVipStore from '../../stores/PatientVipStore';
import { RegistrySearchStore, HalUtils } from 'common';

export function mapProps({
  createStore,
  patientVipStore,
  searchStore,
  location,
  match,
  HalUtils,
}) {
  return {
    store: createStore,
    patient: createStore.patient,
    setPatient: createStore.setPatient,
    isLoading: createStore.isLoading,
    pathname: location.pathname,
    paramsId: match.params.patientId,
    infoById: id => patientVipStore.infoById.get(id),
    resetCreateStore: createStore.reset,
    resetSearchStore: searchStore.reset,
    setType: searchStore.setType,
    selectedPatientId: HalUtils.getId(searchStore.store.selectedPatient),
  };
}

const CreateViewContainer = observer(({ location, match }) => {
  return (
    <CreateView
      {...mapProps({
        createStore: CreateStore,
        patientVipStore: PatientVipStore,
        searchStore: RegistrySearchStore,
        location,
        match,
        HalUtils,
      })}
    />
  );
});

CreateViewContainer.displayName = 'CreateViewContainer';

export default withRouter(CreateViewContainer);
