import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const MultiRecordCompositeFieldSectionHeader = ({
  name,
  isCountVisible,
  numGroups,
}) => (
  <>
    <h3>{name}</h3>
    {isCountVisible && <h3 className={styles.count}>{numGroups}</h3>}
  </>
);

MultiRecordCompositeFieldSectionHeader.propTypes = {
  name: PropTypes.string,
  isCountVisible: PropTypes.bool,
  numGroups: PropTypes.number,
};

export default MultiRecordCompositeFieldSectionHeader;
