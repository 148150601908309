import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import Anchor from '../Anchor';
import { FullName } from 'common';
import moment from 'moment';
import { person } from './PropTypes';

const ClockedOutRow = ({ date, user, userLink, start }) => {
  return (
    <tr className={styles.printTable}>
      <td className={styles.dateColumn}>
        <Anchor tag="a" href={userLink}>
          {moment(date).format('MM/DD/YYYY HH:mm:ss')}
        </Anchor>
      </td>
      <td>-</td>
      <td>Time Entry</td>
      <td>
        <span>
          <strong>Shift {start ? 'Start' : 'End'} </strong>
          <Anchor tag="a" href={userLink}>
            <FullName person={user} condensed />
          </Anchor>{' '}
        </span>
      </td>
    </tr>
  );
};

ClockedOutRow.propTypes = {
  date: PropTypes.string,
  start: PropTypes.string,
  user: person,
  userLink: PropTypes.string,
};

ClockedOutRow.defaultProps = {
  user: {},
};

export default ClockedOutRow;
