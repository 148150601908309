import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import moment from 'moment';
import $ from 'jquery';

import { autorun, computed, decorate, reaction } from 'mobx';
import { observer } from 'mobx-react';

import { RouterContainer } from 'common';
import CaseStore from '../../stores/CaseStore';
import MetalogStore from '../../stores/MetalogStore';
import SmartReferenceStore from '../../stores/SmartReferenceStore';
import Store from '../../stores/Store';
import ChartStore from '../../stores/ChartStore';
import EventDetailedStore from '../../stores/EventDetailedStore';
import CaseLayer from './CaseLayer';
import CircleLayer from './CircleLayer';
import LogLayer from './LogLayer';

/*
 * Method to trim the length of text in an SVG node. This is a stop-gap until
 * there's better support for CSS trimming of text elements (part of SVG2).
 *
 * NOTE: This is to ONLY be used for SVG text elements, and only until SVG has
 * better support for native truncation.
 *
 * NOTE: This is dependent on being run as part of a D3 call chain, where the
 * context is a specific D3 DOM Node.
 *
 * Example:
 *
 *   // row is a D3-appended DOM node
 *   row.append("text")
 *     .text("Some text that needs truncation")
 *     .each(trimText)
 *
 * While this may work with a DOM node provided by another source (jQuery, for
 * example), it's not guaranteed to work.
 */
function trimText() {
  const self = d3.select(this);
  const isSmartReferenceRow =
    this.parentNode.className.animVal.indexOf('smart-reference-row') > -1;

  let textLength = self.node().getComputedTextLength(),
    text = self.text();
  let width = self.attr('width');
  if (isSmartReferenceRow) width -= 40;

  while (textLength > width && text.length > 0) {
    text = text.slice(0, -1);
    self.text(`${text}...`);
    textLength = self.node().getComputedTextLength();
  }
}

const findAlias = values => values.find(v => v.user.aliasId);

/**
 * Layer responsible for drawing user/patient rows including the various sub-layers.
 * @extends React.Component
 */
const UserOnlyRowLayer = class extends React.Component {
  groupRef = createRef();

  componentDidMount() {
    this.scroll();
    setTimeout(() => this.scroll(), 500);

    this.disposers.push(
      autorun(() => {
        if (MetalogStore.filteredMetalogs && !MetalogStore.loading) {
          const { yDomain } = this;
          if (yDomain.length) SmartReferenceStore.setMap(yDomain);
          const rows = d3.select(this.groupRef.current).selectAll('g.row');
          CircleLayer.render(rows.select('g.accesses'), this.yDomainDetailed);
        }
      })
    );

    this.disposers.push(
      reaction(
        () => [
          ChartStore.brushedXScale,
          this.data,
          CaseStore.cases,
          Store.activeLabel,
          SmartReferenceStore.smartReferenceMap.size,
        ],
        () => {
          this.renderRows();
          if (CaseStore.cases.length) {
            const rows = d3.select(this.groupRef.current).selectAll('g.row');
            CaseLayer.render(rows.select('g.cases'));
          }
        },
        { fireImmediately: true }
      )
    );

    this.disposers.push(
      reaction(
        () => [ChartStore.selectedKey],
        () => {
          const rows = d3.select(this.groupRef.current).selectAll('g.row');
          this.updateSelectedRows(rows);
          this.scroll();
        }
      )
    );

    this.disposers.push(
      reaction(
        () => [EventDetailedStore.detailFocus, EventDetailedStore.flagged],
        () => {
          const rows = d3.select(this.groupRef.current).selectAll('g.row');
          LogLayer.render(rows.select('g.metalogs'));
        }
      )
    );

    this.disposers.push(
      reaction(
        () => [this.data],
        () => {
          const rows = d3.select(this.groupRef.current).selectAll('g.row');
          LogLayer.updateTooltips(rows.select('g.metalogs rect'));
        }
      )
    );
  }

  componentWillUnmount() {
    this.disposers.forEach(d => d());
  }

  // Computed
  get data() {
    return MetalogStore.userOnlyMetalogs;
  }

  // Computed
  get yDomain() {
    const arr = this.data.map(i => {
      i = Object.assign({}, i);
      i.startTimeDate = moment(i.startTime).toDate();
      i.endTimeDate = moment(i.endTime).toDate();
      return i;
    });

    return d3
      .nest()
      .key(d => ChartStore.yKey(d))
      .entries(arr || []);
  }

  // Computed
  get yDomainDetailed() {
    // concatenated the data in the 'drawer', so that it is always rendered
    const arr = [EventDetailedStore.detailView].map(i => {
      i = Object.assign({}, i);
      if (i.class === 'access') i.eventDate = moment(i.dateOfAccess).toDate();
      else if (i.class === 'order') i.eventDate = moment(i.startTime).toDate();
      else if (i.class === 'administration')
        i.eventDate = moment(i.startTime).toDate();
      else if (i.class === 'handling')
        i.eventDate = moment(i.eventTime).toDate();
      else if (i.class === 'discrepancy')
        i.eventDate = moment(i.resolutionTime).toDate();
      return i;
    });

    const nested = d3
      .nest()
      .key(d => ChartStore.yKey(d))
      .entries(arr);

    const byY = {};
    this.yDomain.forEach(a => {
      byY[a.key] = [];
    });
    nested.forEach(a => {
      byY[a.key] = a.values;
    });

    return byY;
  }

  disposers = [];

  transform() {
    return 'translate(0,0)';
  }

  label() {
    return 'User Only Events';
  }

  transition(rowData, element) {
    element.classed({ user: true });
  }

  clickedRow() {
    const params = Store.params;
    const newPath = `/activity/employee/${params.employeeId}/accesses`;

    RouterContainer.go(newPath, Store.getQuery());
  }

  isSelected() {
    return Store.focus === 'employee_accesses';
  }

  updateSelectedRows(rows) {
    return rows.classed('selected', d => this.isSelected(d));
  }

  // Manages keeping the selected row in the view
  scroll() {
    const { scrollable } = this.props;
    if (ChartStore.selectedKey) {
      const yVal = ChartStore.yScale(ChartStore.selectedKey);
      if ((yVal || yVal === 0) && yVal !== ChartStore.defaultY && scrollable) {
        const st = scrollable.scrollTop;
        let padding = 0;
        if (ChartStore.showUserOnly) padding += ChartStore.rowHeight;
        if (ChartStore.showDiversion) padding += ChartStore.rowHeight;
        if (
          yVal < st ||
          yVal >
            st + scrollable.offsetHeight - (ChartStore.rowHeight * 2 + padding)
        ) {
          const centerMultiplier = ChartStore.showDiversion ? 1 : 2;
          // yVal is not in the viewable area
          // put yVal in the center of the viewable area
          $(scrollable).scrollTop(
            yVal -
              scrollable.offsetHeight / 2 +
              ChartStore.rowHeight * centerMultiplier
          );
        }
      }
    }
  }

  renderRows = () => {
    // create a row for each item in the y domain
    if (!this.yDomain) return;

    const me = this;

    let rows = d3
      .select(this.groupRef.current)
      .selectAll('g.row')
      .data(this.yDomain, d => d.key);
    try {
      rows.exit().remove();

      const enterRow = rows
        .enter()
        .append('g')
        .classed('row', true)
        .attr('transform', d => me.transform(d))
        .attr('height', ChartStore.rowHeight)
        .on('click', d => {
          this.clickedRow(ChartStore.focusKeys(d.values[0]));
        });
      enterRow
        .append('rect')
        .classed('clickBox', true)
        .attr('width', '100%')
        .attr('height', ChartStore.rowHeight);

      enterRow
        .append('text')
        .text(d => me.label(d))
        .classed('yLabel', true)
        .attr(
          'width',
          d => ChartStore.margin.left - (findAlias(d.values) ? 40 : 17)
        )
        .attr('height', ChartStore.rowHeight)
        .attr(
          'transform',
          d =>
            `translate(${ChartStore.margin.left -
              (findAlias(d.values) ? 40 : 17)}, ${ChartStore.rowHeight / 1.6})`
          // setting font-size to 80%, 1.8 offsets
        )
        .attr('text-anchor', 'end')
        .attr('dominant-baseline', 'baseline')
        .each(trimText);

      enterRow
        .append('text')
        .text('flip')
        .classed('material-icons', true)
        .classed('hidden', d => !findAlias(d.values))
        .attr(
          'data-tippy-content',
          'Patient access occurred across multiple systems/records'
        )
        .attr('data-tippy-animation', 'fade')
        .attr('data-tippy-arrow', 'true')
        .attr('width', ChartStore.margin.left - 17)
        .attr('height', ChartStore.rowHeight)
        .attr(
          'transform',
          () =>
            `translate(${ChartStore.margin.left - 17}, ${ChartStore.rowHeight /
              1.5})`
          // setting font-size to 80%, 1.8 offsets
        )
        .attr('text-anchor', 'end');

      enterRow.append('g').classed('authorized-accesses', true);
      enterRow.append('g').classed('metalogs', true);
      enterRow.append('g').classed('accesses', true);
      enterRow.append('g').classed('cases', true);
      enterRow.append('g').classed('past-future-accesses', true);

      enterRow
        .append('text')
        .text('flag')
        .classed('material-icons', true)
        .classed('smart-reference', true)
        .attr('width', ChartStore.margin.left - 17)
        .attr('height', ChartStore.rowHeight)
        .attr(
          'transform',
          () =>
            `translate(${ChartStore.margin.left - 228}, ${ChartStore.rowHeight /
              1.3})`
        );

      // Merge existing rows and enter selection to incorporate any new additions.
      rows = rows.merge(enterRow);

      this.updateSelectedRows(rows);
      rows.classed(
        'smart-reference-row',
        d =>
          SmartReferenceStore.smartReferenceMap.has(d.key) &&
          SmartReferenceStore.correctFocus
      );

      rows.transition().each(function(d) {
        me.transition(d, d3.select(this));
      });
      rows
        .select('text.yLabel')
        .text(d => me.label(d))
        .attr(
          'width',
          d => ChartStore.margin.left - (findAlias(d.values) ? 40 : 17)
        )
        .attr(
          'transform',
          d =>
            `translate(${ChartStore.margin.left -
              (findAlias(d.values) ? 35 : 10)}, ${ChartStore.rowHeight / 1.6})`
          // setting font-size to 80%, 1.8 offsets
        )
        .each(trimText);
      rows.select('rect.clickBox').attr('width', '100%');

      rows
        .select('text.material-icons')
        .classed('hidden', d => !findAlias(d.values));

      // select any and all smart references and make sure they should still be visable, keep this outside the if so when
      // smart references are removed we will remove them from the activity view
      rows
        .selectAll('text.smart-reference')
        .classed(
          'hidden',
          d =>
            !SmartReferenceStore.smartReferenceMap.has(d.key) ||
            !SmartReferenceStore.correctFocus
        );

      LogLayer.render(rows.select('g.metalogs'));
      CircleLayer.render(rows.select('g.accesses'), this.yDomainDetailed);
    } catch (err) {
      if (err.message) console.error(err.message, err.stack);
      else console.error(err);
    }
  };

  render() {
    return <g className="rows" ref={this.groupRef} data-cy="user-row" />;
  }
};

decorate(UserOnlyRowLayer, {
  data: computed,
  yDomain: computed,
  yDomainDetailed: computed,
});

UserOnlyRowLayer.propTypes = {
  scrollable: PropTypes.shape({
    scrollTop: PropTypes.number,
    offsetHeight: PropTypes.number,
  }),
};

UserOnlyRowLayer.displayName = 'UserOnlyRowLayer';

export default observer(UserOnlyRowLayer);
