import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Pandy, Authenticated } from 'common';
import classnames from 'classnames';
import BulkVipList from '../BulkVipList';
import BulkVipNav from '../BulkVipNav';
import RegistryContainer from '../../../ui/registry/RegistryContainer';
import styles from './index.module.scss';
import { BulkVipContext } from '../Context';

const ObservedBulkVipListView = observer(({ Store }) => {
  const count = Store.size;
  const data = Store.data;

  // Redirect if refreshed or no data
  if (!Store.attachedFile && !count) {
    return <Redirect to="/vips/upload" />;
  }

  if (Store.workflowComplete) {
    return <Redirect to="/vips" />;
  }

  return (
    <div className="vip--view">
      <BulkVipNav
        status={Store.status}
        count={count}
        navTitle={Store.navTitle}
        selected={Store.selected.size}
        templateData={Store.allPatients.length}
        existingPatients={Store.existingPatients.length}
        unmatchedPatients={Store.unmatchedPatients.length}
        registerVIPs={Store.registerVIPs}
        complete={Store.complete}
        percentComplete={Store.percentComplete}
        registering={Store.registering}
        matched={Store.matchedPatients.length}
        someFailed={Store.failedRequests > 0}
        reset={Store.reset}
      />
      <Authenticated permission="VIP_REGISTRATION_SEARCH_ALL">
        <RegistryContainer
          store={Store}
          title={Store.tableTitle}
          className="registry"
          disableScrollPager
        >
          <Pandy
            visible={!count}
            mood={Store.status === 'matched' ? 'sad' : 'happy'}
          >
            <div className="speechBubble">
              <span>{`No ${Store.tableTitle} Found`}</span>
            </div>
          </Pandy>
          <div
            className={classnames('upload-table', styles.tableContainer, {
              hidden: !count,
            })}
          >
            <BulkVipList
              data={data}
              showMasterCheckbox={Store.showMatched && !Store.complete}
              toggleAll={Store.toggleAll}
              allSelected={Store.allSelected}
              mixedCheckedStatus={Store.mixedCheckedStatus}
            />
          </div>
        </RegistryContainer>
      </Authenticated>
    </div>
  );
});

const BulkVipListView = () => (
  <BulkVipContext.Consumer>
    {({ bulkVipStore: Store }) => <ObservedBulkVipListView Store={Store} />}
  </BulkVipContext.Consumer>
);
export default BulkVipListView;
