import React from 'react';
import PropTypes from 'prop-types';

import ShowHideMore from '../../ui/ShowHideMore';
import Encounter from '../../ui/Encounter';

const EncountersList = ({ encounters }) => {
  return (
    <div>
      <ShowHideMore numberToShow={2} label="Encounter">
        {encounters.map(encounter => (
          <Encounter key={encounter.id} encounter={encounter} />
        ))}
      </ShowHideMore>
    </div>
  );
};

EncountersList.propTypes = {
  encounters: PropTypes.array,
  users: PropTypes.array,
  label: PropTypes.string,
};

export default EncountersList;
