import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Authenticated, PermissionSets } from 'common';

import { BulkCaseActions, DeleteForm, ExpandoSection } from '../../ui';
import BundleDetailForm from '../BundleDetailForm';

const BundleActions = ({
  bundle,
  isDirty,
  onDelete,
  hasBundlePerm,
  loading,
  onSave,
  nameValue,
  notesValue,
  setValue,
  store,
}) => {
  const [actionsVisible, setActionsVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [infoVisible, setInfoVisible] = useState(true);

  const toggleInfo = () => {
    setActionsVisible(infoVisible);
    setInfoVisible(!infoVisible);
    setDeleteVisible(false);
  };

  const toggleActions = () => {
    setInfoVisible(actionsVisible);
    setActionsVisible(!actionsVisible);
    setDeleteVisible(false);
  };

  const toggleDelete = () => {
    setInfoVisible(deleteVisible);
    setActionsVisible(false);
    setDeleteVisible(!deleteVisible);
  };

  return (
    <section>
      <ul className="action-group action-group--dark-bg list--button_group">
        <ExpandoSection
          label="Bundle Info"
          icon="icon-info"
          className="action-group__action"
          expandoClassName="bundle-info"
          visible={infoVisible}
          onClick={toggleInfo}
        >
          <BundleDetailForm
            nameValue={nameValue}
            notesValue={notesValue}
            setValue={setValue}
            onSave={onSave}
            shouldDisable={!hasBundlePerm || loading}
            isDirty={isDirty}
          />
        </ExpandoSection>
        <Authenticated
          anyPermission
          permission={PermissionSets.bulkActions.concat(['BUNDLE_MODIFY'])}
        >
          <ExpandoSection
            label="Bundle Actions"
            icon="icon-done_all"
            className="action-group__action bundleActions--text"
            expandoClassName="bundle-actions"
            visible={actionsVisible}
            onClick={toggleActions}
          >
            <div className="bundle__actions">
              <BulkCaseActions store={store} />
            </div>
          </ExpandoSection>
        </Authenticated>
        <Authenticated permission="BUNDLE_DELETE">
          <ExpandoSection
            label="Delete Bundle"
            icon="icon-delete"
            className="action-group__action text-danger"
            expandoClassName="delete"
            visible={deleteVisible}
            onClick={toggleDelete}
            data-cy="delete-bundle--bulkAction"
          >
            <DeleteForm
              description="This bundle will be permanently deleted and cannot be recovered. The cases will not be deleted."
              subject="bundle"
              submit={() => {
                if (bundle?.id) return onDelete(bundle?.id);
              }}
            />
          </ExpandoSection>
        </Authenticated>
      </ul>
    </section>
  );
};

BundleActions.propTypes = {
  bundle: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  isDirty: PropTypes.bool,
  onDelete: PropTypes.func,
  hasBundlePerm: PropTypes.bool,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  nameValue: PropTypes.string,
  notesValue: PropTypes.string,
  setValue: PropTypes.func,
  store: PropTypes.shape({}),
};

export default BundleActions;
