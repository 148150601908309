import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ProtectedRoute, CaseBundleClient } from 'common';
import CaseViewAsideContainer from '../caseAside/CaseViewAsideContainer';
import CaseOverview from '../CaseOverview';
import { ApplyBodyClassName, DocumentTitle, Loader } from '../../ui';
import IncidentNoteAndResolutionStore from '../../stores/IncidentNoteAndResolutionStore';
import { IncidentContext } from '../../ui/IncidentGroupList/Context';
import PromptWrapper, { DEFAULT_MESSAGE } from '../../ui/PromptWrapper';
import { Observer } from 'mobx-react';
import { useEphemeralStore } from '../../utils/hooks';
import CaseBundleIndicatorStore from '../../stores/CaseBundleIndicatorStore';

const CaseView = ({
  caseId,
  setCaseId,
  failureStatus,
  failure,
  result,
  number,
  patientId,
  userId,
  caseCreatedDate,
  setIdForPatientEncounters,
  setIdForEmployeeActivity,
  setPatientEncountersPivotDate,
  caseDirty,
  caseStore,
}) => {
  const [incidentStateStore] = useState(
    IncidentNoteAndResolutionStore({
      incidentsStore: caseStore,
    })
  );

  useEffect(() => {
    if (caseId) setCaseId(caseId);

    return () => {
      setCaseId(null);
    };
  }, [setCaseId, caseId]);

  useEffect(() => {
    setIdForPatientEncounters(patientId);

    return () => {
      setIdForPatientEncounters(null);
    };
  }, [setIdForPatientEncounters, patientId]);

  useEffect(() => {
    setIdForEmployeeActivity(userId);

    return () => {
      setIdForEmployeeActivity(null);
    };
  }, [setIdForEmployeeActivity, userId]);

  useEffect(() => {
    setPatientEncountersPivotDate(caseCreatedDate);

    return () => {
      setPatientEncountersPivotDate(null);
    };
  }, [setPatientEncountersPivotDate, caseCreatedDate]);

  const caseBundleIndicatorStore = useEphemeralStore(
    () =>
      new CaseBundleIndicatorStore({
        caseId,
        caseBundleClient: CaseBundleClient,
      })
  );

  const customErrorResponses = {
    403: 'This case belongs to a group that you cannot see or is not currently active',
    404: `We couldn't find a case with that ID. Sorry!`,
  };

  // Intentionally delay rendering until case type is resolved in store
  const resolvedCaseType = ['GRC', 'diversion', 'privacy'].includes(
    caseStore?.type || ''
  );
  if (!resolvedCaseType) return null;

  return (
    <IncidentContext.Provider value={{ incidentStateStore }}>
      <main className="case_view__body">
        <DocumentTitle text={`Case ${number || 'Detail'}`} />
        <ApplyBodyClassName className="case_view" />
        <Loader
          loaded={!!result}
          customResponses={customErrorResponses}
          failure={failure}
          failureStatus={failureStatus}
        >
          <ProtectedRoute component={CaseOverview} />
          <CaseViewAsideContainer
            caseBundleIndicatorStore={caseBundleIndicatorStore}
          />
        </Loader>
      </main>
      <Observer>
        {() => (
          <PromptWrapper
            when={caseDirty || incidentStateStore.hasUnsavedNotes}
            message={location => {
              return (
                location.pathname.startsWith(`/case/${caseId}`) ||
                DEFAULT_MESSAGE
              );
            }}
          />
        )}
      </Observer>
    </IncidentContext.Provider>
  );
};

CaseView.propTypes = {
  caseId: PropTypes.string,
  setCaseId: PropTypes.func.isRequired,
  failureStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  failure: PropTypes.string,
  result: PropTypes.shape({}),
  number: PropTypes.number,
  patientId: PropTypes.string,
  userId: PropTypes.string,
  caseCreatedDate: PropTypes.string,
  setIdForPatientEncounters: PropTypes.func.isRequired,
  setIdForEmployeeActivity: PropTypes.func.isRequired,
  setPatientEncountersPivotDate: PropTypes.func.isRequired,
  caseDirty: PropTypes.bool.isRequired,
  caseStore: PropTypes.shape({}).isRequired,
};

CaseView.defaultProps = {
  failureStatus: null,
  failure: null,
  result: null,
  caseId: null,
  number: 0,
  patientId: null,
  caseCreatedDate: null,
};

CaseView.displayName = 'CaseView';

export default CaseView;
