import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import ValueOrUnknown from '../ValueOrUnknown';

const FormattedEmails = ({ emails }) => {
  return (
    <ValueOrUnknown>
      {(emails || []).map(email => (
        <p key={email}>{email}</p>
      ))}
    </ValueOrUnknown>
  );
};

FormattedEmails.propTypes = {
  emails: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
};

FormattedEmails.defaultProps = {
  emails: [],
};

export default FormattedEmails;
