import React from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';
import queryString from 'query-string';

import NavBarItem from '../../navbar/NavBarItem';
import { routerAndQueryPropTypes } from '../../utils/RouteHelper';

const dateAttrs = ['created', 'lastModified', 'minEventDate'];
const numericalAttrs = ['suspicionScore'];
const defaultDescAttrs = [...dateAttrs, ...numericalAttrs];

const AdvancedSort = ({ history, location, query, sortAttr, sortDir }) => {
  const sortDirs = [
    {
      name: dateAttrs.includes(sortAttr)
        ? 'Newest First'
        : numericalAttrs.includes(sortAttr)
        ? 'Highest First'
        : 'Z - A',
      value: 'desc',
      label: 'Order',
    },
    {
      name: dateAttrs.includes(sortAttr)
        ? 'Oldest First'
        : numericalAttrs.includes(sortAttr)
        ? 'Lowest First'
        : 'A - Z',
      value: 'asc',
      label: 'Order',
    },
  ];

  const sortAttrs = [
    { value: 'created', name: 'Created', label: 'Sort' },
    { value: 'minEventDate', name: 'Case Event Dates', label: 'Sort' },
    { value: 'userSnapshot.lastName', name: 'EMR User', label: 'Sort' },
    { value: 'lastModified', name: 'Modified', label: 'Sort' },
    { value: 'patientSnapshot.lastName', name: 'Patient', label: 'Sort' },
    { value: 'resolution', name: 'Resolution State', label: 'Sort' },
    { value: 'suspicionScore', name: 'Suspicion Score', label: 'Sort' },
  ].filter(s => s);

  function navigateOnChange(attr, dir) {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ ...query, sort: `${attr},${dir}` }),
    });
  }

  function defaultSortDirectionFor(sortAttr) {
    return defaultDescAttrs.includes(sortAttr) ? 'desc' : 'asc';
  }

  function handleSortAttrChange(e) {
    const newSortAttr = e.value;

    navigateOnChange(newSortAttr, defaultSortDirectionFor(newSortAttr));
  }

  function handleSortDirChange(e) {
    navigateOnChange(sortAttr, e.value);
  }

  return (
    <li className="advanced-sort">
      <ul className="advanced-sort__sort-list">
        <NavBarItem className="advanced-sort__sort-list-item">
          <DropdownList
            data={sortAttrs}
            valueField="value"
            textField="name"
            value={sortAttr}
            onChange={handleSortAttrChange}
            valueComponent={DropdownItem}
          />
        </NavBarItem>

        <NavBarItem className="advanced-sort__sort-list-item">
          <DropdownList
            data={sortDirs}
            valueField="value"
            textField="name"
            value={sortDir}
            onChange={handleSortDirChange}
            valueComponent={DropdownItem}
          />
        </NavBarItem>
      </ul>
    </li>
  );
};

AdvancedSort.propTypes = {
  sortAttr: PropTypes.string.isRequired,
  sortDir: PropTypes.string.isRequired,
  ...routerAndQueryPropTypes,
};

export default AdvancedSort;

function DropdownItem({ item }) {
  const { label, name } = item;

  return (
    <span>
      <strong>{label}</strong>&nbsp;
      {name}
    </span>
  );
}

DropdownItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
