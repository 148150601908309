import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { nodeIsType } from '../../utils/ComponentUtils';
import Header from './Header';
import IncidentSummary from './IncidentSummary';
import IncidentHeadline from './IncidentHeadline';
import IncidentStats from './IncidentStats';
import ExpandCollapseCard from '../ExpandCollapseCard';
import PrivacyAssessments from './PrivacyAssessments';
import UnreconciledDrugs from './UnreconciledDrugs';
import { ScrollIntoViewContext } from '../context';

const Assessment = ({ children, selected, scrollTo, clearScrollTo }) => {
  const header = children.find(node => nodeIsType(node, Assessment.Header));
  const incidentSummary = children.find(node =>
    nodeIsType(node, Assessment.IncidentSummary)
  );
  const unreconciledDrugs = children.find(node =>
    nodeIsType(node, Assessment.UnreconciledDrugs)
  );
  const incidentHeadline = children.find(node =>
    nodeIsType(node, Assessment.IncidentHeadline)
  );
  const incidentStats = children.find(node =>
    nodeIsType(node, Assessment.IncidentStats)
  );
  const privacyAssessments = children.find(node =>
    nodeIsType(node, Assessment.PrivacyAssessments)
  );

  const scrollIntoViewContext = useContext(ScrollIntoViewContext);

  const selectedRef = useCallback(
    node => {
      if (node && scrollTo && scrollIntoViewContext) {
        scrollIntoViewContext.scrollIntoView(node);
        // clear scrollTo so you don't scroll to that assessment every time you switch tabs
        clearScrollTo();
      }
    },
    [scrollTo, clearScrollTo, scrollIntoViewContext]
  );

  return (
    <ExpandCollapseCard
      selected={selected}
      ref={selectedRef}
      data-cy={selected ? 'assessment-selected' : 'assessment-not-selected'}
    >
      <ExpandCollapseCard.Header>{header}</ExpandCollapseCard.Header>
      {incidentSummary && (
        <ExpandCollapseCard.Summary>
          {incidentSummary}
        </ExpandCollapseCard.Summary>
      )}

      {incidentStats && (
        <ExpandCollapseCard.Detail>{incidentStats}</ExpandCollapseCard.Detail>
      )}

      {(unreconciledDrugs !== undefined || incidentHeadline !== undefined) && (
        <ExpandCollapseCard.Detail>
          <div>
            {unreconciledDrugs !== undefined ? unreconciledDrugs : null}
            {incidentHeadline !== undefined ? incidentHeadline : null}
          </div>
        </ExpandCollapseCard.Detail>
      )}

      {privacyAssessments && (
        <ExpandCollapseCard.Detail>
          {privacyAssessments}
        </ExpandCollapseCard.Detail>
      )}
    </ExpandCollapseCard>
  );
};

Assessment.propTypes = {
  children: function({ children }) {
    if (!Array.isArray(children)) children = [children];
    if (children.find(node => nodeIsType(node, Assessment.Header)).length < 1)
      return new Error('Other parts are optional, but Header is required');
  },
  clearScrollTo: PropTypes.func,
  selected: PropTypes.bool,
  scrollTo: PropTypes.bool,
};

Assessment.defaultProps = {
  children: [],
  clearScrollTo: () => {},
  selected: false,
  scrollTo: false,
};

export default Assessment;

Assessment.Header = Header;
Assessment.UnreconciledDrugs = UnreconciledDrugs;
Assessment.IncidentSummary = IncidentSummary;
Assessment.IncidentHeadline = IncidentHeadline;
Assessment.IncidentStats = IncidentStats;
Assessment.PrivacyAssessments = PrivacyAssessments;
