import React from 'react';
import { UnaccountedTotals } from 'common';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';

// this is a wrapper so that we could override label styles && place in inside of expand card UI
const UnreconciledDrugs = ({ incidents }) => {
  return (
    <div className="unreconciled-drugs-container" data-cy="unreconciled-drugs">
      <UnaccountedTotals incidents={incidents} />
    </div>
  );
};

UnreconciledDrugs.propTypes = {
  incidents: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})), // detailed validation already in place
};

UnreconciledDrugs.defaultProps = {
  incidents: [],
};

export default UnreconciledDrugs;
