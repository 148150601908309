export var deepReplaceValue = function deepReplaceValue(obj, regex, substitution) {
  if (typeof obj === 'string') {
    return obj.replace(regex, substitution);
  } else if (Array.isArray(obj)) {
    return obj.map(function (item) {
      return deepReplaceValue(item, regex, substitution);
    });
  } else if (typeof obj === 'object' && obj !== null) {
    var newObj = {};
    for (var key in obj) {
      newObj[key] = deepReplaceValue(obj[key], regex, substitution);
    }
    return newObj;
  }
  return obj;
};