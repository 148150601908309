import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';

import EncounterStore from '../../stores/EncounterStore';
import EncounterTooltip from '../EncounterTooltip';

const EncounterCell = class EncounterCell extends React.Component {
  // Observable
  id = null;

  fetchEnc = encounter => {
    if (encounter.id) {
      this.id = encounter.id;
      EncounterStore.fetch(encounter.id);
    }
  };

  render() {
    const { data } = this.props;
    let encounter = data.encounter;

    // attempt to get order off of order for certain medication events
    if (!encounter)
      encounter = data.medicationOrder && data.medicationOrder.encounter;

    if (!encounter) return <Cell />;

    return (
      <Cell>
        <EncounterTooltip encounterId={this.id}>
          <span
            key="details"
            onMouseOver={() => this.fetchEnc(encounter)}
            className="encounter"
          />
        </EncounterTooltip>
      </Cell>
    );
  }
};

decorate(EncounterCell, {
  id: observable,
});

EncounterCell.propTypes = {
  data: PropTypes.shape({
    encounter: PropTypes.shape({}),
    medicationOrder: PropTypes.shape({
      encounter: PropTypes.shape({}),
    }),
  }),
};

export default observer(EncounterCell);
