import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../../../ui/Checkbox';
import styles from './index.module.scss';

const FORMATTED_NAMES = {
  EMRUserAddress: 'EMR User Address',
  dob: 'Date of Birth',
  accountSecurityRole: 'Account Security Role',
  phoneNumber: 'Phone Number',
};

const PrintOptions = ({ update, isDirty, printValues, toggleValue }) => (
  <div id="settings__wrapper-Print" className="wrapper">
    <div className="card_header">
      <h1>Print Options</h1>
      <span
        className={classnames('prot-a', { muted: !isDirty })}
        onClick={update}
      >
        Save Changes
      </span>
    </div>
    <div className="content">
      <h2>Exclude when printing a case:</h2>
      <div className={styles.checkContainer}>
        {Object.keys(printValues).map(name => (
          <Checkbox
            key={name}
            checked={printValues[name]}
            color="secondary"
            onChange={() => toggleValue(name)}
            label={FORMATTED_NAMES[name]}
          />
        ))}
      </div>
    </div>
  </div>
);

PrintOptions.propTypes = {
  update: PropTypes.func.isRequired,
  toggleValue: PropTypes.func.isRequired,
  isDirty: PropTypes.bool,
  printValues: PropTypes.shape({
    EMRUserAddress: PropTypes.bool,
    dob: PropTypes.bool,
    accountSecurityRole: PropTypes.bool,
    phoneNumber: PropTypes.bool,
  }).isRequired,
};

PrintOptions.defaultProps = {
  isDirty: false,
};

PrintOptions.displayName = 'PrintOptions';

export default PrintOptions;
