import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Redirect, Switch } from 'react-router-dom';
import { reaction } from 'mobx';
import moment from 'moment';
import {
  DateHelpers,
  formatCategoryName,
  RouterContainer,
  ProtectedRoute,
} from 'common';

import CaseStore from '../stores/CaseStore';
import RelationshipStore from '../../stores/RelationshipStore';
import CaseHeader from '../CaseHeader';

import SwitcherContainer from '../subViews/SwitcherContainer';

import styles from './index.module.scss';

const NONE = <i>None</i>;

const PrintInfo = ({ data }) => {
  const {
    user,
    patient,
    created,
    createdBy,
    owner,
    resolution,
    category,
  } = data;

  const timezone = DateHelpers.getCurrentAppUserTimezone();

  const createdLbl = createdBy ? 'Case Created' : 'Case Detected';
  const createdValue =
    moment(created)
      .tz(timezone)
      .format('LLL z') + (createdBy ? ` by ${createdBy.fullName}` : '');
  const catTitle = category ? formatCategoryName(category) : NONE;

  return (
    <div className="forPrint printOverview">
      {user && (
        <dl>
          <dt>EMR User</dt>
          <dd>
            {user.firstName} {user.middleName} {user.lastName}
          </dd>
          <dt>Case Owner</dt>
          <dd>{(owner && owner.fullName) || NONE}</dd>
        </dl>
      )}
      {patient && (
        <dl>
          <dt>Patient</dt>
          <dd>
            {patient.firstName} {patient.middleName} {patient.lastName}
          </dd>
          <dt>Resolution</dt>
          <dd>{resolution || 'Unresolved'}</dd>
        </dl>
      )}
      <dl>
        <dt>{createdLbl}</dt>
        <dd>{createdValue}</dd>
        <dt>Case Type</dt>
        <dd>{catTitle}</dd>
      </dl>
    </div>
  );
};

PrintInfo.propTypes = {
  data: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    patient: PropTypes.shape({
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    created: PropTypes.string,
    createdBy: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    owner: PropTypes.shape({
      fullName: PropTypes.string,
    }),
    resolution: PropTypes.string,
    category: PropTypes.shape({}),
  }),
};

const CaseOverview = observer(
  class CaseOverview extends React.Component {
    disposers = [];

    constructor() {
      super();
      this.disposers.push(
        // used currently for updating accessEvent table when sort happens
        reaction(
          () => [RouterContainer.query],
          () => {
            CaseStore.setFilters(RouterContainer.query);
          }
        )
      );
    }

    componentDidMount() {
      if (CaseStore.userId) RelationshipStore.fetchUser(CaseStore.userId);
      if (CaseStore.patientId)
        RelationshipStore.fetchPatient(CaseStore.patientId);
      window.addEventListener('beforeunload', this.unloadListener);
    }

    componentWillUnmount() {
      CaseStore.reset();
      window.removeEventListener('beforeunload', this.unloadListener);
      this.disposers.forEach(d => d());
    }

    unloadListener = e => {
      // prompt if user tries to refresh or close page before saving
      let confirmationMessage;
      if (CaseStore.dirty) {
        confirmationMessage =
          'It looks like you have been editing something. If you leave before saving, your changes will be lost.';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
      return confirmationMessage;
    };

    render() {
      return (
        <section
          className={styles.caseSection}
          ref={node => {
            this.container = node;
          }}
        >
          <div className="print-only">
            <img
              className="float-left"
              src="assets/img/logo.png"
              alt="Protenus"
            />
            <p className="float-right">
              Report Generated
              <strong>{` ${moment().format('LL')}`}</strong>
            </p>
          </div>
          <CaseHeader
            caseNumber={CaseStore.number}
            title={CaseStore.title}
            canEditTitle={
              CaseStore.type === 'GRC' &&
              !CaseStore.resolution &&
              CaseStore.canModifyGRCCaseTitle
            }
            caseId={CaseStore.case.id}
            isGRC={CaseStore.type === 'GRC'}
          />
          <PrintInfo data={CaseStore.case} />
          <Switch>
            <ProtectedRoute
              exact
              path="/case/:caseId"
              component={SwitcherContainer}
            />
            {CaseStore.type === 'privacy' && (
              <ProtectedRoute
                exact
                path="/case/:caseId/accesses"
                component={SwitcherContainer}
              />
            )}
            <ProtectedRoute
              exact
              path="/case/:caseId/assessments"
              component={SwitcherContainer}
            />
            <ProtectedRoute
              exact
              path="/case/:caseId/incidents"
              component={SwitcherContainer}
            />
            <ProtectedRoute
              exact
              path="/case/:caseId/userDetails/"
              component={SwitcherContainer}
            />
            <Redirect to={`/case/${CaseStore.caseId}/`} />
          </Switch>
        </section>
      );
    }
  }
);

CaseOverview.displayName = 'CaseOverview';

export default CaseOverview;
