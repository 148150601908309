import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../ui';
import styles from './index.module.scss';

// UOA icons are always rotated, so we need to check the icon is UOA icon
const isUOAicon = icon =>
  icon === 'icon-call_split' || icon === 'icon-call_merge';

const CaseIconItem = ({
  icon,
  onClick,
  tooltipContent,
  route,
  rotate,
  'data-cy': dataCy,
}) => {
  const innards = route ? (
    <div>
      <Link to={route} className={styles.link} data-cy={dataCy}>
        <i className={`material-icons ${icon}`} />
      </Link>
    </div>
  ) : (
    <div className={styles.link}>
      <div
        className={
          isUOAicon(icon)
            ? icon === rotate
              ? styles.rotate270
              : styles.default
            : undefined
        }
        onClick={onClick}
        role="button"
      >
        <i className={`material-icons ${icon}`} />
      </div>
    </div>
  );
  return <Tooltip content={tooltipContent}>{innards}</Tooltip>;
};

CaseIconItem.propTypes = {
  'data-cy': PropTypes.string,
  icon: PropTypes.string.isRequired,
  route: PropTypes.string,
  onClick: PropTypes.func,
  rotate: PropTypes.bool,
  tooltipContent: PropTypes.string.isRequired,
};

CaseIconItem.defaultProps = {
  route: undefined,
  onClick: undefined,
  rotate: false,
};

export default CaseIconItem;
