import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Tooltip } from '../../../ui';
import styles from './index.module.scss';

const PersonAttribute = ({
  content,
  emptyText,
  emptyLink,
  icon,
  link,
  tooltipText,
  screenOnly,
  'data-cy': dataCy,
}) => {
  const innerContent = (
    <span data-cy={dataCy}>
      <i className={classnames('material-icons', icon)} />
      {content ? content : emptyText}
    </span>
  );

  let attributeContent;
  if (content) {
    if (link) {
      // Populated attribute with link to visit attribute
      attributeContent = <Link to={link}>{innerContent}</Link>;
    } else {
      // Populated attribute without link to visit attribute
      attributeContent = innerContent;
    }
  } else {
    if (emptyLink) {
      // No attribute with link to page to create attribute
      attributeContent = (
        <Link to={emptyLink}>
          <Tooltip content={tooltipText}>{innerContent}</Tooltip>
        </Link>
      );
    } else {
      // No attribute and no ability to create attribute
      attributeContent = innerContent;
    }
  }

  return (
    <li
      className={classnames(styles.attribute, {
        forScreen: screenOnly || !content,
        [styles.empty]: !content,
      })}
    >
      {attributeContent}
    </li>
  );
};

PersonAttribute.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    ),
  ]),
  'data-cy': PropTypes.string,
  emptyText: PropTypes.string,
  emptyLink: PropTypes.string,
  icon: PropTypes.string.isRequired,
  link: PropTypes.string,
  tooltipText: PropTypes.string,
  screenOnly: PropTypes.bool,
};

export default PersonAttribute;
