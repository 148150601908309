import BulkVipIntro from '../BulkVipIntro';
import { BulkVipContext } from '../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';

export function mapProps({ bulkVipStore }) {
  return {
    onFileChange: bulkVipStore.onFileChange,
    downloadTemplate: bulkVipStore.downloadTemplate,
    loading: bulkVipStore.loading,
    attachedFile: bulkVipStore.attachedFile,
    invalidUpload: bulkVipStore.failedUploadMessage,
    errors: bulkVipStore.errors,
    reset: bulkVipStore.reset,
    registerVIPs: bulkVipStore.registerVIPs,
    uploadComplete: bulkVipStore.uploadComplete,
  };
}

const BulkVipContainer = observerWrappedContainerFactory({
  Context: BulkVipContext,
  mapProps: mapProps,
  PresentationalComponent: BulkVipIntro,
});

BulkVipContainer.displayName = 'BulkVipContainer';

export default BulkVipContainer;
