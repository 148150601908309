import moment from 'moment';

const renderDates = (minDate, maxDate, isTag, stripTZ = false) => {
  let dateClause = '';

  if (minDate && maxDate) {
    const renderedDate = date =>
      (stripTZ ? moment.utc(date) : moment(date)).format('l');
    // strip timezone for GRC cases
    const minDateStr = renderedDate(minDate);
    const maxDateStr = renderedDate(maxDate);

    // formatting flag for template tag
    const formattedMinDate = isTag ? minDateStr : `on ${minDateStr}`;
    const formattedMinMaxDate = isTag
      ? `${minDateStr} to ${maxDateStr}`
      : `between ${minDateStr} and ${maxDateStr}`;

    if (minDateStr === maxDateStr) {
      dateClause = formattedMinDate;
    } else {
      dateClause = formattedMinMaxDate;
    }
  }

  return dateClause;
};

const renderTitle = (synopsis, minDate, maxDate, stripTZ = false) =>
  `${synopsis} ${renderDates(minDate, maxDate, false, stripTZ)}`;

export default {
  renderDates,
  renderTitle,
  title: renderTitle,
};
