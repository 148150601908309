import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const PrintTitle = ({ children }) => {
  return <h2 className={styles.printTitle}>{children}</h2>;
};

PrintTitle.propTypes = {
  children: PropTypes.node,
};

export default PrintTitle;
