import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { VisibleSystemStore } from 'common';
import TopNav from '../Filter';

export function mapProps({
  VisibleSystemStore,
  query,
  location,
  history,
  useTopNav,
}) {
  return {
    quickFilterOptions: VisibleSystemStore.quickFilterOptions,
    savedQuickFilterOptions: VisibleSystemStore.savedQuickFilterOptions,
    handleUpdateQuickFilterOptions:
      VisibleSystemStore.handleUpdateQuickFilterOptions,
    hasMultipleSystems: VisibleSystemStore.hasMultipleSystems,
    setQuickFilterOptionsFromQueryParams:
      VisibleSystemStore.setQuickFilterOptionsFromQueryParams,
    handleSetSavedQuickFilterOptions:
      VisibleSystemStore.handleSetSavedQuickFilterOptions,
    allSelected: VisibleSystemStore.allSelected,
    query,
    pathname: location.pathname,
    historyReplace: history.replace,
    useTopNav,
    sources: VisibleSystemStore.sources,
    showRememberFilterButton: !VisibleSystemStore.isSavedFilterInSyncWithCurrent,
    isCaseView: false,
    hasSystemParams: location?.search?.includes('&system'),
  };
}

const FilterContainer = observer(
  ({ query, location, history, useTopNav = true }) => (
    <TopNav
      {...mapProps({
        VisibleSystemStore,
        query,
        location,
        history,
        useTopNav,
      })}
    />
  )
);

FilterContainer.displayName = 'FilterContainer';

FilterContainer.propTypes = {
  useTopNav: PropTypes.bool,
};

export default withRouterAndQuery(FilterContainer);
