import { action, computed, decorate, observable } from 'mobx';
import {
  CASEFLOW_ACTIONS,
  CASEFLOW_ALL,
  CASEFLOW_ATTACHMENTS,
  CASEFLOW_CUSTOM,
  CASEFLOW_EMAILS,
  CASEFLOW_FINAL_ASSESSMENTS,
  CASEFLOW_NONE,
  CASEFLOW_NOTES,
  CASEFLOW_RESOLUTION,
  DETAILS_ALL,
  DETAILS_NONE,
} from './consts';

class CaseCustomPrintStore {
  // observables
  selectedCaseflowOption = CASEFLOW_ALL;
  caseflowFieldsToInclude = [];
  selectedDetailsOption = DETAILS_ALL;

  // statics
  allCaseflowFields = [
    { name: 'Attachments', value: CASEFLOW_ATTACHMENTS },
    { name: 'Actions', value: CASEFLOW_ACTIONS },
    { name: 'Emails', value: CASEFLOW_EMAILS },
    { name: 'Notes', value: CASEFLOW_NOTES },
    { name: 'Final Assessments', value: CASEFLOW_FINAL_ASSESSMENTS },
    { name: 'Resolution', value: CASEFLOW_RESOLUTION },
  ];
  allCaseflowOptions = [
    { name: 'All', value: CASEFLOW_ALL },
    { name: 'Custom', value: CASEFLOW_CUSTOM },
    { name: 'None', value: CASEFLOW_NONE },
  ];
  allDetailOptions = [
    { name: 'All', value: DETAILS_ALL },
    { name: 'None', value: DETAILS_NONE },
  ];

  // computeds
  get caseflowOptions() {
    return this.allCaseflowOptions;
  }

  get detailsOptions() {
    return this.allDetailOptions;
  }

  get caseflowFields() {
    return this.allCaseflowFields.filter(
      field => !this.caseflowFieldsToInclude.includes(field)
    );
  }

  // actions
  teardown = () => {
    this.selectedCaseflowOption = CASEFLOW_ALL;
    this.caseflowFieldsToInclude = [];
    this.selectedDetailsOption = DETAILS_ALL;
  };

  handleCaseflowOnChange = opt => (this.selectedCaseflowOption = opt.value);

  handleDetailsOnChange = opt => (this.selectedDetailsOption = opt.value);

  setCaseflowFieldsToInclude = values =>
    (this.caseflowFieldsToInclude = values);
}

decorate(CaseCustomPrintStore, {
  selectedCaseflowOption: observable,
  selectedDetailsOption: observable,
  caseflowFieldsToInclude: observable,
  caseflowOptions: computed,
  caseflowFields: computed,
  detailsOptions: computed,
  teardown: action,
  handleCaseflowOnChange: action,
  handleDetailsOnChange: action,
  setCaseflowFieldsToInclude: action,
});

export { CaseCustomPrintStore };
