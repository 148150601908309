import React from 'react';
import { observer } from 'mobx-react';
import PinnedTimezone from '../PinnedTimezone';
import PinnedTimezoneStore from '../../../stores/PinnedTimezoneStore';

export function mapProps({ PinnedTimezoneStore }) {
  return {
    isDirty: PinnedTimezoneStore.isDirty,
    pinnedTimezoneSettingsEditValue:
      PinnedTimezoneStore.pinnedTimezoneSettingsEditValue,
    update: PinnedTimezoneStore.updateSettings,
    updateTimezone: PinnedTimezoneStore.updateTimezone,
  };
}

const PinnedTimezoneContainer = observer(() => {
  return <PinnedTimezone {...mapProps({ PinnedTimezoneStore })} />;
});

PinnedTimezoneContainer.displayName = 'PinnedTimezoneContainer';

export default PinnedTimezoneContainer;
