import { autorun, computed, decorate, observable } from 'mobx';

import {
  AutomatedEmailStore,
  EmailTemplateClient,
  HalUtils,
  PermissionStore,
  SingletonStore,
} from 'common';

import CaseStore from '../CaseStore';

class EmailTemplateStore extends SingletonStore {
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  selected = null;

  // Computed
  get href() {
    return HalUtils.getSelf(this.selected);
  }

  // Computed
  get canModifyTemplates() {
    return PermissionStore.has('CASE_MODIFY_EMAIL_TEMPLATES');
  }

  // Computed
  get canSendEmail() {
    return (
      (PermissionStore.has('CASE_SEND_EMAIL') ||
        PermissionStore.has('CASE_SEND_EMAIL_OWNED')) &&
      AutomatedEmailStore.address
    );
  }

  // Computed
  get template() {
    return this.result;
  }

  fetch() {
    if (this.selected === null) return [];
    if (this.canSendEmail)
      return EmailTemplateClient.find(HalUtils.getId(this.selected));
  }

  sendEmail(email) {
    return EmailTemplateClient.sendEmail(CaseStore.href, email);
  }

  create(temp) {
    return EmailTemplateClient.create(temp).then(id => {
      return id;
    });
  }

  update(updated) {
    return EmailTemplateClient.update(this.href, updated).then(() => {
      this.refresh();
    });
  }

  delete() {
    return EmailTemplateClient.delete(this.href);
  }
}

decorate(EmailTemplateStore, {
  selected: observable,
  href: computed,
  canModifyTemplates: computed,
  canSendEmail: computed,
  template: computed,
});

export default new EmailTemplateStore();
