import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { computed, decorate } from 'mobx';
import classnames from 'classnames';

import BulkCaseActionHelpText from '../BulkCaseActionHelpText';

class RemoveFromBundle extends Component {
  static propTypes = {
    store: PropTypes.shape({
      removeCases: PropTypes.func,
      selected: mobxPropTypes.observableMap,
      performingBulkAction: PropTypes.bool,
    }).isRequired,
    action: PropTypes.string,
    hidden: PropTypes.bool,
  };

  onSubmit = e => {
    e.preventDefault();
    const { store } = this.props;

    if (!this.valid) return;

    store.removeCases(store.selected);
  };

  // Computed
  get valid() {
    const { store } = this.props;

    return store.selected.size > 0;
  }

  render() {
    const { store } = this.props,
      { selected, performingBulkAction } = store;

    return (
      <form className="form" onSubmit={this.onSubmit}>
        <ul>
          <BulkCaseActionHelpText
            store={store}
            hidden={!this.valid}
            helpText={`${selected.size} case${
              selected.size === 1 ? '' : 's'
            } will be removed from this bundle and this action cannot be undone.`}
          />

          <li>
            <button
              type="submit"
              className={classnames(
                'button--block removeCases--bundleAction--button',
                {
                  hidden: !this.valid,
                }
              )}
              disabled={!this.valid || performingBulkAction}
            >
              Remove {selected.size} Case{selected.size === 1 ? '' : 's'} from
              this bundle
            </button>
          </li>
        </ul>
      </form>
    );
  }
}

decorate(RemoveFromBundle, {
  valid: computed,
});

export default observer(RemoveFromBundle);
