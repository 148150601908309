import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';
import { Cell } from 'fixed-data-table-2';

const DOB = ({ rows, rowKey, rowIndex }) => {
  const patient = rows[rowIndex][rowKey];
  let dob;
  if (patient && patient.dateOfBirth) {
    const m = moment(patient.dateOfBirth).utc();
    dob = m.format('l');
  }
  return <Cell>{dob}</Cell>;
};

DOB.propTypes = {
  rows: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      inputPatient: PropTypes.shape({
        dateOfBirth: PropTypes.string,
      }),
      matchedPatient: PropTypes.shape({
        dateOfBirth: PropTypes.string,
      }),
    })
  ),
  rowIndex: PropTypes.number,
  rowKey: PropTypes.string,
};

DOB.defaultProps = {
  rows: [
    {
      inputPatient: null,
      matchedPatient: null,
    },
  ],
  rowIndex: null,
  rowKey: null,
};

export default DOB;
