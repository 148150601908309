import { action, autorun, computed, decorate, observable } from 'mobx';
import React from 'react';
import {
  SingletonStore,
  VipClient,
  HalUtils,
  PermissionStore,
  RouterContainer,
} from 'common';
import NotificationStore from '../../../stores/NotificationStore';
import moment from 'moment';

const checkName = val => val || '(Unknown)';

class VipStore extends SingletonStore {
  constructor({ vipClient }) {
    super();

    this.vipClient = vipClient;

    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  id;

  // Action
  setValue = (type, val) => {
    this[type] = val;
  };

  // Computed
  get patient() {
    return this.result && this.result.patient;
  }

  // computed
  get created() {
    return this.result?.created;
  }

  // computed
  get createdByFullName() {
    return this.result?.createdBy?.fullName || 'Protenus';
  }

  // computed
  get lastModified() {
    return this.result?.lastModified;
  }

  // computed
  get lastModifiedByFullName() {
    return this.result?.lastModifiedBy?.fullName;
  }

  // computed
  get tags() {
    return this.result?.formattedTypes;
  }

  // Computed
  get patientName() {
    return this.patient
      ? `${checkName(this.patient.firstName)} ${checkName(
          this.patient.lastName
        )}`
      : 'Unknown';
  }

  // Computed
  get href() {
    return HalUtils.getSelf(this.result);
  }

  // Computed
  get reason() {
    return this.result && this.result.reason;
  }

  // Computed
  get canView() {
    return PermissionStore.has('VIP_REGISTRATION_VIEW');
  }

  // Computed
  get endDate() {
    return (this.result && this.result.endDate) || null;
  }

  // Computed
  get status() {
    return this.result && this.result.status;
  }

  fetch() {
    if (this.id && this.canView) {
      return this.vipClient.find(this.id, { projection: 'full' });
    }
  }

  update = ({ expireNow, ...updates }) => {
    if (expireNow) {
      updates.endDate = moment
        .utc()
        .startOf('day')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    }
    return this.vipClient.update(this.href, updates).then(
      () => {
        this.refresh();
        const content = (
          <span>
            <i className="material-icons icon-check_circle" />
            Successfully updated VIP Registration
          </span>
        );
        NotificationStore.add({ level: 'success', content });

        if (!expireNow) {
          RouterContainer.go('/vips');
        }
      },
      () => {
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            There was a problem updating this VIP Registration
          </span>
        );
        NotificationStore.add({ level: 'warning', content });
      }
    );
  };
}

decorate(VipStore, {
  id: observable,
  created: computed,
  createdByFullName: computed,
  lastModified: computed,
  lastModifiedByFullName: computed,
  patient: computed,
  patientName: computed,
  href: computed,
  reason: computed,
  canView: computed,
  endDate: computed,
  status: computed,
  tags: computed,
  setValue: action,
});

export { VipStore };
export default new VipStore({ vipClient: VipClient });
