import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { GroupStore } from 'common';
import TopNav from '../Filter';

export function mapProps({ GroupStore, query, location, history, useTopNav }) {
  return {
    quickFilterOptions: GroupStore.quickFilterOptions,
    savedQuickFilterOptions: GroupStore.savedQuickFilterOptions,
    handleUpdateQuickFilterOptions: GroupStore.handleUpdateQuickFilterOptions,
    hasMultipleGroups: GroupStore.hasMultipleGroups,
    setQuickFilterOptionsFromQueryParams:
      GroupStore.setQuickFilterOptionsFromQueryParams,
    handleSetSavedQuickFilterOptions:
      GroupStore.handleSetSavedQuickFilterOptions,
    query,
    pathname: location.pathname,
    historyReplace: history.replace,
    useTopNav,
    showRememberFilterButton: !GroupStore.isSavedFilterInSyncWithCurrent,
  };
}

const FilterContainer = observer(
  ({ query, location, history, useTopNav = true }) => (
    <TopNav
      {...mapProps({
        GroupStore,
        query,
        location,
        history,
        useTopNav,
      })}
    />
  )
);

FilterContainer.displayName = 'FilterContainer';

FilterContainer.propTypes = {
  useTopNav: PropTypes.bool,
};

export default withRouterAndQuery(FilterContainer);
