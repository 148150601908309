import React from 'react';
import {
  AllUsersStore,
  FlashBannerStore,
  PermissionStore,
  VipClient,
} from 'common';
import { BulkActionsContext } from '../Context';
import { VipsStore } from '../stores/VipsStore';
import BulkActionsContainer from '../BulkActionsContainer';
import NotificationStore from '../../stores/NotificationStore';

class BulkActionsRoot extends React.Component {
  state = {
    vipsStore: new VipsStore({
      allUsersStore: AllUsersStore,
      flashBannerStore: FlashBannerStore,
      permissionStore: PermissionStore,
      notificationStore: NotificationStore,
      vipClient: VipClient,
    }),
  };

  render() {
    return (
      <BulkActionsContext.Provider value={this.state}>
        <BulkActionsContainer />
      </BulkActionsContext.Provider>
    );
  }
}

export default BulkActionsRoot;
