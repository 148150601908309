import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Multiselect } from 'react-widgets';
import classnames from 'classnames';

import { filterOptionsPropType } from '../../../vip/VipsFilters/propTypes';
import filtersStyles from '../index.module.scss';
import styles from './index.module.scss';
import ExclusionButton from '../ExclusionToggle';
import { useReactiveRef } from '../../../utils/hooks';

/**
 * A multiselect form field that can be toggled between inclusion and exclusion.
 *
 * It is possible to provide two shapes of props -
 * 1. Provide options, label, value, and isExcluding directly.
 * 2. Provide data and filters which contain the above properties based on
 *    data.param.
 *
 * @param data
 * @param options
 * @param label
 * @param onToggleExclusion
 * @param filters
 * @param value
 * @param isExcluding
 * @param onChange
 * @param valueField
 * @param textField
 * @param dropUp
 * @param disabled
 * @param groupBy
 * @param groupComponent
 * @param inclusionTerm
 * @param exclusionTerm
 * @param hideExclusion
 * @param hideExclusionSpacing
 * @param multiselectProps
 * @return {JSX.Element}
 * @constructor
 */
const ExclusionMultiselect = ({
  data, // {options, param, label} - only use if providing filters as well.
  options,
  label,
  onToggleExclusion,
  filters, // {[param]: value, [paramExclude]: boolean}
  value, // only use if not providing filters
  isExcluding, // only use if not providing filters
  onChange,
  valueField,
  textField,
  dropUp,
  disabled,
  groupBy,
  groupComponent,
  inclusionTerm,
  exclusionTerm,
  hideExclusion,
  hideExclusionSpacing,
  multiselectProps,
  'data-cy': dataCy,
}) => {
  // There are two ways to use this component - providing data and filters, or
  // directly providing options, label, value, and isExcluding.
  // Check to ensure that we only have one set of props to use.
  if (data && (options || label)) {
    console.warn('ExclusionMultiselect: only provide data or options & label');
  }
  if (filters && (value !== undefined || isExcluding !== undefined)) {
    console.warn(
      'ExclusionMultiselect: only provide filters or value & isExcluding'
    );
  }

  let param;
  if (data) {
    options = data.options;
    param = data.param;
    label = data.label;
  }

  if (filters) {
    value = (filters[param] || []).slice();
    isExcluding = filters[`${param}Exclude`];
  }

  const [ref, refCallback] = useReactiveRef();
  useEffect(() => {
    if (ref) {
      // Apply styles to the first tag rendered to make space for the label.
      // The multiselect component makes it difficult to inject custom styling
      // within the tags.
      const label = ref.querySelector('label');
      const list = ref.querySelector('.rw-multiselect-taglist');
      const firstTag = ref.querySelector('.rw-multiselect-tag');

      if (label?.clientWidth > 100 && list) {
        list.style.marginTop = '26px';
      } else if (label && firstTag) {
        firstTag.style.marginLeft = `${label.clientWidth + 16}px`;
      }
    }
  });

  let labelTerm = isExcluding ? exclusionTerm : inclusionTerm;
  if ('string' === typeof labelTerm) {
    labelTerm = (
      <span>
        {label} <strong>{labelTerm}</strong>
      </span>
    );
  }

  return (
    <div
      className={classnames(
        filtersStyles.excludable,
        isExcluding ? styles.excluding : styles.including,
        {
          [styles.hideExclusion]: hideExclusion,
          [styles.hideExclusionSpacing]: hideExclusionSpacing,
        }
      )}
      ref={refCallback}
      data-cy={dataCy}
    >
      {!hideExclusion && (
        <ExclusionButton
          isDisabled={value.length === 0 || disabled}
          isExcluding={isExcluding}
          toggleFilter={onToggleExclusion}
        />
      )}

      {value.length > 0 && (
        <label className={styles.innerLabel}>{labelTerm}</label>
      )}

      <Multiselect
        data={options}
        value={value}
        onChange={onChange}
        placeholder={label}
        valueField={valueField}
        textField={textField}
        dropUp={dropUp}
        disabled={disabled}
        groupBy={groupBy}
        groupComponent={groupComponent}
        {...multiselectProps}
      />
    </div>
  );
};

ExclusionMultiselect.propTypes = {
  data: filterOptionsPropType,
  options: PropTypes.array,
  label: PropTypes.string,
  onToggleExclusion: PropTypes.func,
  filters: PropTypes.shape({}),
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]),
  isExcluding: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  valueField: PropTypes.string,
  textField: PropTypes.string,
  dropUp: PropTypes.bool,
  disabled: PropTypes.bool,
  groupBy: PropTypes.func,
  groupComponent: PropTypes.elementType,
  inclusionTerm: PropTypes.node,
  exclusionTerm: PropTypes.node,
  hideExclusion: PropTypes.bool,
  hideExclusionSpacing: PropTypes.bool,
  multiselectProps: PropTypes.object,
  'data-cy': PropTypes.string,
};

ExclusionMultiselect.defaultProps = {
  dropUp: false,
  disabled: false,
  inclusionTerm: 'Is',
  exclusionTerm: 'Is Not',
  hideExclusion: false,
  hideExclusionSpacing: false,
  multiselectProps: {},
};

export default ExclusionMultiselect;
