import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Cell } from 'fixed-data-table-2';
import EventDetailedStore from '../stores/EventDetailedStore';

class SortHeaderCell extends React.Component {
  sortLogs() {
    EventDetailedStore.changeSort();
  }

  render() {
    const { sortDir } = EventDetailedStore;

    return (
      <Cell
        className="registry__table-header table-header-sortable"
        onClick={this.sortLogs.bind(this)}
        data-cy="events-date-sort"
      >
        {this.props.children}
        <i
          className={classNames('material-icons', {
            'icon-arrow_upward': sortDir === 'asc',
            'icon-arrow_downward': sortDir === 'desc',
          })}
        />
      </Cell>
    );
  }
}

SortHeaderCell.propTypes = {
  children: PropTypes.node,
};

export default SortHeaderCell;
