import React from 'react';
import { observer } from 'mobx-react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute, RouterContainer } from 'common';
import DetailView from '../DetailView';
import AuthListView from '../AuthListView';
import CreateAuthViewContainer from '../CreateAuthViewContainer';

import AuthorizationsStore from '../stores/AuthorizationsStore';

import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import ApplyBodyClassName from '../../ui/ApplyBodyClassName';

const AuthRootView = observer(
  class AuthRootView extends React.Component {
    routes() {
      return (
        <>
          <ApplyBodyClassName className="authorizations" />
          <Switch>
            <ProtectedRoute
              exact
              path="/authorizations/create"
              component={CreateAuthViewContainer}
            />
            <ProtectedRoute
              exact
              path="/authorizations/create/patient/:patientId"
              component={CreateAuthViewContainer}
            />
            <ProtectedRoute
              exact
              path="/authorizations/create/patient/:patientId/employee/:employeeId"
              component={CreateAuthViewContainer}
            />
            <ProtectedRoute
              exact
              path="/authorizations/create/employee/:employeeId"
              component={CreateAuthViewContainer}
            />
            <ProtectedRoute
              exact
              path="/authorizations/create/employee/:employeeId/patient/:patientId"
              component={CreateAuthViewContainer}
            />
            <ProtectedRoute
              exact
              path="/authorizations/temporary"
              component={AuthListView}
            />
            <ProtectedRoute
              exact
              path="/authorizations/temporary/:id"
              key="temporary"
              component={DetailView}
            />
            <ProtectedRoute
              exact
              path="/authorizations/:id"
              key="authorizations"
              component={DetailView}
            />
            <ProtectedRoute component={AuthListView} />
          </Switch>
        </>
      );
    }

    nav() {
      if (
        RouterContainer.path === '/authorizations' ||
        RouterContainer.path === '/authorizations/temporary'
      ) {
        return (
          <TopNavBar filterBar>
            <NavBarItem route={AuthorizationsStore.path(false)}>
              Authorizations
            </NavBarItem>
            <NavBarItem route={AuthorizationsStore.path(true)}>
              Temporary Permissions
            </NavBarItem>
          </TopNavBar>
        );
      }
    }

    render() {
      return (
        <main>
          {this.nav()}
          {this.routes()}
        </main>
      );
    }
  }
);

export default AuthRootView;
