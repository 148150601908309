import { autorun, computed, decorate } from 'mobx';

import { SingletonStore, EncounterClient } from 'common';
import Store from '../Store';

class EncounterStore extends SingletonStore {
  constructor() {
    super();

    autorun(() => {
      const focus = Store.focus;
      const pid = Store.patientId;

      if (focus === 'patient' && pid) this.refresh();
      else this.clear();
    });
  }

  fetch() {
    if (Store.patientId) {
      return EncounterClient.getByPatient(
        Store.patientId,
        Store.fromDate,
        Store.toDate.clone().add(1, 'days')
      );
    }
  }

  // Computed
  get encounters() {
    const r = this.result;
    return (r && r._embedded && r._embedded.encounter) || [];
  }
}

decorate(EncounterStore, {
  encounters: computed,
});

export default new EncounterStore();
