import React from 'react';
import PropTypes from 'prop-types';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';

class BulkCaseActionBase extends React.Component {
  static propTypes = {
    store: PropTypes.shape({
      performBulkAction: PropTypes.func.isRequired,
      performingBulkAction: PropTypes.bool.isRequired,
      percentComplete: PropTypes.number.isRequired,
    }),
  };

  // Observable
  dirty = false;

  onChange(val, dirty) {
    this.dirty = dirty;
  }
}

decorate(BulkCaseActionBase, {
  dirty: observable,
});

export default observer(BulkCaseActionBase);
