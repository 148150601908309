import React from 'react';
import { observer } from 'mobx-react';
import CaseStore from '../../stores/CaseStore';
import UserPatientDetails from '../UserPatientDetails';

export function mapProps(CaseStore) {
  const {
    case: { user, userSnapshot, patient, patientSnapshot },
    personLink,
    userId,
    patientId,
    type,
    selectedDetailsOption,
  } = CaseStore;
  return {
    user,
    userSnapshot,
    patient,
    patientSnapshot,
    personLink,
    userId,
    patientId,
    isDiversion: type === 'diversion',
    selectedDetailsOption,
  };
}

const UserPatientDetailsContainer = () => (
  <UserPatientDetails {...mapProps(CaseStore)} />
);

export default observer(UserPatientDetailsContainer);
