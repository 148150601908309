import {
  COMPARE_EMPLOYEE_PATIENT,
  COMPARE_PATIENT_EMPLOYEE,
  DOUBLE_EMPLOYEE_PATIENT,
  DOUBLE_PATIENT_EMPLOYEE,
  EMR_EMPLOYEE_INCIDENTS,
  EMR_EMPLOYEE_ONLY_ACCESSES,
  SINGLE_EMPLOYEE,
  SINGLE_PATIENT,
} from '../const';
import queryString from 'query-string';

export function getPageMode(path) {
  // the patient employee side by side doesn't have activity in the path 'compare view' with patient on the left
  if (/^\/patient\//.test(path)) return COMPARE_PATIENT_EMPLOYEE;
  // compare view with EMR User on the left
  if (/^\/employee\//.test(path)) return COMPARE_EMPLOYEE_PATIENT;
  // patient careflow and patient detail
  if (/^\/activity\/patient\/.*\/employee/.test(path))
    return DOUBLE_PATIENT_EMPLOYEE;
  if (/^\/activity\/patient\/.*/.test(path)) return SINGLE_PATIENT;
  // EMR User Only Accesses
  if (/^\/activity\/employee\/.*\/accesses/.test(path))
    return EMR_EMPLOYEE_ONLY_ACCESSES;
  // Diversion incidents and analysis
  if (/^\/activity\/employee\/.*\/incidents/.test(path))
    return EMR_EMPLOYEE_INCIDENTS;
  // EMR user workflow and user detail
  if (/^\/activity\/employee\/.*\/patient/.test(path))
    return DOUBLE_EMPLOYEE_PATIENT;
  if (/^\/activity\/employee\/.*/.test(path)) return SINGLE_EMPLOYEE;

  return null;
}

export const generateSubnavLinks = (path, employeeId, patientId, query) => {
  const params = queryString.stringify(query);

  return [
    {
      route:
        getPageMode(path) === SINGLE_EMPLOYEE
          ? `/activity/employee/${employeeId}?${params}`
          : `/activity/employee/${employeeId}/patient/${patientId}?${params}`,
      active:
        /^\/activity\/employee\/.*\/patient/.test(path) ||
        /^\/activity\/employee/.test(path),
      disabled:
        getPageMode(path) === SINGLE_EMPLOYEE
          ? !employeeId
          : !employeeId || !patientId,
      text: 'EMR User Workflow',
    },
    {
      route: `/employee/${employeeId}/patient/${patientId}?${params}`,
      active: /^\/employee\//.test(path) || /^\/patient\//.test(path),
      disabled: !employeeId || !patientId,
      text: 'Compare',
    },
    {
      route:
        getPageMode(path) === SINGLE_PATIENT
          ? `/activity/patient/${patientId}?${params}`
          : `/activity/patient/${patientId}/employee/${employeeId}?${params}`,
      active:
        /^\/activity\/patient\/.*\/employee/.test(path) ||
        /^\/activity\/patient/.test(path),
      disabled:
        getPageMode(path) === SINGLE_PATIENT
          ? !patientId
          : !employeeId || !patientId,
      text: 'Patient Careflow',
    },
  ];
};
