import {
  BACKFILL_PATH_OPTION,
  INGEST_PATH_OPTION,
  NOT_APPLICABLE,
} from './const';
import moment from 'moment';

export const DELIVERY_AFTER_STORAGE_KEY = 'deliveryAfter';
export const DELIVERY_BEFORE_STORAGE_KEY = 'deliveryBefore';
export const DEFAULT_DELIVERY_RANGE = 'week';
export const DEFAULT_DELIVERY_BEFORE = 'P0D';
export const DEFAULT_DELIVERY_AFTER = 'P-7D';

export const DEFAULT_DELIVERY_AFTER_DATE = moment().subtract(1, 'weeks');
export const DEFAULT_DELIVERY_BEFORE_DATE = moment();

const START_OF_RANGE_OFFSET = 'T00:00:00Z';
const END_OF_RANGE_OFFSET = 'T23:59:59Z';

/**
 *
 * This processes string for regex parameter for find endpoint
 * We can add in more regex rules here if need to down the road
 *
 * @param path
 * @param source
 * @param name
 * @returns {string|*}
 */
export const processRegexParam = (path, source, name) => {
  const excludedPrefixes = '^(?!mongodb).*';

  // /* eslint-disable no-useless-escape */
  const excludedSuffixes = '(?<!\\.avro)$';
  /* eslint-enable no-useless-escape */

  let searchString;

  switch (path) {
    case 'Ingest':
      if (!source && !name) {
        searchString = `^(?!.*backfill).*`;
      } else if (!source && name) {
        searchString = `^(?!.*backfill).*/.*/.*${name}.*`;
      } else if (source && !name) {
        searchString = `^(?!.*backfill).*/${source}/.*`;
      } else {
        searchString = `^(?!.*backfill).*/${source}/.*${name}.*`;
      }
      break;
    case 'Backfill':
      if (!source && !name) {
        searchString = `ingest/.*/backfill/.*`;
      } else if (!source && name) {
        searchString = `ingest/.*/backfill/.*${name}.*`;
      } else if (source && !name) {
        searchString = `ingest/${source}/backfill/.*`;
      } else {
        searchString = `ingest/${source}/backfill/.*${name}.*`;
      }
      break;
    default:
      if (!source && !name) {
        searchString = ``;
      } else if (!source && name) {
        searchString = `/.*${name}.*`;
      } else if (source && !name) {
        searchString = `${source}/.*`;
      } else {
        searchString = `${source}/.*${name}.*`;
      }
      break;
  }

  return `${excludedPrefixes}${searchString}${excludedSuffixes}`;
};

/**
 * Derives a path value (Ingest or Backfill) from a given location or source regex string
 *
 * @param {string} location       the location whose path to derive
 * @param {string} defaultValue   the default value to return if no path is found
 */
export const getPathFromLocation = (
  location,
  defaultValue = NOT_APPLICABLE
) => {
  return location.includes('ingest/')
    ? location.includes('/backfill/')
      ? BACKFILL_PATH_OPTION
      : INGEST_PATH_OPTION
    : defaultValue === 'Any'
    ? undefined
    : defaultValue;
};

/**
 * Derives a source value from a given location or source regex string.
 *
 * @param {string} location       the location whose source to derive
 * @param {string} defaultValue   the default value to return if no path is found
 */
export const getSourceFromLocation = (
  location,
  defaultValue = NOT_APPLICABLE
) => {
  const locationArray = location.split('/');
  const ingestIndex = locationArray.findIndex(element => element === 'ingest');
  return ingestIndex > -1 && locationArray[ingestIndex + 1]
    ? locationArray[ingestIndex + 1]
    : defaultValue === 'Any'
    ? undefined
    : defaultValue;
};

/**
 * Format a javascript date into compatible string
 * @param {Date} d the date to format
 * @param {boolean} endOfRange indicates the given date is the end of the date range
 * @returns
 */
export const calculateDate = (d, endOfRange = false) => {
  console.log('Date: ', d);
  console.dir(d);
  const formattedDate = moment(d).format('YYYY-MM-DD');
  if (endOfRange) {
    return formattedDate + END_OF_RANGE_OFFSET;
  } else {
    return formattedDate + START_OF_RANGE_OFFSET;
  }
};
