import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { DoubleViewContext } from '../../../Context';
import MultiRecordInformationDropdown from '../../../../ui/MultiRecordInformationDropdown';
import { employeeDropdownMapProps } from '../../mapProps';

const DoubleViewEmployeeDropdownContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DoubleViewContext,
    mapProps: employeeDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);

export default DoubleViewEmployeeDropdownContainer;
