/*
  mapValuesToArray and mapKeysToArray will take a Map Object and return either the keys or values
  of that Map as an array. You can also pass in a callback function that if there
  will operate on each of the elements before pushing it into the returned array.

  The main reason we are using for each rather than Map.values, Map.keys, or Map.entries
  is because IE doesn't support any of those methods. The other reson is that these methods
  return Iterator objects that you then have to call .next on.
*/
export function mapValuesToArray(mapObject, callback) {
  var values = [];
  mapObject.forEach(function (val) {
    return values.push(callback ? callback(val) : val);
  });
  return values;
}
export function mapKeysToArray(mapObject, callback) {
  var keys = [];
  mapObject.forEach(function (val, key) {
    return keys.push(callback ? callback(key) : key);
  });
  return keys;
}

// Takes a number and will return a string version of that number with the appropriate nth tacked on
export function nthifyNumber(number) {
  if (typeof number !== 'number') return 'Unknown';
  var origNumber = number;
  var nth = 'th';

  // cut down large numbers so the logic will still work
  number %= 100;

  // the teens are a specal case in that 12, 13 etc are 'th' so just pull out this chunk of numbers
  if (number > 3 && number < 21) return "".concat(origNumber).concat(nth);

  // pull the last digit of the number to check the nth
  var lastDigit = number % 10;
  if (lastDigit < 4) {
    switch (lastDigit) {
      case 0:
        break;
      case 1:
        nth = 'st';
        break;
      case 2:
        nth = 'nd';
        break;
      default:
        nth = 'rd';
        break;
    }
  }
  return "".concat(origNumber).concat(nth);
}

/**
 * Method to render a case category & category group name for a case object. If category doesn't exist, returns 'None'
 * @param {Object} caseLike - the case Object from which the case category object is being retrieved
 * @returns {String} category name
 */
export function categoryFor(caseLike) {
  return formatCategoryName(caseLike.category) || 'None';
}

/**
 * Validate email string
 * @param {String} email - the email address
 * @return {Boolean}  whether it's valid
 */
export function validateEmail(email) {
  var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) return true;
  return emailRegex.test(email);
}

/**
 * Formats a category name based off group availability and the useGroups boolean
 * @param {Object} category - the category in question
 * @return {String || Null} A formatted string either GroupName: CategoryName or CategoryName
 */
export function formatCategoryName(category, useGroups) {
  if (!category) return null;
  return category.group && (category.useGroups || useGroups) ? "".concat(category.group.name, ": ").concat(category.name) : "".concat(category.name);
}

/**
 * Formats suspicious activity category name to support correct filtering
 * @param {Object} category - the category in question
 * @return {String} A formatted string only adjusted if category name is suspicious activity
 */
export function formatSuspiciousActivityLink(category) {
  if (category.name === 'Suspicious Activity') {
    return category.product === 'Privacy' ? 'Privacy Suspicious Activity' : category.product === 'Diversion' ? 'Diversion Suspicious Activity' : category.name;
  } else {
    return category.name;
  }
}

/**
 * Helper method to convert a provided parameter value to an array if it
 * isn't one already
 *
 * @param {String|Array} paramValue - the parameter value to convert
 * @return {Array} the converted value as an array
 */
export function convertMultiValue(paramValue) {
  if (paramValue) {
    var values = paramValue instanceof Array ? paramValue : [paramValue];
    return values.map(function (a) {
      return a;
    });
  }
  return undefined;
}