import React from 'react';
import PropTypes from 'prop-types';

import Detail from '../Detail';

import { MedicationUtils } from 'common';

const label = (text, index, size) =>
  index && size > 1 ? `${text} (${index} of ${size})` : text;

const Medication = ({ index, medication, size }) => {
  const {
    form,
    medClassificationSchedule,
    route,
    strength,
    volume,
  } = medication;

  return (
    <ul className="list-unstyled list-nested">
      <Detail
        key="name"
        label={label('Medication Name', index, size)}
        value={MedicationUtils.formatMedication(medication)}
      />
      <Detail
        key="volume"
        label={label('Medication Volume', index, size)}
        value={volume}
      />
      <Detail
        key="strength"
        label={label('Medication Strength', index, size)}
        value={strength}
      />
      <Detail
        key="form"
        label={label('Medication Form', index, size)}
        value={form}
      />
      <Detail
        key="route"
        label={label('Medication Route', index, size)}
        value={route}
      />
      <Detail
        key="classification"
        label={label('Medication Classification', index, size)}
        value={medClassificationSchedule}
      />
    </ul>
  );
};

Medication.propTypes = {
  index: PropTypes.number,
  medication: PropTypes.shape({
    brandName: PropTypes.string,
    form: PropTypes.string,
    genericName: PropTypes.string,
    medClassificationSchedule: PropTypes.number,
    route: PropTypes.string,
    strength: PropTypes.shape({}),
    volume: PropTypes.shape({}),
  }).isRequired,
  size: PropTypes.number,
};

Medication.defaultProps = {
  index: undefined,
  size: undefined,
};

export default Medication;
