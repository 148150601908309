import { autorun, computed, decorate, observable, action, toJS } from 'mobx';
import moment from 'moment';

import { SingletonStore, AccessClient, DownloadUtils, HalUtils } from 'common';

import CaseTemplate from '../../CaseTemplate';
import {
  INCIDENT_SUMMARY,
  INCIDENT_SUMMARY_AND_EVENT_DETAILS,
} from '../../../incident/stores/consts';
import { getIncidentExportURL } from '../../../utils/incidentExportHelper';
import CaseStore from '../CaseStore';

const endpointMapping = {
  Accesses: '/accesses',
  Administrations: '/medicationIncidents/administrations',
  Discrepancies: '/medicationIncidents/discrepancies',
  Handlings: '/medicationIncidents/handlings',
  Incidents: '/medicationIncidents',
  'Incident Summary': '/medicationIncidents',
  'Incident Summary & Event Details': '/medicationIncidents',
  Orders: '/medicationIncidents/orders',
};

class CaseAccessesStore extends SingletonStore {
  constructor() {
    super();
    autorun(() => {
      this.refresh();
    });
  }

  // Observables
  case;
  mergeUOA = false;

  // Computed
  get userOnlyAccesses() {
    if (this.mergeUOA)
      return (HalUtils.getData(this.result) || []).filter(a => !a.patient);
    return [];
  }

  // Computed
  get hasUOA() {
    const accesses = HalUtils.getData(this.result) || [];
    return !accesses.every(access => access.patient);
  }

  fetch() {
    if (toJS(this.case)) {
      const { patientSummary, user } = this.case;
      if (user) {
        return AccessClient.getByUsersAndPatient(
          user.id,
          patientSummary && patientSummary.id,
          moment(this.case.minEventDate),
          moment(this.case.maxEventDate).add(1, 'days'),
          true,
          undefined,
          undefined,
          undefined,
          undefined,
          'caseAccess'
        );
      }
    }
    return [];
  }

  //Action
  setMergeUOA = val => (this.mergeUOA = val);

  downloadCSV = eventType => {
    const baseTitle = CaseTemplate.title(
      `(${eventType}) ${this.case.title}`,
      this.case.minEventDate,
      this.case.maxEventDate
    );
    const baseHref = HalUtils.getSelf(this.case);
    const title = this.case.number
      ? `Case ${this.case.number} - ${baseTitle}.csv`
      : `${baseTitle}.csv`;
    let href = `${baseHref}${endpointMapping[eventType]}`;

    if (eventType === 'Accesses' && this.mergeUOA) {
      href = AccessClient.getURL(
        this.case.user.id,
        this.case.patientSummary && this.case.patientSummary.id,
        moment(this.case.minEventDate),
        moment(this.case.maxEventDate).add(1, 'days'),
        true,
        undefined,
        undefined,
        undefined,
        AccessClient.maxAccesses,
        true,
        'caseAccess'
      );
    }

    if (
      eventType === INCIDENT_SUMMARY ||
      eventType === INCIDENT_SUMMARY_AND_EVENT_DETAILS
    ) {
      const ids = (CaseStore.medicationIncidents || []).map(inc => inc.id);
      href = getIncidentExportURL(eventType);
      DownloadUtils.downloadFromServerWithIds(href, ids, title);
    } else {
      DownloadUtils.downloadFromServer(href, title);
    }
  };
}

decorate(CaseAccessesStore, {
  case: observable,
  mergeUOA: observable,
  userOnlyAccesses: computed,
  hasUOA: computed,
  setMergeUOA: action,
});

export default new CaseAccessesStore();
