import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../../../ui/Checkbox';
import styles from './index.module.scss';

const FORMATTED_PAGES = {
  casePage: 'Case Page',
};

const NewAppExperience = ({ update, isDirty, appValues, toggleValue }) => (
  <div id="settings__wrapper-New_Application_Experience" className="wrapper">
    <div className="card_header">
      <h1>New Application Experience</h1>
      <span
        className={classnames('prot-a', { muted: !isDirty })}
        onClick={update}
      >
        Save Changes
      </span>
    </div>
    <div className="content">
      <p>
        Toggles whether to send application users to the newer versions of these
        pages:
      </p>
      <div className={styles.checkContainer}>
        {Object.entries(appValues).map(([key, value]) => (
          <Checkbox
            key={key}
            checked={value}
            color="secondary"
            onChange={() => toggleValue(key)}
            label={FORMATTED_PAGES[key]}
          />
        ))}
      </div>
    </div>
  </div>
);

NewAppExperience.propTypes = {
  update: PropTypes.func.isRequired,
  toggleValue: PropTypes.func.isRequired,
  isDirty: PropTypes.bool,
  appValues: PropTypes.shape({
    casePage: PropTypes.bool,
  }).isRequired,
};

NewAppExperience.defaultProps = {
  isDirty: false,
};

NewAppExperience.displayName = 'NewAppExperience';

export default NewAppExperience;
