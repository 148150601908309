import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';
import moment from 'moment';

import TagHelpers from '../TagHelpers';

const DateCell = observer(({ data, date, isActive, baseUrl, timezone }) => {
  const goTo = () => {
    TagHelpers.goTo(baseUrl, data);
  };

  const formatDate = date => {
    const m = moment(date).tz(timezone),
      midnight = moment()
        .tz(timezone)
        .millisecond(0)
        .second(0)
        .minute(0)
        .hour(0),
      yesterday = moment(midnight).subtract(1, 'days');

    if (m.diff(midnight) >= 0) return m.fromNow();
    else if (m.diff(yesterday) >= 0) return 'Yesterday ' + m.format('h:mm A z');
    else return m.format('lll z');
  };
  if (!date) return <span />;

  return (
    <Cell
      onClick={goTo.bind(this)}
      className={classnames({
        'record__cell--active': isActive,
      })}
    >
      {formatDate(date)}
    </Cell>
  );
});

DateCell.displayName = 'DateCell';

DateCell.propTypes = {
  date: PropTypes.string,
  isActive: PropTypes.bool,
  baseUrl: PropTypes.string,
  data: PropTypes.shape({}),
  timezone: PropTypes.string,
};

export default DateCell;
