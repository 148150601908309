import React from 'react';
import PropTypes from 'prop-types';
import { decorate, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import Loader from '../../Loader';
import { ScrollPager, SmallPandy } from 'common';

const RegistryContainer = observer(
  class RegistryContainer extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      store: PropTypes.object.isRequired,
      title: PropTypes.oneOfType([
        // either a string or a React DOM node
        PropTypes.string,
        PropTypes.object,
      ]),
      children: PropTypes.node,
      createPath: PropTypes.string,
      createPermission: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
      ]),
      disableScrollPager: PropTypes.bool,
      pandyText: PropTypes.string,
      createIcon: PropTypes.string,
      viewContent: PropTypes.bool,
      downloadCSV: PropTypes.func,
    };

    static defaultProps = {
      viewContent: true,
    };

    // Observable
    pagerElement = null;

    // Computed
    get loaded() {
      const { store } = this.props;
      return !store.loading || store.size !== 0;
    }

    render() {
      const {
        store,
        title,
        className,
        viewContent,
        disableScrollPager,
        pandyText,
        ...other
      } = this.props;

      let klasses = (className || '').split(' ');
      if (viewContent && klasses.indexOf('view-content') < 0)
        klasses.unshift('view-content');
      klasses = klasses.join(' ');

      return (
        <div
          className={klasses}
          ref={node => {
            if (node && node !== this.pagerElement) {
              this.pagerElement = node;
            }
          }}
          {...other}
        >
          {!disableScrollPager && (
            <ScrollPager.Component
              elt={this.pagerElement}
              store={store}
              staticHeader
            />
          )}
          <Loader loaded={this.loaded}>
            <section
              className={classnames({
                'view-content__body': viewContent,
              })}
              data-cy="vips-content-body"
            >
              <header
                className={classnames('row no-gutter', {
                  hidden: store.size === 0,
                })}
              >
                <h2 className="col-xs-8">{title}</h2>
              </header>
              {this.props.children}
              {// Only show the end of list pandy component if we are using the scrollPager
              !disableScrollPager && (
                <SmallPandy
                  visible={
                    !store.hasMore &&
                    store.results.length >= (store.pageSize || 20)
                  }
                >
                  <div className="speechBubble">
                    <span>{`All ${pandyText || title} Loaded`}</span>
                  </div>
                </SmallPandy>
              )}
            </section>
          </Loader>
        </div>
      );
    }
  }
);

decorate(RegistryContainer, {
  loaded: computed,
  pagerElement: observable,
});

RegistryContainer.displayName = 'RegistryContainer';

export default RegistryContainer;
