import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { DateHelpers } from 'common';

import FilterSectionHeader from '../../ui/FilterSectionHeader';
import ExclusionDropdown from '../../ui/Filters/ExclusionDropdown';
import TimePlusCalendarSelector from '../../ui/TimePlusCalendarSelector';
import ListItem from '../../ui/Filters/ListItem';
import filterStyles from '../../ui/CaseDetailFilters/index.module.scss';
import styles from '../CaseReportsFilters/index.module.scss';
const {
  TIME_PERIOD_VALUES: { WEEK, FOURTEEN_DAYS, MONTH, NINETY_DAYS, YEAR, CUSTOM },
} = DateHelpers;

const ReportSettingsFilters = ({
  history,
  location,
  query,
  queryForSelectionChange,
  casesBy,
  casesByOptions,
  pivotOn,
  pivotOnOptions,
  reportSettingsDateRange,
  reportSettingsDateAfter,
  reportSettingsDateBefore,
}) => {
  const navigateOnChange = newQuery => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ ...query, ...newQuery }),
    });
  };

  return (
    <section>
      <FilterSectionHeader icon="icon-speed" title="Report Settings" />
      <ul className={styles.reportFilters}>
        <ExclusionDropdown
          label="Select Cases By"
          options={casesByOptions}
          hideExclusion
          onChange={({ value }) => {
            navigateOnChange({ casesBy: value });
          }}
          value={casesByOptions.find(({ value }) => value === casesBy)}
          onToggleExclusion={() => {}}
          textField="label"
          valueField="value"
          data-cy="select-cases-by--dropdown"
        />
        <div className={filterStyles.withoutExclusion}>
          <TimePlusCalendarSelector
            CustomListItem={ListItem}
            data-cy="time-range--dropdown"
            createdInformation={[
              reportSettingsDateAfter,
              reportSettingsDateBefore,
            ]}
            label="Time Range"
            filterKey="reportSettingsDate"
            defaultValue={reportSettingsDateRange}
            onCalendarChange={(date, valid, beforeOrAfter, filterKeyRoot) => {
              if (valid) {
                navigateOnChange(
                  queryForSelectionChange(
                    `${filterKeyRoot}${beforeOrAfter}`,
                    date
                  )
                );
              }
            }}
            onChangeDropdown={value => {
              navigateOnChange(
                queryForSelectionChange('reportSettingsDateRange', value)
              );
            }}
            hiddenCalendar={reportSettingsDateRange === CUSTOM}
            options={[WEEK, FOURTEEN_DAYS, MONTH, NINETY_DAYS, YEAR, CUSTOM]}
          />
        </div>
        <ExclusionDropdown
          allowNoneLabel
          label="Pivot By"
          options={pivotOnOptions}
          value={pivotOnOptions.find(({ value }) => value === pivotOn)}
          textField="label"
          valueField="value"
          hideExclusion
          onChange={({ value }) => {
            navigateOnChange({ pivotOn: value });
          }}
          onToggleExclusion={() => {}}
          data-cy="pivot-by--dropdown"
        />
      </ul>
    </section>
  );
};

ReportSettingsFilters.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  query: PropTypes.object.isRequired,
  queryForSelectionChange: PropTypes.func,
  casesBy: PropTypes.string,
  casesByOptions: PropTypes.array,
  pivotOn: PropTypes.string,
  pivotOnOptions: PropTypes.array,
  reportSettingsDateRange: PropTypes.string,
  reportSettingsDateAfter: PropTypes.string,
  reportSettingsDateBefore: PropTypes.string,
};

export default ReportSettingsFilters;
