import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';
import Field from '../../ui/Field';
import ScreenOnly from '../../ui/ScreenOnly';
import FormattedDobAndAge from '../../people/PersonBox/FormattedDobAndAge';
import ValueOrUnknown from '../../people/PersonBox/ValueOrUnknown';
import FormattedLastActivityDate from '../../people/PersonBox/FormattedLastActivityDate';
import BoxSection from '../../people/PersonBox/BoxSection';
import pluralize from '../../utils/pluralize';
import FormattedLabels from '../../people/PersonBox/FormattedLabels';
import Addresses from '../../people/Addresses';
import PhoneNumbers from '../../people/PhoneNumbers';
import TerminationInfo from '../../people/PersonBox/TerminationInfo';
import DepartmentsAndOrganizations from '../DepartmentsAndOrganizations';
import Accounts from '../Accounts';
import Relationships from '../../people/Relationships';
import ViaSource from '../../ui/ViaSource';

const EmployeeDetails = ({
  employee,
  employeeId,
  employeeRelationships,
  printSettings,
  lastActivityMoment,
  hideDob,
}) => {
  const recentRoleWithName = (employee.chronoRoles || []).find(r => r.name);

  return (
    <BoxSection>
      <h2>Details</h2>

      <Relationships
        employeeId={employeeId}
        relationships={employeeRelationships}
      />

      {employee.labels?.length > 0 && (
        <Field label={pluralize(employee.labels.length, 'Label')} width="auto">
          <FormattedLabels labels={employee.labels} />
        </Field>
      )}

      <Field label="Role" width="auto">
        <ValueOrUnknown>{recentRoleWithName?.name}</ValueOrUnknown>
        {recentRoleWithName?.source && (
          <ViaSource source={recentRoleWithName.source} />
        )}
      </Field>

      {employee.accounts?.length > 0 && (
        <ScreenOnly hideOnPrint={printSettings?.accountSecurityRole}>
          <Accounts accounts={employee.accounts} />
        </ScreenOnly>
      )}

      <Field label="ID" width="auto">
        <ValueOrUnknown>{employee.userId}</ValueOrUnknown>
      </Field>

      <TerminationInfo
        terminated={employee.terminated}
        terminationDate={employee.terminationDate}
        terminationSource={employee.terminationSource}
      />

      {hideDob ? null : (
        <ScreenOnly hideOnPrint={printSettings?.dob}>
          <Field label="Date of Birth" width="auto">
            <FormattedDobAndAge
              dateOfBirth={employee.dateOfBirth}
              deceased={employee.deceased}
              dateOfDeath={employee.dateOfDeath}
            />
          </Field>
        </ScreenOnly>
      )}

      {lastActivityMoment && (
        <Field label="Most Recent Activity" width="auto">
          <FormattedLastActivityDate
            userId={employeeId}
            date={lastActivityMoment}
          />
        </Field>
      )}

      <Addresses
        addresses={employee.addresses}
        hideOnPrint={printSettings?.EMRUserAddress}
      />

      <PhoneNumbers
        numbers={employee.phoneNumbers}
        hideOnPrint={printSettings?.phoneNumber}
      />

      <DepartmentsAndOrganizations
        ehrDepartments={employee.departments}
        roles={employee.chronoRoles}
      />
    </BoxSection>
  );
};

EmployeeDetails.propTypes = {
  employee: PropTypes.shape({
    accounts: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    addresses: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    chronoRoles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    dateOfBirth: PropTypes.string,
    dateOfDeath: PropTypes.string,
    deceased: PropTypes.bool,
    departments: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    labels: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        source: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    phoneNumbers: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    terminated: PropTypes.bool,
    terminationDate: PropTypes.string,
    terminationSource: PropTypes.string,
    userId: PropTypes.string,
  }),
  employeeId: PropTypes.string.isRequired,
  employeeRelationships: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  printSettings: PropTypes.shape({
    EMRUserAddress: PropTypes.bool,
    dob: PropTypes.bool,
    phoneNumber: PropTypes.bool,
    accountSecurityRole: PropTypes.bool,
  }),
  lastActivityMoment: PropTypes.instanceOf(moment),
  hideDob: PropTypes.bool,
};

EmployeeDetails.defaultProps = {
  lastActivityMoment: null,
};

export default EmployeeDetails;
