import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute, PermissionStore } from 'common';
import BundleDetails from '../BundleDetails';
import BundlesContainer from '../BundlesContainer';
import { GroupQuickFilterContainer } from '../../ui/GroupQuickFilterWithGroupStore';

const CaseBundlesView = observer(({ children }) => {
  if (
    !PermissionStore.hasAny(['BUNDLE_VIEW', 'BUNDLE_MODIFY', 'BUNDLE_DELETE'])
  ) {
    return <Redirect to={'/'} />;
  }

  return (
    <>
      <div>
        <GroupQuickFilterContainer />
      </div>
      <main>
        {children}
        <Switch>
          <ProtectedRoute path="/bundles/:bundleId" component={BundleDetails} />
          <ProtectedRoute component={BundlesContainer} />
        </Switch>
      </main>
    </>
  );
});

CaseBundlesView.propTypes = {
  children: PropTypes.node,
};

CaseBundlesView.displayName = 'CaseBundlesView';

export default CaseBundlesView;
