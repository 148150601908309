import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

export const Count = ({
  text = 'Unknown',
  count = 0,
  danger = false,
  disabled = false,
}) => {
  return (
    <p
      className={classnames(styles.count, {
        [styles.issues]: danger,
        [styles.grayed]: disabled,
      })}
    >{`${count} ${text}`}</p>
  );
};

Count.propTypes = {
  text: PropTypes.string,
  count: PropTypes.number,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
};

const Counts = ({ items = [] }) => {
  return items.map((item, i) => (
    <div key={item.text + i} className={styles.countWrapper}>
      <Count
        text={item.text}
        count={item.count}
        danger={item.danger}
        disabled={item.disabled}
      />
      {items.length > 1 && i !== items.length - 1 ? <span> | </span> : null}
    </div>
  ));
};

Counts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Counts;
