import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const Anchor = ({ tag: Tag, className, disabled, ...otherProps }) => (
  <Tag
    className={classnames(className, { [styles.protA]: !disabled })}
    {...otherProps}
  />
);

Anchor.propTypes = {
  tag: PropTypes.oneOf(['span', 'a', 'button', 'i']).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Anchor.defaultProps = {
  className: null,
  disabled: false,
};

export default Anchor;
