import React from 'react';
import MultiRecordActivityChart from '../MultiRecordActivityChart';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import PropTypes from 'prop-types';

const MultiRecordActivityChartContainer = ({ match }) => (
  <MultiRecordActivityChart match={match} />
);

MultiRecordActivityChartContainer.propTypes = {
  match: PropTypes.shape({}),
};

MultiRecordActivityChartContainer.displayName =
  'MultiRecordActivityChartContainer';

export default withRouterAndQuery(MultiRecordActivityChartContainer);
