import ListView from '../ListView';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { ListContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';

export function mapProps({ vipsStore, query, history }) {
  return {
    query,
    setFilters: vipsStore.setFilters,
    downloadCSV: vipsStore.downloadCSV,
    noFilters: vipsStore.noFilters,
    totalElements: vipsStore.totalElements,
    size: vipsStore.size,
    loading: vipsStore.loading,
    storeForRegistry: vipsStore,
    advancedFiltersVisible: !!vipsStore.showFilters,
    onToggleAdvancedFilters: () => {
      history.push(vipsStore.showFiltersLink());
    },
  };
}

const ListViewContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: ListContext,
    mapProps,
    PresentationalComponent: ListView,
  })
);

ListViewContainer.displayName = 'ListViewContainer';

export default ListViewContainer;
