import React from 'react';
import { Switch } from 'react-router-dom';

import { ProtectedRoute } from 'common';

import NewGRCCaseContainer from '../grc/NewGRCCaseContainer';
import EditGRCCaseContainer from '../grc/EditGRCCaseContainer';
import CaseViewContainer from '../CaseViewContainer';

const CaseRootView = () => {
  return (
    <React.Fragment>
      <Switch>
        <ProtectedRoute path="/case/create" component={NewGRCCaseContainer} />
        <ProtectedRoute
          path="/case/update/:caseId"
          component={EditGRCCaseContainer}
        />
        <ProtectedRoute path="/case/:caseId" component={CaseViewContainer} />
      </Switch>
    </React.Fragment>
  );
};

export default CaseRootView;
