import React from 'react';
import { observer } from 'mobx-react';
import {
  observable,
  action,
  decorate,
  reaction,
  autorun,
  computed,
} from 'mobx';
import classnames from 'classnames';
import moment from 'moment';
import $ from 'jquery';
import { Redirect } from 'react-router-dom';

import {
  ApprovedDomainsStore,
  LoginStore,
  ResetPasswordStore,
  validateEmail,
} from 'common';

import MultifactorSetupView from '../MultifactorSetupView';
import NotificationStore from '../../stores/NotificationStore';
import { ApplyBodyClassName, DocumentTitle, Input } from '../../ui';

const AccountView = observer(
  class extends React.Component {
    // Observable
    mfaEnabled = LoginStore.mfaEnabled;
    firstName = '';
    lastName = '';
    email = '';
    password0 = '';
    password1 = null;
    password2 = null;
    dirty = false;
    processing = false;

    constructor() {
      super();

      autorun(() => {
        if (LoginStore.mfaEnabled) this.mfaEnabled = true;
        if (LoginStore.user) {
          const {
            user: { firstName, lastName, email },
          } = LoginStore;
          this.firstName = firstName;
          this.lastName = lastName;
          this.email = email;
        }
      });

      reaction(
        () => [LoginStore.jwtCheck],
        () => {
          if (LoginStore.jwtCheck) {
            this.mfaEnabled = true;
            const content = (
              <span>
                <i className="material-icons icon-check_circle" />
                2-Step Verification is now enabled on your account.
              </span>
            );
            NotificationStore.add({ level: 'success', content });
          }
        }
      );
    }

    get emailError() {
      if (this.email === undefined) return false;
      return (
        !validateEmail(this.email) ||
        !ApprovedDomainsStore.isApprovedDomain(this.email)
      );
    }

    get emailErrorMsg() {
      if (this.email === undefined) return null;
      if (
        validateEmail(this.email) &&
        !ApprovedDomainsStore.isApprovedDomain(this.email)
      )
        return 'does not belong to the list of allowed domains';
      if (!validateEmail(this.email)) return 'is not valid';
      return null;
    }

    get pass1Valid() {
      return (
        ResetPasswordStore.strongPasswordValidator(this.password1) === true
      );
    }

    get errorMessage1() {
      const error = ResetPasswordStore.strongPasswordValidator(this.password1);
      return typeof error === 'string' ? error : null;
    }

    get pass2Valid() {
      return (
        ResetPasswordStore.matchingValidator(this.password2, this.password1) ===
        true
      );
    }

    get errorMessage2() {
      const error = ResetPasswordStore.matchingValidator(
        this.password2,
        this.password1
      );
      return typeof error === 'string' ? error : null;
    }

    handleSubmit = e => {
      e.preventDefault();
      let section;

      const {
        user: { firstName, lastName, email },
      } = LoginStore;
      const updated = {};
      if (this.firstName !== firstName) updated.firstName = this.firstName;
      if (this.lastName !== lastName) updated.lastName = this.lastName;
      if (this.email !== email) updated.email = this.email;

      if (updated.firstName || updated.lastName || updated.email)
        section = 'profile';

      if (this.password2) {
        if (!this.password0 || !this.pass1Valid || !this.pass2Valid) return;

        section = 'password';
        updated.oldPassword = this.password0;
        updated.newPassword = this.password2;
      }

      this.processing = true;

      // must be valid i guess so try the remote call (server will check complexity but not matching)
      LoginStore.update(updated)
        .fail(xhr => {
          let failure = 'Could not reach server or other failure occurred.';
          if (xhr.responseJSON && xhr.responseJSON.message) {
            failure = xhr.responseJSON.message;
          }
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              {failure}
            </span>
          );
          NotificationStore.add({ level: 'warning', content });
        })
        .then(() => {
          // clear out the input fields
          this.password0 = '';
          this.password1 = null;
          this.password2 = null;
          this.dirty = false;

          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Your {section} has been updated!
            </span>
          );
          NotificationStore.add({ level: 'success', content });
        })
        .always(() => {
          this.processing = false;
        });
    };

    matchingValidator = (val1, val2) =>
      ResetPasswordStore.matchingValidator(val1, val2);

    handleMfaSubmit = (e, bool) => {
      e.preventDefault();
      if (!bool) {
        LoginStore.disableMFA().then(() => {
          this.mfaEnabled = false;
          // this.showInstructions = false;
          LoginStore.renew();
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              2-Step Verification is now disabled on your account.
            </span>
          );
          NotificationStore.add({ level: 'warning', content });
        });
      } else {
        this.showInstructions = true;
      }
    };

    handleFieldChange = (val, field) => {
      this[field] = val;
      const { user } = LoginStore;
      this.dirty =
        this.firstName !== user.firstName ||
        this.lastName !== user.lastName ||
        this.email !== user.email;
    };

    scroll(sectionTitle) {
      let scrollAmount = $(`#${sectionTitle}`).position().top - 50;

      if (sectionTitle === 'wrapper-password') {
        scrollAmount = $('#wrapper-profile').height() + 30;
      } else if (sectionTitle === 'wrapper-security') {
        scrollAmount += 200;
      }

      $('.account_view__body').animate(
        {
          scrollTop: scrollAmount,
        },
        700
      );
    }

    renderAccountExpiration() {
      const cn = classnames('float-right', {
        'form--error': LoginStore.accountExpiresSoon,
      });

      if (LoginStore.accountExpiration) {
        const diff = moment
          .duration(
            LoginStore.accountExpiration.diff(moment(), 'hours'),
            'hours'
          )
          .format('d [days,] h [hours and] m [minutes]');
        return (
          <span className={cn}>
            <small>Your account expires in {diff}</small>
          </span>
        );
      }
    }

    renderCredentialExpiration() {
      const cn = classnames('float-right', {
        'form--error': LoginStore.credentialExpiresSoon,
      });

      if (LoginStore.credentialExpiration) {
        const diff = moment
          .duration(
            LoginStore.credentialExpiration.diff(moment(), 'minutes'),
            'minutes'
          )
          .format('d [days,] h [hours and] m [minutes]');
        return (
          <span className={cn}>
            <small>Your password expires in {diff}</small>
          </span>
        );
      }

      // perhaps a bug of some sort, but expiration is disabled for this user
      return '';
    }

    renderPasswordSection() {
      if (!LoginStore.samlRequired) {
        return (
          <div>
            <p className="pw-policy">
              {ResetPasswordStore.passwordPolicy.replace(/<br ?\/?>/g, '\n')}
            </p>
            <ul>
              <Input
                bordered
                margin="md"
                type="password"
                name="password"
                label="Current Password"
                onChange={val => (this.password0 = val)}
                disabled={this.processing}
                value={this.password0}
              />
              <Input
                bordered
                disabled={this.processing}
                error={this.password1 !== null && !this.pass1Valid}
                errorMessage={this.errorMessage1}
                label="New Password"
                margin="md"
                name="password"
                onChange={val => (this.password1 = val)}
                type="password"
                value={this.password1 || ''}
              />
              <Input
                bordered
                className="error"
                error={this.password2 !== null && !this.pass2Valid}
                errorMessage={this.errorMessage2}
                margin="md"
                type="password"
                name="password"
                label="New Password Confirmation"
                placeholder="Confirm New Password"
                onChange={val => (this.password2 = val)}
                disabled={this.processing}
                value={this.password2 || ''}
              />
            </ul>
          </div>
        );
      }
    }

    renderSecurity() {
      if (!LoginStore.samlRequired) {
        if (!this.mfaEnabled && this.showInstructions) {
          return (
            <MultifactorSetupView page="account">
              <p>
                {' '}
                To get started, you need to setup
                <strong> Google Authenticator </strong>
                on your phone. Don’t worry, we’ll walk you through it. First,
                select the type of smartphone you have:
              </p>
            </MultifactorSetupView>
          );
        }

        return (
          <form>
            <h1> 2-Step Verification</h1>
            <p>
              <strong>2-Step Verification </strong>
              adds an extra layer of security to your account. You will need to
              sign in with your Username, Password, and unique
              <strong> Verification Code </strong>
              code sent to your phone.
            </p>
            <p>
              2-Step Verification is currently{' '}
              <em>{this.mfaEnabled ? 'enabled' : 'disabled'}</em>.
            </p>
            <ul>
              <li>
                <input
                  className="button"
                  type="submit"
                  value={`${
                    this.mfaEnabled ? 'Disable' : 'Enable'
                  } 2-Step Verification`}
                  onClick={e => this.handleMfaSubmit(e, !this.mfaEnabled)}
                />
              </li>
            </ul>
          </form>
        );
      }
      return null;
    }

    render() {
      if (ApprovedDomainsStore.loading) return null;
      if (!ApprovedDomainsStore.amIOnAnApprovedDomain) {
        return <Redirect to="/" />;
      }

      return (
        <div className="account__view">
          <DocumentTitle text="My Account" />
          <ApplyBodyClassName className="dashboard" />
          <aside>
            <h1> My Account </h1>
            <ul>
              <h2
                className="prot-a"
                onClick={() => this.scroll('wrapper-profile')}
              >
                {' '}
                Profile{' '}
              </h2>
              <h2
                className="prot-a"
                onClick={() => this.scroll('wrapper-password')}
              >
                {' '}
                Password{' '}
              </h2>
              <h2
                className="prot-a"
                onClick={() => this.scroll('wrapper-security')}
              >
                {' '}
                Security Settings{' '}
              </h2>
            </ul>
          </aside>
          <section className="account_view__body">
            <div>
              <div className="wrapper" id="wrapper-profile">
                <div className="card_header">
                  <h1>
                    Profile
                    {this.renderAccountExpiration()}
                  </h1>
                </div>
                <div className="content">
                  <form className="form">
                    <h4>My Name</h4>
                    <ul>
                      <Input
                        bordered
                        margin="md"
                        name="firstName"
                        label="First Name"
                        value={this.firstName}
                        onChange={val =>
                          this.handleFieldChange(val, 'firstName')
                        }
                      />
                      <Input
                        bordered
                        margin="md"
                        name="lastName"
                        label="Last Name"
                        value={this.lastName}
                        onChange={val =>
                          this.handleFieldChange(val, 'lastName')
                        }
                      />
                    </ul>
                    <h4>My Email</h4>
                    <ul>
                      <Input
                        bordered
                        error={this.emailError}
                        errorMessage={this.emailErrorMsg}
                        margin="md"
                        type="email"
                        name="email"
                        label="Email Address"
                        value={this.email}
                        onChange={val => this.handleFieldChange(val, 'email')}
                      />
                    </ul>
                    <ul>
                      <li>
                        <input
                          className="button"
                          type="submit"
                          value="Update Profile"
                          disabled={!this.dirty || this.emailError}
                          onClick={this.handleSubmit}
                        />
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
              <div className="wrapper" id="wrapper-password">
                <div className="card_header">
                  <h1>
                    Password
                    {this.renderCredentialExpiration()}
                  </h1>
                </div>
                <div className="content">
                  <form>
                    {this.renderPasswordSection()}
                    <ul>
                      <li>
                        <input
                          className="button"
                          type="submit"
                          value="Update Password"
                          disabled={
                            !this.pass2Valid ||
                            !this.password0 ||
                            this.processing
                          }
                          onClick={this.handleSubmit}
                        />
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
              <div className="wrapper" id="wrapper-security">
                <div className="card_header">
                  <h1>Security</h1>
                </div>
                <div className="content">
                  <div className="security">{this.renderSecurity()}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
);

decorate(AccountView, {
  mfaEnabled: observable,
  showInstructions: observable,
  showMFAShortCut: observable,
  toggleMFA: action,
  firstName: observable,
  lastName: observable,
  email: observable,
  dirty: observable,
  processing: observable,
  password0: observable,
  password1: observable,
  password2: observable,
  emailError: computed,
  emailErrorMsg: computed,
  pass1Valid: computed,
  pass2Valid: computed,
  errorMessage1: computed,
  errorMessage2: computed,
});

AccountView.displayName = 'AccountView';

export default AccountView;
