import React from 'react';
import PropTypes from 'prop-types';
import CaseLink from '../CaseLink';
import Tooltip from '../Tooltip';

const ExistingCasesBubble = ({
  caseId,
  caseView,
  count,
  personId,
  personType,
}) => {
  if (!count) return null;
  const currentCase = count === 1 && caseView;
  return (
    <CaseLink
      className="abs--top_right"
      user={personType === 'user' ? personId : undefined}
      patient={personType === 'patient' ? personId : undefined}
      caseId={count === 1 ? caseId : null}
      cases={count}
      active={!currentCase}
    >
      <Tooltip
        content={
          // if on the case view and only one existing case. Means the user is viewing the only case.
          currentCase
            ? `1 Case, Currently Viewing`
            : `${count} Existing Case${count > 1 ? 's' : ''}`
        }
        placement="right"
      >
        <span className="label label-primary badge" data-cy="num-cases-badge">
          {count}
        </span>
      </Tooltip>
    </CaseLink>
  );
};

ExistingCasesBubble.propTypes = {
  caseId: PropTypes.string,
  caseView: PropTypes.bool.isRequired,
  count: PropTypes.number,
  personId: PropTypes.string.isRequired,
  personType: PropTypes.oneOf(['user', 'patient']).isRequired,
};

ExistingCasesBubble.defaultProps = {
  count: 0,
  caseId: '',
  personId: '',
};

export default ExistingCasesBubble;
