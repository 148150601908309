import React from 'react';
import { observer } from 'mobx-react';
import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute, PermissionStore } from 'common';

import SettingsViewTopNav from '../TopNav';
import SettingsViewContainer from '../caseCreation/SettingsViewContainer';
import TemplateSettingsView from '../templates/TemplateSettingsView';
import GeneralSettingsView from '../general/GeneralSettingsView';
import AppUsersRootView from '../appUsers/AppUsersRootView';
import ApplyBodyClassName from '../../ui/ApplyBodyClassName';
import EnumsRootView from '../enums/EnumsRootView';
import RolesRootView from '../roles/RolesRootView';

const SettingsRootView = observer(
  class SettingsRootView extends React.Component {
    routes() {
      return (
        <>
          <ApplyBodyClassName className="settings" />
          <Switch>
            {PermissionStore.has('GENERAL_SETTINGS_MODIFY') && (
              <ProtectedRoute
                path="/settings/general"
                component={GeneralSettingsView}
              />
            )}
            <ProtectedRoute
              path="/settings/templates"
              component={TemplateSettingsView}
            />
            <ProtectedRoute
              path="/settings/app-users/create"
              component={AppUsersRootView}
            />
            <ProtectedRoute
              path="/settings/app-users/:id"
              component={AppUsersRootView}
            />
            <ProtectedRoute
              path="/settings/app-users"
              component={AppUsersRootView}
            />
            <ProtectedRoute path="/settings/enums/" component={EnumsRootView} />
            <ProtectedRoute path="/settings/roles/" component={RolesRootView} />
            <ProtectedRoute
              path="/settings"
              exact
              component={SettingsViewContainer}
            />
            <Redirect to="/settings" />
          </Switch>
        </>
      );
    }

    render() {
      return (
        <main className="settings_view">
          <SettingsViewTopNav />
          {this.routes()}
        </main>
      );
    }
  }
);

export default SettingsRootView;
