import React from 'react';
import PropTypes from 'prop-types';
import { computed, decorate, observable, action } from 'mobx';
import { observer } from 'mobx-react';

import MfaInstructions from '../MfaInstructions';

const MultifactorSetup = class MultifactorSetup extends React.Component {
  // Observable
  showAndroid = false;
  showIos = false;

  // Computed
  get showInstructions() {
    return this.showIos || this.showAndroid;
  }

  handleClick = e => {
    const { onClick } = this.props;

    if (onClick) onClick(true);
    if (e === 'back') {
      this.toggleShowIos(true);
    } else {
      this.toggleShowAndroid(true);
    }
  };

  // Action
  toggleInstructionsOff = () => {
    const { onClick } = this.props;

    this.toggleShowIos(false);
    this.toggleShowAndroid(false);
    if (onClick) onClick(false);
  };

  // Action
  toggleShowIos(bool) {
    this.showIos = bool;
  }

  // Action
  toggleShowAndroid(bool) {
    this.showAndroid = bool;
  }

  renderMessage() {
    const { page, children } = this.props;
    if (page) {
      return children;
    } else {
      return (
        <p>
          You must enable
          <strong> 2-Step Verification </strong>
          before you can proceed. To get started, you need to setup
          <strong> Google Authenticator </strong>
          on your phone. Don’t worry, we’ll walk you through it. First, select
          the type of smartphone you have:
        </p>
      );
    }
  }

  render() {
    return (
      <div className="mfa-wrapper">
        {this.showInstructions ? (
          <MfaInstructions
            toggleInstructionsOff={this.toggleInstructionsOff}
            showIos={this.showIos}
            showAndroid={this.showAndroid}
            {...this.props}
          />
        ) : (
          <span>
            <h1>2-Step Verification</h1>
            <div className="intro">
              <p>
                <strong>2-Step Verification </strong>
                adds an extra layer of security to your account. You will need
                to sign in with your Username, Password, and unique
                <strong> Verification Code </strong>
                code sent to your phone.
              </p>
              {this.renderMessage()}
            </div>
            <p className="mfa-buttons">
              <input
                className="button"
                value="Apple iPhone"
                onClick={() => this.handleClick('back')}
                type="button"
              />
              <input
                className="button"
                value="Android"
                onClick={this.handleClick}
                type="button"
              />
            </p>
          </span>
        )}
      </div>
    );
  }
};

MultifactorSetup.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  page: PropTypes.string,
};

decorate(MultifactorSetup, {
  showAndroid: observable,
  showInstructions: computed,
  showIos: observable,
  toggleInstructionsOff: action,
  toggleShowIos: action,
  toggleShowAndroid: action,
});

MultifactorSetup.displayName = 'MultifactorSetup';

export default observer(MultifactorSetup);
