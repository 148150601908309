import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import styles from './index.module.scss';
import { generateSubnavLinks } from '../stores/utils/RouteUtils';
import { VisibleSystemQuickFilterContainer } from '../../ui/VisibleSystemQuickFilterWithVisibleSystemStore';

const MultiRecordTopNav = ({ employeeId, path, patientId, query }) => {
  const [subnavLinks, setSubnavLinks] = useState(
    generateSubnavLinks(path, employeeId, patientId, query)
  );
  useEffect(() => {
    if (query?.system) {
      setSubnavLinks(generateSubnavLinks(path, employeeId, patientId, query));
    }
  }, [employeeId, path, patientId, query]);

  return (
    <div className={styles.wrapper}>
      <TopNavBar>
        <VisibleSystemQuickFilterContainer useTopNav={false} />
        {subnavLinks?.map(link => {
          return (
            <NavBarItem
              key={link.text}
              disabled={link.disabled}
              route={link.route}
              active={link.active}
              onClick={e => {
                if (link.disabled) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              {link.text}
            </NavBarItem>
          );
        })}
      </TopNavBar>
    </div>
  );
};

MultiRecordTopNav.propTypes = {
  employeeId: PropTypes.string,
  path: PropTypes.string,
  patientId: PropTypes.string,
  query: PropTypes.object,
};

export default MultiRecordTopNav;
