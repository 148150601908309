import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';

import TagHelpers from '../TagHelpers';

/**
 * Component that manages the basics of rendering a cell in the tags lists;
 * manages some basic behaviors (onClick, setting itself to an active state, etc).
 *
 * Requires a data model (the record to be shown), a data store to check for the
 * active state of the row (is the cell located in a row for an active record?),
 * and a baseUrl value to use when creating links.
 *
 * @extends React.Component
 */
const BaseCell = observer(
  class BaseCell extends React.Component {
    static propTypes = {
      data: PropTypes.object.isRequired,
      store: PropTypes.object.isRequired,
      baseUrl: PropTypes.string.isRequired,
      children: PropTypes.node,
    };

    goTo(opts) {
      TagHelpers.goTo(opts.baseUrl, opts.data);
    }

    render() {
      const { data, store, baseUrl, children, ...props } = this.props;

      return (
        <Cell
          onClick={this.goTo.bind(this, { baseUrl, data })}
          className={classnames({
            'record__cell--active': data.id === store.activeTagId,
          })}
          {...props}
        >
          {children}
        </Cell>
      );
    }
  }
);

BaseCell.displayName = 'BaseCell';

export default BaseCell;
