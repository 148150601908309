import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';
import { TYPE_ENUMS, FILTERED_TEXT } from './const';
import PropTypes from 'prop-types';
import MultiRecordCompositeFieldTieredValue from './TieredValue';
import Anchor from '../../ui/Anchor';
import Address from '../../people/Address';
import moment from 'moment';
import { generateMRVPersonLink } from '../utils/LinkUtils';
import pluralize from '../../utils/pluralize';
import classnames from 'classnames';
import { generateHighlightedText } from '../utils/helpers';
import FormattedLastActivityDate from '../../people/PersonBox/FormattedLastActivityDate';

const MultiRecordCompositeFieldValue = ({ field, filteredTerm }) => {
  const [showMore, setShowMore] = useState(false);

  let value;
  switch (field.type) {
    case TYPE_ENUMS.address:
      value = (
        <Address
          address={field.addressDetails}
          className={styles.text}
          usage={'forMRV'}
          filteredTerm={filteredTerm}
        />
      );
      break;
    case TYPE_ENUMS.object:
      value = (
        <MultiRecordCompositeFieldTieredValue
          fieldWithTieredValues={field}
          filteredTerm={filteredTerm}
        />
      );
      break;
    case TYPE_ENUMS.empty:
      value = (
        <p className={styles.text}>
          <i>{field.value}</i>
        </p>
      );
      break;
    case TYPE_ENUMS.filtered:
      value = (
        <p className={styles.text}>
          <i>{FILTERED_TEXT}</i>
        </p>
      );
      break;
    case TYPE_ENUMS.email:
      value = (
        <p className={styles.text}>
          <Anchor tag={'a'} href={`mailto:${field.value}`}>
            {generateHighlightedText(field.value, filteredTerm)}
          </Anchor>
        </p>
      );
      break;
    case TYPE_ENUMS.personLink:
      value = (
        <Link
          to={
            generateMRVPersonLink(
              field.links[0]?.personType,
              field.links[0]?.personId
            )?.personLink
          }
          className={styles.compositeFieldValueText}
        >
          {generateHighlightedText(field.value, filteredTerm)}
        </Link>
      );
      break;
    case TYPE_ENUMS.multiPersonLink:
      value = (
        <>
          <p
            className={classnames(styles.compositeFieldValueText, {
              [styles.hideMultiPersonLinkText]: showMore,
            })}
          >
            <Link
              to={
                generateMRVPersonLink(
                  field?.links[0]?.personType,
                  field?.links[0]?.personId
                )?.personLink
              }
              className={styles.compositeFieldValueText}
            >
              {generateHighlightedText(field.value, filteredTerm)}
            </Link>
            {field?.links?.length > 1 && ` & `}
            <span
              className={`${styles.compositeFieldValueText} ${styles.multiPersonLinkViewMore}`}
              onClick={() => setShowMore(!showMore)}
            >
              {field?.links?.length > 1
                ? `${field?.links?.length} ${pluralize(
                    field?.links?.length,
                    'Other'
                  )}`
                : ''}
            </span>
          </p>
          {field?.links?.map((link, i) => {
            return (
              <div
                className={styles.multiPersonLinkContainer}
                key={`${link.personName}${i}`}
              >
                <Link
                  to={
                    generateMRVPersonLink(link?.personType, link?.personId)
                      ?.personLink
                  }
                  className={classnames(styles.compositeFieldValueText, {
                    [styles.hideMultiPersonLinkText]: !showMore,
                  })}
                >
                  {generateHighlightedText(link?.personName, filteredTerm)}
                  {i !== field?.links?.length - 1 && `, `}
                </Link>
              </div>
            );
          })}
        </>
      );
      break;
    case TYPE_ENUMS.date:
      value = (
        <p className={styles.text}>
          {field.date
            ? generateHighlightedText(
                moment(field.date)
                  .utc()
                  .format('M/D/YYYY h:mm A'),
                filteredTerm
              )
            : ''}
        </p>
      );
      break;
    case TYPE_ENUMS.activityLink:
      value = (
        <p className={styles.text}>
          <FormattedLastActivityDate
            userId={field?.links[0]?.personId}
            date={field.date}
          />
        </p>
      );
      break;
    case TYPE_ENUMS.unknown:
    case TYPE_ENUMS.string:
    default:
      value = (
        <p className={styles.text}>
          {generateHighlightedText(field.value, filteredTerm)}
        </p>
      );
      break;
  }
  return <div className={styles.compositeFieldValue}>{value}</div>;
};

MultiRecordCompositeFieldValue.propTypes = {
  field: PropTypes.object,
  filteredTerm: PropTypes.string,
};

export default MultiRecordCompositeFieldValue;
