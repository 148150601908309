import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import styles from './index.module.scss';
import Tooltip from '../../ui/Tooltip';
import classnames from 'classnames';

const MultiRecordCompositeFieldSources = ({ sources }) => {
  return (
    <div className={styles.compositeFieldSource}>
      <Tooltip
        className={styles.tooltipOverrides}
        content={sources?.map(({ source, date }, i) => (
          <p key={`${source}${i}`} className={styles.tooltipText}>
            <span className={styles.tooltipBold}>{source}</span> as of{' '}
            {date ?? <span className={styles.tooltipItalic}>not provided</span>}
          </p>
        ))}
        placement="bottom"
      >
        <p className={classnames(styles.text, styles.sourceText)}>
          {sources && sources?.length === 1
            ? sources[0]?.source
            : sources
            ? `${sources?.length} Sources`
            : null}
        </p>
      </Tooltip>
    </div>
  );
};

MultiRecordCompositeFieldSources.propTypes = {
  sources: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
};

export default MultiRecordCompositeFieldSources;
