import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { autorun, computed, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';

/*
 * This is a re-usable component for creating donut charts. It can be easily
 * extended for customization, and has the various life-cycles of chart
 * rendering exposed as separate methods to make this eaiser (so you don't need
 * to re-write the whole render/update cycle to tweak one small aspect).
 *
 * This component must be used inside of a <ChartCanvas /> component.
 */
class DonutChart extends Component {
  static propTypes = {
    ringWidth: PropTypes.number,
    className: PropTypes.string,
    label: PropTypes.string,
    includeZeroes: PropTypes.bool,
    colors: PropTypes.shape({}),
    canvasSize: PropTypes.number.isRequired,
    store: PropTypes.shape({
      margin: PropTypes.shape({
        top: PropTypes.number,
        right: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
      }).isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          count: PropTypes.number,
          group: PropTypes.string,
        })
      ),
    }),
    dataProp: PropTypes.string,
    mouseover: PropTypes.func,
    mouseout: PropTypes.func,
    click: PropTypes.func,
    animationDuration: PropTypes.number,
    animationEasing: PropTypes.func,
  };

  static defaultProps = {
    animationDuration: 150,
    animationEasing: d3.easeCubicInOut,
  };

  // Ref
  chart = React.createRef();

  // Observable
  height = 0;
  width = 0;
  parentHeight = 0;
  parentWidth = 0;

  // NOT Observable
  disposers = [];
  paths = null;

  /**
   * COMPUTED
   * Compute the total value of items in the chart
   * @return {Number} the calculated value.
   */
  get total() {
    const { store, dataProp } = this.props;
    const data = dataProp ? store[dataProp] : store.data;

    return data.reduce(
      (total, d) =>
        d && Object.prototype.hasOwnProperty.call(d, 'count')
          ? total + d.count
          : total,
      0
    );
  }

  /**
   * COMPUTED
   * Retrieve the chart data from the data store.
   * @return {Array} an Array containing the data points
   */
  get data() {
    const { store, dataProp } = this.props;
    return store ? (dataProp ? store[dataProp] : store.data) : [];
  }

  /**
   * COMPUTED
   * Compute the pie chart's radius based on the observed width.
   * @return {Number} the calculated radius
   */
  get radius() {
    return this.width / 2;
  }

  /**
   * COMPUTED
   * Calculate the horizontal offset for the chart rendering
   * @return {Number} the calculated offset
   */
  get xOffset() {
    return this.radius + (this.parentWidth - this.width) / 2;
  }

  /**
   * COMPUTED
   * Calculate the vertical offset for the chart rendering
   * @return {Number} the calculated offset
   */
  get yOffset() {
    return this.parentHeight / 2;
  }

  /*
   * Actions to run when the component first mounts; add event listeners,
   * render the chart, add autorun function that will update the chart as
   * needed.
   */
  componentDidMount() {
    this.renderChart();
    this.setSize();

    this.disposers.push(
      autorun(() => {
        this.updateChart();
      })
    );
  }

  setSize = () => {
    const { canvasSize, store } = this.props;
    const { margin } = store;
    // it's a square chart, so set height and width to the same value (height)
    this.height = canvasSize - (margin.top + margin.bottom);
    this.width = canvasSize - (margin.left + margin.right);
    this.parentHeight = canvasSize;
    this.parentWidth = canvasSize;
  };

  componentDidUpdate() {
    this.updateChart();
  }

  /*
   * Actions to run when the component unmounts; remove event listeners,
   * destroy autorun listeners.
   */
  componentWillUnmount() {
    this.disposers.forEach(d => d());
  }

  mouseover = (...args) => {
    const { mouseover } = this.props;
    if (mouseover) mouseover.apply(this, args);
  };

  mouseout = (...args) => {
    const { mouseout } = this.props;
    if (mouseout) mouseout.apply(this, args);
  };

  click = (...args) => {
    const { click } = this.props;
    if (click) click.apply(this, args);
  };

  /**
   * Store a reference to the primary node in this chart for easy reference
   * later; svg is a little bit of a misnomer in that the element is actually a
   * g element, but the convention for accessing the root node of something
   * when building a D3 component is to call it SVG
   */
  get svg() {
    return d3.select(this.chart.current);
  }

  /*
   * Update the segments in the chart to represent new data as it's received.
   * Will also draw the pie segments if they're missing.
   */
  updateChart() {
    const { ringWidth, label, includeZeroes, colors } = this.props;
    const data = includeZeroes ? this.data : this.data.filter(d => d.count > 0);
    const pie = d3
      .pie()
      .sort(null)
      .value(d => d.count);
    const arc = d3
      .arc()
      .padAngle(0.02)
      .innerRadius(this.radius - (ringWidth || 30))
      .outerRadius(this.radius);
    const group = this.svg;
    const paths = group.selectAll('path').data(pie(data));

    /**
     * Private method to handle animating the pie segments
     * @param {Object} a something
     * @return {Function} Function
     */
    function arcTween(a) {
      const i = d3.interpolate(this._current, a);
      this._current = i(0);
      return t => arc(i(t));
    }

    // resize the SVG container if needed
    group
      .attr('width', this.parentWidth)
      .attr('height', this.parentHeight)
      .select('g')
      .attr('transform', `translate(${this.xOffset}, ${this.yOffset})`);

    paths
      .enter()
      .append('path')
      .classed('chart__donut-segment', true)
      .attr('d', arc)
      .attr('data-group', ({ data }) => data.group)
      .attr('data-tippy-content', ({ data }) => data.group)
      .attr('data-tippy-animation', 'fade')
      .attr('data-tippy-followCursor', 'true')
      .attr('data-tippy-arrow', 'true')
      .attr('data-effect', 'float')
      .attr('fill', d => {
        if (colors && d.data?.type) return colors[d.data.type];
        else return '#BFD4F5';
      })
      .on('mouseover', this.mouseover)
      .on('mouseout', this.mouseout)
      .on('click', this.click)
      .each(function(d) {
        this._current = d;
      }); // store the initial angles

    paths.exit().remove();

    paths
      .transition()
      .duration(500)
      .ease(d3.easeCubicInOut)
      .attrTween('d', arcTween);

    // Update the data-group attributes and the fill values and data tips
    paths._groups.forEach(path => {
      path.forEach((p, idx) => {
        if (p._tippy) p._tippy.destroy(); //Need this for avoiding multiple tooltips
        p.setAttribute('data-group', data[idx].group);
        p.setAttribute('data-tippy-content', data[idx].group);
        p.setAttribute('data-tippy-animation', 'fade');
        p.setAttribute('data-tippy-followCursor', 'true');
        p.setAttribute('data-tippy-arrow', 'true');
      });
    });

    group.select('.chart__donut-total-value').text(this.total);

    if (label) {
      group.select('.chart__donut-total-label').text(label);
    }
  }

  /*
   * Method that does the initial chart rendering. Will construct the necessary
   * `svg` and `g` elements
   */
  renderChart() {
    const { label } = this.props;

    this.chart.current.innerHTML = '';

    this.updateChart();

    this.svg
      .append('text')
      .classed('chart__donut-total-value', true)
      .attr('dy', label ? 5 : 6)
      .text(this.total);

    if (label) {
      this.svg
        .append('text')
        .classed('chart__donut-total-label', true)
        .attr('dy', 25)
        .text(label);
    } else {
      this.svg
        .append('text')
        .classed('chart__donut-total-title', true)
        .attr('dy', label ? 15 : 39)
        .text('Total cases');
    }
  }

  render() {
    const { className, label } = this.props;

    let rootClassName = 'chart__donut-chart';

    if (label) rootClassName += ' chart__donut--with-label';

    if (className) rootClassName += ` ${className}`;

    return (
      <g
        className={rootClassName}
        ref={this.chart}
        transform={`translate(${this.xOffset}, ${this.yOffset})`}
      />
    );
  }
}

decorate(DonutChart, {
  height: observable,
  width: observable,
  parentHeight: observable,
  parentWidth: observable,
  total: computed,
  data: computed,
  radius: computed,
  xOffset: computed,
  yOffset: computed,
});

export default observer(DonutChart);
