import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import GRCCaseStore from '../../stores/GRCCaseStore';
import GRCCaseForm from '../GRCCaseForm';

export function mapProps(GRCCaseStore, match) {
  const caseId = match.params.caseId;
  const {
    canSubmitEdit,
    caseType,
    caseResolution,
    failureStatus,
    title,
    startDate,
    endDate,
    reset,
    setValue,
    submitting,
    updateCase,
  } = GRCCaseStore;
  return {
    caseId: caseId,
    caseResolution,
    caseType,
    canSubmit: canSubmitEdit,
    editing: true,
    failureStatus,
    endDate,
    onChange: setValue,
    reset,
    startDate,
    submitButtonValue: 'Save',
    submitFunction: updateCase,
    submitting,
    title,
  };
}

const EditGRCCaseContainer = ({ match }) => (
  <GRCCaseForm {...mapProps(GRCCaseStore, match)} />
);

EditGRCCaseContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      caseId: PropTypes.string,
    }),
  }),
};

export default observer(EditGRCCaseContainer);
