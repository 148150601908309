import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const RadioButton = ({ disabled, options, selectedOption, setValue }) => {
  return (
    <ul className={styles.radioButtons}>
      {Object.keys(options).map(key => {
        return (
          <li className="radio" key={key}>
            <input
              disabled={disabled}
              id={key}
              type="radio"
              value={key}
              checked={key === selectedOption}
              onChange={() => setValue(key)}
            />
            <label htmlFor={key}>{options[key]}</label>
          </li>
        );
      })}
    </ul>
  );
};

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.object,
  selectedOption: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

export default RadioButton;
