import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';

import { FullName, MedicationUtils, DateHelpers } from 'common';

import Store from '../../stores/Store';
import Detail from '../Detail';
import Medication from '../Medication';
import MedicationOrder from '../MedicationOrder';
import WitnessUser from '../../eventTableCells/WitnessingUser';

const MedicationHandling = ({ handling, nested }) => {
  const {
    action,
    adminDose,
    enteredQty,
    eventDate,
    eventDescription,
    location,
    medication,
    medicationOrder,
    numAfter,
    numBefore,
    orderOverride,
    source,
    station,
    transactionQty,
    user,
    witnessedBy,
  } = handling;
  const timezone = DateHelpers.getCurrentAppUserTimezone();

  let userEl;
  if (user) {
    const ref = Store.getUserLink({ userId: user.id });
    userEl = (
      <Link key={user.id} to={ref}>
        <FullName person={user} />
      </Link>
    );
  }

  return (
    <ul className={classnames('list-unstyled', { 'list-nested': nested })}>
      <Detail
        label={eventDate.tz(timezone).format('dddd, MMMM D, YYYY h:mm:ss A z')}
        value={eventDescription}
        source={source}
      />
      <Detail label="User" value={userEl} />
      <WitnessUser witness={witnessedBy} warning={action === 'Disposed'} />
      <Detail label="Administration Dosage" value={adminDose} />
      <Detail label="Entered Quantity" value={enteredQty} />
      <Detail
        label="Transaction Quantity"
        value={
          transactionQty === undefined
            ? undefined
            : MedicationUtils.formatDecimal(transactionQty)
        }
      />
      <Detail label="Number Prior" value={numBefore} />
      <Detail label="Number After" value={numAfter} />
      <Detail
        label="Order Override"
        value={orderOverride ? 'Yes' : undefined}
      />
      <Detail label="Location" value={location} />
      <Detail label="Station" value={station} />
      {medication && <Medication medication={medication} />}
      {medicationOrder && <MedicationOrder order={medicationOrder} nested />}
    </ul>
  );
};

MedicationHandling.propTypes = {
  nested: PropTypes.bool,
  handling: PropTypes.shape({
    action: PropTypes.string,
    adminDose: PropTypes.shape({}),
    enteredQty: PropTypes.shape({}),
    eventDate: PropTypes.instanceOf(moment).isRequired,
    eventDescription: PropTypes.string,
    location: PropTypes.string,
    medication: PropTypes.shape({}),
    medicationOrder: PropTypes.shape({}),
    numAfter: PropTypes.number,
    numBefore: PropTypes.number,
    orderOverride: PropTypes.bool,
    source: PropTypes.string,
    station: PropTypes.string,
    transactionQty: PropTypes.number,
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
    witnessedBy: PropTypes.shape({}),
  }).isRequired,
};

MedicationHandling.defaultProps = {
  nested: false,
};

export default MedicationHandling;
