import React from 'react';

/**
 * Creates a component that displays a default string at first or when the "Any"
 * option of a dropdown is selected.
 *
 * To be used as the valueComponent prop for a DropdownList
 *
 * @param defaultDisplay
 * @return {function({item?: *}): (*)}
 */
const DropdownValueComponentFactory = defaultDisplay => ({ item }) => {
  if (!item || !item.value) {
    return defaultDisplay;
  }
  return (
    <span>
      <strong>{defaultDisplay}: </strong>
      {item.name}
    </span>
  );
};

export default DropdownValueComponentFactory;
