import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { deepReplaceValue } from './app/utils/deepReplace';
import { detectEnvironment } from './app/utils/HostUtils';
import { scrubbableParams } from './app/utils';

// setup regex query
var queryParamsRegex = new RegExp("(".concat(scrubbableParams.join('|'), ")=[^&]+"), 'gi');
var subStringQueryReplacer = '$1=####';
export var initializeSentry = function initializeSentry() {
  var _window, _window$location;
  var BUILD_NUMBER = process.env.REACT_APP_BUILD_ID || 'no-number';
  var release = 'product-build@' + BUILD_NUMBER;
  Sentry.init({
    dsn: 'https://1449f5c17a7e0bb8f40e79bc69ffd7f9@o4504922227867648.ingest.us.sentry.io/4507726053638144',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.8,
    maxBreadcrumbs: 50,
    environment: detectEnvironment((_window = window) === null || _window === void 0 ? void 0 : (_window$location = _window.location) === null || _window$location === void 0 ? void 0 : _window$location.host),
    release: release,
    beforeSend: function beforeSend(event) {
      var scrubbedEvent = deepReplaceValue(event, queryParamsRegex, subStringQueryReplacer);
      return scrubbedEvent;
    }
  });
  Sentry.configureScope(function (scope) {
    scope.setExtra('BUILD_NUMBER', BUILD_NUMBER);
  });
};