import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Authenticated, ProtectedRoute } from 'common';

import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import CaseReportsRootView from '../../reports/CaseReportsRootView';
import PrivacyStatementViewContainer from '../../statements/privacyStatement/PrivacyStatementViewContainer';
import ApplyBodyClassName from '../../ui/ApplyBodyClassName';

const ReportsRootView = ({ location }) => {
  return (
    <main className="reports_view">
      <TopNavBar secondary>
        <Authenticated permission="PRIVACY_STATEMENT_VIEW">
          <NavBarItem
            route="/reports/summary"
            active={location?.pathname?.includes('/reports/summary')}
          >
            Summary
          </NavBarItem>
        </Authenticated>
        <NavBarItem route="/reports/cases">Cases</NavBarItem>
      </TopNavBar>
      <ApplyBodyClassName className="reports" />
      <Switch>
        <ProtectedRoute
          path="/reports/summary/:statementId?"
          component={PrivacyStatementViewContainer}
        />
        <ProtectedRoute path="/reports/cases" component={CaseReportsRootView} />
        <Redirect to="/reports/cases" />
      </Switch>
    </main>
  );
};

export default ReportsRootView;

ReportsRootView.propTypes = {
  location: PropTypes.object,
};
