import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { HalUtils } from 'common';
import moment from 'moment';
import styles from './index.module.scss';

/**
 * Layer responsible for drawing vertical backgrounds for timeEntry data.
 */
class TimeEntryLayer extends React.Component {
  // Ref
  group = React.createRef();

  componentDidMount() {
    this.renderTimeEntries();
  }

  componentDidUpdate() {
    this.renderTimeEntries();
  }

  renderTimeEntries() {
    const {
      loading,
      timeEntries,
      brushedXScale,
      margin,
      detailView,
    } = this.props;

    if (loading) return;

    const data = timeEntries;
    const targetNode = d3.select(this.group.current);
    const rects = targetNode.selectAll('rect').data(data);

    const enterSelection = rects
      .enter()
      .append('rect')
      .style('opacity', 0.3)
      .attr('height', '300%');

    rects.merge(enterSelection).each(function(d) {
      const node = d3.select(this);
      let isDrawer = false;

      if (detailView && detailView.class === 'timeEntry') {
        const id = HalUtils.getId(d);
        isDrawer = id === detailView.id || id + '_end' === detailView.id;
      }

      // handle reversed start and end time
      if (d.start && d.end && moment(d.start).isAfter(d.end)) {
        const temp = d.start;
        d.start = d.end;
        d.end = temp;
      }

      if (d.start || d.end) {
        const d1 = d.start ? moment(d.start) : moment(d.end).subtract(4, 'h'),
          x = brushedXScale(d1),
          d2 = d.end ? moment(d.end) : moment(d.start).add(4, 'h'),
          x2 = brushedXScale(d2),
          width = x2 - x || 1;

        let gradient = '#327bff';
        if (d.start && !d.end) gradient = 'url(#no-clock-out)';
        if (!d.start && d.end) gradient = 'url(#no-clock-in)';

        if (isDrawer) gradient = '#643ef6';
        if (d.start && !d.end && isDrawer)
          gradient = 'url(#no-clock-out-focus)';
        if (!d.start && d.end && isDrawer) gradient = 'url(#no-clock-in-focus)';

        node
          .attr('width', Math.max(width, 1))
          .attr('transform', `translate(${margin.left + x},0)`)
          .style('fill', gradient);
      }
    });

    rects.exit().remove();
  }

  render() {
    return (
      <g className={styles.timeEntries} ref={this.group}>
        <linearGradient id="no-clock-in">
          <stop offset="0%" stopOpacity="0" stopColor="#327bff" />
          <stop offset="100%" stopColor="#327bff" stopOpacity="1" />
        </linearGradient>
        <linearGradient id="no-clock-out">
          <stop offset="0%" stopColor="#327bff" />
          <stop offset="100%" stopColor="#327bff" stopOpacity="0" />
        </linearGradient>
        <linearGradient id="no-clock-in-focus">
          <stop offset="0%" stopOpacity="0" stopColor="#643ef6" />
          <stop offset="100%" stopColor="#643ef6" stopOpacity="1" />
        </linearGradient>
        <linearGradient id="no-clock-out-focus">
          <stop offset="0%" stopColor="#643ef6" />
          <stop offset="100%" stopColor="#643ef6" stopOpacity="0" />
        </linearGradient>
      </g>
    );
  }
}

TimeEntryLayer.propTypes = {
  loading: PropTypes.bool.isRequired,
  timeEntries: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  brushedXScale: PropTypes.func.isRequired,
  margin: PropTypes.shape({
    left: PropTypes.number,
  }),
  detailView: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }),
};

TimeEntryLayer.defaultProps = {
  timeEntries: [],
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  detailView: {},
};

export default TimeEntryLayer;
