import moment from 'moment';
import { autorun, computed, decorate } from 'mobx';
import { SingletonStore } from 'common';

/**
 * Houses a summary report containing an array of objects with:
 * - count (number of audit logs within a time period)
 * - createdBy (whodunnit)
 * - date
 */
class AuditLogSummaryStore extends SingletonStore {
  constructor({ appAuditClient, auditLogStore, auditLogFilterStore }) {
    super();

    this.auditLogFilterStore = auditLogFilterStore;
    this.appAuditClient = appAuditClient;
    this.auditLogStore = auditLogStore;

    this.disposers = [
      autorun(() => {
        this.refresh();
      }),
    ];
  }

  tearDown = () => {
    this.disposers.forEach(d => d());
  };

  fetch() {
    return this.auditLogFilterStore?.dates[0]
      ? this.appAuditClient.getSummaryReport(
          this.auditLogFilterStore?.dates[0],
          this.auditLogFilterStore?.dates[1].clone().add(1, 'days')
        )
      : [];
  }

  // computed
  get data() {
    const data = (this.result || [])
      .filter(i => i.createdBy)
      .map(i => {
        return {
          ...i,
          date: moment(i.date).toDate(),
        };
      });

    // Add additional data for an "all" user by summing everything together for
    // each date.
    const dataForAll = Array.from(
      data
        .reduce((map, cur) => {
          const date = cur.date.toString();
          map.set(date, (map.get(date) ?? 0) + cur.count);
          return map;
        }, new Map())
        .entries()
    ).map(([date, count]) => ({
      date: new Date(date),
      count,
      createdBy: { id: 'all', username: 'all' },
    }));

    return [...data, ...dataForAll];
  }

  // computed
  get users() {
    // Convert an array of {createdBy} objects to a unique list of users.
    return Array.from(
      this.data
        .map(d => d.createdBy)
        .reduce((map, cur) => {
          map.set(cur.id, cur);
          return map;
        }, new Map())
        .values()
    );
  }
}

decorate(AuditLogSummaryStore, {
  data: computed,
  users: computed,
});

export { AuditLogSummaryStore };
