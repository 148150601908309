import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'moment';

import SmartReferenceStore from '../../activity/stores/SmartReferenceStore';
import Store from '../../activity/stores/Store';

function SmartReference({
  caseId,
  caseNumber,
  description,
  maxEventDate,
  minEventDate,
  patientId,
  userId,
}) {
  if (!description) return null;
  // DESCRIPTION ==> This user accessed a potential [co-worker|patient:5d3b2783e8f43656dd890b72] on this day
  const section = description.split(/\[(.*?[|].*?)\]/); // split the description on the beginning [ to the ] will give you the following array
  // section ==> ['This user accessed a potential ', 'co-worker|patient:5d3b2783e8f43656dd890b72', ' on this day' ]

  // if section is length one that means the description doesn't have a smart reference so just return it
  if (section.length === 1) return description;

  const [beginningString, dataPortion, endString] = section;
  const [keys, idPortion] = dataPortion.split(':'); // Keys => "co-worker|patient", idPortion => "5d3b2783e8f43656dd890b28"
  const [linkKey, personType] = keys.split('|'); // linkKey => 'co-worker', personType => 'patient'
  const ids = idPortion.split(','); // ["5d3b2783e8f43656dd890b4e","5d3b2783e8f43656dd890b2e"]

  const linkOptions = {
    fromDate: minEventDate,
    toDate: maxEventDate,
  };

  // if the person type is patient we need to link to the user and set the patients in localStorage
  if (personType === 'patient') {
    linkOptions.userId = userId;
    // if there is only one id go to the double activity view and set the patient ID
    if (ids.length === 1) linkOptions.patientId = ids[0];
  } else if (personType === 'user') {
    // currently ( 8/19 ) we don't have any user types but left this in here for easy future life
    linkOptions.patientId = patientId;
    if (ids.length === 1) linkOptions.userId = ids[0];
  }

  let link;
  // if there is only one person get the double person page link, else get the single person link
  if (ids.length === 1)
    link = Store.getUserPatientLinks(linkOptions).userPatientActivity;
  else
    link =
      personType === 'patient'
        ? Store.getUserLink(linkOptions)
        : Store.getPatientLink(linkOptions);

  const smartReferenceObj = {
    ids,
    description: `${beginningString}${linkKey}${endString}`,
    caseLink: caseId,
    caseNumber,
    type: personType,
    caseUserId: userId,
  };

  return (
    <span>
      {beginningString}
      <Link
        to={link}
        onClick={() => SmartReferenceStore.setSmartReference(smartReferenceObj)}
      >
        {linkKey}
      </Link>
      {endString}
    </span>
  );
}

SmartReference.propTypes = {
  caseId: PropTypes.string,
  caseNumber: PropTypes.number,
  description: PropTypes.string.isRequired,
  maxEventDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Moment),
    PropTypes.string,
  ]),
  minEventDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Moment),
    PropTypes.string,
  ]),
  patientId: PropTypes.string,
  userId: PropTypes.string.isRequired,
};

SmartReference.defaultProps = {
  caseId: null,
  caseNumber: null,
  patientId: null,
  minEventDate: '',
  maxEventDate: '',
};

export default SmartReference;
