import { action, computed, decorate, observable } from 'mobx';

import {
  PersonTagsClient,
  PermissionStore,
  EnumValueStore,
  DownloadUtils,
  HalUtils,
} from 'common';

import PersonTagsCountStore from '../../../stores/PersonTagsCountStore';

const DEFAULT_SORT = 'name,asc';

const parseSort = sort => {
  const splits = sort.split(',');
  return {
    sortBy: splits[0],
    sortDir: splits[1],
  };
};
/**
 * Store class for managing the display of user tags (e.g., "Vendor") pulled from
 * the database.
 */
class UserTagsStore {
  // Observables
  sort = DEFAULT_SORT;
  activeTagId = null;

  // Computed
  get sortBy() {
    const { sortBy } = parseSort(this.sort);
    return sortBy;
  }

  // Computed
  get sortDir() {
    const { sortDir } = parseSort(this.sort);
    return sortDir;
  }

  // Computed
  get tagCounts() {
    return PersonTagsCountStore.counts;
  }

  // Computed
  get canView() {
    return PermissionStore.has('PERSON_TAG_VIEW');
  }

  // Computed
  get size() {
    return EnumValueStore.userTags.length;
  }

  // Computed
  get loading() {
    return EnumValueStore.loading;
  }

  /**
   * COMPUTED
   * Returns the tag currently referenced by the activeTagId observable, if any
   */
  get activeTag() {
    let tag = this.results.find(r => r.id === this.activeTagId);

    if (tag) {
      tag = Object.assign({}, tag); // make a copy of the object so we don't transform the original
      tag.count = PersonTagsCountStore.countFor(tag.name);
    }

    return tag;
  }

  /**
   * COMPUTED
   * sort the results list and return the sorted list
   */
  get results() {
    if (this.canView) {
      // clone the userTags array since #sort is done inline
      const sortBy = this.sortBy;
      const sortDir = this.sortDir;
      const values = EnumValueStore.userTags.map(tag => {
        const _tag = Object.assign({}, tag);
        _tag.id = HalUtils.getId(tag);
        return _tag;
      });

      values.sort((a, b) => {
        let first = a[sortBy];
        let second = b[sortBy];

        // combine firstName and lastName for sorting createdBy
        if (sortBy === 'createdBy') {
          first = `${first.firstName || ''} ${first.lastName || ''}`;
          second = `${second.firstName || ''} ${second.lastName || ''}`;
        } else if (sortBy === 'name') {
          first = first.toLowerCase();
          second = second.toLowerCase();
        }
        // ... and we can add more vectors here if needed, otherwise the property
        // named in sortBy will be used

        // compare the two parameters and return the sort value
        if (first > second) return sortDir === 'asc' ? 1 : -1;
        if (first < second) return sortDir === 'asc' ? -1 : 1;
        return 0;
      });

      return values;
    } else {
      return [];
    }
  }

  // Computed
  get activeTags() {
    return this.results.filter(r => !r.deprecated);
  }

  // Action
  setActiveUserTagId = id => (this.activeTagId = id);

  // Action
  setFilters = query => {
    const { sort } = query;
    this.sort = sort || DEFAULT_SORT;
    PersonTagsCountStore.initialized = true;
  };

  // Action
  reset() {
    PersonTagsCountStore.initialized = false;
    this.setFilters({});
  }

  nextPage() {
    return null; // no-op to make ScollPager happy
  }

  create(params) {
    return PersonTagsClient.create(params).then(r => {
      EnumValueStore.refresh();
      return r;
    });
  }

  downloadCSV() {
    const csvHref = PersonTagsClient.tagUrl('export/user', { size: 10000 });
    DownloadUtils.downloadFromServer(csvHref, 'UserTags.csv');
  }
}

decorate(UserTagsStore, {
  activeTag: computed,
  activeTagId: observable,
  activeTags: computed,
  canView: computed,
  loading: computed,
  reset: action,
  results: computed,
  size: computed,
  sortBy: computed,
  sortDir: computed,
  tagCounts: computed,
  setFilters: action,
  setActiveUserTagId: action,
  sort: observable,
});

export default new UserTagsStore();
