import AuditLog from '../AuditLog';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { AuditLogContext } from '../Context';

export function mapProps({
  auditLogStore,
  auditLogFilterStore,
  history,
  query,
}) {
  return {
    onChange: auditLogStore.setValue,
    chartData: auditLogStore.chartData,
    tableData: auditLogStore.tableData,
    tablePager: auditLogStore.tablePager,
    toggleOpen: () =>
      history.push(auditLogFilterStore.nextAdvancedFiltersURL()),
    setQueryFromURL: auditLogFilterStore.setQueryFromURL,
    query,
    isLoading: auditLogFilterStore.isLoading,
  };
}

const AuditLogContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: AuditLogContext,
    mapProps,
    PresentationalComponent: AuditLog,
  })
);

AuditLogContainer.displayName = 'AuditLogContainer';

export default AuditLogContainer;
