import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import PersonAttribute from '../PersonAttribute';

const OtherAlias = ({ mode, link }) => {
  if (!link) return null;

  return (
    <PersonAttribute
      content={
        mode === 'user' ? 'View Patient Medical Record' : 'View EMR User Record'
      }
      icon={cn({
        'icon-folder_shared': mode === 'user',
        'icon-local_hospital': mode === 'patient',
      })}
      link={link}
      screenOnly
    />
  );
};

OtherAlias.propTypes = {
  mode: PropTypes.oneOf(['patient', 'user']).isRequired,
  link: PropTypes.string,
};

OtherAlias.defaultProps = {
  link: null,
};

export default OtherAlias;
