import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { MetadataCount } from './MetadataCount';

export const MultiRecordMetadataSummary = ({ metadataTypes, match }) => {
  return (
    <>
      {metadataTypes?.map(metadata => {
        return (
          <MetadataCount
            key={metadata.title}
            metadata={metadata}
            currentPath={match.url}
          />
        );
      })}
    </>
  );
};

MultiRecordMetadataSummary.propTypes = {
  match: PropTypes.object,
  metadataTypes: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object),
};
