import React from 'react';
import { AlertCategoryStore, GroupStore, SettingsStore } from 'common';
import classnames from 'classnames';
import dimensions from 'react-dimensions';
import PropTypes from 'prop-types';
import SettingsListDetail from '../SettingsListDetail';

export const SettingsList = class SettingsList extends React.Component {
  componentDidMount() {
    SettingsStore.init();
  }

  render() {
    const groupData = AlertCategoryStore.groupsWithAutomatedCategories;

    /* Display regular table for one group, collapsible sections for multiple groups */
    const displayGroups = GroupStore.groups.length > 1;
    const { containerWidth, toggleGroup } = this.props;

    return (
      <section
        className={classnames('registry__table settings__table', {
          disabled: !SettingsStore.creatingCases,
        })}
      >
        {groupData.map(item => {
          const [name, data] = item;
          return (
            <SettingsListDetail
              key={name}
              toggleGroupPlay={toggleGroup}
              data={data}
              name={name}
              width={containerWidth}
              displayGroups={displayGroups}
            />
          );
        })}
        <small className="float-right">
          In the event of more than one assignee, the assigned user will be
          chosen in the order they appear.
        </small>
        <div className="clearfix" />
      </section>
    );
  }
};

SettingsList.propTypes = {
  containerWidth: PropTypes.number.isRequired,
  toggleGroup: PropTypes.func.isRequired,
};

export default dimensions({
  elementResize: true,
})(SettingsList);
