import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import styles from './index.module.scss';
import { ApplyBodyClassName, DocumentTitle, Loader } from '../../ui';
import { Authenticated } from 'common';
import moment from 'moment';
import ActionNav from '../../navbar/ActionNav';
import AuditLogAdvancedFiltersContainer from '../AuditLogAdvancedFiltersContainer';
import AuditLogChart from '../AuditLogChart';
import AuditLogTable from '../AuditLogTable';

/* eslint-disable no-unused-vars */
const AuditLog = ({
  endDate,
  onChange,
  range,
  startDate,
  users,
  chartData,
  selectedUser,
  tableData,
  tablePager,
  toggleOpen,
  query,
  setQueryFromURL,
  isLoading,
}) => {
  useEffect(() => {
    setQueryFromURL(query);
  }, [query, setQueryFromURL]);
  return (
    <main className={styles.main}>
      <Authenticated appRole="ADMIN">
        <DocumentTitle text="Audit Log" />
        <ApplyBodyClassName className="user_activity" />
        <AuditLogAdvancedFiltersContainer />
        <div className={styles.auditLogWrapper}>
          <ActionNav
            secondary={true}
            count={tableData.length}
            title="Matching Audit Log Record"
            exportMenuWrapperClass={styles.exportMenuWrapper}
            eventTypes={[]}
            downloadCSVWithOptions={null}
            advancedFiltersOnClick={toggleOpen}
          />
          <section className={styles.content}>
            <div className={styles.body}>
              <Loader loaded={!isLoading}>
                <AuditLogChart data={chartData} selectedUser={selectedUser} />
                <AuditLogTable data={tableData} tablePager={tablePager} />
              </Loader>
            </div>
          </section>
        </div>
      </Authenticated>
    </main>
  );
};

AuditLog.propTypes = {
  endDate: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  range: PropTypes.string,
  startDate: PropTypes.instanceOf(moment),
  users: PropTypes.arrayOf(PropTypes.shape({})),
  chartData: PropTypes.array,
  selectedUser: PropTypes.object,
  tableData: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  tablePager: PropTypes.func,
  toggleOpen: PropTypes.func,
  setQueryFromURL: PropTypes.func,
  query: PropTypes.shape({}),
  isLoading: PropTypes.bool,
};

export default AuditLog;
