import { action, autorun, computed, decorate, observable } from 'mobx';
import moment from 'moment';

import { MetalogClient, SingletonStore } from 'common';

class EmployeeActivityStore extends SingletonStore {
  /**
   * Initialize the class instance, set a method that runs whenever an
   * observed property changes
   * @constructor
   */
  constructor() {
    super();
    autorun(() => {
      this.refresh();
    });
  }

  // Observable
  userId = null;

  // Action
  setUserId = userId => {
    this.userId = userId;
  };

  // Computed
  get date() {
    const r = this.response;
    return (
      (r && r.length && r.length > 0 && moment(r[r.length - 1].endTime)) || null
    );
  }

  /**
   * Fetch the most recent date of activity for a specific user.
   * @method fetch
   */
  fetch() {
    const userIdParam = this.userId;
    return userIdParam
      ? MetalogClient.getByUserRecent(userIdParam)
      : Promise.resolve();
  }
}

decorate(EmployeeActivityStore, {
  userId: observable,
  setUserId: action,
  date: computed,
});

export default new EmployeeActivityStore();
