import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import UserAccountLimitStore from '../../../stores/UserAccountLimitSettingsStore';
import UserAccountLimit from '../UserAccountLimit';

export function mapProps({ UserAccountLimitStore }) {
  return {
    update: UserAccountLimitStore.updateSettings,
    settingsLimit: UserAccountLimitStore.userAccountLimitSettings,
  };
}

const UserAccountLimitContainer = observer(({ onDirtyChange }) => {
  return (
    <UserAccountLimit
      {...mapProps({ UserAccountLimitStore })}
      onDirtyChange={onDirtyChange}
    />
  );
});
UserAccountLimitContainer.displayName = 'UserAccountLimitContainer';
UserAccountLimitContainer.propTypes = {
  onDirtyChange: PropTypes.func.isRequired,
};

export default UserAccountLimitContainer;
