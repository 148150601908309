import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import Store from '../../../stores/Store';
import EventDetailedStore from '../../../stores/EventDetailedStore';
import ChartStore from '../../../stores/ChartStore';
import DiversionActivityStore from '../../../stores/DiversionActivityStore';
import DiversionActivityChart from '../DiversionActivityChart';

/**
 * Provides props to the DiversionActivityChart.
 */

export function mapProps(
  Store,
  ChartStore,
  DiversionActivityStore,
  EventDetailedStore,
  history
) {
  return {
    diversionRowSelected: Store.isDiversionRowSelected,
    height: ChartStore.rowHeight,
    incidents: DiversionActivityStore.collatedIncidentsByKey,
    leftMargin: ChartStore.margin.left,
    leftPadding: ChartStore.padding.left,
    rightPadding: ChartStore.padding.right,
    xScale: ChartStore.brushedXScale,
    selectedIncident: EventDetailedStore.selectedIncident,
    selectedStatistic: DiversionActivityStore.selectedStatistic,
    statisticPeriods:
      DiversionActivityStore.statisticsWithoutOutOfBoundIncidents,
    onSelectIncident: EventDetailedStore.setIncidentFocus,
    onSelectRow: primaryEvent => history.push(Store.linkIncident(primaryEvent)),
    onSelectStatisticPeriodStartDate:
      DiversionActivityStore.selectStatisticSourceStartDate,
  };
}

const DiversionActivityChartContainer = withRouter(
  observer(({ history }) => (
    <DiversionActivityChart
      {...mapProps(
        Store,
        ChartStore,
        DiversionActivityStore,
        EventDetailedStore,
        history
      )}
    />
  ))
);

DiversionActivityChartContainer.displayName = 'DiversionActivityChartContainer';

export default DiversionActivityChartContainer;
