import React from 'react';
import { observer } from 'mobx-react';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { AppUserStore, GroupStore } from 'common';

import CaseActivityStore from '../stores/CaseActivityStore';
import OpenCasesStore from '../stores/OpenCasesStore';
import CasesReportCountStore from '../stores/CasesReportCountStore';
import CaseAgesStore from '../stores/CaseAgesStore';

import HomeView from '../HomeView';

export function mapProps({
  OpenCasesStore,
  CaseActivityStore,
  CasesReportCountStore,
  CaseAgesStore,
  GroupStore,
  query,
}) {
  return {
    groupNames: GroupStore.groupNames,
    setOpenCaseFilters: query => OpenCasesStore.setFilters(query),
    setCaseActivityFilters: query => CaseActivityStore.setFilters(query),
    setCaseReportCountFilters: query => CasesReportCountStore.setFilters(query),
    setCaseAgesFilters: query => CaseAgesStore.setFilters(query),
    activeUserCheck: user => AppUserStore.activeUserCheck(user),
    query,
  };
}

const HomeViewContainer = observer(({ query }) => (
  <HomeView
    {...mapProps({
      OpenCasesStore,
      CaseActivityStore,
      CasesReportCountStore,
      CaseAgesStore,
      GroupStore,
      query,
    })}
  />
));

HomeViewContainer.displayName = 'HomeViewContainer';

export default withRouterAndQuery(HomeViewContainer);
