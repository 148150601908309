import PropTypes from 'prop-types';

export const filterOptionsPropType = PropTypes.shape({
  param: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.string,
        name: PropTypes.string,
      }),
      PropTypes.string,
    ])
  ),
});

export const filterOptionsPropTypeDefaults = PropTypes.shape({
  param: '',
  label: '',
  options: [],
});
