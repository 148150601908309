import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autorun, reaction } from 'mobx';
import classnames from 'classnames';
import queryString from 'query-string';

import { Pandy, PermissionStore } from 'common';

import CaseBundleStore from '../../stores/CaseBundleStore';
import Store from '../../stores/BundledCasesStore';
import BundleActions from '../BundleActions';
import BundleDataVisualization from '../BundleDataVisualization';
import BundleStore from '../stores/BundleStore';
import Cases from '../../bulkCaseActions/Cases';
import {
  ApplyBodyClassName,
  DocumentTitle,
  Loader,
  RegistryContainer,
  PromptWrapper,
} from '../../ui';

const BundleDetails = observer(
  class BundleDetails extends Component {
    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          bundleId: PropTypes.string,
        }),
      }),
      location: PropTypes.shape({
        search: PropTypes.string,
      }),
    };

    constructor() {
      super();

      this.disposers.push(
        autorun(() => {
          if (this.props) {
            const { bundle } = BundleStore;
            if (bundle.cases) {
              Store.bootstrapCases(bundle.cases || []);
            } else {
              Store.bootstrapCases([]);
            }
          }
        })
      );

      reaction(
        () => [this.props?.location?.search],
        () => {
          const { match } = this.props;
          const { bundleId } = match.params;
          const query = queryString.parse(this.props.location.search);
          BundleStore.setValue('bundleId', bundleId);
          BundleStore.setValue('group', query.group || []);
        }
      );
    }

    // NOT Observable
    disposers = [];

    componentWillUnmount() {
      // dispose of the autorun before we clear things, so that the autorun isn't triggered
      this.disposers.forEach(d => d());
      Store.killRequests();
      BundleStore.setValue('bundleId', null);
      CaseBundleStore.reset();
      Store.clear();
    }

    speechBubbleMsg = () => {
      const totalCases = BundleStore.bundle?.cases?.length;
      const visibleCases = Store.visibleCases;
      if (CaseBundleStore.loading) return 'Loading Bundle...';
      if (totalCases && totalCases > 0 && totalCases !== visibleCases)
        return 'This bundle contains no cases in your active groups.';
      return 'This bundle contains zero cases';
    };

    render() {
      if (CaseBundleStore.redirect) {
        return <Redirect to="/bundles" />;
      }
      return (
        <div className="bundle__registry-container">
          <DocumentTitle text="Case Bundle" />
          <ApplyBodyClassName className="bundle" />
          <Loader loaded={!Store.loading && !BundleStore.loading}>
            <div className="bundle__registry registry--flex with-aside">
              <section className="panel__main">
                {Store.visibleCases.length === 0 ? (
                  <Pandy visible mood="happy">
                    <div className="speechBubble">
                      <span>{this.speechBubbleMsg()}</span>
                    </div>
                  </Pandy>
                ) : (
                  <RegistryContainer
                    disableScrollPager
                    store={Store}
                    viewContent={false}
                    className="registry"
                  >
                    <Cases rowHeight={50} store={Store} />
                  </RegistryContainer>
                )}
              </section>
              <aside
                className={classnames('panel__aside', {
                  hidden:
                    Store.visibleCases.length === 0 && BundleStore.loading,
                })}
              >
                <section>
                  <BundleDataVisualization bundle={BundleStore.bundle} />
                  <BundleActions
                    bundle={BundleStore.bundle}
                    isDirty={BundleStore.isDirty}
                    loading={CaseBundleStore.loading}
                    redirect={CaseBundleStore.redirect}
                    setValue={BundleStore.setValue}
                    store={Store}
                    onDelete={CaseBundleStore.delete}
                    onSave={BundleStore.save}
                    nameValue={BundleStore.nameValue}
                    notesValue={BundleStore.notesValue}
                    hasBundlePerm={PermissionStore.has('BUNDLE_MODIFY')}
                  />
                </section>
              </aside>
            </div>
          </Loader>
          <PromptWrapper
            when={Store.performingBulkAction}
            message="Are you sure you want to leave? If you do, your cases may not be updated."
          />
        </div>
      );
    }
  }
);

BundleDetails.displayName = 'BundleDetails';

export default BundleDetails;
