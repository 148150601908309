import { useEffect, useState } from 'react';

/**
 * This hook returns the selected user and patient as an Array (to
 * simplify the rendering logic later). The logic within is running a manual
 * sort based on the document location, ensuring that the proper record is
 * shown first regardless of what order it is loaded.
 *
 * @param patient
 * @param user
 * @param pathname
 * @returns Array*
 */
export function useCollection({ patient, user, pathname }) {
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    const newCollection = [];
    if (patient && pathname.match(/authorizations\/create\/patient/)) {
      newCollection.push(patient);
      if (user) {
        newCollection.push(user);
      }
    }

    if (user && pathname.match(/authorizations\/create\/employee/)) {
      newCollection.push(user);
      if (patient) {
        newCollection.push(patient);
      }
    }
    setCollection(newCollection);

    return () => {
      setCollection([]);
    };
  }, [patient, user, pathname]);
  return collection;
}

/**
 * This hook is responsible for setting RegistrySearchStore and
 * CreateAuthStore's observables based on query params
 * deep comparison warning is disabled since we only care about params and store
 * user patient objects
 *
 * @param patientId
 * @param employeeId
 * @param userId
 * @param pathname
 * @param user
 * @param patient
 * @param setType
 * @param removePatient
 * @param removeUser
 * @param reset
 * @param resetSearchStore
 * @param authStoreSetUser
 * @param authStoreSetPatient
 */
export function useAuthAndSearchStoresFromParams({
  patientId,
  employeeId,
  userId,
  user,
  patient,
  setType,
  removePatient,
  removeUser,
  reset,
  resetSearchStore,
  authStoreSetUser,
  authStoreSetPatient,
}) {
  useEffect(() => {
    if (patientId || employeeId) {
      // set the search type based on the available parameters. Only set type
      // if a single param (either patientId or employeeId) is set
      if (patientId && !employeeId) {
        setType('users');
      } else if (employeeId && !patientId) {
        setType('patients');
      } else {
        setType(null);
      }

      // Clean up any lingering patients or users if they're no longer part of
      // the requested data as managed by the route
      if (!patientId && user) {
        removePatient();
        resetSearchStore();
      }

      if (!employeeId && patient) {
        removeUser();
        resetSearchStore();
      }

      if (patientId && (!patient || patientId !== patient.id)) {
        authStoreSetPatient(patientId);
      }

      if (employeeId && (!user || userId !== user.id)) {
        authStoreSetUser(employeeId);
      }
    } else {
      setType(null);
      reset();
    }
  }, [patientId, employeeId, userId, user, patient]);
}
