import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { observable, computed, decorate, reaction } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { LoginStore, ResetPasswordStore, RouterContainer } from 'common';

import LoginFooter from '../LoginFooter';
import { ApplyBodyClassName, DocumentTitle, Input } from '../../ui';

const Login = observer(
  class Login extends React.Component {
    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          jwt: PropTypes.string,
        }),
      }),
    };
    // Observable
    emailSuccess = null;
    redirectTime = 3;
    errorFade = false;

    focusUser = true;
    focusPassword = false;
    focusMfa = false;

    constructor() {
      super();

      if (!LoginStore.inactive) {
        if (LoginStore.username) {
          this.username = LoginStore.username;
        }
      }

      reaction(
        () => [this.shouldRedirect],
        () => {
          if (this.shouldRedirect) {
            document.addEventListener(
              'keydown',
              e => {
                if (e.keyCode === 27) LoginStore.autoRedirect = false;
              },
              false
            );
            setTimeout(this.decrementRedirect.bind(this), 1000);
          }
        },
        { fireImmediately: false }
      );
    }

    componentDidMount() {
      const { match } = this.props;

      // this shortcut powers the 'Password Reset' flow
      const jwt = match.params.jwt;
      if (jwt) LoginStore.jwt = jwt;

      // don't autoredirect to SAML login if specified
      if (RouterContainer.query.autoRedirect === 'false')
        LoginStore.autoRedirect = false;

      // Users are redirected to this view after a successful password change,
      // so this just informs them why they're here and what step they need to
      // take next.

      // ensure that people can't get here if they are already logged in
      if (LoginStore.loggedIn) {
        if (LoginStore.forcePasswordChange) {
          RouterContainer.go('/resetPassword');
        } else {
          RouterContainer.go('/');
        }
      }

      if (RouterContainer.query.inactive) {
        LoginStore.inactive = true;
        LoginStore.failure = 'Your session has ended due to inactivity';
      }

      // if user has mfa enabled and they login with username and password, they'll be forced to enter mfa after the
      // try to initally login. Once they've done that once they can not return to just the normal login page (un & pw),
      // ie. if they want to login with a different user who doesn't have mfa, they wont be able to because the mfa input box
      // will display and attempt to send something back which will produce an error. On refresh, it will completely
      // clear out the use allowing them to start fresh.
      window.onbeforeunload = () => {
        if (LoginStore.shellUser) localStorage.removeItem('jwt');
      };
    }

    componentWillUnmount() {
      LoginStore.reset();
    }

    onInputKeyDown = e => {
      if (e.keyCode !== 13 && LoginStore.failure) {
        this.errorFade = true;
        this.fadeTimeout = setTimeout(() => {
          LoginStore.failure = null;
        }, 1000);
      }
    };

    // Computed
    get shouldRedirect() {
      return (
        (LoginStore.isAuth0 || LoginStore.samlRequired) &&
        LoginStore.autoRedirect &&
        !LoginStore.loggedIn &&
        !this.errorMessage
      );
    }

    /**
     * COMPUTED
     * @return {string} The error message to display to the user.
     */
    get errorMessage() {
      const failure = LoginStore.failure || RouterContainer.query.failure;

      if (/2-Step.*required/.test(failure)) {
        // suppress the basic "MFA required" message, since this is when we show the input
        return null;
      }

      if (failure === 'Authentication Failed: Bad credentials') {
        return 'Login Failed: Bad Username or Password';
      }
      // otherwise return the whole failure message text
      return failure;
    }

    // Computed
    get successMessage() {
      return (
        this.emailSuccess ||
        (ResetPasswordStore && ResetPasswordStore.passwordChanged)
      );
    }

    // Computed
    get ready() {
      return LoginStore.isAuth0 || Boolean(LoginStore.samlConfiguration);
    }

    login(e) {
      e.preventDefault();
      this.focusUser = false;
      this.focusPassword = false;
      this.focusMfa = false;

      this.errorFade = false;
      clearTimeout(this.fadeTimeout);
      ResetPasswordStore.passwordChanged = null;

      LoginStore.login().fail(() => {
        if (!this.errorFade) {
          if (!LoginStore.username) this.focusUser = true;
          if (LoginStore.username) {
            this.focusPassword = true;
            this.focusMfa = true;
          }
        }
      });
    }

    emailToken() {
      LoginStore.emailMFA().then(
        () =>
          (this.emailSuccess =
            'Please check your email and enter the token into this form.')
      );
    }

    decrementRedirect() {
      if (this.shouldRedirect) {
        this.redirectTime -= 1;

        if (this.redirectTime > 0) {
          setTimeout(this.decrementRedirect.bind(this), 1000);
        } else if (!LoginStore.isAuth0) {
          window.location.href = LoginStore.samlLoginHref;
        } else {
          window.location.href = LoginStore.authLoginHref;
        }
      }
    }

    /**
     * Only render the MFA input field if there's a shell user (no permissions).
     *
     * @return {component} field for MFA token
     */
    renderMFAInput() {
      if (
        /2-Step/.test(LoginStore.failure) ||
        LoginStore.mfaOptions.length !== 0
      ) {
        return (
          <Input
            autoFocus={this.focusMfa}
            darkBackground
            borderRadius="sm"
            coloredFont
            label="2-Step Verification Token"
            margin="lg"
            name="token"
            onChange={val => LoginStore.setValue(val, 'token')}
            value={LoginStore.token}
            onKeyDown={this.onInputKeyDown}
          />
        );
      }
    }

    /**
     * Only render the MFA buttons if there's a shell user (no permissions).
     *
     * @return {array} fields for entering MFA tokens
     */
    renderMFAButtons() {
      const buttons = [];
      if (!LoginStore.mfaEnabled) {
        if (
          /2-Step/.test(LoginStore.failure) ||
          LoginStore.mfaOptions.length !== 0
        ) {
          if (LoginStore.mfaOptions.includes('Email')) {
            buttons.push(
              <p key="email-token">
                <input
                  className="button"
                  type="button"
                  value="Email One Use Token"
                  onClick={this.emailToken.bind(this)}
                />
              </p>
            );
          }
          if (LoginStore.mfaOptions.includes('TOTP')) {
            buttons.push(
              <p key="mfa-setup">
                <Link className="button button--block" to="/mfa">
                  Setup 2-Step Verification
                </Link>
              </p>
            );
          }
        }
      }
      return buttons;
    }

    /*
    For one of our certifications we needed to not have the browser automatically fill out
    the user/password if the user gets kicked out due to inactivity (because then someone
    could just click login to get back in) We put in this second set of login/password
    inputs to trick the browser.
  */
    renderExtraLoginInputs() {
      if (LoginStore.inactive) {
        return (
          <div style={{ display: 'none' }}>
            <input type="email" name="email" />
            <input type="password" name="password" />
          </div>
        );
      }
    }

    renderForm() {
      if (this.ready && !LoginStore.samlRequired && !LoginStore.isAuth0) {
        return (
          <form className="form" autoComplete="off">
            <ul>
              {this.renderExtraLoginInputs()}
              <Input
                autoFocus={this.focusUser}
                darkBackground
                borderRadius="sm"
                coloredFont
                margin="lg"
                type="email"
                name="email"
                label="Email Address"
                onChange={val => LoginStore.setValue(val, 'username')}
                onKeyDown={this.onInputKeyDown}
                value={LoginStore.username}
              />
              <Input
                autoFocus={this.focusPassword}
                darkBackground
                borderRadius="sm"
                coloredFont
                margin="lg"
                type="password"
                name="password"
                label="Password"
                onChange={val => LoginStore.setValue(val, 'password')}
                onKeyDown={this.onInputKeyDown}
                value={LoginStore.password}
              />
              {this.renderMFAInput()}
            </ul>
            {this.renderMFAButtons()}
            <p>
              <input
                className="button"
                type="submit"
                value="Log In"
                onClick={this.login.bind(this)}
              />
            </p>
          </form>
        );
      }
    }

    renderSaml() {
      if (this.ready && (LoginStore.isAuth0 || LoginStore.samlRequired)) {
        let content = (
          <span>
            <p>
              <input
                className="button"
                type="button"
                value="Log In"
                onClick={() => {
                  window.location.href = LoginStore.isAuth0
                    ? LoginStore.authLoginHref
                    : LoginStore.samlLoginHref;
                }}
              />
            </p>
          </span>
        );

        if (this.shouldRedirect) {
          content = (
            <span>
              <p className="login-form--error">
                Redirecting to authentication provider in {this.redirectTime}{' '}
                second{this.redirectTime === 1 ? '' : 's'}...
              </p>
              <p className="text-subtle">(Esc to cancel)</p>
            </span>
          );
        }

        return (
          <form className="form" autoComplete="off">
            <p className="form--success">{this.successMessage}</p>
            {content}
          </form>
        );
      }
    }

    renderFooter() {
      let node;

      if (
        (this.ready && (LoginStore.isAuth0 || LoginStore.samlRequired)) ||
        LoginStore.jwt
      ) {
        node = (
          <div>
            <small>
              If you are having trouble logging in, please{' '}
              <a
                href="https://help.protenus.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact support
              </a>
              .
            </small>
          </div>
        );
      } else if (this.errorMessage?.includes('Error 27')) {
        node = (
          <div>
            <small>
              <a href="mailto:support@protenus.com">Contact Help Desk</a>
            </small>
          </div>
        );
      } else {
        node = (
          <div>
            <small>
              <Link to="/lostPassword">I need to reset my password</Link>
            </small>
          </div>
        );
      }

      return node;
    }

    renderSubheaderText() {
      if (this.errorMessage || this.successMessage)
        return (
          <span>
            <p
              className={classnames('login-form--error', {
                fadeout: this.errorFade,
              })}
            >
              {this.errorMessage}
            </p>
            <p className="form--success">{this.successMessage}</p>
          </span>
        );

      return <p>Please log in to continue</p>;
    }

    render() {
      return (
        <section className="login">
          <DocumentTitle text="Protenus Login" />
          <ApplyBodyClassName className="login_view" />
          <article className="login-container">
            <header className="login-header">
              <h1>Welcome to Protenus</h1>
              {this.renderSubheaderText()}
            </header>
            {this.renderForm()}
            {this.renderSaml()}
            {this.renderFooter()}
          </article>
          <LoginFooter />
        </section>
      );
    }
  }
);

decorate(Login, {
  emailSuccess: observable,
  errorFade: observable,
  redirectTime: observable,
  shouldRedirect: computed,
  errorMessage: computed,
  successMessage: computed,
  ready: computed,
  focusUser: observable,
  focusPassword: observable,
  focusMfa: observable,
});

Login.displayName = 'Login';

export default Login;
