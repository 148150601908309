import React from 'react';
import PropTypes from 'prop-types';
import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import pluralize from '../../utils/pluralize';

const ActionNav = ({ count }) => {
  return (
    <TopNavBar actionBar secondary={false}>
      <NavBarItem>
        <strong>{count.toLocaleString()}</strong> Matching{' '}
        {pluralize(count, 'File')}
      </NavBarItem>
    </TopNavBar>
  );
};

ActionNav.propTypes = {
  count: PropTypes.number,
};

ActionNav.defaultProps = {
  count: 0,
};

export default ActionNav;
