import { decorate, observable } from 'mobx';

import { HalUtils, PermissionStore, RelationshipClient } from 'common';

const validRelationship = (id, rel) => {
  return (
    rel.ids &&
    rel.ids.length === 2 &&
    rel.ids[0] &&
    rel.ids[0].id === id &&
    rel.ids[1] &&
    rel.people &&
    rel.people.length === 2
  );
};

const sortPeople = (rel1, rel2) => {
  const p1 = rel1.people[1];
  const p2 = rel2.people[1];
  // sorted by first then last then middle
  const p1Name = `${p1.firstName} ${p1.lastName} ${p1.middleName}`;
  const p2Name = `${p2.firstName} ${p2.lastName} ${p2.middleName}`;
  return p1Name.localeCompare(p2Name);
};

class RelationshipStore {
  // NOT Observable
  loading = {};

  // Observable
  userRelationships = new Map();
  patientRelationships = new Map();

  getAliases = (type, id) => {
    const relationships =
      type === 'users'
        ? this.userRelationships.get(id) || []
        : this.patientRelationships.get(id) || [];
    return relationships.filter(
      r =>
        r.relationships.includes('alias') &&
        HalUtils.getEndpoint(r.ids[1]) === type
    );
  };

  getOtherAliases = (type, id, source) => {
    const relationships =
      type === 'users'
        ? this.userRelationships.get(id) || []
        : this.patientRelationships.get(id) || [];
    const otherType = type === 'users' ? 'patients' : 'users';
    const otherAliases = relationships.filter(
      r =>
        r.relationships.includes('alias') &&
        HalUtils.getEndpoint(r.ids[1]) === otherType
    );

    if (!otherAliases.length) return [];
    // for other aliases, first try to match source, otherwise use another source as a fallback
    const matchingSource = otherAliases.filter(
      a => a.people[1].source === source
    );
    return matchingSource.length ? matchingSource : [otherAliases[0]];
  };

  getOtherAliasId = (type, id, source) => {
    const otherAliases = this.getOtherAliases(type, id, source);
    return otherAliases && otherAliases[0]?.ids[1].id;
  };

  fetchUser(userId) {
    if (
      !PermissionStore.has('USER_VIEW') ||
      !userId ||
      this.loading[userId] ||
      this.userRelationships.has(userId)
    )
      return;
    this.loading[userId] = true;

    RelationshipClient.getByUser(userId).then(r => {
      const embedded = HalUtils.getData(r)
        .filter(validRelationship.bind(this, userId))
        .sort(sortPeople);
      this.userRelationships.set(userId, embedded);
      this.loading[userId] = false;
    });
  }

  fetchPatient(patientId) {
    if (
      !PermissionStore.has('PATIENT_VIEW') ||
      !patientId ||
      this.loading[patientId] ||
      this.patientRelationships.has(patientId)
    )
      return;
    this.loading[patientId] = true;

    RelationshipClient.getByPatient(patientId).then(r => {
      const embedded = HalUtils.getData(r)
        .filter(validRelationship.bind(this, patientId))
        .sort(sortPeople);
      this.patientRelationships.set(patientId, embedded);
      this.loading[patientId] = false;
    });
  }
}

decorate(RelationshipStore, {
  userRelationships: observable,
  patientRelationships: observable,
});

export default new RelationshipStore();
