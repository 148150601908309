import { SingletonStore } from 'common';
import {
  action,
  computed,
  comparer,
  decorate,
  observable,
  reaction,
} from 'mobx';

const createdAfterMap = {
  today: 'P-0D',
  last7Days: 'P-7D',
  last30Days: 'P-30D',
  last90Days: 'P-90D',
  year: 'P-365D',
};

export const DEFAULT_QUERY = { createdAfter: 'last30Days' };

/**
 * Houses a file evaluation report serving data containing
 * array of individual file statuses
 *
 */
class EvaluationStore extends SingletonStore {
  constructor({ dataFeedClient, halUtils, processGroupsData }) {
    super();

    this.dataFeedClient = dataFeedClient;
    this.halUtils = halUtils;
    this.processGroupsData = processGroupsData;

    this.disposers = [
      reaction(
        () => [this.createdAfter],
        () => {
          this.refresh();
        },
        { equals: comparer.structural, fireImmediately: true }
      ),
    ];
  }

  // Observables
  createdAfter = 'last30Days';

  selectedRow = null;

  // Computed properties
  get groups() {
    return this.halUtils.getData(this.result) || [];
  }

  get requiresAttentionGroups() {
    return (
      this.processGroupsData({ data: this.groups, forGroupsWithError: true }) ||
      []
    );
  }

  get noIssuesGroups() {
    return this.processGroupsData({ data: this.groups }) || [];
  }

  get selectedFeedRow() {
    let feedRowForAside = {};
    this.requiresAttentionGroups.forEach(g => {
      (g.tableDataForDetailAside || []).forEach(d => {
        if (d.id === this.selectedRow) feedRowForAside = d;
      });
    });
    return feedRowForAside;
  }

  // Actions
  setQueryFromURI = ({ createdAfter, selectedRow }) => {
    if (createdAfter) this.createdAfter = createdAfter;
    if (selectedRow) this.selectedRow = selectedRow;
  };

  tearDown = () => {
    this.disposers.forEach(d => d());
  };

  fetch() {
    return this.dataFeedClient.statusRules({
      createdBefore: 'P0D',
      createdAfter: createdAfterMap[this.createdAfter || 'last30Days'],
    });
  }
}

decorate(EvaluationStore, {
  createdAfter: observable,
  selectedRow: observable,
  groups: computed,
  requiresAttentionGroups: computed,
  noIssuesGroups: computed,
  selectedFeedRow: computed,
  setQueryFromURI: action,
});

export { EvaluationStore };
