import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { CardBadge } from '../../../ui/Card';

// max tag length not including ellipsis or "and X more"
const MAX_TAG_TEXT_LENGTH = 25;

const UserTagsBadge = ({ tags }) => {
  let tagText = tags[0].name;

  if (tagText.length > MAX_TAG_TEXT_LENGTH) {
    tagText = `${tagText.slice(0, MAX_TAG_TEXT_LENGTH)}...`;
  }

  if (tags.length > 1) {
    tagText += ` and ${tags.length - 1} more`;
  }

  return <CardBadge title={tagText} label="primary" icon="icon-label" />;
};

UserTagsBadge.propTypes = {
  tags: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
};

export default UserTagsBadge;
