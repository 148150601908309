import moment from 'moment';
import { autorun, computed, decorate, observable } from 'mobx';

import {
  convertMultiValue,
  AppUserStore,
  CaseClient,
  DateHelpers,
  SingletonStore,
} from 'common';

/**
 * Data store for the new cases report.
 * @extends SingletonStore
 */
class NewCasesReport extends SingletonStore {
  /**
   * Call the super class's constructor method, configure autorun methods.
   */
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Observables
  initialized = false;
  createdAfter;
  createdBefore;
  active;
  owner;
  group;

  /**
   * Computed
   * Sorts the results
   * @getter sorted
   *
   * @return {Null} or {Array}
   */
  get sortedResults() {
    if (!this.result) return null;
    const data = this.result.slice().map(d => {
      if (!d.group) d.group = 'Other';

      return d;
    });
    return data
      .sort((a, b) => {
        if (a.group < b.group) return -1;
        if (a.group > b.group) return 1;
        return 0;
      })
      .sort((a, b) => b.count - a.count);
  }

  /**
   * Retrieve the results from the server.
   * @return {Promise} an HTTP Promise
   */
  fetch() {
    if (!this.initialized) return [];
    const params = { groupBy: 'category' };

    if (this.createdAfter) params.createdAfter = this.createdAfter;
    if (this.createdBefore) params.createdBefore = this.createdBefore;

    if (this.owner) params.owner = this.owner;
    if (this.group) params.group = convertMultiValue(this.group);

    return CaseClient.countBy(params);
  }
}

decorate(NewCasesReport, {
  active: observable,
  createdAfter: observable,
  createdBefore: observable,
  initialized: observable,
  owner: observable,
  group: observable,
  sortedResults: computed,
});

const newCasesReport = new NewCasesReport();

/**
 * Data store for the closed cases report.
 */
class ClosedCasesReport extends SingletonStore {
  /**
   * Call the super class's constructor method, configure autorun methods.
   */
  constructor() {
    super();

    autorun(() => {
      this.refresh();
    });
  }

  // Observables
  initialized = false;
  resolutionDateAfter = moment().subtract(7, 'days'); // default to a week
  resolutionDateBefore = null;
  owner;
  group;

  /**
   * Retrieve the results from the server.
   * @return {Promise} an HTTP Promise
   */
  fetch() {
    if (!this.initialized) return [];
    const params = { groupBy: 'resolution' };

    if (this.resolutionDateAfter) {
      params.resolutionDateAfter = this.resolutionDateAfter;
    }

    if (this.resolutionDateBefore) {
      params.resolutionDateBefore = this.resolutionDateBefore;
    }

    if (this.owner) params.owner = this.owner;
    if (this.group) params.group = convertMultiValue(this.group);

    return CaseClient.countBy(params);
  }
}

decorate(ClosedCasesReport, {
  initialized: observable,
  owner: observable,
  resolutionDateAfter: observable,
  resolutionDateBefore: observable,
  group: observable,
});

const closedCasesReport = new ClosedCasesReport();

/**
 * Data store for managing the reports for new and closed cases.
 */
class CasesReportCountStore {
  /**
   * Run initializers.
   */
  constructor() {
    this.init();
  }

  // Observables
  range;
  newCasesActive;
  newOwner;
  closedOwner;
  customStart = moment().subtract(1, 'month');
  customEnd = moment();
  group;

  /**
   * Configure autorun tasks.
   */
  init() {
    autorun(() => {
      const range = this.getRange();
      closedCasesReport.owner =
        this.closedOwner === 'all' ? null : this.closedOwner;
      newCasesReport.owner = this.newOwner === 'all' ? null : this.newOwner;

      newCasesReport.createdAfter = range[0];
      newCasesReport.createdBefore = range[1];
      newCasesReport.group = this.group;

      closedCasesReport.resolutionDateAfter = range[0];
      closedCasesReport.resolutionDateBefore = range[1];
      closedCasesReport.group = this.group;
    });
  }

  /**
   * Computed
   * Compute whether or not the child stores are loading.
   * @return {Boolean} the evaluated response from the child stores
   */
  get loading() {
    return newCasesReport.loading || closedCasesReport.loading;
  }

  /**
   * Computed
   * Compute the results for the newCasesReport.
   * @return {Array} a collection of new cases report data
   */
  get newCases() {
    return newCasesReport.sortedResults || [];
  }

  /**
   * Computed
   * Compute the results for the closedCasesReport.
   * @return {Array} a collection of closed cases report data
   */
  get closedCases() {
    return closedCasesReport.result || [];
  }

  /**
   * Returns the timeframe for which reports are generated.
   * @return {Array} a two element Moment range
   */
  getRange() {
    return DateHelpers.rangeForPeriod(
      this.range,
      [this.customStart, this.customEnd],
      [null, null]
    );
  }

  /**
   * Helper method to clear out all child stores.
   */
  clear() {
    closedCasesReport.clear();
    newCasesReport.clear();
  }

  /**
   * Helper method to trigger the refresh method of the child stores.
   */
  refresh() {
    newCasesReport.refresh();
    closedCasesReport.refresh();
  }

  /**
   * Update the filtering options for the data store.
   * @param {Object} query - the new filters to apply
   */
  setFilters(query) {
    if (query.closedOwner === 'all') query.closedOwner = null;
    if (query.newOwner === 'all') query.newOwner = null;

    this.closedOwner = AppUserStore.activeUserCheck(query.closedOwner);
    this.newOwner = AppUserStore.activeUserCheck(query.newOwner);
    this.range = query.newCasesRange;
    if (query.createdAfter) this.customStart = query.createdAfter;
    if (query.createdBefore) this.customEnd = query.createdBefore;
    query.group ? (this.group = query.group) : (this.group = undefined);
    newCasesReport.initialized = true;
    closedCasesReport.initialized = true;
  }
}

decorate(CasesReportCountStore, {
  closedOwner: observable,
  customEnd: observable,
  customStart: observable,
  newCasesActive: observable,
  newOwner: observable,
  range: observable,
  group: observable,
  closedCases: computed,
  loading: computed,
  newCases: computed,
});

export default new CasesReportCountStore();
