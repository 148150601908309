import React from 'react';
import { observer } from 'mobx-react';
import RolesManageForm from '../RolesManageForm';
import { AppRolesStore } from 'common';

export function mapProps({ AppRolesStore, match, history }) {
  return {
    allPermissions: AppRolesStore.allPermissions,
    isDirty: AppRolesStore.isFormDirty,
    isEditDirty: AppRolesStore.isEditDirty,
    loading: AppRolesStore.loading,
    roleFields: AppRolesStore.roleFields,
    onCloseForm: () => history.push('/settings/roles'),
    onSubmit: match.params.rolename
      ? AppRolesStore.update
      : AppRolesStore.create,
    onChangeSelectedRole: AppRolesStore.setEditingRole,
    onDeleteRole: AppRolesStore.delete,
    redirect: AppRolesStore.redirect,
    showPrompt: AppRolesStore.showCreateFormPrompt,
    selectedRoleName: match.params.rolename,
    setRoleFields: AppRolesStore.setRoleFields,
    setIsOpen: AppRolesStore.setIsFormOpen,
  };
}

const RolesManageFormContainer = observer(({ match, history }) => (
  <RolesManageForm
    {...mapProps({
      AppRolesStore,
      match,
      history,
    })}
  />
));

RolesManageFormContainer.displayName = 'RolesManageFormContainer';

export default RolesManageFormContainer;
