import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';

export const quickFilterDataPropType = mobxPropTypes.arrayOrObservableArrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
  })
);

export const groupPropType = PropTypes.oneOfType([
  PropTypes.string,
  mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
]);

const quickFilterPropTypeDefaults = PropTypes.shape({
  data: quickFilterDataPropType,
  handleCheckboxOnChange: PropTypes.func.isRequired,
  handleSetThisGroupOnly: PropTypes.func.isRequired,
  handleSetLocalStorage: PropTypes.func.isRequired,
  showRememberFilterButton: PropTypes.bool,
});

export default { quickFilterPropTypeDefaults };
