import React from 'react';
import PropTypes from 'prop-types';
import { SAI_360_EXPLANATION } from '../../../stores/CaseIntegrationStore/const';
import { MultilineInput } from '../../../../ui';
import Checkbox from '../../../../ui/Checkbox';
import styles from './index.module.scss';
import SAI360IntegrationTable from '../SAI360IntegrationTable';

export const SAI360CaseIntegration = ({
  isEnabled,
  setEnabled,
  urlDisplay,
  setUrl,
  caseSyncSettings,
  setCaseSyncSettings,
}) => {
  return (
    <form className="content other">
      <p>{SAI_360_EXPLANATION}</p>
      <div className={styles.bottomSpacing} data-cy="integration-sai360-enable">
        <Checkbox
          checked={isEnabled}
          tabIndex={0}
          color={'secondary'}
          onChange={() => setEnabled(!isEnabled)}
        >
          Enable Integration
        </Checkbox>
      </div>
      <div data-cy="integration-sai360-token">
        <MultilineInput
          label="SAI 360 Integration URL"
          placeholder="Enter URL here"
          value={urlDisplay}
          onChange={setUrl}
          bordered
        />
      </div>
      <SAI360IntegrationTable
        caseSyncSettings={caseSyncSettings}
        setCaseSyncSettings={setCaseSyncSettings}
      />
    </form>
  );
};

SAI360CaseIntegration.propTypes = {
  isEnabled: PropTypes.bool,
  setEnabled: PropTypes.func.isRequired,
  urlDisplay: PropTypes.string,
  setUrl: PropTypes.func,
  caseSyncSettings: PropTypes.shape({
    importId: PropTypes.bool,
    name: PropTypes.bool,
    description: PropTypes.bool,
    incidentDate: PropTypes.bool,
    dateReceived: PropTypes.bool,
    responsibleParty: PropTypes.bool,
    caseNotes: PropTypes.bool,
    caseActions: PropTypes.bool,
    caseResolution: PropTypes.bool,
    caseAttachments: PropTypes.bool,
    caseEmails: PropTypes.bool,
    caseFinalAssessment: PropTypes.bool,
  }),
  setCaseSyncSettings: PropTypes.func,
};

export default SAI360CaseIntegration;
