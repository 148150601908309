import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter, pluralize } from 'common';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DoubleLineCell } from '../../../../ui';
import classnames from 'classnames';
import styles from '../index.module.scss';

const RolesCell = ({ isActive = false, roles = [] }) => {
  const roleCountString = `${roles.length} ${pluralize(
    roles.length,
    'Role',
    'Roles'
  )}`;

  roles.sort();
  const rolesString = roles
    .map(role =>
      role
        .split(' ')
        .map(word => capitalizeFirstLetter(word.toLowerCase()))
        .join(' ')
    )
    .join(', ');

  return (
    <DoubleLineCell
      className={classnames({ [styles.isActive]: isActive })}
      lineOne={roleCountString}
      lineTwo={rolesString}
    />
  );
};

export default RolesCell;

RolesCell.propTypes = {
  isActive: PropTypes.bool,
  roles: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
};
