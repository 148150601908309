import { useEffect } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_TITLE = 'Protenus';

const DocumentTitle = ({ text }) => {
  useEffect(() => {
    window.document.title = text || DEFAULT_TITLE;
  }, [text]);

  return null;
};

DocumentTitle.propTypes = {
  text: PropTypes.string,
};

DocumentTitle.defaultProps = {
  text: DEFAULT_TITLE,
};

export default DocumentTitle;
