import { autorun, computed, decorate, reaction } from 'mobx';

import { MetalogClient, SingletonStore, VisibleSystemStore } from 'common';

import Store from '../Store';

const MAX_METALOGS = 3000;

class MetalogStore extends SingletonStore {
  constructor({ visibleSystemStore }) {
    super();

    this.visibleSystemStore = visibleSystemStore;

    autorun(() => {
      this.refresh();
    });

    reaction(
      () => [
        Store.sort,
        Store.accessesVisible,
        Store.administrationsVisible,
        Store.discrepanciesVisible,
        Store.handlingsVisible,
        Store.ordersVisible,
      ],
      () => this.refresh()
    );
  }

  get filteredMetalogs() {
    const filteredMetalogs = [];

    this.metalogs.forEach(metalog => {
      if (metalog.sources) {
        if (metalog.eventCountBySource) {
          const numSourcesFromEvents = Object.values(
            metalog.eventCountBySource
          ).reduce((prev, curr) => prev + curr, 0);
          const numEventsFiltered = metalog.sources.reduce((count, source) => {
            if (this.visibleSystemStore.filteredSources.includes(source))
              return count + metalog.eventCountBySource[source];
            return count;
          }, 0);
          // only show filtered metalog if some sources weren't disabled
          if (numEventsFiltered < numSourcesFromEvents) {
            filteredMetalogs.push({
              ...metalog,
              eventCount: metalog.eventCount - numEventsFiltered,
            });
          }
        } else {
          // some logs don't have events attributed to them
          let numFilteredSources = 0;
          metalog.sources.forEach(source => {
            if (this.visibleSystemStore.filteredSources.includes(source))
              numFilteredSources += 1;
          });
          if (numFilteredSources < metalog.sources.length) {
            filteredMetalogs.push(metalog);
          }
        }
      } else {
        // no sources to filter metalog by, so add it by default
        filteredMetalogs.push(metalog);
      }
    });

    return filteredMetalogs;
  }

  fetch() {
    const fp = Store.filterParams;
    const disabledPerms = [
      { event: 'accessesVisible', perm: 'ACCESS_VIEW' },
      {
        event: 'administrationsVisible',
        perm: 'MEDICATION_ADMINISTRATION_VIEW',
      },
      { event: 'discrepanciesVisible', perm: 'MEDICATION_DISCREPANCY_VIEW' },
      { event: 'handlingsVisible', perm: 'MEDICATION_HANDLING_VIEW' },
      { event: 'ordersVisible', perm: 'ORDER_VIEW' },
    ]
      .filter(v => !Store[v.event])
      .map(v => v.perm);

    if (Store.focus === 'patient') {
      if (!Store.activePatients) return [];
      return MetalogClient.getByPatients(
        Store.activePatients.split(','),
        Store.fromDate,
        Store.toDate.clone().add(1, 'days'),
        fp.filterField,
        fp.filterValues,
        disabledPerms,
        MAX_METALOGS
      );
    }
    if (
      Store.focus === 'employee' ||
      Store.focus === 'employee_accesses' ||
      Store.focus === 'employee_incidents'
    ) {
      if (!Store.activeUsers) return [];
      return MetalogClient.getByUsers(
        Store.activeUsers.split(','),
        Store.fromDate,
        Store.toDate.clone().add(1, 'days'),
        fp.filterField,
        fp.filterValues,
        disabledPerms,
        MAX_METALOGS
      );
    }

    return [];
  }

  // Computed
  get metalogs() {
    return this.result || [];
  }

  // Computed
  get fullMetalogs() {
    return this.filteredMetalogs.filter(m => m.patient);
  }

  // Computed
  get userOnlyMetalogs() {
    return this.filteredMetalogs.filter(m => !m.patient);
  }

  // Computed
  get limitReached() {
    return this.filteredMetalogs.length >= MAX_METALOGS;
  }
}

decorate(MetalogStore, {
  limitReached: computed,
  metalogs: computed,
  fullMetalogs: computed,
  userOnlyMetalogs: computed,
  filteredMetalogs: computed,
});

export { MetalogStore };
export default new MetalogStore({ visibleSystemStore: VisibleSystemStore });
