import React from 'react';
import { Cell } from 'fixed-data-table-2';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const CheckCell = ({ value }) => (
  <Cell className={value ? styles.checkCell : styles.iconClosed}>
    <i className={`material-icons ${value ? 'icon-check' : 'icon-close'}`}></i>
  </Cell>
);

export default CheckCell;

CheckCell.propTypes = {
  value: PropTypes.bool,
  className: PropTypes.string,
};

CheckCell.defaultProps = {
  value: false,
  className: '',
};
