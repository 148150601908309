import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import classnames from 'classnames';

import { Pandy, ScrollPager } from 'common';
import CaseLI from '../CaseLI/CaseCard';
import AuthorizationLI from '../AuthorizationLI';
import SearchViewTopNav from '../SearchViewTopNav';
import SearchViewActionNav from '../SearchViewActionNav';
import PatientResults from '../PatientResults';
import styles from './index.module.scss';
import {
  ApplyBodyClassName,
  DocumentTitle,
  Loader,
  StaggeredList,
} from '../../ui';
import UserCardContainer from '../../people/cards/UserCardContainer';
import { useSearchCriteriaAndTypeFromParam, useCleanup } from './hooks';

const ROW_HEIGHT = 135;

const SearchView = ({
  query,
  match,
  failure,
  type,
  criteria,
  results = [],
  totalElements,
  hasMore,
  maxed,
  patientResultsProps,
  getQueryParam,
  useGroups,
  getId,
  storePagingProps,
}) => {
  const pagerElement = useRef();

  useSearchCriteriaAndTypeFromParam({ query, match });

  useCleanup();

  const renderResults = () => {
    return results.map((i, idx) => {
      if (type === 'authorizations') {
        return <AuthorizationLI key={`a${idx}`} data={i} />;
      }
      if (type === 'users') {
        return <UserCardContainer key={getId(i)} user={i} />;
      }
      if (type === 'comments') {
        return (
          <CaseLI
            key={`o${idx}`}
            fadeIn
            case={i.complianceCase}
            comment={i}
            useGroups={useGroups}
          />
        );
      }
      return <CaseLI key={`c${idx}`} fadeIn case={i} useGroups={useGroups} />;
    });
  };

  const renderResultSection = () => {
    if (type === 'patients') {
      return (
        <PatientResults
          results={results.length ? results : []}
          size={totalElements}
          isMaxed={maxed}
          hasMore={hasMore}
          loading={storePagingProps.loading}
          failure={failure ? <p className="error">{failure.failure}</p> : null}
          searchTerm={getQueryParam(criteria)}
          {...patientResultsProps}
        />
      );
    }

    const pandyText =
      type && typeof type === 'string' && type !== 'all'
        ? `${type.capitalizeFirstLetter()}`
        : 'Results';

    return (
      <>
        <SearchViewActionNav />

        <div
          className={classnames(
            'view-content view-content-pandy',
            styles.viewContent
          )}
          ref={pagerElement}
        >
          <ScrollPager.Component
            elt={pagerElement.current}
            store={storePagingProps}
            rowHeight={ROW_HEIGHT}
          />
          {failure}
          <Loader loaded={!storePagingProps.loading}>
            <Pandy
              visible={!storePagingProps.loading && storePagingProps.size === 0}
              mood="happy"
            >
              <div className="speechBubble">
                <span>No matches. Sorry!</span>
              </div>
            </Pandy>
            <div>
              <div className="col-xs-10 col-xs-offset-1 search-results">
                <StaggeredList
                  className="card_holder"
                  drag={5}
                  useSmallPandy
                  pandyHasMore={Boolean(hasMore)}
                  pandyText={`Matching ${pandyText}`}
                  toAdd="fadeIn"
                  topOfListScrollValues={{
                    elementToScroll: 'view-content',
                    watchedElements: [type],
                  }}
                  parentNodeRef={pagerElement.current}
                >
                  {renderResults()}
                </StaggeredList>
              </div>
            </div>
          </Loader>
        </div>
      </>
    );
  };

  return (
    <main>
      <DocumentTitle text="Search Results" />
      <ApplyBodyClassName className="dashboard" />
      <SearchViewTopNav />
      {renderResultSection()}
    </main>
  );
};

SearchView.propTypes = {
  query: PropTypes.shape({}),
  match: PropTypes.shape({}),
  failure: PropTypes.shape({ failure: PropTypes.string }),
  type: PropTypes.string,
  criteria: PropTypes.string,
  results: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  totalElements: PropTypes.number,
  hasMore: PropTypes.bool.isRequired,
  maxed: PropTypes.bool.isRequired,
  patientResultsProps: PropTypes.shape({
    isAdvancedSearchActive: PropTypes.bool,
    areResultsFromAdvancedSearch: PropTypes.bool,
    onToggleAdvancedSearch: PropTypes.func,
    onUnmount: PropTypes.func,
    searchFields: PropTypes.shape({}),
    onSearchFieldChange: PropTypes.func,
    onSubmitAdvancedSearch: PropTypes.func,
    requiredAdvancedFiltersExist: PropTypes.bool,
    pagingStore: PropTypes.shape({}),
  }),
  getQueryParam: PropTypes.func.isRequired,
  useGroups: PropTypes.bool.isRequired,
  getId: PropTypes.func.isRequired,
  storePagingProps: PropTypes.shape({
    nextPage: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    paging: PropTypes.bool,
    size: PropTypes.number,
  }).isRequired,
};

export default SearchView;
