import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';
import moment from 'moment-timezone';
import { Link, Redirect } from 'react-router-dom';
import { DropdownList } from 'react-widgets';

import { Button, DatePicker, MultilineInput } from '../../ui';

import AuthorizationPermissionStore from '../stores/AuthorizationPermissionStore';
import CreateAuthStore from '../stores/CreateAuthStore';
import { toLocalDateString } from '../../utils/LocalDateUtils';

const CreateAuthForm = observer(
  class CreateAuthForm extends React.Component {
    static propTypes = {
      reset: PropTypes.func,
    };
    // Observable
    startDate = moment();
    endDate = moment().add(364, 'days');
    reasonValue = '';

    // Ref
    type = React.createRef();

    onSubmit(e) {
      e.preventDefault();
      const form = {
        startDate: toLocalDateString(this.startDate),
        endDate: toLocalDateString(this.endDate),
        reason: this.reasonValue,
        type: this.type.current?._values?.value?.value,
      };

      CreateAuthStore.create(form);
    }

    onStartChange = (date, valid) => {
      if (valid) {
        this.startDate = moment(date);
        if (!this.endDateValid)
          this.endDate = moment(date)
            .clone()
            .add(364, 'days');
      }
    };

    onEndChange = (date, valid) => {
      if (valid) this.endDate = moment(date);
    };

    reset() {
      if (this.props.reset) this.props.reset();
    }

    render() {
      if (CreateAuthStore.createAuthId) {
        return (
          <Redirect to={`/authorizations/${CreateAuthStore.createAuthId}`} />
        );
      }
      const options = AuthorizationPermissionStore.authTypes;
      return (
        <form
          className="record__create-form record__form"
          onSubmit={this.onSubmit.bind(this)}
        >
          <ul className="row no-gutter">
            <DatePicker
              className="split-two input-section"
              onChange={this.onStartChange}
              label="Authorization Start Date"
              placeholder="Authorization Start Date - MM/DD/YYYY"
              value={this.startDate}
              maxDate={this.endDate}
            />
            <DatePicker
              className="split-two input-section"
              label="Authorization End Date"
              placeholder="Authorization End Date - MM/DD/YYYY"
              onChange={this.onEndChange}
              value={this.endDate}
              minDate={this.startDate}
            />

            <div className="input-section full-width">
              <MultilineInput
                label="Authorization Reason - Optional"
                value={this.reasonValue}
                onChange={value => (this.reasonValue = value)}
                bordered
              />
            </div>

            <li className="input-container input-section">
              <DropdownList
                ref={this.type}
                data={options}
                defaultValue={options[0]}
                valueField="value"
                textField="label"
              />
            </li>

            <li className="form__actions">
              <Button
                type="submit"
                value="Submit"
                disabled={CreateAuthStore.disabled}
                data-cy="submit-patient-emr-auth"
              />

              <Link
                to="/authorizations"
                onClick={this.reset.bind(this)}
                className="button button--link"
              >
                Cancel
              </Link>
            </li>
          </ul>
        </form>
      );
    }
  }
);

decorate(CreateAuthForm, {
  startDate: observable,
  endDate: observable,
  reasonValue: observable,
});

CreateAuthForm.displayName = 'CreateAuthForm';

export default CreateAuthForm;
