import React, { Component } from 'react';
import { decorate, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import classnames from 'classnames';

import { RouterContainer } from 'common';
import { DropdownList } from 'react-widgets';
import DatePicker from '../../../ui/DatePicker';
import { Input } from '../../../ui';
import Tooltip from '../../../ui/Tooltip';

import Store from '../../stores/Store';
import { sqSubFilter1, sqSubFilter2 } from './constants';

const numbersOnly = function preventNonNumericalInput(e) {
  e = e || window.event;
  const charCode = typeof e.which == 'undefined' ? e.code : e.which;
  const charStr = String.fromCharCode(charCode);

  if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
};

const EventSubFilter = observer(
  class EventSubFilter extends Component {
    // Observable
    subFilter1 = '';
    subFilter2 = '';
    subFilter1Valid = true;
    subFilter2Valid = true;
    disposers = [];

    componentDidMount() {
      this.disposers.push(
        // reset filters on label change
        reaction(
          () => [Store.activeLabel],
          () => {
            this.subFilter1 = '';
            this.subFilter2 = '';
            this.subFilter1Valid = true;
            this.subFilter2Valid = true;
          }
        ),
        reaction(
          () => [Store.subFilter1, Store.subFilter2],
          () => {
            this.subFilter1 = Store.subFilter1 || '';
            this.subFilter2 = Store.subFilter2 || '';
          }
        )
      );
    }

    componentWillUnmount() {
      this.disposers.forEach(d => d());
    }

    onSubmit(hasDateFields) {
      // sometimes this function is called from an onClick and so need to prevent default in order to avoid a page refresh
      if (event) event.preventDefault();
      const overrides = {};

      overrides.subFilter1 =
        moment(this.subFilter1, moment.ISO_8601).isValid() && hasDateFields
          ? moment(this.subFilter1)
              .startOf('day')
              .toISOString()
          : this.subFilter1;

      overrides.subFilter2 =
        moment(this.subFilter2, moment.ISO_8601).isValid() && hasDateFields
          ? moment(this.subFilter2)
              .endOf('day')
              .toISOString()
          : this.subFilter2;

      const cleanFields = { ...overrides };

      if (cleanFields.subFilter1) {
        const storageKey = sqSubFilter1.timeSet(cleanFields.subFilter1);
        cleanFields.subFilter1 = storageKey;
      }

      if (cleanFields.subFilter2) {
        const storageKey = sqSubFilter2.timeSet(cleanFields.subFilter2);
        cleanFields.subFilter2 = storageKey;
      }

      const newQuery = Store.getQuery(cleanFields);
      RouterContainer.go(undefined, newQuery);
    }

    onClear() {
      this.subFilter1 = '';
      this.subFilter2 = '';
      this.subFilter1Valid = true;
      this.subFilter2Valid = true;
      this.onSubmit({});
    }

    renderSingleField(label) {
      return [
        <Input
          bordered
          font="sm"
          padding="sm"
          key="single-field"
          className="activity_view__filters_single filters_height"
          value={this.subFilter1}
          placeholder={`Filter by ${label}`}
          onChange={val => (this.subFilter1 = val)}
        />,
        this.renderFilterButton(false),
        this.renderClearButton(),
      ];
    }

    renderDropdown(options) {
      return [
        <DropdownList
          key="dropdown"
          className="activity_view__filters_single"
          data={options}
          value={this.subFilter1}
          placeholder="Select an option"
          onChange={value => {
            this.subFilter1 = value;
          }}
        />,
        this.renderFilterButton(false),
        this.renderClearButton(),
      ];
    }

    renderDateRange() {
      const start = this.subFilter1;
      const end = this.subFilter2;

      return [
        <DatePicker
          className="split-two start-date js-hide-label activity_view__filters_double filters_height"
          key="start-date"
          onChange={(date, valid) => {
            if (valid) this.subFilter1 = date;
          }}
          placeholder="Start Date"
          value={start}
          maxDate={end}
        />,

        <DatePicker
          className="split-two end-date js-hide-label activity_view__filters_double"
          key="end-date"
          onChange={(date, valid) => {
            if (valid) this.subFilter2 = date;
          }}
          placeholder="End Date"
          value={end}
          minDate={start}
        />,
        this.renderFilterButton(true),
        this.renderClearButton(),
      ];
    }

    renderNumberRange(label1, label2, min, max) {
      const validator1 = value =>
        value === '' ||
        (value >= min &&
          value <= max &&
          (this.subFilter2 === '' || parseInt(value) <= this.subFilter2));

      const validator2 = value =>
        value === '' ||
        (value >= min &&
          value <= max &&
          (this.subFilter1 === '' || parseInt(value) >= this.subFilter1));

      return [
        <Input
          bordered
          font="sm"
          padding="sm"
          className="activity_view__filters_double"
          error={!validator1(this.subFilter1)}
          type="number"
          min={min}
          max={max}
          key="start-number"
          value={this.subFilter1}
          placeholder={label1}
          onKeyPress={numbersOnly}
          onChange={val => {
            this.subFilter1 = val;
            this.subFilter1Valid = validator1(this.subFilter1);
          }}
        />,
        <Input
          bordered
          font="sm"
          padding="sm"
          className="activity_view__filters_double"
          error={!validator2(this.subFilter2)}
          type="number"
          min={min}
          max={max}
          key="end-number"
          value={this.subFilter2}
          placeholder={label2}
          onKeyPress={numbersOnly}
          onChange={val => {
            this.subFilter2 = val;
            this.subFilter2Valid = validator2(this.subFilter2);
          }}
        />,
        this.renderFilterButton(false),
        this.renderClearButton(),
      ];
    }

    renderFilterButton(hasDateFields) {
      return (
        <Tooltip content="Filter Events" key="submit-filter">
          <button
            type="submit"
            className="button"
            disabled={!this.subFilter1Valid || !this.subFilter2Valid}
            tabIndex="-2"
            onClick={this.onSubmit.bind(this, hasDateFields)}
          >
            <i className="material-icons icon-filter_list" />
          </button>
        </Tooltip>
      );
    }

    renderClearButton() {
      return (
        <Tooltip content="Clear Filter" key="clear-filter">
          <button
            type="submit"
            className={classnames('button', {
              hidden: !this.subFilter1 && !this.subFilter2,
            })}
            onClick={this.onClear.bind(this)}
            tabIndex="-2"
          >
            <i className="material-icons icon-clear" />
          </button>
        </Tooltip>
      );
    }

    render() {
      let components = [];
      if (Store.activeLabel === 'dob')
        components = this.renderNumberRange('Min Age', 'Max Age', 0, Infinity);
      else if (Store.activeLabel === 'dod') components = this.renderDateRange();
      else if (Store.activeLabel === 'id')
        components = this.renderSingleField('User ID');
      else if (Store.activeLabel === 'patientId')
        components = this.renderSingleField('Patient ID');
      else if (Store.activeLabel === 'mrn')
        components = this.renderSingleField('Patient MRN');
      else if (Store.activeLabel === 'name')
        components = this.renderSingleField('Name');
      else if (Store.activeLabel === 'organization')
        components = this.renderSingleField('Organization');
      else if (Store.activeLabel === 'sex')
        components = this.renderDropdown(['Male', 'Female']);
      else if (Store.activeLabel === 'suspicionScore')
        components = this.renderNumberRange('Min Score', 'Max Score', 0, 100);
      else if (Store.activeLabel === 'title')
        components = this.renderSingleField('Title');
      return (
        <form
          className="activity_view__filters form__small"
          onSubmit={this.onSubmit.bind(this)}
        >
          {components}
        </form>
      );
    }
  }
);

decorate(EventSubFilter, {
  subFilter1: observable,
  subFilter2: observable,
  subFilter1Valid: observable,
  subFilter2Valid: observable,
});

EventSubFilter.displayName = 'EventSubFilter';

export default EventSubFilter;
