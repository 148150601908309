import React from 'react';
import { observer } from 'mobx-react';
import CaseNotifications from '../CaseNotifications';
import CaseNotificationsStore from '../../stores/CaseNotificationsStore';

export function mapProps({ CaseNotificationsStore }) {
  return {
    onSave: () => CaseNotificationsStore.save(),
    isDirty: CaseNotificationsStore.isDirty,
    editedGroups: CaseNotificationsStore.editedGroups,
    onChangeSetting: CaseNotificationsStore.changeSetting,
  };
}

const CaseNotificationsContainer = observer(() => {
  return <CaseNotifications {...mapProps({ CaseNotificationsStore })} />;
});

CaseNotificationsContainer.displayName = 'CaseNotificationsContainer';

export default CaseNotificationsContainer;
