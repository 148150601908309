import {
  action,
  comparer,
  computed,
  decorate,
  observable,
  reaction,
  toJS,
} from 'mobx';
import {
  DateHelpers,
  FeatureFlags,
  GroupStore,
  IncidentClient,
  IncidentDescriptionStore,
  localStorageHasFlag,
  PermissionStore,
  SingletonStore,
} from 'common';
import {
  alphabeticalOpts,
  chronologicalOpts,
  defaultOptions,
  groupByOptions,
  statuses,
} from './const';
import $ from 'jquery';
import { sqUser, sqPatient } from '../../IncidentFilterBar/helpers';

const {
  TIME_PERIOD_VALUES: {
    ALL,
    WEEK_EXACT,
    MONTH_EXACT,
    NINETY_DAYS,
    QUARTER,
    YEAR,
  },
} = DateHelpers;

class IncidentReportFilterStore extends SingletonStore {
  constructor({ permissionStore, incidentDescriptionStore, groupStore }) {
    super();

    this.groupStore = groupStore;
    this.permissionStore = permissionStore;
    this.incidentDescriptionStore = incidentDescriptionStore;

    reaction(
      () => [this.query.range, this.query.rangeBefore, this.query.rangeAfter],
      () => {
        this.refresh();
      },
      {
        equals: comparer.structural,
      }
    );

    reaction(
      () => this.query,
      query => {
        let user;
        if (query.user) {
          user = this.filters.users?.find(({ id }) => id === query.user);
        }
        this.currentlySelectedFilters.user = user;

        let patient;
        if (query.patient) {
          patient = this.filters.patients?.find(
            ({ id }) => id === query.patient
          );
        }
        this.currentlySelectedFilters.patient = patient;
      },
      {
        equals: comparer.structural,
      }
    );
  }

  // Observables
  // Observes query strings
  query = {};

  // Observable
  /**
   * Stashes the following specific filter options as they are selected.
   * We hold on to these since updating the date filter can potentially blow
   * away the previously selected options.
   */
  currentlySelectedFilters = {
    type: null,
    user: null,
    patient: null,
  };

  // Observes dropdown UI filter options
  filterOptions = {
    adcs: [{ value: null, label: 'ADC', name: 'Any' }],
    dates: [], // default comes from DateHelpers.timePeriods
    medications: [{ value: null, label: 'Drug', name: 'All' }],
    status: [{ value: null, label: 'Status', name: 'Any' }],
    types: [{ value: null, label: 'Type', name: 'Any' }],
  };

  // Actions
  /**
   * Sets query parameters in store from URL
   * @param  {Object} newQuery - the Object containing params to be converted
   */
  setQueryFromURL = newQuery => {
    // get our field name values from the numeric string pair in session storage
    const userFullNames = sqUser.get(newQuery.userFullNames, null);
    const patientFullNames = sqPatient.get(newQuery.patientFullNames, null);

    this.query = {
      ...defaultOptions,
      ...newQuery,
      userFullNames,
      patientFullNames,
    };
  };

  fetch() {
    const customRange = [this.query.rangeAfter, this.query.rangeBefore];
    const [startDate, endDate] = DateHelpers.getDateParamsFromRange(
      this.query.range,
      customRange
    );
    if (this.permissionStore.has('MEDICATION_INCIDENT_VIEW')) {
      const params = {};
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;
      return IncidentClient.getTypeFilterOptions(params);
    }
  }

  // Computeds
  get filters() {
    return this.result || {};
  }

  get subNavDateOptions() {
    return DateHelpers.timePeriodsForValues(
      ALL,
      WEEK_EXACT,
      MONTH_EXACT,
      NINETY_DAYS,
      QUARTER,
      YEAR
    ).map(p => ({
      value: p.value,
      label: 'Time',
      name: p.label,
      range: p.range,
    }));
  }

  get adcOptions() {
    if ('stations' in this.filters)
      return this.filters.stations.map(val => ({
        value: val,
        label: 'ADC',
        name: val,
      }));
    return [];
  }

  get medicationOptions() {
    if ('medications' in this.filters)
      return this.filters.medications.map(val => ({
        value: val.id,
        label: 'Drug',
        name: val.genericName,
      }));
    return [];
  }

  get typeOptions() {
    return Array.from(
      (this.filters.types || []).reduce((allOptions, type) => {
        // Add descriptions to a set since we might have multiple type strings
        // that map to the same description.
        // E.g. AdministrationDelayedTranslator and
        // AdministrationDelayedSubClusterTranslator.
        allOptions.add(this.incidentDescriptionStore.defsMap[type]);
        return allOptions;
      }, new Set([]))
    )
      .filter(Boolean)
      .sort()
      .map(description => ({
        value: description,
        label: 'Type',
        name: description,
      }));
  }

  get allFilterOptions() {
    return {
      dates: [...DateHelpers.dateOptions],
      types: [...this.typeOptions],
      status: [...statuses],
      adcs: [...this.adcOptions],
      userFullNames: true, // passed in to render the User ExclusionFreeForm between other dropdowns
      patientFullNames: true, // passed in to render the Patient ExclusionFreeForm between other dropdowns
      medications: true,
      groups: this.groupStore.groups,
    };
  }

  get subnavFilterOptions() {
    const { status, types } = this.filterOptions;
    return this.isAdvancedFiltersOpen
      ? {}
      : {
          dates: [...this.subNavDateOptions],
          types: [...types, ...this.typeOptions],
          status: [...status, ...statuses],
        };
  }

  get allGroupByOptions() {
    return groupByOptions;
  }

  get allSortByOptions() {
    let opts;
    if (this.query.groupBy === 'startTime') opts = [...chronologicalOpts];
    else opts = [...alphabeticalOpts];
    return opts;
  }

  // computed
  get isAdvancedFiltersOpen() {
    return Boolean(this.query.showFilters);
  }

  // computed
  get nextAdvancedFiltersURL() {
    // closing advanced filters, remove any user and or patient values that were applied
    if (this.isAdvancedFiltersOpen) {
      if (this.query.range === 'custom') {
        delete this.query.rangeAfter;
        delete this.query.rangeBefore;
        delete this.query.range;
      }

      const currentQuery = { ...this.query };
      delete currentQuery.patientFullNames;
      delete currentQuery.userFullNames;
      delete currentQuery.medications;
      delete currentQuery.showFilters;
      delete currentQuery.type;
      delete currentQuery.excludeFilters;
      delete currentQuery.resolution;

      return `/incidents?${$.param(
        {
          ...currentQuery,
        },
        true
      )}`;
    }

    // opening the advanced filters, take the applied filters and move them to the advanced filter panel
    if (!this.query.group) this.query.group = this.groupStore.groupIds; // if no groups selected make it appear that all selected
    return `/incidents?${$.param(
      {
        ...toJS(this.query),
        ...{ showFilters: true },
      },
      true
    )}`;
  }
}

decorate(IncidentReportFilterStore, {
  // Observables
  filterOptions: observable,
  query: observable,
  currentlySelectedFilters: observable,
  // Actions
  setQueryFromURL: action,
  // Computeds
  filters: computed,
  adcOptions: computed,
  medicationOptions: computed,
  typeOptions: computed,
  allFilterOptions: computed,
  subnavFilterOptions: computed,
  subNavDateOptions: computed,
  allGroupByOptions: computed,
  allSortByOptions: computed,
  isAdvancedFiltersOpen: computed,
  nextAdvancedFiltersURL: computed,
});

export default new IncidentReportFilterStore({
  permissionStore: PermissionStore,
  incidentDescriptionStore: IncidentDescriptionStore,
  groupStore: GroupStore,
});
