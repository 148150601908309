import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'common';
import VipViewContainer from '../ViewContainer';
import VipCreateViewContainer from '../CreateViewContainer';
import BulkVipRoot from '../bulk/BulkVipRoot';
import ApplyBodyClassName from '../../ui/ApplyBodyClassName';
import ListViewRoot from '../ListViewRoot';
import BulkActionsRoot from '../BulkActionsRoot';

const RootView = () => {
  return (
    <main>
      <ApplyBodyClassName className="vips" />
      <Switch>
        <ProtectedRoute exact path="/vips/upload" component={BulkVipRoot} />
        <ProtectedRoute
          exact
          path="/vips/upload-confirm"
          component={BulkVipRoot}
        />

        <ProtectedRoute
          exact
          path="/vips/create"
          component={VipCreateViewContainer}
        />
        <ProtectedRoute exact path="/vips/update" component={BulkActionsRoot} />
        <ProtectedRoute
          exact
          path="/vips/create/patient/:patientId"
          component={VipCreateViewContainer}
        />
        <ProtectedRoute
          exact
          path="/vips/:vipId"
          component={VipViewContainer}
        />
        <ProtectedRoute exact path="/vips" component={ListViewRoot} />
        <Redirect to="/vips" />
      </Switch>
    </main>
  );
};

export default RootView;
