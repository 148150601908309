import moment from 'moment';
import { autorun, computed, decorate } from 'mobx';
import { AccessClient, SingletonStore, HalUtils } from 'common';
import { getAssessmentsHeadlines } from '../../../utils/AssessmentUtils';

import Store from '../Store';
import { compare } from '../../../utils/compare';

class AssessmentStore extends SingletonStore {
  constructor({ halUtils, activityStore }) {
    super();

    this.halUtils = halUtils;
    this.activityStore = activityStore;

    autorun(() => {
      this.refresh();
    });
  }

  fetch() {
    const {
      patientIdParam,
      userIdParam,
      fromDate,
      toDate,
    } = this.activityStore;
    if (userIdParam && patientIdParam) {
      return AccessClient.getScoreByPatientAndUser(
        patientIdParam,
        userIdParam,
        fromDate,
        toDate.clone().add(1, 'days')
      );
    }
    return [];
  }

  formatScores(score) {
    return Math.round(score * 100);
  }

  // Computed
  get detailed() {
    const r = this.result;
    return this.halUtils.getData(r) || [];
  }

  get assessments() {
    const { fromDate, toDate } = this.activityStore;
    const linkedAssessments = this.detailed
      .filter(d => d.assessments !== undefined && d.assessments.length)
      .flatMap(d => [
        ...d.assessments.map(a => {
          // legacy assessments were embedded in access buckets, which can
          // effectively act as their identifier
          if (!a.id) a.id = d.id;
          return a;
        }),
      ]);

    // if consecutive day buckets are returned, they could share an assessment.
    // however, we only want the assessment to appear once in the list so we
    // de-duplicate based on assessment ids
    const seenIds = new Set();
    return linkedAssessments
      .filter(
        a =>
          !seenIds.has(a.id) &&
          seenIds.add(a.id) &&
          moment(a.maxEventDate).isAfter(fromDate) &&
          moment(a.minEventDate).isBefore(toDate.clone().add(1, 'days'))
        // ISO Date string sort
      )
      .sort((a, b) => compare(a.minEventDate, b.minEventDate));
  }

  get assessmentsHeadlines() {
    return getAssessmentsHeadlines({
      assessments: this.assessments,
      type: 'privacy',
    });
  }

  get scores() {
    const { accessesVisible, focus } = this.activityStore;
    const scores = this.assessments
      .filter(a => a.suspicionScore !== undefined)
      .map(a => this.formatScores(a.suspicionScore));

    // hide privacy suspicion scores if accesses filter is off and is not diversion scores
    if (!accessesVisible && focus !== 'employee_incidents') return [];
    else return scores;
  }
}

decorate(AssessmentStore, {
  detailed: computed,
  assessments: computed,
  assessmentsHeadlines: computed,
  scores: computed,
});

export default new AssessmentStore({
  halUtils: HalUtils,
  activityStore: Store,
});
