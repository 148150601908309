import React from 'react';
import {
  AllUsersStore,
  FlashBannerStore,
  PermissionStore,
  VipClient,
} from 'common';
import { ListContext } from '../Context';
import { VipsStore } from '../stores/VipsStore';
import ListViewContainer from '../ListViewContainer';
import NotificationStore from '../../stores/NotificationStore';

class ListViewRoot extends React.Component {
  state = {
    vipsStore: new VipsStore({
      allUsersStore: AllUsersStore,
      flashBannerStore: FlashBannerStore,
      permissionStore: PermissionStore,
      notificationStore: NotificationStore,
      vipClient: VipClient,
    }),
  };

  render() {
    return (
      <ListContext.Provider value={this.state}>
        <ListViewContainer />
      </ListContext.Provider>
    );
  }
}

export default ListViewRoot;
