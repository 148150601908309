import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import CaseStore from '../stores/CaseStore';
import CaseAccessesStore from '../stores/CaseAccessesStore';
import CaseTabs from '../CaseTabs';
import CaseViewStore from '../stores/CaseViewStore';
import CaseLogStore from '../stores/CaseLogStore';

export function mapProps({
  CaseStore,
  CaseAccessesStore,
  CaseLogStore,
  CaseViewStore,
  location,
}) {
  return {
    analyticAssessments: CaseStore.analyticAssessments,
    analyticAssessmentLogs: CaseLogStore.analyticAssessmentLogs,
    caseCreatedDate: CaseStore.created,
    caseId: CaseStore.caseId,
    caseType: CaseStore.type,
    downloadCSV: CaseAccessesStore.downloadCSV,
    eventTypes: CaseStore.eventTypes,
    isMergeUOA:
      location.pathname.endsWith('accesses') && CaseAccessesStore.hasUOA,
    isExportAvail:
      (location.pathname.endsWith('incidents') &&
        CaseStore.medicationIncidents.length > 0) ||
      location.pathname.endsWith('accesses'),
    isUserDetails: location.pathname.includes('userDetails'),
    lastViewedByUser: CaseViewStore.lastViewedByUser,
    links: CaseStore.links,
    medicationIncidents: CaseStore.medicationIncidents,
    mergeUOA: CaseAccessesStore.mergeUOA,
    patientId: CaseStore.patientId,
    employeeId: CaseStore.userId,
    setMergeUOA: CaseAccessesStore.setMergeUOA,
    caseIncidentsLoading: CaseStore.caseIncidentsLoading,
    incidentsTabActive: location.pathname.endsWith('incidents'),
  };
}

const CaseTabsContainer = withRouter(
  observer(({ location }) => {
    return (
      <CaseTabs
        {...mapProps({
          CaseStore,
          CaseAccessesStore,
          CaseLogStore,
          CaseViewStore,
          location,
        })}
      />
    );
  })
);

CaseTabsContainer.displayName = 'CaseTabsContainer';

export default CaseTabsContainer;
