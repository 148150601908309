import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import classnames from 'classnames';

const BulkActionsHeader = ({ size, item, icon }) => (
  <header className={styles.header} data-cy="bulk-actions-header--text">
    <i className={classnames('material-icons', icon)} />
    {size} {item}
    {size === 1 ? '' : 's'} will be Changed
  </header>
);

BulkActionsHeader.propTypes = {
  size: PropTypes.number,
  item: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

BulkActionsHeader.defaultProps = {
  size: 0,
  icon: '',
};

export default BulkActionsHeader;
