import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../../ui/Tooltip';
import { Cell } from 'fixed-data-table-2';

const ActionCell = ({ path, method }) => {
  let verb;

  switch (method) {
    case 'GET':
      if (path.indexOf('search') !== -1) {
        verb = 'Query';
      } else if (path.indexOf('/print') !== -1) {
        verb = 'Print';
      } else {
        verb = 'View';
      }
      break;
    case 'PATCH':
      verb = 'Change';
      break;
    case 'PUT':
    case 'POST':
      verb = 'Add';
      break;
    default:
      verb = '';
  }

  return (
    <Cell>
      <Tooltip content={method} placement="top">
        <span>{verb}</span>
      </Tooltip>
    </Cell>
  );
};

ActionCell.propTypes = {
  path: PropTypes.string,
  method: PropTypes.string,
};

ActionCell.defaultProps = {
  path: '',
  method: '',
};

export default ActionCell;
