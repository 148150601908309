import React from 'react';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';
import { Pandy } from 'common';

export const STATUS_CODE_RESPONSES = {
  400: 'The server could not understand the request due to invalid syntax.',
  401: 'The client must authenticate itself to get the requested response.',
  403: 'The client does not have access rights to the content',
  404: 'The server can not find the requested resource',
  405: 'The request method is known by the server but has been disabled and cannot be used',
  500: "The server has encountered a situation it doesn't know how to handle",
  504: 'The server cannot get a response in time',
};

export const SpinnerWrapper = () => (
  <div className="loader-parent">
    <div className="loader">
      <Spinner big dark />
    </div>
  </div>
);

export const Response = ({ text = '', code }) => (
  <Pandy visible mood="sad">
    <div className="speechBubble">
      <span>{code ? `${code}: ` : null}Error occurred retrieving data</span>
    </div>
    <p>{text}</p>
  </Pandy>
);

const Loader = ({
  children = [],
  loaded,
  failure = '',
  failureStatus,
  customResponses = {},
}) => {
  let statusCodeResponses = STATUS_CODE_RESPONSES;
  if (Object.keys(customResponses).length)
    statusCodeResponses = { ...statusCodeResponses, ...customResponses };

  if (loaded && !failure && !failureStatus) return children;
  else {
    if (!loaded && !failure && !failureStatus) return <SpinnerWrapper />;
    else {
      if (failureStatus)
        return (
          <Response
            text={statusCodeResponses[failureStatus] || failure}
            code={failureStatus}
          />
        );
      else return <Response text={`Sorry, an error occurred`} />;
    }
  }
};

Response.propTypes = {
  text: PropTypes.string.isRequired,
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Loader.propTypes = {
  children: PropTypes.node.isRequired,
  loaded: PropTypes.bool.isRequired,
  failure: PropTypes.string,
  failureStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customResponses: PropTypes.shape({}),
};

export default Loader;
