import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { DoubleViewContext } from '../../../Context';
import { patientDropdownMapProps } from '../../mapProps';
import MultiRecordInformationDropdown from '../../../../ui/MultiRecordInformationDropdown';

const DoubleViewPatientDropdownContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DoubleViewContext,
    mapProps: patientDropdownMapProps,
    PresentationalComponent: MultiRecordInformationDropdown,
  })
);

export default DoubleViewPatientDropdownContainer;
