import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import {
  SINGLE_PATIENT,
  SINGLE_EMPLOYEE,
  PERSON_TYPE_PATIENT,
  PERSON_TYPE_USER,
  SINGLE_EMPLOYEE_LEFT_COLUMN,
  SINGLE_EMPLOYEE_RIGHT_COLUMN,
  SINGLE_PATIENT_LEFT_COLUMN,
  SINGLE_PATIENT_RIGHT_COLUMN,
} from '../../stores/const';
import MultiRecordPersonHeaderSectionContainer from '../../MultiRecordPersonHeaderSectionContainer';
import { MultiRecordUserMetadataContainer } from '../../multiRecordPersonMetadata/MultiRecordUserMetadataContainer';
import { MultiRecordPatientMetadataContainer } from '../../multiRecordPersonMetadata/MultiRecordPatientMetadataContainer';
import { SingleViewContext } from '../../Context';
import { useEphemeralStore } from '../../../utils/hooks';
import { MultiRecordInformationDropdownStore } from '../../stores/MultiRecordInformationDropdownStore';
import { MultiRecordEntityColumnStore } from '../../stores/MultiRecordEntityColumnStore';
import MultiRecordViewClient from 'common/src/app/clients/MultiRecordViewClient';
import { VisibleSystemStore } from 'common';
import {
  SingleViewPatientDropdownContainer,
  SingleViewPatientDropdownContainerSecond,
} from '../../views/single/SingleViewPatientDropdownContainer';
import {
  SingleViewPatientColumnContainer,
  SingleViewPatientColumnContainerSecond,
} from '../../views/single/SingleViewPatientColumnContainer';
import {
  SingleViewEmployeeDropdownContainer,
  SingleViewEmployeeDropdownContainerSecond,
} from '../../views/single/SingleViewEmployeeDropdownContainer';
import {
  SingleViewEmployeeColumnContainer,
  SingleViewEmployeeColumnContainerSecond,
} from '../../views/single/SingleViewEmployeeColumnContainer';
import MultiRecordActivityChartContainer from '../../MultiRecordActivityChartContainer';

const SingleView = ({ mode }) => {
  const employeeDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: SINGLE_EMPLOYEE_LEFT_COLUMN,
      })
  );
  const employeeDropdownStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: SINGLE_EMPLOYEE_RIGHT_COLUMN,
      })
  );
  const patientDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: SINGLE_PATIENT_LEFT_COLUMN,
      })
  );
  const patientDropdownStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: SINGLE_PATIENT_RIGHT_COLUMN,
      })
  );
  const employeeColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );
  const employeeColumnStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );
  const patientColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );
  const patientColumnStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
      })
  );
  return (
    <SingleViewContext.Provider
      value={{
        employeeDropdownStore,
        patientDropdownStore,
        employeeColumnStore,
        patientColumnStore,
        employeeDropdownStoreSecond,
        patientDropdownStoreSecond,
        employeeColumnStoreSecond,
        patientColumnStoreSecond,
      }}
    >
      {mode === SINGLE_PATIENT && (
        <div className={styles.doubleLayout}>
          <div className={styles.patientBox}>
            <MultiRecordPersonHeaderSectionContainer
              personType={PERSON_TYPE_PATIENT}
              forPage={'activity'}
            />
            <MultiRecordPatientMetadataContainer />
            <div className={styles.doubleColumn}>
              <div className={styles.column}>
                <SingleViewPatientDropdownContainer />
                <SingleViewPatientColumnContainer />
              </div>
              <div className={styles.column}>
                <SingleViewPatientDropdownContainerSecond />
                <SingleViewPatientColumnContainerSecond />
              </div>
            </div>
          </div>
          <div className={styles.activityChartBox}>
            <MultiRecordActivityChartContainer />
          </div>
        </div>
      )}
      {mode === SINGLE_EMPLOYEE && (
        <div className={styles.doubleLayout}>
          <div className={styles.employeeBox}>
            <MultiRecordPersonHeaderSectionContainer
              personType={PERSON_TYPE_USER}
              forPage={'activity'}
            />
            <MultiRecordUserMetadataContainer />
            <div className={styles.doubleColumn}>
              <div className={styles.column}>
                <SingleViewEmployeeDropdownContainer />
                <SingleViewEmployeeColumnContainer />
              </div>
              <div className={styles.column}>
                <SingleViewEmployeeDropdownContainerSecond />
                <SingleViewEmployeeColumnContainerSecond />
              </div>
            </div>
          </div>
          <div className={styles.activityChartBox}>
            <MultiRecordActivityChartContainer />
          </div>
        </div>
      )}
    </SingleViewContext.Provider>
  );
};

SingleView.propTypes = {
  mode: PropTypes.string,
};

export default SingleView;
