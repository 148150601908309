import React from 'react';
import { decorate, observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';
import {
  AlertCategoryStore,
  SettingsStore,
  PermissionStore,
  AlertCategoryGroupStore,
} from 'common';
import PropTypes from 'prop-types';
import AssignmentCell from '../AssignmentCell';
import CreateCasesCell from '../CreateCasesCell';
import CountCell from '../CountCell';
import LimitCell from '../LimitCell';
import Tooltip from '../../../ui/Tooltip';
import styles from './index.module.scss';

const BASE_ROW_HEIGHT = 60;
const TALL_ASSIGNMENT_HEIGHT = 40;

function isActive(rowIndex) {
  if (!rowIndex.group) return SettingsStore.creatingCases;
  return AlertCategoryStore.isGroupEnabledMap.get(
    rowIndex.group && rowIndex.group.name
  );
}

const SettingsListDetail = class SettingsListDetail extends React.Component {
  // Observable
  height = 1000;
  assignmentHeights = {};
  collapsed = true;

  get tableHeight() {
    const { data } = this.props;
    return (
      // Base height for each row + buffer.
      (data.length + 1.25) * BASE_ROW_HEIGHT +
      // Extra height for each tall row.
      Object.values(this.assignmentHeights).filter(
        h => h >= TALL_ASSIGNMENT_HEIGHT
      ).length *
        BASE_ROW_HEIGHT
    );
  }

  // Action
  resizeRowHeight = (rowIndex, assignmentHeight) => {
    if (this.assignmentHeights[rowIndex] !== assignmentHeight)
      this.assignmentHeights = {
        ...this.assignmentHeights,
        [rowIndex]: assignmentHeight,
      };
  };

  getRowHeight = rowIndex => {
    return this.assignmentHeights[rowIndex] >= TALL_ASSIGNMENT_HEIGHT
      ? BASE_ROW_HEIGHT * 2
      : BASE_ROW_HEIGHT;
  };

  setCellWidth = percentage => {
    const { width } = this.props;
    return (width * percentage) / 100;
  };

  /**
   * Take in the updates to perform
   *
   * @param {Object} model       the data model to update
   * @param {Number} limit      the new limit for the alert category
   * @param {Array} assignees    the assignees for new cases
   * @returns {void}
   */
  updateRow = (model, limit, assignees) => {
    SettingsStore.update(model, limit, assignees);
  };

  getTable = () => {
    const { data, width } = this.props;

    return (
      <Table
        rowHeight={BASE_ROW_HEIGHT}
        rowHeightGetter={this.getRowHeight}
        headerHeight={50}
        rowsCount={data.length}
        width={width}
        overflowX="hidden"
        overflowY="hidden"
        height={this.tableHeight}
      >
        <Column
          header={<Cell>Case Type</Cell>}
          width={this.setCellWidth(24)}
          cellClassName="no-wrap"
          cell={({ rowIndex, ...props }) => (
            <Cell
              className={classnames('settings__table-cell', {
                inactive: !isActive(data[rowIndex]),
              })}
              {...props}
            >
              {data[rowIndex].name}
            </Cell>
          )}
        />
        <Column
          header={<Cell>New Past Week</Cell>}
          width={this.setCellWidth(14)}
          cellClassName="no-wrap"
          cell={({ rowIndex, ...props }) => (
            <CountCell
              groupSize={AlertCategoryGroupStore.size}
              category={data[rowIndex]}
              groupActive={isActive(data[rowIndex])}
              store={SettingsStore.weekCases}
              {...props}
            />
          )}
        />
        <Column
          header={<Cell>Enabled</Cell>}
          width={this.setCellWidth(11)}
          cellClassName="no-wrap"
          cell={({ rowIndex, ...props }) => (
            <CreateCasesCell
              updateRow={this.updateRow}
              data={data[rowIndex]}
              groupActive={isActive(data[rowIndex])}
              {...props}
            />
          )}
        />
        <Column
          header={<Cell>7-Day Limit</Cell>}
          width={this.setCellWidth(10)}
          cellClassName="no-wrap"
          cell={({ rowIndex }) => (
            <LimitCell
              groupActive={isActive(data[rowIndex])}
              updateRow={this.updateRow}
              data={data[rowIndex]}
              alertCategoryPermission={PermissionStore.has(
                'ALERT_CATEGORY_SAVE'
              )}
            />
          )}
        />
        <Column
          header={<Cell>New Case Assignment</Cell>}
          width={this.setCellWidth(41)}
          cellClassName="tall"
          cell={({ rowIndex, ...props }) => (
            <AssignmentCell
              groupActive={isActive(data[rowIndex])}
              resize={assignmentHeight =>
                this.resizeRowHeight(rowIndex, assignmentHeight)
              }
              updateRow={this.updateRow}
              category={data[rowIndex]}
              {...props}
            />
          )}
        />
      </Table>
    );
  };

  getGroupTable = () => {
    const { name } = this.props;
    const enabled = AlertCategoryStore.isGroupEnabledMap.get(name);

    return (
      <div
        className={classnames(styles['settings-groups'], {
          [styles.inactive]: !enabled,
        })}
        data-cy="settings-list-table"
      >
        <div className={styles.header} onClick={this.toggleGroup}>
          <div className={styles.title}>
            <span>{name}</span>&nbsp;
            <span
              className={classnames({ hidden: enabled })}
              data-cy="settings-list-paused"
            >
              Paused
            </span>
          </div>
          <div className={styles.icons}>
            <Tooltip
              content={`${
                enabled ? 'Pause ' : 'Resume '
              } ${name} Case Creation`}
              placement="left"
            >
              <i
                onClick={this.togglePlayPause}
                className={classnames('material-icons', {
                  hidden: !PermissionStore.has('ALERT_CATEGORY_SAVE'),
                  'icon-play_arrow': !enabled,
                  'icon-pause': enabled,
                })}
                data-cy="settings-list-play-pause"
              />
            </Tooltip>
            <i
              className={classnames(styles.arrow, {
                [styles.collapsed]: this.collapsed,
                'material-icons icon-keyboard_arrow_up': true,
              })}
              data-cy="settings-list-arrow"
            />
          </div>
        </div>
        <AnimateHeight
          duration={200}
          className={styles.body}
          animationStateClasses={{
            staticHeightSpecific: [styles.overflow],
          }}
          height={this.collapsed ? 0 : this.tableHeight}
        >
          {this.getTable()}
        </AnimateHeight>
      </div>
    );
  };

  toggleGroup = () => {
    this.collapsed = !this.collapsed;
  };

  togglePlayPause = e => {
    const { toggleGroupPlay, data } = this.props;
    e.stopPropagation();
    toggleGroupPlay(data[0].group);
  };

  render() {
    const { displayGroups } = this.props;
    return displayGroups ? this.getGroupTable() : this.getTable();
  }
};

decorate(SettingsListDetail, {
  height: observable,
  collapsed: observable,
  assignmentHeights: observable,
  tableHeight: computed,
});

SettingsListDetail.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      group: PropTypes.shape({}),
    })
  ).isRequired,
  toggleGroupPlay: PropTypes.func,
  name: PropTypes.string,
  displayGroups: PropTypes.bool.isRequired,
};

SettingsListDetail.defaultProps = {
  toggleGroupPlay: () => {},
  name: '',
};

export default observer(SettingsListDetail);
