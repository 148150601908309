import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import classnames from 'classnames';
import { Pandy } from 'common';
import Source from '../../../ui/Source';
import DrawerHeader from '../DrawerHeader';
import styles from './index.module.scss';
import IncidentGroup from '../../../ui/IncidentGroupList/IncidentGroup';
import PromptWrapper, { DEFAULT_MESSAGE } from '../../../ui/PromptWrapper';
import { withRouterAndQuery } from '../../../utils/RouteHelper';
import { ScrollIntoViewContext } from '../../../ui/context';
import { useReactiveRef } from '../../../utils/hooks';

const DrawerIncidents = ({
  drawerControls,
  onSelectEvent,
  onSelectIncident,
  selectedIncident,
  scrollToInc,
  clearScrollToInc,
  statistics,
  personLink,
  rangeEnd,
  rangeStart,
  hasUnsavedNotes,
  query,
  location,
}) => {
  const isOutsideRange = incident => {
    const incidentDate = moment(incident.startTime);
    return (
      rangeStart.isAfter(incidentDate, 'day') ||
      rangeEnd.isBefore(incidentDate, 'day')
    );
  };

  const nextOutsideRange = (statistics, statIndex, incIndex) => {
    // check next incident in this period
    if (statistics[statIndex].incidents.length > incIndex + 1) {
      return isOutsideRange(statistics[statIndex].incidents[incIndex + 1]);
    }

    // check first incident in next period
    if (statistics.length > statIndex + 1) {
      return isOutsideRange(statistics[statIndex + 1].incidents[0]);
    }

    return false;
  };

  const count = (statistics || [])
    .flatMap(s => s.incidents || [])
    .reduce(
      (sum, { linkedIncidents = { length: 1 } }) =>
        sum + linkedIncidents.length,
      0
    );

  const filteredStats = statistics.filter(
    s => s.incidents && s.incidents.length
  );

  const messageForPrompt = nextLocation => {
    // Do not prompt if we are just changing a query param that affects the
    // current view's display, like user or patient label.
    const currentFromDateString = `fromDate=${query.fromDate}`;
    const currentToDateString = `toDate=${query.toDate}`;
    const shouldPrompt =
      nextLocation.pathname !== location.pathname ||
      !nextLocation.search.includes(currentFromDateString) ||
      !nextLocation.search.includes(currentToDateString);

    // Returning true results in no prompt.
    return shouldPrompt ? DEFAULT_MESSAGE : true;
  };

  const [sectionRef, setSectionRef] = useReactiveRef();

  return (
    <>
      <DrawerHeader count={count} type="Incident">
        {drawerControls}
      </DrawerHeader>
      <section
        className={classnames('incidents', styles.drawerIncidents)}
        ref={setSectionRef}
      >
        <ScrollIntoViewContext.Provider
          value={
            sectionRef
              ? {
                  scrollIntoView: node => {
                    if (node) {
                      $(sectionRef).animate(
                        {
                          scrollTop: node.offsetTop - 10,
                        },
                        500
                      );
                    }
                  },
                }
              : null
          }
        >
          {count ? (
            filteredStats.map((s, i) => {
              const source = s.user && s.user.source;
              const dateRange = `${moment(s.startTime).format('l')} - ${moment(
                s.endTime
              ).format('l')}`;
              const isInAssessmentRange = !s.outOfBound;

              return (
                <div key={`statistic-${source}_${s.startTime}`}>
                  <h5>
                    {source && (
                      <Source source={source} className={styles.iconInfo} />
                    )}
                    &nbsp;
                    {isInAssessmentRange
                      ? `Incidents from Assessment Period ${dateRange}`
                      : `Incidents`}
                  </h5>
                  {s.incidents.map(({ id, linkedIncidents }, j) => {
                    const selected = id === selectedIncident;
                    const outside = isOutsideRange(s.incidents[j]);
                    const scrollTo = scrollToInc === id;

                    const incidentGrouping = (
                      <IncidentGroup
                        key={id}
                        incidents={linkedIncidents}
                        onSelectIncident={() =>
                          onSelectIncident(s.incidents[j])
                        }
                        clearScrollTo={clearScrollToInc}
                        buildEventLink={onSelectEvent}
                        selected={selected}
                        showCaseLink
                        scrollToInc={scrollTo}
                        buildPersonLink={personLink}
                      />
                    );

                    // incident is outside range but next one isn't
                    if (
                      outside &&
                      !nextOutsideRange(filteredStats, i, j) &&
                      (j < s.incidents.length - 1 ||
                        i < filteredStats.length - 1)
                    ) {
                      return [
                        incidentGrouping,
                        <hr key="chart-start" className={styles.chartStart} />,
                      ];
                    }

                    // incident is inside range but next one isn't
                    if (!outside && nextOutsideRange(filteredStats, i, j)) {
                      return [
                        incidentGrouping,
                        <hr key="chart-end" className={styles.chartEnd} />,
                      ];
                    }

                    return incidentGrouping;
                  })}
                </div>
              );
            })
          ) : (
            <Pandy visible mood="happy">
              <div className="speechBubble">
                <span>No incidents.</span>
              </div>
            </Pandy>
          )}
        </ScrollIntoViewContext.Provider>
      </section>
      <PromptWrapper when={hasUnsavedNotes} message={messageForPrompt} />
    </>
  );
};

DrawerIncidents.propTypes = {
  drawerControls: PropTypes.node,
  onSelectEvent: PropTypes.func.isRequired,
  onSelectIncident: PropTypes.func.isRequired,
  rangeEnd: PropTypes.instanceOf(moment).isRequired,
  rangeStart: PropTypes.instanceOf(moment).isRequired,
  selectedIncident: PropTypes.string,
  clearScrollToInc: PropTypes.func,
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      incidents: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  personLink: PropTypes.func.isRequired,
  scrollToInc: PropTypes.string,
  hasUnsavedNotes: PropTypes.bool.isRequired,
  query: PropTypes.shape(),
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

DrawerIncidents.defaultProps = {
  drawerControls: null,
  clearScrollToInc: () => {},
  selectedIncident: undefined,
  scrollToInc: null,
};

export { DrawerIncidents }; // For testing purposes.
export default withRouterAndQuery(DrawerIncidents);
