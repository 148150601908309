import React from 'react';
import PropTypes from 'prop-types';
import View from '../View';
import { observer } from 'mobx-react';
import { PermissionStore } from 'common';

import Store from '../stores/VipStore';
import NotificationStore from '../../stores/NotificationStore';
import { withRouter } from 'react-router-dom';

export const mapProps = ({
  match,
  Store,
  NotificationStore,
  PermissionStore,
}) => {
  return {
    addNotification: msg => NotificationStore.add(msg),
    created: Store.created,
    createdByFullName: Store.createdByFullName,
    status: Store.status,
    patient: Store.patient,
    hasPermission: PermissionStore.has('VIP_REGISTRATION_MODIFY'),
    result: Store.result,
    tags: Store.tags,
    reason: Store.reason,
    endDate: Store.endDate,
    lastModifiedByFullName: Store.lastModifiedByFullName,
    lastModified: Store.lastModified,
    isLoading: Store.isLoading,
    setValue: Store.setValue,
    update: Store.update,
    paramsId: match.params.vipId,
    id: Store.id,
  };
};

const ViewContainer = observer(({ match }) => (
  <View
    {...mapProps({
      match,
      NotificationStore,
      Store,
      PermissionStore,
    })}
  />
));

ViewContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      vipId: PropTypes.string,
    }),
  }),
};

ViewContainer.displayName = 'ViewContainer';

export default withRouter(ViewContainer);
