import React from 'react';
import PropTypes from 'prop-types';
import { autorun, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import SelectMenu from '../../SelectMenu';

import {
  AddCaseAction,
  ChangeCaseOwner,
  ChangeCaseType,
  ChangeCaseResolution,
  DeleteCases,
  AddCaseNote,
  OverwriteCaseAssessment,
  BundleCases,
  RemoveFromBundle,
} from '../index';

class BulkCaseActions extends React.Component {
  static propTypes = {
    store: PropTypes.shape({
      actionList: PropTypes.array,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ).isRequired,
      completed: PropTypes.bool,
      completeSuccessCount: PropTypes.number,
      completeFailureCount: PropTypes.number,
      selected: PropTypes.object.isRequired,
      percentComplete: PropTypes.number.isRequired,
      percentRemaining: PropTypes.number,
      performingBulkAction: PropTypes.bool.isRequired,
    }),
    defaultValue: PropTypes.object,
  };

  // Observable
  action = null;
  // Not Observable
  disposers = [];

  componentDidMount() {
    this.disposers.push(
      autorun(() => {
        if (!this.props) return;

        const { store } = this.props;

        if (store && store.completed) {
          this.action = null; // reset the active action
        }
      })
    );
  }

  componentWillUnmount() {
    this.disposers.forEach(d => d());
  }

  changeAction = action => {
    this.action = action.value;
  };

  renderAction() {
    const { store } = this.props;
    let node;

    if (this.action === 'resolution') {
      node = <ChangeCaseResolution store={store} />;
    }

    if (this.action === 'type') {
      node = <ChangeCaseType store={store} />;
    }

    if (this.action === 'owner') {
      node = <ChangeCaseOwner store={store} />;
    }

    if (this.action === 'action') {
      node = <AddCaseAction store={store} />;
    }

    if (this.action === 'notes') {
      node = <AddCaseNote store={store} />;
    }

    if (this.action === 'assessment') {
      node = <OverwriteCaseAssessment store={store} />;
    }

    if (this.action === 'bundle') {
      node = <BundleCases store={store} />;
    }

    if (this.action === 'removeFromBundle') {
      node = <RemoveFromBundle store={store} />;
    }

    if (this.action === 'delete') {
      node = <DeleteCases store={store} />;
    }

    return node;
  }

  renderCompletionText = () => {
    const { store } = this.props;
    if (this.action === null && store.completed) {
      return (
        <div className="help-text">
          <p className="small">
            <span
              className={classnames({
                hidden: store.completeSuccessCount < 1,
              })}
            >
              {store.completeSuccessCount} case
              {store.completeSuccessCount === 1 ? '' : 's'} successfully
              changed.
              <br />
            </span>
            <span
              className={classnames({
                hidden: store.completeFailureCount < 1,
              })}
            >
              {store.completeFailureCount} case
              {store.completeFailureCount === 1 ? '' : 's'} failed.
            </span>
          </p>
          <p className="small">
            Select another task to perform additional bulk actions.
          </p>
        </div>
      );
    }
  };

  renderBundleActions() {
    const { store, defaultValue } = this.props;
    const { selected, performingBulkAction } = store;

    if (selected.size < 1) {
      return <div>Please select at least one case to perform any actions.</div>;
    }

    return (
      <div data-cy="bulk-actions-action-dropdwon">
        <SelectMenu
          placeholder="Select an Action"
          data={store.actionList || store.actions}
          defaultValue={defaultValue || null}
          value={this.action}
          onChange={this.changeAction}
          disabled={performingBulkAction}
        />

        {this.renderAction()}
        {this.renderCompletionText()}
      </div>
    );
  }

  render() {
    return (
      <div className="bulk-case-actions__action">
        {this.renderBundleActions()}
      </div>
    );
  }
}

decorate(BulkCaseActions, {
  action: observable,
});

export default observer(BulkCaseActions);
