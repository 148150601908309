// event types for csv export in case and activity view
export const INCIDENT_SUMMARY = 'Incident Summary';
export const INCIDENT_SUMMARY_AND_EVENT_DETAILS =
  'Incident Summary & Event Details';

// endpoints for csv export in case and activity view
export const INCIDENT_SUMMARY_URL = 'incidents/export/summary';
export const INCIDENT_SUMMARY_AND_EVENT_DETAILS_URL =
  'incidents/export/summaryAndDetails';
export const INCIDENT_SUMMARY_BY_IDS_URL = 'incidents/export/summaryByIds';
export const INCIDENT_SUMMARY_AND_EVENT_DETAILS_BY_IDS_URL =
  'incidents/export/summaryAndDetailsByIds';
