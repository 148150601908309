import React, { useEffect } from 'react';
import { Observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { EmployeeCasesStore, HalUtils, PermissionStore } from 'common';
import UserCard from '../UserCard';
import PropTypes from 'prop-types';

export function mapProps({
  disabled,
  EmployeeCasesStore,
  onSelect,
  openLinkNew,
  selectable,
  selected,
  user,
  PermissionStore,
}) {
  const id = HalUtils.getId(user);

  return {
    cases: EmployeeCasesStore.infoById.get(id),
    disabled,
    onSelect,
    openLinkNew,
    selectable,
    selected,
    user,
    hideDob: !PermissionStore.has('USER_PII_VIEW'),
  };
}

const Index = ({
  disabled,
  onSelect,
  openLinkNew,
  selectable,
  selected,
  user,
}) => {
  const id = HalUtils.getId(user);

  useEffect(() => {
    EmployeeCasesStore.fetch(id);
  }, [id]);

  // observe changes of async case request
  return (
    <Observer>
      {() => (
        <UserCard
          {...mapProps({
            disabled,
            EmployeeCasesStore,
            onSelect,
            openLinkNew,
            selectable,
            selected,
            user,
            PermissionStore,
          })}
        />
      )}
    </Observer>
  );
};

Index.propTypes = {
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  openLinkNew: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  user: PropTypes.shape({
    dateOfBirth: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    recentDepartment: PropTypes.string,
    recentOrganization: PropTypes.string,
    recentRoleName: PropTypes.string,
    tags: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ),
    userId: PropTypes.string,
  }).isRequired,
};

Index.defaultProps = {
  disabled: false,
  onSelect: null,
  openLinkNew: false,
  selectable: false,
  selected: false,
};

Index.displayName = 'UserCardContainer';

export default Index;
