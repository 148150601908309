import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import styles from './index.module.scss';
import MultiRecordCompositeFieldValue from '../MultiRecordCompositeField/Value';
import MultiRecordCompositeFieldSources from '../MultiRecordCompositeField/Sources';
import classnames from 'classnames';
import { generateHighlightedText } from '../utils/helpers';

const MultiRecordCompositeFieldGroup = ({
  fieldName,
  fields,
  filteredTerm,
}) => (
  <div className={styles.compositeFieldGroup}>
    <h3 className={classnames(styles.text, styles.fieldName)}>
      {generateHighlightedText(fieldName, filteredTerm)}
    </h3>
    <div className={styles.fieldCol}>
      {fields.map((field, i) => (
        <div
          key={`${fieldName}${i}`}
          className={classnames(styles.fieldRow, styles.rowOffset)}
        >
          <MultiRecordCompositeFieldValue
            field={field}
            filteredTerm={filteredTerm}
          />
          <MultiRecordCompositeFieldSources sources={field.sources} />
        </div>
      ))}
    </div>
  </div>
);

MultiRecordCompositeFieldGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fields: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object).isRequired,
  filteredTerm: PropTypes.string,
};

export default MultiRecordCompositeFieldGroup;
