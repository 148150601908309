import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import PersonAttribute from '../../people/PersonBox/PersonAttribute';
import { Link } from 'react-router-dom';
import intersperse from '../../utils/intersperse';

const UserTagsAttribute = ({ canManageTags, tags, userId }) => {
  let content;
  if (tags?.length) {
    content = intersperse(
      tags.map(tag => (
        <Link key={tag.id} to={`/user-tags/${tag.id}`}>
          {tag.name}
        </Link>
      )),
      ', '
    );
  }

  return (
    <PersonAttribute
      content={content}
      emptyLink={
        canManageTags ? `/user-tags/assign/employee/${userId}` : undefined
      }
      emptyText="No User Tags"
      icon="icon-label"
      tooltipText="Assign User Tags"
    />
  );
};

UserTagsAttribute.propTypes = {
  canManageTags: PropTypes.bool,
  tags: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  userId: PropTypes.string.isRequired,
};

export default UserTagsAttribute;
