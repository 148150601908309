import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './index.module.scss';
import NoteByline from './NoteByline';

import { TextFormatter, MultilineInput } from '../../ui';

const ChartNote = ({ onSave, onRemove, note, onReset, onNoteEdit }) => {
  const [deletingNote, setDeletingNote] = useState(false);
  const [textareaValue, setTextareaValue] = useState(note?.notes);
  const [editingNote, setEditingNote] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const reset = useCallback(() => {
    setTextareaValue(note?.notes);
    setEditingNote(false);
    setDeletingNote(false);
    setIsDirty(false);
  }, [note]);

  useEffect(() => {
    reset();
  }, [note, reset]);

  useEffect(() => {
    onNoteEdit(isDirty);
  }, [isDirty, onNoteEdit]);

  const isNoteDirty = val => {
    if (!note?.notes) return !!val;
    if (editingNote) return note?.notes !== val;
    return false;
  };

  const onChange = val => {
    setTextareaValue(val);
    const isDirty = isNoteDirty(val);
    setIsDirty(isDirty);
  };

  const onCancel = () => {
    reset();
    setTimeout(() => onReset(), 100);
  };

  const renderAction = (action, callBack, danger) => {
    return (
      <span
        className={classnames('prot-a forScreen', { 'text-danger': danger })}
        key={action}
        onClick={callBack}
        onKeyPress={callBack}
        role="link"
        tabIndex="0"
        data-cy="note-action-renderer"
      >
        {action}
      </span>
    );
  };

  const renderNoteActions = () => {
    const actions = [];

    if (!editingNote && deletingNote) {
      actions.push(renderAction('Confirm Delete', () => onRemove(), true));
      actions.push(renderAction('Cancel', () => onCancel()));
    } else if (!editingNote && note) {
      actions.push(renderAction('Edit', () => setEditingNote(true)));
      actions.push(renderAction('Delete', () => setDeletingNote(true)));
    } else {
      actions.push(renderAction('Cancel', () => onCancel(), true));
      if (isDirty)
        actions.push(renderAction('Save', () => onSave(textareaValue)));
    }

    return actions;
  };

  return (
    <div
      className={classnames(styles.note, {
        [styles.confirmDelete]: deletingNote,
      })}
    >
      <div className={styles.noteHeader}>
        <strong>Note</strong>
        <span className={styles.actions}>{renderNoteActions()}</span>
        {note && <NoteByline note={note} />}
      </div>
      <div className={styles.noteContent} data-cy="note-content">
        {note && !editingNote ? (
          <TextFormatter text={note.notes} />
        ) : (
          <MultilineInput
            darkBackground
            value={textareaValue || ''}
            onChange={val => onChange(val)}
            placeholder="Type to add additional notes"
          />
        )}
      </div>
    </div>
  );
};

ChartNote.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onNoteEdit: PropTypes.func,
  prevId: PropTypes.string,
  paramsId: PropTypes.string,
  note: PropTypes.shape({
    notes: PropTypes.string,
  }),
};

ChartNote.defaultProps = {
  note: null,
};

export default ChartNote;
