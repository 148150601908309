import React from 'react';
import { Switch } from 'react-router-dom';
import { Authenticated, ProtectedRoute } from 'common';
import { DocumentTitle } from '../../../ui';
import RolesListViewContainer from '../RolesListViewContainer';
import RolesManageFormContainer from '../RolesManageFormContainer';
import styles from './index.module.scss';

const RolesRootView = () => (
  <Authenticated appRole="admin">
    <DocumentTitle text="Roles Management" />
    <div className={styles.rolesRootView}>
      <RolesListViewContainer />
      <Switch>
        <ProtectedRoute
          exact
          path="/settings/roles/create"
          component={RolesManageFormContainer}
        />
        <ProtectedRoute
          exact
          path="/settings/roles/:rolename"
          component={RolesManageFormContainer}
        />
      </Switch>
    </div>
  </Authenticated>
);

export default RolesRootView;
