import React from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { LoginStore } from 'common';

export const DEFAULT_MESSAGE =
  'Oh no! If you leave this page any unsaved changes you have made will be lost. Are you sure you wish to leave?';

/*
* Prompt Wrapper
-------------------
* A stateless react component that checks auth status and return react-router-dom Prompt component.
* The parameters are the same as react-router-dom Prompt, it just checks user auth status.
*
* @param {Object}
*   message: string | ((location: H.Location) => string | boolean);
*   when?: boolean;
*
*  NOTE:
*  - Tried using React.Component and observable to mirror login state but that reintroduced the same bug.
*
*  TODO:
*  - See if this can prevent changing url when alert is up which resulted in falsh of unstyled page bug.
*
* @return {Object} <Prompt />
*/

const PromptWrapper = ({ when, message }) => {
  if (LoginStore.loggedIn) return <Prompt when={when} message={message} />;
  return null;
};

PromptWrapper.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

PromptWrapper.defaultProps = {
  when: false,
  message: DEFAULT_MESSAGE,
};

export default PromptWrapper;
