import React from 'react';
import { observer } from 'mobx-react';

import DeleteForm from '../../DeleteForm';

import BulkCaseActionBase from '../BulkCaseActionBase';

const DeleteCases = observer(
  class extends BulkCaseActionBase {
    render() {
      const deletePromise = value =>
        this.props.store.performBulkAction('delete', value);

      return (
        <DeleteForm
          description="These cases will be permanently deleted and cannot be recovered."
          notify={false}
          padding={false}
          plural
          reason
          subject="case"
          submit={deletePromise}
        />
      );
    }
  }
);

export default DeleteCases;
