import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { MultiRecordCompositeSectionFilterStore } from '../../stores/MultiRecordCompositeSectionFilterStore';
import { useEphemeralStore } from '../../../utils/hooks';
import MultiRecordCompositeFieldSection from '../../MultiRecordCompositeFieldSection';

export const MultiRecordEmployeeInformation = ({
  employeeInformationSections,
}) => {
  const multiRecordCompositeSectionFilterStore = useEphemeralStore(
    () => new MultiRecordCompositeSectionFilterStore()
  );
  return (
    <div className={styles.wrapper}>
      {employeeInformationSections?.map((groups, idx) => {
        return (
          <div className={styles.employeeInformationGroup} key={idx}>
            <MultiRecordCompositeFieldSection
              name={groups?.name}
              isCountVisible={groups?.isCountVisible}
              isExpandable={groups?.isExpandable}
              groups={groups?.groups}
              multiRecordCompositeSectionFilterStore={
                multiRecordCompositeSectionFilterStore
              }
            />
          </div>
        );
      })}
    </div>
  );
};

MultiRecordEmployeeInformation.propTypes = {
  employeeInformationSections: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.array,
  ]),
};
