import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import classnames from 'classnames';

import { HalUtils } from 'common';

import ActionNav from '../../../navbar/ActionNav';
import styles from './index.module.scss';

const CREATE_PATH = '/settings/enums/create';

const EnumsListView = ({ allEnums, selectedEnumValueId, onEditEnumValue }) => {
  return (
    <section className={styles.tablePanel}>
      <ActionNav
        secondary
        store={{ size: allEnums.length }}
        count={allEnums.length}
        createPath={CREATE_PATH}
        createRole="admin"
        createIcon="add_circle"
        title="Enum Value"
        createLabel="Create New Enum Value"
      />
      <div className={styles.tableArea}>
        <h2>Values</h2>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Category</th>
              <th>Name</th>
              <th>Description</th>
              <th>Groups</th>
              <th>Deprecated</th>
            </tr>
          </thead>
          <tbody>
            {allEnums.map(num => {
              const numId = HalUtils.getId(num);
              return (
                <tr
                  key={Math.random()}
                  onClick={() => onEditEnumValue(numId)}
                  className={classnames({
                    [styles.selectedRow]: numId === selectedEnumValueId,
                  })}
                >
                  <td>{num.category}</td>
                  <td>{num.name}</td>
                  <td>{num.description}</td>
                  <td>
                    {(num.groups || []).length === 0
                      ? 'Visible to All'
                      : num.groups.length === 1
                      ? num.groups[0] && num.groups[0].name
                      : `${num.groups.length} groups`}
                  </td>
                  <td>{num.deprecated ? 'True' : 'False'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

EnumsListView.propTypes = {
  allEnums: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({}))
    .isRequired,
  selectedEnumValueId: PropTypes.string,
  onEditEnumValue: PropTypes.func.isRequired,
};

EnumsListView.defaultProps = {
  selectedEnumValueId: null,
};
export default EnumsListView;
