import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import classnames from 'classnames';
import ViaSource from '../../../ui/ViaSource';
import styles from './index.module.scss';

const FormattedLabels = ({ labels }) => {
  // Convert the array of {source, text} objects to an object of
  // {source: [text]}
  let sourceToTextsMap = labels.reduce((sourceToTextsMap, label) => {
    const source = label.source || 'Unknown Source';
    sourceToTextsMap[source]
      ? sourceToTextsMap[source].push(label.text)
      : (sourceToTextsMap[source] = [label.text]);
    return sourceToTextsMap;
  }, {});
  // Deduplicate texts within the same source.
  sourceToTextsMap = Object.entries(sourceToTextsMap).reduce(
    (sourceToTextsMap, [source, texts]) => {
      return {
        ...sourceToTextsMap,
        [source]: Array.from(new Set(texts)),
      };
    },
    {}
  );

  return Object.entries(sourceToTextsMap).map(([source, texts]) => (
    <div className={styles.labelGroup} key={source}>
      {texts.map(text => (
        <div
          className={classnames('ellipsis', styles.labelLine)}
          key={text}
          title={text}
        >
          {text}
        </div>
      ))}
      <ViaSource source={source} />
    </div>
  ));
};

FormattedLabels.propTypes = {
  labels: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
};

export default FormattedLabels;
