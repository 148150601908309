import React from 'react';
import PropTypes from 'prop-types';
import { RegistrySortableHeaderCell, Tooltip } from '../../../ui';
import styles from '../index.module.scss';

const HeaderCell = ({ label, propName, store, tooltipContent }) => (
  <RegistrySortableHeaderCell
    label={label}
    propName={propName}
    store={store}
    baseUrl="/data-feed/delivery"
    data-cy="fdd-delivery-header"
  >
    {tooltipContent ? (
      <Tooltip content={tooltipContent}>
        <span className={styles.tooltip} data-cy="fdd-delivery-tooltip" />
      </Tooltip>
    ) : null}
  </RegistrySortableHeaderCell>
);

HeaderCell.propTypes = {
  label: PropTypes.string,
  propName: PropTypes.string,
  store: PropTypes.shape({
    sortBy: PropTypes.string,
    sortDir: PropTypes.string,
  }),
  tooltipContent: PropTypes.string,
};

export default HeaderCell;
