import { EncounterClient, InfoCacheStore, PermissionStore } from 'common';

class EncounterStore extends InfoCacheStore {
  cacheSize() {
    return 100;
  }

  fetchData(id) {
    return EncounterClient.getById(id, { projection: 'full' });
  }

  hasPermission() {
    return PermissionStore.has('ENCOUNTER_VIEW');
  }
}

export default new EncounterStore();
