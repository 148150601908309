import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';

const LegendItem = observer(
  class extends Component {
    static propTypes = {
      type: PropTypes.string.isRequired,
      store: PropTypes.shape({
        active: PropTypes.string,
        data: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string.isRequired,
            group: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
            color: PropTypes.string.isRequired,
          })
        ).isRequired,
      }).isRequired,
    };

    /**
     * Method to determine whether or not a specific category has been set to
     * "active" in the store.
     * @param {String} group - the name of the category to check
     * @return {Boolean} the evaluated result of the check
     */
    isDisabled(group) {
      const { store } = this.props;

      return store.active && store.active !== group;
    }

    render() {
      const { store, type } = this.props,
        data = store.data.find(d => d.type === type) || {},
        group = data.group,
        count = data.count,
        color = data.color;

      return (
        <li
          key={type}
          className={classnames({
            disabled: this.isDisabled(group),
          })}
        >
          <i className="material-icons icon-brightness_1" style={{ color }} />
          <span>{group}</span>
          <span className="data__count">{`${count} Case${
            count === 1 ? '' : 's'
          }`}</span>
        </li>
      );
    }
  }
);

LegendItem.displayName = 'LegendItem';

export default LegendItem;
