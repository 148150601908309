import DataFeedEvaluationAside from '../DataFeedEvaluationAside';
import { DataFeedContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({ evaluationStore, history, query, location }) {
  return {
    selectedFeedRow: evaluationStore.selectedFeedRow,
    isOpen: query.showAsidePanel === 'true',
    history,
    query,
    location,
  };
}

const DataFeedEvaluationAsideContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DataFeedContext,
    mapProps: mapProps,
    PresentationalComponent: DataFeedEvaluationAside,
  })
);

export default DataFeedEvaluationAsideContainer;
