import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { PermissionStore } from 'common';

import CustomDropdown from '../CustomDropdown';

const ExportMenu = observer(
  ({
    buttonClass,
    iTip,
    exportCb,
    eventTypes,
    cb,
    menuDisabled,
    wrapperClass,
  }) => {
    const eventRows = eventTypes.map(e => {
      const onClick = e.active
        ? () => {
            exportCb(e.name);
          }
        : null;
      return (
        <li
          key={e.name}
          className={classnames({ 'export-list_disabled': !e.active })}
        >
          <span onClick={onClick} role="button">
            {e.name}
          </span>
        </li>
      );
    });

    return (
      <CustomDropdown
        wrapperClass={wrapperClass || 'export-list'}
        buttonClass={buttonClass}
        iClass="material-icons icon-get_app"
        iTip={iTip}
        cb={cb}
        iPlace="left"
        menuDisabled={
          !PermissionStore.has('MEDICATION_ADMINISTRATION_VIEW') || menuDisabled
        }
      >
        <ul>{eventRows}</ul>
      </CustomDropdown>
    );
  }
);

ExportMenu.propTypes = {
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    })
  ),
  buttonClass: PropTypes.string,
  iTip: PropTypes.string,
  exportCb: PropTypes.func,
  cb: PropTypes.func,
  menuDisabled: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

ExportMenu.defaultProps = {
  menuDisabled: false,
};

ExportMenu.displayName = 'ExportMenu';

export default ExportMenu;
