/**
 *
 * EntityHeader component
 *
 */
import React from 'react';
import { ADC, Date, Patient, User, Overrides } from './presets';
import { nodeIsType } from '../../utils/ComponentUtils';
import styles from './index.module.scss';

const EntityHeader = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

EntityHeader.propTypes = {
  children: function({ children }) {
    if (!Array.isArray(children)) children = [children].filter(n => n);
    if (!children.length) return new Error('Please specify the header type.');
    if (
      children.filter(
        node =>
          nodeIsType(node, ADC) ||
          nodeIsType(node, Date) ||
          nodeIsType(node, Patient) ||
          nodeIsType(node, User) ||
          nodeIsType(node, Overrides)
      ).length !== children.length
    )
      return new Error(
        'As of now, only ADC, Date, Patient, User and Overrides headers are supported.'
      );
  },
};

EntityHeader.defaultProps = {
  children: [],
};

export default EntityHeader;

// preset exports
export const EntityHeaderADC = props => (
  <EntityHeader>
    <ADC {...props} />
  </EntityHeader>
);

export const EntityHeaderDate = props => (
  <EntityHeader>
    <Date {...props} />
  </EntityHeader>
);

export const EntityHeaderPatient = props => (
  <EntityHeader>
    <Patient {...props} />
  </EntityHeader>
);

export const EntityHeaderUser = props => (
  <EntityHeader>
    <User {...props} />
  </EntityHeader>
);

export const EntityHeaderOverrides = props => (
  <EntityHeader>
    <Overrides {...props} />
  </EntityHeader>
);
