import { useEffect } from 'react';
import SearchStore from '../stores/SearchStore';

/**
 * Responsible for setting store search criteria and type
 *
 * @param query
 * @param match
 */
export function useSearchCriteriaAndTypeFromParam({
  query = {},
  match = { params: {} },
}) {
  useEffect(() => {
    const key = query.criteria;
    const type = match.params?.searchType;
    SearchStore.setCriteria(key);
    if (type) SearchStore.setType(type);
  }, [query, match]);
}

/**
 * Responsible for cleanup store on component unmount
 *
 */
export function useCleanup() {
  useEffect(() => {
    return () => {
      SearchStore.setCriteria(null);
      SearchStore.patients.resetAdvancedFilters();
    };
  }, []);
}
