import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { DateHelpers, LoginStore } from 'common';
import CaseTemplate from '../../case/CaseTemplate';
import { LogValue } from '../../ui';
import { getResolutionDisplay } from '../../utils/resolutionDescriptions';

//
// Private variables.
//
const UNKNOWN = <em>Unknown</em>;

//
// Private functions.
//

/**
 * Function expression to produce an "unknown" DOM node for use when data is
 * missing, or the value of the property if not missing.
 * @param {Object} val - the property being evaluated
 * @return {Object} A React DOM Object
 */
const unknown = val => val || UNKNOWN;

/**
 * Function expression to retrieve the full name of a given person.
 * @param {Object} model - the data Object representing the person
 * @return {Object} A React DOM Object (either the full name or the UNKNOWN
 *   Object)
 */
const personName = model => {
  if (model) {
    return (
      <span>
        {unknown(model.firstName)} {unknown(model.lastName)}
      </span>
    );
  }
  return UNKNOWN;
};

/**
 * Function expression to retrieve the full name of a given person, falling
 * back to the system user if empty.
 * @param {Object} model - the data Object representing the person
 * @return {Object} A React DOM Object (either the full name or the the
 *   system user)
 */
const systemName = model => {
  if (model) {
    return (
      <span>
        {unknown(model.firstName)} {unknown(model.lastName)}
      </span>
    );
  }
  return <span>Protenus System</span>;
};

/**
 * Evaluate whether or not two objects represent the same User.
 * @param {Object} dest - the first Object to compare
 * @param {Object} source - the second Object to compare
 * @return {Boolean} result of comparing the two Objects
 */
const isSameUser = (dest, source) => dest && source && dest.id === source.id;

/**
 * Produce a text string describing who assigned a case to whom. This function
 * evaluates whether or not the user viewing the activity item is one of the
 * users referenced in the activity item (replacing references to that user
 * with the word "you"), if the user referenced in the activity took both
 * actions (self-assignment), or not.
 * @param {Object} model - the activity item
 * @return {Object} A React DOM Object
 */
const assignedToBy = model => {
  const user = LoginStore.user;
  const owner = model.caseOwner;
  const lastModifiedBy = model.lastModifiedBy;
  const isCurrentUser = isSameUser(lastModifiedBy, user);
  const assignerFullName = isSameUser(lastModifiedBy, user)
    ? 'You'
    : systemName(lastModifiedBy);
  const assigneeFullName = personName(owner);

  let text = null;

  if (isCurrentUser && isSameUser(owner, lastModifiedBy)) {
    text = <span>You assigned this to yourself.</span>;
  } else if (isSameUser(owner, lastModifiedBy)) {
    text = <span>{assignerFullName} self-assigned this.</span>;
  } else if (isSameUser(owner, user)) {
    text = <span>{assignerFullName} assigned this to you.</span>;
  } else {
    text = (
      <span>
        {assignerFullName} assigned this to {assigneeFullName}
      </span>
    );
  }

  return text;
};

//
// Private components.
//

/**
 * Component to render the timestamp for the activity item.
 * @param {Object} properties - the Component's properties
 * @return {Object} A React DOM Object
 */
const Timestamp = ({ date, isMultiple }) => {
  // isMultiple bool checks if this is a collection of actions
  // in that case we want to only show the date.

  const timezone = DateHelpers.getCurrentAppUserTimezone();
  const m = moment(date).tz(timezone);
  const midnight = moment()
    .tz(timezone)
    .millisecond(0)
    .second(0)
    .minute(0)
    .hour(0);
  const yesterday = moment(midnight).subtract(1, 'days');

  let text = null;

  if (m.diff(midnight) >= 0) {
    text = isMultiple ? 'Today' : m.fromNow();
  } else if (m.diff(yesterday) >= 0) {
    const prefix = 'Yesterday ';
    text = isMultiple ? prefix : `${prefix}${m.format('h:mm A z')}`;
  } else {
    text = isMultiple ? m.format('MMM Do, YYYY') : `${m.format('lll z')}`;
  }

  return <div className="time-stamp">{text}</div>;
};

Timestamp.propTypes = {
  isMultiple: PropTypes.bool,
  date: PropTypes.string,
};

/**
 * Component to render a comment for the activity item.
 * @param {Object} properties - the Component's properties
 * @return {Object} A React DOM Object
 */
const Comment = ({ model }) => {
  const { action, description } = model;
  if (action === 'COMMENT') {
    return <div className="comment">{description}</div>;
  }
  return <span />;
};

Comment.propTypes = {
  model: PropTypes.shape({
    action: PropTypes.string,
    description: PropTypes.string,
  }),
};

/**
 * Component to render a Summary of the activity item.
 * @param {Object} properties - the Component's properties
 * @return {Object} A React DOM Object
 */
const Summary = ({ model }) => {
  const { action, description } = model;
  const isCurrentUser = isSameUser(model.createdBy, LoginStore.user);
  const commenter = isCurrentUser ? 'You' : systemName(model.createdBy);
  const noteActions = ['ADD_NOTE', 'EDIT_NOTE', 'REMOVE_NOTE'];
  const isNoteAction = noteActions.includes(action);
  let icon = '';
  const value = <LogValue log={model} />;
  let displayDescription = (
    <span>
      <span>
        {commenter} {description}
      </span>
      {isNoteAction && ':'} {isNoteAction ? <>&quot;{value}&quot;</> : value}
    </span>
  );

  if (action === 'COMMENT') {
    displayDescription = <span>{commenter} added a comment</span>;
  } else if (action === 'ASSIGN') {
    displayDescription = assignedToBy(model);
  }

  const icons = {
    ADD_ATTACHMENT: 'attach_file',
    ASSIGN: 'assignment_ind',
    UNASSIGN: 'assignment',
    COMMENT: 'comment',
    CREATE: 'assignment',
    REOPEN: 'assignment_late',
    RESOLVE: 'assignment_turned_in',
    ADD_ANALYTIC_ASSESSMENT: 'fiber_new',
    ADD_INCIDENT: 'local_pharmacy',
    ADD_NOTE: 'edit',
    ADD_NOTE_INCIDENT: 'edit',
    CREATE_ASSESSMENT: 'edit',
    EDIT_ASSESSMENT: 'edit',
    EDIT_CATEGORY: 'edit',
    EDIT_USER_TYPE: 'edit',
    EDIT_NOTE: 'edit',
    EXTERNAL_SYNC: 'arrow_right_alt',
    REMOVE_ATTACHMENT: 'attach_file',
    REMOVE_ASSESSMENT: 'edit',
    REMOVE_NOTE: 'edit',
    REMOVE_NOTE_INCIDENT: 'edit',
    SEND_EMAIL: 'email',
  };

  icon = icons[action];

  return (
    <div data-cy="activity-summary-item">
      <i className={`material-icons icon-${icon || 'brightness_1'}`} />{' '}
      {displayDescription}
    </div>
  );
};

Summary.propTypes = {
  model: PropTypes.shape({
    action: PropTypes.string,
    createdBy: PropTypes.shape({}),
    description: PropTypes.string,
  }),
};

/**
 * Component to render the title of the activity item.
 * @extends React.Component
 */
class ActivityTitle extends React.Component {
  /**
   * Build the title string for the activity item
   * @return {String} The title of the case activity item
   */

  renderResolution(resolution, resolutionDescription) {
    return (
      <span
        className={classnames('label', {
          'label-danger': resolution === 'VIOLATION',
          'label-success': resolution === 'NOT_VIOLATION',
          'label-warning': !resolution,
        })}
      >
        {getResolutionDisplay({ resolution, resolutionDescription })}
      </span>
    );
  }

  get title() {
    const {
      minEventDate,
      maxEventDate,
      number,
      resolution,
      resolutionDescription,
      title,
      category,
    } = this.props.model.complianceCase;
    const stripTZ = category?.product === 'GRC';
    return (
      <span>
        {this.renderResolution(resolution, resolutionDescription)}
        <h5 className="link-bold">{`Case number ${number}`}</h5>
        <p>
          {' '}
          {CaseTemplate.title(title, minEventDate, maxEventDate, stripTZ)}{' '}
        </p>
      </span>
    );
  }

  /**
   * Render the activity title.
   * @return {Object} A React DOM Object
   */
  render() {
    if (this.props.model.complianceCase) {
      return (
        <Link
          to={`/case/${this.props.model.complianceCase.id}`}
          target="_blank"
        >
          {this.title}
        </Link>
      );
    }
    return <span>{UNKNOWN}</span>;
  }
}

ActivityTitle.propTypes = {
  model: PropTypes.shape({
    complianceCase: PropTypes.shape({
      id: PropTypes.string,
      minEventDate: PropTypes.string,
      maxEventDate: PropTypes.string,
      number: PropTypes.number,
      resolution: PropTypes.string,
      resolutionDescription: PropTypes.string,
      title: PropTypes.string,
      category: PropTypes.shape({
        product: PropTypes.string,
      }),
    }),
  }),
};

/**
 * Component to render the activity item.
 * @extends React.Component
 */
const CaseActivityItem = observer(
  class CaseActivityItem extends React.Component {
    static propTypes = {
      data: mobxPropTypes.arrayOrObservableArrayOf(
        PropTypes.shape({
          lastModified: PropTypes.string,
        })
      ),
    };
    /**
     * Render the activity item or collection of activities.
     * @return {Object} A React DOM Object
     */
    render() {
      const { data, ...props } = this.props;
      return (
        <li className="fadeIn" {...props}>
          <div className="case-activity-title">
            <ActivityTitle model={data[0]} />
          </div>
          <div>
            <Timestamp
              date={data[0].lastModified}
              isMultiple={data.length !== 1}
            />
            {data.map((activity, idx) => (
              <Summary key={`${activity.id}-${idx}`} model={activity} />
            ))}
          </div>
          {data.length === 1 ? <Comment model={data[0]} /> : null}
        </li>
      );
    }
  }
);

CaseActivityItem.displayName = 'CaseActivityItem';

export default CaseActivityItem;
