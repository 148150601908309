import React from 'react';
import { MultiRecordViewContext } from '../Context';
import { DocumentTitle } from '../../ui';
import MultiRecordTopNavContainer from '../MultiRecordTopNavContainer';
import { useEphemeralStore } from '../../utils/hooks';
import { MultiRecordMainStore } from '../stores/MultiRecordMainStore';
import MultiRecordMainContainer from '../MultiRecordMainContainer';
import { getPageMode } from '../stores/utils/RouteUtils';
import { MultiRecordPersonHeaderStore } from '../stores/MultiRecordPersonHeaderStore';
import { MultiRecordPersonMetadataStore } from '../stores/MultiRecordPersonMetadataStore';
import MultiRecordViewClient from 'common/src/app/clients/MultiRecordViewClient';
import { PERSON_TYPE_USER, PERSON_TYPE_PATIENT } from '../stores/const';
import CaseStore from '../../case/stores/CaseStore';
import Store from '../../activity/stores/Store';

const MultiRecordRootView = () => {
  const multiRecordMainStore = useEphemeralStore(
    () => new MultiRecordMainStore({ getPageMode })
  );

  const multiRecordPersonHeaderStore = useEphemeralStore(
    () =>
      new MultiRecordPersonHeaderStore({
        multiRecordViewClient: MultiRecordViewClient,
      })
  );

  const multiRecordPatientMetadataStore = useEphemeralStore(
    () =>
      new MultiRecordPersonMetadataStore({
        multiRecordViewClient: MultiRecordViewClient,
        personType: PERSON_TYPE_PATIENT,
      })
  );

  const multiRecordUserMetadataStore = useEphemeralStore(
    () =>
      new MultiRecordPersonMetadataStore({
        multiRecordViewClient: MultiRecordViewClient,
        personType: PERSON_TYPE_USER,
      })
  );

  return (
    <>
      <MultiRecordViewContext.Provider
        value={{
          multiRecordMainStore,
          multiRecordPersonHeaderStore,
          multiRecordPatientMetadataStore,
          multiRecordUserMetadataStore,
          CaseStore,
          Store,
        }}
      >
        <DocumentTitle text="Multi Record Activity View" />
        <MultiRecordTopNavContainer />
        <MultiRecordMainContainer />
      </MultiRecordViewContext.Provider>
    </>
  );
};

export default MultiRecordRootView;
