import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Anchor } from '../index';
import { FullName } from 'common';
import moment from 'moment';
import classnames from 'classnames';
import {
  PatientType,
  DestinationLocationsType,
} from '../../types/incidentsTypes';

const buildName = (user: PatientType) => {
  return <FullName person={user} condensed />;
};

interface LinkOrWrapperProps {
  link: string;
  children: ReactNode;
}

const LinkOrWrapper = ({ link, children }: LinkOrWrapperProps) => {
  if (link)
    return (
      <Anchor tag={'a'} href={link} disabled={false} className={null}>
        {children}
      </Anchor>
    );
  return <>{children}</>;
};

LinkOrWrapper.propTypes = {
  link: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LinkOrWrapper.defaultProps = {
  link: '',
};

interface LabelItemProps {
  label: string;
  sourceOrDestinationLabel?: string;
  content: React.ReactElement | string;
  hideContentOnPrint: boolean;
  secondaryContent?: string | null;
  link: string;
}

export const LabelItem = ({
  label,
  sourceOrDestinationLabel,
  content,
  hideContentOnPrint,
  secondaryContent,
  link,
}: LabelItemProps) => {
  return (
    <div className="user-patient-section">
      <h5 className="user-patient-label">{label}</h5>
      {sourceOrDestinationLabel ? (
        <span className="user-patient-additional-label">
          &nbsp;{sourceOrDestinationLabel}
        </span>
      ) : null}
      <h4 className="user-patient-values">
        <LinkOrWrapper link={link}>
          <span className={classnames({ forScreen: hideContentOnPrint })}>
            {content}{' '}
          </span>
          {secondaryContent ? (
            <span className="user-patient-sub-value">{secondaryContent}</span>
          ) : null}
        </LinkOrWrapper>
      </h4>
    </div>
  );
};

LabelItem.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  hideContentOnPrint: PropTypes.bool,
  secondaryContent: PropTypes.string,
  link: PropTypes.string,
  sourceOrDestinationLabel: PropTypes.string,
};

LabelItem.defaultProps = {
  hideContentOnPrint: false,
  secondaryContent: null,
  link: '',
};

interface UserPatientSectionProps {
  user: any;
  patient: PatientType;
  adc: string;
  userLink: string;
  patientLink: string;
  sourceLocation?: any;
  destinationLocations?: any;
}

const UserPatientSection = ({
  user,
  patient,
  adc,
  userLink,
  patientLink,
  sourceLocation,
  destinationLocations,
}: UserPatientSectionProps) => {
  const { medicalRecordNumbers = [], dateOfBirth } = patient || {};
  return (
    <>
      {sourceLocation !== undefined &&
      destinationLocations?.[0] !== undefined &&
      destinationLocations.length ? (
        <div
          className="user-patient-section-container"
          data-cy="user-patient-container"
        >
          <LabelItem
            label="Source"
            sourceOrDestinationLabel={sourceLocation.label}
            content={sourceLocation.location}
          />
          <i className="material-icons icon-arrow_forward" />
          <LabelItem
            label="Handler"
            sourceOrDestinationLabel="User"
            content={buildName(user)}
            link={userLink}
          />
          <i className="material-icons icon-arrow_forward" />
          <LabelItem
            label={'Destination'}
            sourceOrDestinationLabel={destinationLocations[0].label}
            content={
              // remove 0 index for multiple location values
              destinationLocations.length > 1
                ? destinationLocations
                    .map((dest: DestinationLocationsType) => dest.location)
                    .join(', ')
                : destinationLocations[0].label === 'Patient'
                ? buildName(patient)
                : destinationLocations[0].location !== undefined
                ? destinationLocations[0].location
                : 'Unknown'
            }
            secondaryContent={
              destinationLocations[0].label === 'Patient'
                ? `${(medicalRecordNumbers.length && medicalRecordNumbers[0]) ||
                    'No MRN'} - ${
                    dateOfBirth
                      ? moment.utc(dateOfBirth).format('MM/DD/YYYY')
                      : 'No DoB'
                  }`
                : null
            }
            link={
              destinationLocations[0].label === 'Patient'
                ? patientLink
                : undefined
            }
          />
        </div>
      ) : (
        <div className="user-patient-section-container">
          <LabelItem label="User" content={buildName(user)} link={userLink} />
          <LabelItem
            label="Patient"
            content={patient ? buildName(patient) : 'No Patient'}
            secondaryContent={
              patient
                ? `${(medicalRecordNumbers.length && medicalRecordNumbers[0]) ||
                    'No MRN'} - ${
                    dateOfBirth
                      ? moment.utc(dateOfBirth).format('MM/DD/YYYY')
                      : 'No DoB'
                  }`
                : null
            }
            link={patientLink}
          />
          <LabelItem label="ADC" content={adc || 'None'} />
        </div>
      )}
    </>
  );
};

UserPatientSection.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  patient: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    medicalRecordNumbers: MobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.string
    ),
    dateOfBirth: PropTypes.string,
  }),
  adc: PropTypes.string,
  userLink: PropTypes.string.isRequired,
  patientLink: PropTypes.string.isRequired,
  sourceLocation: PropTypes.shape({
    label: PropTypes.string,
    location: PropTypes.string,
  }),
  destinationLocations: MobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      location: PropTypes.string,
    })
  ),
};

UserPatientSection.defaultProps = {
  user: {},
  patient: {},
  adc: '',
};

UserPatientSection.displayName = 'UserPatientSection';

export default UserPatientSection;
