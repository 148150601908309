import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { nodeIsType } from '../../../utils/ComponentUtils';

/**
 * A wrapper for all sections within an employee or patient box.
 * Each section is assumed to have one H2, optional H3s, and one or more field
 * components. The fields can be actual Field instances or might be similar
 * components that produce equivalent markup.
 *
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const BoxSection = ({ children }) => {
  return (
    <div>
      {React.Children.toArray(children).map(child => {
        if (nodeIsType(child, 'h2') || nodeIsType(child, 'h3')) {
          return child;
        }
        return (
          <div key={Math.random()} className={styles.spacedField}>
            {child}
          </div>
        );
      })}
    </div>
  );
};

BoxSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BoxSection;
