import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import classnames from 'classnames';
import {
  PERSON_TYPE_PATIENT,
  PERSON_TYPE_USER,
  SINGLE_EMPLOYEE_LEFT_COLUMN,
  SINGLE_EMPLOYEE_RIGHT_COLUMN,
  SINGLE_PATIENT_LEFT_COLUMN,
} from '../stores/const';
import MultiRecordPersonHeaderSectionContainer from '../MultiRecordPersonHeaderSectionContainer';
import { MultiRecordUserMetadataContainer } from '../multiRecordPersonMetadata/MultiRecordUserMetadataContainer';
import { MultiRecordPatientMetadataContainer } from '../multiRecordPersonMetadata/MultiRecordPatientMetadataContainer';
import { CaseViewPatientColumnContainer } from '../views/case/CaseViewPatientColumnContainer';
import {
  CaseViewEmployeeColumnContainer,
  CaseViewEmployeeColumnContainerSecond,
} from '../views/case/CaseViewEmployeeColumnContainer';
import {
  CaseViewEmployeeDropdownContainer,
  CaseViewEmployeeDropdownContainerSecond,
} from '../views/case/CaseViewEmployeeDropdownContainer';
import { CaseViewPatientDropdownContainer } from '../views/case/CaseViewPatientDropdownContainer';
import { MultiRecordInformationDropdownStore } from '../stores/MultiRecordInformationDropdownStore';
import { MultiRecordEntityColumnStore } from '../stores/MultiRecordEntityColumnStore';
import MultiRecordViewClient from 'common/src/app/clients/MultiRecordViewClient';
import { CaseViewContext } from '../Context';
import { useEphemeralStore } from '../../utils/hooks';
import { CaseVisibleSystemQuickFilterContainer } from '../../case/subViews/CaseVisibleSystemQuickFilterContainer';
import { VisibleSystemStore } from 'common';
import { DETAILS_NONE } from '../../case/print/stores/CaseCustomPrintStore/consts';
import PrintTitle from '../../ui/PrintTitle';

export const MultiRecordUserPatientDetails = ({
  isDiversion,
  patientId,
  selectedDetailsOption,
  isUserDetailsView,
}) => {
  const employeeDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: SINGLE_EMPLOYEE_LEFT_COLUMN,
      })
  );
  const employeeDropdownStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: SINGLE_EMPLOYEE_RIGHT_COLUMN,
      })
  );
  const patientDropdownStore = useEphemeralStore(
    () =>
      new MultiRecordInformationDropdownStore({
        sessionStorageKey: SINGLE_PATIENT_LEFT_COLUMN,
      })
  );
  const employeeColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
        personType: PERSON_TYPE_USER,
      })
  );
  const employeeColumnStoreSecond = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
        personType: PERSON_TYPE_USER,
      })
  );
  const patientColumnStore = useEphemeralStore(
    () =>
      new MultiRecordEntityColumnStore({
        multiRecordViewClient: MultiRecordViewClient,
        visibleSystemStore: VisibleSystemStore,
        personType: PERSON_TYPE_PATIENT,
      })
  );

  let titleText;
  if (isDiversion) {
    titleText = 'User Details';
  } else {
    titleText = 'User & Patient Details';
  }

  return (
    <CaseViewContext.Provider
      value={{
        employeeDropdownStore,
        employeeDropdownStoreSecond,
        patientDropdownStore,
        employeeColumnStore,
        employeeColumnStoreSecond,
        patientColumnStore,
      }}
    >
      <div className="forScreen">
        {isUserDetailsView && <CaseVisibleSystemQuickFilterContainer />}
      </div>
      <div className={classnames('forScreen', styles.dataWarningBanner)}>
        <p className={styles.dataWarningText}>
          User & Patient information is based on data available at the time of
          case creation and may not match current data shown elsewhere.
        </p>
      </div>
      {selectedDetailsOption !== DETAILS_NONE && (
        <PrintTitle>{titleText}</PrintTitle>
      )}
      <div
        className={classnames({
          [styles.detailsNone]: selectedDetailsOption === DETAILS_NONE,
        })}
      >
        {isDiversion || !patientId ? (
          <div className={styles.doubleLayout}>
            <div className={styles.employeeBox}>
              <MultiRecordPersonHeaderSectionContainer
                personType={PERSON_TYPE_USER}
                forPage={'case'}
              />
              <MultiRecordUserMetadataContainer />
              <div className={styles.doubleColumn}>
                <div className={styles.column}>
                  <CaseViewEmployeeDropdownContainer />
                  <CaseViewEmployeeColumnContainer />
                </div>
                <div className={styles.column}>
                  <CaseViewEmployeeDropdownContainerSecond />
                  <CaseViewEmployeeColumnContainerSecond />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.doubleLayout}>
            <div className={styles.employeeBox}>
              <MultiRecordPersonHeaderSectionContainer
                personType={PERSON_TYPE_USER}
                forPage={'case'}
              />
              <MultiRecordUserMetadataContainer personType={PERSON_TYPE_USER} />
              <div className={styles.doubleColumn}>
                <div className={styles.column}>
                  <CaseViewEmployeeDropdownContainer />
                  <CaseViewEmployeeColumnContainer />
                </div>
              </div>
            </div>
            {patientId ? (
              <div className={styles.patientBox}>
                <MultiRecordPersonHeaderSectionContainer
                  personType={PERSON_TYPE_PATIENT}
                  forPage={'case'}
                />
                <MultiRecordPatientMetadataContainer
                  personType={PERSON_TYPE_PATIENT}
                />
                <div className={styles.doubleColumn}>
                  <div className={styles.column}>
                    <CaseViewPatientDropdownContainer />
                    <CaseViewPatientColumnContainer />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.employeeBox}>
                <MultiRecordPersonHeaderSectionContainer
                  personType={PERSON_TYPE_USER}
                  forPage={'case'}
                />
                <MultiRecordUserMetadataContainer
                  personType={PERSON_TYPE_USER}
                />
                <div className={styles.doubleColumn}>
                  <div className={styles.column}>
                    <CaseViewEmployeeDropdownContainerSecond />
                    <CaseViewEmployeeColumnContainerSecond />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </CaseViewContext.Provider>
  );
};

MultiRecordUserPatientDetails.propTypes = {
  isDiversion: PropTypes.bool,
  patientId: PropTypes.string,
  selectedDetailsOption: PropTypes.string,
  isUserDetailsView: PropTypes.bool,
};
