import React from 'react';
import Circle from '../Circle';
import Header from '../Header';
import { icons } from '../icons';
import { colorDefaults, presetDefaults, presetPropTypes } from './propTypes';
import styles from '../index.module.scss';

const Patient = ({ topText, bottomText, extraText, color }) => (
  <div className={styles.wrapper}>
    <Circle color={color}>
      <i className={icons.patient} />
    </Circle>
    <Header topText={topText} bottomText={bottomText} extraText={extraText} />
  </div>
);

Patient.propTypes = presetPropTypes;

Patient.defaultProps = {
  ...presetDefaults,
  color: colorDefaults.secondary,
};

export default Patient;
