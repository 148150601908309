import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Field, SideList, ListItem, ShowHideMore } from '../../ui';
import ValueOrUnknown from '../../people/PersonBox/ValueOrUnknown';

const Accounts = ({ accounts = [] }) => {
  return (
    <ShowHideMore numberToShow={1} label="Account">
      {accounts.map(a => (
        <Field key={`Account-${a.source}`} label="Account" width="auto">
          <ValueOrUnknown>{a.source}</ValueOrUnknown>
          <SideList>
            <ListItem label="Username" value={a.username} />
            <ListItem label="Security Role" value={a.securityRole} />
          </SideList>
        </Field>
      ))}
    </ShowHideMore>
  );
};

Accounts.propTypes = {
  accounts: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      securityRole: PropTypes.string,
      username: PropTypes.string,
    })
  ),
};

export default Accounts;
