import React from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';
import ExclusionMultiselect from '../../../ui/Filters/ExclusionMultiselect';
import FilterSectionHeader from '../../../ui/FilterSectionHeader';

const ListItem = ({ item }) => <span>{item && item.label}</span>;

ListItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
  }),
};

const StatementsViewAside = ({
  // eslint-disable-next-line no-unused-vars
  routeToStatement,
  routeToReportSummary,
  currentStatementId,
  periods,
  groups,
  query,
}) => {
  const currId = currentStatementId || periods[0].id;
  const { label } = periods.find(d => d.id === currId);

  return (
    <aside>
      <>
        <h1>Summary</h1>
        <FilterSectionHeader icon="icon-speed" title="Report Settings" />
        <div className="statements_view__timeframe">
          <DropdownList
            onChange={({ id }) => routeToReportSummary(id)}
            valueField="label"
            textField="label"
            valueComponent={({ item }) => (
              <span>
                <strong>Time Range</strong> {item?.label}
              </span>
            )}
            value={label}
            data={periods}
            itemComponent={ListItem}
            groupBy={statement => statement.timeFrame}
            data-cy="time-range-dropdown"
          />
          <div
            className="statements_view__multiselect_container"
            data-cy="group-multi-select"
          >
            <ExclusionMultiselect
              label="Group"
              options={groups}
              value={groups?.filter(({ id }) =>
                (query.group || []).includes(id)
              )}
              hideExclusion
              hideExclusionSpacing
              onChange={value => routeToReportSummary(currId, value)}
              textField="name"
              valueField="id"
            />
          </div>
        </div>
      </>
    </aside>
  );
};

StatementsViewAside.propTypes = {
  groups: PropTypes.array,
  routeToStatement: PropTypes.func,
  routeToReportSummary: PropTypes.func,
  periods: PropTypes.array,
  currentStatementId: PropTypes.string,
  query: PropTypes.object,
};

export default StatementsViewAside;
