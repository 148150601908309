import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../ui/Tooltip';
import styles from './index.module.scss';
import pluralize from '../../utils/pluralize';

const DataFeedIcon = ({ errorCount }) => {
  return (
    <div className={styles.icon}>
      <Tooltip
        content="File Delivery Dashboard"
        placement="bottom"
        offset={[0, 0]}
      >
        <i className="material-icons icon-power" />
      </Tooltip>
      {errorCount > 0 && (
        <Tooltip
          content={`${errorCount} delivery ${pluralize(
            errorCount,
            'error'
          )} over the last 30 days`}
          placement="bottom"
        >
          <span className={styles.error} data-cy="fdd-error-icon" />
        </Tooltip>
      )}
    </div>
  );
};

DataFeedIcon.propTypes = {
  errorCount: PropTypes.number,
};

DataFeedIcon.defaultProps = {
  errorCount: 0,
};

export default DataFeedIcon;
