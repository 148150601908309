import React from 'react';
import { PropTypes } from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import ToggleCell from '../../../../ui/ToggleCell';
import Tooltip from '../../../../ui/Tooltip';
import styles from '../../BulkVipList/index.module.scss';

function statusFor(patientId, completedRequests) {
  let node;
  const patient = completedRequests[patientId];
  if (patient) {
    const req = patient;
    const { status } = req;
    const isSuccess = status >= 200 && status < 300;

    if (isSuccess) {
      node = (
        <span>
          <i className="material-icons icon-done text-success" />
        </span>
      );
    } else {
      let errorText = 'There was an error with your request.';

      if (req.responseJSON && req.responseJSON.message) {
        errorText = `There was an error with your request: ${req.responseJSON.message}`;
      }

      node = (
        <Tooltip
          content={req.statusText === 'error' ? errorText : req.statusText}
          placement={'right'}
        >
          <span>
            <i className="material-icons icon-error text-danger" />
          </span>
        </Tooltip>
      );
    }
  }

  return node;
}

const Status = ({
  completedRequests,
  toggleActions,
  hasSubmit,
  rowErrorMessage,
  matchedPatient,
}) => {
  // the user is currently registering or has finished but is in a half success state, show status cell
  if (hasSubmit) {
    if (!matchedPatient && rowErrorMessage) return null;
    const { id } = matchedPatient;
    return <Cell>{statusFor(id, completedRequests)}</Cell>;
  }

  // the user has a matched row, show the checkbox
  if (matchedPatient && !rowErrorMessage)
    return <ToggleCell option={matchedPatient} store={toggleActions} />;

  // the user has a row with no match, show error icon
  if (rowErrorMessage) {
    return (
      <Cell>
        <Tooltip content={rowErrorMessage} placement="right">
          <i className={`material-icons icon-error ${styles.iconError}`} />
        </Tooltip>
      </Cell>
    );
  }
  return null;
};

Status.propTypes = {
  completedRequests: PropTypes.shape({
    /*
    This object is a fake Map object to get around a bug in our current version of mobX.
    The keys of the object are patient IDs and so will always be changing.
    The values are objects containing the xhr data
    */
  }).isRequired,
  toggleActions: PropTypes.shape({
    toggleSelect: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
  }).isRequired,
  hasSubmit: PropTypes.bool.isRequired,
  rowErrorMessage: PropTypes.string,
  matchedPatient: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Status.defaultProps = {
  matchedPatient: {},
  rowErrorMessage: null,
};

export default Status;
