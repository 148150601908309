import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import Address, { getLabel } from '../Address';
import ShowHideMore from '../../ui/ShowHideMore';
import Field from '../../ui/Field';
import ScreenOnly from '../../ui/ScreenOnly';

export const NoAddress = () => (
  <Field label={'Address'} width="auto">
    <p>None</p>
  </Field>
);

export const AddressGroup = ({ group, hideOnPrint, type }) => {
  return (
    <ShowHideMore
      numberToShow={1}
      label={getLabel(type)}
      pluralLabel={getLabel(type) + 'es'}
    >
      {group.map((address, i) => (
        <ScreenOnly hideOnPrint={hideOnPrint} key={i}>
          <Address address={address} usage={'forPersonDetail'} />
        </ScreenOnly>
      ))}
    </ShowHideMore>
  );
};

const Addresses = ({ addresses = [], hideOnPrint }) => {
  if (!addresses.length) return <NoAddress />;

  const addressGroups = [];
  const reverseAndGroupByType = addresses
    .slice()
    .map(a => {
      if (!a.type) {
        a.type = '';
      }
      return a;
    })
    .reverse()
    .groupBy('type');

  Object.keys(reverseAndGroupByType).forEach((type, i) =>
    addressGroups.push(
      <AddressGroup
        group={reverseAndGroupByType[type]}
        hideOnPrint={type !== 'work' && hideOnPrint}
        type={type}
        key={i + type}
      />
    )
  );

  return addressGroups;
};

Addresses.propTypes = {
  addresses: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  hideOnPrint: PropTypes.bool,
};

AddressGroup.propTypes = {
  group: PropTypes.arrayOf(PropTypes.shape({})),
  hideOnPrint: PropTypes.bool,
  type: PropTypes.string,
};

export default Addresses;
