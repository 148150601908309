import React from 'react';
import PropTypes from 'prop-types';
import IncidentFilterBarContainer from '../IncidentFilterBarContainer';
import IncidentListingContainer from '../IncidentListingContainer';
import styles from './index.module.scss';
import { ApplyBodyClassName, DocumentTitle } from '../../ui';
import ActionNav from '../../navbar/ActionNav';
import IncidentAdvancedFiltersContainer from '../IncidentAdvancedFiltersContainer';

const IncidentView = ({
  totalElements,
  // eslint-disable-next-line no-unused-vars
  downloadCSV,
  downloadCSVIncidentSummaryAndDetails,
  downloadCSVIncidentSummary,
  toggleOpen,
}) => {
  return (
    <main className={styles.main}>
      <DocumentTitle text="Incidents" />
      <ApplyBodyClassName className="incidents_view" />
      <IncidentAdvancedFiltersContainer />
      <div className={styles.incidentWrapper}>
        <IncidentFilterBarContainer />
        <ActionNav
          secondary={false}
          count={totalElements}
          title="Incident"
          exportMenuWrapperClass={styles.exportMenuWrapper}
          eventTypes={[
            { name: 'Incident Summary', active: true },
            {
              name: 'Incident Summary & Event Details',
              active: true,
            },
          ]}
          downloadCSVWithOptions={
            totalElements > 0
              ? eventType => {
                  if (eventType === 'Incident Summary')
                    downloadCSVIncidentSummary();
                  if (eventType === 'Incident Summary & Event Details')
                    downloadCSVIncidentSummaryAndDetails();
                }
              : null
          }
          advancedFiltersOnClick={toggleOpen}
        />
        <IncidentListingContainer />
      </div>
    </main>
  );
};

IncidentView.propTypes = {
  totalElements: PropTypes.number,
  downloadCSV: PropTypes.func,
  downloadCSVWithOptions: PropTypes.func,
  downloadCSVIncidentSummary: PropTypes.func,
  downloadCSVIncidentSummaryAndDetails: PropTypes.func,
  toggleOpen: PropTypes.func.isRequired,
};

IncidentView.defaultProps = {
  totalElements: null,
  downloadCSV: () => {},
  downloadCSVWithOptions: () => {},
  downloadCSVIncidentSummary: () => {},
  downloadCSVIncidentSummaryAndDetails: () => {},
};

IncidentView.displayName = 'IncidentView';

export default IncidentView;
