import { autorun, computed, decorate } from 'mobx';
import GeneralSettingsStore from '../GeneralSettingsStore';

/*
  The two values that we do ' x === 'true ' the values are stored as strings in the DB,
  they will be either 'true' or 'false'. These lines are to make sure we are getting a
  true value check and coercing it to a boolean.
*/

class TemporaryPermissionsSettingsStore extends GeneralSettingsStore {
  category = 'services.readWrite';
  key = 'general.temporaryPermissions';

  constructor() {
    super();
    autorun(() => {
      this.refresh();
    }, true);
  }

  // Computed
  get properties() {
    const { properties = {} } = this.response || {};
    return properties;
  }

  // Computed
  get creationPrompt() {
    const { creationPrompt } = this.properties;
    return creationPrompt === 'true';
  }

  // Computed
  get duration() {
    const { duration } = this.properties;
    return duration || 30;
  }

  // Computed
  get enabled() {
    const { enabled } = this.properties;
    return enabled === 'true';
  }
}

decorate(TemporaryPermissionsSettingsStore, {
  creationPrompt: computed,
  duration: computed,
  enabled: computed,
  properties: computed,
});

export default new TemporaryPermissionsSettingsStore();
