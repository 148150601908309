// if diversion @return {Array} an Array containing arrays of headline strings
// e.g) [['headline1', 'headline2'], ['headline1'], ['headline1']]
// if privacy @return {Array} an Array containing headline strings
// e.g) ['headline1', 'headline2']
export function getAssessmentsHeadlines({
  assessments = [],
  incidents = [],
  type = '',
}) {
  const reasonsArr = assessments.map(a => {
    if (a.reasons !== undefined && a.reasons.length > 0) {
      return a.reasons;
    } else if (a.clusterReasons !== undefined && a.clusterReasons.length > 0) {
      return a.clusterReasons[0].reasons; // we always use institutional, anesthesia, or pharmacy peer group at index 0
    }
    return [];
  });
  let headlines = [];
  if (type === 'diversion') {
    headlines = incidents.map((incidents, i) => {
      // attempt to find any reasons that match medication incidents
      const incidentReasons = new Set(
        incidents.map(inc => inc.type).filter(Boolean)
      );
      return reasonsArr[i]?.filter(r => incidentReasons.has(r.identifier));
    });
  } else if (type === 'privacy') {
    // fallback logic
    headlines =
      reasonsArr[0] && reasonsArr.map(reasons => reasons[0]?.description);
  }
  return headlines;
}
