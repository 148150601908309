import { withRouterAndQuery } from '../../utils/RouteHelper';
import { ListContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import AdvancedFilters from '../AdvancedFilters';

export function mapProps({ vipsStore, query, history }) {
  return {
    query,
    filters: vipsStore.advancedFormFields,
    advancedFiltersVisible: !!vipsStore.showFilters,
    onToggleAdvancedFilters: () => {
      history.push(vipsStore.showFiltersLink());
    },
    createdByFilterOptions: vipsStore.createdByFilterOptions,
    lastModifiedByFilterOptions: vipsStore.lastModifiedByFilterOptions,
    onChangeDropdown: vipsStore.onChangeDropdown,
    onToggleExclusion: vipsStore.onToggleExclusion,
    onDOBChange: vipsStore.onDOBChange,
    onCalendarChange: vipsStore.onCalendarChange,
    advancedFiltersPath: vipsStore.advancedFiltersPath,
    historyPush: history.push,
    statusFilterOptions: vipsStore.statusFilterOptions,
    typesFilterOptions: vipsStore.typesFilterOptions,
  };
}

const AdvancedFiltersContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: ListContext,
    mapProps,
    PresentationalComponent: AdvancedFilters,
  })
);

AdvancedFiltersContainer.displayName = 'AdvancedFiltersContainer';

export default AdvancedFiltersContainer;
