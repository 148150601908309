import { SingletonStore, DataFeedClient, HalUtils, LoginStore } from 'common';
import { computed, decorate, reaction } from 'mobx';

export const DEFAULTS = {
  createdBefore: 'P0D',
  createdAfter: 'P-30D',
};

/**
 * Houses file evaluation data error reporting
 *
 */
class EvaluationIconStore extends SingletonStore {
  constructor({ loginStore }) {
    super();

    this.loginStore = loginStore;

    this.refresh();

    reaction(
      () => [loginStore.loggedIn],
      () => this.refresh()
    );
  }

  get errorCount() {
    const result = HalUtils.getData(this.result);
    return result.map(item => item.errors).reduce((a, b) => a + b, 0);
  }

  fetch() {
    return DataFeedClient.statusRules(DEFAULTS);
  }
}

decorate(EvaluationIconStore, {
  errorCount: computed,
});

const store = new EvaluationIconStore({
  loginStore: LoginStore,
});
export { store as EvaluationIconStore };
export default store;
