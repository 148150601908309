import { action, computed, decorate, observable } from 'mobx';
import { DOUBLE_COLUMN_MODE } from '../const';

class MultiRecordMainStore {
  constructor({ getPageMode }) {
    this.getPageMode = getPageMode;
  }

  currentPath;

  setCurrentPath = path => (this.currentPath = path);

  get pageMode() {
    return this.getPageMode(this.currentPath);
  }

  isDoubleColumnMode = pageMode => {
    if (DOUBLE_COLUMN_MODE.includes(pageMode)) return true;
    return false;
  };
}

decorate(MultiRecordMainStore, {
  currentPath: observable,
  pageMode: computed,
  setCurrentPath: action,
});

export { MultiRecordMainStore };
