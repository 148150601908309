import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { observer } from 'mobx-react';
import { decorate, observable } from 'mobx';

/**
 * This is the topmost component for building a chart in the application. It
 * creates the wrapper SVG element and sets up the basic layout of the chart in
 * the context where it's being rendered.
 *
 * The canvas will get re-rendered whenever this component receives new properties.
 */
class ChartCanvas extends React.Component {
  // Observable
  svg = null;

  // Ref
  canvas = React.createRef();

  static propTypes = {
    margin: PropTypes.object,
    children: PropTypes.node,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.number,
    className: PropTypes.string,
  };

  static defaultProps = {
    margin: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };

  componentDidMount() {
    this.updateCanvas();
    this.svg = this.canvas.current.parentNode;
  }

  updateCanvas() {
    d3.select(this.canvas.current).select('svg');
  }

  renderSVG() {
    const { margin, children, width, height, className } = this.props;

    if (!this.svg || width <= 0 || height <= 0) return null;

    const viewbox = `0 0 ${width} ${height + margin.top + margin.bottom}`;

    if (className && className.includes('non-viewbox')) {
      return (
        <svg width={width} height={height + margin.top + margin.bottom}>
          {children}
        </svg>
      );
    } else {
      return <svg viewBox={width && viewbox}>{children}</svg>;
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { children, margin, height, width, ...props } = this.props;

    return (
      <div ref={this.canvas} {...props}>
        {this.renderSVG()}
      </div>
    );
  }
}

decorate(ChartCanvas, {
  svg: observable,
});

export default observer(ChartCanvas);
