export const COMPARE_PATIENT_EMPLOYEE = 'patient_employee';
export const COMPARE_EMPLOYEE_PATIENT = 'employee_patient';
export const SINGLE_EMPLOYEE = 'single_employee';
export const SINGLE_PATIENT = 'single_patient';
export const DOUBLE_EMPLOYEE_PATIENT = 'double_employee_patient';
export const DOUBLE_PATIENT_EMPLOYEE = 'double_patient_employee';
export const EMR_EMPLOYEE_ONLY_ACCESSES = 'employee_accesses';
export const EMR_EMPLOYEE_INCIDENTS = 'employee_incidents';

// double column mode where there's two metadata columns
// this happens in compare view as well as single entity view
export const DOUBLE_COLUMN_MODE = [
  COMPARE_EMPLOYEE_PATIENT,
  COMPARE_PATIENT_EMPLOYEE,
  SINGLE_EMPLOYEE,
  SINGLE_PATIENT,
];

// dropdown
export const DROPDOWN_PERSONAL_OPT_LABEL = 'Personal Information';
export const DROPDOWN_EMPLOYEE_OPT_LABEL = 'Employee Information';
export const DROPDOWN_PATIENT_OPT_LABEL = 'Patient Information';

// person type
export const PERSON_TYPE_USER = 'USER';
export const PERSON_TYPE_PATIENT = 'PATIENT';

// information column view mode
export const PERSONAL_INFORMATION = 'personal';
export const EMPLOYEE_INFORMATION = 'user';
export const PATIENT_INFORMATION = 'patient';

// localStorage keys for MRV dropwdowns, Single View
export const SINGLE_EMPLOYEE_LEFT_COLUMN = 'singleemployeeleftcolumn';
export const SINGLE_EMPLOYEE_RIGHT_COLUMN = 'singleemployeerightcolumn';
export const SINGLE_PATIENT_LEFT_COLUMN = 'singlepatientleftcolumn';
export const SINGLE_PATIENT_RIGHT_COLUMN = 'singlepatientrightcolumn';

// localStorage keys for MRV dropwdowns, Double View
export const DOUBLE_EMPLOYEE_COLUMN = 'doubleemployeeleftcolumn';
export const DOUBLE_PATIENT_COLUMN = 'doublepatientleftcolumn';

// localStorage keys for MRV dropwdowns, Compare View
export const COMPARE_EMPLOYEE_LEFT_COLUMN = 'compareemployeeleftcolumn';
export const COMPARE_EMPLOYEE_RIGHT_COLUMN = 'compareemployeerightcolumn';
export const COMPARE_PATIENT_LEFT_COLUMN = 'comparepatientleftcolumn';
export const COMPARE_PATIENT_RIGHT_COLUMN = 'comparepatientrightcolumn';

// information sections
export const PERSONAL_INFORMATION_SECTION = 'personalInformationSections';
export const EMPLOYEE_INFORMATION_SECTION = 'employeeInformationSections';
export const PATIENT_INFORMATION_SECTION = 'patientInformationSections';

export const MOCK_CONTACT_GROUPS = [
  {
    id: 1,
    fieldName: 'Subordinate',
    fields: [
      {
        value: 'Kelly Hauck',
        type: 'OBJECT',
        sources: [
          {
            source: 'OnBase',
            instance: 'Zoilaberg',
          },
        ],
        tieredValues: [
          {
            value: '(203) 410-3298 x048',
            type: 'STRING',
            tier: 1,
            name: 'Cell Phone',
          },
          {
            date: '1947-07-09T00:00:00.000+0000',
            type: 'DATE',
            tier: 1,
            name: 'DOB',
          },
          {
            date: '2021-10-17T00:00:00.000+0000',
            type: 'DATE',
            tier: 1,
            name: 'DOD',
          },
          {
            value: 'Female',
            type: 'STRING',
            tier: 1,
            name: 'Sex',
          },
          {
            type: 'ADDRESS',
            addressDetails: {
              address1: '47629 Abshire Passage',
              city: 'Dickinsonfurt',
              state: 'New Mexico',
              zipCode: '62033-9169',
            },
            tier: 1,
            name: 'Address',
          },
          {
            type: 'ADDRESS',
            addressDetails: {
              address1: '24469 Kihn Trace',
              city: 'Rosaleemouth',
              state: 'Ohio',
              zipCode: '10018',
            },
            tier: 1,
            name: 'Address',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    fieldName: 'Manager',
    fields: [
      {
        value: 'Kristine Crona',
        type: 'OBJECT',
        sources: [
          {
            source: 'Allscripts',
          },
        ],
        tieredValues: [
          {
            value: '397-546-2885',
            type: 'STRING',
            tier: 1,
            name: 'Work Phone',
          },
          {
            value: '674-910-5206 x2530',
            type: 'STRING',
            tier: 1,
            name: 'Cell Phone',
          },
          {
            date: '1942-01-24T00:00:00.000+0000',
            type: 'DATE',
            tier: 1,
            name: 'DOB',
          },
          {
            value: 'Female',
            type: 'STRING',
            tier: 1,
            name: 'Sex',
          },
          {
            type: 'ADDRESS',
            addressDetails: {
              address1: '4893 Gibson Camp',
              address2: 'Apt. 961',
              city: 'North Garnett',
              state: 'Maine',
              zipCode: '54404-3026',
            },
            tier: 1,
            name: 'Address',
          },
        ],
      },
    ],
  },
];

export const MOCK_ROLE_GROUPS = [
  {
    id: 1,
    fieldName: 'Role',
    fields: [
      {
        value: 'Medical Doctor',
        type: 'OBJECT',
        sources: [
          {
            source: 'PeopleSoft',
          },
        ],
        tieredValues: [
          {
            date: '2021-06-16T18:06:50.237+0000',
            type: 'DATE',
            tier: 1,
            name: 'Start Date',
          },
          {
            date: '2021-07-23T20:58:33.700+0000',
            type: 'DATE',
            tier: 1,
            name: 'End Date',
          },
          {
            value: 'PabloCarter311@erin.com',
            type: 'EMAIL',
            tier: 1,
            name: 'Email',
          },
          {
            value: 'Critical Care',
            type: 'STRING',
            tier: 1,
            name: 'Dept.',
          },
          {
            value: 'University',
            type: 'STRING',
            tier: 1,
            name: 'Org',
          },
          {
            value: '606-885-1137 x51562',
            type: 'STRING',
            tier: 1,
            name: 'Phone',
          },
          {
            type: 'ADDRESS',
            addressDetails: {
              address1: '842 Torrance Street',
              city: 'Port Ricostad',
              state: 'Missouri',
              zipCode: '14343',
            },
            tier: 1,
            name: 'Address',
          },
          {
            value: 'School of Medicine',
            type: 'STRING',
            tier: 1,
            name: 'Division',
          },
          {
            value: 'Kristine Crona',
            type: 'OBJECT',
            tieredValues: [
              {
                value: '397-545-2885',
                type: 'STRING',
                tier: 2,
                name: 'Work Phone',
              },
              {
                value: '674-910-5206 x2530',
                type: 'STRING',
                tier: 2,
                name: 'Cell Phone',
              },
              {
                date: '1942-01-24T00:00:00.000+0000',
                type: 'DATE',
                tier: 2,
                name: 'DOB',
              },
              {
                value: 'Female',
                type: 'STRING',
                tier: 2,
                name: 'Sex',
              },
              {
                type: 'ADDRESS',
                addressDetails: {
                  address1: '4893 Gibson Camp',
                  address2: 'Apt. 961',
                  city: 'North Garnett',
                  state: 'Maine',
                  zipCode: '54404-3026',
                },
                tier: 2,
                name: 'Address',
              },
            ],
            tier: 1,
            name: 'Manager',
          },
        ],
      },
    ],
  },
  {
    fieldName: 'Role',
    id: 2,
    fields: [
      {
        value: 'Chief',
        type: 'OBJECT',
        sources: [
          {
            source: 'ADP',
          },
        ],
        tieredValues: [
          {
            value: 'Psychology',
            type: 'STRING',
            tier: 1,
            name: 'Dept.',
          },
          {
            value: 'Hospital',
            type: 'STRING',
            tier: 1,
            name: 'Org',
          },
          {
            value: '401-592-9820',
            type: 'STRING',
            tier: 1,
            name: 'Phone',
          },
          {
            type: 'ADDRESS',
            addressDetails: {
              address1: '5506 Collins Gardens',
              city: 'Breitenbergport',
              state: 'Alabama',
              zipCode: '58674-4287',
            },
            tier: 1,
            name: 'Address',
          },
          {
            value: 'School of Medicine',
            type: 'STRING',
            tier: 1,
            name: 'Division',
          },
          {
            value: 'Kelly Hauck',
            type: 'OBJECT',
            tieredValues: [
              {
                value: '(203) 410-3298 x048',
                type: 'STRING',
                tier: 2,
                name: 'Cell Phone',
              },
              {
                date: '1947-07-09T00:00:00.000+0000',
                type: 'DATE',
                tier: 2,
                name: 'DOB',
              },
              {
                date: '2021-10-17T00:00:00.000+0000',
                type: 'DATE',
                tier: 2,
                name: 'DOD',
              },
              {
                value: 'Female',
                type: 'STRING',
                tier: 2,
                name: 'Sex',
              },
              {
                type: 'ADDRESS',
                addressDetails: {
                  address1: '47629 Abshire Passage',
                  city: 'Dickinsonfurt',
                  state: 'New Mexico',
                  zipCode: '62033-9169',
                },
                tier: 2,
                name: 'Address',
              },
              {
                type: 'ADDRESS',
                addressDetails: {
                  address1: '24469 Kihn Trace',
                  city: 'Rosaleemouth',
                  state: 'Ohio',
                  zipCode: '10018',
                },
                tier: 2,
                name: 'Address',
              },
            ],
            tier: 1,
            name: 'Subordinate',
          },
        ],
      },
    ],
  },
];

export const MOCK_ENCOUNTERS_GROUP = [
  {
    id: 1,
    fieldName: 'Encounter',
    fields: [
      {
        value: 'Email',
        type: 'OBJECT',
        sources: [
          {
            source: 'Cerner',
            instance: 'West Alejandrahaven',
          },
        ],
        tieredValues: [
          {
            value: '12/25/21 3:42 AM - 12/28/21 10:25 PM',
            type: 'STRING',
            tier: 1,
            name: 'Date/Time',
          },
          {
            value: 'Marcos A. Pagac',
            type: 'MULTI_PERSON_LINK',
            links: [
              {
                personId:
                  'D3E151981E35420965ABEC738D17AE585A0161FA219AA1BBAA6BF4101F3ACC4A',
                personName: 'Marcos A Pagac',
                personType: 'USER',
              },
              {
                personId:
                  '70E936443AB89810061F494D098F03F435080C2F3C1C027227B3F30CE9C67A49',
                personName: 'Olga S Gorczany',
                personType: 'USER',
              },
              {
                personId:
                  'FEC86145A1D2EE4BEF8428035896CBAF3044F40DCB2033E040D86ABC07D8ECF8',
                personName: 'George H Braun',
                personType: 'USER',
              },
            ],
            tier: 1,
            name: 'Prim. Care',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    fieldName: 'Encounter',
    fields: [
      {
        type: 'OBJECT',
        sources: [
          {
            source: 'Cerner',
          },
        ],
        tieredValues: [
          {
            value: 'Beckerview Immuno/Allergy',
            type: 'STRING',
            tier: 1,
            name: 'Dept.',
          },
          {
            value: '12/26/21 11:52 AM - 12/29/21 1:11 AM',
            type: 'STRING',
            tier: 1,
            name: 'Date/Time',
          },
          {
            value: "Kenny H. D'Amore",
            type: 'MULTI_PERSON_LINK',
            links: [
              {
                personId:
                  '922A769CB5023ACB8A25B00F362D919FFEF5D558C9717133B83BF9AF5D9E5FC8',
                personName: "Kenny H D'Amore",
                personType: 'USER',
              },
              {
                personId:
                  '782B44E1582BF7193CD14AC1590468893120FE1EB64A8429FBF99C29E0CFCF58',
                personName: 'Jackie G Reynolds',
                personType: 'USER',
              },
              {
                personId:
                  '9D2C1A99E1333F65220E6BC29B9442CF47BBA28913B514763DCA37130C31F796',
                personName: 'Lucia V Flatley',
                personType: 'USER',
              },
            ],
            tier: 1,
            name: 'Prim. Care',
          },
          {
            value: '23838053819081',
            type: 'STRING',
            tier: 1,
            name: 'CSN',
          },
        ],
      },
    ],
  },
];
