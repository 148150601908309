import React, { useEffect } from 'react';
import { useStateMatchProp } from '../../../utils/hooks';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from '../../../ui/Input';
import styles from './index.module.scss';

const MIN_LIMIT = 1;
const MAX_LIMIT = 1000;

const UserAccountLimit = ({ update, onDirtyChange, settingsLimit }) => {
  // Value of editable limit, matches settings
  const [editableLimit, setEditableLimit] = useStateMatchProp(settingsLimit);

  // Check/update dirty status of general settings when the settings or edit value changes
  useEffect(() => {
    onDirtyChange(editableLimit !== settingsLimit);
  }, [settingsLimit, editableLimit, onDirtyChange]);

  return (
    <div id="settings__wrapper-User_Account_Limit" className="wrapper">
      <div className="card_header">
        <h1>Customer User Accounts</h1>
        <span
          className={classnames('prot-a', {
            muted: editableLimit === settingsLimit,
            [styles.disabledLink]: editableLimit === settingsLimit,
          })}
          onClick={() => {
            update(editableLimit);
          }}
          data-cy="customer-account-limit--save--btn"
        >
          Save Changes
        </span>
      </div>
      <div className="content">
        <p>
          Limit customer user accounts by the number of licenses purchased. This
          setting only applies to non-Protenus employee accounts.
        </p>
        <Input
          bordered
          className="duration-input"
          label="User Account Limit"
          min={MIN_LIMIT}
          max={MAX_LIMIT}
          name="userAccountLimit"
          placeholder="Unlimited"
          onChange={value => {
            if (value === '') setEditableLimit(MIN_LIMIT);
            const parsedValue = parseInt(value);
            if (parsedValue >= MIN_LIMIT && parsedValue <= MAX_LIMIT) {
              setEditableLimit(parsedValue);
            } else if (parsedValue >= MIN_LIMIT) {
              setEditableLimit(MAX_LIMIT);
            }
          }}
          type="number"
          error={false}
          value={editableLimit ?? ''}
        />
      </div>
    </div>
  );
};

UserAccountLimit.propTypes = {
  update: PropTypes.func.isRequired,
  onDirtyChange: PropTypes.func.isRequired,
  settingsLimit: PropTypes.number,
};

export default UserAccountLimit;
