import React from 'react';
import * as d3 from 'd3';
import moment from 'moment';

import { autorun } from 'mobx';

import ChartStore from '../../stores/ChartStore';
import EventDetailedStore from '../../stores/EventDetailedStore';
import Store from '../../stores/Store';

/**
 * Layer responsible for drawing a vertical line for the selected access in the activity chart.
 * @extends React.Component
 */
export default class VerticalRuleLayer extends React.Component {
  // Ref
  group = React.createRef();

  componentDidMount() {
    this.disposers.push(
      autorun(() => {
        this.renderLine();
      })
    );
  }

  componentWillUnmount() {
    this.disposers.forEach(d => d());
  }

  transformD = d => {
    const d1 = (Store.focus === 'employee_incidents'
      ? moment(d.startTime)
      : d.eventDate
    ).toDate();
    const x = ChartStore.brushedXScale(d1);
    return `translate(${ChartStore.margin.left + x},0)`;
  };

  disposers = [];

  renderLine() {
    const event =
      EventDetailedStore[
        Store.focus === 'employee_incidents' ? 'detailFocus' : 'detailView'
      ];

    const data = (event && [event]) || [];
    const lines = d3
      .select(this.group.current)
      .selectAll('line')
      .data(data);

    lines
      .enter()
      .append('line')
      .attr('y1', 0)
      .attr('y2', 99999)
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('transform', this.transformD);

    lines.transition().attr('transform', this.transformD);

    lines.exit().remove();
  }

  render() {
    // The clipPath is defined in FullActivityChart.
    return <g className="rule" ref={this.group} clipPath="url(#clip)" />;
  }
}
