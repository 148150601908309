import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MultilineInput } from '../../ui';
import styles from './index.module.scss';

function Reason({ onSubmit }) {
  const [reason, setReason] = useState('');
  return (
    <>
      <div>Any existing VIP Registration Reasons will be overwritten.</div>
      <MultilineInput
        placeholder="Registration Reason - Optional"
        value={reason}
        onChange={value => setReason(value)}
      />
      <button className={styles.fullWidth} onClick={() => onSubmit(reason)}>
        Change Registration reason
      </button>
    </>
  );
}

Reason.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Reason;
