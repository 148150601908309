import React from 'react';
import PropTypes from 'prop-types';
import { action, autorun, decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import $ from 'jquery';

import { LoginStore, RouterContainer } from 'common';
import MultifactorSetup from '../MultifactorSetup';
import QrCode from '../QrCode';
import LoginFooter from '../LoginFooter';
import { ApplyBodyClassName, DocumentTitle } from '../../ui';

const MultifactorSetupView = observer(
  class MultifactorSetupView extends React.Component {
    static propTypes = {
      page: PropTypes.string,
    };
    // Observable
    showMFAShortCut = false;
    showInstructions = false;

    componentWillUnmount = autorun(() => {
      if (!LoginStore.loggedIn || LoginStore.mfaEnabled) {
        RouterContainer.go('/', {});
      }
    });

    // action
    toggleMFA = bool => {
      this.showMFAShortCut = bool;
    };

    handleClick = bool => {
      this.showInstructions = bool;
    };

    render() {
      const { page } = this.props;
      return (
        <section className="login mfa-forced">
          <DocumentTitle text="Protenus Enable MFA" />
          {!page && <ApplyBodyClassName className="mfa_view" />}
          <article className="login-container">
            {this.showMFAShortCut ? (
              <QrCode
                type="shortcut"
                toggleMFA={this.toggleMFA}
                onBackClick={() => this.toggleMFA(false)}
                nextValue="Verify"
                {...this.props}
              >
                <p key="mfa-text">
                  Use your authentication app to scan the above barcode and
                  enter the verification code in the text box below.
                </p>
              </QrCode>
            ) : (
              <MultifactorSetup onClick={this.handleClick} {...this.props} />
            )}
            <div className="mfa-links">
              {!this.showMFAShortCut && !this.showInstructions && (
                <small>
                  <p
                    className="prot-a"
                    onClick={() => {
                      this.toggleMFA(true);
                    }}
                  >
                    I already have an Authenticaton App
                  </p>
                </small>
              )}
              <small>
                <a
                  className="prot-a"
                  href={`mailto:support@protenus.com?${$.param(
                    { subject: '2-Factor Validation Setup' },
                    true
                  )}`}
                >
                  I need help with 2-Step Verification Setup
                </a>
              </small>
            </div>
          </article>
          {!page && <LoginFooter />}
        </section>
      );
    }
  }
);

decorate(MultifactorSetupView, {
  showInstructions: observable,
  showMFAShortCut: observable,
  toggleMFA: action,
});

MultifactorSetupView.displayName = 'MultifactorSetupView';

export default MultifactorSetupView;
