import React from 'react';
import { action, computed, decorate, observable, reaction } from 'mobx';

import { CaseBundleClient, SingletonStore, convertMultiValue } from 'common';

import CaseBundleStore from '../../../stores/CaseBundleStore';
import NotificationStore from '../../../stores/NotificationStore';

class BundleStore extends SingletonStore {
  constructor() {
    super();

    this.refresh();
    // Make sure the draft state is cleared when bundle is updated.
    reaction(
      () => [this.bundleId, this.group],
      () => {
        this.draftName = null;
        this.draftNotes = null;
        this.refresh();
      },
      { fireImmediately: true }
    );
  }

  // Observables
  bundleId = null;
  draftName = null;
  draftNotes = null;
  group = [];

  // action
  setValue = (type, val) => {
    this[type] = val;
  };

  fetch() {
    return CaseBundleClient.getSingleBundle({
      bundleId: this.bundleId,
      groups: convertMultiValue(this.group),
    });
  }

  get bundle() {
    return this.result || [];
  }

  get nameValue() {
    if (this.draftName || this.draftName === '') return this.draftName;
    return this.bundle?.name;
  }

  get notesValue() {
    if (this.draftNotes || this.draftNotes === '') return this.draftNotes;
    return this.bundle?.notes;
  }

  // Computed
  get isNameDirty() {
    if (!this.bundle) return false;
    return this.nameValue !== null && this.bundle.name !== this.nameValue;
  }

  // Computed
  get isNotesDirty() {
    if (!this.bundle) return false;
    return this.bundle.notes !== this.notesValue;
  }

  // Computed
  get isDirty() {
    return this.isNameDirty || this.isNotesDirty;
  }

  removeCases(caseIds) {
    return CaseBundleClient.removeCases(this.bundleId, caseIds);
  }

  save = () => {
    if (!this.isDirty || !this.bundle) return;
    const updated = {};
    updated.name = this.nameValue;
    updated.notes = this.notesValue;
    return this.updateBundle(updated);
  };

  updateBundle(updated) {
    return CaseBundleClient.update(this.bundle.id, updated)
      .then(() => {
        CaseBundleStore.refresh();
        const content = (
          <span>
            <i className="material-icons icon-check_circle" />
            Successfully updated bundle
          </span>
        );
        NotificationStore.add({ level: 'success', content });
      })
      .catch(() => {
        const content = (
          <span>
            <i className="material-icons icon-warning" />
            There was a problem updating this Bundle
          </span>
        );
        NotificationStore.add({ level: 'warning', content });
      });
  }
}

decorate(BundleStore, {
  bundleId: observable,
  draftName: observable,
  draftNotes: observable,
  group: observable,
  bundle: computed,
  isNameDirty: computed,
  isNotesDirty: computed,
  isDirty: computed,
  nameValue: computed,
  notesValue: computed,
  setValue: action,
});

const store = new BundleStore();
export { store as BundleStore };
export default store;
