import React from 'react';
import PropTypes from 'prop-types';
import PersonAttribute from '../../people/PersonBox/PersonAttribute';

const VIPAttribute = ({ canManageVIPs, patientId, registration }) => {
  let content;
  if (registration) content = 'VIP';
  if (registration?.status === 'EXPIRED') content += ' (Expired)';

  return (
    <PersonAttribute
      content={content}
      emptyText="No VIP Registration"
      emptyLink={
        canManageVIPs ? `/vips/create/patient/${patientId}` : undefined
      }
      icon="icon-star"
      link={registration ? `/vips/${registration.id}` : undefined}
      tooltipText="Create VIP Registration"
    />
  );
};

VIPAttribute.propTypes = {
  canManageVIPs: PropTypes.bool,
  patientId: PropTypes.string.isRequired,
  registration: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default VIPAttribute;
