import React from 'react';
import { observer } from 'mobx-react';
import NewAppExperience from '../NewAppExperience';
import NewAppExperienceStore from '../../../stores/NewAppExperienceStore';

export function mapProps({ NewAppExperienceStore }) {
  return {
    update: NewAppExperienceStore.updateSettings,
    toggleValue: NewAppExperienceStore.toggleValue,
    appValues: NewAppExperienceStore.appSettingsEditValues,
    isDirty: NewAppExperienceStore.isDirty,
  };
}

const NewAppExperienceContainer = observer(() => {
  return <NewAppExperience {...mapProps({ NewAppExperienceStore })} />;
});

NewAppExperienceContainer.displayName = 'NewAppExperienceContainer';

export default NewAppExperienceContainer;
