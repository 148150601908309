import { MedicationUtils } from 'common';
import moment from 'moment';

export const buildEvents = medicationIncident => {
  const events = [];
  let custodyChangeIds = [];

  const {
    administrations = [],
    discrepancies = [],
    handlings = [],
    details = {},
  } = medicationIncident;

  if (details.changeInCustody) {
    const {
      administrations: a = [],
      handlings: h = [],
    } = details.changeInCustody;
    custodyChangeIds = a.map(a => a.id).concat(h.map(h => h.id));
  }

  if (details.timeEntries) {
    details.timeEntries.forEach(d => {
      const { id, start, end, user } = d;
      if (start) {
        events.push({
          id,
          start,
          date: start,
          user,
          clockedOutTransaction: true,
        });
      }
      if (end) {
        events.push({
          id,
          date: end,
          end,
          user: user,
          clockedOutTransaction: true,
        });
      }
    });
  }

  administrations.forEach(a => {
    events.push({
      id: a.id,
      user: a.user,
      patient: a.patient,
      date: a.startTime,
      detail: MedicationUtils.formatAdministration(a, true),
      order: a.medicationOrder && a.medicationOrder.orderId,
      action: a.actionString,
    });
  });

  discrepancies.forEach(d => {
    events.push({
      id: d.id,
      date: d.resolutionTime || d.startTime,
      detail: MedicationUtils.formatDiscrepancy(d, true),
      action: 'Discrepancy',
    });
  });

  handlings.forEach(h => {
    events.push({
      id: h.id,
      user: h.user,
      patient: h.patient,
      date: h.eventTime,
      detail: MedicationUtils.formatHandling(h, true),
      order: h.medicationOrder && h.medicationOrder.orderId,
      action: h.formattedAction,
    });
  });

  // moment.js ensures dates sort correctly in IE
  events.sort((a, b) => moment(a.date) - moment(b.date));

  // Now that we have all events in time order, we tack on any change in custody
  // information when the user changes between admins or handlings.
  return events.reduce((events, e, index) => {
    if (
      !details.changeInCustody ||
      !custodyChangeIds.includes(e.id) ||
      index === 0
    ) {
      return [...events, e];
    }

    const changeInCustody = {
      fromUser: events[index - 1].user,
      toUser: e.user,
    };

    // Check that the last change in custody was not the same from/to.
    const lastChangeInCustody = events
      .slice(0, index)
      .reverse()
      .find(e => e.changeInCustody)?.changeInCustody;

    // If lastChangeInCustody is defined, ensure that its toUser is the same as
    // our fromUser and that from and to are different.
    // If not, it is likely a duplicate we do not need to display.
    if (
      lastChangeInCustody?.toUser &&
      (lastChangeInCustody.toUser.id !== changeInCustody.fromUser.id ||
        changeInCustody.fromUser.id === changeInCustody.toUser.id)
    ) {
      return [...events, e];
    }

    return [...events, { ...e, changeInCustody }];
  }, []);
};
