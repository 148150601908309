import React from 'react';

import CaseReportsNew from '../CaseReportsNew';
import CaseReportsClosed from '../CaseReportsClosed';

/**
 * Primary component for the display of reports for cases which were recently
 * created and closed.
 * @param {Object} props - the component's properties
 * @return {Object} a React DOM Object
 */
const CaseReports = () => (
  <section className="home__cases-reporting">
    <header>
      <h3>Reporting</h3>
    </header>
    <div>
      <CaseReportsNew />
      <CaseReportsClosed />
    </div>
  </section>
);

export default CaseReports;
