var environmentMapping = {
  "development": /\.dev\./g,
  "integration": /\.int\./g,
  "local": /localhost/g,
  "shadow": /\.shd\./g,
  "production": /.*/g
};
export var detectEnvironment = function detectEnvironment(host) {
  var _Object$entries$find;
  return (_Object$entries$find = Object.entries(environmentMapping).find(function (e) {
    return e[1].test(host);
  })) === null || _Object$entries$find === void 0 ? void 0 : _Object$entries$find[0];
};