import React from 'react';
import moment from 'moment';
import * as d3 from 'd3';
import { autorun, computed, decorate } from 'mobx';
import { observer } from 'mobx-react';

import { RouterContainer } from 'common';

import CaseAgesStore from '../stores/CaseAgesStore';

const DATE_FORMAT = 'YYYY-MM-DD';

const CaseAgesChart = observer(
  class CaseAgesChart extends React.Component {
    width = 370;
    height = 30;
    minWidth = 30;
    disposers = [];

    // Ref
    chart = React.createRef();

    /**
     * Tasks to be run when the component mounts, specifically setting autorun
     * actions and event listeners.
     */
    componentDidMount() {
      this.renderChart();

      this.disposers.push(
        autorun(() => {
          this.updateChart();
        })
      );
    }

    /**
     * Tasks to be run when the component mounts, specifically disposing autorun
     * actions and removing event listeners.
     */
    componentWillUnmount() {
      this.disposers.forEach(d => d());
    }

    /**
     * COMPUTED
     * Produce list data for each category, to be used when building up the
     * chart. The data is configured so that the D3 stack layout can be leveraged
     * (useful for calculating the stacked segments). The data is manipulated so
     * it's ready for a horizontal layout (as opposed to a vertical layout, which
     * is the stacked layout's assumption).
     * @return {Array} Array of objects representing each segment
     */
    get data() {
      const store = CaseAgesStore;
      const stack = d3.stack();

      // The x properties in the objects in this Array MUST be indexes that
      // correlate to the position of the object in the larger Array, otherwise
      // odd behavior will happen.
      const dataset = [
        [
          {
            group: 'upToFifteenDays',
            range: [null, 15],
            x: 0,
            y: store.totalUpToFifteen,
          },
        ],
        [
          {
            group: 'fifteenToThirtyDays',
            range: [16, 30],
            x: 1,
            y: store.totalFifteenToThirty,
          },
        ],
        [
          {
            group: 'thirtyToSixtyDays',
            range: [31, 60],
            x: 2,
            y: store.totalThirtyToSixty,
          },
        ],
        [
          {
            group: 'moreThanSixtyDays',
            range: [61, null],
            x: 3,
            y: store.totalMoreThanSixty,
          },
        ],
      ];

      // run the data through the stack layout transformer
      stack(dataset);

      // Invert the x and y values, y0 becomes x0
      return dataset.map(group =>
        group.map(d => {
          return {
            group: d.group,
            range: d.range,
            x: d.y,
            x0: d.y0,
            y: d.x,
          };
        })
      );
    }

    /**
     * COMPUTED
     * Calculate the total value in the series, used for calculating widths of
     * segments for the chart
     * @return {Number} total data value in the series
     */
    get xTotal() {
      return d3.sum(this.data, group => group[0].x);
    }

    /**
     * COMPUTED
     * Calculate the usable total value in the series, used for calcuating widths
     * of segments for the charts. This will not include small segment values.
     * @return {Number} total usable data value in the series
     */
    get xUsableTotal() {
      return d3.sum(
        this.data.filter(
          group =>
            group[0].x / Math.max(1, this.xTotal) > this.minWidth / this.width
        ),
        group => group[0].x
      );
    }

    /**
     * COMPUTED
     * Produces a list of values for segments which are deemed small. A small
     * segment occurs when: value / total value is less than
     * minimum width / total width.
     */
    get smallSegments() {
      return this.data
        .filter(
          group =>
            group[0].x / Math.max(1, this.xTotal) < this.minWidth / this.width
        )
        .map(d => {
          return d[0];
        });
    }

    /**
     * COMPUTED
     * Create the scale for the x axis
     * @return {Function} a d3 scale instance
     */
    get xScale() {
      return d3
        .scaleLinear()
        .domain([0, this.xUsableTotal])
        .range([0, this.width - this.minWidth * this.smallSegments.length]);
    }

    /**
     * COMPUTED
     * Normalize the widths of the chart segments. This takes the dimensions
     * produced by D3 and does two things:
     * 1) allows for the display of 0 value data points
     * 2) sets a minimum width on segments (ensuring that the value is always
     *    displayed in the visualization, regardless of overall balance of the data)
     * @return {Array} an Array of widths
     */
    get segmentWidths() {
      let widths = [];

      widths = this.data.map(
        group =>
          group.map(d => {
            // In the case of all 0's
            if (this.data.length === this.smallSegments.length) {
              return this.width / this.data.length;
            } else {
              if (this.smallSegments.indexOf(d) !== -1) return this.minWidth;

              return this.xScale(d.x);
            }
          })[0]
      );

      return widths;
    }

    /**
     * COMPUTED
     * Normalize the xOffsets of the chart segments.
     * Because the dimensions of the chart segments are being modified, it's
     * necessary to also modify the values of the xOffset in order to ensure
     * accurate placement within the visualization. This produces a flat Array of
     * offset value that can be accessed by referencing the index of the segment
     * that should have the offset value.
     *
     * @return {Array} an Array of offset values
     */
    get segmentOffsets() {
      const xOffsets = [];

      this.segmentWidths.forEach((w, i) => {
        const precedingWidths = this.segmentWidths.slice(0, i);

        if (precedingWidths.length) {
          xOffsets.push(precedingWidths.reduce((a, b) => a + b));
        } else {
          // the first entry won't have any preceding widths, and its offset
          // should start at 0
          xOffsets.push(0);
        }
      });
      return xOffsets;
    }

    /**
     * Helper method to properly handle the pluralization of the word 'Case'
     * based on the value with which it is being paired. Bound to the Class
     * Instance context.
     * @param {Object} d - the data object being evaluated
     * @return {String} the inflected label
     */
    caseInflection = d => {
      const width = this.segmentWidthFor(d);

      if (width <= (d.x < 10 ? this.minWidth * 2.4 : this.minWidth * 2.5))
        return '';
      else return d.x === 1 ? ' Case' : ' Cases';
    };

    /**
     * Produce a label for the given datum's segment. Bound to the Class Instance
     * context.
     * @param {Object} d - the datum being used to produce the label
     * @return {String} the label for the datum's chart segment
     */
    labelSegment = d => {
      return `${d.x}${this.caseInflection(d)}`;
    };

    /**
     * Event handler for mouseover events; this will update the Store's "active"
     * value when a user is interacting with a specific segment in the chart.
     * Additionally, this will cause all non-hovered segments (and their labels)
     * in the chart to fade out slightly, to help emphasize the segment that is
     * in focus. Bound to the Class Instance context.
     * @param {Object} d - a reference to the segment being interacted with
     */
    mouseover = d => {
      const datum = d[0];
      CaseAgesStore.selectedSegment = datum.group;

      d3.selectAll(`[data-group='${datum.group}']`).classed(
        'active-selection',
        true
      );

      d3.selectAll(
        `.case-ages__chart-segment:not([data-group='${datum.group}'])`
      ).classed('not-active-selection', true);

      d3.selectAll(
        `.case-ages__chart-segment-label:not([data-group='${datum.group}'])`
      ).classed('not-active-selection', true);
    };

    /**
     * Event handler for mouseout events; this will update the Store's "active"
     * value when a user has stopped interacting with a specific segment in the
     * chart.  Additionally, this will cause all non-hovered segments (and their
     * labels) in the chart to fade back in, resetting the chart to its
     * pre-hovered state. Bound to the Class Instance context.
     * @param {Object} d - a reference to the segment being interacted with
     */
    mouseout = d => {
      const datum = d[0];
      CaseAgesStore.selectedSegment = null;

      d3.select(`[data-group='${datum.group}']`).classed(
        'active-selection',
        false
      );

      d3.selectAll(
        `.case-ages__chart-segment:not([data-group='${datum.group}'])`
      ).classed('not-active-selection', false);

      d3.selectAll(
        `.case-ages__chart-segment-label:not([data-group='${datum.group}'])`
      ).classed('not-active-selection', false);
    };

    /**
     * Event handler for click events; this will send the user to the case
     * listing based upon a specific segment.
     * @param {Object} d - a reference to the segment being interacted with
     */
    click = d => {
      d3.event.stopPropagation();

      const datum = d[0];
      const params = {};

      params.showFilters = true;
      params.resolution = 'null';
      params.createdRange = 'custom';

      if (CaseAgesStore.owner) params.owner = CaseAgesStore.owner;

      if (datum.range[0])
        params.createdBefore = moment()
          .subtract(datum.range[0], 'days')
          .format(DATE_FORMAT);

      if (datum.range[1]) {
        params.createdAfter = moment()
          .subtract(datum.range[1], 'days')
          .format(DATE_FORMAT);
      } else {
        params.createdAfter = moment(0).format(DATE_FORMAT);
      }

      RouterContainer.go('/cases', params);
    };

    /**
     * Retrieve the width of a specific chart segment. Bound to the Class
     * Instance context.
     * @param {Object} d - the data Object reference for the chart segment
     * @return {Number} the width of the segment
     */
    segmentWidthFor = d => {
      return this.segmentWidths[d.y];
    };

    /**
     * Retrieve the x offset of a specific chart segment. Bound to the Class
     * Instance context.
     * @param {Object} d - the data Object reference for the chart segment
     * @return {Number} the offset of the segment
     */
    segmentXOffsetFor = d => {
      return this.segmentOffsets[d.y];
    };

    /**
     * Retrieve the label offset of a specific chart segment. Bound to the Class
     * Instance context.
     * @param {Object} d - the data Object reference for the chart segment
     * @return {Number} the offset of the segment's label
     */
    segmentLabelOffsetFor = d => {
      return this.segmentXOffsetFor(d) + 10;
    };

    /**
     * Updates the already-rendered chart with new data values, animating the transition.
     */
    updateChart() {
      const svg = d3.select(this.chart.current).select('svg');
      const duration = 500;
      const easing = d3.easeCubicInOut;
      const groups = svg.selectAll('g').data(this.data);

      // Update the bars
      groups
        .selectAll('rect')
        .data(d => d)
        .transition()
        .duration(duration)
        .ease(easing)
        .attr('width', this.segmentWidthFor)
        .attr('x', this.segmentXOffsetFor);
    }

    /**
     * Method to manage the initial rendering of the chart.
     */
    renderChart() {
      const data = this.data;
      const svg = d3
        .select(this.chart.current)
        .select('svg')
        .attr('width', this.width)
        .attr('height', this.height);

      const groups = svg
        .selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .classed('case-ages__chart-segment', true)
        .attr('data-group', d => d[0].group)
        .on('mouseover', this.mouseover)
        .on('mouseout', this.mouseout)
        .on('click', this.click);

      // draw the bars for the visualization
      groups
        .selectAll('rect')
        .data(d => d)
        .enter()
        .append('rect')
        .attr('x', this.segmentXOffsetFor)
        .attr('y', 0)
        .attr('height', this.height)
        .attr('width', this.segmentWidthFor);

      // overlayShadow
      d3.select(this.chart.current)
        .select('svg')
        .append('rect')
        .attr('width', this.width)
        .attr('height', '3px')
        .attr('fill', 'url(#grad1)');
    }

    /**
     * Render the component to the DOM.
     * @return {Object} the React DOM Object
     */
    render() {
      return (
        <article className="datavis" ref={this.chart}>
          <svg>
            <defs>
              <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="black" stopOpacity="0.2" />
                <stop offset="100%" stopColor="black" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </article>
      );
    }
  }
);

decorate(CaseAgesChart, {
  data: computed,
  xTotal: computed,
  xUsableTotal: computed,
  smallSegments: computed,
  xScale: computed,
  segmentWidths: computed,
  segmentOffsets: computed,
});

CaseAgesChart.displayName = 'CaseAgesChart';

export default CaseAgesChart;
