import React from 'react';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { DoubleLineCell } from '../../..';
import { FullName } from 'common';
import moment from 'moment';

const PatientCell = ({ patient }) => {
  const { medicalRecordNumbers, dateOfBirth } = patient;

  let mrn = 'No MRN';
  if (medicalRecordNumbers && medicalRecordNumbers.length) {
    if (medicalRecordNumbers.length > 1)
      mrn = `${medicalRecordNumbers.length} MRNs`;
    else mrn = medicalRecordNumbers[0];
  }

  let dob = 'No DOB';
  if (dateOfBirth) {
    const m = moment(dateOfBirth).utc();
    dob = m.format('l');
  }

  const lineOne = <FullName person={patient} lastNameFirst />;
  const lineTwo = `${mrn} - ${dob}`;

  return <DoubleLineCell lineOne={lineOne} lineTwo={lineTwo} />;
};

export default PatientCell;

PatientCell.propTypes = {
  patient: PropTypes.shape({
    dateOfBirth: PropTypes.string,
    medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.string
    ),
  }),
};

PatientCell.defaultProps = {
  patient: {},
};
