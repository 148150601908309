import React from 'react';
import ReactDOM from 'react-dom';
import './app.scss';
import momentLocalizer from 'react-widgets-moment';
import Main from './app/Main';

import { initializeSentry } from 'common';

initializeSentry();

const Moment = require('moment');

momentLocalizer(Moment);

ReactDOM.render(<Main />, document.getElementById('routeHandler'));
