import React from 'react';
import { observer } from 'mobx-react';

import { LoginStore, PermissionStore } from 'common';
import NavBarItem from '../../navbar/NavBarItem';
import TopNavBar from '../../navbar/TopNavBar';
import AdvancedSortContainer from '../AdvancedSortContainer';

import CaseResultsStore from '../stores/CaseResultsStore';
import { GroupQuickFilterContainer } from '../../ui/GroupQuickFilterWithGroupStore';

import styles from './index.module.scss';

const TextItem = observer(({ text, filterProperty, filterValue, ...props }) => (
  <NavBarItem
    active={CaseResultsStore[filterProperty] === filterValue}
    getRoute={() => CaseResultsStore.path({ [filterProperty]: filterValue })}
    {...props}
  >
    {text}
  </NavBarItem>
));

const CaseResultsTopNav = observer(
  class CaseResultsTopNav extends React.Component {
    downloadCSV = e => {
      e.preventDefault();

      CaseResultsStore.downloadCSV();
    };

    renderBasicFilters() {
      if (!CaseResultsStore.showFilters) {
        const owners = [
          <TextItem
            key="caseOwnerFilter"
            text="Show All"
            filterProperty="owner"
            filterValue={null}
          />,
          <TextItem
            key="unassignedCasesFilter"
            text="Unassigned"
            filterProperty="owner"
            filterValue="null"
          />,
        ];
        const types = [
          <TextItem
            key="caseUnresolvedFilter"
            text="Unresolved"
            filterProperty="resolution"
            filterValue="null"
          />,
          <TextItem
            key="caseViolationFilter"
            text="Violation"
            filterProperty="resolution"
            filterValue="VIOLATION"
          />,
          <TextItem
            key="caseNotViolationFilter"
            text="Not Violation"
            filterProperty="resolution"
            filterValue="NOT_VIOLATION"
          />,
        ];

        if (
          PermissionStore.has('CASE_MODIFY_NOTES_OWNED') &&
          !LoginStore.roles.includes('VIEW ONLY')
        ) {
          owners.splice(
            1,
            0,
            <TextItem
              key="myCasesFilter"
              text="My Cases"
              filterProperty="owner"
              filterValue={LoginStore.id}
            />
          );
        }

        let ownerFilters = [];

        if (PermissionStore.has('CASE_SEARCH')) {
          ownerFilters = [
            <div
              className="basicFilters__owner"
              key="caseOwnerFilter"
              data-cy="cases-owner-filter"
            >
              {owners}
            </div>,
          ];
        }

        const typeFilters = [
          <div
            className="basicFilters__type"
            key="caseTypeFilter"
            data-cy="cases-type-filter"
          >
            {types}
          </div>,
        ];

        return [...ownerFilters, ...typeFilters];
      }
    }

    render() {
      return (
        <TopNavBar filterBar>
          <div className={styles.leftSideFilters}>
            {!CaseResultsStore.showFilters && (
              <GroupQuickFilterContainer useTopNav={false} />
            )}
            {this.renderBasicFilters()}
          </div>
          <AdvancedSortContainer />
        </TopNavBar>
      );
    }
  }
);

CaseResultsTopNav.displayName = 'CaseResultsTopNav';

export default CaseResultsTopNav;
