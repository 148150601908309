import { useEffect, useCallback } from 'react';
import queryString from 'query-string';

export function useQueryFromURI(query, setQuery, history, path, defaultVal) {
  const memoizedSetQuery = useCallback(
    q => {
      setQuery(q);
    },
    [setQuery]
  );

  useEffect(() => {
    if (Object.keys(query).length) memoizedSetQuery(query);
    else
      history.replace({
        pathname: path,
        search: queryString.stringify(defaultVal),
      });
  }, [memoizedSetQuery, query, history, path, defaultVal]);
}
