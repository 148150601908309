import React from 'react';
import PropTypes from 'prop-types';
import { fromCamelCase } from 'common';
import { PropTypes as mobxPropTypes } from 'mobx-react';

const valuePropType = mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string);

export const PrintOptionItem = ({ name, value, bolded = false }) => {
  if (!name || !value.length) return null;
  return (
    <span>
      {bolded ? (
        <>
          <br></br>
          <strong>{`${fromCamelCase(name)}: `}</strong>
          {value.join(', ')}
        </>
      ) : (
        <>
          <br></br>
          {`${fromCamelCase(name)}: ${value.join(', ')}`}
        </>
      )}
    </span>
  );
};

PrintOptionItem.propTypes = {
  name: PropTypes.string,
  value: valuePropType,
  bolded: PropTypes.bool,
};

const CasePrintLogValue = ({ printOptions }) => {
  const {
    caseflow,
    caseflowCustom,
    incidents,
    incidentId,
    incidentTimeRange,
    incidentType,
    incidentStatus,
    assessments,
    assessmentTimeRange,
    userDetails,
  } = printOptions;
  return (
    <>
      {caseflow && (
        <PrintOptionItem name={'Caseflow'} value={caseflow} bolded />
      )}
      {caseflowCustom && (
        <PrintOptionItem name={'Fields included'} value={caseflowCustom} />
      )}
      {incidents && (
        <PrintOptionItem name={'Incidents'} value={incidents} bolded />
      )}
      {incidentId && (
        <PrintOptionItem name={'Ids included'} value={incidentId} />
      )}
      {incidentTimeRange && (
        <PrintOptionItem name={'Time Range'} value={incidentTimeRange} />
      )}
      {incidentType && <PrintOptionItem name={'Type'} value={incidentType} />}
      {incidentStatus && (
        <PrintOptionItem name={'Status'} value={incidentStatus} />
      )}
      {assessments && (
        <PrintOptionItem name={'Assessments'} value={assessments} bolded />
      )}
      {assessmentTimeRange && (
        <PrintOptionItem name={'Time Range'} value={assessmentTimeRange} />
      )}
      {userDetails && (
        <PrintOptionItem name={'User Details'} value={userDetails} bolded />
      )}
    </>
  );
};

CasePrintLogValue.propTypes = {
  printOptions: PropTypes.shape({
    caseflow: valuePropType,
    caseflowCustom: valuePropType,
    incidents: valuePropType,
    incidentId: valuePropType,
    incidentTimeRange: valuePropType,
    incidentType: valuePropType,
    incidentStatus: valuePropType,
    assessments: valuePropType,
    assessmentTimeRange: valuePropType,
    userDetails: valuePropType,
  }),
};

export default CasePrintLogValue;
