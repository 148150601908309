import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import moment from 'moment';
import classnames from 'classnames';
import ChartPropTypes from '../PropTypes';
import { Tooltip } from '../../../../ui';
import styles from './index.module.scss';
import pluralize from '../../../../utils/pluralize';

const getTooltipContent = (assessments, startTime, endTime) => {
  const numIncidents = assessments
    .filter(
      period => period.startTime === startTime && period.endTime === endTime
    )
    .flatMap(assessment => assessment.incidents || [])
    .reduce(
      (sum, { linkedIncidents = { length: 1 } }) =>
        sum + linkedIncidents.length,
      0
    );

  return (
    <span>
      {moment(startTime).format('l')} - {moment(endTime).format('l')}{' '}
      <strong>
        {numIncidents} {pluralize(numIncidents, 'Incident')}
      </strong>
    </span>
  );
};

/**
 * Layer responsible for drawing rectangles for each month-ish period of diversion stats.
 */

const DiversionStatisticPeriodLayer = ({
  height,
  leftMargin,
  onSelectStatisticPeriodStartDate,
  selectedIncident,
  statisticPeriods,
  xScale,
}) => {
  const DEFAULT_RECT_HEIGHT = 12;

  const data = statisticPeriods.map(period => {
    const startMoment = moment(period.startTime);
    const endMoment = moment(period.endTime);
    const x0 = xScale(startMoment);
    const x1 = xScale(endMoment);
    const rectHeight = period.selected
      ? DEFAULT_RECT_HEIGHT + 6
      : DEFAULT_RECT_HEIGHT;
    const translate = `translate(${xScale(startMoment) + leftMargin}, ${height /
      2 -
      rectHeight / 2})`;
    const source = period.user && period.user.source;
    return {
      endDate: period.endTime,
      flagged: period.checked,
      height: rectHeight,
      key: `${source}_${period.startTime}`,
      startDate: period.startTime,
      selected: period.selected,
      translate,
      tooltipContent: getTooltipContent(
        statisticPeriods,
        period.startTime,
        period.endTime
      ),
      width: x1 - x0,
    };
  });

  return (
    // The clipPath is defined in FullActivityChart.
    <g
      className={styles.group}
      clipPath="url(#clip)"
      data-cy="diversion-assessments"
    >
      {/* Ensures the group is clickable.*/}
      <rect className={styles.expander} width="100%" height={height} />
      {data.map(
        ({
          endDate,
          flagged,
          height,
          key,
          startDate,
          selected,
          tooltipContent,
          translate,
          width,
        }) => (
          <Tooltip
            key={key}
            content={tooltipContent}
            placement="top"
            followCursor
          >
            <rect
              className={classnames(styles.periodRect, {
                [styles.selected]: selected,
                [styles.flagged]: flagged,
                [styles.incidentSelected]:
                  selectedIncident &&
                  selected &&
                  !flagged &&
                  moment(selectedIncident.startTime).isBetween(
                    moment(startDate),
                    moment(endDate)
                  ),
              })}
              rx={height / 2}
              width={width}
              height={height}
              transform={translate}
              data-cy={classnames({
                'assessment-row-selected': selected,
                'assessment-row-not-selected': !selected,
              })}
              onClick={() => {
                onSelectStatisticPeriodStartDate(startDate);
              }}
            />
          </Tooltip>
        )
      )}
    </g>
  );
};

DiversionStatisticPeriodLayer.propTypes = {
  height: PropTypes.number.isRequired,
  leftMargin: PropTypes.number.isRequired,
  onSelectStatisticPeriodStartDate: PropTypes.func.isRequired,
  selectedIncident: ChartPropTypes.incident,
  statisticPeriods: mobxPropTypes.arrayOrObservableArrayOf(
    ChartPropTypes.statisticPeriod
  ).isRequired,
  xScale: PropTypes.func.isRequired,
};

DiversionStatisticPeriodLayer.defaultProps = {
  selectedIncident: undefined,
};

export default DiversionStatisticPeriodLayer;
