import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const PersonAside = ({ children, fixedWidth, orderLast }) => {
  return (
    <aside
      className={classnames(styles.container, {
        [styles.orderLast]: orderLast,
        [styles.fixedWidth]: fixedWidth,
      })}
    >
      {children}
    </aside>
  );
};

PersonAside.propTypes = {
  children: PropTypes.node,
  fixedWidth: PropTypes.bool,
  orderLast: PropTypes.bool,
};

PersonAside.defaultProps = {
  fixedWidth: false,
  orderLast: false,
};

PersonAside.displayName = 'PersonAside';

export default PersonAside;
