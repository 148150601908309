import React from 'react';
import PropTypes from 'prop-types';
import { Pandy } from 'common';
import styles from './index.module.scss';

class ErrorBoundaryPandy extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // In the future we can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className={styles.container}>
          <div className={styles.pandyWrapper}>
            <Pandy visible mood={'sad'}>
              <div className="speechBubble">
                <span>Oh no!</span>
              </div>
              <p>
                We have encountered a critical error. Please try refreshing the
                page. If that does not solve the issue, contact the{' '}
                <a
                  href="https://help.protenus.com/hc/en-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Protenus Help Center.
                </a>
              </p>
            </Pandy>
          </div>
          <details className={styles.errorWrapper}>
            <pre className={styles.errorDetails}>
              {this.state.error && this.state.error.toString()}
              {this.state.errorInfo.componentStack}
            </pre>
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundaryPandy.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundaryPandy;
