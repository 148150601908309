import { computed, decorate } from 'mobx';

import { HalUtils, SingletonStore, EmailTemplateClient } from 'common';

class CaseEmailTemplatesStore extends SingletonStore {
  fetch() {
    return EmailTemplateClient.findAll();
  }

  // Computed
  get templates() {
    return HalUtils.getData(this.result);
  }
}

decorate(CaseEmailTemplatesStore, {
  templates: computed,
});

export default new CaseEmailTemplatesStore();
