import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Field, ShowHideMore } from '../../ui';
import ViaSource from '../../ui/ViaSource';
import {
  filterRelationships,
  getPersonLink,
  getTitle,
  groupByRelationshipType,
} from './helpers';

const Relationships = ({ relationships, employeeId }) => {
  const filteredRelationships = filterRelationships({
    employeeId,
    relationships,
  });

  const groupedByRelationshipType =
    groupByRelationshipType(filteredRelationships) || {};

  return (
    <ShowHideMore numberToShow={1} label={'Relationship'}>
      {groupedByRelationshipType.map((person, i) => (
        <Field label={getTitle(person.type)} width="auto" key={person.id + i}>
          <Link to={getPersonLink(person)}>{person.fullName}</Link>
          <ViaSource
            source={person.relationshipSource}
            asOfDate={person.metadata?.created}
          />
        </Field>
      ))}
    </ShowHideMore>
  );
};

Relationships.propTypes = {
  relationships: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  employeeId: PropTypes.string,
};

export default Relationships;
