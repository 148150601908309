import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import Assessment from '../../../ui/Assessment';

const DiversionAssessment = ({
  analyticAssessment,
  assessmentsHeadlines,
  author,
  condensedHeader,
  linkTo,
  lastViewedByUser,
  unreconciledDrugs,
  incidentsByPeriod,
  onHeaderClick,
  selectInc,
  scrollTo,
  clearScrollTo,
  source,
  selected,
}) => {
  /* eslint-disable react/prop-types */
  const {
    minEventDate,
    startTime,
    maxEventDate,
    endTime,
    dateCreated,
    dateAdded,
  } = analyticAssessment;
  const incidentReasons = new Set(
    incidentsByPeriod.filter(inc => inc.type).map(inc => inc.type)
  );

  return (
    <Assessment
      selected={selected}
      scrollTo={scrollTo}
      clearScrollTo={clearScrollTo}
    >
      <Assessment.Header
        author={author}
        dateAdded={dateAdded}
        dateCreated={dateCreated}
        lastViewedByUser={lastViewedByUser}
        startDate={minEventDate || startTime}
        endDate={maxEventDate || endTime}
        type="diversion"
        onClick={() => onHeaderClick(analyticAssessment.startTime)}
        source={source}
        condensedHeader={condensedHeader}
        suspicionScore={analyticAssessment.suspicionScore}
        unreconciledDrugs={unreconciledDrugs && unreconciledDrugs.length}
        incidentsLen={incidentsByPeriod.length}
        lastUpdatedByAG={analyticAssessment.lastUpdatedByAG}
      />
      <Assessment.IncidentStats
        reasons={analyticAssessment?.reasons?.filter(
          r => !incidentReasons.has(r.identifier)
        )}
        clusterReasons={analyticAssessment?.clusterReasons}
      />
      {unreconciledDrugs.length && (
        <Assessment.UnreconciledDrugs incidents={incidentsByPeriod} />
      )}
      {assessmentsHeadlines.length && (
        <Assessment.IncidentHeadline
          onClick={selectInc}
          page={linkTo}
          incidents={incidentsByPeriod}
          headlineReasons={assessmentsHeadlines}
          startDate={minEventDate || startTime}
          endDate={maxEventDate || endTime}
        />
      )}
    </Assessment>
  );
};

DiversionAssessment.propTypes = {
  analyticAssessment: PropTypes.shape({
    dateAdded: PropTypes.string,
    dateCreated: PropTypes.string,
    lastUpdatedByAG: PropTypes.string,
    maxEventDate: PropTypes.string,
    minEventDate: PropTypes.string,
    reasons: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})), // detailed validations in the component
    clusterReasons: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
    suspicionScore: PropTypes.number,
  }),
  assessmentsHeadlines: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  author: PropTypes.string,
  condensedHeader: PropTypes.bool,
  lastViewedByUser: PropTypes.string,
  linkTo: PropTypes.string,
  unreconciledDrugs: PropTypes.arrayOf(PropTypes.string),
  incidentsByPeriod: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ), // detailed validations done in medication util
  clearScrollTo: PropTypes.func,
  onHeaderClick: PropTypes.func,
  scrollTo: PropTypes.bool,
  selected: PropTypes.bool,
  selectInc: PropTypes.func,
  source: PropTypes.string,
};

DiversionAssessment.defaultProps = {
  analyticAssessment: {},
  assessmentsHeadlines: [],
  author: '',
  clearScrollTo: () => {},
  condensedHeader: false,
  incidentsByPeriod: [],
  lastViewedByUser: '',
  linkTo: '',
  onHeaderClick: () => {},
  scrollTo: false,
  selected: false,
  selectInc: () => {},
  source: '',
  unreconciledDrugs: [],
};

export default DiversionAssessment;
