import React from 'react';
import PropTypes from 'prop-types';
import dimensions from 'react-dimensions';

// Use this do-nothing component if you need to measure DOM dimensions around or
// within another component.
//
//  <Parents>
//    <DimensionWatcher onChange={(width, height) => ...}>
//      <OtherStuff />
//    <DimensionWatcher />
//  <Parents />

class DimensionWatcherInner extends React.Component {
  static propTypes = {
    containerWidth: PropTypes.number.isRequired,
    containerHeight: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { containerWidth, containerHeight, onChange } = this.props;
    if (
      prevProps.containerWidth !== containerWidth ||
      prevProps.containerHeight !== containerHeight
    ) {
      onChange(containerWidth, containerHeight);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const DimensionWatcher = dimensions({ elementResize: true })(
  DimensionWatcherInner
);

export { DimensionWatcherInner };
export default DimensionWatcher;
