import moment from 'moment';
import pluralize from '../../../utils/pluralize';

/**
 * group has a results array that contains errors array
 * which is array of error messages.
 * We determine whether the feed has errors or not based on the existence of errors array
 *
 * @param result
 * @returns {boolean}
 */
export const isError = result => {
  return !!(result.errors || []).length;
};

/**
 * As of now, it is guaranteed that error message will have one colon ':'
 * as the title of the error and following description after
 * this method is to extract the error title
 *
 * it also de-duplicates the titles and add the count
 * the mapping is pretty slow, O(n²) ... but we are not dealing w/ millions of them
 *
 * @param errors array
 * @returns {string|*}
 */
export const constructErrorsText = errors => {
  if (errors.length) {
    const mapped = [...new Set(errors.map(e => e.split(':')[0]))].map(x => {
      const count = errors.filter(e => e.split(':')[0] === x).length;
      return x + `${count > 1 ? '(' + count + ')' : ''}`;
    });

    return mapped.join(', ');
  } else return errors;
};

/**
 * Used for details aside panel
 * in "foo:bar" => out { title: 'foo', 'description': 'bar' }
 *
 * @param errors
 * @returns {null|[]}
 */
export const constructIssueDescriptions = errors => {
  if (errors.length) {
    const issues = [];
    errors.forEach(e => {
      const title = e.split(':')[0] || 'Unknown';
      const description = e.split(':')[1] || 'Unknown';
      issues.push({ title, description });
    });
    return issues;
  } else return null;
};

/**
 * Formats two dates e.g.)
 * in "date iso string" => out "12/3/4 - 12/6/7"
 *
 * @param start
 * @param end
 * @returns {string}
 */
export const formatDates = (start, end) =>
  `${moment(start).format('l')} - ${moment(end).format('l')}`;

/**
 *
 * This method does not return anything rather embed
 * a few properties that can be passed to presentational component
 *
 * As of now we only care about whether the feed has errors or not
 * no warnings or successes are surfaced
 *
 *
 * @param groups
 * @param danger
 * @param disabled
 */
export const mapGroupsData = (groups, danger, disabled) => {
  groups.forEach(g => {
    const errors = g.results.filter(r => isError(r));

    g.id = g.group.id;
    g.groupName = g.group.name;
    g.tableData = errors.map(r => ({
      id: g.id + r.name,
      feedName: r.name,
      description: constructErrorsText(r.errors),
    }));
    g.tableDataForDetailAside = errors.map(r => ({
      id: g.id + r.name,
      name: r.name,
      descriptions: constructIssueDescriptions(r.errors) || [],
      regexPrefix: r.regex,
      date: formatDates(g.group.created, g.group.lastModified),
      recipients: g.group.recipients,
    }));
    g.counts = [
      {
        text: `${pluralize(errors.length, 'Issue')}`,
        count: errors.length,
        danger: danger,
        disabled: disabled,
      },
    ];
  });
};

/**
 *
 * This is total error sum minus decryption failure count
 * This calculation is necessary as decryption failures are not included in the file delivery table
 *
 * @param evalGroup - evaluation group object
 * @returns {number}
 */
export const getTotalErrorCount = evalGroup => {
  const { errors = 0, decryptionFailures = [] } = evalGroup;
  return errors - decryptionFailures.length;
};

/**
 *
 * This invokes mapGroupsData after filtering groups with or without errors
 * and returns the processed data
 *
 * getTotalErrorCount computes the total error count
 *
 * @param data
 * @param forGroupsWithError
 * @param mapGroups
 * @returns {*[]}
 */
export const processGroupsData = ({
  data = [],
  forGroupsWithError,
  mapGroups = mapGroupsData,
}) => {
  let groups = [];
  if (forGroupsWithError) {
    groups = [...data.filter(g => !!getTotalErrorCount(g))];
    mapGroups(groups, true, false);
  } else {
    groups = [...data.filter(g => !getTotalErrorCount(g))];
    mapGroups(groups, false, true);
  }
  return groups;
};
