import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Anchor, Checkbox } from '../index';
import { capitalizeFirstLetter } from 'common';
import AnimateHeight from 'react-animate-height';
import quickFilterPropTypeDefaults from './propTypes';

/**
 *
 * @param handleOnClick {Function}
 * @param count {String}
 * @returns {*}
 * @constructor
 */
export const DropdownButton = ({ handleOnClick, count = '' }) => {
  return (
    <div
      className={styles.button}
      role="button"
      onClick={handleOnClick}
      data-cy="qf-dropdown"
    >
      <div className={styles.buttonInner}>
        <span>
          <i className="material-icons icon-layers" />
        </span>
        <p>{count}</p>
      </div>
    </div>
  );
};

DropdownButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  count: PropTypes.string,
};

/**
 *
 * @param data {Array}
 * @param handleCheckboxOnChange {Function}
 * @param handleSetThisGroupOnly {Function}
 * @param handleSetLocalStorage
 * @param showRememberFilterButton
 * @returns {*}
 * @constructor
 */
const GroupQuickFilter = ({
  data = [],
  handleCheckboxOnChange,
  handleSetThisGroupOnly,
  handleSetLocalStorage,
  showRememberFilterButton,
}) => {
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState('');
  const [isOnlyOneLeft, setIsOnlyOneLeft] = useState(false);

  // handles calculating count
  useEffect(() => {
    const total = data.length;
    const enabled = data.filter(d => d.enabled).length;

    if (enabled === 1) setIsOnlyOneLeft(true);
    else if (enabled > 1) setIsOnlyOneLeft(false);

    if (total) setCount(`${enabled} / ${total}`);
  }, [data]);

  // handles auto-closing
  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = e => {
    e.stopPropagation();
    if (!dropdownRef?.current?.contains(e.target)) setIsOpen(false);
  };

  const toggleDropdown = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const enabledOptions = data.filter(group => group.enabled);

  return (
    <div className={styles.wrapper} ref={dropdownRef}>
      <DropdownButton handleOnClick={e => toggleDropdown(e)} count={count} />
      <ul className={styles.dropdownRow}>
        <div
          className={
            showRememberFilterButton ? styles.rememberFiltersOpen : null
          }
        >
          <AnimateHeight
            height={isOpen ? 'auto' : 0}
            duration={250}
            data-cy="qf-heightwrapper"
          >
            <div className={styles.checkboxWrapper}>
              <Checkbox
                color="primaryLight"
                onChange={() => handleCheckboxOnChange('checkall')}
                label={<span className={styles.allSelected}>All Groups</span>}
                style={{
                  display:
                    enabledOptions.length === data.length ? 'none' : 'block',
                }}
              />
              {data.map(d => (
                <li key={d.id} data-cy="qf-choice">
                  <Checkbox
                    color="primaryLight"
                    onChange={() => handleCheckboxOnChange(d.id)}
                    checked={d.enabled || false}
                    disabled={d.enabled && isOnlyOneLeft}
                    label={
                      <span className={styles.checkboxLabel}>
                        {capitalizeFirstLetter(d.name)}
                      </span>
                    }
                  />

                  <span className={styles.spacer} />
                  <Anchor
                    tag="a"
                    role="button"
                    data-cy="qf-choice-button"
                    className={styles.thisGroupOnly}
                    onClick={() => handleSetThisGroupOnly(d.id)}
                  >
                    This Group Only
                  </Anchor>
                </li>
              ))}
            </div>
            {showRememberFilterButton && (
              <div className={styles.rememberContainer}>
                <span className={styles.border} />
                <div
                  className={styles.rememberButton}
                  role="button"
                  onClick={handleSetLocalStorage}
                >
                  Remember Groups Settings
                </div>
              </div>
            )}
          </AnimateHeight>
        </div>
      </ul>
    </div>
  );
};

GroupQuickFilter.propTypes = quickFilterPropTypeDefaults;

export default GroupQuickFilter;
