import { MultiRecordMetadataSummary } from '../MultiRecordMetadataSummary';
import { MultiRecordViewContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../../utils/RouteHelper';

export function mapProps({ match, multiRecordPatientMetadataStore }) {
  return {
    personType: multiRecordPatientMetadataStore.personType,
    metadataTypes: multiRecordPatientMetadataStore.metadataTypes,
    match,
  };
}

export const MultiRecordPatientMetadataSummaryContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordMetadataSummary,
  })
);
