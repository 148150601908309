import React from 'react';
import { observer } from 'mobx-react';

import BulkCaseActionBase from '../BulkCaseActionBase';
import BulkCaseActionFormActions from '../BulkCaseActionFormActions';
import OwnerSelect from '../../../case/assignResolve/OwnerSelect';

const ChangeCaseOwner = observer(
  class extends BulkCaseActionBase {
    /**
     * Set the case owner to a new value. This is the onClick handler for
     * this form. Pulls the data from the form and calls bulk action method from
     * the associated store.
     * @return {undefined}
     */
    onSubmit = e => {
      e.preventDefault();
      const { store } = this.props;

      if (store.newOwner.toLowerCase() === 'unassigned') {
        store.performBulkAction('unassign');
      } else {
        store.performBulkAction('assign', {
          newOwnerId: store.newOwner,
        });
      }
    };

    /**
     * Handle the owner value change. This is the onChange callback handler
     * for the dropdown menu.
     * @param {String} val    - the new value being set
     * @return {undefined}
     */
    onChange = val => {
      const { store } = this.props;
      this.dirty = val !== store.newOwner;
      store.newOwner = val;
    };

    render() {
      const { store } = this.props;
      const { performingBulkAction } = store;

      return (
        <form className="form" onSubmit={this.onSubmit}>
          <ul>
            <li>
              <OwnerSelect
                placeholder="Select a Case Owner"
                onChange={this.onChange}
                disabled={performingBulkAction}
              />
            </li>
            <BulkCaseActionFormActions
              store={store}
              buttonActionType="assign"
              hidden={!this.dirty}
              helpText="This action will change the Case Owner for all selected cases and cannot be undone."
              isList
            />
          </ul>
        </form>
      );
    }
  }
);

ChangeCaseOwner.displayName = 'ChangeCaseOwner';

export default ChangeCaseOwner;
