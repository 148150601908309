import { useEffect, useState } from 'react';
import ResizeWatcher from '../../../utils/ResizeWatcher';

export function useContainerDimension(containerElementRef) {
  const [containerWidth, setContainerWidth] = useState(1);
  const [containerHeight, setContainerHeight] = useState(1);

  useEffect(() => {
    if (!containerElementRef?.current) return;

    const setDimensions = () => {
      setContainerWidth(containerElementRef?.current?.offsetWidth);
      setContainerHeight(containerElementRef?.current?.offsetHeight);
    };

    setDimensions();
    const resizeWatcher = new ResizeWatcher(
      containerElementRef.current,
      setDimensions
    );

    return () => {
      setContainerWidth(0);
      setContainerHeight(0);
      resizeWatcher.off();
    };
  }, [containerElementRef]);

  return { width: containerWidth, height: containerHeight };
}
