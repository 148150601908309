import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PropTypes as mobxPropTypes } from 'mobx-react';

import { Pandy, ScrollPager } from 'common';
import { Loader, StaggeredList } from '../../ui';
import PatientResultsActionNav from '../PatientResultsActionNav';
import AdvancedPatientSearchForm from '../AdvancedPatientSearchForm';
import styles from './index.module.scss';
import PatientCardContainer from '../../people/cards/PatientCardContainer';
import { useReactiveRef } from '../../utils/hooks';

const PatientResults = ({
  results,
  size,
  isMaxed,
  hasMore,
  failure,
  loading,
  isAdvancedSearchActive,
  areResultsFromAdvancedSearch,
  searchTerm,
  onToggleAdvancedSearch,
  searchFields,
  onSearchFieldChange,
  onSubmitAdvancedSearch,
  requiredAdvancedFiltersExist,
  pagingStore,
  onUnmount,
}) => {
  const [scrollerElement, refForScroller] = useReactiveRef();

  // eslint-disable-next-line
  useEffect(() => onUnmount, []);

  return (
    <div className={styles.wholeLayout}>
      <AdvancedPatientSearchForm
        isActive={isAdvancedSearchActive}
        onClose={onToggleAdvancedSearch}
        onSubmit={onSubmitAdvancedSearch}
        searchFields={searchFields}
        onSearchFieldChange={onSearchFieldChange}
        requiredAdvancedFiltersExist={requiredAdvancedFiltersExist}
      />

      <div className={styles.resultsLayout}>
        <PatientResultsActionNav
          size={size}
          isMaxed={isMaxed}
          loading={loading}
          isAdvancedSearchActive={isAdvancedSearchActive}
          onToggleAdvancedSearch={onToggleAdvancedSearch}
          areResultsFromAdvancedSearch={areResultsFromAdvancedSearch}
          searchTerm={searchTerm}
        />

        <div ref={refForScroller}>
          <ScrollPager.Component elt={scrollerElement} store={pagingStore} />
          {failure}
          <Loader loaded={!loading}>
            <Pandy visible={!loading && size === 0} mood="happy">
              <div className="speechBubble">
                <span>No matches. Sorry!</span>
              </div>
            </Pandy>
            <div
              className={classnames(
                isAdvancedSearchActive
                  ? styles.skinnier
                  : 'col-xs-10 col-xs-offset-1',
                styles.listWrapper
              )}
            >
              <StaggeredList
                className="card_holder"
                drag={5}
                useSmallPandy
                pandyHasMore={Boolean(hasMore)}
                pandyText="Matching Patients"
                toAdd="fadeIn"
                topOfListScrollValues={{
                  elementToScroll: 'view-content',
                  watchedElements: ['patient'],
                }}
                parentNodeRef={scrollerElement}
              >
                {results.map(patient => (
                  <PatientCardContainer
                    key={patient.id}
                    patient={patient}
                    showAuthInfo
                  />
                ))}
              </StaggeredList>
            </div>
          </Loader>
        </div>
      </div>
    </div>
  );
};

PatientResults.propTypes = {
  results: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({}))
    .isRequired,
  size: PropTypes.number,
  isMaxed: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  failure: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  isAdvancedSearchActive: PropTypes.bool.isRequired,
  areResultsFromAdvancedSearch: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string,
  onToggleAdvancedSearch: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  searchFields: PropTypes.shape({}).isRequired,
  onSearchFieldChange: PropTypes.func.isRequired,
  onSubmitAdvancedSearch: PropTypes.func.isRequired,
  requiredAdvancedFiltersExist: PropTypes.bool.isRequired,
  pagingStore: PropTypes.shape({}).isRequired,
};

PatientResults.defaultProps = {
  size: 0,
  failure: null,
  searchTerm: '',
};

export default PatientResults;
