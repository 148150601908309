import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const CaseIntegrationSource = ({ text, arrowBack = true }) => (
  <div className={styles.caseIntegrationIcon}>
    <i
      className={classnames(
        'material-icons',
        { 'icon-arrow_back': arrowBack },
        { 'icon-arrow_forward': !arrowBack }
      )}
    ></i>
    {text}
  </div>
);

CaseIntegrationSource.propTypes = {
  text: PropTypes.string,
  arrowBack: PropTypes.bool,
};

export default CaseIntegrationSource;
