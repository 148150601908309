import React, { createRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, decorate, observable } from 'mobx';
import $ from 'jquery';
import queryString from 'query-string';

import { GroupStore } from 'common';

import Store from '../../stores/PrivacyStatementStore';
import ByTheNumbers from '../../ByTheNumbers';

import TopNavBar from '../../../navbar/TopNavBar';
import NavBarItem from '../../../navbar/NavBarItem';
import PromptWrapper from '../../../ui/PromptWrapper';
import Tooltip from '../../../ui/Tooltip';
import ResizeWatcher from '../../../utils/ResizeWatcher';

const IconItemInner = ({ icon, onClick, innerRef, ...props }) => (
  <NavBarItem {...props} ref={innerRef}>
    <i onClick={onClick} className={`material-icons ${icon}`} />
  </NavBarItem>
);
IconItemInner.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  innerRef: PropTypes.func,
};
IconItemInner.defaultProps = {
  innerRef: null,
};

const forwardRefIcon = (props, ref) => (
  <IconItemInner {...props} innerRef={ref} />
);

const IconItem = forwardRef(forwardRefIcon);

const PrivacyStatementOverview = observer(
  class PrivacyStatementOverview extends React.Component {
    static propTypes = {
      history: PropTypes.object,
      match: PropTypes.shape({
        params: PropTypes.shape({
          statementId: PropTypes.string,
        }),
      }),
    };
    // Observable
    dirtyNoteMap = {};

    // NOT Observable
    disposers = [];

    resultsWrapperRef = createRef();

    componentDidMount() {
      this.onResize();
      const watcher = new ResizeWatcher(
        this.resultsWrapperRef.current,
        this.onResize
      );
      this.disposers.push(() => watcher.off());
      Store.resultWrapperOffset = $('#by_the_numbers__wrapper').height() + 120;
    }

    componentDidUpdate(prevProps) {
      if (
        this.props.match.params.statementId !==
        prevProps.match.params.statementId
      ) {
        this.dirtyNoteMap = {};
      }
    }

    componentWillUnmount() {
      this.disposers.forEach(d => d());
      this.dirtyNoteMap = {};
    }

    onNoteEdit = (chart, dirty) => {
      this.dirtyNoteMap = { ...this.dirtyNoteMap, [chart]: dirty };
    };

    onResize = () => {
      const width =
        this.resultsWrapperRef && this.resultsWrapperRef.current.clientWidth;
      if (width) Store.chartWidth = width;
    };

    // Computed
    get notesDirty() {
      return Object.values(this.dirtyNoteMap).filter(Boolean).length > 0;
    }

    render() {
      const { statementId } = this.props.match.params;

      // Hacks to compute the selected groups from the query string, and generate the group subtitle header if necessary.
      const qs = queryString.parse(this.props.history.location.search);
      const selectedGroups = qs.group;
      const selectedGroupNames = !selectedGroups
        ? []
        : GroupStore.groups
            .filter(group => selectedGroups.includes(group.id))
            .map(group => group.name)
            .sort();
      const zeroOrAllGroups =
        !selectedGroups || // No groups on QS
        qs.group.length === GroupStore.groups.length || // All user's groups selected
        GroupStore.groups.length === 0; // No groups assigned to user
      const subtitle = `For ${selectedGroupNames.join(', ')}`;

      /**
       * We use keys on chart components to be sure that they are re-rendered
       * when statements change. That way no dirty state is carried between
       * statement changes.
       */
      const makeKey = name => `${name}${statementId}`;

      return (
        <section>
          <TopNavBar actionBar>
            <Tooltip content="Print" placement="left">
              <IconItem
                className="float-right icon prot-a"
                icon="icon-print"
                onClick={() => window.print()}
              />
            </Tooltip>
          </TopNavBar>
          <div ref={this.resultsWrapperRef}>
            <ByTheNumbers
              key={makeKey('ByTheNumbers')}
              currentStatement={Store.currentStatement}
              prevStatement={Store.prevStatement}
              onNoteEdit={this.onNoteEdit}
              allGroups={zeroOrAllGroups}
              subtitle={subtitle}
              note={Store?.currentStatement?.notesByChart?.ByTheNumbers}
              saveNote={note => Store.saveNote('ByTheNumbers', note)}
              removeNote={() => Store.removeNote('ByTheNumbers')}
              groupPHCStats={Store.groupPHCStats}
              noGroupPHCStats={Store.noGroupPHCStats}
              globalPHCStats={Store.globalPHCStats}
              prevStartDate={Store.prevStartDate}
              prevEndDate={Store.prevEndDate}
            />
          </div>
          <PromptWrapper when={this.notesDirty} />
        </section>
      );
    }
  }
);

decorate(PrivacyStatementOverview, {
  dirtyNoteMap: observable,
  notesDirty: computed,
});

PrivacyStatementOverview.displayName = 'PrivacyStatementOverview';

export default PrivacyStatementOverview;
