import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ValueOrUnknown from '../ValueOrUnknown';
import formattedAge from '../../../utils/formattedAge';

const FormattedDobAndAge = ({ dateOfBirth, deceased, dateOfDeath }) => {
  if (!dateOfBirth) return <ValueOrUnknown />;

  const age = formattedAge({
    dateOfBirth,
    deceased,
    dateOfDeath,
  });

  // dateOfBirth is always stored as UTC midnight.
  const m = moment(dateOfBirth).utc();
  const isDeceased = deceased || dateOfDeath;

  /* If person is deceased & date of death is unknown, do not display age. */
  if (isDeceased && !dateOfDeath) {
    return (
      <p>
        {m.format('l')}
        {isDeceased && <sup>&nbsp;(deceased)</sup>}
      </p>
    );
  }

  const ageLine = (
    <>
      {m.format('l')} : {age}
    </>
  );

  const deceasedLine = isDeceased && <sup>&nbsp;(deceased)</sup>;

  return (
    <p>
      {ageLine}
      {deceasedLine}
    </p>
  );
};

FormattedDobAndAge.propTypes = {
  dateOfBirth: PropTypes.string,
  deceased: PropTypes.bool,
  dateOfDeath: PropTypes.string,
};

FormattedDobAndAge.defaultProps = {
  dateOfBirth: undefined,
  deceased: false,
  dateOfDeath: undefined,
};

export default FormattedDobAndAge;
