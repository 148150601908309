import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import { nodeIsType } from '../../utils/ComponentUtils';
import classnames from 'classnames';
import styles from './index.module.scss';

const Header = ({ children }) => children;

const Section = ({ children }) => children;

const ExpandCollapse = ({
  children,
  onExpand,
  onCollapse,
  useArrow = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!Array.isArray(children)) children = [children];
  const header = children.find(node => nodeIsType(node, ExpandCollapse.Header));
  const section = children.find(node =>
    nodeIsType(node, ExpandCollapse.Section)
  );

  const toggleExpandCollapse = e => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);

    if (!isExpanded && onExpand) onExpand();
    else if (isExpanded && onCollapse) onCollapse();
  };

  return (
    <div>
      <span
        className={styles.header}
        role="button"
        tabIndex="0"
        onClick={e => toggleExpandCollapse(e)}
      >
        {header}
        {useArrow ? (
          <i
            className={classnames(
              'material-icons',
              isExpanded ? 'icon-expand_less' : 'icon-expand_more'
            )}
          />
        ) : null}
      </span>
      {section && (
        <AnimateHeight height={isExpanded ? 'auto' : 0} duration={250}>
          {section}
        </AnimateHeight>
      )}
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
};

ExpandCollapse.propTypes = {
  useArrow: PropTypes.bool,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default ExpandCollapse;

ExpandCollapse.Header = Header;
ExpandCollapse.Section = Section;
