import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './index.module.scss';
import SuspicionRow from './SuspicionRow';
import { dateContent } from '../../ui/SuspicionScore';
import { getResolutionDisplay } from '../../utils/resolutionDescriptions';

const CaseViewUserCases = ({ userCases }) => {
  const sortedCases = userCases
    .sort((a, b) => moment(a.maxEventDate).diff(b.maxEventDate))
    .reverse();

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Time Range</th>
            <th>Suspicion</th>
            <th>Resolution</th>
          </tr>
        </thead>
        <tbody>
          {sortedCases.map(c => (
            <tr key={c.id}>
              <td>
                <Link to={`/case/${c.id}`}>
                  {dateContent(
                    moment(c.minEventDate),
                    moment(c.maxEventDate),
                    'l'
                  )}
                </Link>
              </td>
              <td>
                <Link to={`/case/${c.id}`}>
                  <SuspicionRow analyticAssessments={c.analyticAssessments} />
                </Link>
              </td>
              <td>
                <Link to={`/case/${c.id}`}>{getResolutionDisplay(c)}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

CaseViewUserCases.propTypes = {
  userCases: MobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      minEventDate: PropTypes.string,
      maxEventDate: PropTypes.string,
      analyticAssessments: MobxPropTypes.arrayOrObservableArrayOf(
        PropTypes.shape({
          suspicionScore: PropTypes.number,
          minEventDate: PropTypes.string,
          maxEventDate: PropTypes.string,
        })
      ),
      resolution: PropTypes.string,
    })
  ),
};

CaseViewUserCases.defaultProps = {
  userCases: [],
};

export default CaseViewUserCases;
