import * as d3 from 'd3';

import { RouterContainer } from 'common';

import CaseStore from '../../stores/CaseStore';
import ChartStore from '../../stores/ChartStore';

/**
 * Layer responsible for drawing case triangles below accesses. A sub-component of RowLayer.
 */
class CaseLayer {
  clickedCase(access) {
    const caseId = access.caseId;
    RouterContainer.go(`/case/${caseId}`, {});
  }

  render(selection) {
    const me = this;
    const transition = ChartStore.scrollTransition;

    const cases = selection.selectAll('g.case').data(
      d => CaseStore.rangesByYKey[d.key] || [],
      d => d.id
    );

    this.update(cases, transition);
    const rects = cases
      .enter()
      .append('g')
      .classed('case', true);

    rects
      .append('path')
      .attr('d', d3.symbol().type(d3.symbolTriangle))
      .attr('data-tippy-content', 'View Case')
      .attr('data-tippy-placement', 'bottom')
      .attr('data-tippy-animation', 'fade')
      .attr('data-tippy-arrow', 'true')
      .on('click', d => {
        d3.event.stopPropagation();
        me.clickedCase(d);
      })
      .classed('violation', d => d.resolution === 'VIOLATION')
      .classed('not-violation', d => d.resolution === 'NOT_VIOLATION');

    this.update(rects, false);

    cases.exit().remove();
  }

  update(selection, transition) {
    transition = transition !== false;

    if (transition) selection = selection.transition().duration(200);
    selection.attr('transform', d => {
      const date = d.dateOfEvent;
      const x = ChartStore.brushedXScale(date) + ChartStore.margin.left;
      const y = (ChartStore.rowHeight * 3) / 3.4;
      const trans = `translate(${x},${y})`;
      return trans;
    });
  }
}

export default new CaseLayer();
