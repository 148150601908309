import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

/**
 * Spinning element - literally just the piece that spins. Should be used as
 * part of another component to indicate that something is processing (like the
 * Loader component). Stateless component.
 *
 * Styles are set in the spinner.scss file. Will apply any props passed to it
 * to the outermost container.
 */
export default class Spinner extends Component {
  circle = React.createRef();

  static propTypes = {
    big: PropTypes.bool,
    dark: PropTypes.bool,
  };

  static defaultProps = {
    big: false,
    dark: false,
  };

  componentDidMount() {
    const offsetMax = 80;

    let offset = 1;
    let incrementing = true;

    const offsetMe = () => {
      if (offset === offsetMax) {
        incrementing = false;
      } else if (offset === 0) {
        incrementing = true;
      }

      incrementing ? (offset += 1) : (offset -= 1);
      this.circle.current.style.strokeDashoffset = offset;

      this.animation = requestAnimationFrame(offsetMe);
    };

    offsetMe();
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animation);
  }

  animation = null;

  render() {
    const { big, dark, ...other } = this.props;

    const cn = classnames('spinner', { big, dark });
    return (
      <div className={cn} {...other}>
        <div className="spinner-inner" data-cy="spinner-inner">
          <svg xmlns="http://www.w3.org/2000/svg">
            <circle
              ref={this.circle}
              cx="50%"
              cy="50%"
              r="40%"
              fill="rgba(0,0,0,0)"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export const ArrowSpinner = () => {
  return (
    <i
      className={classnames('material-icons icon-sync', styles.arrowSpinner)}
    />
  );
};
