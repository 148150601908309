import React, { useEffect } from 'react';
import { Observer, PropTypes as mobxPropTypes } from 'mobx-react';
import { HalUtils, PermissionStore } from 'common';
import PatientCard from '../PatientCard';
import PatientAuthorizationsStore from '../../../stores/PatientAuthorizationsStore';
import PatientVipStore from '../../../stores/PatientVipStore';
import PropTypes from 'prop-types';
import { withFlags } from '../../../ui/Flagged';

export function mapProps({
  disabled,
  onSelect,
  openLinkNew,
  PatientAuthorizationsStore,
  PatientVipStore,
  patient,
  PermissionStore,
  selectable,
  selected,
  showAuthInfo,
}) {
  const id = HalUtils.getId(patient);
  const vipData = PatientVipStore.infoById.get(id)?.slice();

  return {
    canCreateAuths: PermissionStore.has('ACCESS_AUTH_SEARCH'),
    canViewAuths: PermissionStore.has('ACCESS_AUTH_CREATE'),
    disabled,
    existingAuths: PatientAuthorizationsStore.countById.get(id) > 0,
    openLinkNew,
    onSelect,
    patient,
    registeredVIP: !!vipData?.length,
    selectable,
    selected,
    showAuthInfo,
    vipStatus: vipData && vipData[0]?.status,
  };
}

const PatientCardContainer = ({
  disabled,
  onSelect,
  openLinkNew,
  patient,
  selectable,
  selected,
  showAuthInfo,
}) => {
  const id = HalUtils.getId(patient);

  useEffect(() => {
    PatientAuthorizationsStore.fetch(id);
    PatientVipStore.fetch(id);
  }, [id]);

  const mapPropsInput = {
    disabled,
    onSelect,
    openLinkNew,
    PatientAuthorizationsStore,
    PatientVipStore,
    patient,
    PermissionStore,
    selectable,
    selected,
    showAuthInfo,
  };

  // observe changes of async auth/vip requests
  return (
    <Observer>{() => <PatientCard {...mapProps(mapPropsInput)} />}</Observer>
  );
};

PatientCardContainer.propTypes = {
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  openLinkNew: PropTypes.bool,
  patient: PropTypes.shape({
    addresses: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
      })
    ),
    dateOfBirth: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.string
    ),
    references: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
  }).isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  showAuthInfo: PropTypes.bool,
  flags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PatientCardContainer.defaultProps = {
  disabled: false,
  onSelect: null,
  openLinkNew: false,
  selectable: false,
  selected: false,
  showAuthInfo: false,
};

PatientCardContainer.displayName = 'PatientCardContainer';

export default withFlags(PatientCardContainer);
