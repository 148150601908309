import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownList } from 'react-widgets';

import { CategoryComponent } from 'common';

import Button from '../../ui/Button';
import styles from './index.module.scss';

const CreateCaseForm = ({ categories, onSubmit }) => {
  const [category, setCategory] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        setSubmitted(true);
        onSubmit(category);
      }}
    >
      <h2>Almost There...</h2>
      <p>
        Please select a category for your new case. You can always change this
        later!
      </p>
      <DropdownList
        onChange={c => setCategory(c)}
        data={categories}
        defaultValue={category || { name: 'Select an Option' }}
        textField="name"
        valueField="id"
        valueComponent={CategoryComponent}
        itemComponent={CategoryComponent}
      />
      <Button
        className={styles.marginTop}
        type="submit"
        value={`${submitted ? 'Creating...' : 'Create New Case'}`}
        disabled={!category || submitted}
      />
    </form>
  );
};

CreateCaseForm.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      group: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateCaseForm;
