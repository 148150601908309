export const CREATED = 'created';
export const LAST_MODIFIED = 'lastModified';
export const EVENT_DATE = 'minEventDate';
export const RESOLUTION_DATE = 'resolutionDate';

export const CASES_BY_OPTIONS = [
  { label: 'Case Creation Date', value: CREATED },
  { label: 'Last Modified Date', value: LAST_MODIFIED },
  { label: 'Event Date', value: EVENT_DATE },
  { label: 'Resolution Date', value: RESOLUTION_DATE },
];

export const CASES_STATISTIC_CALCULATION = [
  { label: 'Average', value: 'average' },
  { label: 'Median', value: 'median' },
];

export const TOO_MANY_RESULTS_TEXT =
  'Too many results to display a clear line chart. Use filters to reduce the number of results.';
export const NO_RESULTS_TEXT = 'Nothing to show. Sorry!';
