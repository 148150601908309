/**
 * No-op if the provided argument is null/undefined or an array.
 * Otherwise, turns the provided argument into an array of length one.
 *
 * @param maybeArray
 * @return {Array|null|undefined}
 */
import { isObservableArray } from 'mobx';

export default function(maybeArray) {
  if (maybeArray == null) {
    return maybeArray;
  }

  return Array.isArray(maybeArray) || isObservableArray(maybeArray)
    ? maybeArray
    : [maybeArray];
}
