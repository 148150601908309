export const permissionMap = {
  case: ['CASE_VIEW_OWNED', 'CASE_VIEW'],
  general: ['GENERAL_SETTINGS_MODIFY'],
  template: ['CASE_MODIFY_NOTES_OWNED', 'CASE_SEND_EMAIL_OWNED'],
  appUsers: ['APP_USER_CREATE', 'APP_USER_MODIFY'],
};

export const anyPermission = Object.values(permissionMap).flatMap(
  perms => perms
);
