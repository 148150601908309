import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import { DropdownList } from 'react-widgets';
import styles from './index.module.scss';
import {
  DETAILS_TEXT_FIELD,
  DETAILS_VALUE_FIELD,
  DIVERSION_DETAILS_LABEL,
  PRIVACY_DETAILS_LABEL,
  DIVERSION_CASETYPE,
} from '../stores/CaseCustomPrintStore/consts';

const DetailsPrintOptions = ({
  selectedDetailsOption,
  detailsOptions,
  handleDetailsOnChange,
  caseType,
  setDetailsPrintOptionsForCaseStore,
}) => {
  useEffect(() => {
    setDetailsPrintOptionsForCaseStore(selectedDetailsOption);
    return () => setDetailsPrintOptionsForCaseStore(null);
  }, [setDetailsPrintOptionsForCaseStore, selectedDetailsOption]);

  return (
    <div className={styles.dropdownWrapper}>
      <p className={styles.label}>
        {caseType === DIVERSION_CASETYPE
          ? DIVERSION_DETAILS_LABEL
          : PRIVACY_DETAILS_LABEL}
      </p>
      <DropdownList
        data={detailsOptions}
        textField={DETAILS_TEXT_FIELD}
        valueField={DETAILS_VALUE_FIELD}
        value={selectedDetailsOption}
        onChange={handleDetailsOnChange}
      />
    </div>
  );
};

DetailsPrintOptions.propTypes = {
  selectedDetailsOption: PropTypes.string,
  detailsOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  handleDetailsOnChange: PropTypes.func.isRequired,
  caseType: PropTypes.string,
  setDetailsPrintOptionsForCaseStore: PropTypes.func.isRequired,
};

export default DetailsPrintOptions;
