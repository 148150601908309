import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from 'common';
import { BulkVipContext } from '../Context';
import { BulkVipStore } from '../stores/BulkVipStore';
import BulkVipContainer from '../BulkVipContainer';
import BulkVipListView from '../BulkVipListView';

class BulkVipRoot extends React.Component {
  state = { bulkVipStore: new BulkVipStore() };

  render() {
    return (
      <BulkVipContext.Provider value={this.state}>
        <Switch>
          <ProtectedRoute
            exact
            path="/vips/upload"
            component={BulkVipContainer}
          />
          <ProtectedRoute
            exact
            path="/vips/upload-confirm"
            component={BulkVipListView}
          />
        </Switch>
      </BulkVipContext.Provider>
    );
  }
}

export default BulkVipRoot;
