/**
 *
 * @param selectedPatient
 * @param selectedUser
 * @returns {string}
 */
export const getAddButtonText = ({ selectedPatient, selectedUser }) => {
  let buttonText = 'Make a Selection';

  if (selectedPatient || selectedUser) {
    if (selectedPatient && selectedUser) {
      buttonText = 'Add User and Patient';
    } else if (selectedUser) {
      buttonText = 'Add User';
    } else if (selectedPatient) {
      buttonText = 'Add Patient';
    }
  }

  return buttonText;
};

/**
 *
 * @param selectedPatientId
 * @param selectedUserId
 * @param pathname
 * @returns {string|*}
 */
export const getNextLink = ({
  selectedPatientId,
  selectedUserId,
  pathname,
}) => {
  if (selectedPatientId && selectedUserId) {
    return `${pathname}/patient/${selectedPatientId}/employee/${selectedUserId}`;
  } else if (selectedPatientId && !selectedUserId) {
    return `${pathname}/patient/${selectedPatientId}`;
  } else if (selectedUserId && !selectedPatientId) {
    return `${pathname}/employee/${selectedUserId}`;
  }
  return pathname;
};
