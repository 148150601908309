import IncidentListing from '../IncidentListing';
import { IncidentContext } from '../../ui/IncidentGroupList/Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';

export function mapProps({ incidentReportStore, incidentStateStore }) {
  return {
    hasMore: incidentReportStore.hasMore,
    incidents: incidentReportStore.groupedIncidents,
    loaded: incidentReportStore.doneShowingLoader,
    showSlowLoadingPrompt: incidentReportStore.showSlowLoadingPrompt,
    hasUnsavedNotes: incidentStateStore.hasUnsavedNotes,
    store: incidentReportStore,
  };
}

const IncidentListingContainer = observerWrappedContainerFactory({
  Context: IncidentContext,
  mapProps: mapProps,
  PresentationalComponent: IncidentListing,
});

IncidentListingContainer.displayName = 'IncidentListingContainer';

export default IncidentListingContainer;
