import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '../Tooltip';

const Source = ({ source, instance, className, raw, environment }) => {
  const sourceAndEnv = source + (environment ? `-${environment}` : '');
  const sourceAndMaybeInstance =
    sourceAndEnv + (instance ? ` (${instance})` : '');

  return (
    <span className={classnames({ source: !raw }, className)}>
      <Tooltip content={`Information sourced from ${sourceAndMaybeInstance}`}>
        <i className="material-icons icon-info" />
      </Tooltip>{' '}
      {sourceAndMaybeInstance}
    </span>
  );
};

Source.propTypes = {
  source: PropTypes.string.isRequired,
  instance: PropTypes.string,
  className: PropTypes.string,
  raw: PropTypes.bool,
  environment: PropTypes.string,
};

Source.defaultProps = {
  instance: null,
  className: null,
  raw: false,
  environment: null,
};

export default Source;
