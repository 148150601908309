import React from 'react';
import PropTypes from 'prop-types';
import { CardBadge, CardHeader } from '../../../ui/Card';
import { HalUtils } from 'common';
import Anchor from '../../../ui/Anchor';

const PatientCardHeader = ({
  fullName,
  id,
  instance,
  openLinkNew,
  registeredVIP,
  source,
  vipStatus,
}) => {
  const title = (
    <Anchor
      tag="a"
      href={HalUtils.buildActivityViewLink({ patientId: id, type: 'patient' })}
      target={openLinkNew ? '_blank' : '_self'}
    >
      {fullName}
    </Anchor>
  );

  const vipItems = [
    <CardBadge
      key="badge"
      title="Patient"
      label="patient"
      icon="icon-folder_shared"
    />,
  ];
  if (registeredVIP) {
    vipItems.unshift(
      <CardBadge
        key="registered"
        title={`VIP${vipStatus === 'EXPIRED' ? ' (Expired)' : ''}`}
        label="primary"
        icon="icon-star"
        link={`/vips/${id}`}
      />
    );
  }

  return (
    <CardHeader instance={instance} source={source} title={title}>
      {vipItems}
    </CardHeader>
  );
};

PatientCardHeader.propTypes = {
  fullName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  instance: PropTypes.string,
  openLinkNew: PropTypes.bool,
  registeredVIP: PropTypes.bool,
  source: PropTypes.string,
  vipStatus: PropTypes.string,
};

PatientCardHeader.defaultProps = {
  openLinkNew: false,
  registeredVIP: false,
  vipStatus: null,
};

export default PatientCardHeader;
