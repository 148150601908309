import React from 'react';
import { observer } from 'mobx-react';
import { PermissionStore, RouterContainer } from 'common';
import MetalogStore from '../../stores/MetalogStore';
import EventDetailedStore from '../../stores/EventDetailedStore';
import DiversionActivityStore from '../../stores/DiversionActivityStore';
import Store from '../../stores/Store';
import ActivityOptions from '../ActivityOptions';

export function mapProps({
  PermissionStore,
  RouterContainer,
  MetalogStore,
  EventDetailedStore,
  DiversionActivityStore,
  Store,
}) {
  return {
    storeFocus: Store.focus,
    isUserFocus: Store.isUserFocus,
    storeActiveLabel: Store.activeLabel,
    storeSort: Store.sort,
    patientId: Store.patientId,
    userId: Store.userId,
    getQuery: Store.getQuery,
    downloadCSV: Store.downloadCSV,
    visible: Store.optionsVisible,
    canViewSuspicionScore: PermissionStore.has('PRIVACY_ASSESSMENT_VIEW'),
    canDownloadMedicationAdmin: PermissionStore.has(
      'MEDICATION_ADMINISTRATION_VIEW'
    ),
    noData:
      (!MetalogStore.loading &&
        MetalogStore.filteredMetalogs &&
        MetalogStore.filteredMetalogs.length === 0) ||
      false,
    eventTypes: EventDetailedStore.eventTypes,
    downloadIncidents: DiversionActivityStore.downloadIncidents,
    routerContainerGo: RouterContainer.go,
  };
}

const ActivityOptionsContainer = observer(() => {
  return (
    <ActivityOptions
      {...mapProps({
        PermissionStore,
        RouterContainer,
        MetalogStore,
        EventDetailedStore,
        DiversionActivityStore,
        Store,
      })}
    />
  );
});

export default ActivityOptionsContainer;
