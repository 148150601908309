import React from 'react';
import ListItem from '../SideList/ListItem';
import PropTypes from 'prop-types';

/** Rewrite 'other' map keys to their display counterpart, if necessary. **/
function toDisplay(key) {
  if (key === 'FinancialNumber') return 'Financial Num.';
  else return key;
}

const EncounterOther = ({ encounter }) => {
  if (encounter.other) {
    return Object.entries(encounter.other).map(entry => (
      <ListItem key={entry[0]} label={toDisplay(entry[0])} value={entry[1]} />
    ));
  }
  return null;
};

EncounterOther.propTypes = {
  encounter: PropTypes.object,
};

export default EncounterOther;
