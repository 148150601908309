import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { DropdownList } from 'react-widgets';

const aIUpdatedOption = {
  value: 'AI_UPDATED_AND_RESOLVED',
  label: 'AI Updated and Resolved',
};

export const getOptions = resolution => {
  const options = [
    { value: 'Unresolved', label: 'Unresolved' },
    { value: 'ACCURATE', label: 'Accurate' },
    { value: 'INACCURATE', label: 'Inaccurate' },
    { value: 'PENDING', label: 'Pending' },
  ];

  if (resolution === 'AI_UPDATED_AND_RESOLVED') options.push(aIUpdatedOption);

  return options;
};

const ValueComponent = ({ item }) => {
  return (
    <span>
      <strong>Status</strong>
      {item.label}
    </span>
  );
};

ValueComponent.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
  }),
};

const ResolutionSelect = ({ onReopen, onResolve, resolution }) => {
  const options = getOptions(resolution);

  return (
    <DropdownList
      disabled={options.includes(aIUpdatedOption) ? [aIUpdatedOption] : false}
      className={styles.resolution}
      data={options}
      defaultValue={resolution || 'Unresolved'}
      onChange={selected =>
        selected.value === 'Unresolved' ? onReopen() : onResolve(selected.value)
      }
      valueField="value"
      textField="label"
      valueComponent={ValueComponent}
    />
  );
};

ResolutionSelect.defaultProps = {
  resolution: null,
};

ResolutionSelect.propTypes = {
  onReopen: PropTypes.func.isRequired,
  onResolve: PropTypes.func.isRequired,
  resolution: PropTypes.string,
  flags: PropTypes.arrayOf(PropTypes.string),
};

export default ResolutionSelect;
