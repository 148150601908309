import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from '../Tooltip';

/*
* ✔️✔️✔️ Checkbox UI ✔️✔️✔️
-------------------
* A stateless react component that simply returns DOM elements based on props.
*
* @param {Object}
*  checked: bool - displays check mark if true (required)
*  onChange: func - action when the box is checked or unchecked (required)
*  indeterminate: bool - displays '-' symbol in the box if true (optional)
*  disabled: bool - displays disabled checkbox and removes onClick action (optional)
*  color: string - color of the check box, default is orange (optional)
*  tabIndex: number - tab index number for keyboard control (optional)
*  tooltip: string - tooltip msg if tooltip is needed on checkbox (optional)
*  label: string - tooltip label (optional)
*
*  NOTES:
*  - if you want to pass a DOM obj as a label (eg. `<h1>Styled label</h1>`), just wrap it around the component
*  - if multiple props are passed as true (eg. checked and indeterminate are both true, which shouldn't happen)
*    the component will log an error and return null
*
* @return {Object} Checkbox DOM
*/

const Checkbox = ({
  checked,
  indeterminate,
  disabled,
  color,
  tabIndex,
  onChange,
  tooltip,
  label,
  style,
  ...other
}) => {
  if (checked === true && indeterminate === true) {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        'Oops, a checkbox can only have one state at a time. Check the data!'
      );
    }
    return null;
  }

  const iconClasses = classnames('material-icons', {
    'icon-check_box': checked,
    'icon-indeterminate_check_box': !checked && indeterminate,
    'icon-check_box_outline_blank': !checked && !indeterminate,
    disabled: disabled,
    primary: color === 'primary',
    primaryLight: color === 'primaryLight',
    secondary: color === 'secondary',
    warning: color === 'warning',
  });

  if ('string' === typeof label) {
    label = <span>{label}</span>;
  }

  return (
    <span
      role="checkbox"
      className="checkbox-base"
      tabIndex={isNaN(tabIndex) ? undefined : tabIndex.toString()}
      onKeyPress={
        isNaN(tabIndex) ? undefined : ({ key }) => key === ' ' && onChange()
      }
      aria-checked={indeterminate ? 'mixed' : checked ? 'true' : 'false'}
      onClick={disabled ? undefined : onChange}
      style={style}
    >
      {tooltip ? (
        <Tooltip content={tooltip}>
          <i className={iconClasses} />
        </Tooltip>
      ) : (
        <i className={iconClasses} />
      )}
      {label}
      {/* Use below (other props) sparingly */}
      {Object.prototype.hasOwnProperty.call(other, 'children') ? (
        <span {...other} />
      ) : null}
    </span>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  color: PropTypes.string,
  tabIndex: PropTypes.number,
  tooltip: PropTypes.string,
  label: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Checkbox;
