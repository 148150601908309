import { computed, decorate, observable, reaction, action, toJS } from 'mobx';
import $ from 'jquery';
import moment from 'moment';

import {
  PagedStore,
  UserClient,
  PatientClient,
  CaseClient,
  CaseLogClient,
  AuthorizationClient,
  PermissionStore,
} from 'common';

const PAGE_SIZE = 20;
const MAX_ELEMENTS = 1000;

const USER = 'user';
const PATIENT = 'patient';
const CASE = 'case';
const COMMENT = 'comment';
const AUTHORIZATION = 'authorization';

class SearchStore extends PagedStore {
  constructor() {
    super();

    reaction(
      () => [this.criteria, this.sort],
      () => {
        this.refresh();
      }
    );
  }

  // Observable
  criteria = null;
  sort = 'score,desc';

  // NOT Observable
  pageSize = PAGE_SIZE;

  // computed
  get maxed() {
    return this.totalElements >= MAX_ELEMENTS;
  }

  postProcess(item) {
    item.type = this.type;
    return item;
  }
}

decorate(SearchStore, {
  criteria: observable,
  sort: observable,
  maxed: computed,
});

class Users extends SearchStore {
  type = USER;

  fetch() {
    if (this.criteria)
      return UserClient.search(
        this.criteria,
        this.sort,
        MAX_ELEMENTS,
        this.pageSize
      );
    return [];
  }
}
const users = new Users();

export class Patients extends SearchStore {
  type = PATIENT;

  // Observables
  isAdvancedSearchActive = false;
  areResultsFromAdvancedSearch = false;
  searchFields = {};

  // Computeds
  get requiredAdvancedFiltersExist() {
    return ['mrn', 'patientId', 'lastName']
      .map(field => this.searchFields[field])
      .map(value => (value || '').trim())
      .some(value => value);
  }

  get advancedFiltersForQuery() {
    const map = {
      mrn: 'medicalRecordNumbers',
      address1: 'addresses.address1',
      address2: 'addresses.address2',
      city: 'addresses.city',
      state: 'addresses.state',
      zip: 'addresses.zip',
    };
    return Object.keys(toJS(this.searchFields)).reduce((filters, field) => {
      let value = this.searchFields[field] || '';
      if (value.trim) value = value.trim();
      if (!value) return filters;

      if (field === 'dateOfBirth') {
        if (value instanceof Date)
          value = moment
            .utc(value)
            .startOf('day')
            .toISOString();
        else return filters;
      }

      if (map[field]) filters[map[field]] = value;
      else filters[field] = value;
      return filters;
    }, {});
  }

  constructor() {
    super();

    reaction(
      () => [this.criteria],
      () => this.resetAdvancedFilters()
    );
  }

  fetch() {
    if (this.requiredAdvancedFiltersExist && this.isAdvancedSearchActive) {
      this.areResultsFromAdvancedSearch = true;
      return PatientClient.searchWithAdvancedFilters(
        this.advancedFiltersForQuery,
        MAX_ELEMENTS,
        this.pageSize
      );
    }

    this.areResultsFromAdvancedSearch = false;
    if (this.criteria)
      return PatientClient.search(
        this.criteria,
        this.sort,
        MAX_ELEMENTS,
        this.pageSize
      );

    return [];
  }

  // Actions
  toggleAdvancedSearchActive = () => {
    const wasClosed = !this.isAdvancedSearchActive;
    this.isAdvancedSearchActive = !this.isAdvancedSearchActive;

    // Refresh if we are not moving from text criteria results to advanced panel
    // for the first time.
    if (wasClosed && !this.requiredAdvancedFiltersExist) return;
    this.refresh();
  };

  ensureAdvancedSearchClosed = () => {
    if (this.isAdvancedSearchActive) {
      this.toggleAdvancedSearchActive();
    }
  };

  resetAdvancedFilters = () => {
    this.searchFields = {};
    this.isAdvancedSearchActive = false;
    this.areResultsFromAdvancedSearch = false;
  };

  setSearchField = (field, value) => {
    this.searchFields = { ...this.searchFields, [field]: value };
  };

  searchWithAdvancedFilters = () => {
    if (this.requiredAdvancedFiltersExist) this.refresh();
  };
}
decorate(Patients, {
  isAdvancedSearchActive: observable,
  areResultsFromAdvancedSearch: observable,
  searchFields: observable,
  requiredAdvancedFiltersExist: computed,
  advancedFiltersForQuery: computed,
  toggleAdvancedSearchActive: action,
  ensureAdvancedSearchClosed: action,
  resetAdvancedFilters: action,
  setSearchField: action,
  searchWithAdvancedFilters: action,
});
const patients = new Patients();

class Cases extends SearchStore {
  type = CASE;

  fetch() {
    if (
      this.criteria &&
      (PermissionStore.has('CASE_SEARCH') ||
        PermissionStore.has('CASE_SEARCH_OWNED'))
    )
      return CaseClient.search(
        this.criteria,
        this.sort,
        MAX_ELEMENTS,
        this.pageSize
      );
    return [];
  }
}
const cases = new Cases();

class Comments extends SearchStore {
  type = COMMENT;

  fetch() {
    if (this.criteria && PermissionStore.has('CASE_SEARCH')) {
      return CaseLogClient.search(
        this.criteria,
        this.sort,
        MAX_ELEMENTS,
        this.pageSize
      );
    }
    return [];
  }
}
const comments = new Comments();

class Authorizations extends SearchStore {
  type = AUTHORIZATION;

  fetch() {
    if (this.criteria && PermissionStore.has('ACCESS_AUTH_SEARCH_ALL')) {
      return AuthorizationClient.search(
        this.criteria,
        this.sort,
        this.pageSize
      );
    }
    return [];
  }

  postProcess(item) {
    item.authorizationType = item.type;
    item.type = this.type;
    return item;
  }
}
const authorizations = new Authorizations();

const stores = [users, patients, authorizations, comments, cases];

class SearchAllStore {
  // Observable
  criteria = null;
  sort = ['score,desc', 'lastName,asc'];
  type = 'users';

  // Computed
  get store() {
    switch (this.type) {
      case 'patients':
        return patients;
      case 'cases':
        return cases;
      case 'comments':
        return comments;
      case 'authorizations':
        return authorizations;
      default:
        return users;
    }
  }

  setCriteria(criteria) {
    const decodedCriteria = this.getQueryParam(criteria);
    this.criteria = criteria;
    stores.forEach(s => {
      s.criteria = decodedCriteria || criteria;
    });
  }

  setSort(sort) {
    this.sort = sort;
    stores.forEach(s => {
      s.sort = sort;
    });
  }

  /**
   * Retrieve all of the IDs for a specific case query (as defined by the
   * params). This is used primarily for building up the list necessary for a
   * bulk case action.
   * @param {Number} pageSize - (optional) pageSize value that can override the default.
   * @return {Promise}        - an XmlHttpRequest Promise Object.
   */
  fetchIds(pageSize) {
    pageSize = typeof pageSize === 'number' ? pageSize : 10000;
    const decodedCriteria = this.getQueryParam(this.criteria);
    return CaseClient.search(
      decodedCriteria,
      this.sort,
      MAX_ELEMENTS,
      pageSize
    );
  }

  get users() {
    return users;
  }

  get patients() {
    return patients;
  }

  get cases() {
    return cases;
  }

  get comments() {
    return comments;
  }

  get authorizations() {
    return authorizations;
  }

  refresh() {
    stores.forEach(s => {
      s.refresh();
    });
  }

  /**
   * @param  {number} key      Epoch millis of when then search criteria string was sent
   * @param  {string} value    The search criteria string entered by the user
   * @return {Object}          A storage Map object which can be used to access the search history
   */
  setQueryParam = (key, value) => {
    let map = new Map();
    try {
      map = new Map(JSON.parse(sessionStorage.getItem('search')));
    } catch (e) {
      // set with default empty map
    }
    if (key && value) map.set(key, value);
    return sessionStorage.setItem(
      'search',
      JSON.stringify(Array.from(map.entries()))
    );
  };

  /**
   * @param  {number} key      epoch millis of when then search criteria string was set, used to access search history
   * @return {string}          The search criteria string from session storage
   */
  getQueryParam = key => {
    let searchHistory = new Map();
    try {
      searchHistory = new Map(JSON.parse(sessionStorage.getItem('search')));
    } catch (e) {
      // do nothing
    }
    const param = searchHistory.get(Number(key));
    if (!searchHistory || !param) return '';
    return param;
  };

  setType = type => {
    this.type = type;
  };

  /**
   * @param  {string} type     The type of search, undefined, user, patient, case
   * @param  {string} criteria (Optional) - The search criteria string entered by the user in the search dialog
   * @return {string}          The route path to use for the search
   */
  path(type, criteria) {
    // default type to whatever is currently set
    type = type || this.type;
    criteria = criteria || this.criteria;

    let path = '/search';
    if (type) path += `/${type}`;
    return (
      `${path}?` +
      $.param(
        {
          criteria,
        },
        true
      )
    );
  }
}

decorate(SearchAllStore, {
  criteria: observable,
  sort: observable,
  type: observable,
  store: computed,
  setType: action,
});

export default new SearchAllStore();
