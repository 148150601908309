import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import CaseStore from '../stores/CaseStore';
import CaseView from '../CaseView';
import PatientEncountersStore from '../../patient/stores/PatientEncountersStore';
import EmployeeActivityStore from '../../employee/stores/EmployeeActivityStore';
import NewAppExperienceStore from '../../stores/NewAppExperienceStore';

export function mapProps({
  CaseStore,
  EmployeeActivityStore,
  PatientEncountersStore,
  match,
}) {
  sessionStorage.setItem('caseId', match.params.caseId);
  return {
    caseId: match.params.caseId,
    setCaseId: CaseStore.setCaseId,
    number: CaseStore.number,
    failureStatus: CaseStore.failureStatus,
    failure: CaseStore.failure,
    result: CaseStore.result,
    patientId: CaseStore.patientId,
    userId: CaseStore.userId,
    caseCreatedDate: CaseStore.created,
    setIdForPatientEncounters: PatientEncountersStore.setPatientId,
    setIdForEmployeeActivity: EmployeeActivityStore.setUserId,
    setPatientEncountersPivotDate: PatientEncountersStore.setTimebase,
    caseDirty: CaseStore.dirty,
    caseStore: CaseStore,
  };
}

const CACHE_KEY = 'privacyCases';
// Set a cap to keep memory usage low for localStorage
const MAX_CACHE_ITEM_COUNT = 1000;

function isValidCacheValue(value) {
  if (!value) return false;

  return Array.isArray(value) && value.every(v => typeof v === 'string');
}

function getPrivacyCaseCache() {
  let cacheValue;
  try {
    cacheValue = JSON.parse(localStorage.getItem(CACHE_KEY) || '[]');

    if (!isValidCacheValue(cacheValue)) throw new Error();
  } catch (_) {
    cacheValue = [];
  }
  return cacheValue;
}

function cachePrivacyCase(caseId) {
  if (!caseId) return;

  const privacyCases = getPrivacyCaseCache();

  privacyCases.length > MAX_CACHE_ITEM_COUNT
    ? localStorage.setItem(CACHE_KEY, JSON.stringify([caseId]))
    : localStorage.setItem(
        CACHE_KEY,
        JSON.stringify(Array.from(new Set([...privacyCases, caseId])))
      );
}

const CaseViewContainer = withRouter(
  observer(({ match }) => {
    const hasV2CasePage = NewAppExperienceStore.appSettingsEditValues.casePage;
    const isCachedPrivacyCase = getPrivacyCaseCache().includes(
      match.params.caseId
    );
    const isPrivacyCase = isCachedPrivacyCase || CaseStore.type === 'privacy';

    if (hasV2CasePage && isPrivacyCase) {
      if (!isCachedPrivacyCase) cachePrivacyCase(match.params.caseId);

      window.location.replace(
        process.env.NODE_ENV === 'production'
          ? `/v2/case/index.html?caseId=${match.params.caseId}`
          : `http://localhost:8080/case?caseId=${match.params.caseId}`
      );

      // Return null here, otherwise the CaseView will briefly flash until location replaces
      // because code continues continues to run
      return null;
    }

    return (
      <CaseView
        {...mapProps({
          CaseStore,
          EmployeeActivityStore,
          PatientEncountersStore,
          match,
        })}
      />
    );
  })
);

CaseViewContainer.displayName = 'CaseViewContainer';

export default CaseViewContainer;
