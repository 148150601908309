import DataFeedDeliveryAside from '../DataFeedDeliveryAside';
import { DataFeedContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../utils/RouteHelper';

export function mapProps({
  deliveryStore,
  deliveryFilterStore,
  query,
  history,
  location,
}) {
  return {
    deliveryAfter: deliveryStore.deliveryAfter,
    name: deliveryStore.name,
    processingState: deliveryStore.processingState,
    sourceOptions: deliveryFilterStore.groupPrefixes,
    path: deliveryStore.path,
    source: deliveryStore.source,
    query,
    history,
    location,
  };
}

const DataFeedDeliveryAsideContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DataFeedContext,
    mapProps,
    PresentationalComponent: DataFeedDeliveryAside,
  })
);

export default DataFeedDeliveryAsideContainer;
