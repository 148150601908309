import React from 'react';
import { observer } from 'mobx-react';

import CaseResultsStore from '../stores/CaseResultsStore';
import FilterActions from '../FilterActions';

export function mapProps({ CaseResultsStore }) {
  return {
    rememberFilters: CaseResultsStore.rememberFilters,
    toggleRememberFilters: CaseResultsStore.toggleRememberFilters,
    clearFilterQuery: {
      showFilters: true,
      sort: 'lastModified,desc',
    },
  };
}

const FilterActionsContainer = observer(() => (
  <FilterActions {...mapProps({ CaseResultsStore })} />
));

FilterActionsContainer.displayName = 'FilterActionsContainer';

export default FilterActionsContainer;
