import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes } from 'mobx-react';
import { DoubleLineCell } from '../../index';

const StatusCell = ({ status, tags }) => {
  let content;
  const lowerCaseStatus = status.toLowerCase();

  if (lowerCaseStatus === 'expired') {
    content = <em className="text-danger">Expired</em>;
  } else {
    content = <span>Active</span>;
  }

  return (
    <DoubleLineCell
      lineOne={content}
      lineTwo={tags.length ? tags.join(', ') : '-'}
    />
  );
};

export default StatusCell;

StatusCell.propTypes = {
  status: PropTypes.string,
  tags: MobXPropTypes.observableArrayOf(PropTypes.string),
};

StatusCell.defaultProps = {
  status: '',
  tags: [],
};
