import React from 'react';
import { observer } from 'mobx-react';
import { AlertCategoryStore, HalUtils } from 'common';
import SearchStore from '../stores/SearchStore';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import SearchView from '../SearchView';

export function mapProps({
  AlertCategoryStore,
  SearchStore,
  HalUtils,
  query,
  match,
}) {
  const patientResultsProps = {
    isAdvancedSearchActive: SearchStore.store.isAdvancedSearchActive,
    areResultsFromAdvancedSearch:
      SearchStore.store.areResultsFromAdvancedSearch,
    onToggleAdvancedSearch: SearchStore.store.toggleAdvancedSearchActive,
    onUnmount: SearchStore.store.ensureAdvancedSearchClosed,
    searchFields: SearchStore.store.searchFields,
    onSearchFieldChange: SearchStore.store.setSearchField,
    onSubmitAdvancedSearch: SearchStore.store.searchWithAdvancedFilters,
    requiredAdvancedFiltersExist:
      SearchStore.store.requiredAdvancedFiltersExist,
    pagingStore: SearchStore.store,
  };

  const storePagingProps = {
    nextPage: SearchStore.store.nextPage,
    loading: SearchStore.store.loading,
    paging: SearchStore.store.paging,
    size: SearchStore.store.size,
  };

  return {
    query,
    match,
    failure: SearchStore.store.failure,
    results: SearchStore.store.results,
    totalElements: SearchStore.store.totalElements,
    hasMore: SearchStore.store.hasMore,
    maxed: SearchStore.store.maxed,
    criteria: SearchStore.criteria,
    type: SearchStore.type,
    getQueryParam: SearchStore.getQueryParam,
    useGroups: AlertCategoryStore.useGroups,
    getId: HalUtils.getId,
    patientResultsProps,
    storePagingProps,
  };
}

const SearchViewContainer = withRouterAndQuery(
  observer(({ query, match }) => (
    <SearchView
      {...mapProps({
        AlertCategoryStore,
        SearchStore,
        HalUtils,
        query,
        match,
      })}
    />
  ))
);

export default SearchViewContainer;
