import React from 'react';
import PropTypes from 'prop-types';
import ExpandCollapse from '../../ui/ExpandCollapse';
import Circle from './Circle';
import Counts from './Counts';
import Table from '../../ui/Table';
import classnames from 'classnames';
import styles from './index.module.scss';
import { TOOLTIP_CONTENT } from './const';

const DataFeedGroupEvaluation = ({
  tableData = [],
  counts = [],
  handleRowClick,
  handleCollapse = () => {},
  groupName = 'Unknown',
  status = 'success',
  selectedRow,
}) => {
  return (
    <ExpandCollapse
      useArrow={!!tableData.length}
      onCollapse={() => handleCollapse(tableData, selectedRow)}
    >
      <ExpandCollapse.Header>
        <div
          className={classnames(styles.row, {
            [styles.clickable]: tableData.length,
          })}
          data-cy="fdd-row-header"
        >
          <Circle status={status} dataCy="fdd-row-circle" />
          <p className={styles.name}>{groupName}</p>
          <div className={styles.spacer} />
          <Counts items={counts} />
        </div>
      </ExpandCollapse.Header>
      {tableData.length && (
        <ExpandCollapse.Section>
          <Table
            selectRowHandler={handleRowClick}
            data={tableData}
            selectedRow={selectedRow}
            className={styles.table}
            tooltips={TOOLTIP_CONTENT}
          />
        </ExpandCollapse.Section>
      )}
    </ExpandCollapse>
  );
};

DataFeedGroupEvaluation.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  counts: PropTypes.arrayOf(PropTypes.shape({})),
  status: PropTypes.string,
  groupName: PropTypes.string,
  handleCollapse: PropTypes.func,
  handleRowClick: PropTypes.func,
  selectedRow: PropTypes.string,
};

export default DataFeedGroupEvaluation;
