import React from 'react';
import { observer } from 'mobx-react';
import { OrigamiCaseIntegration } from '../OrigamiCaseIntegration';
import { CaseIntegrationStore } from '../../../stores/CaseIntegrationStore';

export function mapProps({ caseIntegrationStore }) {
  return {
    isEnabled: caseIntegrationStore.isEnabled,
    setEnabled: caseIntegrationStore.setEnabled,
  };
}

export const OrigamiCaseIntegrationContainer = observer(() => {
  return (
    <OrigamiCaseIntegration
      {...mapProps({
        caseIntegrationStore: CaseIntegrationStore,
      })}
    />
  );
});

OrigamiCaseIntegrationContainer.displayName = 'OrigamiCaseIntegrationContainer';
