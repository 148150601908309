import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';
import classnames from 'classnames';

import { FullName } from 'common';

import TagHelpers from '../TagHelpers';

const PersonNameCell = observer(
  ({ person, isActive, lastNameFirst, baseUrl, data }) => {
    const goTo = () => {
      TagHelpers.goTo(baseUrl, data);
    };

    if (!person) return <span />;

    return (
      <Cell
        onClick={goTo.bind(this)}
        className={classnames({
          'record__cell--active': isActive,
        })}
      >
        <FullName person={person} lastNameFirst={lastNameFirst} />
      </Cell>
    );
  }
);

PersonNameCell.propTypes = {
  person: PropTypes.shape({}),
  isActive: PropTypes.bool,
  lastNameFirst: PropTypes.bool,
  baseUrl: PropTypes.string,
  data: PropTypes.shape({}),
};

PersonNameCell.displayName = 'PersonNameCell';

export default PersonNameCell;
