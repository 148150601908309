import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed, decorate, observable, reaction } from 'mobx';
import moment from 'moment';
import { DropdownList } from 'react-widgets';

import { PermissionStore } from 'common';
import TemporaryPermissionsStore from '../../../stores/TemporaryPermissionsSettingsStore';
import { Checkbox, DatePicker, MultilineInput } from '../../index';
import BulkCaseActionFormActions from '../BulkCaseActionFormActions';
import { toLocalDateString } from '../../../utils/LocalDateUtils';
import { withFlags } from '../../Flagged';
import {
  DIVERSION,
  FALSE_POSITIVE,
  GOOD_CATCH,
  POLICY,
  PRIVACY,
  resolutionDescriptionDisplayName,
} from '../../../utils/resolutionDescriptions';

export const ChangeCaseResolution = observer(
  class ChangeCaseResolution extends React.Component {
    static propTypes = {
      store: PropTypes.shape({
        mixedCaseTypesSelected: PropTypes.bool.isRequired,
        performBulkAction: PropTypes.func.isRequired,
        performingBulkAction: PropTypes.bool.isRequired,
        percentComplete: PropTypes.number.isRequired,
        standardPrivacyCasesSelected: PropTypes.bool.isRequired,
        selectionIncludesPrivacyCases: PropTypes.bool.isRequired,
        selectionIncludesDiversionCases: PropTypes.bool.isRequired,
      }).isRequired,
      flags: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    // Observable
    dirty = false;
    newResolution = null;
    newResolutionDescription = null;
    temporaryPermissionCreate = false;
    temporaryPermissionEndDate = moment().add(
      TemporaryPermissionsStore.duration,
      'days'
    );
    temporaryPermissionReason = null;

    constructor() {
      super();
      reaction(
        () => [TemporaryPermissionsStore.duration],
        () => {
          this.temporaryPermissionEndDate = moment().add(
            TemporaryPermissionsStore.duration,
            'days'
          );
        }
      );
    }

    // Computed
    get temporaryPermissionAvailable() {
      const {
        store: { standardPrivacyCasesSelected },
      } = this.props;

      return (
        TemporaryPermissionsStore.enabled &&
        TemporaryPermissionsStore.creationPrompt &&
        this.dirty &&
        (this.newResolution === 'NOT_VIOLATION_FALSE_POSITIVE' ||
          this.newResolutionDescription === 'FALSE_POSITIVE') &&
        standardPrivacyCasesSelected
      );
    }

    // Computed
    get createTemporaryPermission() {
      const {
        store: { standardPrivacyCasesSelected },
      } = this.props;

      return (
        this.dirty &&
        (this.newResolution === 'NOT_VIOLATION_FALSE_POSITIVE' ||
          this.newResolutionDescription === 'FALSE_POSITIVE') &&
        (this.temporaryPermissionCreate ||
          (TemporaryPermissionsStore.enabled &&
            !TemporaryPermissionsStore.creationPrompt)) &&
        standardPrivacyCasesSelected
      );
    }

    /**
     * Update each case resolution if possible. This is the onSubmit handler for
     * this form. Pulls the data from the form and calls bulk action method from
     * the associated store.
     * @param {Object} e - the Event Object
     * @return {undefined}
     */
    onSubmit = e => {
      e.preventDefault();
      const { store } = this.props;

      // Sanity check; can't move forward without a proper resolution.
      if (
        !this.newResolution ||
        (!this.newResolutionDescription && this.newResolution !== 'UNRESOLVED')
      )
        return;

      if (this.newResolution === 'UNRESOLVED') {
        return store.performBulkAction('reopen');
      }

      return store.performBulkAction('resolve', {
        resolution: this.newResolution,
        resolutionDescription: this.newResolutionDescription,
        tempPermission: this.createTemporaryPermission,
        tempPermissionEnd: toLocalDateString(this.temporaryPermissionEndDate),
        tempPermissionReason: this.temporaryPermissionReason,
      });
    };

    /**
     * Handle the resolution value change. This is the onChange callback handler
     * for the dropdown menu.
     * @param {String} val    - the new value being set
     * @return {undefined}
     */
    onResolutionChange = val => {
      // Dirty === ready to submit the form.
      // If using resolution descriptions, only consider us ready when we have
      // selected unresolved since it does not require a description.
      this.dirty = val === 'UNRESOLVED';
      this.newResolution = val;
      this.newResolutionDescription = null;
    };

    /**
     * Handle the resolution description value change. This is the onChange
     * callback handler for the dropdown menu.
     * @param {String} val    - the new value being set
     */
    onResolutionDescriptionChange = val => {
      this.dirty = true;
      this.newResolutionDescription = val;
    };

    /**
     * Handle the date value change. This is the onChange callback handler
     * for the date picker.
     * @param {Object} val   - the new value being set
     * @param {Boolean} valid - whether or not the date is valid
     * @return {undefined}
     */
    onDateChange = (val, valid) => {
      if (valid) this.temporaryPermissionEndDate = val;
    };

    /**
     * Handle the reason value change. This is the onChange callback handler
     * for the textarea.
     * @param {String} val    - the new value being set
     * @return {undefined}
     */
    onReasonChange = val => {
      this.temporaryPermissionReason = val;
    };

    renderHelpText() {
      const { store } = this.props;
      if (store.performingBulkAction)
        return 'If you leave the page, any incomplete action will fail.';
      return 'This action will change the Resolution for all selected cases and cannot be undone.';
    }

    renderTemporaryPermission() {
      const {
        store: { mixedCaseTypesSelected },
      } = this.props;

      if (this.temporaryPermissionAvailable) {
        const formElements = [
          <li key="create">
            <Checkbox
              checked={this.temporaryPermissionCreate}
              onChange={() =>
                (this.temporaryPermissionCreate = !this
                  .temporaryPermissionCreate)
              }
              tabIndex={-1}
              color="secondary"
              label={`Create Temporary Permissions${
                // if the bundle is of mixed case types, alert the user
                mixedCaseTypesSelected ? ' for Applicable Cases' : ''
              }`}
            />
          </li>,
        ];

        if (this.temporaryPermissionCreate) {
          formElements.push(
            <li key="end-date">
              <DatePicker
                key="date"
                className="labeled-date-picker"
                label="End Date"
                onChange={this.onDateChange}
                value={this.temporaryPermissionEndDate}
              />
            </li>,
            <li key="reason">
              <MultilineInput
                label="Temporary permission reason (optional)"
                value={this.temporaryPermissionReason}
                onChange={this.onReasonChange}
                bordered
              />
            </li>
          );
        }
        formElements.push(
          <p className="help-text">
            * Applicable Cases must have one user and one patient
          </p>
        );
        return formElements;
      }
      // if the bundle has mixed case types and the user is going to create a temp permission
      if (this.createTemporaryPermission && mixedCaseTypesSelected) {
        return (
          <p className="help-text">
            Temporary Permissions will only be created for cases with one user
            and one patient
          </p>
        );
      }
    }

    render() {
      const { store } = this.props;
      const {
        performingBulkAction,
        selectionIncludesPrivacyCases,
        selectionIncludesDiversionCases,
      } = store;
      const canReopen = PermissionStore.hasAny(
        'CASE_REOPEN',
        'CASE_REOPEN_OWNED'
      );
      const canResolve = PermissionStore.hasAny(
        'CASE_RESOLVE',
        'CASE_RESOLVE_OWNED'
      );

      const resolutionOptions = [];
      if (canReopen)
        resolutionOptions.push({ value: 'UNRESOLVED', label: 'Unresolved' });
      if (canResolve)
        resolutionOptions.push(
          { value: 'NOT_VIOLATION', label: 'Not a Violation' },
          { value: 'VIOLATION', label: 'Violation' }
        );

      const resolutionDescriptionOptions = [];
      if (this.newResolution === 'NOT_VIOLATION') {
        resolutionDescriptionOptions.push(
          {
            value: GOOD_CATCH,
            label: resolutionDescriptionDisplayName(GOOD_CATCH),
          },
          {
            value: FALSE_POSITIVE,
            label: resolutionDescriptionDisplayName(FALSE_POSITIVE),
          }
        );
      }
      if (this.newResolution === 'VIOLATION') {
        resolutionDescriptionOptions.push({
          value: POLICY,
          label: resolutionDescriptionDisplayName(POLICY),
        });
        if (selectionIncludesDiversionCases)
          resolutionDescriptionOptions.push({
            value: DIVERSION,
            label: resolutionDescriptionDisplayName(DIVERSION),
          });
        if (selectionIncludesPrivacyCases)
          resolutionDescriptionOptions.push({
            value: PRIVACY,
            label: resolutionDescriptionDisplayName(PRIVACY),
          });
      }
      return (
        <form onSubmit={this.onSubmit} className="tools form">
          <ul>
            <li>
              <DropdownList
                data={resolutionOptions}
                readOnly={resolutionOptions.length === 0}
                disabled={performingBulkAction}
                onChange={({ value }) => this.onResolutionChange(value)}
                value={this.newResolution}
                placeholder="Select a Resolution"
                valueField="value"
                textField="label"
              />
              {!!this.newResolution && this.newResolution !== 'UNRESOLVED' && (
                <DropdownList
                  data={resolutionDescriptionOptions}
                  disabled={performingBulkAction}
                  onChange={({ value }) =>
                    this.onResolutionDescriptionChange(value)
                  }
                  value={this.newResolutionDescription}
                  placeholder="Select a Resolution Description"
                  valueField="value"
                  textField="label"
                />
              )}
            </li>
            {this.renderTemporaryPermission()}
            <BulkCaseActionFormActions
              store={store}
              buttonActionType={
                this.createTemporaryPermission
                  ? 'resolve-createTemporary'
                  : 'resolve'
              }
              hidden={!this.dirty}
              helpText="This action will change the Resolution for all selected cases and cannot be undone."
              isList
            />
          </ul>
        </form>
      );
    }
  }
);

decorate(ChangeCaseResolution, {
  dirty: observable,
  newResolution: observable,
  newResolutionDescription: observable,
  temporaryPermissionCreate: observable,
  temporaryPermissionEndDate: observable,
  temporaryPermissionReason: observable,
  temporaryPermissionAvailable: computed,
});

ChangeCaseResolution.displayName = 'ChangeCaseResolution';

export default withFlags(ChangeCaseResolution);
