import CaseDetailFilters from '../../ui/CaseDetailFilters';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { CaseReportsContext } from '../Context';

export function mapProps({
  caseReportsFilterSelectionsStore,
  caseResultsFiltersStore,
  groupStore,
  permissionStore,
  history,
  location,
  query,
}) {
  return {
    history,
    location,
    query,
    onMount: () =>
      caseResultsFiltersStore.caseBundleFilterOptionsStore.refresh(),

    queryForToggle: caseReportsFilterSelectionsStore.queryForToggle,
    queryForSelectionChange:
      caseReportsFilterSelectionsStore.queryForSelectionChange,

    canToggleOwner: permissionStore.has('CASE_SEARCH'),
    caseOwnerOptions: caseResultsFiltersStore.caseOwners,
    caseOwnerSelections: caseReportsFilterSelectionsStore.displayOwner,
    caseOwnerExcluded: caseReportsFilterSelectionsStore.ownerExclude,

    caseCreatedByOptions: caseResultsFiltersStore.caseCreators,
    caseCreatedBySelections: caseReportsFilterSelectionsStore.displayCreatedBy,
    caseCreatedByExcluded: caseReportsFilterSelectionsStore.createdByExclude,

    groupOptions: caseResultsFiltersStore.groups,
    groupSelections: caseReportsFilterSelectionsStore.displayGroup,
    groupExcluded: caseReportsFilterSelectionsStore.groupExclude,

    handleUpdateQuickFilterOptions: groupStore.handleUpdateQuickFilterOptions,
    rememberFilters: caseReportsFilterSelectionsStore.rememberFilters,
    savedQuickFilterOptions: groupStore.savedQuickFilterOptions,

    caseTypeOptions: caseResultsFiltersStore.alertCategoryNames,
    caseTypeSelections: caseReportsFilterSelectionsStore.displayCategory,
    caseTypeExclusionDisabled: true,

    caseBundleOptions: caseResultsFiltersStore.caseBundles,
    caseBundleSelections: caseReportsFilterSelectionsStore.displayBundle,
    caseBundleExcluded: caseReportsFilterSelectionsStore.bundleExclude,

    resolutionOptions: caseResultsFiltersStore.resolutions,
    resolutionSelections: caseReportsFilterSelectionsStore.displayResolution,
    resolutionExcluded: caseReportsFilterSelectionsStore.resolutionExclude,

    resolutionDescriptionOptions:
      caseResultsFiltersStore.resolutionDescriptionOptions,
    resolutionDescriptionSelections:
      caseReportsFilterSelectionsStore.displayResolutionDescription,
    resolutionDescriptionExcluded:
      caseReportsFilterSelectionsStore.resolutionDescriptionExclude,

    resolvedByOptions: caseResultsFiltersStore.caseResolvers,
    resolvedBySelections: caseReportsFilterSelectionsStore.displayResolvedBy,
    resolvedByExcluded: caseReportsFilterSelectionsStore.resolvedByExclude,

    caseActionOptions: caseResultsFiltersStore.caseActions,
    caseActionSelections: caseReportsFilterSelectionsStore.displayActions,
    caseActionExcluded: caseReportsFilterSelectionsStore.actionsTakenExclude,

    caseActionDateRangeSelection:
      caseReportsFilterSelectionsStore.actionsDateRange,
    caseActionDateAfter: caseReportsFilterSelectionsStore.actionsDateAfter,
    caseActionDateBefore: caseReportsFilterSelectionsStore.actionsDateBefore,

    suspicionScoreOptions: caseResultsFiltersStore.suspicionScores,
    suspicionScoreSelection: caseReportsFilterSelectionsStore.suspicionScore,
    suspicionScoreExcluded:
      caseReportsFilterSelectionsStore.suspicionScoreExclude,
  };
}

const CaseDetailFiltersContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseReportsContext,
    mapProps,
    PresentationalComponent: CaseDetailFilters,
  })
);

CaseDetailFiltersContainer.displayName = 'CaseDetailFiltersContainer';

export default CaseDetailFiltersContainer;
