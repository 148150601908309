import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';

import { ProtectedRoute, PermissionStore, PermissionSets } from 'common';
import CaseList from '../CaseList';
import ApplyBodyClassName from '../../ui/ApplyBodyClassName';

const BulkCaseActionsView = props => {
  // if the user cannot make modifications to cases, don't even let them view
  // this page. Instead, send them to the dashboard.
  if (!PermissionStore.hasAny(PermissionSets.bulkActions))
    return <Redirect to={'/'} />;

  return (
    <main>
      <ApplyBodyClassName className="bulk-case-actions" />
      {props.children}
      <Switch>
        <ProtectedRoute component={CaseList} />
      </Switch>
    </main>
  );
};

BulkCaseActionsView.propTypes = {
  children: PropTypes.node,
};

export default BulkCaseActionsView;
