// basic
export const ASSESSMENTS_LABEL = 'Assessments';

// main incidents print options
export const ASSESSMENTS_ALL = 'all';
export const ASSESSMENTS_CUSTOM = 'custom';
export const ASSESSMENTS_NONE = 'none';

// react widget specific
export const ASSESSMENTS_VALUE_FIELD = 'value';
export const ASSESSMENTS_TEXT_FIELD = 'name';

// custom filter specific
export const ASSESSMENT_TIME_RANGES_ALL_LABEL = 'All Time Ranges';
export const ASSESSMENT_TIME_RANGES_LABEL = 'Time Ranges';
