import React from 'react';
import * as d3 from 'd3';
import moment from 'moment';

import { autorun } from 'mobx';
import { HalUtils } from 'common';

import EventDetailedStore from '../../stores/EventDetailedStore';
import ChartStore from '../../stores/ChartStore';
import EncounterStore from '../../stores/EncounterStore';

/**
 * Layer responsible for drawing vertical encounter backgrounds in the patient careflow.
 * @extends React.Component
 */
export default class EncounterLayer extends React.Component {
  disposers = [];

  // Ref
  group = React.createRef();

  componentDidMount() {
    this.disposers.push(
      autorun(() => {
        this.renderEncounters();
      })
    );
  }

  componentWillUnmount() {
    this.disposers.forEach(d => d());
  }

  renderEncounters() {
    if (EncounterStore.loading) return;

    const data = EncounterStore.encounters;
    const rects = d3
      .select(this.group.current)
      .selectAll('rect')
      .data(data);

    const enterSelection = rects
      .enter()
      .append('rect')
      .style('opacity', 0.3)
      .style('fill', '#111111')
      .style('stroke', 'black')
      .attr('height', '300%');

    rects.merge(enterSelection).each(function(d) {
      const me = d3.select(this);
      const d1 = moment(d.startTime).toDate(),
        x = ChartStore.brushedXScale(d1),
        d2 = d.endTime && moment(d.endTime).toDate(),
        x2 = d.endTime && ChartStore.brushedXScale(d2),
        width = x2 - x || 1; // cover undefined end dates

      me.attr('width', Math.max(width, 1));
      me.attr('transform', `translate(${ChartStore.margin.left + x},0)`);

      let isDrawer = false;
      const { detailView } = EventDetailedStore;
      if (detailView && detailView.encounter) {
        isDrawer = HalUtils.getId(d) === detailView.encounter.id;
      }
      me.classed('drawer', isDrawer);
    });

    rects.exit().remove();
  }

  render() {
    return <g className="encounters" ref={this.group} />;
  }
}
