import MultiRecordMetadataLayout from '../MultiRecordMetadataLayout';
import { MultiRecordViewContext } from '../../Context';
import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../../utils/RouteHelper';

export function mapProps({
  match,
  multiRecordMainStore,
  multiRecordUserMetadataStore,
}) {
  return {
    pageMode: multiRecordMainStore.pageMode,
    isDoubleColumnMode: multiRecordMainStore.isDoubleColumnMode,
    match,
    setCurrentMatch: multiRecordUserMetadataStore.setCurrentMatch,
    personType: multiRecordUserMetadataStore.personType,
  };
}

export const MultiRecordUserMetadataContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordMetadataLayout,
  })
);
