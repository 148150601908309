import { HalUtils } from 'common';
import moment from 'moment';

export const createCase = (
  caseType,
  category,
  flaggedAssessments,
  flaggedEvents
) => {
  let newCase;

  const caseBase = {
    category: HalUtils.getSelf(category),
    _class: caseType,
  };

  if (caseType === 'PrivacyCase') {
    newCase = constructPrivacyCase(caseBase, flaggedEvents);
  } else {
    newCase = constructDiversionCase(caseBase, flaggedAssessments);
  }

  return newCase;
};

const constructPrivacyCase = (base, flaggedEvents) => {
  const buckets = new Set();
  let userId, patientId;
  let minDate, maxDate;

  // determine user, patient, applicable access buckets and assessments for case
  flaggedEvents.forEach(access => {
    const accessMoment = moment(access.dateOfAccess);
    if (!userId && access?.user?.id) userId = access.user.id;
    if (!patientId && access?.patient?.id) patientId = access.patient.id;
    buckets.add(access.accessBucketId);
    if (!minDate || accessMoment.isBefore(minDate)) minDate = accessMoment;
    if (!maxDate || accessMoment.isAfter(maxDate)) maxDate = accessMoment;
  });

  return {
    ...base,
    accessBuckets: Array.from(buckets),
    minEventDate: minDate.toISOString(),
    maxEventDate: maxDate.toISOString(),
    user: userId,
    patient: patientId,
  };
};

const constructAssessments = assessment => {
  return {
    suspicionScore: assessment.suspicionScore,
    minEventDate: assessment.startTime,
    maxEventDate: assessment.endTime,
    reasons: assessment.reasons,
    clusterReasons: assessment.clusterReasons,
  };
};

const constructDiversionCase = (base, stats) => {
  const medicationIncidents = stats.flatMap(stat =>
    stat.incidents.flatMap(i => i.linkedIncidents.flatMap(i => i.id))
  );
  const constructedAssessments = stats.map(stat => constructAssessments(stat));

  const userId = stats[0].user.id;

  return {
    ...base,
    user: userId,
    medicationIncidents,
    analyticAssessments: constructedAssessments,
  };
};
