import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import ValueOrUnknown from '../ValueOrUnknown';

const FormattedMRNs = ({ medicalRecordNumbers }) => {
  return (
    <ValueOrUnknown>
      {(medicalRecordNumbers || []).map(mrn => (
        <div key={mrn}>{mrn}</div>
      ))}
    </ValueOrUnknown>
  );
};

FormattedMRNs.propTypes = {
  medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.string
  ),
};

FormattedMRNs.defaultProps = {
  medicalRecordNumbers: [],
};

export default FormattedMRNs;
