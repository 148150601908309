import { computed, decorate, observable, reaction, action } from 'mobx';

import moment from 'moment-timezone';

import {
  PrivacyStatementsClient,
  SingletonStore,
  HalUtils,
  PermissionStore,
  RouterContainer,
  GroupStore,
} from 'common';
import PrivacyStatementCaseStore from '../PrivacyStatementCaseStore';
import {
  FALSE_POSITIVE,
  GOOD_CATCH,
} from '../../../utils/resolutionDescriptions';

class Store extends SingletonStore {
  constructor() {
    super();

    reaction(
      () => this.statements,
      () => {
        // We want to set the current statement id to the first of our fresh
        // statements if
        // - We've not yet set a currentStatementId.
        // OR
        // - We've set a currentStatementId before we fetched statements and
        //   there is no statement with that id.
        //
        // If for any reason we don't have any statements after the fetch,
        // don't do anything. We have bigger problems.
        if (
          this.firstStatementId &&
          (!this.currentStatementId ||
            (this.currentStatementId &&
              !this.allStatementIds.includes(this.currentStatementId)))
        ) {
          this.setCurrentStatementId(this.firstStatementId);
        }
      }
    );

    reaction(
      () => this.currentStatement,
      () => {
        if (this.startDate) {
          // The statement has changed, need to pull back a fresh set of cases.
          // We either pull back a 3 month or 9 month chunk depending on if
          // we're looking at a monthly or quarterly statement.
          const lookback =
            this.determineTimeframe(this.startDate, this.endDate) === 'month'
              ? 3
              : 9;
          const end = moment(this.endDate);
          const start = moment(this.endDate).subtract(lookback, 'months');
          PrivacyStatementCaseStore.setDateRange(start, end);
        }
      }
    );

    reaction(
      () => [this.group],
      () => {
        if (this.group) PrivacyStatementCaseStore.setGroup(this.group);
        else PrivacyStatementCaseStore.setGroup(GroupStore.groupIds);
      }
    );
  }

  // Observable
  currentStatementId = null;
  chartWidth = 600;
  filterValue = '';

  // Action
  setValue = (type, val) => {
    this[type] = val;
  };

  // Computed
  get statements() {
    return HalUtils.getData(this.result) || [];
  }

  /* COMPUTED The starting date of the current statement */
  get startDate() {
    return this.currentStatement.startDate;
  }

  /* COMPUTED The ending date of the current statement */
  get endDate() {
    return this.currentStatement.endDate;
  }

  /* COMPUTED The starting date of the previous statement */
  get prevStartDate() {
    return this.prevStatement.startDate;
  }

  /* COMPUTED The ending date of the previous statement */
  get prevEndDate() {
    return this.prevStatement.endDate;
  }

  /* COMPUTED */
  get group() {
    return RouterContainer?.query?.group;
  }

  /* COMPUTED The list of cases created within the current statement's time range */
  get statementCases() {
    let cases = [];

    const monthLookback =
      this.determineTimeframe(this.startDate, this.endDate) === 'month' ? 1 : 3;
    const dateIndex = moment(this.endDate)
      .utc()
      .subtract(1, 'day');
    for (let i = 0; i < monthLookback; i += 1) {
      cases = cases.concat(
        this.allCasesByMonthAndYear[dateIndex.utc().format('MMMM YYYY')] || []
      );
      dateIndex.subtract(1, 'months');
    }
    return cases;
  }

  /* COMPUTED The list of cases created within the current statement's time range */
  get prevStatementCases() {
    let cases = [];

    const monthLookback =
      this.determineTimeframe(this.startDate, this.endDate) === 'month' ? 1 : 3;
    const dateIndex = moment(this.prevEndDate)
      .utc()
      .subtract(1, 'day');
    for (let i = 0; i < monthLookback; i += 1) {
      cases = cases.concat(
        this.allCasesByMonthAndYear[dateIndex.utc().format('MMMM YYYY')] || []
      );
      dateIndex.subtract(1, 'months');
    }

    return cases;
  }

  /* COMPUTED The current privacy statement */
  get currentStatement() {
    return this.statements.find(f => f.id === this.currentStatementId) || {};
  }

  /* COMPUTED The previous privacy statement */
  get prevStatement() {
    let prevStatement;
    let currentTimeframe;
    this.statements.forEach(statement => {
      const timeframe = this.determineTimeframe(
        statement.startDate,
        statement.endDate
      );
      if (statement.id === this.currentStatementId) {
        currentTimeframe = timeframe;
        // will grab the one following the current statement with the same timeframe
      } else if (currentTimeframe === timeframe && !prevStatement)
        prevStatement = statement;
    });
    return prevStatement || {};
  }

  /* COMPUTED The 'By the Numbers' statistics that stay consistent despite the currently applied groups */
  get globalPHCStats() {
    return [
      {
        title:
          'Number of Active EHR Users in Period (includes human and system users)',
        data: this.numActiveUsers,
      },
      {
        title: 'Number of Medical Records Accessed by Active EHR Users',
        data: this.numPatientsAccessed,
      },
      {
        title: 'Unique Links Between Active Users and Medical Records',
        data: this.numLinks,
      },
      {
        title: 'Unique Access Log Events (i.e., access log rows)',
        data: this.numAccesses,
      },
      {
        title: 'Percent of Access Log Events Monitored',
        data: this.percentMonitored,
      },
    ];
  }
  /* COMPUTED These stats are all group dependent. They will change depending on what current groups are applied */
  get groupPHCStats() {
    return [
      {
        title: 'Number of Unique Links Prioritized for Expert Human Review',
        data: this.numberOfCases,
      },
      {
        title: 'Percent of Unique Links Prioritized for Expert Human Review',
        data: this.percentUniqueLinksExpert,
      },
      {
        title: 'Percent of Unique Links Reviewed by Investigator (cases)',
        data: this.percentCasesReviewed,
      },
      {
        title: 'Number of Cases Pending Investigation at End of Period',
        data: this.numUnresolvedCases,
      },
      {
        title: 'Number of Cases Resolved as Violations',
        data: this.numCasesViolations,
      },
      {
        title: 'Percent of Unique Links Labeled as a Violation',
        data: this.percentUniqueViolations,
      },
      {
        title: 'Number of Cases Resolved as Non-Violations',
        data: this.numCasesNotViolation,
      },
      {
        title: 'Number of Cases Resolved as False Positives',
        data: this.numCasesFalsePositive,
      },
      {
        title: 'Percent of Cases Resolved as False Positive',
        data: this.percentResolvedFalsePositive,
      },
      {
        title: 'Number of EHR Users Involved in Violations',
        data: this.numUsersInCases,
      },
    ];
  }

  /* COMPUTED This stat can only be computed correctly if there are NO groups or ALL the possible groups are applied */
  get noGroupPHCStats() {
    return [
      {
        title: 'Percent of EHR Users Involved in Violations',
        data: this.percentUsersInCases,
      },
    ];
  }

  /* COMPUTED The number of active EMR users in the current and previous statement time ranges */
  get numActiveUsers() {
    const curr = this.currentStatement.activeUsers || 0;
    const prev = this.prevStatement.activeUsers;
    return { curr, prev };
  }

  /* COMPUTED The number of active EMR users in the current and previous statement time ranges */
  get numPatientsAccessed() {
    const curr = this.currentStatement.patientsAccessed || 0;
    const prev = this.prevStatement.patientsAccessed;
    return { curr, prev };
  }

  /* COMPUTED The number of unique EMR user to patient accesses in the current and previous statement time ranges */
  get numLinks() {
    const curr = this.currentStatement.links || 0;
    const prev = this.prevStatement.links;
    return { curr, prev };
  }

  /* COMPUTED The number of accesses in the current and previous statement time ranges */
  get numAccesses() {
    const curr = this.currentStatement.accesses || 0;
    const prev = this.prevStatement.accesses;
    return { curr, prev };
  }

  /* COMPUTED The percentage of accesses that are monitored in the current and previous statement time ranges */
  get percentMonitored() {
    const curr = 1;
    const prev = 1;
    return { curr, prev };
  }

  /* COMPUTED The number of cases in the current and previous statement time ranges */
  get numberOfCases() {
    const curr = this.statementCases.length;
    const prev = this.prevStatementCases.length;
    return { curr, prev };
  }

  // Computed
  get allCasesByMonthAndYear() {
    const cases = {};
    PrivacyStatementCaseStore.cases.forEach(c => {
      const month = moment(c.created)
        .utc()
        .format('MMMM YYYY');
      if (cases[month]) {
        cases[month].push(c);
      } else {
        cases[month] = [c];
      }
    });
    return cases;
  }

  // Computed
  get casesObject() {
    const timePeriod =
      this.determineTimeframe(this.startDate, this.endDate) === 'month' ? 3 : 9;
    const casesObject = {};
    const dateIndex = moment(this.endDate)
      .utc()
      .subtract(1, 'day');

    for (let i = 0; i < timePeriod; i += 1) {
      casesObject[dateIndex] =
        this.allCasesByMonthAndYear[dateIndex.utc().format('MMMM YYYY')] || [];
      dateIndex.subtract(1, 'months');
    }

    return casesObject;
  }

  /* COMPUTED The percentage of unique links that became cases in the current and previous statement time ranges */
  get percentUniqueLinksExpert() {
    const curr = this.statementCases.length / this.numLinks.curr;

    let prev;
    if (this.numLinks.prev) {
      prev = this.prevStatementCases.length / this.numLinks.prev;
    }
    return { curr, prev };
  }

  /* COMPUTED The percentage of cases that were reviewed in the current and previous statement time ranges */
  get percentCasesReviewed() {
    let curr = 0;
    let prev = 0;
    if (this.numReviewedCases.curr && this.statementCases.length)
      curr = this.numReviewedCases.curr / this.statementCases.length;
    if (this.numReviewedCases.prev && this.prevStatementCases.length)
      prev = this.numReviewedCases.prev / this.prevStatementCases.length;
    return { curr, prev };
  }

  /* COMPUTED The number of cases that were reviewed in the current and previous statement time ranges */
  get numReviewedCases() {
    const curr = this.statementCases.filter(c => c.resolution).length;
    const prev = this.prevStatementCases.filter(c => c.resolution).length;
    return { curr, prev };
  }

  /* COMPUTED The number of cases were left unresolved in the current and previous statement time ranges */
  get numUnresolvedCases() {
    const curr = this.statementCases.filter(
      c => !c.resolutionDate || moment(c.resolutionDate).isAfter(this.endDate)
    ).length;
    const prev = this.prevStatementCases.filter(
      c =>
        !c.resolutionDate || moment(c.resolutionDate).isAfter(this.prevEndDate)
    ).length;
    return { curr, prev };
  }

  /* COMPUTED The number of cases that were violations in the current and previous statement time ranges */
  get numCasesViolations() {
    const curr = this.statementCases.filter(c => c.resolution === 'VIOLATION')
      .length;
    const prev = this.prevStatementCases.filter(
      c => c.resolution === 'VIOLATION'
    ).length;
    return { curr, prev };
  }

  /* COMPUTED The percentage of unique links that became violations in the current and previous statement time ranges */
  get percentUniqueViolations() {
    let curr = 0;
    let prev = 0;
    // const curr = this.numCasesViolations.curr / this.numLinks.curr;
    if (this.numCasesViolations.curr && this.numLinks.curr)
      curr = this.numCasesViolations.curr / this.numLinks.curr;
    // const prev = this.numCasesViolations.prev / this.numLinks.prev;
    if (this.numCasesViolations.prev && this.numLinks.prev)
      prev = this.numCasesViolations.prev / this.numLinks.prev;
    return { curr, prev };
  }

  /* COMPUTED The percentage of cases that weren't violations in the current and previous statement time ranges */
  get numCasesNotViolation() {
    const curr = this.statementCases.filter(
      c => c.resolution && c.resolution.includes('NOT_VIOLATION')
    ).length;
    const prev = this.prevStatementCases.filter(
      c => c.resolution && c.resolution.includes('NOT_VIOLATION')
    ).length;
    return { curr, prev };
  }

  /* COMPUTED The number of cases that were false positives in the current and previous statement time ranges */
  get numCasesFalsePositive() {
    const curr = this.statementCases.filter(
      c =>
        c.resolution &&
        c.resolution === 'NOT_VIOLATION' &&
        c.resolutionDescription === FALSE_POSITIVE
    ).length;
    const prev = this.prevStatementCases.filter(
      c =>
        c.resolution &&
        c.resolution === 'NOT_VIOLATION' &&
        c.resolutionDescription === FALSE_POSITIVE
    ).length;
    return { curr, prev };
  }

  /* COMPUTED The percentage of cases that were false positives in the current and previous statement time ranges */
  get percentResolvedFalsePositive() {
    let curr = 0;
    let prev = 0;
    if (this.numCasesFalsePositive.curr && this.statementCases.length)
      curr = this.numCasesFalsePositive.curr / this.statementCases.length;
    if (this.numCasesFalsePositive.prev && this.prevStatementCases.length)
      prev = this.numCasesFalsePositive.prev / this.prevStatementCases.length;
    return { curr, prev };
  }

  /* COMPUTED The unique number of users in cases found in the current and previous statement time ranges */
  get numUsersInCases() {
    const userCount = new Map();
    const prevUserCount = new Map();

    this.statementCases
      .filter(c => c.userSnapshot)
      .forEach(c => {
        if (c.resolution === 'VIOLATION') {
          userCount.set(c.userSnapshot.id, c);
        }
      });

    this.prevStatementCases
      .filter(c => c.userSnapshot)
      .forEach(c => {
        if (c.resolution === 'VIOLATION') {
          prevUserCount.set(c.userSnapshot.id, c);
        }
      });

    return {
      curr: userCount.size,
      prev: prevUserCount.size,
    };
  }

  /* COMPUTED The percentage of active users in cases found in the current and previous statement time ranges */
  get percentUsersInCases() {
    let curr = 0;
    let prev = 0;
    if (this.numUsersInCases.curr && this.numActiveUsers.curr)
      curr = this.numUsersInCases.curr / this.numActiveUsers.curr;
    if (this.numUsersInCases.prev && this.numActiveUsers.prev)
      prev = this.numUsersInCases.prev / this.numActiveUsers.prev;
    return { curr, prev };
  }

  // Computed
  get currentMonthsArray() {
    const dates = Object.keys(this.casesObject);
    return dates.sort((a, b) => new Date(a) - new Date(b));
  }

  // Computed
  get violationsPerTimePeriod() {
    const violations = [];
    this.currentMonthsArray.forEach((k, i) => {
      violations.push({
        monthIndex: i + 1,
        count: this.casesObject[k].filter(r => r.resolution === 'VIOLATION')
          .length,
      });
    });

    return violations;
  }

  // Computed
  get goodCatchPerTimePeriod() {
    const goodCatch = [];

    this.currentMonthsArray.forEach((k, i) => {
      goodCatch.push({
        monthIndex: i + 1,
        count: this.casesObject[k].filter(
          r =>
            r.resolution === 'NOT_VIOLATION' &&
            r.resolutionDescription === GOOD_CATCH
        ).length,
      });
    });

    return goodCatch;
  }

  // Computed
  get falsePositivePerTimePeriod() {
    const falsePositive = [];

    this.currentMonthsArray.forEach((k, i) => {
      falsePositive.push({
        monthIndex: i + 1,
        count: this.casesObject[k].filter(
          r =>
            r.resolution === 'NOT_VIOLATION' &&
            r.resolutionDescription === FALSE_POSITIVE
        ).length,
      });
    });

    return falsePositive;
  }

  // Computed
  get unresolvedPerTimePeriod() {
    const unresolved = [];

    this.currentMonthsArray.forEach((k, i) => {
      unresolved.push({
        monthIndex: i + 1,
        count: this.casesObject[k].filter(r => !r.resolution).length,
      });
    });

    return unresolved;
  }

  /*
    takes a CB function that will determin the key value and returns an array
    of objects
  */
  compileViolationsByType(findKeyFunc) {
    const violationsCount = {};
    this.statementCases.forEach(aCase => {
      if (aCase.resolution === 'VIOLATION') {
        const key = findKeyFunc(aCase);

        if (violationsCount[key]) {
          violationsCount[key].y += 1;
        } else {
          violationsCount[key] = {
            x: key,
            y: 1,
          };
        }
      }
    });

    return this.sortData(Object.values(violationsCount));
  }

  fetch() {
    if (PermissionStore.has('PRIVACY_STATEMENT_VIEW'))
      return PrivacyStatementsClient.findAll();
    return [];
  }

  removeNote(chartKey) {
    PrivacyStatementsClient.removeNote(this.currentStatementId, chartKey).then(
      () => {
        this.refresh();
      }
    );
  }

  saveNote(chartKey, note) {
    PrivacyStatementsClient.editNote(
      this.currentStatementId,
      chartKey,
      note
    ).then(() => {
      this.refresh();
    });
  }

  determineTimeframe(startDate, endDate) {
    return moment(endDate).diff(moment(startDate), 'days') < 90
      ? 'month'
      : 'quarter';
  }

  sortData(arr) {
    return arr.sort((obj1, obj2) => {
      if (obj1.y < obj2.y) return -1;
      if (obj1.y > obj2.y) return 1;
      return 0;
    });
  }

  get groups() {
    return GroupStore.groups;
  }

  get periods() {
    const tp = [];
    this.statements.forEach(s => {
      const start = moment(s.startDate)
        .utc()
        .format('MMMM YYYY');
      const end = moment(s.endDate)
        .utc()
        .subtract(1, 'day')
        .format('MMMM YYYY');
      if (moment(s.endDate).diff(moment(s.startDate), 'days') < 90) {
        tp.push({ id: s.id, label: start, timeFrame: 'month' });
      } else {
        tp.push({ id: s.id, label: `${start} - ${end}`, timeFrame: 'quarter' });
      }
    });
    return tp;
  }

  roundNumber(num) {
    if (isNaN(num)) return 0;
    return parseFloat(num.toFixed(2));
  }

  /* takes an object of objects sub objects need totalTime and a
     validCaseCount values. It will return an array of objects
     with the keys and the average times */
  getAverageResolutionTime(obj) {
    const arr = Object.values(obj).map(subObj => ({
      x: subObj.title,
      y: this.roundNumber(subObj.totalTime / subObj.validCaseCount),
    }));

    return this.sortData(arr);
  }

  /*
    adds values to the given object by given key
  */
  buildTimeAndCaseCountObject(obj, key, dateDiff) {
    if (obj[key]) {
      obj[key].totalTime += dateDiff;
      obj[key].validCaseCount += 1;
    } else {
      obj[key] = {
        totalTime: dateDiff,
        validCaseCount: 1,
        title: key,
      };
    }
  }

  // Computed
  get crunchingNumbers() {
    // note, for subsequent refreshes (i.e. group toggling) we want this to return false to avoid a loader flash
    return (
      PrivacyStatementCaseStore.cases.length === 0 &&
      PrivacyStatementCaseStore.loading
    );
  }

  // Computed
  get allStatementIds() {
    return (this.statements || []).map(s => s.id);
  }

  // Computed
  get firstStatementId() {
    return this.allStatementIds[0];
  }

  // Action
  setCurrentStatementId(statementId) {
    // If we haven't been given a statementId, use the first one from our
    // statements.
    //
    // If for some reason the given statementId does not exist, again use the
    // first one from our statements instead.
    if (
      !statementId ||
      (this.allStatementIds.length &&
        !this.allStatementIds.includes(statementId))
    ) {
      statementId = this.firstStatementId;
    }

    // Bail if for any unforeseen reason we do not have a truthy statementId.
    // Setting to an undefined value will break charts.
    if (!statementId) return;

    this.currentStatementId = statementId;
  }
}

decorate(Store, {
  chartWidth: observable,
  currentStatementId: observable,
  filterValue: observable,
  setValue: action,
  allCasesByMonthAndYear: computed,
  casesObject: computed,
  crunchingNumbers: computed,
  currentMonthsArray: computed,
  currentStatement: computed,
  endDate: computed,
  falsePositivePerTimePeriod: computed,
  globalPHCStats: computed,
  goodCatchPerTimePeriod: computed,
  groupPHCStats: computed,
  group: computed,
  groups: computed,
  noGroupPHCStats: computed,
  numAccesses: computed,
  numActiveUsers: computed,
  numberOfCases: computed,
  numCasesFalsePositive: computed,
  numCasesNotViolation: computed,
  numCasesViolations: computed,
  numLinks: computed,
  numPatientsAccessed: computed,
  numReviewedCases: computed,
  numUnresolvedCases: computed,
  numUsersInCases: computed,
  percentCasesReviewed: computed,
  percentMonitored: computed,
  percentResolvedFalsePositive: computed,
  percentUniqueLinksExpert: computed,
  percentUniqueViolations: computed,
  percentUsersInCases: computed,
  prevEndDate: computed,
  prevStartDate: computed,
  prevStatement: computed,
  prevStatementCases: computed,
  startDate: computed,
  statements: computed,
  statementCases: computed,
  unresolvedPerTimePeriod: computed,
  violationsPerTimePeriod: computed,
  allStatementIds: computed,
  firstStatementId: computed,
  setCurrentStatementId: action,
});

export default new Store();
