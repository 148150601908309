import { withRouterAndQuery } from '../../../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../../../utils/observerWrappedContainerFactory';
import { DoubleViewContext } from '../../../Context';
import MultiRecordEntityColumn from '../../../MultiRecordEntityColumn';
import { patientColumnMapProps } from '../../mapProps';

const DoubleViewPatientColumnContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: DoubleViewContext,
    mapProps: patientColumnMapProps,
    PresentationalComponent: MultiRecordEntityColumn,
  })
);

export default DoubleViewPatientColumnContainer;
