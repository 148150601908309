import React from 'react';
import { observer } from 'mobx-react';

import BaseCell from '../BaseCell';

const unknown = <em>Unknown</em>;

const checkName = val => val || unknown;

const CreatedByCell = observer(({ data, ...other }) => {
  const { createdBy } = data;

  return (
    <BaseCell data={data} {...other}>
      {checkName(createdBy.firstName)} {checkName(createdBy.lastName)}
    </BaseCell>
  );
});

CreatedByCell.displayName = 'CreatedByCell';

export default CreatedByCell;
