import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DropdownList } from 'react-widgets';

import { PermissionStore, AppUserStore, HalUtils } from 'common';
import CaseStore from '../../stores/CaseStore';

const OwnerSelect = observer(
  ({
    defaultValue,
    onChange,
    value,
    placeholder,
    readOnly,
    disabled,
    dropUp,
  }) => {
    const loaded = !AppUserStore.loading;
    const _onChange = val => onChange(val.value);
    let options = [];

    // only add this to the options if they can unassign, and unassigned is not the current value
    // this ensures that users without the unassign permission can still select the current value
    // but otherwise it's only present once in the list

    if (PermissionStore.has('CASE_UNASSIGN') || defaultValue === 'Unassigned') {
      options.push({
        value: 'Unassigned',
        label: 'Unassigned',
      });
    }

    if (
      !AppUserStore.enabledActiveCaseOwners ||
      AppUserStore.enabledActiveCaseOwners.length === 0
    ) {
      // this ensures the id is not rendered if it takes a while to get the names back
      options.push({
        value: value,
        label: '',
      });
    } else {
      // case owner is view only/lost permissions - show anyways in this case
      if (
        CaseStore.owner &&
        !AppUserStore.enabledActiveCaseOwners.find(
          u => HalUtils.getId(u) === CaseStore.owner
        )
      ) {
        options.push({
          value: CaseStore.owner,
          label:
            CaseStore.case.owner.firstName +
            ' ' +
            CaseStore.case.owner.lastName,
        });
      }

      options = options.concat(
        AppUserStore.enabledActiveCaseOwners.map(u => ({
          value: HalUtils.getId(u),
          label: u.firstName + ' ' + u.lastName,
        }))
      );
    }

    if (loaded) {
      return (
        <DropdownList
          data={options}
          readOnly={!PermissionStore.has('CASE_ASSIGN') || readOnly}
          onChange={_onChange}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          valueField="value"
          textField="label"
          dropUp={dropUp}
        />
      );
    } else {
      return <DropdownList readOnly busy />;
    }
  }
);

OwnerSelect.propTypes = {
  defaultValue: PropTypes.string,
  dropUp: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
};

OwnerSelect.displayName = 'OwnerSelect';

export default OwnerSelect;
