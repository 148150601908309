import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import styles from './index.module.scss';
import CaseMedicationIncidents from '../../incidents/CaseMedicationIncidents';

const IncidentsToPrint = ({ filteredIncidentsForPrint }) => {
  return (
    <div className={styles.hideForScreen}>
      <CaseMedicationIncidents filteredIncidents={filteredIncidentsForPrint} />
    </div>
  );
};

IncidentsToPrint.propTypes = {
  filteredIncidentsForPrint: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
};

export default IncidentsToPrint;
