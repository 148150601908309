import FilterActions from '../../search/FilterActions';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { CaseReportsContext } from '../Context';

export function mapProps({ caseReportsFilterSelectionsStore }) {
  return {
    rememberFilters: caseReportsFilterSelectionsStore.rememberFilters,
    toggleRememberFilters:
      caseReportsFilterSelectionsStore.toggleRememberFilters,
    clearFilterQuery: {},
    clearStorage: caseReportsFilterSelectionsStore.clearStoredFilters,
  };
}

const ReportFilterActionsContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseReportsContext,
    mapProps,
    PresentationalComponent: FilterActions,
  })
);

ReportFilterActionsContainer.displayName = 'ReportFilterActionsContainer';

export default ReportFilterActionsContainer;
