import { observer } from 'mobx-react';
import React from 'react';

export function observerWrappedContainerFactory({
  Context,
  mapProps,
  PresentationalComponent,
  globalStores,
}) {
  const ObserverWrappedContainer = observer(props => (
    <PresentationalComponent {...mapProps(props)} />
  ));

  return function ContainerComponent(props) {
    return (
      <Context.Consumer>
        {context => (
          <ObserverWrappedContainer {...globalStores} {...context} {...props} />
        )}
      </Context.Consumer>
    );
  };
}
