import React from 'react';
import { observer } from 'mobx-react';
import CaseStore from '../../stores/CaseStore';
import Switcher from '../Switcher';
import { withRouter } from 'react-router-dom';

export function mapProps({ CaseStore, match }) {
  return {
    caseType: CaseStore.type,
    match,
  };
}

const SwitcherContainer = observer(({ match }) => (
  <Switcher
    {...mapProps({
      CaseStore,
      match,
    })}
  />
));

export default withRouter(SwitcherContainer);
