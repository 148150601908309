import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import classnames from 'classnames';

import DatePicker from '../../ui/DatePicker';
import styles from './index.module.scss';

const FilterDateRange = observer(
  ({
    className,
    label,
    dates,
    onChange,
    filterKey,
    alignDropdownRight,
    restrictFutureDate,
    ...props
  }) => {
    const labelStart = label ? label.start : 'Start Date';
    const labelEnd = label ? label.end : 'End Date';
    const start = dates[0] ? moment(dates[0]) : moment().subtract(1, 'month');
    const end = dates[1] ? moment(dates[1]) : moment();

    return (
      <div className={classnames(className, 'date-range')} {...props}>
        <DatePicker
          className="split-two start-date"
          onChange={(date, valid) => onChange(date, valid, 'After', filterKey)}
          label={labelStart}
          value={start}
          maxDate={end}
        />
        <DatePicker
          className={classnames('split-two end-date', {
            [styles.alignRight]: alignDropdownRight,
          })}
          onChange={(date, valid) => onChange(date, valid, 'Before', filterKey)}
          label={labelEnd}
          value={end}
          minDate={start}
          maxDate={restrictFutureDate && moment()}
        />
      </div>
    );
  }
);

FilterDateRange.displayName = 'FilterDateRange';

export default FilterDateRange;
