import React from 'react';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  scoreWithTooltip,
  scoreWithoutTooltip,
} from '../../../ui/SuspicionScore';

function SuspicionScore({ analyticAssessments }) {
  // if there are no assessments for the case, return null
  if (analyticAssessments.length === 0) return null;
  const multiple = analyticAssessments.length > 1;

  return (
    <li className="card__meta-item">
      <h5>{`Suspicion Score${multiple ? 's' : ''}`}</h5>
      {multiple ? (
        scoreWithTooltip(analyticAssessments)
      ) : (
        <span>{scoreWithoutTooltip(analyticAssessments)}</span>
      )}
    </li>
  );
}

SuspicionScore.propTypes = {
  analyticAssessments: MobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      suspicionScore: PropTypes.number,
      minEventDate: PropTypes.string,
      maxEventDate: PropTypes.string,
    })
  ),
};

SuspicionScore.defaultProps = {
  analyticAssessments: [],
};

export default SuspicionScore;
