import React from 'react';
import { observer } from 'mobx-react';
import CaseStore from '../../stores/CaseStore';
import IncidentsToPrint from '../IncidentsToPrint';

export function mapProps({ CaseStore }) {
  return {
    filteredIncidentsForPrint: CaseStore.filteredIncidentsForPrint,
  };
}

const IncidentsToPrintContainer = observer(() => (
  <IncidentsToPrint
    {...mapProps({
      CaseStore,
    })}
  />
));

export default IncidentsToPrintContainer;
