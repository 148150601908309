import _defineProperty from "/home/runner/work/product/product/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { PRIVACY_PRODUCT, DIVERSION_PRODUCT, GRC_PRODUCT } from '../const';

/**
 * Gets a mapping of the roles applicable to each product, given by whether or not a role contains a specific permission
 * used to identify that product. Also returns a list of "general" roles that are not related to any product, identified
 * by the role not having any of these permissions.
 *
 * @param   allRoles  a set of all roles currently defined in the system
 */
export var getRolesByProduct = function getRolesByProduct(allRoles) {
  var _ref;
  return _ref = {}, _defineProperty(_ref, PRIVACY_PRODUCT, allRoles.filter(function (role) {
    return role.permissions.includes('PRIVACY_ASSESSMENT_VIEW');
  })), _defineProperty(_ref, DIVERSION_PRODUCT, allRoles.filter(function (role) {
    return role.permissions.includes('MEDICATION_STATISTICS_VIEW');
  })), _defineProperty(_ref, GRC_PRODUCT, allRoles.filter(function (role) {
    return role.permissions.includes('CASE_CREATE_GRC');
  })), _defineProperty(_ref, "General", allRoles.filter(function (role) {
    return !role.permissions.some(function (permission) {
      return ['PRIVACY_ASSESSMENT_VIEW', 'MEDICATION_STATISTICS_VIEW', 'CASE_CREATE_GRC'].includes(permission);
    });
  })), _ref;
};