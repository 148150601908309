import styles from './index.module.scss';
import React from 'react';
import PropTypes from 'prop-types';

const DropdownItem = ({ item }) => {
  const { label, name } = item;
  return (
    <span className={styles.rwInput}>
      <strong>{label}</strong> {name}
    </span>
  );
};

DropdownItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default DropdownItem;
