import React from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import moment from 'moment';
import { HalUtils } from 'common';
import EncounterTooltip from '../../EncounterTooltip';
import TimeEntryTooltip from '../../../ui/TimeEntryTooltip';

// responsible for drawing encounters and
// time entries in the x-axis
export default function drawVectorIcons(
  context,
  data,
  icon,
  type,
  brushedXScale
) {
  const svg = context;

  if (!svg) return;

  const circleGroups = svg
    .select('g.vector_icons')
    .selectAll('g.for_path')
    .data(data, d => HalUtils.getId(d));

  const enterSelection = circleGroups
    .enter()
    .append('g')
    .classed('for_path', true);
  const existingAndNewCircleGroups = circleGroups.merge(enterSelection);

  if (type === 'encounters') {
    existingAndNewCircleGroups.each(function(d) {
      ReactDOM.render(
        <EncounterTooltip encounter={d}>{icon}</EncounterTooltip>,
        this
      );
    });
  } else if (type === 'timeEntries') {
    existingAndNewCircleGroups.each(function(d) {
      ReactDOM.render(
        <TimeEntryTooltip timeEntry={d} source={d.source}>
          {icon}
        </TimeEntryTooltip>,
        this
      );
    });
  }

  existingAndNewCircleGroups.selectAll('g.icon_wrapper').each(function() {
    const elt = d3.select(this);
    const d = d3.select(this.parentNode).datum();
    let startTime = d.startTime || d.start,
      endTime = d.endTime || d.end;

    if (startTime && !endTime) endTime = moment(startTime).add(4, 'h');
    if (!startTime && endTime) startTime = moment(endTime).subtract(4, 'h');

    const d1 = moment(startTime).toDate(),
      x = brushedXScale(d1),
      d2 = moment(endTime).toDate(),
      x2 = brushedXScale(d2),
      xAvg = (x + x2) / 2 || x;

    elt.attr('transform', `translate(${xAvg - 7.5}, -17)`);

    if (xAvg < 0) elt.transition().style('opacity', 0);
    else elt.transition().style('opacity', 1);
  });
  //responsible for clearing up && update
  circleGroups.exit().remove();
}
