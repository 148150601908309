import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Source from '../Source';

/**
 * Simple badge for use on Card components. Creates a label with an icon and basic color.
 * @param {object} props - properties for the JSX node. Label, title, and icon are the necessary props.
 * @return {object} JSX Node
 */
export const CardBadge = props => {
  const { label, title, icon, link } = props;

  if (link) {
    return (
      <Link to={link}>
        <span className={classnames('label', `label-${label}`, 'label-icon')}>
          {title}
          <i className={classnames('material-icons', icon)} />
        </span>
      </Link>
    );
  } else {
    return (
      <span className={classnames('label', 'label-' + label, 'label-icon')}>
        {title}
        <i className={classnames('material-icons', icon)}></i>
      </span>
    );
  }
};

export class CardHeader extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    instance: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    source: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  };

  get header() {
    const title = this.props.title,
      link = this.props.link;

    let titleTag;

    if (link) {
      titleTag = <Link to={link}>{title}</Link>;
    } else {
      titleTag = title;
    }
    return (
      <h2 className="float-left" data-cy="case-card-link">
        {titleTag}
      </h2>
    );
  }

  get source() {
    if (this.props.source) {
      return (
        <Source
          instance={this.props.instance}
          source={this.props.source}
          className="float-none"
        />
      );
    }
    return null;
  }

  renderChildren() {
    let children = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children]; // coerce an Array
    children = children.filter(child => child);

    return (
      <ul className="list-inline list-inline_block float-right">
        {children.map((child, idx) => (
          <li key={`item_${idx}`}>{child}</li>
        ))}
      </ul>
    );
  }

  render() {
    return (
      <header className="card-header" data-cy="case-card-header">
        {this.renderChildren()}
        <div className="card__subtitle">{this.props.subtitle}</div>
        {this.header}
        {this.source}
      </header>
    );
  }
}

export class Card extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onSelected: PropTypes.func,
    linkTo: PropTypes.string,
    active: PropTypes.bool,
  };

  get classnames() {
    const _classnames = classnames(this.props.className, 'card');
    return _classnames;
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {
      children,
      className,
      onSelected,
      linkTo,
      active,
      ...props
    } = this.props;

    return (
      <article className={this.classnames} {...props}>
        {children}
      </article>
    );
  }
}
