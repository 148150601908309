import * as d3 from 'd3';
import moment from 'moment';
import { action, computed, decorate, observable, reaction } from 'mobx';

import { RouterContainer } from 'common';

import Store from '../Store';
import PastAndFutureAccessStore from '../PastAndFutureAccessStore';
import RelationshipStore from '../../../stores/RelationshipStore';
import DiversionActivityStore from '../DiversionActivityStore';
import MetalogStore from '../MetalogStore';
import formattedAge from '../../../utils/formattedAge';

export const scrollOffsetMargin = (rowHeight, height, yKeys = []) => {
  const totalRowHeight = (yKeys?.length ?? 0) * rowHeight;
  return totalRowHeight > height ? 15 : 0;
};

class ChartStore {
  constructor() {
    // when the xExtent changes based on the dates, overwrite the brush state
    // or when metalogs change, reset brush completely
    reaction(
      () => [this.xExtent],
      ([xExtent]) => this.resetAction(xExtent)
    );

    // found multiple "others" with the same name, check to see if they are aliased
    reaction(
      () => [this.otherAliasCandidates],
      () => {
        this.otherAliasCandidates.forEach(id =>
          Store.isUserFocus
            ? RelationshipStore.fetchPatient(id)
            : RelationshipStore.fetchUser(id)
        );
      },
      { fireImmediately: false }
    );

    reaction(
      () => [Store.subFilter1, Store.subFilter2, this.yKeys],
      () => {
        const rowFocused =
          Store.focus === 'patient' || Store.focus === 'employee';
        // user has filtered to where the old row no longer appears - redirect to the first row as a replacement
        if (
          (Store.subFilter1 || Store.subFilter2) &&
          rowFocused &&
          !this.yKeys.includes(this.selectedKey) &&
          this.yKeys.length
        ) {
          const userId = this.yKeys[0].substr(
            this.yKeys[0].length / 2,
            this.yKeys[0].length - 1
          );
          const patientId = this.yKeys[0].substr(0, this.yKeys[0].length / 2);
          const focus = Store.isUserFocus ? 'userPatient' : 'patientUser';

          // patient detail view
          if (focus === 'patientUser' && !Store.userIdParam)
            return RouterContainer.go(
              Store.getPatientLink({ patientId }),
              undefined,
              true
            );
          // User detail view
          if (focus === 'userPatient' && !Store.patientIdParam)
            return RouterContainer.go(
              Store.getUserLink({ userId }),
              undefined,
              true
            );
          // else activity view
          return RouterContainer.go(
            Store.getUserPatientLinks({ patientId, userId })[
              `${focus}Activity`
            ],
            undefined,
            true
          );
        }
      },
      { fireImmediately: false }
    );
  }

  // Action
  //
  // If the detail view's date of access changes, possibly adjust the selected
  // dates to accommodate.
  updateDateOfAccess(dateOfAccess) {
    const date = moment(new Date(dateOfAccess));
    const extent = this.brushExtent.map(i => moment(new Date(i)));
    const xExtent = this.xExtent.map(i => moment(new Date(i)));

    const range = extent[1].diff(extent[0]);

    // check if the date is outside of the extent (not visible)
    if (date.diff(extent[0]) <= 0 || date.diff(extent[1]) >= 0) {
      // recenter the brush extent
      let newStart = date.clone().subtract(range / 2, 'ms'),
        newEnd = date.clone().add(range / 2, 'ms');

      if (newStart.diff(xExtent[0]) <= 0) {
        newStart = xExtent[0].clone();
        newEnd = newStart.clone().add(range, 'ms');
      } else if (newEnd.diff(xExtent[1]) >= 0) {
        newEnd = xExtent[1].clone();
        newStart = newEnd.clone().subtract(range, 'ms');
      }

      this.selectDates([newStart.toDate(), newEnd.toDate()]);
      this.scrollTransition = true;
    }
  }

  // Action
  resetAction(newXExtent) {
    this.scrollTransition = false;
    this.brushExtent = newXExtent;
  }

  // Observables
  width = 500;
  height = 0;
  /**
   * The brushed extent of the x range to show in the vis. This defaults to the overall xExtent,
   * but is overwritten if the brush changes.
   * @type {d3.svg.brush}
   */
  brushExtent = this.xExtent;

  // NOT observables
  tickFormat = date => {
    return d3.timeFormat(
      [
        // Dealing with millisecond precision.
        ['.%L', d => d.getMilliseconds()],
        // Dealing with second precision.
        [':%S', d => d.getSeconds()],
        // Dealing with minute precision.
        ['%I:%M %p', d => d.getMinutes()],
        // Dealing with hour precision.
        ['%I %p', d => d.getHours()],
        // Very common case: represents a calendar day. Only fall through if
        // this is the first day of the year.
        ['%b %d', d => !(d.getDate() === 1 && d.getMonth() === 0)],
        // Final case: represents the first of the year.
        ['%Y', () => true],
      ].find(possibility => possibility[1](date))[0]
    )(date);
  };

  padding = {
    left: 15,
    right: 15,
  };

  margin = {
    left: 240,
    right: 20,
    bottom: 40,
  };

  rowHeight = 50;

  /**
   * True if the brushExtent is changing as a result of a scrollLeft operation.
   * This tells the vis to use a transition to animate the change.
   *
   * @type {Boolean}
   */
  scrollTransition = false;

  /**
   * This shouldn't need to be observable. If it needs to be observable, then there is a potential cycle.
   * @type {Number}
   */
  defaultY = -1;

  // Computed
  get showUserOnly() {
    return (
      Store.isUserFocus &&
      Store.user &&
      MetalogStore.userOnlyMetalogs.length !== 0
    );
  }

  // Computed
  get showDiversion() {
    return (
      Store.shouldViewDiversionActivity &&
      !MetalogStore.loading && // While the diversion row doesn't use metalogs we are looking at this value to stay in sync renderwise with other rows
      !DiversionActivityStore.loading &&
      DiversionActivityStore.statistics.length !== 0
    );
  }

  // Computed
  get otherFocus() {
    return Store.focus === 'patient' ? 'user' : 'patient';
  }

  // Computed
  get y() {
    const o = this.otherFocus;
    return a => a[o];
  }

  // Computed
  get x() {
    const f = Store.focus === 'patient' ? 'patient' : 'user';
    return a => a[f];
  }

  /**
   * COMPUTED
   * Returns the focus keys, aka the user and patient ids for the selected row
   */
  get focusKeys() {
    const x = this.x;
    const y = this.y;

    return a => [
      (a && x(a) && x(a).id) || undefined,
      (a && y(a) && y(a).id) || undefined,
    ];
  }

  // Computed
  get yLabel() {
    const y = this.y;
    const { activeLabel } = Store;
    if (activeLabel === 'dob') {
      return a => {
        const dob = y(a[0]).dateOfBirth;
        const isDeceased = y(a[0]).dateOfDeath || y(a[0]).deceased;
        const dod = y(a[0]).dateOfDeath;

        if (!dob) return 'Unknown';
        if (isDeceased && !dod) return 'deceased';

        const age = formattedAge({
          dateOfBirth: dob,
          deceased: isDeceased,
          dateOfDeath: dod,
        });
        return `${age}${dod ? ' (deceased)' : ''}`;
      };
    } else if (activeLabel === 'dod') {
      return a => {
        const dod = y(a[0]).dateOfDeath;
        if (!dod && y(a[0]).deceased) return 'Deceased';
        if (dod)
          return moment(dod)
            .utc()
            .format('l');
        return 'Unknown';
      };
    } else if (activeLabel === 'mrn') {
      return a =>
        (y(a[0]).medicalRecordNumbers && y(a[0]).medicalRecordNumbers[0]) ||
        'Unknown';
    } else if (activeLabel === 'sex') {
      return a => y(a[0]).sex || 'Unknown';
    } else if (activeLabel === 'patientId') {
      return a => y(a[0]).patientId || 'Unknown';
    } else if (activeLabel === 'suspicionScore') {
      return a => {
        const scores = a
          .filter(m => m.minSuspicionScore != null)
          .map(m => [m.minSuspicionScore, m.maxSuspicionScore])
          .reduce((min, max) => min.concat(max), []);
        if (scores.length) {
          const min = Math.round(Math.min(...scores) * 100);
          const max = Math.round(Math.max(...scores) * 100);
          if (max - min < 1) return `${min}`;
          return `${min} - ${max}`;
        }
        return 'Unknown';
      };
    } else if (activeLabel === 'id') {
      // only user ids are available as labels for now
      return a => y(a[0]).userId || 'Unknown';
    } else if (activeLabel === 'title') {
      return a => y(a[0]).title || 'Unknown';
    } else if (activeLabel === 'organization') {
      return a => y(a[0]).organization || 'Unknown';
    }

    // 'name' is the default
    return a => {
      const p = y(a[0]);
      const fn = (p && p.fullName) || 'Unknown';
      let disp = fn;
      if (fn.length > 27 && p.firstName) {
        disp = `${p.firstName.substr(0, 1)}. ${p.lastName}`;
      }
      return disp;
    };
  }

  // Computed
  get yIndex() {
    const rh = this.rowHeight;
    return y => y / rh;
  }

  // Computed
  get hasPastAccesses() {
    return Boolean(PastAndFutureAccessStore.past);
  }

  // Computed
  get hasFutureAccesses() {
    return Boolean(PastAndFutureAccessStore.future);
  }

  // Computed
  get xExtent() {
    return [
      Store.fromDate
        .clone()
        .startOf('day')
        .toDate(),
      Store.toDate
        .clone()
        .endOf('day')
        .toDate(),
    ];
  }

  // Computed
  get scrollTrackOffset() {
    return scrollOffsetMargin(this.rowHeight, this.height, this.yKeys);
  }

  // Computed
  get brushedXScale() {
    const upperBound = Math.max(
      0,
      this.width -
        this.margin.left -
        this.margin.right -
        this.scrollTrackOffset -
        10
    );

    return d3
      .scaleTime()
      .range([this.padding.left, upperBound])
      .domain(this.brushExtent)
      .clamp(false);
  }

  // Computed
  get rawKeys() {
    const entries = d3
      .nest()
      .key(d => this.yKey(d))
      .entries(MetalogStore.fullMetalogs || []);

    return entries.map(i => i.key);
  }

  // Computed
  get yKeys() {
    const entries = d3
      .nest()
      .key(d => this.yKey(d))
      .entries(this.data || []);

    if (Store.sort !== 'startTime,asc') entries.sort(this.sortKeys);

    return entries.map(i => i.key);
  }

  // Computed
  get yRange() {
    const yRange = [];
    const yKeys = this.yKeys;

    yKeys.forEach((i, index) => {
      yRange.push(index * this.rowHeight);
    });
    // set a default value to be one more than the maxY. This is also used
    // to size the SVG so we can't use anything ridiculous
    const defaultY = yRange[yRange.length - 1] + 1;

    // grrr when an ordinal scale is out of values, then it starts at the beginning
    // and we need to be able to test when a value is the default
    for (let i = 0; i < 100000; i++) {
      yRange.push(defaultY);
    }

    this.defaultY = defaultY;
    return yRange;
  }

  // Computed
  get yScale() {
    return d3
      .scaleOrdinal()
      .domain(this.yKeys)
      .range(this.yRange);
  }

  // Computed
  get selectedKey() {
    const pid = Store.patientIdParam;
    const uid = Store.userIdParam;
    // this must match up with functions.yKey values (concatenate IDs)
    if (pid && uid) return pid + uid;
    if (uid) return uid;
    return false;
  }

  /**
   * COMPUTED
   * Builds a list of potential aliases based on names for the opposite focus or "other" (i.e.
   * patient candidates when focused on a user, user candidates when focused on a patient)
   */
  get otherAliasCandidates() {
    const candidates = {};
    /*
      Example candidates object:
      {
        JohnSmith: { 10-10-1990: [ {id: JohnSmith1}, {...} ],
                     10-11-1991: [ {...} ],
                     unknown: [ {...} ]
                    },
      }
    */

    MetalogStore.fullMetalogs.forEach(m => {
      const other = Store.isUserFocus ? m.patient : m.user;
      if (other.fullName) {
        const names = other.fullName.split(' ');
        let name = names[0];
        if (names.length > 1 && other.lastName) name += `${other.lastName}`;

        const dob = other.dateOfBirth || 'Unknown';

        if (!candidates[name]) {
          // have yet to see someone with this name
          candidates[name] = {};
          candidates[name][dob] = [other];
        } else if (!candidates[name][dob]) {
          // have seen someone with this name, but not with this date of birth
          candidates[name][dob] = [other];
        } else {
          // have seen someone with this name + dob, add them to the list of matches
          if (!candidates[name][dob].find(c => c.id === other.id))
            candidates[name][dob].push(other);
        }
      }
    });

    let finalCandidates = [];
    Object.keys(candidates).forEach(c => {
      // if you have a John Smith with an unknown dob and there's other JS out there,
      // we need to add to final candidates as it may be the same person
      if (
        candidates[c].Unknown &&
        (Object.keys(candidates[c]).length > 1 ||
          candidates[c].Unknown.length > 1)
      ) {
        finalCandidates = finalCandidates.concat(candidates[c].Unknown);
      }
      Object.keys(candidates[c])
        .filter(dob => dob !== 'Unknown' && candidates[c][dob].length > 1)
        .forEach(dob => {
          finalCandidates = finalCandidates.concat(candidates[c][dob]);
        });
    });

    return finalCandidates.map(f => f.id);
  }

  /**
   * COMPUTED
   * Retrieves alias relationships based on the list of potential aliases for the opposite focus or
   * "other" (i.e. patient candidates when focused on a user, user candidates when focused on a patient)
   */
  get otherAliases() {
    const aliases = {};

    // we're only interested in aliases that appear in the chart, so collect all visible ids first
    const visibleIds = new Set();
    this.rawKeys.forEach(key => {
      visibleIds.add(key.substr(0, key.length / 2))
      visibleIds.add(key.substr(key.length / 2, key.length - 1));
    })

    // for each of the name-matched patients, check to see if they have an actual alias
    this.otherAliasCandidates.forEach(id => {
      const relationships = Store.isUserFocus
        ? RelationshipStore.patientRelationships.get(id)
        : RelationshipStore.userRelationships.get(id);
      if (relationships) {
        relationships
          .filter(
            r => 
              r.relationships.includes('alias') && visibleIds.has(r.ids[1].id) &&
              // Filter for people aliases of the same type user->user and patient->patient
              ((r.people?.[0]?.userId && r.people?.[1]?.userId) ||
              (r.people?.[0]?.patientId && r.people?.[1]?.patientId))
          )
          .forEach(r => {
            const addToSet = (id, otherId) => {
              let aliasSet = aliases[id];
              if (!aliasSet) {
                aliasSet = new Set();
                aliases[id] = aliasSet;
              }

              // the aliased user/patient appears in the chart, so we'll need it for duplicating accesses
              aliasSet.add(otherId);
            };

            addToSet(id, r.ids[1].id);
            addToSet(r.ids[1].id, id);
          });
      }
    });

    return aliases;
  }

  // Computed
  get data() {
    // need to duplicate metalogs for aliased users/patients
    if (Object.keys(this.otherAliases).length > 0) {
      const duplicateLogs = [];
      const aliasedFocus = {};
      MetalogStore.fullMetalogs.forEach(m => {
        const other = Store.isUserFocus ? m.patient : m.user;
        const aliases = this.otherAliases[other.id];
        // metalog has an aliased access, so duplicate and swap in the aliased user/patient
        if (aliases) {
          // keep a running list of which users are accessing each patient
          aliasedFocus[other.id] = Store.isUserFocus ? m.user : m.patient;
          aliases.forEach(alias => {
            const aliasedOther = Object.assign({}, other, {
              id: alias,
              aliasId: other.id,
            });
            const duplicate = Object.assign(
              {},
              m,
              Store.isUserFocus
                ? { patient: aliasedOther }
                : { user: aliasedOther }
            );
            duplicateLogs.push(duplicate);
          });
        }
      });

      // swap in aliased users/patients
      duplicateLogs.forEach(m => {
        if (Store.isUserFocus) {
          m.user = Object.assign({}, aliasedFocus[m.patient.id], {
            aliasId: m.user.id,
          });
        } else {
          m.patient = Object.assign({}, aliasedFocus[m.user.id], {
            aliasId: m.patient.id,
          });
        }
      });

      return MetalogStore.fullMetalogs.concat(duplicateLogs);
    }

    // no row-merging necessary, return server-side data
    return MetalogStore.fullMetalogs;
  }

  /**
   * COMPUTED
   * @return {integer} The width of the brush (px)
   */
  get brushChartWidth() {
    return (
      this.width -
      this.margin.left -
      (Store.filterOptions.length === 1 ? 100 : 180)
    );
  }

  /**
   * COMPUTED
   * The xScale used to translate between brush chart x coordinates and dates.
   * @return {function} d3 scale function
   */
  get brushChartXScale() {
    return d3
      .scaleTime()
      .range([0, Math.max(this.brushChartWidth, 15) - 15])
      .domain(this.xExtent)
      .clamp(true);
  }

  // Computed
  get brushChartAllowStartCut() {
    const x = this.brushChartXScale(this.brushExtent[0]);
    // show the cut icon when brush is 25 pixels from the min
    return x > 25 && Store.toDate.diff(Store.fromDate) > 0;
  }

  // Computed
  get brushChartAllowEndCut() {
    const x = this.brushChartXScale(this.brushExtent[1]);
    // show the cut icon when brush is 25 pixels from the max
    return (
      x < this.brushChartWidth - 25 && Store.toDate.diff(Store.fromDate) > 0
    );
  }

  // Computed
  get brushChartIsHidden() {
    return !!Store.badDates;
  }

  // Computed
  get visualizationWidth() {
    // add the last offset in to account for the ellipses if present;
    // otherwise, the visualization should extend all the way to the right-hand
    // edge of the SVG container
    // Additionally, this will account for the presence or absence of a
    // scrollbar track.
    return Math.max(
      0,
      this.width -
        this.margin.left -
        this.padding.left -
        this.padding.right -
        this.scrollTrackOffset
    );
  }

  yKey(e) {
    if (!e) return undefined;

    let userId;
    if (!e.class || e.class === 'access' || e.class === 'administration')
      userId = e.user && e.user.id;
    else if (e.class === 'order') {
      if (Store.activeUsers.includes(e.orderedBy && e.orderedBy.id)) {
        userId = e.orderedBy.id;
      } else {
        userId = e.createdBy && e.createdBy.id;
      }
    } else if (e.class === 'handling') {
      if (Store.activeUsers.includes(e.witnessedBy && e.witnessedBy.id)) {
        userId = e.witnessedBy.id;
      } else {
        userId = e.user && e.user.id;
      }
    } else if (e.class === 'discrepancy') {
      if (Store.activeUsers.includes(e.witnessedBy && e.witnessedBy.id)) {
        userId = e.witnessedBy.id;
      } else {
        userId = e.resolvedBy && e.resolvedBy.id;
      }
    }

    if (e.patient) {
      return e.patient.id + userId || undefined;
    }

    return userId;
  }

  sortKeys(a, b) {
    let aRow = a.values[0];
    let bRow = b.values[0];

    const sort = Store.sort.split(',');
    const sortBy = sort[0].split('.');

    // nested sort field (e.g. patient.medicalRecordNumbers)
    if (sortBy.length === 2) {
      aRow = aRow[sortBy[0]];
      bRow = bRow[sortBy[0]];

      sortBy.splice(0, 1);
    }

    let aValue = aRow[sortBy[0]];
    let bValue = bRow[sortBy[0]];

    // special case for suspicion scores, which is based on metalogs
    if (sortBy[0] === 'suspicionScore') {
      aValue = Math.round(
        Math.max(
          ...a.values
            .filter(m => m.maxSuspicionScore !== undefined)
            .map(m => m.maxSuspicionScore)
        ) * 100
      );
      bValue = Math.round(
        Math.max(
          ...b.values
            .filter(m => m.maxSuspicionScore !== undefined)
            .map(m => m.maxSuspicionScore)
        ) * 100
      );

      // same max suspicion - secondary sort on min suspicion
      if (aValue !== undefined && bValue !== undefined && aValue === bValue) {
        aValue = Math.min(
          ...a.values
            .filter(m => m.minSuspicionScore !== undefined)
            .map(m => m.minSuspicionScore)
        );
        bValue = Math.min(
          ...b.values
            .filter(m => m.minSuspicionScore !== undefined)
            .map(m => m.minSuspicionScore)
        );
      }
    }

    // special case for mrns, which is an array
    if (sortBy[0] === 'medicalRecordNumbers') {
      aValue = aValue && aValue[0];
      bValue = bValue && bValue[0];
    }

    // special case for age, which sorts backwards
    if (sortBy[0] === 'dateOfBirth')
      sort[1] = sort[1] === 'asc' ? 'desc' : 'asc';

    // group the unknowns at the top or bottom
    if (!aValue) return sort[1] === 'asc' ? 1 : -1;
    if (!bValue) return sort[1] === 'asc' ? -1 : 1;

    if (aValue < bValue) return sort[1] === 'asc' ? -1 : 1;
    if (aValue > bValue) return sort[1] === 'asc' ? 1 : -1;
    return 0;
  }

  // Action
  brushChartCut = () => {
    const start = moment(this.brushExtent[0]);
    let end = moment(this.brushExtent[1]);
    if (start.diff(end) > 0) end = moment(start);

    RouterContainer.go(
      undefined,
      Store.getQuery({
        fromDate: start.startOf('day'),
        toDate: end.startOf('day'),
      })
    );
  };

  // Action
  selectDates = dates => {
    this.scrollTransition = false;
    this.brushExtent = dates;
  };

  // Action
  setWidth = width => {
    this.width = width;
  };
}

decorate(ChartStore, {
  // OBSERVABLES
  width: observable,
  height: observable,
  brushExtent: observable,
  // COMPUTEDS
  showUserOnly: computed,
  otherFocus: computed,
  y: computed,
  x: computed,
  focusKeys: computed,
  yLabel: computed,
  yIndex: computed,
  hasPastAccesses: computed,
  hasFutureAccesses: computed,
  xExtent: computed,
  scrollTrackOffset: computed,
  brushedXScale: computed,
  rawKeys: computed,
  yKeys: computed,
  yRange: computed,
  yScale: computed,
  selectedKey: computed,
  otherAliasCandidates: computed,
  otherAliases: computed,
  data: computed,
  brushChartWidth: computed,
  brushChartXScale: computed,
  brushChartAllowStartCut: computed,
  brushChartAllowEndCut: computed,
  brushChartIsHidden: computed,
  visualizationWidth: computed,
  // ACTIONS
  updateDateOfAccess: action,
  resetAction: action,
  brushChartCut: action,
  selectDates: action,
  setWidth: action,
});

export default new ChartStore();
