import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '../../../ui/Tooltip';
import classnames from 'classnames';

export const MetadataDetail = ({ metadata }) => {
  return (
    <div className={styles.metadataDetail}>
      <i className={`material-icons ${metadata.icon}`}></i>
      {metadata?.data?.metadataMulticolumnText ? (
        <div className={styles.metadataMultilineTextContainer}>
          {metadata?.data?.metadataMulticolumnText?.map((metadataText, i) => {
            return (
              <Link
                key={`${metadataText.text}${i}`}
                to={metadataText.link}
                onClick={e => metadataText?.disabled && e.preventDefault()}
                className={classnames({
                  [styles.metadataLinkDisabled]: metadataText?.disabled,
                })}
              >
                <div className={styles.metadataRow}>
                  <p
                    className={classnames(styles.metadataDetailText, {
                      [styles.metadataDetailTextDisabled]:
                        metadataText?.disabledStyle,
                    })}
                  >
                    {metadataText.textLeftCol}
                  </p>
                  <Tooltip content={metadataText?.tooltipContent}>
                    <p
                      className={classnames({
                        [styles[metadataText.className]]:
                          metadataText.styleCondition,
                      })}
                    >
                      {metadataText.textRightCol}
                    </p>
                  </Tooltip>
                </div>
              </Link>
            );
          })}
        </div>
      ) : metadata?.data?.metadataMultilineText ? (
        <div className={styles.metadataMultilineTextContainer}>
          {metadata?.data?.metadataMultilineText?.map((metadataText, i) => {
            return (
              <Link
                key={`${metadataText.text}${i}`}
                to={metadataText.link}
                onClick={e => metadataText?.disabled && e.preventDefault()}
                className={classnames({
                  [styles.metadataLinkDisabled]: metadataText?.disabled,
                })}
              >
                <div
                  className={classnames(styles.metadataDetailText, {
                    [styles.metadataDetailTextDisabled]: metadataText?.disabled,
                  })}
                >
                  {metadataText.text}
                </div>
              </Link>
            );
          })}
        </div>
      ) : (
        <Link
          to={metadata.link}
          onClick={e => !metadata.link && e.preventDefault()}
        >
          <p className={styles.metadataDetailText}>
            {metadata?.data?.metadataText}
          </p>
        </Link>
      )}
    </div>
  );
};

MetadataDetail.propTypes = {
  metadata: PropTypes.object,
};
