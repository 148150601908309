import React from 'react';
import { computed, decorate } from 'mobx';
import { observer } from 'mobx-react';

import TopNavBar from '../../navbar/TopNavBar';
import NavBarItem from '../../navbar/NavBarItem';
import Store from '../stores/Store';

const TopNav = observer(
  class TopNav extends React.Component {
    // Computed
    get links() {
      return Store.getUserPatientLinks();
    }

    renderUserButton() {
      return (
        <NavBarItem
          active={Store.focus === 'employee'}
          route={this.links.emrUserWorkflow}
          onClick={() => Store.setOptionsVisible(false)}
        >
          EMR User Workflow
        </NavBarItem>
      );
    }

    renderPatientButton() {
      return (
        <NavBarItem
          active={Store.focus === 'patient'}
          route={this.links.patientCareflow}
          onClick={() => Store.setOptionsVisible(false)}
        >
          Patient Careflow
        </NavBarItem>
      );
    }

    renderCompareButton() {
      let route = this.links.patientUser;
      if (/^employee/.test(Store.focus)) {
        route = this.links.userPatient;
      }
      const active =
        Store.focus === 'patient_employee' ||
        Store.focus === 'employee_patient';
      return (
        <NavBarItem active={active} route={route}>
          Compare
        </NavBarItem>
      );
    }

    render() {
      if (Store.userIdParam && Store.patientIdParam) {
        return (
          <TopNavBar filterbar>
            {this.renderUserButton()}
            {this.renderCompareButton()}
            {this.renderPatientButton()}
          </TopNavBar>
        );
      } else {
        return <TopNavBar />;
      }
    }
  }
);

decorate(TopNav, {
  links: computed,
});

TopNav.displayName = 'TopNav';

export default TopNav;
