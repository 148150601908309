import { PRIVACY_PRODUCT, DIVERSION_PRODUCT, GRC_PRODUCT } from '../const';

/**
 * Gets the products that the current user has access to, based on select permissions expected to be present when
 * these products are available.
 *
 * @param   permissionStore   a PermissionStore instance representing the permissions of the logged-in user
 */
export var getProducts = function getProducts(permissionStore) {
  var products = [];
  if (permissionStore.has('PRIVACY_ASSESSMENT_VIEW')) products.push(PRIVACY_PRODUCT);
  if (permissionStore.has('MEDICATION_STATISTICS_VIEW')) products.push(DIVERSION_PRODUCT);
  if (permissionStore.has('CASE_CREATE_GRC')) products.push(GRC_PRODUCT);
  return products;
};