import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './index.module.scss';

const ViaSource = ({ source, instance, environment, asOfDate }) => {
  if (!source) return null;

  const displayText = `${source}${environment ? `-${environment}` : ''}${
    instance ? ` (${instance})` : ''
  }`;
  const asOfText = asOfDate
    ? ` as of ${moment(asOfDate).format('M/D/YYYY')}`
    : null;

  return (
    <span className={styles.viaSource}>
      via <strong>{displayText}</strong>
      {asOfText}
    </span>
  );
};

ViaSource.propTypes = {
  source: PropTypes.string.isRequired,
  instance: PropTypes.string,
  environment: PropTypes.string,
  asOfDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]),
};

ViaSource.defaultProps = {
  instance: null,
  environment: null,
  asOfDate: null,
};

export default ViaSource;
