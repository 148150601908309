import { MultiRecordUserPatientDetails } from '../MultiRecordUserPatientDetails';
import { MultiRecordViewContext } from '../Context';
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory';
import { withRouterAndQuery } from '../../utils/RouteHelper';
import CaseStore from '../../case/stores/CaseStore/index.js';

export function mapProps({ location }) {
  const { type, patientId, selectedDetailsOption } = CaseStore;
  return {
    isDiversion: type === 'diversion',
    patientId,
    selectedDetailsOption,
    isUserDetailsView: location.pathname.includes('/userDetails'),
  };
}

export const MultiRecordUserPatientDetailsContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: MultiRecordViewContext,
    mapProps,
    PresentationalComponent: MultiRecordUserPatientDetails,
  })
);
