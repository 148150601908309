import React from 'react';
import PropTypes from 'prop-types';

/*
* Text Formatter
-------------------
* A stateless react component that
* - converts '/n' to <br /> for line break.
* - converts urls in the text to links.
*
* This is a good place for adding more text formatting features.
*
* @param {Object}
*  text: string - text string
* @return {Object} Paragraph DOM
*/

// Regular expression that determines if a string is close enough to a url.
// This does NOT perfectly capture every possible case, but is a reasonable
// approximation.
export const URL_REGEX = new RegExp(
  '^(https?:[/][/])?' + // optional scheme, e.g. https://
  '[a-z0-9]+([-.][a-z0-9]+)*' + // (sub)domains, e.g. www.google
  '([.][a-z]{2,})+' + // tld(s), e.g. .com.mx
  '(:[0-9]{1,5})?' + // optional port, e.g. :8080
    '[^@]*$', // anything after the host, like path, hash, query.
  'i'
);

export function LinkOrText({ text }) {
  if (URL_REGEX.test(text)) {
    const url = text.startsWith('http') ? text : 'https://' + text;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {' ' + text}
      </a>
    );
  } else return <>{' ' + text}</>;
}

LinkOrText.propTypes = {
  text: PropTypes.string.isRequired,
};

const TextFormatter = ({ text }) => {
  if (text && typeof text === 'string') {
    return text.split('\n').map(t =>
      t ? (
        <React.Fragment key={Math.random()}>
          <span>
            {t.split(' ').map((t, i) => (
              <LinkOrText key={t + i} text={t} />
            ))}
          </span>
          <br />
        </React.Fragment>
      ) : (
        <br key={Math.random()} />
      )
    );
  }
  return null;
};

TextFormatter.propTypes = {
  text: PropTypes.string,
};

TextFormatter.defaults = {
  text: null,
};

export default TextFormatter;
