import React from 'react';
import PropTypes from 'prop-types';

/**
 * Layer responsible for drawer gutters on either side of the chart
 */

const GutterLayer = ({ leftMargin, leftPadding, rightPadding }) => {
  return (
    <g>
      {/* Left gutter for past/future accesses */}
      <rect
        width="1"
        height="300%"
        transform={`translate(${leftMargin - 1}, 0)`}
        fill="#dddbe5"
      />
      <rect
        width="1"
        height="300%"
        transform={`translate(${leftMargin + leftPadding - 1}, 0)`}
        fill="#dddbe5"
      />
      {/* Right gutter for past/future accesses */}
      <rect
        width="1"
        height="300%"
        transform={`translate(${(rightPadding + 1) * -1}, 0)`}
        x="100%"
        y="0"
        fill="#dddbe5"
      />
    </g>
  );
};

GutterLayer.propTypes = {
  leftMargin: PropTypes.number,
  leftPadding: PropTypes.number,
  rightPadding: PropTypes.number,
};

GutterLayer.defaultProps = {
  leftMargin: 240,
  leftPadding: 15,
  rightPadding: 20,
};

export default GutterLayer;
