import moment from 'moment';

export function parse(text, formats) {
  for (let i = 0; i < formats.length; i++) {
    const m = moment(text, formats[i], true);
    if (m.isValid()) return m;
  }
}

export function isValid(dateText, formats, min, max, inclusive) {
  const m = parse(dateText, formats);

  if (m && m.isValid()) {
    if (min && max)
      return (
        (inclusive && (m.isSame(min) || m.isSame(max))) || m.isBetween(min, max)
      );
    else if (min) return (inclusive && m.isSame(min)) || m.isAfter(min);
    else if (max) return (inclusive && m.isSame(max)) || m.isBefore(max);
    return true;
  }

  return false;
}
