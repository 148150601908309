import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import cn from 'classnames';
import { DateTimePicker, DropdownList } from 'react-widgets';
import moment from 'moment';
import { filterOptionsPropType } from '../VipsFilters/propTypes';
import { AsidePanel } from '../../ui';
import styles from './index.module.scss';
import DateSelector from '../../ui/Filters/DateSelector';
import DropdownValueComponentFactory from '../../ui/DropdownValueComponentFactory';
import ExclusionMultiselect from '../../ui/Filters/ExclusionMultiselect';
import Button from '../../ui/Button';

const CLEARED_FILTERS_PATH = '/vips?showFilters=true';

const AdvancedFilters = ({
  advancedFiltersVisible,
  createdByFilterOptions,
  onToggleExclusion,
  filters,
  lastModifiedByFilterOptions,
  onCalendarChange,
  onChangeDropdown,
  onDOBChange,
  onToggleAdvancedFilters,
  advancedFiltersPath,
  historyPush,
  statusFilterOptions,
  typesFilterOptions,
}) => {
  // Check to see if any filters are actually applied. If not, we will enable
  // the "Clear Filters" button.
  // showFilters is always there, and types is an empty array when not selected.
  //
  const hasFiltersApplied =
    Object.entries(filters).filter(
      ([key, value]) => !(key === 'showFilters' || value?.length === 0)
    ).length > 0;

  return (
    <AsidePanel
      title="ADVANCED FILTERS"
      isOpen={advancedFiltersVisible}
      leftAnimation
      actionNavHeight
      onClose={onToggleAdvancedFilters}
    >
      <form className={styles.section} data-cy="vip-advanced-search">
        <div className={styles.indent}>
          <h3>
            <i className={cn(`material-icons icon-star`, styles.icon)} />
            VIP Details
          </h3>
          <input
            type="text"
            placeholder="First and/or Last Name"
            value={filters.name || ''}
            onChange={e => onChangeDropdown(e.target.value, 'name')}
          />
          <DateTimePicker
            placeholder="Date of Birth"
            format="M/D/YYYY"
            className={styles.dateOfBirth}
            value={filters.dob ? moment(filters.dob).toDate() : null}
            onChange={onDOBChange}
            time={false}
          />
          <DropdownList
            className={styles.bottomMargin}
            data={statusFilterOptions.options}
            valueField="value"
            textField="name"
            value={filters.status}
            onChange={value => onChangeDropdown(value, 'status')}
            valueComponent={DropdownValueComponentFactory('Status')}
          />
        </div>
        <ExclusionMultiselect
          data={typesFilterOptions}
          filters={filters}
          onToggleExclusion={() => onToggleExclusion(typesFilterOptions.param)}
          onChange={types => {
            onChangeDropdown(types, typesFilterOptions.param);
          }}
        />
        <DateSelector
          value="endDate"
          label="Expiration Date"
          filters={filters}
          onChangeDropdown={onChangeDropdown}
          onToggleExclusion={() => onToggleExclusion('endDate')}
          onCalendarChange={onCalendarChange}
          alignDropdownRight
          data-cy="vip-expiration--dropdown"
        />
        <DateSelector
          value="created"
          label="Created Date"
          filters={filters}
          onChangeDropdown={onChangeDropdown}
          onToggleExclusion={() => onToggleExclusion('created')}
          onCalendarChange={onCalendarChange}
          alignDropdownRight
        />
        <ExclusionMultiselect
          data={createdByFilterOptions}
          onToggleExclusion={() =>
            onToggleExclusion(createdByFilterOptions.param)
          }
          filters={filters}
          onChange={people =>
            onChangeDropdown(people, createdByFilterOptions.param)
          }
          valueField="value"
          textField="name"
        />

        <DateSelector
          value="lastModified"
          label="Modified Date"
          filters={filters}
          onChangeDropdown={onChangeDropdown}
          onToggleExclusion={() => onToggleExclusion('lastModified')}
          onCalendarChange={onCalendarChange}
          dropUp
          alignDropdownRight
        />
        <ExclusionMultiselect
          data={lastModifiedByFilterOptions}
          filters={filters}
          onChange={people =>
            onChangeDropdown(people, lastModifiedByFilterOptions.param)
          }
          onToggleExclusion={() =>
            onToggleExclusion(lastModifiedByFilterOptions.param)
          }
          valueField="value"
          textField="name"
          dropUp
        />
        <div className={styles.indent}>
          <Button
            type="submit"
            className={cn(styles.button, 'button--block')}
            onClick={e => {
              e.preventDefault();
              historyPush(advancedFiltersPath);
            }}
            value="Search"
            data-cy="vip-advanced--submit"
          />
          <Button
            disabled={!hasFiltersApplied}
            className={styles.button}
            invert
            onClick={e => {
              e.preventDefault();
              historyPush(CLEARED_FILTERS_PATH);
            }}
            value="Clear Filters"
          />
        </div>
      </form>
    </AsidePanel>
  );
};

AdvancedFilters.propTypes = {
  advancedFiltersVisible: PropTypes.bool.isRequired,
  createdByFilterOptions: filterOptionsPropType.isRequired,
  statusFilterOptions: filterOptionsPropType.isRequired,
  typesFilterOptions: filterOptionsPropType.isRequired,
  onToggleExclusion: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    dob: PropTypes.string,
    status: PropTypes.string,
    types: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
  }).isRequired,
  lastModifiedByFilterOptions: filterOptionsPropType.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onCalendarChange: PropTypes.func.isRequired,
  onDOBChange: PropTypes.func.isRequired,
  onToggleAdvancedFilters: PropTypes.func.isRequired,
  advancedFiltersPath: PropTypes.string.isRequired,
  historyPush: PropTypes.func.isRequired,
};

export default AdvancedFilters;
