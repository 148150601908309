import { observerWrappedContainerFactory } from '../../../utils/observerWrappedContainerFactory';
import { CaseCustomPrintContext } from '../../Context';
import DetailsPrintOptions from '../DetailsPrintOptions';

export function mapProps({ caseCustomPrintStore, caseStore }) {
  return {
    selectedDetailsOption: caseCustomPrintStore.selectedDetailsOption,
    detailsOptions: caseCustomPrintStore.detailsOptions,
    handleDetailsOnChange: caseCustomPrintStore.handleDetailsOnChange,
    caseType: caseStore.type,
    setDetailsPrintOptionsForCaseStore:
      caseStore.setDetailsPrintOptionsForCaseStore,
  };
}

const DetailsPrintOptionsContainer = observerWrappedContainerFactory({
  Context: CaseCustomPrintContext,
  mapProps,
  PresentationalComponent: DetailsPrintOptions,
});

export default DetailsPrintOptionsContainer;
