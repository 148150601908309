import { fromCamelCase } from 'common';

/**
 *
 * @param param
 * @param filterOptions
 * @param additionalOptions
 * @param includeAny
 * @returns {{param: string, options: ({name: string, value: null}|{name: *, value: *})[], label: (string|*)}}
 */
export function mapOptionsByParam({
  param = '',
  filterOptions = [],
  additionalOptions = [],
  includeAny = true,
}) {
  return {
    param: param,
    label: fromCamelCase(param),
    options: [
      ...(includeAny ? [{ value: null, name: 'Any' }] : []),
      ...additionalOptions,
      ...filterOptions.map(f => ({
        value: f.id,
        name: `${f.firstName || 'Unknown'} ${f.lastName || 'Unknown'}`,
      })),
    ],
  };
}
