import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

const DropdownItem = ({ item }) => {
  const { filterLabel, label, name } = item;

  const displayLabel = filterLabel || label;
  const displayName = filterLabel ? label : name;

  if (!displayName) return displayLabel;

  return (
    <span className={styles.rwInput}>
      {displayName ? (
        <>
          <strong>{displayLabel}:</strong> {displayName}
        </>
      ) : (
        displayLabel
      )}
    </span>
  );
};

DropdownItem.propTypes = {
  item: PropTypes.shape({
    filterLabel: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default DropdownItem;
