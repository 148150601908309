import React from 'react';
import { observer } from 'mobx-react';
import { EmployeeCasesStore, FlashBannerStore, HalUtils } from 'common';

import Store from '../stores/UsersByTagStore';
import UserTagsStore from '../stores/UserTagsStore';

import UsersByTagListView from '../UsersByTagListView';
import { withRouter } from 'react-router-dom';

const filterMsg = localStorage.getItem('filterMsg');

export function mapProps({
  employeeCasesStore,
  flashBannerStore,
  store,
  location,
  match,
  userTagsStore,
  HalUtils,
}) {
  const count = employeeCasesStore.countById.get(store.activeUserId);
  const caseIdByUserId = employeeCasesStore.infoById.get(store.activeUserId);
  const caseId =
    caseIdByUserId?.length === 1 ? HalUtils.getId(caseIdByUserId[0]) : '';

  const employeeStoreFetch = id => {
    employeeCasesStore.fetch(id);
  };

  const setEmployeeStoreId = id => {
    employeeCasesStore.id = id;
  };

  return {
    activeTag: userTagsStore.activeTag,
    activeUserId: store.activeUserId,
    count,
    caseId,
    store,
    userTagsStore,
    addBanner: (msg, level) => flashBannerStore.add(msg, level),
    failure: store.failure,
    employeeStoreFetch,
    setEmployeeStoreId,
    tagParamsId: match.params.tagId,
    tagId: store.tagId,
    userParamsId: match.params.userId,
    userId: store.userId,
    pathname: location.pathname,
    setValue: store.setValue,
    setActiveUserTagId: userTagsStore.setActiveUserTagId,
    hideTable: !store.loading && store.size === 0,
  };
}

const UsersByTagListViewContainer = withRouter(
  observer(({ location, match }) => (
    <UsersByTagListView
      {...mapProps({
        employeeCasesStore: EmployeeCasesStore,
        flashBannerStore: FlashBannerStore,
        store: Store,
        userTagsStore: UserTagsStore,
        location,
        match,
        filterMsg,
        HalUtils,
      })}
    />
  ))
);

export default UsersByTagListViewContainer;
