import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BaseCell from '../BaseCell';

const TaggedCell = observer(({ data, store, label, labelPlural, ...other }) => {
  const { name } = data;
  const count = store.tagCounts[name] || 0;

  return (
    <BaseCell data={data} store={store} {...other}>
      {count} {count === 1 ? label : labelPlural || `${label}s`}
    </BaseCell>
  );
});

TaggedCell.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
  }),
  store: PropTypes.shape({}),
  label: PropTypes.string,
  labelPlural: PropTypes.string,
};

TaggedCell.displayName = 'TaggedCell';

export default TaggedCell;
