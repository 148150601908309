import React from 'react';
import { FlagContext } from '../context';
import PropTypes from 'prop-types';

const Flagged = ({ children, flag, fallback }) => {
  return (
    <FlagContext.Consumer>
      {({ flags }) => {
        return flags.includes('featureFlag-' + flag) ? children : fallback;
      }}
    </FlagContext.Consumer>
  );
};

Flagged.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.node,
  fallback: PropTypes.node,
};

Flagged.defaultProps = {
  fallback: null,
  children: null,
};

export default Flagged;

/**
 * Higher-order component that provides a prop `flags` to your component.
 * This prop is an array of all current feature flag names that are applied.
 *
 * @param Component Component to wrap.
 * @return {function(*): *} Wrapped component.
 */
export const withFlags = Component => props => (
  <FlagContext.Consumer>
    {({ flags = [] }) => {
      flags = flags.map(flag => flag.replace('featureFlag-', ''));

      return <Component {...props} flags={flags} />;
    }}
  </FlagContext.Consumer>
);
