import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Cell } from 'fixed-data-table-2';
import styles from '../index.module.scss';

const UserStatusCell = ({ attemptsLeft, enabled, isActive, triage }) => {
  const icon =
    attemptsLeft === 0
      ? 'icon-lock'
      : !enabled
      ? 'icon-close'
      : triage
      ? 'icon-people'
      : 'icon-check';

  return (
    <Cell className={classnames({ [styles.isActive]: isActive })}>
      <i
        className={classnames(
          'material-icons',
          icon,
          'app-users--user-status-cell--text'
        )}
      />
    </Cell>
  );
};

export default UserStatusCell;

UserStatusCell.propTypes = {
  attemptsLeft: PropTypes.number,
  enabled: PropTypes.bool,
  isActive: PropTypes.bool,
  triage: PropTypes.bool,
};
