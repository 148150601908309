import moment from 'moment';

import Store from '../../stores/Store';
import ChartStore from '../../stores/ChartStore';
import PastAndFutureAccessStore from '../../stores/PastAndFutureAccessStore';

/**
 * Layer responsible for drawing past and future access dots in the gutters of the chart. A
 * sub-component of RowLayer.
 */
class PastFutureAccessLayer {
  /**
   * Calculate the translate property of the transform attribute of the
   * past/future accesses indicator.
   * @param {Object} d - the past/future accesses data point
   * @return {String}  - the translate property of the transform attribute
   */
  pastFutureAccessesTranslate(d) {
    let xPos = 0;

    if (d.key === 'past') {
      xPos = ChartStore.margin.left - 1;
    } else {
      xPos = ChartStore.width - ChartStore.scrollTrackOffset;
    }

    return `translate(${xPos}, ${ChartStore.rowHeight / 1.5})`;
  }

  pastFutureAccessesTooltipText(d) {
    if (d.value) {
      const date = moment(d.value.dateOfAccess).format('l');
      return `Go to ${date}`;
    }
  }

  render(selection) {
    const accesses = selection.selectAll('g.past-future-access').data(d => {
      const patientId = d.values[0].patient.id;
      const userId = d.values[0].user.id;

      if (patientId === Store.patientId && userId === Store.userId) {
        return [
          {
            key: 'past',
            value: PastAndFutureAccessStore.past,
          },
          {
            key: 'future',
            value: PastAndFutureAccessStore.future,
          },
        ];
      }
      return [];
    });

    accesses.attr('transform', this.pastFutureAccessesTranslate);
    accesses.attr('data-tippy-content', this.pastFutureAccessesTooltipText);
    accesses.attr('data-tippy-animation', 'fade');
    accesses.attr('data-tippy-arrow', 'true');
    accesses.classed('past-future-access--visible', d => d.value);

    const nodes = accesses
      .enter()
      .append('g')
      .classed('past-future-access material-icons', true)
      .classed('past-future-access--visible', d => d.value)
      .attr('transform', this.pastFutureAccessesTranslate)
      .attr('text-anchor', d => (d.key === 'future' ? 'end' : 'start'))
      .attr('data-tippy-content', this.pastFutureAccessesTooltipText)
      .attr('data-tippy-animation', 'fade')
      .attr('data-tippy-arrow', 'true');
    nodes
      .append('text')
      .attr('dominant-baseline', 'baseline')
      .text('more_vert')
      .on('click', d => Store.recenterOn(PastAndFutureAccessStore[d.key]));

    accesses.exit().remove();
  }
}

export default new PastFutureAccessLayer();
