import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Input } from '../../ui';
import CaseIconItem from '../CaseIconItem';
import CaseTabsContainer from '../CaseTabsContainer';
import styles from './index.module.scss';
import PrintTitle from '../../ui/PrintTitle';

const CaseHeader = ({
  caseNumber,
  editing,
  onChange,
  canEditTitle,
  caseId,
  title,
  isGRC,
}) => {
  return (
    <div className={styles.stickeyWrapper}>
      <PrintTitle>Case Information</PrintTitle>
      <div className={styles.padded}>
        <div className={styles.titleCard}>
          <div className={styles.folderIcon}>
            <i className="material-icons icon-folder" />
          </div>
          <div className={styles.information}>
            {caseNumber && (
              <div className={styles.number}>
                <h5>
                  Case
                  <strong data-cy="case-number">{` ${caseNumber}`}</strong>
                </h5>
              </div>
            )}
            {editing ? (
              <Input
                bordered
                onChange={onChange}
                padding="md"
                placeholder="Give this case a name"
                value={title || ''}
              />
            ) : (
              <h3 className={styles.title} data-cy="case-header">
                {title}
              </h3>
            )}
          </div>
          {canEditTitle && (
            <Link to={`/case/update/${caseId}`}>
              <CaseIconItem
                classNames={styles.icon}
                tooltipContent="Edit Case Details"
                icon="icon-edit"
              />
            </Link>
          )}
        </div>
      </div>
      {!isGRC && <CaseTabsContainer />}
    </div>
  );
};

CaseHeader.propTypes = {
  caseNumber: PropTypes.number,
  onChange: PropTypes.func,
  title: PropTypes.string,
  canEditTitle: PropTypes.bool,
  editing: PropTypes.bool,
  caseId: PropTypes.string,
  isGRC: PropTypes.bool,
};

CaseHeader.defaultProps = {
  caseNumber: null,
  onChange: () => {},
  printClick: () => {},
  title: '',
  canEditTitle: false,
  editing: false,
  caseId: '',
  isGRC: false,
};

export default CaseHeader;
